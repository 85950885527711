import {BaseApi} from '@/api/base-api';
import {getAuthHeader} from '@/api/common-utils';
import {AxiosResponse} from 'axios';

export interface GetCheckerTaskPayload {
  urls: string;
  recaptcha: string;
}

interface GetCheckerTaskResponse {
  task: {
    id: number;
  };
}

export class BulkDaCheckerApi extends BaseApi {
  public async getCheckerTask({urls, recaptcha}: GetCheckerTaskPayload) {
    try {
      const {
        data: {task},
      }: AxiosResponse<GetCheckerTaskResponse> = await this.axios({
        method: 'POST',
        url: '/customer/da_checker/start/',
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        data: {
          urls,
          recaptcha,
        },
      });

      return task;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async checkTaskStatus(task: number) {
    try {
      const {data} = await this.axios({
        method: 'GET',
        url: '/customer/da_checker/status/',
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: {
          task_id: task,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
