import React, {useState, useEffect} from 'react';
import {Modal, SkeletonRingLoader} from '@/components/common-components/components';
import {Radio} from 'antd';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-light-svg-icons';
import {observer} from 'mobx-react';
import classnames from 'classnames';
import styles from './style.module.scss';
import {Button} from '@/components/common-components/v2';
import {CloseOutlined} from '@ant-design/icons';
import {NextImg} from '@/utils/nextImg';

interface props {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  handleChangePayment: () => void;
  getCustomerQuota?: any;
}

export const ChangePaymentMethodModal: React.FC<props> = observer(({isOpen, setIsOpen, handleChangePayment, getCustomerQuota}) => {
  const [previousCard, setPreviousCard] = useState(-1);
  const [newCard, setNewCard] = useState(true);
  const [removeCard, setRemoveCard] = useState(Number);
  const [isDisable, setIsDisable] = useState(-1);

  const {
    invoices: {
      getPaymentOptions,
      getPaymentMethod,
      updatePrimaryPaymentMethod,
      getCurrentPaymentLoading,
      getCurrentPayment,
    },
  } = useStore('');
  const [isPreviousCard, setIsPreviousCard] = useState(-1);

  const onClickSetPrimary = itemId => {
    setIsPreviousCard(itemId);
    setPreviousCard(itemId);
    itemId == -1 ? setNewCard(true) : setNewCard(false);
  };
  const handleSubmit = async () => {
    if (newCard) {
      handleChangePayment();
    } else {
      await updatePrimaryPaymentMethod(previousCard, {is_primary: true});
      if (getCustomerQuota) await getCustomerQuota();
      setIsOpen(false);
    }
  };
  const handleRemoveCard = async () => {
    await updatePrimaryPaymentMethod(removeCard, {is_active: false});
    await getCurrentPayment();
    setRemoveCard(0);
  };

  const removeFirstStep = itemId => {
    setRemoveCard(itemId);
  };
  const removeCancel = () => {
    setRemoveCard(0);
  };

  useEffect(() => {
    setIsPreviousCard(getPaymentMethod?.id);
    setPreviousCard(getPaymentMethod?.id);
    setIsDisable(getPaymentMethod?.id);
    getPaymentMethod ? setNewCard(false) : setNewCard(true);
  }, [getPaymentMethod, setPreviousCard]);

  const getCardIcons = brand => {
    if (brand?.includes('visa')) {
      return '/icons/visaicon.svg';
    } else if (brand?.includes('discover')) {
      return '/icons.discover-card-icon.svg';
    } else {
      return '/img/icon/master-card.svg';
    }
  };
  return (
    <div>
      <Modal
        onClose={() => setIsOpen(false)}
        closeIcon={<CloseOutlined className={styles.closeIcon}/>}

        visible={isOpen}
        className={styles.changePaymentMethod}
      >
        <div className={styles.changePaymentMethod}>
          <div className={styles.content}>

            <h2 className={styles.heading}>Manage saved cards</h2>

            <div className={styles.cardDetail}>

              { getCurrentPaymentLoading ? (
                <SkeletonRingLoader/>
              ) : (
                <Radio.Group value={previousCard}>
                  { getPaymentOptions.length > 0 && (
                    getPaymentOptions.map((item, index) =>{
                      return <div key={index} className={classnames(styles.paymentCard, previousCard== item.id ? styles.paymentCardActive : '' )}>
                        <Radio value={item.id}>
                          <div className={styles.paymentDetail} style={{justifyContent: removeCard == item.id ? 'space-evenly' : 'center'}}>
                            <div className={styles.images}>
                              <NextImg style={previousCard== item.id ? {} : {filter: 'grayscale(0.9)'}} src={getCardIcons(item?.brand?.toLowerCase())}/>
                              <div style={{display: removeCard == item.id ? 'none' : 'flex'}}>
                                { getPaymentOptions.length > 1 && <div onClick={()=>removeFirstStep(item.id)} className={styles.removeButton}>Remove</div>}
                                {previousCard == item.id ?
                                  <div className={styles.setPrimaryCardChecked}>
                                    <FontAwesomeIcon icon={faCheck} style={{color: '#7F4EAD', fontSize: '13px', marginRight: '5px'}}/>Primary card</div> :

                                  <div onClick={()=>onClickSetPrimary(item.id)} className={styles.setPrimaryButton}>Set as primary</div>}
                              </div>

                            </div>
                            {removeCard == item.id ?
                              <div>
                                <div className={styles.removeCardText}>
                              Are you sure you want to remove this card?
                                </div>
                                <div className={styles.removeCardButtonsContainer}>
                                  <div onClick={handleRemoveCard} className={styles.removeCardButtons} style={{color: '#FF0000'}}>Yes, remove card</div>
                                  <div onClick={removeCancel} style={{marginLeft: '30px', color: '#808080'}} className={styles.removeCardButtons}>Cancel</div>
                                </div>
                              </div> :
                              <div className={styles.paymentInfo}>
                                <span className={styles.numberLabel}>NUMBER</span>
                                <span style={{fontSize: '14px', color: '#121212', fontWeight: 500, marginBottom: '8px', display: 'flex', alignItems: 'center'}}>•••• •••• <span style={{paddingTop: '4px', paddingLeft: '5px'}}> ••••</span>&nbsp;{item.last4}</span>
                                <p className={styles.nameLabel}>
                                  <span>NAME ON CARD</span>
                                  <span>Expires</span>
                                </p>
                                <p>
                                  <span style={{fontSize: '14px', color: '#121212', fontWeight: 500}}>{ item.name && item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name }</span>
                                  <span style={{fontSize: '14px', color: '#121212', fontWeight: 500}}>{item.expMonth}/{item.expYear}</span>
                                </p>
                              </div>}

                          </div>
                        </Radio>
                      </div>;
                    })
                  )}
                  <div onClick={handleChangePayment} className={classnames(styles.paymentCard, styles.newPaymentCard, previousCard== -1 ? styles.paymentCardActive : '' )}>
                    <Radio value={-1}>
                      <div className={styles.newCard}>
                        <NextImg src='/img/icon/add-new-card.svg' />
                      </div>
                    </Radio>
                  </div>
                </Radio.Group>
              )}

            </div>

            <div className={styles.lock}>
              <NextImg width={18} height={18} src='/img/icon/lock.svg'/>
              <label className={styles.labelBottom}>
                Your information and transactions are secured with SSL encryption.</label>
            </div>
          </div>

          <div className={styles.footer}>
            <span
              className={styles.cancel}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </span>
            <Button
              disabled = {isDisable == isPreviousCard}
              className={styles.payForInvoice}
              onClick={handleSubmit}
            >
              Update payment method
            </Button>
          </div>
        </div>

      </Modal>
    </div>
  );
});
