import React, {useEffect, useState} from 'react';
import {StyledModal, StyledPhoneInput, StyledSelect} from './styles';
import {Button, Form, Select} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {OTTO_V2_API} from '@/api/otto-v2';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {CaretDownOutlined} from '@ant-design/icons';
import {debounce} from 'lodash';

export const AddPhoneNumberModal = ({
  modalConfig,
  setModalConfig,
  updateData,
  setModalLoader,
  selectedValue,
  isInLocalSeo,
  categories,
  setCategories,
  selectedLocation,
  modalLoader,
}) => {
  const [form] = Form.useForm();
  const [searchParam, setSearchParam] = useState('');
  const [loader, setLoader] = useState(false);
  const {Option} = Select;

  const getOptions = categories => {
    return categories?.map(category => (
      <Option key={category.key} value={category.key}>
        {category.label}
      </Option>
    ));
  };

  const resetSearch = async () => {
    if (searchParam) {
      const searchParamApi = {location: selectedLocation};
      const categoryResponse = await OTTO_V2_API.getMetadataCategories(searchParamApi);
      await setCategories(categoryResponse?.categories);
      setSearchParam('');
    }
  };

  const onSearch = debounce( async value => {
    setSearchParam(value);
    const searchParam = value ? {search: value, location: selectedLocation} : {location: selectedLocation};
    setLoader(true);
    const categoryResponse = await OTTO_V2_API.getMetadataCategories(searchParam);
    setCategories(categoryResponse?.categories);
    setLoader(false);
  }, 1000);

  const modalTypeMapping = {
    phoneNumber: {
      title: 'Phone number',
      description: 'Provide a number that connects directly to your business',
      field: props => <StyledPhoneInput
        inputClass='support-phone-input'
        country='us'
        width='100%'
        onChange={(value, country, e, formattedValue) => {
          form?.setFieldsValue({
            [`${modalTypeMapping[modalConfig?.type]?.fieldName}_${props?.index + 1}`]: formattedValue,
          });
        }}
      />,
      fieldLabel: ['Primary phone', 'Secondary phone'],
      fieldName: 'primary_phone',
    },
    businessCategory: {
      title: 'Business category',
      description: 'Help customers find your business by industry.',
      field: props => <StyledSelect
        showSearch
        defaultValue={props?.key}
        onSearch={onSearch}
        suffixIcon={props?.index > 0 ? '' : <CaretDownOutlined color='black'/>}
        disabled={!categories?.length && !searchParam}
        notFoundContent={loader ? (
          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
            <RingLoaderV2 fontSize={20} />
          </div>
        ) : (
          <div style={{padding: '8px', textAlign: 'center'}}>No data found</div>
        )}
        placeholder='Enter more than 3 characters to search'
        filterOption={false}
        onSelect={() => resetSearch()}
      >
        {getOptions(categories)}
      </StyledSelect>,
      fieldLabel: ['Primary category', 'Additional category'],
      fieldName: 'primary_category',
    },
  };

  const [fieldsArr, setFieldsArr] = useState([{name: `${modalTypeMapping[modalConfig?.type]?.fieldName}_1`}]);
  const onClose = () => {
    setModalConfig({type: null, visible: false});
  };

  const getCategories = async () => {
    const categoryResponse = await OTTO_V2_API.getMetadataCategories({location: selectedLocation}, isInLocalSeo);
    setCategories(categoryResponse?.categories);
  };

  useEffect(() => {
    const arr = [];
    selectedValue.forEach((item, index) => {
      const obj = {name: `${modalTypeMapping[modalConfig?.type]?.fieldName}_${index + 1}`};
      arr.push(obj);
    });
    setFieldsArr(arr);
    getCategories();
  }, [modalConfig?.visible]);

  const initialValues = () => {
    const obj = {};
    selectedValue.forEach((item, index) => {
      obj[`${modalTypeMapping[modalConfig?.type]?.fieldName}_${index + 1}`] = item;
    });
    return obj;
  };

  return (
    <StyledModal
      open={modalConfig?.visible}
      onCancel={onClose}
      width={460}
      closable={false}
      footer={false}
    >
      <div className='title'>{modalTypeMapping[modalConfig?.type].title}</div>
      <div className='desc'>{modalTypeMapping[modalConfig?.type].description}</div>
      <Form
        className='fields-container'
        form={form}
        onFinish={async values => {
          setModalLoader(true);
          try {
            const fieldName = modalTypeMapping[modalConfig?.type]?.fieldName;
            const valueKeys = fieldName === 'primary_category' ?
              Object.values(values).map(item => typeof item !== 'object' ? categories?.find(i => i?.key === item) : item) :
              Object.values(values);

            const fields = [{
              fieldName: fieldName === 'primary_phone' ? 'phone_numbers' : 'categories',
              fieldType: 'FIELD',
              value: valueKeys,
            }];

            await updateData(fields);
          } catch (error) {
            console.error(error);
            return Promise.reject(error);
          } finally {
            setModalLoader(false);
          }
        }}

        initialValues={initialValues()}
      >
        {fieldsArr?.map((i, index) => (
          <div className='input-field-container' key={i?.name}>
            <div className='label'>{modalConfig?.type === 'phoneNumber' ? index === 0 ? 'Primary phone' : 'Secondary phone' : index === 0 ? 'Primary category' : 'Additional category'}</div>
            <Form.Item name={i?.name} preserve={false}>
              {modalTypeMapping[modalConfig?.type]?.field({...selectedValue[index], index})}
            </Form.Item>
            {index > 0 && <FontAwesomeIcon icon={faTrash} className='deleteIcon' onClick={() => setFieldsArr(prev => prev.filter(item => item?.name !== i?.name))} />}
          </div>
        ))}
        <button onClick={e => {
          e.preventDefault();
          setFieldsArr(prev => {
            return [...prev, {name: `${modalTypeMapping[modalConfig?.type].fieldName}_${prev.length + 1}`}];
          });
        }} className='add-phone-num-button'>+ {modalConfig?.type === 'phoneNumber' ? 'Add phone number' : 'Add another category' }</button>
        <div className='footer'>
          <Button
            className='save-button'
            htmlType='submit'
            loading={modalLoader}
          >Save Changes</Button>
          <button onClick={onClose} className='cancel-button'>Cancel</button>
        </div>
      </Form>
    </StyledModal>
  );
};
