import {FOCUS_TERMS_API, PAGES_API} from '@/api/content-optimizer';
import {EditPagePermissionPayload, PagePermissionsApiResponse} from '@/api/content-optimizer/content-optimizer.model';
import {ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState, SelectionState} from 'draft-js';
import {cast, flow, getParent, Instance, types, getRoot} from 'mobx-state-tree';
import {notification} from 'antd';
import {DialogModel} from './pages.model';
import {TargetKeywordsModel, FeTargetKeywordsModel, FeTargetKeywordsType} from './TargetKeywordsModel';
import {SuggestedKeywordsModel} from './SuggestedKeywordsModel';
import {API_POLL_INTERVAL} from '@/constants';
import {notification as notify, useErrorNotification} from '@/utils/notification-v2';
import {OVERVIEW_DETAIL_API} from '@/api/keyword-explorer';
import {headingsArr} from '@/constants/tags';
import {toJS} from 'mobx';
import Router from 'next/router';
import {routes} from '@/utils/const';
import * as CurrentPageModels from './CurrentPageModel';
import {CONTENT_ASSISTANT_API} from '@/api/ContentAssistant';
import {isNil} from 'lodash';
import {convertHtmlToEditorState} from '@/utils/htmlToDraft';
import {handleNetworkError} from '@/utils/api';

enum FocusTermInclusionStatus {
  INCLUDED,
  INCLUDE_MORE,
  INCLUDE_LESS,
}

const FocusTermsModel = types
  .model({
    competitors: types.maybeNull(CurrentPageModels.FocusTermCompetitorModel),
    name: types.string,
    foundIn: types.maybeNull(types.array(types.string)),
    variations: types.maybeNull(types.array(types.string)),
    requiredCountMin: types.maybeNull(types.number),
    idealCountMin: types.maybeNull(types.number),
    idealCountMax: types.maybeNull(types.number),
    requiredCountMax: types.maybeNull(types.number),
    usedCount: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    sv: types.maybeNull(types.number),
    cpc: types.maybeNull(types.number),
    normalizedScore: types.maybeNull(types.number),
    source: types.maybeNull(types.array(types.string)),
    isBranded: types.maybeNull(types.boolean),
    crunchbaseLink: types.maybeNull(types.string),
    knowledgeGraphLink: types.maybeNull(types.string),
    wikidataLink: types.maybeNull(types.string),
    wikipediaLink: types.maybeNull(types.string),
  })
  .views(self => ({
    get inclusionStatus(): FocusTermInclusionStatus {
      if (self.usedCount < self.requiredCountMin) return FocusTermInclusionStatus.INCLUDE_MORE;
      if (self.usedCount > self.requiredCountMax) return FocusTermInclusionStatus.INCLUDE_LESS;
      return FocusTermInclusionStatus.INCLUDED;
    },
    get percentage(): number {
      if (self.idealCountMin <= 0 || self.usedCount <= 0) return 0;
      return Math.floor((self.usedCount / self.idealCountMin) * 10) * 10;
    },
  }))
  .actions(self => {
    const updateCount = (count: number) => self.usedCount = count;
    return {updateCount};
  });

type FocusTermsType = Instance<typeof FocusTermsModel>;

export interface IFocusTermToClassNameMap {
  [key: string]: string;
}


const ClusterFocusTermsModel = types.model({
  name: types.string,
  foundIn: types.maybeNull(types.array(types.string)),
  idealCountMax: types.maybeNull(types.number),
  status: types.string,
  usedCount: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
});
const ClusteredModel = types.model({
  title: types.maybeNull(types.string),
  clusterSv: types.maybeNull(types.number),
  clusterNormalizedScore: types.maybeNull(types.number),
  focusTerms: types.array(ClusterFocusTermsModel),
});

export type FocusTermModel = Instance<typeof FocusTermsModel>;
export type FocusTermClusteredType = Instance<typeof ClusteredModel>;
const AnalyticsModel = types
  .model({
    commonCitations: types.maybeNull(types.array(CurrentPageModels.CommonCitationsModel)),
    focusTerms: types.maybeNull(types.array(FocusTermsModel)),
    clusters: types.maybeNull(types.array(ClusteredModel)),
    questions: types.maybeNull(types.array(CurrentPageModels.QuestionsModel)),
    competitors: types.maybeNull(types.array(CurrentPageModels.CompetitorModel)),
    competitorsData: types.maybeNull(types.array(CurrentPageModels.CompetitorDataModel)),
    score: types.maybeNull(types.optional(types.number, 0)),
    focusTermsGridData: types.maybeNull(types.array(CurrentPageModels.FocusTermsGridItem)),
    keywordSuggestionsGridData: types.maybeNull(types.array(CurrentPageModels.KeywordSuggestionsGridItem)),
  })
  .views(self => ({

    get includedTerms() {
      return self.focusTerms?.filter(term => term.status === 'included');
    },
    get includeMoreTerms() {
      return self.focusTerms?.filter(term => term.status === 'include-more');
    },
    get includeLessTerms() {
      return self.focusTerms?.filter(term => term.status === 'include-less');
    },

    get termsWithHeadingsTotal() {
      const included = self.focusTerms?.filter(term => term.status === 'included' && term.foundIn?.some(t => headingsArr.includes(t))).length;
      const includeMore = self.focusTerms?.filter(term => term.status === 'include-more' && term.foundIn?.some(t => headingsArr.includes(t))).length;
      const includeLess = self.focusTerms?.filter(term => term.status === 'include-less' && term.foundIn?.some(t => headingsArr.includes(t))).length;

      return included + includeMore + includeLess;
    },

    get totalUsedTerms() {
      return self.focusTerms?.filter(term => term.usedCount > 0)?.length || 0;
    },

    get totalUsedQuestions() {
      return self.questions?.filter(term => term.usedCount > 0)?.length || 0;
    },

  }));

const SchemaModel = types.model({
  rdfa: types.maybeNull(types.string),
  jsonLd: types.maybeNull(types.string),
  microdata: types.maybeNull(types.string),
});

const AnalysisModel = types.model({
  score: types.maybeNull(types.number),
  technicalScore: types.maybeNull(types.number),
  psiMetrics: types.maybeNull(CurrentPageModels.avgSpeedIndexModel),
  url: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  contentScoreMetrics: types.maybeNull(types.model({
    focusTermsUsed: types.maybeNull(types.number),
    focusTermsTotal: types.maybeNull(types.number),
  })),
  robots: types.maybeNull(types.string),
  canonical: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  headers: types.maybeNull(types.array(CurrentPageModels.HeaderModel)),
  images: types.maybeNull(types.model({
    count: types.maybeNull(types.number),
    img: types.maybeNull(types.array(CurrentPageModels.ImageModel)),
  })),
  iframes: types.maybeNull(types.model({
    count: types.maybeNull(types.number),
    iframe: types.maybeNull(types.array(CurrentPageModels.IframeModel)),
  })),
  videos: types.maybeNull(types.model({
    count: types.maybeNull(types.number),
    video: types.maybeNull(types.array(CurrentPageModels.VideoModel)),
  })),
  links: types.maybeNull(types.model({
    internal: types.maybeNull(types.array(CurrentPageModels.LinkModel)),
    external: types.maybeNull(types.array(CurrentPageModels.LinkModel)),
  })),
  social: types.maybeNull(types.model({
    count: types.maybeNull(types.number),
    social: types.maybeNull(types.array(CurrentPageModels.SocialModel)),
  })),
  schema: types.maybeNull(types.union(SchemaModel, types.array(types.frozen()))),
  js: types.maybeNull(types.model({
    count: types.maybeNull(types.number),
    js: types.maybeNull(types.array(CurrentPageModels.JSModel)),
  })),
  metaSummary: types.maybeNull(types.array(types.model({
    field: types.maybeNull(types.string),
    isCompliant: types.maybeNull(types.boolean),
    status: types.maybeNull(types.string),
    statusColor: types.maybeNull(types.string),
    suggestion: types.maybeNull(types.string),
    suggestionColor: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
}).views(self => ({
  get getInternalBrokenLinksCount() {
    return self.links?.internal?.filter(item => item.statusCode && item.statusCode >= 400 && item.statusCode < 500).length || 0;
  },
  get getInternalRedirectsCount() {
    return self.links?.internal?.filter(item => item.statusCode && item.statusCode >= 300 && item.statusCode < 400).length || 0;
  },
  get getExternalBrokenCount() {
    return self.links?.external?.filter(item => item.statusCode && item.statusCode >= 400 && item.statusCode < 500).length || 0;
  },
  get getExternalRedirectsCount() {
    return self.links?.external?.filter(item => item.statusCode && item.statusCode >= 300 && item.statusCode < 400).length || 0;
  },
  get brokenHeaders() {
    return self.headers?.filter(item => item.status === 'Bad');
  },
})).actions(self => {
  const clearUrl = () => self.url = null;

  return {
    clearUrl,
  };
});


// TODO Implement references instead of separate PageModel
const CurrentPageDataModel = types
  .model({
    url: types.maybeNull(types.string),
    hostname: types.maybeNull(types.string),
    uuid: types.maybeNull(types.string),
    createdByUser: types.maybeNull(types.string),
    targetKeywords: types.maybeNull(types.array(TargetKeywordsModel)),
    targetKeywordsCount: types.maybeNull(types.number),
    kwSuggestions: types.optional(types.array(SuggestedKeywordsModel), []),
    title: types.maybeNull(types.string),
    importedFromUrl: types.maybeNull(types.string),
    requiredWordCount: types.maybeNull(types.number),
    version: types.maybeNull(types.string),
    maxSerpPosition: types.maybeNull(types.number),
    textPreview: types.maybeNull(types.string),
    projectName: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    wordCount: types.maybeNull(types.number),
    suggestedWordCountLimits: types.maybeNull(CurrentPageModels.suggestedWordCountLimitsModel),
    suggestedWordCount: types.maybeNull(types.number),
    readability: types.maybeNull(types.union(types.string, types.number)),
    averageReadability: types.maybeNull(types.string),
    readabilityGrade: types.maybeNull(types.string),
    score: types.maybeNull(types.number),
    scoreExtended: types.maybeNull(CurrentPageModels.ExtendedScoreModel),
    scoreCounts: types.maybeNull(CurrentPageModels.ScoreCountsModel),
    paragraphsCount: types.maybeNull(types.number),
    viewers: types.maybeNull(types.array(CurrentPageModels.ViewerModel)),
    cleanedHtml: types.maybeNull(types.string),
    editorState: types.maybeNull(types.string),
    headingLevel: types.maybeNull(types.optional(types.string, '')),
    isAnalyzing: types.maybeNull(types.optional(types.boolean, false)),
    isProcessingKws: types.maybeNull(types.optional(types.boolean, false)),
    analyzingError: types.optional(types.boolean, false),
    countryCode: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    locationId: types.maybeNull(types.number),
    reach: types.maybeNull(types.number),
    internalLinkSuggestions: types.optional(types.array(CurrentPageModels.InternalLinkSuggestionsModel), []),
    internalLinkSuggestionsV2: types.maybeNull(CurrentPageModels.InternalLinkSuggestionsModelV2),
    isPublic: types.maybeNull(types.boolean),
    status: types.maybeNull(types.string),
    wpPublishedAtUrl: types.maybeNull(types.string),
    wpPostCategories: types.maybeNull(types.array(types.string)),
    wpSyncedAt: types.maybeNull(types.string),
    wpSynced: types.maybeNull(types.boolean),
    wpPostStatus: types.maybeNull(types.string),
    wpSyncingTaskStatus: types.maybeNull(types.string),
    wpPostType: types.maybeNull(types.string),
    metaTitle: types.maybeNull(types.string),
    metaDesc: types.maybeNull(types.string),
    isOwner: types.maybeNull(types.boolean),
    shouldUseHeadlessBrowser: types.maybeNull(types.boolean),
    heroImage: types.maybeNull(types.string),
    heroImageAltText: types.maybeNull(types.string),
    aiInProgress: types.maybeNull(types.boolean),
    wpPublishedAtWebsiteId: types.maybeNull(types.number),
    wpPostAuthorId: types.maybeNull(types.number),
    projectId: types.maybeNull(types.number),
    defaultWpPostCategories: types.maybeNull(types.array(types.string)),
    defaultWpPostStatus: types.maybeNull(types.string),
    defaultWpPostType: types.maybeNull(types.string),
    defaultWpUrlSlug: types.maybeNull(types.string),
    defaultWpWebsiteId: types.maybeNull(types.number),
    shopifyArticleId: types.maybeNull(types.number),
    shopifyBlogId: types.maybeNull(types.number),
    shopifyContentType: types.maybeNull(types.string),
    shopifyContentUrl: types.maybeNull(types.string),
    shopifyPageId: types.maybeNull(types.number),
    shopifySynced: types.maybeNull(types.boolean),
    shopifySyncedAt: types.maybeNull(types.string),
    wpPostParent: types.maybeNull(types.number),
    isCleanedHtmlUpdated: types.maybeNull(types.boolean),
  })

  .views(self => ({
    get listedLinkSuggestions() {
      const spreadArr = [];
      const cleanedArr = [];
      if (self.internalLinkSuggestions?.length) {
        for (let i = 0; i <= self.internalLinkSuggestions.length; i++) {
          spreadArr.push([...(self.internalLinkSuggestions[i].results || [])]);
        }
      }

      if (spreadArr.length) {
        for (let i = 0; i <= spreadArr.length; i++) {
          if (!cleanedArr.filter(link => link.url === spreadArr[i]?.url).length) {
            cleanedArr.push(spreadArr[i]);
          }
        }
      }

      return cleanedArr;
    },

    get linkSuggestionsKeywords() {
      const kwArray = [];
      if (self.internalLinkSuggestions?.length) {
        for (let i = 0; i <= self.internalLinkSuggestions.length; i++) {
          if (!kwArray.includes(self.internalLinkSuggestions[i].keyword)) {
            kwArray.push(self.internalLinkSuggestions[i].keyword);
          }
        }
      }
      return kwArray;
    },

    formattedEditorState() {
      if (self?.editorState) {
        const formatted = convertFromRaw(JSON.parse(self.editorState));
        return EditorState.createWithContent(formatted);
      } else if (!self?.editorState && self.cleanedHtml) {
        return convertHtmlToEditorState(self.cleanedHtml);
      } else {
        return EditorState.createEmpty();
      }
    },

    get targetKewyordsOnlyArr() {
      const cleanArr = [];
      self.targetKeywords?.forEach(item => {
        cleanArr.push(item.keyword);
      });
      return cleanArr;
    },

    get totalUsedSuggestions() {
      let totalUsed = 0;
      toJS(self.internalLinkSuggestionsV2?.allLinks)?.forEach(suggestion => {
        if (suggestion.isUsed) {
          totalUsed += 1;
        }
      });

      return totalUsed;
    },

  }))
  .views(self => ({
    get editorText(): string {
      return self.formattedEditorState().getCurrentContent().getPlainText();
    },
  }))
  .views(self => ({
    get percentage(): number {
      if (self.wordCount <= self.suggestedWordCount) {
        return Math.floor((self.wordCount / (self.suggestedWordCount ?? 2500)) * 100);
      } else {
        return 100;
      }
    },
  }))
  .actions(self => {
    const setFromResponse = (resp: any) => {
      self.title = resp.title;
      self.version = resp.version;
      self.editorState = resp.editorState;
      self.cleanedHtml = resp.cleanedHtml;
      self.targetKeywords = resp.targetKeywords;
      self.score = resp.score;
      self.importedFromUrl = resp.importedFromUrl;
      self.readability = resp.readability;
      self.wordCount = resp.wordCount;
      self.status = resp.status;
      self.updatedAt = resp.updatedAt;
      self.metaTitle = resp.metaTitle;
      self.metaDesc = resp.metaDesc;
      self.wpSynced = resp.wpSynced;
      self.heroImage = resp.heroImage;
      self.heroImageAltText = resp.heroImageAltText;
      self.isCleanedHtmlUpdated = resp.isCleanedHtmlUpdated;
      if (resp?.scoreCounts && self.scoreCounts) self.scoreCounts = cast(resp?.scoreCounts);
      if (resp?.suggestedWordCount && self.suggestedWordCount) self.suggestedWordCount = cast(resp?.suggestedWordCount);
    };

    const clearInternalLinkSuggestions = () => self.internalLinkSuggestions.length = 0;

    const setHeroImage = (image, altText) => {
      self.heroImage = image;
      self.heroImageAltText = altText;
    };

    const clearKwSuggestions = () => self.kwSuggestions.length = 0;

    const setKwSuggestions = kwSuggestions => self.kwSuggestions = cast(kwSuggestions);

    const setHeadingLevel = (level: string) => self.headingLevel = level;

    const cleanEditorState = () => {
      self.editorState = '';
      self.cleanedHtml = '';
    };

    return {setKwSuggestions, clearKwSuggestions, setFromResponse, setHeadingLevel, cleanEditorState, clearInternalLinkSuggestions, setHeroImage};
  });

const ShareDialog = types.compose(
  DialogModel,
  types.model({
    uuid: types.optional(types.string, ''),
  }),
).actions(self => {
  const openDialog = (uuid: string) => {
    self.isOpen = true;
    self.uuid = uuid;
  };

  const closeDialog = () => {
    self.isOpen = false;
    self.uuid = '';
  };

  return {
    openDialog,
    closeDialog,
  };
});

const PermissionUserModel = types
  .model({
    id: types.maybeNull(types.number),
    email: types.string,
  })
  .views(self => ({
    get formattedUser() {
      return {
        email: self.email,
      };
    },
  }));

const PagePermissionsModel = types
  .model({
    isEditableByAnyone: types.boolean,
    isViewableByAnyone: types.boolean,
    viewableToUsers: types.array(PermissionUserModel),
    editableToUsers: types.array(PermissionUserModel),
    loading: types.optional(types.boolean, false),
    error: types.optional(types.boolean, false),
  })
  .views(self => ({
    get users() {
      const editorEmails = self.editableToUsers.map(({email}) => email);

      const editors = self.editableToUsers.map(user => ({
        ...user.formattedUser,
        permission: 'Edit',
      }));

      const viewers = self.viewableToUsers.map(user => ({
        ...user.formattedUser,
        permission: 'View',
      })).filter(item => !editorEmails.includes(item.email));

      return [...viewers, ...editors];
    },
  }))
  .actions(self => {
    const fetchPagePermissions = flow(function* (uuid: string) {
      self.loading = true;
      try {
        const resp: PagePermissionsApiResponse = yield PAGES_API.getPagePermissions(uuid);
        if (resp) {
          self.isEditableByAnyone = resp.isEditableByAnyone || false;
          self.isViewableByAnyone = resp.isViewableByAnyone || false;
          self.viewableToUsers = cast(resp?.viewableToUsers);
          self.editableToUsers = cast(resp?.editableToUsers);
        }
        self.loading = false;
        self.error = false;
      } catch (e) {
        self.loading = false;
        self.error = true;
        if (e?.response?.status !== 520) {
          useErrorNotification({
            e,
            msg: 'Content Assistant stopped working',
            desc: 'A critical issue caused the tool to stop working. To fix the issue:',
            permanent: false,
            handleStatuses: [{
              statuses: [401, 403],
              msg: 'Please sign up to edit this article.',
              permanent: false,
              showDetails: false,
            }],
          });
          return Promise.reject(e);
        }
      }
    });

    const editPagePermissions = flow(function* (payload: {
      emails?: string[];
      permission?: 'edit' | 'view' | 'revoke';
      note?: string;
      isEditableByAnyone?: boolean;
      isViewableByAnyone?: boolean;
    }) {
      const parent = getParent<typeof CurrentPage>(self);
      const data: EditPagePermissionPayload = {
        'viewable_to_emails': [],
        'editable_to_emails': [],
        'is_editable_by_anyone': false,
        'is_viewable_by_anyone': false,
      };


      if (payload.permission === 'view' || payload.permission === 'edit') {
        data['viewable_to_emails'].push(...payload.emails);
        data['viewable_to_emails'].push(...(self.viewableToUsers?.map(user => user?.email)?.filter(email => email !== (payload?.emails[0] ?? '')) ?? []));
      }
      if (payload.permission === 'edit') {
        data['editable_to_emails'].push(...payload.emails);
        data['editable_to_emails'].push(...(self.editableToUsers?.map(user => user?.email)?.filter(email => email !== (payload?.emails[0] ?? '')) ?? []));
      }
      if (payload.permission === 'revoke') {
        data['viewable_to_emails'] = data['viewable_to_emails']?.filter(email => email !== (payload?.emails[0] ?? ''));
        data['editable_to_emails'] = data['editable_to_emails']?.filter(email => email !== (payload?.emails[0] ?? ''));
      }
      // due to BE setup, we are sending only view when view option is select but
      // when edit is selected we are sending both view and edit
      if (payload.isViewableByAnyone) {
        data['is_viewable_by_anyone'] = true;
      }
      if (payload.isEditableByAnyone) {
        data['is_viewable_by_anyone'] = true;
        data['is_editable_by_anyone'] = true;
      }

      try {
        const resp: PagePermissionsApiResponse = yield PAGES_API.editPagePermissions(parent.content.uuid, {
          ...data,
          // note: payload.note ?? null,
        });
        if (payload.isViewableByAnyone || payload.isEditableByAnyone) {
          notification.success({message: `Global Page permission has been changed! Public page URL is copied to clipboard.`});
        }

        if (payload.emails?.length) {
          if (payload.permission === 'revoke') {
            notification.success({message: `User permissions are revoked!`});
          } else {
            notification.success({message: `Invite to ${payload.permission} the Page has been sent!`});
          }
        }

        if (resp) {
          self.isEditableByAnyone = resp.isEditableByAnyone;
          self.isViewableByAnyone = resp.isViewableByAnyone;
          self.viewableToUsers = cast(resp.viewableToUsers);
          self.editableToUsers = cast(resp.editableToUsers);
        }
        return resp;
      } catch (e) {
        return Promise.reject(e);
      }
    });

    return {fetchPagePermissions, editPagePermissions};
  });

export type targetKeywords = Instance<typeof TargetKeywordsModel>;

const importErrorType = types.model({
  show: types.maybeNull(types.boolean),
  isCreatedFromScratch: types.maybeNull(types.boolean),
});

const EditorHeaderOffset = types.model({
  breadcrumbHeight: types.maybeNull(types.number),
  keywordsHeight: types.maybeNull(types.number),
});


const FocusTermsSorterModel = types.model({
  label: types.string,
  key: types.string,
});

const TermsAssistantSuggestionsModel = types.model({
  html: types.maybeNull(types.string),
  isSuggestion: types.maybeNull(types.boolean),
  suggestion: types.maybeNull(types.string),
  termsString: types.maybeNull(types.union(types.array(types.string), types.string)),
  termIncluded: types.maybeNull(types.array(types.string)),
});

const LinkAssistantSuggestionsModel = types.model({
  html: types.maybeNull(types.string),
  isSuggestion: types.maybeNull(types.boolean),
  suggestion: types.maybeNull(types.string),
  linksString: types.maybeNull(types.union(types.array(types.string), types.string)),
  linksIncluded: types.maybeNull(types.array(types.string)),
});

const StagingTaSuggestionsModel = types.model({
  html: types.maybeNull(types.string),
  isSuggestion: types.maybeNull(types.boolean),
  suggestion: types.maybeNull(types.string),
  termsString: types.maybeNull(types.union(types.array(types.string), types.string)),
  termIncluded: types.maybeNull(types.array(types.string)),
  status: types.string,
  itemIndex: types.number,
});

const StagingLinkSuggestionsModel = types.model({
  html: types.maybeNull(types.string),
  isSuggestion: types.maybeNull(types.boolean),
  suggestion: types.maybeNull(types.string),
  linksString: types.maybeNull(types.union(types.array(types.string), types.string)),
  linksIncluded: types.maybeNull(types.array(types.string)),
  status: types.string,
  itemIndex: types.number,
});

type StagingTaSuggestionsType = Instance<typeof StagingTaSuggestionsModel>;
type StagingLinkSuggestionsType = Instance<typeof StagingLinkSuggestionsModel>;

const TaActiveTermModel = types.model({
  class: types.string,
  term: types.string,
  order: types.number,
  itemIndex: types.maybeNull(types.number),
});

const UnsavedChangesModel = types.model({
  wpPostStatus: types.maybeNull(types.boolean),
  wpPostType: types.maybeNull(types.boolean),
  wpPostAuthorId: types.maybeNull(types.boolean),
  wpPostCategory: types.maybeNull(types.boolean),
  postParent: types.maybeNull(types.boolean),
  content: types.maybeNull(types.boolean),
});

export const CurrentPage = types
  .model({
    loading: types.optional(types.boolean, false),
    content: types.maybeNull(CurrentPageDataModel),
    countries: types.optional(types.array(CurrentPageModels.countriesModel), []),
    contentLoadedAt: types.maybeNull(types.number),
    isSaving: types.optional(types.boolean, false),
    isExporting: types.optional(types.boolean, false),
    isUploading: types.optional(types.boolean, false),
    isImportError: importErrorType,
    savingError: types.optional(types.boolean, false),
    analytics: types.maybeNull(AnalyticsModel),
    analysis: types.maybeNull(AnalysisModel),
    highlights: types.optional(types.boolean, true),
    shareDialog: ShareDialog,
    pagePermissions: PagePermissionsModel,
    newPage: types.boolean,
    currentStep: types.number,
    error: types.boolean,
    isAnalyzing: types.boolean,
    isProcessingKws: types.boolean,
    isCreateFromScratch: types.boolean,
    isCreateFromUrl: types.boolean,
    isFetchingKwInitData: types.boolean,
    isFetchingTargetKw: types.boolean,
    isFetchingSuggestedKw: types.boolean,
    feTargetKeywords: types.maybeNull(types.array(FeTargetKeywordsModel)),
    isWordCountLoading: types.boolean,
    isTopRankingCompetitorsLoading: types.boolean,
    isReadabilityLoading: types.boolean,
    isScoreLoading: types.boolean,
    isLocationLoading: types.boolean,
    isFirstRepoll: types.boolean,
    isOpenKeywordsDrawer: types.boolean,
    isDrawerFromCa: types.boolean,
    isOpenContentGeneratorDrawer: types.boolean,
    focusTermsExpanded: types.boolean,
    isCompetingFocusTermsDrawerOpen: types.boolean,
    isCompetitorsHeadingsDrawerOpen: types.boolean,
    activeTargetKw: types.maybeNull(types.string),
    isInitialEditorStateFetched: types.boolean,
    showTopNavBar: types.boolean,
    isExportingPage: types.boolean,
    isExportingPageLoader: types.boolean,
    isPublic: types.boolean,
    zoomLevel: types.number,
    isRemovingFocusTerms: types.boolean,
    editorHeightOffset: EditorHeaderOffset,
    isOpeningKwSuggestionsFromContentResearch: types.maybeNull(types.boolean),
    isInitialPageLoad: types.maybeNull(types.boolean),
    isTechnicalsDataLoading: types.maybeNull(types.boolean),
    analyticsCounter: types.maybeNull(types.number),
    analysisCounter: types.maybeNull(types.number),
    suggestionsCounter: types.maybeNull(types.number),
    repollsAllowed: types.maybeNull(types.boolean),
    isFetchingIntLinksSug: types.maybeNull(types.boolean),
    isUpdatingHostname: types.maybeNull(types.boolean),
    sideBarWidth: types.maybeNull(types.string),
    aiTooltip: types.model({
      show: types.maybeNull(types.boolean),
      isLink: types.maybeNull(types.boolean),
      isLinkEdit: types.maybeNull(types.boolean),
      selectedText: types.maybeNull(types.string),
      left: types.maybeNull(types.number),
      right: types.maybeNull(types.number),
      top: types.maybeNull(types.number),
      bottom: types.maybeNull(types.number),
      height: types.maybeNull(types.number),
      width: types.maybeNull(types.number),
      selectionRangeStart: types.maybeNull(types.number),
      selectionRangeEnd: types.maybeNull(types.number),
      urlValue: types.maybeNull(types.string),
      textValue: types.maybeNull(types.string),
      updatedSelection: types.maybeNull(types.frozen()),
    }),
    showAiBanner: types.boolean,
    viewers: types.maybeNull(types.number),
    isSideBarHidden: types.boolean,
    isCopy: types.boolean,
    currentEditorState: types.frozen({}),
    newTextSelection: types.frozen({}),
    focusTermsClassNames: types.frozen({}),
    isEditorFocus: types.maybeNull(types.boolean),
    isFirstEdit: types.boolean,
    isEditorScrolled: types.boolean,
    isExternalTitleEdit: types.boolean,
    focusTermsSorter: FocusTermsSorterModel,
    isUnsplashDrawerOpen: types.boolean,
    isWpSynced: types.boolean,
    isMetaUpdating: types.boolean,
    isBulkRemovingCluster: types.boolean,
    topRankingCompetitorActiveKw: types.maybeNull(types.string),
    termsForInsertion: types.maybeNull(types.array(FocusTermsModel)),
    LinksForInsertion: types.maybeNull(types.array(types.string)),
    termsAssistantSuggestions: types.maybeNull(types.array(TermsAssistantSuggestionsModel)),
    linksAssistantSuggestions: types.maybeNull(types.array(LinkAssistantSuggestionsModel)),
    stagingTaSuggestions: types.maybeNull(types.array(StagingTaSuggestionsModel)),
    stagingLinkSuggestions: types.maybeNull(types.array(StagingLinkSuggestionsModel)),
    taSuggestionsLoading: types.boolean,
    taLinkSuggestionLoading: types.boolean,
    isTaActive: types.boolean,
    isLinkActive: types.boolean,
    taActiveTerm: types.maybeNull(TaActiveTermModel),
    linkActiveTerm: types.maybeNull(TaActiveTermModel),
    highlightedNodeId: types.maybeNull(types.number),
    showOriginalBlockId: types.maybeNull(types.number),
    updatingTa: types.maybeNull(types.boolean),
    loadingCountriesList: types.maybeNull(types.boolean),
    loadingCountryData: types.maybeNull(types.boolean),
    currentLocation: types.maybeNull(types.model({
      location: types.maybeNull(types.string),
    })),
    isHeroImage: types.boolean,
    showCompetitorTermsTableDetail: types.boolean,
    competitorSchemaObject: types.frozen(),
    competitorTitle: types.string,
    competitorUrl: types.string,
    competitorSchemaGraphIdx: types.number,
    isAddingHeroImage: types.boolean,
    isHeroImageLoading: types.boolean,
    copiedHeadings: types.string,
    repollCounnter: types.number,
    articlesInProgress: types.number,
    pageLoading: types.boolean,
    showAddHeroCheck: types.boolean,
    showAddToArticleCheck: types.boolean,
    showCopiedCheck: types.boolean,
    isFocusTermsLoading: types.boolean,
    showAddFocusTermModal: types.boolean,
    contentType: types.string,
    singleArticleSettingsLoading: types.boolean,
    articlesCategoriesList: types.maybeNull(types.array(types.model({
      uuid: types.maybeNull(types.string),
      categories: types.maybeNull(types.array(types.string)),
    }))),
    updatingFolder: types.boolean,
    unsavedChangesInContent: types.maybeNull(UnsavedChangesModel),
  }).views(self => ({
    get getContent() {
      return self?.content;
    },
    get getstagingLinkSuggestions() {
      return toJS(self.stagingLinkSuggestions);
    },
    get getlinksForInsertion() {
      return toJS(self.LinksForInsertion);
    },
    get getCurrentEditorState() {
      return self.currentEditorState;
    },
    get getUniqueKeywords() {
      const uniquKwArra = [];

      if (self.feTargetKeywords?.length) {
        for (let i = 0; i < self.feTargetKeywords.length; i++) {
          if (!uniquKwArra.includes(self.feTargetKeywords[i]?.keyword)) {
            uniquKwArra.push(self.feTargetKeywords[i]?.keyword);
          }
        }
      }

      return uniquKwArra;
    },
  }))
  .actions(self => {
    const setFocusTermsForInsertion = (terms: FocusTermsType[]) => self.termsForInsertion = cast(terms);
    const setLinksForInsertion = (terms: any) => {
      self.LinksForInsertion = cast(terms);
    };
    const setCurrentEditorState = (editorState: EditorState) => {
      self.currentEditorState = cast(editorState);
    };
    const setNewTextSelection = (selection: SelectionState) => {
      self.newTextSelection = cast(selection);
    };
    const setShowAddFocusTermModal = value => {
      self.showAddFocusTermModal = value;
    };

    const setContentType = value => {
      self.contentType = value;
    };

    const setUnsavedChanges = value => {
      self.unsavedChangesInContent = value;
    };

    const setFocusTermsClassNames = focusTerms => {
      const isHighlight = localStorage.getItem('isHighlight');
      if (!isHighlight || isHighlight === 'true') {
        self.highlights = true;
      } else {
        self.highlights = false;
      }
      self.focusTermsClassNames = cast(focusTerms);
    };

    const setFocusTerms = analytics => {
      self.analytics = cast(analytics);
    };

    const setHighlights = highlight => {
      localStorage.setItem('isHighlight', highlight);
      self.highlights = cast(highlight);
    };

    const setIsTaUpdating = (value: boolean) => self.updatingTa = value;

    const setIsEditorFocus = (value: boolean) => self.isEditorFocus = value;
    const setIsTaActive = (value: boolean) => self.isTaActive = value;
    const setIsLinkActive = (value: boolean) => self.isLinkActive = value;
    const setLinkActiveTerm = (term: {class: string; term: string; order: number}) => self.linkActiveTerm = cast(term);
    const setTaActiveTerm = (term: {class: string; term: string; order: number}) => self.taActiveTerm = cast(term);
    const setHighlightedNodeId = (id: number) => self.highlightedNodeId = id;
    const setShowOriginalBlockId = (id: number) => self.showOriginalBlockId = id;
    const setIsFirstArticleEdit = (value: boolean) => self.isFirstEdit = value;
    const setIsEditorScrolled = (value: boolean) => self.isEditorScrolled = value;
    const setIsExternalTitleEdit = (value: boolean) => self.isExternalTitleEdit = value;
    const setFocusTermsSorter = (value: {label: string; key: string}) =>self.focusTermsSorter = value;
    const setIsWpSynced = (value:boolean) => self.isWpSynced = value;
    const setIsMetaLoading = (value:boolean) => self.isMetaUpdating = value;
    const setIsBulkRemovingCluster = (value:boolean) => self.isBulkRemovingCluster = value;
    const setTopRankingCompetitorsActiveKw = (value: string) => self.topRankingCompetitorActiveKw = value;
    const clearFocusTermsClusters = () => {
      if (self.analytics) {
        self.analytics.clusters = cast([]);
      }
    };
    const setCopiedHeadings = value => self.copiedHeadings = value;
    const setRepollCounnter = value => self.repollCounnter = value;
    const setArticlesInProgress = value => self.articlesInProgress = value;

    const checkIfWpSynced = () => {
      if (!self.content.wpSyncedAt) {
        setIsWpSynced(false);
      } else if (self.content.wpSyncedAt && self.content.updatedAt && Date.parse(self.content.wpSyncedAt) < Date.parse(self.content.updatedAt)) {
        setIsWpSynced(false);
      } else {
        setIsWpSynced(true);
      }
    };

    const regenerateImg = flow(function* (img:any) {
      try {
        const resp = yield PAGES_API.getRegernatedImagebyUuid(self.content?.uuid, img);
        return resp;
      } catch (e) {
        return Promise.reject(e);
      }
    });


    const fetchTaSuggestions = flow(function* (focusTerms: string[]) {
      self.taSuggestionsLoading = true;

      try {
        const resp = yield FOCUS_TERMS_API.getTaSuggesions(focusTerms, self.content?.uuid);
        if (resp.isCancel) return;

        self.termsAssistantSuggestions = cast(resp);
        // self.termsAssistantSuggestions = cast(taDummyData);

        const stagingPrepData = [];
        for (let i = 0; i < resp?.length; i++) {
          stagingPrepData.push({...resp[i], status: 'default', itemIndex: i});
        }
        self.stagingTaSuggestions = cast(stagingPrepData);
        // finding the first term that is used for suggestions
        const firstTermWithSuggestion = self.termsForInsertion?.find(term => resp.filter(suggestion => suggestion.termIncluded?.includes(term.name))?.length > 0);

        if (firstTermWithSuggestion) {
          self.taActiveTerm = cast({class: firstTermWithSuggestion.name.replaceAll(' ', '-'), term: firstTermWithSuggestion.name, order: 0});
        }
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Error fetching Terms Assistant Suggestions. Please try again later.',
          permanent: false,
        });
      } finally {
        self.taSuggestionsLoading = false;
      }
    });

    const fetchLinkSuggestions = flow(function* (links: string[]) {
      self.taLinkSuggestionLoading = true;

      try {
        const resp = yield FOCUS_TERMS_API.getLinkSuggesions(links, self.content?.uuid);
        if (resp.isCancel) return;

        self.linksAssistantSuggestions = cast(resp);
        // self.termsAssistantSuggestions = cast(taDummyData);

        const stagingPrepData = [];
        for (let i = 0; i < resp?.length; i++) {
          stagingPrepData.push({...resp[i], status: 'default', itemIndex: i});
        }
        self.stagingLinkSuggestions = cast(stagingPrepData);
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Error fetching Terms Assistant Suggestions. Please try again later.',
          permanent: false,
        });
      } finally {
        self.taSuggestionsLoading = false;
      }
    });

    const setStagingTaSuggestions = (suggestions: StagingTaSuggestionsType[]) => self.stagingTaSuggestions = cast(suggestions);
    const setStagingLinkSuggestions = (linksSuggestion: StagingLinkSuggestionsType[]) => self.stagingLinkSuggestions = cast(linksSuggestion);
    const clearAllTaData = () => {
      setIsTaActive(false);
      setIsLinkActive(false);
      setTaActiveTerm(null);
      setLinkActiveTerm(null);
      setStagingTaSuggestions([]);
      setStagingLinkSuggestions([]);
      self.termsAssistantSuggestions = cast([]);
      self.linksAssistantSuggestions = cast([]);
      self.highlightedNodeId = null;
      self.showOriginalBlockId = null;
    };
    const updateCountriesList = flow(function* (queryParam: string) {
      self.loadingCountriesList = true;
      try {
        const resp = yield OVERVIEW_DETAIL_API.getCountriesList(queryParam);
        if (resp && !resp.isCancel) self.countries = cast(resp);
        if (!resp.isCancel) {
          self.loadingCountriesList = false;
        }
        return resp;
      } catch (e) {
        self.loadingCountriesList = false;
        return Promise.reject(e);
      } finally {
        self.loadingCountriesList = false;
      }
    });
    const getCountryDataUsingCountryCode = flow(function* (queryParam: string) {
      try {
        const resp = yield OVERVIEW_DETAIL_API.getCountriesList(queryParam);
        if (resp && !resp.isCancel && resp.length > 0) {
          return {
            countryCode: resp[0]?.countryCode?.toLowerCase(),
            location: resp[0]?.simpleLocation,
            locationId: Number(resp[0]?.locationId),
          };
        }
      } catch (e) {
        return Promise.reject(e);
      }
    });
    const getCountryData = flow(function* (id) {
      self.loadingCountryData = true;
      try {
        const resp = yield OVERVIEW_DETAIL_API.getCountryData(id);
        if (resp && !resp.isCancel) {
          self.currentLocation = cast(resp);
          return resp;
        }
        if (!resp.isCancel) {
          self.loadingCountryData = false;
        }
      } catch (e) {
        if (e?.response?.data?.locationId) {
          notify.error(e?.response?.data?.locationId, '');
        }
        self.loadingCountryData = false;
        return Promise.reject(e);
      } finally {
        self.loadingCountryData = false;
      }
    });

    const updateHostname = flow(function* (hostname: string) {
      self.isUpdatingHostname = true;
      try {
        const resp = yield CONTENT_ASSISTANT_API.updateHostname(self.content.uuid, hostname);
        if (resp && !resp.isCancel) self.content.hostname = cast(resp.hostname);
        self.isUpdatingHostname = false;
      } catch (e) {
        self.isUpdatingHostname = false;
        return Promise.reject(e);
      }
    });

    const getInternalLinkSuggestions = flow(function* (uuid: string, shouldReproccess: boolean = false) {
      self.isFetchingIntLinksSug = true;
      try {
        const response = yield CONTENT_ASSISTANT_API.getInternalLinkSuggestions(uuid, shouldReproccess);
        if (response.isCancel) return;

        self.content.internalLinkSuggestions = cast(response);
        self.isFetchingIntLinksSug = false;
      } catch (e) {
        self.isFetchingIntLinksSug = false;
        return Promise.reject(e);
      }
    });

    const getInternalLinkSuggestionsv2 = flow(function* (uuid: string, shouldReproccess: boolean = false) {
      self.isFetchingIntLinksSug = true;
      try {
        const response = yield CONTENT_ASSISTANT_API.getInternalLinkSuggestionsv2(uuid, shouldReproccess);
        if (response.isCancel) return;

        self.content.internalLinkSuggestionsV2 = response;
        self.isFetchingIntLinksSug = false;
      } catch (e) {
        self.isFetchingIntLinksSug = false;
        return Promise.reject(e);
      }
    });

    const updateExistingLinkSuggestions = (url: string, action: 'remove' | 'bookmark' = 'remove') => {
      if (!self.content?.internalLinkSuggestionsV2?.allLinks?.length || isNil(url)) return;

      const curLinks = toJS(self.content.internalLinkSuggestionsV2.allLinks);

      const updatedLinks = [];

      curLinks.map(link => {
        if (action == 'remove') {
          if (link.url != url) updatedLinks.push(link);
        }
        if (action == 'bookmark') {
          if (link.url !== url) {
            updatedLinks.push(link);
          } else {
            updatedLinks.push({
              ...link,
              isBookmarked: !link.isBookmarked,
            });
          }

          updatedLinks.sort((a, b) => a.isBookmarked > b.isBookmarked ? -1 : 1);
        }
      });
      self.content.internalLinkSuggestionsV2.allLinks = cast(updatedLinks);
    };

    const removeInternalLinkSuggestion = flow(function* (uuid: string, url: string, kwList: string[]) {
      try {
        yield CONTENT_ASSISTANT_API.deleteInternalLinkSuggestion(uuid, url, kwList);
        updateExistingLinkSuggestions(url, 'remove');
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Could not update link suggestions.',
          desc: 'Try again later',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      }
    });

    const bookmarkInternalLinkSuggestion = flow(function* (uuid: string, url: string) {
      try {
        yield CONTENT_ASSISTANT_API.bookmarkInternalLinkSuggestion(uuid, url);
        updateExistingLinkSuggestions(url, 'bookmark');
      } catch (e) {
        return Promise.reject(e);
      }
    });


    const updatelocationData = (countryCode: string, location: string, locationId: number, reach: number) => {
      self.content.countryCode = countryCode;
      self.content.location = location;
      self.content.locationId = locationId;
      self.content.reach = reach;
    };

    /* We use this action to get keyword suggestions */
    const getKwSuggestions = flow(function* (uuid: string) {
      // this loader is for keyword suggestions section on the screen (small spinenr loader)
      self.isFetchingSuggestedKw = true;

      // Using the counter to show loading banner on the screen
      if (self.suggestionsCounter > 6) {
        self.isInitialPageLoad = true;
      }

      // We are using this as a callback to filtering keyword suggestions to show only unique keywords on the screen
      // this is done to avoid having duplicated keyword suggestions
      const onlyUnique = (value, index, self) => {
        return self.map(item => item.keyword).indexOf(value.keyword) == index;
      };

      try {
        if (uuid || self.content.uuid) {
          const kwSuggestions = yield PAGES_API.getPageKwSuggestionsByUuidV2(uuid || self.content.uuid);
          self.content.clearKwSuggestions();

          const kwSuggestionsArray = [];

          const uniqueArray = kwSuggestions.results.filter(onlyUnique);

          uniqueArray?.filter(suggestKw => {
            if (suggestKw.keyword) {
              if (self.feTargetKeywords && self.feTargetKeywords?.filter(targetKw => targetKw.keyword === suggestKw.keyword).length === 0) {
                kwSuggestionsArray.push(suggestKw);
              } else if (!self.feTargetKeywords || !self.feTargetKeywords?.length) {
                kwSuggestionsArray.push(suggestKw);
              }
            }
          });

          self.content.kwSuggestions = cast(kwSuggestionsArray);

          if (kwSuggestions.shouldRepoll && self.repollsAllowed && self.suggestionsCounter < 40) {
            self.suggestionsCounter = self.suggestionsCounter + 1;
            yield new Promise(r => setTimeout(r, API_POLL_INTERVAL));
            return getKwSuggestions();
          } else {
            if (self.suggestionsCounter >= 60) {
              notify.warning('Something went wrong.', 'We could not fetch latest Keyword Suggestions. If the issue persists, reach us via live chat.');
              self.suggestionsCounter = 0;
            }
            self.isInitialPageLoad = false;
            self.suggestionsCounter = 0;
          }
        }
      } catch (e) {
        handleNetworkError(e);
        self.isInitialPageLoad = false;
        self.suggestionsCounter = 0;
        return Promise.reject(e);
      } finally {
        self.isFetchingSuggestedKw = false;
      }
    });


    // Even tho we are updaing FE keywords array on any action independently from caling the API
    // we still want to update FE keywords array with the BE incomming array
    // This is done so FE keywords get latest SV, CPC and KD values, and also loose loaders.
    // Also this is used to add additional keyeowrds if the BE sends more keywords than we have on the FE
    const updateFeTargetKeywordsFromResponse = (targetKwArray: FeTargetKeywordsType[]) => {
      if (!Array.isArray(targetKwArray)) return;

      targetKwArray?.forEach((item, index) => {
        const itemIndex = self.feTargetKeywords && self.feTargetKeywords?.findIndex(kw => {
          if (JSON.stringify({
            keyword: kw.keyword,
            countryCode: kw.countryCode,
            locationId: kw.locationId,
            location: kw.location,
          }) === JSON.stringify({
            keyword: item.keyword,
            countryCode: item.countryCode,
            locationId: item.locationId,
            location: item.location,
          })) {
            return kw.keyword === item.keyword;
          }
        });

        if (isNil(itemIndex) || isNil(self.feTargetKeywords)) return;
        if (itemIndex !== -1) {
          self.feTargetKeywords[itemIndex] = targetKwArray[index];
        } else {
          self.feTargetKeywords?.push(targetKwArray[index]);
        }
      });
    };

    // This updates FE array of target keywords
    // We are not showing incoming KW from backend directly to speed up the process and have keywords on the screen look like the are adding/removing or updating immediatly
    const updateFeTargetKeywords = (targetKwArray: FeTargetKeywordsType[]) => {
      self.feTargetKeywords = cast(targetKwArray);
    };

    // Look to previous comm.
    const removeSuggestedKeyword = flow(function* (targetKw: string) {
      const kwArray = self.content.kwSuggestions;
      const formatArray = kwArray.filter(kw => kw.keyword !== targetKw);

      yield new Promise(r => setTimeout(r, 300));
      self.content.setKwSuggestions(formatArray);
    });

    // When we ar removing focus terms we are not using BULK but firing api on every remove action
    const removeTerms = flow(function* (term: string) {
      try {
        // overall loader shown in the focus terms section
        self.isRemovingFocusTerms = true;

        const focusTermsResponse = yield FOCUS_TERMS_API.removeTerm(self.content.uuid, term);
        if (focusTermsResponse.isCancel) return;

        // since the api is not returning udpated array of focus terms, we have to manually remove them from the FE after the api was successfull
        // this is done so the FE updates as fast as possible
        const filteredFocusTerms = toJS(self.analytics?.focusTerms).filter(focusTerm => focusTerm.name !== term);
        self.analytics.focusTerms = cast(filteredFocusTerms);

        // after removing focus terms we want to call analytics again to get a fresh set of focus terms + page score
        const analytics = yield PAGES_API.getKeywords(self.content.uuid);
        if (analytics && analytics !== '' && !analytics?.isCancel) {
          self.analytics = cast(analytics);
          if ((analytics?.score || analytics?.score === 0) && (self?.content?.score || self?.content?.score === 0)) {
            self.content.score = analytics?.score;
          }
          self.isRemovingFocusTerms = false;
        }
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Could not remove term.',
          desc: 'Try again later',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      }
    });

    // When we ar removing focus terms we are not using BULK but firing api on every remove action
    const bulkRemoveTerms = flow(function* (terms: string[], removeType: 'cluster' | 'term', clusterTitle: string) {
      try {
        // overall loader shown in the focus terms section
        self.isBulkRemovingCluster = true;
        let response;
        if (removeType === 'cluster') {
          response = yield FOCUS_TERMS_API.bulkRemoveTerms(self.content.uuid, terms);
        } else {
          response = yield FOCUS_TERMS_API.removeTerm(self.content.uuid, terms[0]);
        }

        if (response.isCancel) return;

        // since the api is not returning udpated array of focus terms, we have to manually remove them from the FE after the api was successfull
        // this is done so the FE updates as fast as possible
        if (removeType === 'cluster') {
          const filteredClusters = toJS(self.analytics?.clusters)?.filter(cluster => cluster.title !== clusterTitle);
          self.analytics.clusters = cast(filteredClusters);
          yield new Promise(r => setTimeout(r, 1000));
          setIsBulkRemovingCluster(false);
        } else {
          const clustersArrayNew = [];
          toJS(self.analytics?.clusters).forEach(cluster => {
            if (cluster.title === clusterTitle) {
              clustersArrayNew.push({
                ...cluster,
                focusTerms: cluster.focusTerms.filter(term => !terms.includes(term.name)),
              });
            } else {
              clustersArrayNew.push(cluster);
            }
          });
          self.analytics.clusters = cast(clustersArrayNew);
          self.isBulkRemovingCluster = false;

          const filteredFocusTerms = toJS(self.analytics?.focusTerms).filter(focusTerm => !terms.includes(focusTerm.name));
          self.analytics.focusTerms = cast(filteredFocusTerms);
        }


        // after removing focus terms we want to call analytics again to get a fresh set of focus terms + page score
        const analytics = yield PAGES_API.getKeywords(self.content.uuid);
        if (analytics && analytics !== '' && !analytics?.isCancel) {
          self.analytics = cast(analytics);
          if ((analytics?.score || analytics?.score === 0) && (self?.content?.score || self?.content?.score === 0)) {
            self.content.score = analytics?.score;
          }
        }
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Could not remove terms.',
          desc: 'Try again later.',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      }
    });


    /* This is stripped down version of fetchAnalytics method wich is used for initial page load and keywords updating */
    const fetchDataOnEdit = flow(function* () {
      // We are showing a loading banner if api has repolled more than 4 times
      self.isAnalyzing = true;
      let analytics;
      try {
        if (self.isPublic) {
          analytics = yield CONTENT_ASSISTANT_API.editContentGetData(self.content.uuid, true);
        } else {
          analytics = yield CONTENT_ASSISTANT_API.editContentGetData(self.content.uuid);
        }

        //  if cancel token is used, API will return isCancel prop and we want to prevent trying to populate mobx store in that case
        if (analytics?.isCancel) return;
        // loader is set to true in parent method, and here being set to false
        // so it wont show loaders all the time
        self.isWordCountLoading = false;
        self.isLocationLoading = false;
        self.isScoreLoading = false;
        self.isReadabilityLoading = false;

        // we are mapping focus terms and clusters in order to only update changed props name, status and used count on existing terms
        const focusTermsFormatData = self.analytics?.focusTerms?.length ? toJS(self.analytics?.focusTerms).map((term, termId) => {
          return {
            ...term,
            name: analytics?.focusTerms[termId]?.name,
            status: analytics?.focusTerms[termId]?.status,
            usedCount: analytics?.focusTerms[termId]?.usedCount,
          };
        }) : analytics?.focusTerms;

        const focusTermsClusteredFormatData = [];
        self.analytics?.clusters?.length ? toJS(self.analytics?.clusters).forEach(cluster => {
          const clusterTerms = cluster.focusTerms?.map((term, termId) => {
            return {
              ...term,
              name: analytics?.focusTerms[termId]?.name,
              status: analytics?.focusTerms[termId]?.status,
              usedCount: analytics?.focusTerms[termId]?.usedCount,
            };
          });

          focusTermsClusteredFormatData.push({
            ...cluster,
            focusTerms: clusterTerms,
          });
        }) : analytics?.clusters;

        if (self.analytics?.commonCitations?.length === 0) {
          self.analytics.commonCitations = null;
        }
        const analyticsData = toJS(self.analytics);
        const newAnalyticsData = toJS(analytics);
        const formattData = {
          ...analyticsData,
          focusTerms: focusTermsFormatData,
          clusters: focusTermsClusteredFormatData,
          focusTermsGridData: newAnalyticsData?.focusTermsGridData,
          questions: newAnalyticsData?.questions,
          score: analytics?.score ?? newAnalyticsData?.score,
        };
        self.content.score = analytics?.score ?? analyticsData?.score;

        self.analytics = cast(formattData);
        self.isTopRankingCompetitorsLoading = false;
        // we use isInitialPageLoad prop to show large page loading banner if any endpoint is pending for more than 5s.
        return analytics;
      } catch (e) {
        self.isInitialPageLoad = false;
        useErrorNotification({e, msg: 'Content Assistant stopped working', desc: 'A critical issue caused the tool to stop working. To fix the issue:', permanent: false});
      } finally {
        self.isAnalyzing = false;
      }
    });


    const fetchAnalytics = flow(function* (country?: string, action?: string, shouldRescore = false) {
      self.pageLoading = true;
      // We are showing a loading banner if api has repolled more than 4 times
      if (self.analyticsCounter > 4) {
        self.isInitialPageLoad = true;
      }

      try {
        if (self.content.uuid) {
          self.isAnalyzing = true;
          self.isFetchingKwInitData = true;
          if (action) self.isFetchingTargetKw = true;
          const analytics = yield PAGES_API.getKeywords(self.content.uuid, shouldRescore);


          //  if cancel token is used, API will return isCancel prop and we want to prevent trying to populate mobx store in that case
          if (analytics?.isCancel) return;

          self.content.targetKeywords = cast(analytics?.targetKeywords);

          // loader is set to true in parent method, and here being set to false
          // so it wont show loaders all the time
          self.isWordCountLoading = false;
          self.isLocationLoading = false;
          self.isScoreLoading = false;
          self.isReadabilityLoading = false;

          const successKw = analytics?.targetKeywords?.filter(kw => kw.serpTaskStatus === 'SUCCESS' || kw.serpTaskStatus === 'FAILED' || kw.serpTaskStatus === 'FAILURE' || kw.serpTaskStatus === 'PENDING');
          const formatArray = self.feTargetKeywords ? [...self.feTargetKeywords] : [];

          // If any of the keywords has a status, that means it was updated and we want to update the FE
          if (successKw?.length > 0) {
            // here we are casting everything except focusTerms which should be casted ONLY if it is last repoll.
            const formattedResponse = {
              ...analytics,
              focusTerms: analytics?.focusTerms.length ? analytics?.focusTerms : self.analytics?.focusTerms,
            };
            self.analytics = cast(formattedResponse);
            // score prop + suggestedWordCountLImits were added additionally to keywords-initial-data endpoint
            // the purpose is to update the score and suggested word count limits when u edit target keywords + on initial page load
            self.content.score = analytics?.score;
            self.content.suggestedWordCountLimits = analytics?.suggestedWordCountLimits;
            self.isTopRankingCompetitorsLoading = false;
          }

          // We are doing this check to make sure that we at least one keyword from response with updated status +
          // at least one keyword in frontend array which we use to show keywords on the screen
          // After that chak we loop to see if any of existing keywords are updated or we should add a new one to the array
          if (successKw?.length > 0 && formatArray.length > 0) {
            toJS(successKw).forEach(kw => {
              const itemIndex = toJS(formatArray).findIndex(item => {
                return JSON.stringify({
                  keyword: kw.keyword,
                  countryCode: kw.countryCode || null,
                  locationId: kw.locationId || null,
                  location: kw.location || null,
                }) === JSON.stringify({
                  keyword: toJS(item).keyword,
                  countryCode: toJS(item).countryCode || null,
                  locationId: toJS(item).locationId || null,
                  location: toJS(item).location || null,
                });
              });

              if (itemIndex !== -1) {
                formatArray[itemIndex] = {...kw};
              }
            });

            updateFeTargetKeywords(formatArray);
          }

          // We want to make sure to update frontend array with target keywords on first repoll
          if (self.isFirstRepoll) {
            updateFeTargetKeywordsFromResponse(analytics?.targetKeywords);
          }
          // We have added additional option for repolling, that will turn to false after the editor is closed to prevent continious repolling
          if (analytics?.shouldRepoll && self.repollsAllowed && self.analyticsCounter < 80) {
            self.analyticsCounter = self.analyticsCounter + 1;
            if (!self.isProcessingKws) {
              self.isProcessingKws = true;
            }

            yield new Promise(r => setTimeout(r, API_POLL_INTERVAL));
            self.isFirstRepoll = false;
            return fetchAnalytics(country);
          } else {
            if (self.analyticsCounter >= 80) {
              notify.warning('Something went wrong.', 'We could not fetch latest keyword data. If the issue persists, reach us via live chat.');
              self.analyticsCounter = 0;
            }


            // if we are done repolling we are doing one last data castin + reseting the repoll counter  + setting all important loaders to fasle
            self.analytics = cast(analytics);
            // getInternalLinkSuggestions(self.content.uuid, true);
            getInternalLinkSuggestionsv2(self.content.uuid, true);

            self.analyticsCounter = 0;

            updateFeTargetKeywordsFromResponse(analytics?.targetKeywords);
            self.isAnalyzing = false;
            self.isProcessingKws = false;
            self.isTopRankingCompetitorsLoading = false;

            // we call analysis only if page is created from URL
            if (!self.isCreateFromScratch) {
              yield fetchAnalysis();
            }

            self.isFetchingKwInitData = false;
            self.isFetchingTargetKw = false;

            // we use isInitialPageLoad prop to show large page loading banner if any endpoint is pending for more than 5s.
            self.isInitialPageLoad = false;

            const page = yield PAGES_API.getPageByUuid(self.content.uuid);
            if (!page?.isCancel && page?.scoreCounts && self.content?.scoreCounts) self.content.scoreCounts = cast(page?.scoreCounts);
            if (!page?.isCancel && page?.suggestedWordCount && self.content?.suggestedWordCount) self.content.suggestedWordCount = cast(page?.suggestedWordCount);

            return analytics;
          }
        }
      } catch (e) {
        self.isFetchingKwInitData = false;
        self.isInitialPageLoad = false;
        self.isFetchingTargetKw = false;
        self.analyticsCounter = 0;
        notify.error('Content Assistant stopped working', 'A critical issue caused the tool to stop working. To fix the issue:', false);
      } finally {
        self.pageLoading = false;
      }
    });

    /* Here we are fetching technical data for a page */
    const fetchAnalysis = flow(function* () {
      self.isTechnicalsDataLoading = true;
      // if there are 3 or more repolls we want to show thepage updating banner
      if (self.analysisCounter > 4) {
        self.isInitialPageLoad = true;
      }
      try {
        const data = yield PAGES_API.analysis(self.content.uuid);
        self.content.updatedAt= data.updatedAt;

        if (data?.isCancel) return;

        if (data && data !== '') {
          data.schema.rdfa = JSON.stringify(data.schema.rdfa) === '{}' ? null: JSON.stringify(data.schema.rdfa);
          data.schema.jsonLd = JSON.stringify(data.schema.jsonLd) === '{}' ? null: JSON.stringify(data.schema.jsonLd);
          data.schema.microdata = JSON.stringify(data.schema.microdata) === '{}' ? null: JSON.stringify(data.schema.microdata);
          self.analysis = cast(data);
          // TO DO: REMOVE
          // value was previously received as a function argument
          // isLastRepoll = true;
        }


        if (data?.shouldRepoll && self.repollsAllowed && self.analysisCounter < 40) {
          // we count the number of repolls
          self.analysisCounter = self.analysisCounter + 1;
          yield new Promise(r => setTimeout(r, API_POLL_INTERVAL));
          return fetchAnalysis();
        } else {
          // clean counter if it reaches maximum as well as if it goes through
          if (self.analysisCounter >= 60) {
            notify.warning('Something went wrong.', 'We could not fetch latest technical data. If the issue persists, reach us via live chat.');
            self.analysisCounter = 0;
          }
          self.analysisCounter = 0;

          if (self?.analysis?.score && self.content.score !== self.analysis.score) {
            self.content.score = self.analysis.score;
          }
          // After repoll is done we close the page loading banner
          self.isInitialPageLoad = false;
          self.isTechnicalsDataLoading = false;
          return data;
        }
      } catch (e) {
        self.isInitialPageLoad = false;
        self.isTechnicalsDataLoading = false;
        self.isFetchingSuggestedKw = false;
        self.analysisCounter = 0;
        notify.error('Content Assistant stopped working', 'A critical issue caused the tool to stop working. To fix the issue:', false);
        return Promise.reject(e);
      }
    });


    const refetchPage = flow(function* () {
      self.isInitialPageLoad = true;
      try {
        const res = yield PAGES_API.refetchPage(self.content.uuid);
        fetchAnalysis();
        return res;
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Could not refetch page.',
          desc: 'To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      }
    });

    const addFocusTerms = flow(function* (payload) {
      self.isFocusTermsLoading = true;
      try {
        const res = yield PAGES_API.addFocusTerms(payload, self.content.uuid);
        const terms = yield PAGES_API.getKeywords(self.content.uuid);
        self.analytics.focusTerms = cast(terms?.focusTerms);
        return res;
      } catch (e) {
        self.isFocusTermsLoading = false;
      } finally {
        self.isFocusTermsLoading = false;
      }
    });

    const fetchAnalysisByUrl = flow(function* (pageUrl: string) {
      self.isAnalyzing = true;
      try {
        const data = yield PAGES_API.analysisByUrl(self.content.uuid, pageUrl);
        data.schema = JSON.stringify(data.schema) === '{}' ? null: JSON.stringify(data.schema);
        self.analysis = cast(data);
        const kwSuggestions = yield PAGES_API.getPageKwSuggestionsByUuidV2(self.content.uuid);
        self.content.clearKwSuggestions();

        const kwSuggestionsArray = [];

        kwSuggestions?.filter(suggestKw => {
          if (suggestKw.keyword && self.content.targetKeywords && self.content.targetKeywords.filter(targetKw => targetKw.keyword === suggestKw.keyword).length === 0) {
            kwSuggestionsArray.push(suggestKw);
          }
        });

        self.content.setKwSuggestions(kwSuggestions);
        return data;
      } catch (e) {
        handleNetworkError(e);
        return Promise.reject(e);
      } finally {
        self.isAnalyzing = false;
      }
    });

    const openCompetingFocusTermsDrawer = () => {
      self.isCompetingFocusTermsDrawerOpen = true;
    };

    const closeCompetingFocusTermsDrawer = () => {
      self.isCompetingFocusTermsDrawerOpen = false;
    };
    const openCompetitorsHeadingsDrawer = () => {
      self.isCompetitorsHeadingsDrawerOpen = true;
    };

    const closeCompetitorsHeadingsDrawer = () => {
      self.isCompetitorsHeadingsDrawerOpen = false;
    };

    const setIsOpeningKwSuggestionFromContentResearch = (value: boolean) => {
      self.isOpeningKwSuggestionsFromContentResearch = value;
    };

    const setShowCompetitorTermsTableDetail = (value: boolean, competitorTitle?: string, competitorUrl?: string, competitorSchemaGraphIdx?: number, obj?: any) => {
      self.showCompetitorTermsTableDetail = value;
      if (obj) {
        self.competitorSchemaObject = obj;
        self.competitorTitle = competitorTitle;
        self.competitorUrl = competitorUrl;
        self.competitorSchemaGraphIdx = competitorSchemaGraphIdx;
      }
    };

    return {
      refetchPage,
      fetchAnalytics,
      fetchDataOnEdit,
      fetchAnalysis,
      fetchAnalysisByUrl,
      removeTerms,
      bulkRemoveTerms,
      getKwSuggestions,
      updateFeTargetKeywordsFromResponse,
      updateFeTargetKeywords,
      removeSuggestedKeyword,
      updateCountriesList,
      getCountryData,
      updatelocationData,
      openCompetingFocusTermsDrawer,
      closeCompetingFocusTermsDrawer,
      setIsOpeningKwSuggestionFromContentResearch,
      getInternalLinkSuggestions,
      getInternalLinkSuggestionsv2,
      updateHostname,
      setCurrentEditorState,
      setFocusTermsClassNames,
      clearFocusTermsClusters,
      setIsFirstArticleEdit,
      setNewTextSelection,
      setIsEditorScrolled,
      setIsExternalTitleEdit,
      setFocusTermsSorter,
      checkIfWpSynced,
      setIsMetaLoading,
      setIsBulkRemovingCluster,
      setTopRankingCompetitorsActiveKw,
      removeInternalLinkSuggestion,
      bookmarkInternalLinkSuggestion,
      setFocusTermsForInsertion,
      setLinksForInsertion,
      fetchTaSuggestions,
      regenerateImg,
      fetchLinkSuggestions,
      setIsTaActive,
      setIsLinkActive,
      setIsEditorFocus,
      setTaActiveTerm,
      setLinkActiveTerm,
      setStagingTaSuggestions,
      setStagingLinkSuggestions,
      clearAllTaData,
      setHighlightedNodeId,
      setShowOriginalBlockId,
      setIsTaUpdating,
      openCompetitorsHeadingsDrawer,
      closeCompetitorsHeadingsDrawer,
      setShowCompetitorTermsTableDetail,
      setFocusTerms,
      setHighlights,
      setCopiedHeadings,
      setRepollCounnter,
      setArticlesInProgress,
      getCountryDataUsingCountryCode,
      addFocusTerms,
      setShowAddFocusTermModal,
      setContentType,
      setUnsavedChanges,
    };
  })
  .actions(self => {
    const setNewPage = (isNewPage: boolean) => (self.newPage = isNewPage);
    const setCurrentStep = (step: number) => (self.currentStep = step);
    const setSaving = (isSaving: boolean) => (self.isSaving = isSaving);
    const setExporting = (isExporting: boolean) => (self.isExporting = isExporting);
    const setLoading = (loading: boolean) => (self.loading = loading);
    const setSavingError = (value: boolean) => (self.savingError = value);
    const setImportError = (show: boolean, isCreatedFromScratch?: boolean) => (self.isImportError = {show: show, isCreatedFromScratch: isCreatedFromScratch});
    const setIsCreateFromScratch = (value: boolean) => (self.isCreateFromScratch = value);
    const setIsCreateFromUrl = (value: boolean) => (self.isCreateFromUrl = value);
    const setIsLocationLoading = (value: boolean) => (self.isLocationLoading = value);
    const setIsFetchingSuggestedKw = (value: boolean) => (self.isFetchingSuggestedKw = value);
    const setIsFetchingTargetKw = (value: boolean) => (self.isFetchingTargetKw = value);
    const setIsAnalyzing = (value: boolean) => (self.isAnalyzing = value);
    const setIsTopRankingCompetitorsLoading = (value: boolean) => (self.isTopRankingCompetitorsLoading = value);
    const setIsOpenKeywordDrawer = (value: boolean) => (self.isOpenKeywordsDrawer = value);
    const setIsDrawerFromCa = (value: boolean) => (self.isDrawerFromCa = value);
    const setActiveTargetKw = (value: string) => (self.activeTargetKw = value);
    const setIsOpenContentGeneratorDrawer = (value: boolean) => (self.isOpenContentGeneratorDrawer = value);
    const setIsInitialEditorStateFetched = (value: boolean) => (self.isInitialEditorStateFetched = value);
    const setShowTopNavBar = (value: boolean) => (self.showTopNavBar = value);
    const setIsExportingPage = (value: boolean) => (self.isExportingPage = value);
    const setIsExportingPageLoader = (value: boolean) => (self.isExportingPageLoader = value);
    const setIsPublic = (value: boolean) => (self.isPublic = value);
    const setEditorHeightOffset = (breadcrumbHeight: number, keywordsHeight: number) => {
      self.editorHeightOffset.breadcrumbHeight = breadcrumbHeight;
      self.editorHeightOffset.keywordsHeight = keywordsHeight;
    };
    const setIsReadabilityLoading = (value: boolean) => (self.isReadabilityLoading = value);
    const setIsWordCountLoading = (value: boolean) => (self.isWordCountLoading = value);
    const setIsInitialPageLoad = (value: boolean) => (self.isInitialPageLoad = value);
    const setRepollsAllowed = (value: boolean) => (self.repollsAllowed = value);
    const setFocusTermsExpanded = (value: boolean) => (self.focusTermsExpanded = value);
    const setZoomLevel = (value: number) => (self.zoomLevel = value);
    const clearFeTargetKeywords = () => {
      if (self.feTargetKeywords) {
        self.feTargetKeywords.length = 0;
      }
    };
    const clearTargetKeywords = () => {
      if (self.content.targetKeywords) {
        self.content.targetKeywords.length = 0;
      }
    };
    const clearAnalytics = () => (self.analytics = null);
    const clearAnalysis = () => (self.analysis = null);
    const clearCountries = () => (self.countries.length = 0);
    const setSideBarWidth = (width: string) => self.sideBarWidth = width;
    const setAiTooltip = (tooltip: any) => self.aiTooltip = tooltip;
    const setShowAiBanner = (tooltip: any) => self.showAiBanner = tooltip;
    const setViewers = (viewers: number) => self.viewers = viewers;
    const setIsSideBarHidden = (isSideBarHidden: boolean) => self.isSideBarHidden = isSideBarHidden;
    const setIsCopy = (isCopy: boolean) => self.isCopy = isCopy;
    const clearEditorState = async () => {
      await new Promise(r => setTimeout(r, 1000));
      self.content.cleanEditorState();
    };
    const setHeroImageLoading = (isHeroImageLoading: boolean) => (self.isHeroImageLoading = isHeroImageLoading);
    const setIsUnspashDrawerOpen = (value: boolean) => self.isUnsplashDrawerOpen = value;
    const setShowAddHeroCheck = (value: boolean) => self.showAddHeroCheck = value;
    const setShowAddToArticleCheck = (value: boolean) => self.showAddToArticleCheck = value;
    const setShowCopiedCheck = (value: boolean) => self.showCopiedCheck = value;

    const setAiInProgress = (aiInProgress: boolean) => self.content.aiInProgress = aiInProgress;

    const resetRepollCounters = () => {
      self.analyticsCounter = 0;
      self.analysisCounter = 0;
      self.suggestionsCounter = 0;
    };

    /* Method is used to fetch page info on creation/editing and updating content */
    const fetchPage = flow(function* (uuid: string, action?: string) {
      self.pageLoading = true;
      let page = null;
      try {
        page = yield PAGES_API.getPageByUuid(uuid);
        if (page?.isCancel) return;

        /* Here we set kw suggestions as soon as response comes in, so the user can see kw suggestions asap */
        self.content = cast({
          ...page,
          kwSuggestions: self.content.kwSuggestions,
          internalLinkSuggestions: self.content.internalLinkSuggestions,
        });
        self.isFetchingSuggestedKw = false;
        self.setIsFirstArticleEdit(true);

        /* Call the analysis only if page is created from url because we dont need it for page created from scratch */
        if (!self.isCreateFromScratch) {
          yield self.fetchAnalysis(true);
        }
        self.fetchAnalytics(self.content.countryCode, action);
      } catch (e) {
        self.pageLoading = false;
        return Promise.reject(e);
      } finally {
        self.pageLoading = false;
        /* If page was created from scratch */
        if (self.isCreateFromScratch) {
          self.getKwSuggestions(uuid);
        } else {
          self.isFetchingSuggestedKw = false;
        }
      }
    });

    /* This action is used for updating or editing keywords */
    const editKeywordsAction = flow(function* (keyword: any, action?: string) {
      self.pageLoading = true;
      try {
        let data;

        /* There are different actions calling different APIs, passed as an argument */
        switch (action) {
          case 'add':
            data = yield PAGES_API.editKeywords(self.content.uuid, keyword);
            break;
          case 'edit':
            data = yield PAGES_API.editKeywords(self.content.uuid, keyword);
            break;
          case 'delete':
            data = yield PAGES_API.deleteKeywords(self.content.uuid, keyword);
            break;
          case 'location':
            yield PAGES_API.editPageLocation(self.content.uuid, self.content.countryCode, self.content.location, self.content.locationId);
            break;

          default:
            break;
        }

        if (data?.isCancel) {
          self.pageLoading = false;
        }

        return data;
      } catch (err) {
        self.pageLoading = false;
        /* In case we get 429 status - meaning we are out of quota */
        if (err?.response?.status && err?.response?.status === 429) {
          const rootStore = getRoot(self) as any;
          rootStore.plans.showSidebarPaymentDrawer(null, {title: 'You need more Content Genius focus terms quota to continue', subtitle: '', text: `Current limit: ${rootStore.settings.customer.profile.quotaUtilization?.ca?.allowedFocusTerms?.total} lookups`});
          self.isFetchingSuggestedKw = false;
        } else if (err?.response?.status && err?.response?.status === 406) {
          notify.warning('Total Target Keywords limit reached.', 'You cannot add more than 15 Target Keywords to a single article.');
        } else {
          useErrorNotification({
            e: err,
            msg: 'Content Assistant stopped working',
            desc: 'A critical issue caused the tool to stop working. To fix the issue:',
            permanent: false,
            handleStatuses: [{
              statuses: [401, 403],
              msg: 'Please sign up to edit this article.',
              permanent: false,
              showDetails: false,
            }],
          });
        }
      }
    });

    /* This method is used when importing an article from url */
    const uploadFromUrl = flow(function* (url: string, shouldOverwriteContent?: boolean, renderWithbrowser?: boolean) {
      self.isUploading = true;
      self.isInitialEditorStateFetched = false;
      try {
        // in the API we need to tell the BE if we want to override article content with the latest option from the url
        const resp = yield PAGES_API.uploadFromUrl(self.content.uuid, url, shouldOverwriteContent, renderWithbrowser);
        self.getKwSuggestions();
        // if response returns a status code greater than 399 setImportError to true
        // also we set all loaders to false because we are stopping the rest of the flow
        // also we set isCreatedFromScratch prop true, because that will be the state of the created page
        if (resp.statusCode && resp.statusCode >= 400) {
          self.isInitialEditorStateFetched = true;
          self.isUploading = false;
          self.isFetchingKwInitData = false;
          self.isFetchingSuggestedKw = false;

          setImportError(true, true);
          return;
          // if import is success set fail import notifications to false
        } else if (self.isImportError) {
          setImportError(false, false);
        }

        self.content.setFromResponse(resp);
        self.isCreateFromScratch = false;
        const html = convertFromHTML(resp.cleanedHtml);
        const content = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap);
        self.content.editorState = JSON.stringify(convertToRaw(content));
        Router.push(`/${routes.landingPageOptimizer}/${self.content.uuid}/`, undefined, {shallow: true});

        // Fetch page info after all of the importing is done
        yield fetchPage(self.content.uuid);
        self.isFetchingKwInitData = false;
        self.isUploading = false;
        return resp;
      } catch (e) {
        self.isUploading = false;

        // if response fails setImportError to true
        // also we set all loaders to false because we are stopping the rest of the flow
        // also we set isCreatedFromScratch prop true, because that will be the state of the created page
        self.isFetchingSuggestedKw = false;
        self.isFetchingTargetKw = false;
        setImportError(true, true);
        useErrorNotification({
          e,
          msg: 'Could not import from URL.',
          desc: 'To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        // we are updating the router to remove newPage parameter, that will allow the editor to render
        // this is because now we are showing import error and still allowing user to add data manually if import fails
        Router.push(`/${routes.landingPageOptimizer}/${self.content.uuid}/`, undefined, {shallow: true});
        return Promise.reject(e);
      } finally {
        // we don't want to set this value to true earlier, as it will cause infinite editor loop
        self.isInitialEditorStateFetched = true;
      }
    });

    const updateArticleData = (editorState, cleanedHtml) => {
      self.content.editorState = editorState;
      self.content.cleanedHtml = cleanedHtml;
    };

    const updateArticleStatus = flow(function* (status: string, uuid?:string) {
      try {
        if (self.content.uuid || uuid) {
          const resp = yield PAGES_API.articleStatus(self.content.uuid || uuid, status);
          return resp;
        }
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Error updating article status',
          desc: 'Please try again later.',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      }
    });

    const updateArticleStatusV2 = flow(function* (status: string, uuid?:string) {
      try {
        if (uuid) {
          const resp = yield PAGES_API.editPageStatus(uuid, status);
          return resp;
        }
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Error updating article status',
          desc: 'Please try again later.',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      }
    });

    const updateViewers = flow(function* () {
      try {
        if (self.content.uuid) {
          const viewers = yield PAGES_API.getViewers(self.content.uuid);
          self.content.viewers = cast(viewers);
          return viewers;
        }
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const setUpdatingFolder = (loading: boolean) => (self.updatingFolder = loading);

    return {
      updateArticleStatus,
      fetchPage,
      setLoading,
      setSaving,
      setSavingError,
      setImportError,
      setExporting,
      editKeywordsAction,
      uploadFromUrl,
      updateViewers,
      setNewPage,
      setCurrentStep,
      setIsCreateFromScratch,
      setIsCreateFromUrl,
      clearFeTargetKeywords,
      clearTargetKeywords,
      clearAnalytics,
      clearAnalysis,
      setIsLocationLoading,
      setIsTopRankingCompetitorsLoading,
      setIsFetchingSuggestedKw,
      setIsFetchingTargetKw,
      setIsAnalyzing,
      setIsOpenKeywordDrawer,
      setIsDrawerFromCa,
      setActiveTargetKw,
      setIsOpenContentGeneratorDrawer,
      setIsUnspashDrawerOpen,
      clearCountries,
      clearEditorState,
      setFocusTermsExpanded,
      setIsInitialEditorStateFetched,
      setShowTopNavBar,
      setIsExportingPage,
      setIsExportingPageLoader,
      setIsPublic,
      setZoomLevel,
      setEditorHeightOffset,
      setIsReadabilityLoading,
      setIsInitialPageLoad,
      setIsWordCountLoading,
      setRepollsAllowed,
      setSideBarWidth,
      setAiTooltip,
      setViewers,
      setIsSideBarHidden,
      setIsCopy,
      resetRepollCounters,
      setShowAiBanner,
      updateArticleStatusV2,
      updateArticleData,
      setHeroImageLoading,
      setAiInProgress,
      setShowAddHeroCheck,
      setShowAddToArticleCheck,
      setShowCopiedCheck,
      setUpdatingFolder,
    };
  })
  .actions(self => {
    /* This method starts the flow and gets all data of a page when we open an existing page */
    const loadPage = flow(function* (uuid: string, reload: boolean=true) {
      /*
      * We set all the loading states to appropriate state
      * Some states are set to false immediately so they don't show loader on initial load
      * We update their loading states through other actions
      */
      if (reload) {
        self.setIsInitialPageLoad(true);
        self.isFirstRepoll = true;
        self.setLoading(true);
      }
      self.isFetchingTargetKw = true;
      self.setIsWordCountLoading(true);
      self.isLocationLoading = false;
      self.isTopRankingCompetitorsLoading = true;
      self.setIsReadabilityLoading(true);
      self.isScoreLoading = true;
      self.isInitialEditorStateFetched = false;

      try {
        if (!self.isPublic) {
          yield self.pagePermissions.fetchPagePermissions(uuid);
        }
        const page = yield PAGES_API.getPageByUuid(uuid);
        if (page?.isCancel) return;
        if (!self.isFirstEdit && self.content.status != 'NOT_BEGUN') {
          self.setIsFirstArticleEdit(true);
        }

        // self.content = cast(prepContent);
        self.content = cast(page);
        self.isInitialEditorStateFetched = true;
        // self.getInternalLinkSuggestions(uuid, true);
        self.getInternalLinkSuggestionsv2(uuid, true);

        // if page is imported from url, change isCreatedFromScratch to false
        if (self.content.importedFromUrl) {
          self.isCreateFromScratch = false;
        } else {
          self.isCreateFromScratch = true;
        }
        self.getKwSuggestions();

        if (page.targetKeywords) self.updateFeTargetKeywordsFromResponse(page.targetKeywords);

        /* Readability and score are comming with getPage response, therefore we can hide loaders */
        self.setIsReadabilityLoading(false);
        self.isScoreLoading = false;

        /* If there is content in the editor, we fetch analysis initially */
        if (page?.importedFromUrl && !self.isCreateFromScratch) {
          self.fetchAnalysis();
        }

        /*
        * Analytics endpoint also includes analysis but only when all the keyword-initial-data flow is done
        * We want to make sure that analysis trigers at the start and the end of the process
        */
        self.fetchAnalytics(null, null, !page.score);
      } catch (e) {
        if (e?.response?.status !== 520) {
          self.setIsInitialPageLoad(false);
          useErrorNotification({
            e,
            msg: 'Error loading Content Assistant',
            desc: 'The Content Assistant data did not load properly. To fix the issue:',
            permanent: false,
            handleStatuses: [
              {
                statuses: [404],
                msg: 'You are not able to access this article.',
              },
            ],
          });
          return Promise.reject(e);
        }
      } finally {
        /* This loading prop is used only on couple of places and can be set to false regardles of api success */
        self.setLoading(false);
      }
    });

    const returnSingleArticleSettings = flow(function* (uuid: string) {
      self.singleArticleSettingsLoading = true;
      try {
        const articleSettings = yield PAGES_API.getPageByUuid(uuid);
        return articleSettings;
      } catch (e) {
        if (e?.response?.status !== 520) {
          notify.error('Error loading Content Assistant', 'The Content Assistant data did not load properly. To fix the issue:', false);
          return Promise.reject(e);
        }
      } finally {
        self.singleArticleSettingsLoading = false;
      }
    });

    const setArticlesCategoriesList = list => {
      self.articlesCategoriesList = list;
    };

    /* This method starts the flow and gets all data of a page when we first create a page */
    const initialLoadPage = flow(function* (uuid: string) {
      self.setLoading(true);
      self.isLocationLoading = false;
      self.isFirstRepoll = true;
      try {
        yield self.pagePermissions.fetchPagePermissions(uuid);
        const page = yield PAGES_API.getPageByUuid(uuid);
        if (page?.isCancel) return;
        self.content = cast(page);
      } catch (e) {
        if (e?.response?.status !== 520) {
          notify.error('Error loading Content Assistant', 'The Content Assistant data did not load properly. To fix the issue:', false);
          return Promise.reject(e);
        }
      } finally {
        self.isInitialEditorStateFetched = true;
        self.setLoading(false);
        self.setIsWordCountLoading(false);
        self.isTopRankingCompetitorsLoading = false;
        self.setIsReadabilityLoading(false);
        self.isScoreLoading = false;
      }
    });

    const editTitle = async (title: string) => {
      if (!self.isSaving) {
        self.setSaving(true);
        try {
          const resp = await PAGES_API.editTitle(self.content.uuid, title, self.content.version);
          self.getKwSuggestions();
          self.content.setFromResponse(resp);
          self.setSavingError(false);
        } catch (e) {
          self.setSavingError(true);
          return Promise.reject(e);
        } finally {
          self.setSaving(false);
        }
      }
    };

    const updateHeroImage = async (heroImage: string | null, heroImageAltText: string | null) => {
      if (!self.isHeroImageLoading) {
        self.setHeroImageLoading(true);
        try {
          const resp = await PAGES_API.updateHeroImage(self.content.uuid, heroImage, heroImageAltText, self.content.version);
          self.content.setFromResponse(resp);
          self.setSavingError(false);
        } catch (e) {
          self.setSavingError(true);
          return Promise.reject(e);
        } finally {
          self.setHeroImageLoading(false);
          self.setShowAddHeroCheck(true);
        }
      }
    };

    const updateMetaData = async (metaData: {metaTitle?: string; metaDesc?: string}) => {
      self.setIsMetaLoading(true);
      try {
        const resp = await PAGES_API.editMeta(self.content.uuid, metaData);
        if (resp.isCancel) return;
        self.content.setFromResponse(resp);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.setIsMetaLoading(false);
      }
    };

    const editContentUpdateText = flow(function* (editorState: EditorState) {
      const contentState = editorState.getCurrentContent();
      const rawJson = JSON.stringify(convertToRaw(contentState));

      self.setIsReadabilityLoading(true);
      self.setIsWordCountLoading(true);
      self.setSaving(true);

      try {
        const resp = yield PAGES_API.editPageContent(self.content.uuid, rawJson, self.content.version, true);
        self.setIsReadabilityLoading(false);
        self.setIsWordCountLoading(false);

        // if page is from scrach, we want to generate kwSuggestion from content.
        if (self.isCreateFromScratch) {
          self.getKwSuggestions();
        }

        if (!self.isFirstEdit && resp.status != 'NOT_BEGUN') {
          self.setIsFirstArticleEdit(true);
        }

        if (resp.isCancel) return;
        // self.getInternalLinkSuggestions(self.content.uuid, true);
        self.getInternalLinkSuggestionsv2(self.content.uuid, true);
        self.content.setFromResponse(resp);

        // set possible importing error to false, if content is updated
        if (self.isImportError) {
          self.setImportError(false, false);
        }
        yield self.fetchDataOnEdit();
        self.setSavingError(false);
        // TO DO: Remove
        // self.getKwSuggestions(self.content.uuid);
        return resp;
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Content Assistant stopped working',
          desc: 'A critical issue caused the tool to stop working. To fix the issue:',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        self.setSavingError(true);
        return Promise.reject(e);
      } finally {
        self.setSaving(false);
      }
    });

    const editContent = flow(function* (editorState: EditorState, isNewDecorations: boolean = false, isManualUpdate: boolean = false, isCleanedHtmlUpdated: boolean = true) {
      if (self.isCreateFromScratch || self.isCreateFromUrl) {
        self.isFetchingSuggestedKw = false;
        self.isFetchingKwInitData = false;
      }


      const contentState = editorState.getCurrentContent();
      const rawContent = editorState.getCurrentContent().getPlainText();
      const rawJson = JSON.stringify(convertToRaw(contentState));

      const lastChangedBlockKey = editorState.getSelection()?.getAnchorKey();
      const lastChangedBlock = contentState.getBlockForKey(lastChangedBlockKey);
      const lastChangedBlockType = lastChangedBlock.getType();

      if (rawContent !== self.content?.editorText || isNewDecorations || lastChangedBlockType === 'atomic' || isManualUpdate) {
        self.setIsReadabilityLoading(true);
        self.setIsWordCountLoading(true);
        self.setSaving(true);

        try {
          const resp = yield PAGES_API.editPageContent(self.content.uuid, rawJson, self.content.version, isCleanedHtmlUpdated);
          self.setIsReadabilityLoading(false);
          self.setIsWordCountLoading(false);

          // if page is from scrach, we want to generate kwSuggestion from content.
          if (self.isCreateFromScratch) {
            self.getKwSuggestions();
          }

          if (!self.isFirstEdit && resp.status != 'NOT_BEGUN') {
            self.setIsFirstArticleEdit(true);
          }

          if (resp.isCancel) return;
          // self.getInternalLinkSuggestions(self.content.uuid, true);
          self.getInternalLinkSuggestionsv2(self.content.uuid, true);
          self.content.setFromResponse(resp);

          // set possible importing error to false, if content is updated
          if (self.isImportError) {
            self.setImportError(false, false);
          }
          yield self.fetchDataOnEdit();
          self.setSavingError(false);
          // TO DO: Remove
          // self.getKwSuggestions(self.content.uuid);
          return resp;
        } catch (e) {
          useErrorNotification({
            e,
            msg: 'Content Assistant stopped working',
            desc: 'A critical issue caused the tool to stop working. To fix the issue:',
            permanent: false,
            handleStatuses: [{
              statuses: [401, 403],
              msg: 'Please sign up to edit this article.',
              permanent: false,
              showDetails: false,
            }],
          });
          self.setSavingError(true);
          return Promise.reject(e);
        } finally {
          self.setSaving(false);
        }
      }
    });

    const smallPageLoad = flow(function* (uuid) {
      try {
        const page = yield PAGES_API.getPageByUuid(uuid);
        if (page.isCancel) return;
        self.content.shopifyArticleId = page.shopifyArticleId;
        self.content.shopifyBlogId = page.shopifyBlogId;
        self.content.shopifyContentType = page.shopifyContentType;
        self.content.shopifyContentUrl = page.shopifyContentUrl;
        self.content.shopifyPageId = page.shopifyPageId;
        self.content.shopifySyncedAt = page.shopifySyncedAt;
        self.content.isCleanedHtmlUpdated = page.isCleanedHtmlUpdated;
        if (page.shopifySynced) self.content.shopifySynced = page.shopifySynced;
        self.content.importedFromUrl = page.importedFromUrl;
        self.content.updatedAt = page.updatedAt;
        self.content.wpPublishedAtUrl = page.wpPublishedAtUrl;
        self.content.wpPostCategories = page.wpPostCategories;
        self.content.wpPostStatus = page.wpPostStatus;
        self.content.wpPostType = page.wpPostType;
        self.content.wpSyncedAt = page.wpSyncedAt;
        self.content.wpPostAuthorId = page.wpPostAuthorId;
        self.content.wpPostParent = page.wpPostParent;
        self.content.defaultWpWebsiteId = page.defaultWpWebsiteId;
        self.content.wpPublishedAtWebsiteId = page.wpPublishedAtWebsiteId;
        if (page.wpSynced) self.content.wpSynced = page.wpSynced;
        if (page?.scoreCounts && self.content?.scoreCounts) self.content.scoreCounts = cast(page?.scoreCounts);
        if (!page?.isCancel && page?.suggestedWordCount && self.content?.suggestedWordCount) self.content.suggestedWordCount = cast(page?.suggestedWordCount);
        // self.checkIfWpSynced();
      } catch (e) {
        return Promise.reject(e);
      }
    });

    interface keywordInterface {
      keyword: string;
      countryCode?: string;
      location?: string;
      locationId?: number;
      id?: number;
    }

    type actionType = 'add' | 'edit' | 'delete' | 'location';

    const editKeywords = flow(function* (keyword: keywordInterface, action: actionType = 'add') {
      // if we are only updating location, there is no need to call the entire flow
      // only change loading state for location
      if (action === 'location') {
        self.isLocationLoading = true;
        yield self.editKeywordsAction(keyword, action);

        self.isLocationLoading = false;
        return;
      }
      self.isFirstRepoll = false;
      self.isFetchingSuggestedKw = true;
      self.setSaving(true);
      self.isAnalyzing = true;
      self.isProcessingKws = true;
      self.isTopRankingCompetitorsLoading = true;
      self.isScoreLoading = true;
      try {
        const resp = yield self.editKeywordsAction(keyword, action);

        self.setIsFirstArticleEdit(true);
        // self.getInternalLinkSuggestions(self.content.uuid, true);

        self.getKwSuggestions(self.content.uuid);

        yield self.fetchPage(self.content.uuid, action);

        return resp;
      } catch (err) {
        useErrorNotification(
          {
            e: err,
            msg: 'Could not create target keyword.',
            desc: 'Please try again later.',
            permanent: false,
            handleStatuses: [{
              statuses: [406],
              msg: 'Could not create target keyword..',
              desc: err?.response?.data?.keywordsList,
              permanent: false,
              showDetails: false,
            }],
          });
        return Promise.reject(err);
      } finally {
        self.setSaving(false);
      }
    });


    const editKeywordsSA = flow(function* (keywords: []) {
      if (!self.loading && !self.isSaving) {
        self.setSaving(true);
        self.isAnalyzing = true;
        try {
          const resp = yield self.editKeywordsAction(keywords);
          yield self.fetchPage(self.content.uuid);
          self.isAnalyzing = false;
          return resp;
        } catch (err) {
          return Promise.reject(err);
        } finally {
          self.isAnalyzing = false;
          self.setSaving(false);
        }
      }
    });

    const updateContentLoadedAt = async () => {
      self.contentLoadedAt = new Date().getTime();
    };

    const exportToGoogledoc = async () => {
      try {
        if (self.content.uuid) {
          self.setExporting(true);
          const googledoc = await PAGES_API.exportToGoogledoc(self.content.uuid);
          return googledoc;
        }
      } catch (e) {
        notification.error({message: 'Unable to export.'});
      } finally {
        self.setExporting(false);
      }
    };

    const exportToGoogleSheet = async id => {
      try {
        if (id) {
          const googleSheet = await PAGES_API.exportToGoogleSheet(id);
          return googleSheet;
        }
      } catch (e) {
        notification.error({message: 'Unable to export.'});
      }
    };

    return {
      editTitle,
      editContent,
      loadPage,
      initialLoadPage,
      editKeywords,
      updateContentLoadedAt,
      exportToGoogledoc,
      exportToGoogleSheet,
      editKeywordsSA,
      smallPageLoad,
      updateMetaData,
      editContentUpdateText,
      updateHeroImage,
      returnSingleArticleSettings,
      setArticlesCategoriesList,
    };
  });

export type CurrentPageStore = Instance<typeof CurrentPage>;
export const initCurrentPage = () => {
  return CurrentPage.create({
    loading: true,
    loadingCountriesList: false,
    loadingCountryData: false,
    copiedHeadings: '',
    repollCounnter: 0,
    articlesInProgress: 0,
    pageLoading: false,
    updatingFolder: false,
    shareDialog: {
      isOpen: false,
    },
    pagePermissions: {
      editableToUsers: [],
      viewableToUsers: [],
      isEditableByAnyone: false,
      isViewableByAnyone: false,
      error: false,
      loading: false,
    },
    content: {
      url: '',
      uuid: '',
      hostname: '',
      createdByUser: null,
      targetKeywords: [],
      kwSuggestions: [],
      title: '',
      importUrl: '',
      importFromUrl: '',
      requiredWordCount: null,
      version: '',
      maxSerpPosition: 0,
      textPreview: '',
      createdAt: '',
      updatedAt: '',
      wordCount: null,
      suggestedWordCount: null,
      readability: '',
      averageReadability: '',
      score: null,
      viewers: null,
      html: '',
      editorState: '',
      headingLevel: null,
      isAnalyzing: null,
      analyzingError: false,
      countryCode: '',
      location: '',
      locationId: null,
      reach: null,
      internalLinkSuggestions: [],
      internalLinkSuggestionsV2: null,
      status: '',
      metaTitle: '',
      metaDesc: '',
      isCleanedHtmlUpdated: false,
    },
    countries: [],
    savingError: false,
    isSaving: false,
    isExporting: false,
    isUploading: false,
    isImportError: {
      show: false,
      isCreatedFromScratch: false,
    },
    analytics: null,
    newPage: false,
    currentStep: 1,
    error: false,
    isAnalyzing: false,
    isProcessingKws: false,
    isCreateFromScratch: true,
    isCreateFromUrl: false,
    isFetchingKwInitData: true,
    isFetchingTargetKw: true,
    isFetchingSuggestedKw: true,
    feTargetKeywords: [],
    isWordCountLoading: true,
    isTopRankingCompetitorsLoading: true,
    isReadabilityLoading: true,
    isScoreLoading: true,
    isLocationLoading: true,
    isFirstRepoll: true,
    isOpenKeywordsDrawer: false,
    isDrawerFromCa: false,
    isOpenContentGeneratorDrawer: false,
    focusTermsExpanded: false,
    isCompetingFocusTermsDrawerOpen: false,
    activeTargetKw: null,
    isInitialEditorStateFetched: false,
    showTopNavBar: false,
    isExportingPage: false,
    isExportingPageLoader: false,
    isPublic: false,
    zoomLevel: 1,
    isRemovingFocusTerms: false,
    editorHeightOffset: {
      breadcrumbHeight: 100,
      keywordsHeight: 240,
    },
    isOpeningKwSuggestionsFromContentResearch: false,
    isInitialPageLoad: false,
    isTechnicalsDataLoading: false,
    analyticsCounter: 0,
    analysisCounter: 0,
    suggestionsCounter: 0,
    repollsAllowed: true,
    isFetchingIntLinksSug: false,
    isUpdatingHostname: false,
    sideBarWidth: '350px',
    aiTooltip: {
      show: false,
      isLink: false,
      isLinkEdit: false,
      selectedText: '',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      height: 0,
      width: 0,
      selectionRangeStart: 0,
      selectionRangeEnd: 0,
      urlValue: '',
      textValue: '',
      updatedSelection: null,
    },
    showAiBanner: false,
    viewers: 0,
    isSideBarHidden: false,
    isCopy: false,
    currentEditorState: {},
    isFirstEdit: true,
    isEditorScrolled: false,
    isExternalTitleEdit: false,
    focusTermsSorter: {
      label: 'Importance',
      key: 'normalizedScore',
    },
    isUnsplashDrawerOpen: false,
    isWpSynced: false,
    isMetaUpdating: false,
    isBulkRemovingCluster: false,
    topRankingCompetitorActiveKw: '',
    termsForInsertion: [],
    LinksForInsertion: [],
    taSuggestionsLoading: false,
    taLinkSuggestionLoading: false,
    isTaActive: false,
    isLinkActive: false,
    isCompetitorsHeadingsDrawerOpen: false,
    isHeroImage: false,
    showCompetitorTermsTableDetail: false,
    competitorTitle: '',
    competitorUrl: '',
    competitorSchemaGraphIdx: 0,
    isAddingHeroImage: false,
    isHeroImageLoading: false,
    showAddHeroCheck: false,
    showAddToArticleCheck: false,
    showCopiedCheck: false,
    isFocusTermsLoading: false,
    showAddFocusTermModal: false,
    contentType: 'toArticle',
    singleArticleSettingsLoading: false,
    unsavedChangesInContent: {
      wpPostStatus: false,
      wpPostType: false,
      wpPostAuthorId: false,
      wpPostCategory: false,
      postParent: false,
      content: false,
    },
  });
};
