import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react-lite';
import {Modal} from '@/components/common-components/components';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {addProtocolToDomain, getSingleUrlParam, isValidDomain, findValidDomain, removeProtocolAndPrefix} from '@/utils/url';
import {faCircle, faCircleCheck, faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {Button} from '@/components/common-components/v2/Button';
import {faEnvelope} from '@fortawesome/pro-light-svg-icons';
import {openUrl} from '@/utils/router';
import {Form, Spin, Switch, Tabs} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {NextImg} from '@/utils/nextImg';
import AiSettings from './aiSettings';
import {copyToClipBoard} from '@/utils/clipboard';
import CrawlSettings from './crawlSettings';
import OTTOSettings from './ottoSettings';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
import {openLiveChat} from '@/utils/intercom';
import {faBoxArchive, faExclamationTriangle} from '@fortawesome/pro-duotone-svg-icons';
import {FreezeBanner} from '../../otto-page-detail/Component/ottoHeaderV2';
import {APIKeysSection} from './apikeys';
import {Footer, UpdateAiSettings} from './styledComponents';
import {notification} from '@/utils/notification-v2';
import {Button as Button2} from 'antd';

const {TabPane} = Tabs;
interface AiSettingsForm {
  llm_provider: string;
  language_code: string;
}
interface Props {
  isVisible: boolean;
  currentProject?: any;
  setIsVisible: (value: boolean) => void;
  instructionsData: {
    domainToOpen: string;
    uuid?: string;
    page?: string;
  };
  setInstructionsData: ({domainToOpen}) => void;
}
export const SettingsModal:React.FC<Props> = observer(({isVisible, setIsVisible, instructionsData, currentProject}) => {
  const {
    ottoV2Store: {
      scriptStatusLoading,
      gscScriptStatusLoading,
      loadOttoV2Projects,
      checkScriptFromInstance,
      getGSCVerificationScript,
      gscSiteVerificationScriptVerification,
      ottoV2Project,
      loadOttoV2Project,
      getOttoV2Project,
      loadingProject,
      setLoadingProject,
      updateOttoV2Project,
    },
    settings: {customer: {profile: {whitelabelOtto, isLinkgraph}}},
  } = useStore('');
  const [crawlSettingsForm] = Form.useForm();
  const isPublicHash = getSingleUrlParam('public_hash');
  const [loading, setLoading] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const domain = getSingleUrlParam('domain') || instructionsData?.domainToOpen;
  const [form] = Form.useForm<AiSettingsForm>();
  const [swichDisable, setswichDisable] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      form.resetFields();
      setLoading(false);
      setConfirmationVisible(false);
      setswichDisable(false);
    }
  }, [isVisible]);
  useEffect(() => {
    if (isVisible && !swichDisable ) {
      form.setFieldsValue({
        'llm_provider': ottoV2Project?.llmProvider ?? 'openai',
        'language_code': ottoV2Project?.languageCode ?? null,
      });
      if (getOttoV2Project) {
        setWildFire(getOttoV2Project?.experimentalFeaturesToggles?.wildFire ?? false);
        setswichDisable(true);
      }
    }
  }, [isVisible, getOttoV2Project]);

  useEffect(() => {
    setWidgetStatus(-1);
    setGscWidgetStatus(-1);
    if (isVisible) {
      checkScript();
      checkGSCScript();
      if (instructionsData?.page !== 'detail-page') loadOttoV2Project(instructionsData?.uuid || uuid);
    }
  }, [isVisible]);

  const uuid = getSingleUrlParam('uuid');
  const [widgetStatus, setWidgetStatus] = useState(-1);
  // const projectImg = <NextImg
  //   style={{height: '24px', width: '24px', borderRadius: '4px'}}
  //   src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${instructionsData?.domainToOpen && getDomain((!/^(https?:)?\/\//i.test(instructionsData?.domainToOpen)) ? 'https:' + instructionsData?.domainToOpen : instructionsData?.domainToOpen)}&size=64`}
  // />;
  const [gscWidgetStatus, setGscWidgetStatus] = useState(-1);
  const [gscSiteVerificationScript, setGscSiteVerificationScript] = useState(null);
  const [wildFire, setWildFire] = useState(false);
  const [activeKey, setActiveKey] = useState(isLinkgraph ? 'otto_settings' : 'otto_integration');

  const checkScript = async () => {
    setLoadingProject(true);
    if (instructionsData?.uuid || uuid) {
      const response = await checkScriptFromInstance(instructionsData?.uuid || uuid);
      if (response?.pixelState === 'installed') {
        setWidgetStatus(1);
      } else if (response?.pixelState === 'not_installed') {
        setWidgetStatus(2);
      } else if (response?.pixelState === 'wrong_uuid') {
        setWidgetStatus(3);
      }
      if (instructionsData?.page == 'detail-page') {
        loadOttoV2Project(uuid, false);
      } else {
        loadOttoV2Projects(true);
      }
    }
  };
  const url = `https://www.google.com/s2/favicons?sz=64&domain_url=${addProtocolToDomain(domain)}`;

  const capitalizeFirstLetter = string => {
    if (string) {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    } else return '-';
  };

  const checkGSCScript = async () => {
    if (instructionsData?.uuid || uuid) {
      const response = await getGSCVerificationScript(instructionsData?.uuid || uuid);
      if (response?.isVerified) {
        setGscWidgetStatus(1);
      } else {
        setGscWidgetStatus(2);
      }
    }
  };

  const checkGSCScriptVerification = async () => {
    if (instructionsData?.uuid || uuid) {
      const response = await gscSiteVerificationScriptVerification(instructionsData?.uuid || uuid);
      if (response) {
        setGscSiteVerificationScript(response);
        setGscWidgetStatus(1);
      } else {
        setGscWidgetStatus(2);
      }
      checkGSCScript();
    }
  };

  const updateWildFireSettings = async () => {
    try {
      await setConfirmationVisible(false);
      await setLoading(true);
      const payload = {
        'experimental_features_toggles': {
          'wild_fire': wildFire,
        },
      };
      await updateOttoV2Project(
        getOttoV2Project?.uuid,
        payload,
      ).then(() => {
        notification.success('Success', 'Settings updated successfully');
      });
      await loadOttoV2Project(getOttoV2Project?.uuid);
    } catch {
      notification.error('Error', 'Failed to update settings');
    } finally {
      setLoading(false);
      setIsVisible(false);
    }
  };

  return (
    <>
      {!confirmationVisible ? <StyledModal
        destroyOnClose
        width={isLinkgraph ? '960px' : '500px'}
        closable={true}
        onClose={() => {
          if (isLinkgraph) {
            localStorage.setItem('TOOLTIP_SHOWN', 'true');
          }
          crawlSettingsForm.resetFields();
          setIsVisible(false);
        }}
        closeIcon={<div style={{padding: '10px'}}><FontAwesomeIcon color={'#121212'} fontSize={22} icon={faXmark}/></div>}
        visible={isVisible}
        style={{top: isLinkgraph ? 0 : 50, marginTop: isLinkgraph ? '10px' : '', marginBottom: isLinkgraph ? '10px' : ''}}
      >
        {getOttoV2Project?.isFrozen ? <FreezeBanner style={{marginInline: 'auto', width: '92%'}}>
          <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
          <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
              The changes on this site are frozen. You will be unable to make any modifications.
          </div>
        </FreezeBanner> : ''}
        <Domain>
          <NextImg src={isValidDomain(domain) ? url : `https://www.google.com/s2/favicons?sz=64&domain_url=${findValidDomain(domain)?.includes('.com') ? findValidDomain(domain) : `${findValidDomain(domain)}.com`}`}
            alt={domain} height={24} width={24} style={{borderRadius: '5px'}} />
          {capitalizeFirstLetter(removeProtocolAndPrefix(domain))}
        </Domain>
        <Tabs className='modal-tabs' defaultActiveKey={isLinkgraph ? 'otto_settings' : 'otto_integration'} activeKey={activeKey} onChange={key => setActiveKey(key)}>
          {!isLinkgraph && <TabPane tab='AI Writing' key={'ai_settings'}>
            <AiSettings setOpenModel={setIsVisible} form={form}/>
          </TabPane>}
          {!isLinkgraph && <TabPane tab='Crawl Settings' key={'crawl_settings'}>
            <CrawlSettings data={getOttoV2Project} form={crawlSettingsForm} selectedProject={currentProject} setOpenModel={setIsVisible} />
          </TabPane>}
          {isLinkgraph && <TabPane tab={`${whitelabelOtto} Settings`} key={'otto_settings'}>
            <OTTOSettings instructionsDataUuid={instructionsData?.uuid} setModalVisible={setIsVisible} />
          </TabPane>}
          <TabPane tab={`${whitelabelOtto} Pixel Integration`} key={'otto_integration'}>
            <div className='content-wrapper'>
              <ScriptTitle>
                <FontAwesomeIcon icon={faCircle} fontSize={6} color='#2D6CCA' style={{marginLeft: 10, marginTop: 6}}/>
                <div className='script-title-text link' onClick={() => openUrl('https://searchatlas.com/otto-pixel/', '_blank')}>Read the Installation guide</div>
              </ScriptTitle>
              <ScriptTitle>
                <FontAwesomeIcon icon={faCircle} fontSize={6} color='#121212' style={{marginLeft: 10, marginTop: 6}}/>
                <div className='script-title-text'>The script should be accessible on all pages of your site. This script is compatible with all <b>JavaScript frameworks and libraries</b>, as well as platforms like <b>WordPress</b> and <b>Shopify</b>. This can be achieved by adding it to the header of your template.</div>
              </ScriptTitle>
              <ScriptWrapper>
                <div style={{marginBottom: '10px', overflowWrap: 'break-word', fontSize: 12}}>
                  {loadingProject && !ottoV2Project?.pixelHtml ? (
                    <div style={{fontSize: 14}} className='banner-text'>Loading script <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} /></div>
                  ) : ottoV2Project?.pixelHtml}
                </div>
                {ottoV2Project?.pixelHtml && <div className={'snippet-buttons'}>
                  <Button background='#121212' textColor='#FFFFFF' onClick={()=>copyToClipBoard(ottoV2Project?.pixelHtml)}>Copy</Button>
                  <FreezeWrapper>
                    <a
                      className={'email-button'}
                      href={`mailto:?subject=Install this Widget on my site&body=Installation guide: https://searchatlas.com/otto-pixel/ \n${ottoV2Project?.pixelHtml}`}
                    >
                      <FontAwesomeIcon color={'#2D6CCA'} fontSize={16} icon={faEnvelope} />Email my developer
                    </a>
                  </FreezeWrapper>
                </div>}
              </ScriptWrapper>
              {
                widgetStatus === -1 && scriptStatusLoading && <Banner style={{backgroundColor: '#F2F2F5', marginBottom: '20px'}}>
                  <div className='banner-text'>{`Loading ${whitelabelOtto} pixel script status`} <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} /></div>
                </Banner>
              }
              {widgetStatus === 2 && <Banner style={{marginBottom: '20px'}}>
                <FontAwesomeIcon icon={faCircleExclamation} fontSize={16} color='#F44343'/>
                <div className='banner-text'>{`${whitelabelOtto} SEO Pixel is not Installed.`} <span onClick={checkScript}>Check status</span>{scriptStatusLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} />}</div>
              </Banner>}

              {widgetStatus === 3 && <Banner style={{marginBottom: '20px'}}>
                <FontAwesomeIcon icon={faCircleExclamation} fontSize={16} color='#F44343'/>
                <div className='banner-text'>{`${whitelabelOtto} SEO Pixel was not installed correctly. Copy & Paste the script to your website again.`}
                  <span onClick={checkScript}>Check status</span>{scriptStatusLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} />}</div>
              </Banner>}

              {widgetStatus === 1 && <Banner style={{backgroundColor: 'rgba(42, 193, 85, 0.15)', marginBottom: '20px'}}>
                <FontAwesomeIcon icon={faCircleCheck} fontSize={16} color='#2AC155'/>
                <div className={'text'}>{`${whitelabelOtto} SEO Pixel was Installed Correctly!`}</div>
              </Banner>}
              {gscSiteVerificationScript?.isVerified && <ScriptWrapper>
                <div style={{marginBottom: '10px', overflowWrap: 'break-word', fontSize: 12}}>
                  {gscSiteVerificationScript?.isVerified}
                </div>
                <div className={'snippet-buttons'}>
                  <Button background='#121212' textColor='#FFFFFF' onClick={()=>copyToClipBoard(gscSiteVerificationScript?.isVerified)}>Copy</Button>
                  <FreezeWrapper>
                    <a
                      className={'email-button'}
                      href={`mailto:?subject=Install this Widget on my site&body=Installation guide: https://searchatlas.com/otto-pixel/ \n${ottoV2Project?.pixelHtml}`}
                    >
                      <FontAwesomeIcon color={'#2D6CCA'} fontSize={16} icon={faEnvelope} />Email my developer
                    </a>
                  </FreezeWrapper>
                </div>
              </ScriptWrapper>}
              {
                gscWidgetStatus === -1 && gscScriptStatusLoading && <Banner style={{backgroundColor: '#F2F2F5', marginBottom: '20px'}}>
                  <div className='banner-text'>Loading GSC Token script status <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} /></div>
                </Banner>
              }
              {gscSiteVerificationScript?.isVerified && gscWidgetStatus === 2 && <Banner style={{marginBottom: '20px'}}>
                <FontAwesomeIcon icon={faCircleExclamation} fontSize={16} color='#F44343'/>
                <div className='banner-text'>GSC Verification Token is not Installed. <span onClick={checkGSCScriptVerification}>Check status</span>{gscScriptStatusLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, display: 'flex', alignItems: 'center'}} spin />} />}</div>
              </Banner>}

              {gscSiteVerificationScript?.isVerified && gscWidgetStatus === 1 && <Banner style={{backgroundColor: 'rgba(42, 193, 85, 0.15)', marginBottom: '20px'}}>
                <FontAwesomeIcon icon={faCircleCheck} fontSize={16} color='#2AC155'/>
                <div className={'text'}>{`${whitelabelOtto} SEO Pixel was Installed Correctly!`}</div>
              </Banner>}
              <LiveChatLinkWrapper>
                <FontAwesomeIcon icon={faCircle} fontSize={6} color='#121212' style={{marginLeft: 10, marginTop: 6}}/>
                <div className='live-chat-text-wrapper'>Tried everything and nothing works?&nbsp;{!isPublicHash && <div>Reach us via <span onClick={openLiveChat}>live chat</span>.</div>}</div>
              </LiveChatLinkWrapper>
            </div>
          </TabPane>
          <TabPane tab='Experimental Hub' key={'experimental_hub'}>
            <Wrapper>
              <div className='heading'>Experimental Hub Access</div>
              <div className='content'>
                <div className='section'>
                  <div className='description'>Unlock cutting-edge features and stay ahead of the competition. Enable experimental tools crafted with our unique approach, designed to give you an edge.</div>
                </div>
                <div className='section'>
                  <div className='switch-container'>
                    <div className='label'>
                      WILDFIRE:
                    </div>
                    <Switch className='switch' disabled={!swichDisable || loading} checked={wildFire} onChange={e => setWildFire(e)}/> {wildFire ? 'Active' : 'Inactive'}
                  </div>
                </div>

              </div>
              <Footer style={{width: '100%'}}>
                <button className='cancel-button' type='button' onClick={() => {
                  setIsVisible(false);
                }}>Cancel</button>
                <FreezeWrapper>
                  <div>
                    <UpdateAiSettings loading={loading} onClick={() => {
                      if (wildFire) {
                        updateWildFireSettings();
                      } else {
                        setConfirmationVisible(true);
                      }
                    }}>
                        Update Settings
                    </UpdateAiSettings>
                  </div>
                </FreezeWrapper>
              </Footer>
            </Wrapper>
          </TabPane>
          <TabPane tab='API Keys' key={'api_keys'}>
            <APIKeysSection setModalVisible={setIsVisible} />
          </TabPane>
        </Tabs>
      </StyledModal> :
        <ConfirmationModalStyled
          visible={confirmationVisible}
          width={700}
          onClose={() => {
            setConfirmationVisible(false);
            setWildFire(true);
            setswichDisable(false);
          }}
        >
          <div>
            <FontAwesomeIcon icon={faExclamationTriangle} color={'#F44343'} fontSize={34}/>
            <div style={{fontSize: 24, fontWeight: 600, marginTop: 15}}>Opt out of OTTO Community Backlinks Program</div>
            <div>All incoming and outgoing backlinks between other OTTO partners and your site will be stopped.</div>
            <div style={{marginTop: 30, display: 'flex', gap: 5}}>
              <StyledButton isCancel={false} onClick={updateWildFireSettings}>Cancel and Delete all Backlinks</StyledButton>
              <StyledButton isCancel={true} onClick={() => {
                setConfirmationVisible(false);
                setWildFire(true);
              }}>Cancel</StyledButton>
            </div>
          </div>
        </ConfirmationModalStyled>}
    </>
  );
});

const ConfirmationModalStyled = styled(Modal)`
  .rc-dialog-close {
    color: #fff;
    right: -44px;
    top: -25px;
    font-size: 40px;
    opacity: 1 !important;
    font-weight: 200 !important; 
  }
`;


const StyledButton = styled(Button2)<{isCancel: boolean}>`
  background-color: ${p => p.isCancel ? 'transparent' : '#F44343'} !important;
  padding: 13px 30px 14px 30px;
  border-radius: 8px;
  border: unset;
  box-shadow: unset;
  color: ${p => p.isCancel ? 'black' : 'white'} !important;
  height: 44px;
  &:hover {
    color: ${p => p.isCancel ? 'black' : 'white'} !important;
    background-color: ${p => p.isCancel ? 'transparent' : '#F44343'} !important;
  }
`;

const StyledModal = styled(Modal)`
  .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      border-radius: 10px;
      overflow: hidden !important;
      padding: 23px 18px !important;
      .content-wrapper {
        margin-top: 8px;
      }
    }
    .rc-dialog-close {
    font-size: 21px;
    right: 8px;
    top: 8px;
    font-weight: 700;
    opacity: 1 !important;
    }
  }
  .modal-tabs {
          .ant-tabs-nav {
            margin: 0 0 8px 0 !important;
            &::before {
              border-bottom: 1px solid #D9D9D9 !important;
            }
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab-active {
                  .ant-tabs-tab-btn {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #121212;
                  }
                }
                .ant-tabs-ink-bar {
                  background-color: #2D6CCA !important;
                  height: 5px !important;
                  border-radius: 4px 4px 0px 0px !important;
                }
                .ant-tabs-tab {
                  .ant-tabs-tab-btn {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #4E5156 !important;
                  }
                  &:hover {
                    color: #4E5156 !important;
                  }
                }
              }
            }
          }
          .ant-tabs-content-holder {
            .ant-tabs-content {
              .ant-tabs-tabpane {
                .input-wrapper {
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  .label {
                    font-family: 'Inter', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    color: #4E5156;
                  }
                  .ant-col {
                    .text-area {
                      height: 75px;
                      border: 1px solid #D2D2D2;
                      border-radius: 8px;
                      resize: none;
                      &:hover {
                        border: 1px solid #D2D2D2 !important;
                      }
                    }
                    .email-input {
                      height: 38px;
                      border: 1px solid #D2D2D2;
                      border-radius: 8px;
                    }
                    .react-tel-input {
                      .support-phone-input {
                        width: 100% !important;
                        height: 38px !important;
                        border-radius: 8px;

                      }
                      .flag-dropdown {
                        border-radius: 8px 0 0 8px !important;
                      }
                    }  
                  }
                }
              }
            }
          }
        }
`;

const ScriptTitle = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 11px;

  .script-title-text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
  }
  .link {
    color: #2D6CCA;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ScriptWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  margin-left: 30px;
  background-color: #F2F2F5;
  padding: 14px 12px;
  margin-bottom: 10px;
  .snippet-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    button {
      margin: 0px !important;
      border-radius: 8px !important;
    }
    .email-button {
      display: flex;
      align-items: center;
      gap: 6px; color: #2D6CCA;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const OpenLinkWrapper = styled(ScriptTitle)`
   margin-bottom: 13px;
  .link-text-wrapper {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
    word-break: break-all;
    
    span {
      color: #2D6CCA;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const LiveChatLinkWrapper = styled(OpenLinkWrapper)`
  margin-bottom: 18px;

  .live-chat-text-wrapper {
    display: flex;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 18px;

    span {
      color: #2D6CCA;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Banner = styled.div`
  padding: 11px 15px;
  border-radius: 8px;
  background-color: rgba(244, 67, 67, 0.15);
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 6px;

  .banner-text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;

    span {
      color: #2D6CCA;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Domain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(18, 18, 18, 1);
`;

const Wrapper = styled.div`
  .content {
    display: flex;
  }
  .section {
    width: 100%;
    font-size: 12px;
    line-height: 14.52px;
  }
  .heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    margin-bottom: 3px;
    margin-top: 30px;
  }
  .description {
    color: #4E5156;
    width: 70%;
  }
  .switch-container {
    display: flex;
    margin-bottom: 5px;
  }
  .label {
    width: 160px;
  }
  .ant-switch {
    height: 18px !important;
    margin-right: 3px;
    width: 32px;
    min-width: 32px;
  }
  .ant-switch-handle {
    height: 14px;
    width: 14px;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 0px) !important;
  }
  .ant-switch-checked {
    background-color: #18923B !important;
  }
`;
