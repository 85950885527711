import moment from 'moment';

export const getDateDifference = (start: string, end: string) => {
  const startDate = moment(start, 'YYYY-MM-DD');
  const endDate = moment(end, 'YYYY-MM-DD');


  return moment.duration(endDate.diff(startDate)).asMonths();
};

export const getDaysDifference = (start: string, end: string) => {
  const startDate = moment(start, 'YYYY-MM-DD');
  const endDate = moment(end, 'YYYY-MM-DD');

  return moment.duration(endDate.diff(startDate)).asDays();
};

export const getDefaultInterval = (start: string, end: string) => {
  const difference = getDateDifference(start, end);

  if (difference >= 180) {
    return 500;
  } if (difference >= 40) {
    return 250;
  }
  if (difference >= 24) {
    return 89;
  }
  if (difference <= 1) {
    return 0;
  }
  if (difference < 6) {
    return 14;
  }
  if (difference < 12) {
    return 29;
  }
  if (difference >= 12) {
    return 58;
  }
};
