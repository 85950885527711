import {getApiUrl, getAuthHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {notification} from '@/utils/notification-v2';

class QuestApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');

  public async loadQuestList(params) {
    try {
      const {data} = await this.axios.get(`${QuestApi.baseUrl}/v3/quest-results/`,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
          params: params,
        });
      return data;
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode==500) {
        notification.error('Error', 'Server Error 500');
      }
      return Promise.reject(error);
    }
  }

  public async getQuestDetail(id) {
    try {
      const {data} = await this.axios.get(`${QuestApi.baseUrl}/v3/quest-results/${id}/`,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode==500) {
        notification.error('Error', 'Server Error 500');
      }
      return Promise.reject(error);
    }
  }
  public async deleteQuest(id) {
    try {
      const {data} = await this.axios.delete(`${QuestApi.baseUrl}/v3/quest-results/${id}/`,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode==500) {
        notification.error('Error', 'Server Error 500');
      }
      return Promise.reject(error);
    }
  }

  public async createQuest(payload) {
    try {
      const response = await this.axios.post(`${QuestApi.baseUrl}/v3/quest-results/`, payload,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode==500) {
        notification.error('Error', 'Server Error 500');
      }
      return Promise.reject(error);
    }
  }
}
export const QUEST_API = new QuestApi();
