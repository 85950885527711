import {Modal, Button} from '@/components/common-components/components';
import styles from './style.module.scss';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import React from 'react';

interface PendingInvoiceProps {
    visible?: boolean;
    closable?: boolean;
}
export const ProjectActiveModal: React.FC<PendingInvoiceProps> = observer(({
  visible,
  closable,
}) => {
  const {
    plans: {
      hideActiveProjectPopup,
      getSelectedDomainForUpgrade,
      getSelectedDomainIdForUpgrade,
    },
    settings: {customer: {profile, getCustomerQuota}},
    navBar: {activateSite, loadSiteProperties, setActivateIds, loading},
  } = useStore('');
  const closeModal = () => {
    hideActiveProjectPopup();
  };

  const modalTemplate = (
    <div>
      <div className={styles.SummaryContainer}>

        <p>You’re about to activate <strong>{getSelectedDomainForUpgrade}</strong> in GSC Insights. All of the Google Search <br/>
          Console data for this property will be added to your dashboard.</p>
      </div>
    </div>
  );

  const modalTitle = (
    <div className={styles.ModalHeader}>
      <h2>Only {profile.quotaUtilization?.gsc?.allowedActiveProjects?.total - profile.quotaUtilization?.gsc?.allowedActiveProjects?.consumed} GSC property left for tracking</h2>
    </div>
  );

  const modalFooter = (
    <div className={styles.footer}>
      <Button className={styles.cancel} onClick={closeModal}>Cancel</Button>
      <Button
        className={styles.active}
        loading={loading}
        onClick={async ()=>{
          setActivateIds(getSelectedDomainIdForUpgrade);
          await activateSite(getSelectedDomainIdForUpgrade);
          await loadSiteProperties();
          getCustomerQuota();
          closeModal();
        }}>{!loading && `Activate ${getSelectedDomainForUpgrade}`}</Button>
    </div>
  );

  return (
    <Modal
      onClose={closeModal}
      title={modalTitle}
      className={styles.activeProject}
      footer={modalFooter}
      visible={visible}
      closable={closable} >
      {modalTemplate}
    </Modal>
  );
});
