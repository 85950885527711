import {Button} from '@/components/common-components/v2/Button';
import {Input, Select} from 'antd';
import styled from 'styled-components';
import {Checkbox} from '@/components/common-components/v2/Checkbox';

export const StyledSelect = styled(Select)`
 width: 125px;
 height: fit-content;
 .ant-select-selector {
    max-width:180px !important;
    border-color: 1px solid #E8E8E8 !important;
    height: 32px !important;
    cursor: pointer !important;
    line-height: unset !important;
    border-radius: 0;
  }
  .ant-select-arrow {
    height: -webkit-fill-available;
  }
`;

export const StyledSelectDiv = styled.div`
 height: fit-content;
 .ant-select-selector {
    max-width:180px !important;
    border-color: 1px solid #E8E8E8 !important;
    height: 32px !important;
    cursor: pointer !important;
    line-height: unset !important;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
  .ant-select-selection-item {
    margin-top: -5px;
  }
  .ant-select-selection-search-input {
    margin-top: -5px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  .ant-select-dropdown {
    border-radius: 10px;
  }
  .rc-virtual-list-holder-inner {
    .ant-select-item:last-child {
      box-shadow: 0px -1px 0px #D3D3D3;
    }
  }
`;

export const InputStyled = styled(Input)`
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  height: 32px !important;
  width: 330px;
  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4E5156;
  }
  @media screen and (max-width: 440px) {
    width: 270px;
  }
  @media screen and (max-width: 381px) {
    width: 240px;
  }
`;

export const ButtonStyled = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #fff;
  cursor: pointer;
  font-size: 14;
  height: 32px !important;
  margin-left: 0;
`;

export const GrayText = styled.div<{color?: string}>`
  color: ${p=> p?.color ? p?.color : '#a3a4a4'};
`;

export const OptionContent = styled.div`
  border-color: #fff !important;
  max-width: 140px;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight:400;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0px;
`;

export const CheckboxStyled = styled(Checkbox)`
color: #4E5156;
.ant-checkbox {
  .ant-checkbox-inner {
    background-color: #fff;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #7F4EAD;
  }
}`;
