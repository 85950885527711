import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {Input} from 'antd';

export const ActivateWhiteLabelWrapper = styled.div`
  width: 530px;
  background: #303236;
  padding: 35px 20px;
  color: #E8E8E8;
  border-radius: 12px;
`;

export const Heading = styled.div`
  color: white;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;

export const Points = styled.div`
  margin-top: 5px;
`;

export const SinglePoint = styled.div``;

export const PointText = styled.span`
  margin-left: 7px;
`;

export const StepOne = styled.div`
  margin-top: 18px;
`;

export const StepOneHeading = styled.p`
  color: #E8E8E8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
`;

export const StepOneDesc = styled.p`
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const UrlInput = styled(Input)`
  width: 480px;
  height: 38px;
  flex-shrink: 0;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #4E5054;
  background: #4E5054;
  outline: none;
  padding-left: 10px;
  &:disabled {
    background-color: #4E5054 !important;
    border-color: #4E5054 !important;
  }
`;

export const ImgSize = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #E8E8E8;
`;

export const DragText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #E8E8E8;
`;

export const InputContainer = styled.div`
  margin-top: 15px;
  flex-grow: 1;
  &:hover {
    .dragImageHover {
      border-color: #2D6CCA;
      color: #2D6CCA;
    }
    .dragSubtextHover {
      color: #2D6CCA;
    }
  }
  .ant-upload-btn {
    padding: 0 !important;
  }
  .ant-upload-drag {
    background: transparent !important;
    border: 0 !important;
  }
`;

export const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  padding: 10px 5px;
  background: #4E5054;
  border: 1px solid #4E5054;
  border-radius: 5px;
  img {
    height: 100%;
    width: 100%;
  }
  svg {
    height: 100%;
    width: 90%;
  }
`;

export const DragRightContainer = styled.div`
  flex-grow: 1;
  text-align: left;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StepTwo = styled.div`
  margin-top: 22px;

`;

export const InstructionBanner = styled.div`
  width: fit-content;
  height: 32px;
  padding-right: 15px;
  flex-shrink: 0;
  border-radius: 6px;
  background: rgba(255, 133, 54, 0.15);
  display: flex;
  align-items: center;
`;

export const InstructionBannerTxt = styled.span`
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px !important;
`;

export const List = styled.ul`
  margin-top: 5px;
  margin-left: -10px;
  margin-bottom: 0px !important;
`;

export const Value = styled.span`
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-left: 5px;
`;

export const ListDesc = styled.p`
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 30px;
  padding-right: 37px;
`;

export const Footer = styled.div`
  margin-top: 28px;
`;

export const SubmitButton = styled(Button)`
  width: 174px;
  height: 44px;
  display: flex;
  padding: 13px 25px 14px 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #2D6CCA;
  font-weight: 500;
  color: white;
  &&:hover{
    background: #2D6CCA !important;
  }
  &&:disabled{
    background: #2D6CCA !important;
  }
  &&:focus{
    background: #2D6CCA !important;
  }
`;

export const WaitingBanner = styled.div`
  width: 530px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(52, 174, 243, 0.15);
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const BannerText = styled.span`
  color: white;
  margin: 7px 11px;
`;

export const Domain = styled.span`
color: #34AEF3;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px;
text-decoration-line: underline;
cursor: pointer;
`;

export const LogoName = styled.div`
  margin-left: 16px;
`;

export const Pill = styled.div`
  display: inline;
  background: #4E5156;
  border-radius: 34px;
  color: #fff;
  padding: 2px 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-left: 3px;
`;

export const TooltipSubmitButton = styled(SubmitButton)``;
