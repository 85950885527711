// TODO: Refactor this to use Antd, and fix typings/confusing default exports.
import classnames from 'classnames';
import RcTabs, {TabPaneProps, TabsProps as TabsPropsRc} from 'rc-tabs';
import {TabBarExtraContent} from 'rc-tabs/lib/interface';
import 'rc-tabs/assets/index.css';
import styles from './style.module.scss';
import React from 'react';


interface TabsProps extends TabsPropsRc {
  header?: React.ReactElement<any>;
  tabBarExtraContent?: TabBarExtraContent;
}

export const Tabs: React.FC<TabsProps> = ({
  className,
  header,
  tabBarExtraContent,
  ...props
}) => {
  return (
    <div className={classnames(styles.tabsContainer, className)}>
      {
        header ?
          <div className={styles.header}> {header} </div> :
          null
      }
      <RcTabs {...props} className={classnames(styles.tabs)} tabBarExtraContent={tabBarExtraContent}>
        {props.children}
      </RcTabs>
    </div>
  );
};


export const TabPane: React.FC<TabPaneProps> = ({
  className,
  ...props
}) => {
  return (
    <RcTabs.TabPane {...props} className={classnames(styles.tabPane, className)}>
      {props.children}
    </RcTabs.TabPane>
  );
};
