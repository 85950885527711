import {cast, flow, types} from 'mobx-state-tree';
import {CREATE_TASKS_API} from '@/api/content-optimizer';
import {toJS} from 'mobx';

const CreateTasksModel = types.model({
  id: types.maybeNull(types.number),
  taskName: types.maybeNull(types.string),
  pageUrl: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  currentStatus: types.maybeNull(types.string),
  priority: types.maybeNull(types.string),
  assignedTo: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
});

export const CreateTasks = types.model({
  loading: types.boolean,
  tasks: types.maybeNull(types.array(CreateTasksModel)),
}).views(self => ({
  get getAllTasks() {
    return toJS(self.tasks);
  },

})).actions(self => {
  const loadTasks = flow(function* () {
    self.loading = true;
    try {
      const response = yield CREATE_TASKS_API.getTasks();
      if (response) {
        self.tasks = cast(response);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  const updateTask = flow(function* (id: number, taskData: {
    taskName?: string;
    pageUrl?: string;
    dueDate?: string;
    currentStatus?: string;
    priority?: string;
    assignedTo?: string;
    comments?: string;
  }) {
    self.loading = true;
    let data = null;
    try {
      data = yield CREATE_TASKS_API.updateTask(id, taskData);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
    return data;
  });

  const createTask = flow(function* (taskData: {
    taskName?: string;
    pageUrl?: string;
    dueDate?: string;
    currentStatus?: string;
    priority?: string;
    assignedTo?: string;
    comments?: string;
  }) {
    self.loading = true;
    let data = null;
    try {
      data = yield CREATE_TASKS_API.createTask(taskData);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
    return data;
  });

  const deleteTask = flow(function* (id: number) {
    self.loading = true;
    let data = null;
    try {
      data = yield CREATE_TASKS_API.deleteTask(id);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
    return data;
  });

  return {
    loadTasks,
    updateTask,
    deleteTask,
    createTask,
  };
});

export function initCreateTasks() {
  return CreateTasks.create({
    loading: false,
  });
}

