import Head from 'next/head';
// import {useTranslation} from 'next-i18next';
import React from 'react';
import {getLocation} from '@/utils/string';

type HeadTitleProps = {
  children: string;
  hideHeadTitle?: boolean;
};

export const HeadTitle: React.FC<HeadTitleProps> = ({
  children,
  hideHeadTitle,
}) => {
  // const {t} = useTranslation('common');

  return (
    <Head>
      <title>{children} {!hideHeadTitle ? `| ${getLocation(location)}` : null}</title>
    </Head>
  );
};

