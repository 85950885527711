import {AUDITED_SITES_API_V2} from '@/api/SiteAuditorV2';
import {notification} from '@/utils/notification-v2';
import {types, cast, flow} from 'mobx-state-tree';
import {toJS} from 'mobx';
import {getSingleUrlParam} from '@/utils/url';

const alertsListModel = types.model({
  label: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  numOfPages: types.maybeNull(types.number),
  isDomainLevel: types.maybeNull(types.boolean),
  isActive: types.maybeNull(types.boolean),
  numOfReversals: types.maybeNull(types.number),
});

const userSettingsAlertsListModel = types.model({
  key: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  isIndividualAlert: types.maybeNull(types.boolean),
});
const alertDrawerModel = types.model({
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  changes: types.maybeNull(types.frozen({})),
  importance: types.maybeNull(types.number),
  sslExpiresAt: types.maybeNull(types.string),
  isReverted: types.maybeNull(types.boolean),
});

const alertsParamsModel = types.model({
  page: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
});

export const AlertsPage = types.model({
  alertsList: types.maybeNull(types.array(alertsListModel)),
  alertsListCount: types.maybeNull(types.number),
  userSettingsAlertsList: types.maybeNull(types.array(userSettingsAlertsListModel)),
  loadingAlertsList: types.maybeNull(types.boolean),
  loadingAlertDrawerData: types.maybeNull(types.boolean),
  alertDrawerData: types.maybeNull(types.array(alertDrawerModel)),
  updatedList: types.maybeNull(types.string),
  alertsParams: types.maybeNull(alertsParamsModel),
}).views(self => ({
  get getAlertsList() {
    return toJS(self.alertsList);
  },
  get getAlertDrawerData() {
    return toJS(self.alertDrawerData);
  },
  get getUserSettingsAlertsList() {
    return toJS(self.userSettingsAlertsList);
  },
})).actions(self => {
  const loadAlertsList = flow(function* (auditId, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingAlertsList = true;
    }
    self.alertsList = cast([]);
    const payload = {
      audit: auditId,
      page: self.alertsParams.page,
      page_size: self.alertsParams.pageSize,
      ordering: self.alertsParams.ordering,
      search: self.alertsParams.search,
    };
    try {
      const response = yield AUDITED_SITES_API_V2.getAlertsList(payload, publicHash);
      if (!response?.isCancel) {
        self.alertsListCount = response?.count;
        self.alertsList = cast(response?.results);
        self.loadingAlertsList = false;
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    }
  });
  const setAlertDetailDrawerData = flow(function* (auditId, alertID, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingAlertDrawerData = true;
    }
    try {
      const response = yield AUDITED_SITES_API_V2.getAlertDetailDrawerData(auditId, alertID, publicHash);
      if (response) {
        self.alertDrawerData = cast(response);
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingAlertDrawerData = false;
    }
  });

  const setAlertsParams = (data, noApiCall=false) => {
    self.alertsParams.search = data?.search || '';
    self.alertsParams.page = data?.page;
    self.alertsParams.pageSize = data?.pageSize;
    self.alertsParams.ordering = data?.ordering;
    if (!noApiCall) {
      loadAlertsList(data?.auditId);
    }
  };


  return {
    loadAlertsList,
    setAlertDetailDrawerData,
    setAlertsParams,
  };
});

export function initAlertsPage() {
  return AlertsPage.create({
    loadingAlertsList: true,
    updatedList: '',
    loadingAlertDrawerData: true,
    alertsParams: {
      page: 1,
      pageSize: 50,
      ordering: '',
      search: '',
    },
    // userSettingsList: [],
  });
}
