import {Select, Switch} from 'antd';
import styled from 'styled-components';
import {opaqueColor} from '@/utils/colors';
import {CommonInputStyled} from '../../../settings';
import {LargeBadge} from '@/components/common-components/components/badge';

interface ImagesProps {
  imagesModel?: string;
  setImagesModel?: (value: string) => void;
  imagesCheck: boolean;
  setImagesCheck?: (value: boolean) => void;
  generateHeroImageCheck?: boolean;
  setGenerateHeroImageCheck?: (value: boolean) => void;
  setImagesAspectRatio?: (value:any) => void;
  imagesAspectRatio?: string;
  setImagesAdditionalContext?: (value: string) => void;
  imagesAdditionalContext?: string;
}

export const Images = ({
  imagesCheck,
  setImagesCheck,
  generateHeroImageCheck,
  setGenerateHeroImageCheck,
  setImagesAspectRatio,
  imagesAspectRatio,
  setImagesAdditionalContext,
  imagesAdditionalContext,
}: ImagesProps) => {
  const {Option} = Select;

  const updateCheckBox = (type: string, checked: boolean) => {
    switch (type) {
      case 'generate_images':
        setImagesCheck(checked);
        break;
      case 'generate_hero_image':
        setGenerateHeroImageCheck(checked);
        break;
    }
  };

  const aspectRatioOptions = [{
    name: 'Article image (8:3)',
    value: '8:3',
  }, {
    name: 'Wide screen image (16:9)',
    value: '16:9',
  }, {
    name: 'Square image (1:1)',
    value: '1:1',
  }, {
    name: 'Vertical image (2:3)',
    value: '2:3',
  }];


  return <div>
    <SwitchContainer style={{width: '100%'}}>
      <SwitchStyled checked={imagesCheck} defaultChecked={true} onChange={checked => updateCheckBox('generate_images', checked)}/>
        Add one image per H2 section
    </SwitchContainer>
    <SwitchContainer>
      <SwitchStyled checked={generateHeroImageCheck} defaultChecked={true} onChange={checked => updateCheckBox('generate_hero_image', checked)}/>
        Generate Hero Image
    </SwitchContainer>

    <SettingContainer>
      <SelectContainer>
        <OptionLabel>Aspect ratio:</OptionLabel>
        <SelectStyled
          grayBackground={false}
          value={imagesAspectRatio}
          onChange={(value:any) => setImagesAspectRatio(value)}
        >
          {aspectRatioOptions.map((item, idx) => {
            return <Option style={{fontSize: 12}} key={idx} value={item.value}>{item.name}</Option>;
          })}
        </SelectStyled>
      </SelectContainer>
      <><div style={{display: 'flex', alignItems: 'center'}}><OptionLabel style={{width: 'fit-content', marginRight: 0}}>AI Image Generation Prompt</OptionLabel><LargeBadge beta /></div><CommonInputStyled
        border={true}
        type='text'
        size={1}
        variant='white'
        inputType='textarea'
        value={imagesAdditionalContext}
        placeholder='Enter AI Image Generation Prompt'
        onChange={e => {
          setImagesAdditionalContext(e.target.value);
        } } /></>
    </SettingContainer>
  </div>;
};

const SelectStyled = styled(Select)<{grayBackground?: boolean}>`
  width: 180px !important;
  .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;
    background-color: ${p => p.grayBackground ? '#F9F9FB': '#fff'} !important;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02) !important;
    min-width: 160px;
    font-size: 12px;
  }
  .ant-select-item {
    font-size: 12px !important;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SwitchStyled = styled(Switch)`
  width: 30px;
  min-width: 30px;
  height: 12px;
  margin-right: 10px;
  background: #A7B2C3;

  .ant-switch-handle {
    height: 15px;
    width: 15px;
    margin-top: -4px;
  }

  &.ant-switch-checked {
    background: ${opaqueColor('#7F4EAD', 30)} !important;
    .ant-switch-handle {
      &::before {
        background: #7F4EAD;
      }
     
    }
  }
`;

const SettingContainer = styled.div`
  margin: 15px 0px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 14px;
`;

const OptionLabel = styled.div`
  margin-right: 30px;
  min-width: 120px;
  font-size: 14px;
`;
