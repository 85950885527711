import {getAuthHeader, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {AxiosResponse} from 'axios';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';

interface keywordDetailPayload {
  keyword: string;
}

class OverviewApi extends BaseApi {
  public async findKeywordList(keywords) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/v1/keywordslist/find_keywords/`, keywords, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async postKeywordlist(keywords) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/v1/keywordslist/`, keywords, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async postWebsitelist(data) {
    const response: any = await this.axios.post(`/v1/website_list/`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async postCompetitorResearcher(payload) {
    const response = await this.axios.post(`/v1/competitor_research/`, payload, {
      headers: getAuthHeader(),
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async postDomainAnalyzer(url) {
    try {
      const response = await this.axios.get(`https://public.linkgraph.com/api/signup-analytic-flow/domain-analyzer/?domain=${url}`);
      return response.data;
    } catch (e) {
      return e;
    }
  }


  public async getCompetitorResearchlist(params) {
    try {
      const response = await this.axios.get(`/v1/competitor_research/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }

  public async deleteCompetitorResearcher(id) {
    try {
      const response = await this.axios.delete(`/v1/competitor_research/${id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getCompetitorResearchDetail(id, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async recrawlCompetitorResearchDetail(id) {
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/recrawl/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getSubDomainsDetail(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/view-all/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getRankingKeywordsDetail(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/view-all/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getSubDomainDetailData(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/subdomains/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getRelevantPageDetailData(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/relevant-pages/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getRankingKeywordsFilteredDetail(id, payload, publicHash) {
    const params = payload;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.post(`/v1/competitor_research/${id}/filter-ranking-keywords/`, params, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getRelevantPagesDetail(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/view-all/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getOrganicCompetitorsDetail(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/view-all/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async getAllCategories(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/view-all/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }

  public async individualCategoryData(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`/v1/competitor_research/${id}/categories/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  }
  public async getKeywordlist() {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/v1/website_list/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getKeywordStats(website:string) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/backlink/website-stats/?url=${website}`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getKeywordOverview(url:string, countryCode: string, page: number) {
    const params ={
      countryCode,
      page,
    };
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/v1/website_targetedkeywords?url=${url}`, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async updateKeywordlist(data) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.put(`/v1/keywordslist/${data.id}/`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async deleteKeywordlist(id) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.delete(`/v1/keywordslist/${id}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async postKeywordDetail(keyword, countryCode, excludeKd) {
    const response = await this.axios.get('/v1/keyword_details', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
      params: {
        'query': keyword,
        'countrycode': countryCode,
        'exclude_kd': excludeKd ? excludeKd : 0,
        'exclude_sca': 0,
      },
    });

    return response.data;
  }
  public async getWidgetUsesTableData(params: {hostname: string; page_size: number; page: number}) {
    try {
      const response = await this.axios.get(`https://public.linkgraph.com/domain-analysis/api/history/retrieve/`, {
        params,
      },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default OverviewApi;
