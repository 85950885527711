import styled from 'styled-components';

export const StyledLoaderDiv = styled.div`
  .cardBackground {
    background-color: transparent !important;
  }
  
`;

export const UncategoriezedProject = styled.span`
  font-size: 14px;
  color: #a3a4a4;
  font-family: inter;
  font-style: italic;
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: end;
`;
