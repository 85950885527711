import {getApiUrl, getAuthorizationHeader, getAuthHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {notification} from '@/utils/notification-v2';

interface PagePayload {
  title?: string;
  'target_url'?: string;
  'keywords_list'?: string[];
  'country_code'?: string;
  'location'?: string;
  'location_id'?: number | string;
  'cleaned_html'?: string;
}

class ContentAssistantApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.CA_ENDPOINT, '/api');
  private static readonly shopifyBaseUrl: string = 'https://api.searchatlas.com/api/customer/account';

  public async createPage(pagePayload: PagePayload ) {
    try {
      const {data} = await this.axios.post(`${ContentAssistantApi.baseUrl}/pages/`,
        pagePayload,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      if (error?.response?.status === 429) {
        notification.warning('Article Quota Exceeded', error?.response?.data?.message);
      }
      return error;
    }
  }


  public async getInternalLinkSuggestions(uuid: string, shouldReproccess: boolean = false) {
    const params = shouldReproccess ? {should_reprocess: true} : {};
    try {
      const response = await this.axios.get(`${ContentAssistantApi.baseUrl}/pages/${uuid}/internallink-suggestions/`, {
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getInternalLinkSuggestionsv2(uuid: string, shouldReproccess: boolean = false) {
    const params = shouldReproccess ? {should_reprocess: true} : {};
    try {
      const response = await this.axios.get(`${ContentAssistantApi.baseUrl}/pages/${uuid}/v2/internallink-suggestions/`, {
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateHostname(uuid: string, hostname: string) {
    try {
      const response = await this.axios.put(`${ContentAssistantApi.baseUrl}/pages/${uuid}/`,
        {hostname: hostname},
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async editContentGetData(uuid: string, isPublic = false) {
    try {
      const response = await this.axios.get(`${ContentAssistantApi.baseUrl}/pages/${uuid}/small-keywords-data/`,
        {
          headers: {
            ...(!isPublic && {Authorization: await getAuthorizationHeader()}),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getWpPublishingData() {
    try {
      const response = await this.axios.get(`${ContentAssistantApi.baseUrl}/wp-website-heartbeat/`,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getShopifyBlogs() {
    try {
      const response = await this.axios.get(`${ContentAssistantApi.shopifyBaseUrl}/vendor-account-content/blogs/`,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createNewShopifyPage(payload) {
    try {
      const response = await this.axios.post(`${ContentAssistantApi.shopifyBaseUrl}/vendor-account-content/`, payload,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createNewShopifyBlog(payload) {
    try {
      const response = await this.axios.post(`${ContentAssistantApi.shopifyBaseUrl}/vendor-account-content/`, payload,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getVendorAccounts() {
    try {
      const response = await this.axios.get(`${ContentAssistantApi.shopifyBaseUrl}/vendor-account-content/vendor-accounts/`,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async publishWpData(props: any) {
    const payload = {
      wp_website_id: props.pageId,
      ...(props.pageUrl && {post_url: props.pageUrl}),
      ...(props.postStatus && {post_status: props.postStatus}),
      ...(props.postType && {post_type: props.postType}),
      ...(props.permalink && {permalink: props.permalink}),
      ...(props.postCategories && {post_categories: props.postCategories}),
      ...(props.postAuthor && {post_author: props.postAuthor}),
      ...(props.postParent!==null && {post_parent: props.postParent}),
    };
    try {
      const response = await this.axios.post(`${ContentAssistantApi.baseUrl}/pages/${props.uuid}/publish-to-wp/`,
        {...payload},
        {
          timeout: 180000,
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async exportPageToGoogleDocs(uuid: string) {
    try {
      const response = await this.axios.get(`${ContentAssistantApi.baseUrl}/pages/${uuid}/export-brief-to-google-doc/`, {
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async generateReport(id: number) {
    try {
      const response = await this.axios.get(`${ContentAssistantApi.baseUrl}/content-audit/${id}/export-to-google-doc/`, {
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async deleteInternalLinkSuggestion(uuid: string, url: string, kwList: string[]) {
    const payload = {
      url: url,
      keywords: kwList,
    };
    try {
      const response = await this.axios.patch(`${ContentAssistantApi.baseUrl}/pages/${uuid}/remove-internal-link-suggestion/`,
        payload,
        {
          headers: {Authorization: await getAuthorizationHeader()},
        // cancelToken: this.cancelToken,
        });

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async bookmarkInternalLinkSuggestion(uuid: string, url: string) {
    const payload = {
      url: url,
    };
    try {
      const response = await this.axios.patch(`${ContentAssistantApi.baseUrl}/pages/${uuid}/bookmark-internal-link-suggestion/`,
        payload,
        {
          headers: {Authorization: await getAuthorizationHeader()},
        // cancelToken: this.cancelToken,
        });

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async uploadImage(formData:any) {
    try {
      const response = await this.axios.post(`${ContentAssistantApi.baseUrl}/files/upload-image`,
        formData,
        {
          headers: {Authorization: await getAuthorizationHeader()},
        // cancelToken: this.cancelToken,
        });

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export const CONTENT_ASSISTANT_API = new ContentAssistantApi();
