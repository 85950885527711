import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';

export const ButtonStyled = styled(Button)`
  margin: 50px 0px 0px 0px !important;
  border-radius: 6px;
  background: #2D6CCA;

  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)), #2D6CCA !important;
  }
  &:focus {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)), #2D6CCA !important;
  }
  &:disabled {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)), #2D6CCA !important;
  }
`;
