/* eslint-disable max-len */
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styles from '../styles.module.scss';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {customNumberWithCommas} from '@/utils/number';
import {Button, Checkbox, Radio} from 'antd';
import styled from 'styled-components';
import ReactEcharts from 'echarts-for-react';
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import moment from 'moment';
import {getFormatedDate} from '@/utils/moment';
import _ from 'lodash';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {callsList, conversionsList, googleMatrix} from '../../componentsDrag/layoutData';
import {getSingleUrlParam} from '@/utils/url';

export const Calls = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 1 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'calls', key: 'calls', name: 'Calls', value: 1})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Calls</span><Radio.Group value={googleAdSelect['value']}><Radio value={1} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['data'] ? customNumberWithCommas(Number(reportsGoogleListMatrix['data']['calls'])) : '-'}</Values>
      </div>}
  </div>;
});

export const Missed = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 2 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'missed', key: 'missed', name: 'Missed', value: 2})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Missed</span><Radio.Group value={googleAdSelect['value']}><Radio value={2} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['data'] ? customNumberWithCommas(Number(reportsGoogleListMatrix['data']['missed'])) : '-'}</Values>
      </div>}
  </div>;
});

export const Received = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 3 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'received', key: 'received', name: 'Received', value: 3})} >
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Received</span><Radio.Group value={googleAdSelect['value']}><Radio value={3} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['data'] ? customNumberWithCommas(Number(reportsGoogleListMatrix['data']['received'])) : '-'}</Values>
      </div>}
  </div>;
});

export const Unknown = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 4 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'unknown', key: 'unknown', name: 'Unknown', value: 4})} >
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Unknown</span><Radio.Group value={googleAdSelect['value']}><Radio value={4} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['data'] ? customNumberWithCommas(Number(reportsGoogleListMatrix['data']['unknown'])) : '-'}</Values>
      </div>}
  </div>;
});

export const AllConversion = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 1 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'all_conversions', key: 'allConversions', name: 'All Conversions', value: 1})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />All Conversions</span><Radio.Group value={googleAdSelect['value']}><Radio value={1} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['allConversions'])) : '-'}</Values>
      </div>}
  </div>;
});

export const AllConversionValue = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 2 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'all_conversions_value', key: 'allConversionsValue', name: 'All Conversions Value', value: 2})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />All Conversions Value</span><Radio.Group value={googleAdSelect['value']}><Radio value={2} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['allConversionsValue'])) : '-'}</Values>
      </div>}
  </div>;
});

export const Conversions = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 3 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'conversions', key: 'Conversions', name: 'Conversions', value: 3})} >
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Conversions</span><Radio.Group value={googleAdSelect['value']}><Radio value={3} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['conversions'])) : '-'}</Values>
      </div>}
  </div>;
});

export const ConversionsValue = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 4 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'conversions_value', key: 'conversionsValue', name: 'Conversions Value', value: 4})} >
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Conversions Value</span><Radio.Group value={googleAdSelect['value']}><Radio value={4} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['conversionsValue'])) : '-'}</Values>
      </div>}
  </div>;
});

export const VTConversionValGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 5 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'view_through_conversions', key: 'viewThroughConversions', name: 'View-Through Conversion', value: 5})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span style={{width: '215px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />View-Through Conversion</span><Radio.Group value={googleAdSelect['value']}><Radio value={5} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['viewThroughConversions'])) : '-'}</Values>
      </div>}
  </div>;
});

export const ImpressionsGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 1 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'impressions', key: 'impressions', name: 'Impressions', value: 1})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Impressions</span><Radio.Group value={googleAdSelect['value']}><Radio value={1} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['impressions'])) : '-'}</Values>
      </div>}
  </div>;
});

export const ClicksGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 2 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'clicks', key: 'clicks', name: 'Clicks', value: 2})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Clicks</span><Radio.Group value={googleAdSelect['value']}><Radio value={2} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['clicks'])) : '-'}</Values>
      </div>}
  </div>;
});

export const AverageCpcGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 3 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'average_cpc', key: 'averageCpc', name: 'Average CPC', value: 3})} >
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Average CPC</span><Radio.Group value={googleAdSelect['value']}><Radio value={3} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['averageCpc'] ? '$' : ''}{reportsGoogleListMatrix && reportsGoogleListMatrix['averageCpc']? JSON.stringify(reportsGoogleListMatrix['averageCpc'])?.slice(0, 4) : '-'}</Values>
      </div>}
  </div>;
});

export const VTConversionGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 4 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'view_through_conversions', key: 'viewThroughConversions', name: 'View-Through Conversion', value: 4})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span style={{width: '215px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />View-Through Conversion</span><Radio.Group value={googleAdSelect['value']}><Radio value={4} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix ? customNumberWithCommas(Number(reportsGoogleListMatrix['viewThroughConversions'])) : '-'}</Values>
      </div>}
  </div>;
});


export const CostGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 5 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'cost', key: 'cost', name: 'Cost', value: 5})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Cost</span><Radio.Group value={googleAdSelect['value']}><Radio value={5} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['cost'] ? '$' : ''}{reportsGoogleListMatrix && reportsGoogleListMatrix['cost'] ? Number(reportsGoogleListMatrix['cost']).toFixed(2) : '-'}</Values>
      </div>}
  </div>;
});

export const ConversionGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect, activeTabKey}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 6 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'conversions', key: (activeTabKey == 'conversion' ? 'conversionsValue' : 'conversions'), name: 'Conversions', value: 6})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Conversions</span><Radio.Group value={googleAdSelect['value']}><Radio value={6} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['conversions'] ? Number(reportsGoogleListMatrix['conversions']) : '-'}</Values>
      </div>}
  </div>;
});

export const CostPerConvrsionGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 7 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'cost_per_conversion', key: 'costPerConversion', name: 'Cost Per Conversion', value: 7})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Cost per Conversion</span><Radio.Group value={googleAdSelect['value']}><Radio value={7} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['costPerConversion'] ? '$' : ''}{reportsGoogleListMatrix && reportsGoogleListMatrix['costPerConversion'] ? Number(reportsGoogleListMatrix['costPerConversion']).toFixed(2) : '-'}</Values>
      </div>}
  </div>;
});

export const ConversionRateGL = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, reportsGoogleListMatrix, setGoogleAdSelect, googleAdSelect}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={googleAdSelect['value'] == 8 ? {background: 'rgb(45 108 202 / 7%)', border: '1px solid #2D6CCA', height: 'inherit'} : {height: 'inherit', backgroundColor: 'transparent'}} onClick={()=> setGoogleAdSelect({ip: 'conversion_rate', key: 'conversionRate', name: 'Conversion Rate', value: 8})}>
    {loadingglMatrix ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600', display: 'flex', justifyContent: 'space-between'}}><span><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' />Conversion Rate</span><Radio.Group value={googleAdSelect['value']}><Radio value={8} /></Radio.Group></p>
        <Values>{reportsGoogleListMatrix && reportsGoogleListMatrix['conversionRate'] ? `${Number(reportsGoogleListMatrix['conversionRate']).toFixed(2)}%` : '-'}</Values>
      </div>}
  </div>;
});

export const AllMatrix = observer(() => {
  const {reportBuilder: {details: {activeTabKey, updateSingleProjectData, isConfig, singleProjectData}}} = useStore('');
  const [leyout, setNewLayout] = useState(null);

  useEffect(()=>{
    if (activeTabKey == 'conversion') {
      const newLayout = singleProjectData.layout.map(item=> item?.name == 'Matrix GL' ? {...item, minH: 4} : {...item});
      setNewLayout([...conversionsList]);
      updateSingleProjectData({...singleProjectData, layout: newLayout});
    } else if (activeTabKey == 'call') {
      const newLayout = singleProjectData.layout.map(item=> item?.name == 'Matrix GL' ? {...item, minH: 2} : {...item});
      setNewLayout([...callsList]);
      updateSingleProjectData({...singleProjectData, layout: newLayout});
    } else {
      const newLayout = singleProjectData.layout.map(item=> item?.name == 'Matrix GL' ? {...item, minH: 4} : {...item});
      setNewLayout([...googleMatrix]);
      updateSingleProjectData({...singleProjectData, layout: newLayout});
    }
  }, [activeTabKey]);

  return <MainWrapper isConfig={isConfig}>
    {leyout?.map(item=> {
      return React.createElement(item.component, {index: item.index, type: activeTabKey});
    })}
  </MainWrapper>;
});

export const OverTime = observer(() => {
  const {reportBuilder: {details: {loadingglMatrix, isConfig, loadingglgraph, activeTabKey, singleProjectData, googleAdSelect, reportsGoogleListOverTime, loadgetGoogleAdsOverTimeData}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);
  const numberOfPastMonths = dateRangeObj[`${singleProjectData?.dateRange}`] == 'One month' ? 'oneMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Three month' ? 'threeMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Six month' ? 'sixMonth' : 'oneYear';
  const params = {
    period_start: singleProjectData?.dateRangeEnd ? singleProjectData?.dateRangeStart : moment(getFormatedDate(numberOfPastMonths, moment()?.format('YYYY-MM-DD')))?.format('YYYY-MM-DD'),
    period_end: singleProjectData?.dateRangeEnd ? singleProjectData?.dateRangeEnd : moment()?.format('YYYY-MM-DD'),
  };
  const domain = getSingleUrlParam('domain');

  const [activeSeries, setActiveSeries] = useState({'CONNECTED_TV': true, 'DESKTOP': true, 'MOBILE': true, 'TABLET': true, 'MALE': true, 'FEMALE': true, 'UNDETERMINED': true, '18-24': true, '25-34': true, '35-44': true, '45-54': true, '55-64': true, '65-UP': true, 'UNDETERMINE': true});

  useEffect(()=> {
    singleProjectData?.reportsData.googleAds?.googleAdsAccountId && domain && loadgetGoogleAdsOverTimeData({startDate: params.period_start, endDate: params.period_end, customerId: singleProjectData?.reportsData.googleAds?.googleAdsAccountId, type: activeTabKey || 'campaign'});
  }, [activeTabKey]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'category',
      data: reportsGoogleListOverTime ? reportsGoogleListOverTime['data']?.map(item=> moment(activeTabKey == 'call' ? item.date : item.segmentDate).format('MMM DD YYYY')) : [],
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    series: [
      {
        name: googleAdSelect['key'],
        type: 'line',
        showSymbol: false,
        data: reportsGoogleListOverTime ? reportsGoogleListOverTime['data']?.map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
    ],
  };

  const optionsAge = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'category',
      data: reportsGoogleListOverTime ? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == '18-24')?.map(item=> moment(item.segmentDate).format('MMM DD')) : [],
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    series: [
      {
        name: '18-24',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#4285F4',
        },
        data: reportsGoogleListOverTime && activeSeries['18-24']? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == '18-24').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: '25-34',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#34AEF3',
        },
        data: reportsGoogleListOverTime && activeSeries['25-34']? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == '25-34').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: '35-44',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#7F4EAD',
        },
        data: reportsGoogleListOverTime && activeSeries['35-44']? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == '35-44').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: '45-54',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#F1AA3E',
        },
        data: reportsGoogleListOverTime && activeSeries['45-54']? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == '45-54').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: '55-64',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#FF8536',
        },
        data: reportsGoogleListOverTime && activeSeries['55-64']? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == '55-64').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: '65-UP',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#F44343',
        },
        data: reportsGoogleListOverTime && activeSeries['65-UP']? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == '65-UP').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: 'UNDETERMINE',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: 'gray',
        },
        data: reportsGoogleListOverTime && activeSeries['UNDETERMINE']? reportsGoogleListOverTime['data']?.filter(item=> item.ageRange == 'Undetermined').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
    ],
  };

  const optionsGender = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'category',
      data: reportsGoogleListOverTime ? reportsGoogleListOverTime['data']?.filter(item=> item.gender == 'MALE')?.map(item=> moment(item.segmentDate).format('MMM DD')) : [],
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    series: [
      {
        name: 'MALE',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#4285F4',
        },
        data: reportsGoogleListOverTime && activeSeries['MALE']? reportsGoogleListOverTime['data']?.filter(item=> item.gender == 'MALE').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: 'FEMALE',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#FF5B96',
        },
        data: reportsGoogleListOverTime && activeSeries['FEMALE']? reportsGoogleListOverTime['data']?.filter(item=> item.gender == 'FEMALE').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: 'UNDETERMINED',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#A3A4A4',
        },
        data: reportsGoogleListOverTime && activeSeries['UNDETERMINED']? reportsGoogleListOverTime['data']?.filter(item=> item.gender == 'UNDETERMINED').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
    ],
  };

  const optionsDevice = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      type: 'category',
      data: reportsGoogleListOverTime ? reportsGoogleListOverTime['data']?.filter(item=> item.device == 'DESKTOP')?.map(item=> moment(item.segmentDate).format('MMM DD')) : [],
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    series: [
      {
        name: 'CONNECTED_TV',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: 'gray',
        },
        data: reportsGoogleListOverTime && activeSeries['CONNECTED_TV'] ? reportsGoogleListOverTime['data']?.filter(item=> item.device == 'CONNECTED_TV').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: 'DESKTOP',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#4285F4',
        },
        data: reportsGoogleListOverTime && activeSeries['DESKTOP'] ? reportsGoogleListOverTime['data']?.filter(item=> item.device == 'DESKTOP').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: 'MOBILE',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#7F4EAD',
        },
        data: reportsGoogleListOverTime && activeSeries['MOBILE']? reportsGoogleListOverTime['data']?.filter(item=> item.device == 'MOBILE').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
      {
        name: 'TABLET',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#34AEF3',
        },
        data: reportsGoogleListOverTime && activeSeries['TABLET']? reportsGoogleListOverTime['data']?.filter(item=> item.device == 'TABLET').map(item=> item[googleAdSelect['key'] || 'impressions']) : [],
      },
    ],
  };

  const hideAndShowSeries = key => {
    setActiveSeries({...activeSeries, [key]: !activeSeries[key]});
  };

  const legendsDataDevice = [
    {keyName: 'CONNECTED_TV', color: 'gray', name: 'Connected Tv'},
    {keyName: 'DESKTOP', color: '#4285F4', name: 'Desktop'},
    {keyName: 'MOBILE', color: '#7F4EAD', name: 'Mobile'},
    {keyName: 'TABLET', color: '#34AEF3', name: 'Tablet'},
  ];

  const legendsDataGender = [
    {keyName: 'MALE', color: '#4285F4', name: 'Male'},
    {keyName: 'FEMALE', color: '#FF5B96', name: 'Female'},
    {keyName: 'UNDETERMINED', color: '#A3A4A4', name: 'Undetermined'},
  ];

  const legendsDataAge = [
    {keyName: '18-24', color: '#4285F4', name: '18-24'},
    {keyName: '25-34', color: '#34AEF3', name: '25-34'},
    {keyName: '35-44', color: '#7F4EAD', name: '35-44'},
    {keyName: '45-54', color: '#F1AA3E', name: '45-54'},
    {keyName: '55-64', color: '#FF8536', name: '55-64'},
    {keyName: '65-UP', color: '#F44343', name: '65-UP'},
    {keyName: 'UNDETERMINE', color: '#A3A4A4', name: 'Undetermine'},
  ];

  const isData = reportsGoogleListOverTime && reportsGoogleListOverTime['data']?.length && reportsGoogleListOverTime['data']?.filter(item=> item[googleAdSelect['key'] || 'impressions'] >= 0)?.length;

  return isLoaded ? <Wrapper className={isConfig && 'no-border'}>
    <div style={{display: 'flex', justifyContent: 'space-between'}}><div><Title><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' /> {googleAdSelect['name']} over time</Title></div>
      {(isData) && (activeTabKey?.includes('age') || activeTabKey?.includes('gender') || activeTabKey?.includes('device')) ? <div style={{display: 'flex'}}>
        {(activeTabKey?.includes('age') ? legendsDataAge : activeTabKey?.includes('gender') ? legendsDataGender : activeTabKey?.includes('device') ? legendsDataDevice : legendsDataDevice)?.map((item, index) => {
          return <div key={index} className={classnames(styles.legends, styles.legendsTop, !activeSeries[item.keyName] && styles.legendsInactive)} onClick={() => hideAndShowSeries(item.keyName)}>
            <div className={styles.left}>
              <div className={styles.legendTitle} style={{lineHeight: '22px', whiteSpace: 'nowrap', marginLeft: '20px', color: 'black'}}><CheckboxStyled color={item?.color} defaultChecked={true} checked={activeSeries[item.keyName]}></CheckboxStyled>{item.name}</div>
            </div>
          </div>;
        })}
      </div> : <></> }</div>
    {loadingglMatrix || loadingglgraph ? <RingLoaderV2/> : isData ? <>
      {activeTabKey?.includes('age') ? <ReactEcharts style={{width: '100%', height: '400px'}}
        option={{...optionsAge}}
      /> : activeTabKey?.includes('gender') ? <ReactEcharts style={{width: '100%', height: '400px'}}
        option={{...optionsGender}}
      /> : activeTabKey?.includes('device') ? <ReactEcharts style={{width: '100%', height: '400px'}}
        option={{...optionsDevice}}
      /> : <ReactEcharts style={{width: '100%', height: '400px'}}
        option={{...options}}
      />}
    </> : <NoData>No Data</NoData>}
  </Wrapper> : null;
});

export const ByCampaign = observer(() => {
  const {reportBuilder: {details: {isConfig, loadingglgraph, reportsGoogleListByCampaign, singleProjectData, loadgetGoogleAdsByCampaign, activeTabKey, loadingglMatrix, googleAdSelect}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);
  const numberOfPastMonths = dateRangeObj[`${singleProjectData?.dateRange}`] == 'One month' ? 'oneMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Three month' ? 'threeMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Six month' ? 'sixMonth' : 'oneYear';
  const params = {
    period_start: singleProjectData.dateRangeEnd ? singleProjectData.dateRangeStart : moment(getFormatedDate(numberOfPastMonths, moment()?.format('YYYY-MM-DD')))?.format('YYYY-MM-DD'),
    period_end: singleProjectData.dateRangeEnd ? singleProjectData.dateRangeEnd : moment()?.format('YYYY-MM-DD'),
  };
  const domain = getSingleUrlParam('domain');

  useEffect(()=> {
    singleProjectData?.reportsData.googleAds?.googleAdsAccountId && domain && loadgetGoogleAdsByCampaign({startDate: params.period_start, endDate: params.period_end, customerId: singleProjectData?.reportsData.googleAds?.googleAdsAccountId, type: activeTabKey || 'campaign', sortBy: googleAdSelect['ip'] || 'impressions'});
  }, [activeTabKey, googleAdSelect]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const tabName = {'campaign': 'Campaigns', 'ads_group': 'Ads Group', 'search_term': 'Search Term', 'search_keyword': 'Search Keyword', 'demographic_device': 'Demographic Device', 'demographic_age': 'Demographic Age', 'demographic_gender': 'Demographic Gender', 'demographic_location': 'Demographic Location', 'conversion': 'Conversion', 'call': 'Call'};

  const colors = activeTabKey?.includes('age') ? ['#4285F4', '#34AEF3', '#7F4EAD', '#F1AA3E', '#FF8536', '#F44343', '#A3A4A4'] : activeTabKey?.includes('device') ? ['#7F4EAD', '#4285F4', 'gray', '#34AEF3'] : activeTabKey?.includes('gender') ? ['#4285F4', '#FF5B96', '#A3A4A4'] : ['#34A853', '#247F3D', '#FCBC04', '#4285F4', '#34A853', '#247F3D', '#FCBC04', '#4285F4'];
  const options = {
    xAxis: {
      type: 'category',
      data: reportsGoogleListByCampaign && reportsGoogleListByCampaign['data']?.map(item=> item.keyword || item.name || item.device || item.ageRange || item.gender || item.searchTerm || item.type),
      axisLabel: {
        interval: 0,
        width: 80,
        overflow: 'break',
        // rotate: 20,
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: reportsGoogleListByCampaign && reportsGoogleListByCampaign['data']?.map(item=> activeTabKey == 'call' ? item.count : item[googleAdSelect['key'] || 'impressions']),
        type: 'bar',
        barWidth: 14,
        itemStyle: {
          color: function(param) {
            return colors[param.dataIndex];
          },
        },
      },
    ],
  };

  return isLoaded ? <Wrapper className={isConfig && 'no-border'}>
    <div><Title><img style={{width: '16px'}} src='/img/googleIcon.svg' alt='' /> {googleAdSelect['name']} by {tabName[activeTabKey || 'campaign']?.toLowerCase()}</Title></div>
    {loadingglMatrix || loadingglgraph ? <RingLoaderV2/> : reportsGoogleListByCampaign && reportsGoogleListByCampaign['data']?.length && reportsGoogleListByCampaign['data']?.filter(item=> activeTabKey == 'call' ? item.count : item[googleAdSelect['key'] || 'impressions'] >= 0)?.length ? <>
      <ReactEcharts style={{width: '100%', height: '400px'}}
        option={options}
      />
    </> : <NoData>No Data</NoData>}
  </Wrapper> : null;
});

const dateRangeObj = {
  last_1_month: 'One month',
  last_3_months: 'Three month',
  last_6_months: 'Six month',
  last_year: 'One year',
};

export const GoogleCampaignTable = observer(() => {
  const {reportBuilder: {details: {isConfig}}} = useStore('');
  const {reportBuilder: {details: {reportsGoogleListUser, activeTabKey, loadgetGoogleAdsTableData, loadingglTable, loadgetGoogleAdsMatrixData, singleProjectData}}} = useStore('');
  const numberOfPastMonths = dateRangeObj[`${singleProjectData?.dateRange}`] == 'One month' ? 'oneMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Three month' ? 'threeMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Six month' ? 'sixMonth' : 'oneYear';
  const params = {
    period_start: singleProjectData.dateRangeEnd ? singleProjectData.dateRangeStart : moment(getFormatedDate(numberOfPastMonths, moment()?.format('YYYY-MM-DD')))?.format('YYYY-MM-DD'),
    period_end: singleProjectData.dateRangeEnd ? singleProjectData.dateRangeEnd : moment()?.format('YYYY-MM-DD'),
  };
  const domain = getSingleUrlParam('domain');


  const dateColumnNames = ['startDate', 'endDate', 'date', 'startCallDateTime', 'endCallDateTime'];

  const dateColumn = (date, time) => {
    return time ? date.split(' ')[1] : moment(date).format('MMM DD yyyy');
  };
  const columnSigns = {
    'searchImpressionShare': {
      isBefore: false,
      sign: '%',
    },
    'averageCpc': {
      isBefore: true,
      sign: '$',
    },
    'cost': {
      isBefore: true,
      sign: '$',
    },
    'costPerConversion': {
      isBefore: true,
      sign: '$',
    },
    'conversionRate': {
      isBefore: false,
      sign: '%',
    },
    'ctr': {
      isBefore: false,
      sign: '%',
    },
  };

  useEffect(()=> {
    singleProjectData?.reportsData.googleAds?.googleAdsAccountId && domain && loadgetGoogleAdsTableData({startDate: params.period_start, endDate: params.period_end, customerId: singleProjectData?.reportsData.googleAds?.googleAdsAccountId, type: activeTabKey});
    singleProjectData?.reportsData.googleAds?.googleAdsAccountId && domain && loadgetGoogleAdsMatrixData({startDate: params.period_start, endDate: params.period_end, customerId: singleProjectData?.reportsData.googleAds?.googleAdsAccountId, type: activeTabKey});
  }, [activeTabKey]);

  const formattedValue = (key, value) => {
    if (key in columnSigns) {
      return columnSigns[key].isBefore ? `${columnSigns[key].sign}${value}` : `${value}${columnSigns[key].sign}`;
    }
    return value;
  };

  const tableData = data => {
    const col = data && data[0] ? Object.keys(data[0])?.map((key, index) => {
      const value = _.startCase(key)?.toUpperCase();
      return {
        title: <div style={{padding: '0 5px', whiteSpace: 'pre-wrap'}}>{value}</div>,
        dataIndex: 'campaign',
        key: 'campaign',
        align: 'center' as 'center',
        fixed: index == 0 ? true : false,
        width: 150,
        render: (_, record) => {
          return <div style={{whiteSpace: 'pre-wrap', padding: '0 5px'}}>
            {typeof record[key] == 'number' ? formattedValue(key, record[key]) : dateColumnNames.includes(key) ? dateColumn(record[key], key.includes('Time')) : formattedValue(key, record[key])}
          </div>;
        },
      };
    }) : [];
    return col;
  };

  const loadMore = () => {
    const isLoaded = reportsGoogleListUser && reportsGoogleListUser['data'] ? {next_page: reportsGoogleListUser['data']['nextPageToken']} : {};
    singleProjectData?.reportsData.googleAds?.googleAdsAccountId && loadgetGoogleAdsTableData({startDate: params.period_start, endDate: params.period_end, customerId: singleProjectData?.reportsData.googleAds?.googleAdsAccountId, type: activeTabKey, ...isLoaded});
  };

  const tableFooter = () => (
    reportsGoogleListUser && reportsGoogleListUser['data'] && reportsGoogleListUser['data']['nextPageToken'] ? <div style={{display: 'flex', justifyContent: 'center'}}>
      <Button type='link' onClick={loadMore}>
      Load more
      </Button>
    </div> : <></>
  );

  const tables = () => {
    return <div className='krtTable'>
      <TableStyled
        style={{height: '390px', background: 'white'}}
        columns={reportsGoogleListUser && reportsGoogleListUser['data'] && reportsGoogleListUser['data']['results'] ? tableData(reportsGoogleListUser['data']['results']) : []}
        dataSource={reportsGoogleListUser && reportsGoogleListUser['data'] && reportsGoogleListUser['data']['results'] || []}
        loading={loadingglTable}
        pagination={false}
        sticky={true}
        loadingRows={5}
        footer={tableFooter}
      />
    </div>;
  };

  return <Wrapper className={isConfig && 'no-border'}>
    {tables()}
  </Wrapper>;
});


const Title = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 18, 18, 1);
`;

const NoData = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
    height: 83%;
`;

const Values = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: justify;
`;

const Wrapper = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  padding: 20px;
  background-color: white;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #00000005;
  border-radius: 16px;
  // margin-top: 20px;
  .title {
  color: gray;
  }
  .ant-table-header{
  overflow: visible !important;
  }
  .ant-table-sticky-scroll{
  display: none;
  }
  td {
  white-space: inherit !important;
  }
  .ant-checkbox-checked::after{
  border: none !important;
  }
  h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-top: 10%;
  }
  
  svg {
    margin-right: 10px;
  }
  .rc-tabs-nav {
  background: transparent !important;
  .rc-tabs-tab-btn{
  color: black !important;
  padding: 5px 5px !important;
  }
  .rc-tabs-ink-bar{
  background: #2D6CCA;
  height: 6px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  }
  .selections{
  margin-left: 30px;
  }
  .krtTable{
  padding-left: 0px !important;
  }
  .tabTitle{
    position: absolute;
    right: 0;
    margin-right: -25px;
    align-items: center;
    display: flex;
    }
    .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
    margin: 0;
    }
    }
    .echarts-for-react{
    &::first-child{
    widht: 300px;}
    }
    .ant-table-wrapper{
    height: 500px !important;
    }
`;

const CheckboxStyled = styled(Checkbox)<{color}>`
.ant-checkbox-inner {
    background-color:${p => p.color};
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color:${p => p.color};
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
`;

const MainWrapper = styled.div<{isConfig}>`
display: flex;
flex-wrap: wrap;
.mainInner{
width: ${p=> p.isConfig ? '245px' : '284px'};
margin: 5px;
height: 124px !important;
    border: 1px solid #80808038;

}
`;
