import {types} from 'mobx-state-tree';
import {FocusedKeywordStore, initFocusedKeywordStore} from './focused-keyword';
import {initOverviewStore, OverviewStore} from './overview';
import {BannerVariant} from '@/components/common-components/components/banner';

export const KeywordsExplorerStore = types.model({
  overview: OverviewStore,
  focusedKeyword: FocusedKeywordStore,
  banner: types.model({
    show: types.boolean,
    title: types.string,
    subtitle: types.string,
    variant: types.enumeration<BannerVariant>('BannerVariant', Object.values(BannerVariant)),
  }),
}).actions(self => {
  const setBanner = (show: boolean, title: string, subtitle: string, variant: BannerVariant) => {
    self.banner = {
      show,
      title,
      subtitle,
      variant,
    };
  };
  return {
    setBanner,
  };
});

export const initKeywordsExplorerStore = () => {
  return KeywordsExplorerStore.create({
    overview: initOverviewStore(),
    focusedKeyword: initFocusedKeywordStore(),
    banner: {
      show: false,
      title: '',
      subtitle: '',
      variant: BannerVariant.ERROR,
    },
  });
};
