export const API_POLL_INTERVAL = 2000;
export const API_POLL_INTERVAL_CONTENT_AUDIT = 5000;
export const API_POLL_INTERVAL_BULK_DA_CHECKER = 2000;
export const API_POLL_INTERNAL_LINKING_INTERVAL = 2000;
export const MESSAGE_ERROR_DURATION = 1.5;
export const QUERY_DEBOUNCE_WAIT = 500;
export const RECAPTCHA_KEY = '6LeUKAAbAAAAANq3qpeYPEaHrfT5jeAFQDiSOFtW';
export const FREE_PLAN_PROJECT_ID = 1328;
export const FREE_PLAN_PROJECT_DOMAIN = 'tesla.com';
export const KEYWORD_DETAIL_API_POLL_INTERVAL = 2000;
export const KEYWORD_DETAIL_TIME_INTERVAL = 5;
export const BACKLINK_API_API_POLL_INTERVAL = 4000;
export const COMPETITOR_RESEARCHER_API_POLL_INTERVAL = 5000;
export const BACKLINK_RESEARCHER_API_POLL_INTERVAL = 10000;
export const DELETE_ALERT_INTERVAL = 5000;
export const EMAIL_VERIFY_API_POLL_INTERVAL = 5000;
export const GSC_PROPERTIES_PAGE_SIZE= 10;
export const SITE_EVENT_PAGE_SIZE= 10;
export const FREE_PLAN_ID = 11;
export const STARTER_PLAN_ID = 10;
export const GROWTH_PLAN_ID = 1;
export const PRO_PLAN_ID = 8;
export const CUSTOM_PLAN_ID = 5;
export const AGENCY_STARTER_ID = 1012;
export const COMMUNITY_EDITION_ID = 1025;

export const KE_API_MAX_NUMBER_OF_POLLS = 65;
export const CR_API_MAX_NUMBER_OF_POLLS = 40;
export const KE_API_POLL_INTERVAL = 1000;
export const BUSINESS_PLAN_ID = 12;
export const CA_BASIC_PLAN_ID = 3;

export enum SDATopicalMainTableFilterEnum {
  pillar_page = 'Pillar Page',
  Keyword = 'Keyword',
}

export enum OTTOTopicalModalTableFilterEnum {
  title = 'Title',
  Keyword = 'Keyword',
}
export const SETopicalAuthorityTableFilterEnum = {
  domain: 'Domain / Central entity',
  broadTopic: 'Broad topic',
  topicAuthority: 'Topic dominance',
  topicWeight: 'Topic weight',
  avgPosition: 'Avg. Position',
  keyword: 'Keyword',
  searchVolume: 'Search volume',
  position: 'Position',
  rankingUrl: 'Ranking URL',
};

export const SDATopicalMainTableFilterList = [
  {id: 1, name: 'exclude', header: SDATopicalMainTableFilterEnum.pillar_page, text: '', type: undefined, active: false, isSearch: true, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
  {id: 2, name: 'exclude', header: SDATopicalMainTableFilterEnum.Keyword, text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
];

export const OTTOTopicalModalTableFilterList = [
  {id: 1, name: 'exclude', header: OTTOTopicalModalTableFilterEnum.title, text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
  {id: 2, name: 'exclude', header: OTTOTopicalModalTableFilterEnum.Keyword, text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
];

export const TopicalAuthorityBreakDownTableFilterList = [
  {id: 1, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.broadTopic, text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
  {id: 2, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.topicAuthority, text: '', type: 'number', active: false, customFields: [
    {label: 'Greater or equal', operator: 'contains'},
  ]},
  // {id: 3, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.topicWeight, text: '', type: 'number', active: false, customFields: [
  //   {label: 'Greater or equal', operator: 'contains'},
  // ]},
  {id: 4, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.avgPosition, text: '', type: 'number', active: false, customFields: [
    {label: 'Greater or equal', operator: 'contains'},
  ]},
];

export const TopicalAuthorityGapTableFilterList = [
  {id: 1, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.broadTopic, text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
  {id: 2, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.keyword, text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
  {id: 3, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.searchVolume, text: '', type: 'number', active: false, customFields: [
    {label: 'Greater or equal', operator: 'contains'},
  ]},
  {id: 4, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.position, text: '', type: 'number', active: false, customFields: [
    {label: 'Greater or equal', operator: 'contains'},
  ]},
  {id: 5, name: 'exclude', header: SETopicalAuthorityTableFilterEnum.rankingUrl, text: '', type: undefined, active: false, isSearch: false, customFields: [
    {label: 'Contains', operator: 'contains'},
  ]},
];

export enum SDAQuestMainTableFilterEnum {
  query = 'Query',
  }

