import {types, Instance, flow} from 'mobx-state-tree';
import {integrationApi} from '@/api/gsc/index';
import {toJS} from 'mobx';
import {isNetworkError} from '@/utils/api';

const Property = types.model({
  countryBreakdownLastFetchedAt: types.maybeNull(types.string),
  countryBreakdownStatus: types.maybeNull(types.string),
  deviceBreakdownLastFetchedAt: types.maybeNull(types.string),
  deviceBreakdownStatus: types.maybeNull(types.string),
  evotLastFetchedAt: types.maybeNull(types.string),
  evotTaskStatus: types.maybeNull(types.string),
  favicon: types.maybeNull(types.string),
  isProcessingComplete: types.maybeNull(types.boolean),
  isActive: types.maybeNull(types.boolean),
  saDataLoadTimeLeft: types.maybeNull(types.number),
  saDataPoints: types.optional(types.maybeNull(types.number), 0),
  saHistoricalLastFetchedAt: types.maybeNull(types.string),
  saHistoricalStatus: types.maybeNull(types.string),
  saMostrecentDataDt: types.maybeNull(types.string),
  saTotalKwCount: types.optional(types.maybeNull(types.number), 0),
  totalPages: types.optional(types.maybeNull(types.number), 0),
  trafficValue: types.maybeNull(types.number),
  saOldestDataDt: types.maybeNull(types.string),
  saPctProgress: types.optional(types.maybeNull(types.number), 100),
  searchAppearanceLastFetchedAt: types.maybeNull(types.string),
  searchAppearanceStatus: types.maybeNull(types.string),
  sitemapsLastFetchedAt: types.maybeNull(types.string),
  sitemapsStatus: types.maybeNull(types.string),
  url: types.string,
  id: types.number,
  isCredentialsValid: types.boolean,
});

const GoogleProfile = types.model({
  email: types.maybeNull(types.string),
  pictureUrl: types.maybeNull(types.string),
  siteproperties: types.optional(types.array(Property), []),
  sitepropertiesLastPulledAt: types.maybeNull(types.string),
  isActive: types.boolean,
  sitesConnected: types.optional(types.maybeNull(types.number), 0),
  sitesConnectedUrls: types.maybeNull(types.array(types.string)),
  isAuthorizedForGsc: types.maybeNull(types.boolean),
  hasCreds: types.maybeNull(types.boolean),
  isAuthorizedForAnalytics: types.maybeNull(types.boolean),
});

export type GoogleProfileType = Instance<typeof GoogleProfile>;

export const Integration = types.model({
  googleProfiles: types.maybeNull(types.optional(types.array(GoogleProfile), [])),
  integrationLoading: types.boolean,
  showNoGscConnectedOnPageBanner: types.boolean,
  showFailedCredsOnPageBanner: types.boolean,
  updateMenuItems: types.boolean,
}).views(self => ({

  get noGoogleUserProfiles() {
    if (!Array.isArray(self.googleProfiles)) {
      return false;
    } else {
      return !self.googleProfiles?.length;
    }
  },

  get gscAccountsConnected() {
    if (!Array.isArray(self.googleProfiles)) {
      return true;
    } else {
      return self.googleProfiles?.filter(profile => !profile.hasCreds).length === 0;
    }
  },

  get getIsAllGscNotConnectedAccounts() {
    return self.googleProfiles?.filter(profile => !profile.hasCreds).length === self.googleProfiles?.length;
  },

  get gscNoSitesConnected() {
    return self.googleProfiles?.filter(profile => profile.sitesConnected === 0).length > 0;
  },

  get gscIsAllAccHaveNoSitesConnected() {
    return self.googleProfiles?.filter(profile => profile.sitesConnected === 0).length === self.googleProfiles?.length;
  },

  get gscIsSomeAccHaveNoSitesConnected() {
    if (self.googleProfiles?.filter(profile => profile.sitesConnected === 0).length > 0) {
      if (self.googleProfiles?.filter(profile => profile.sitesConnected === 0).length < self.googleProfiles?.length) {
        return true;
      }
    }
    return false;
  },

  get gscIsSomeAccFailedCreds() {
    if (self.googleProfiles?.filter(profile => !profile.hasCreds && profile.sitesConnected > 0).length > 0) {
      if (self.googleProfiles?.filter(profile => !profile.hasCreds).length < self.googleProfiles?.length) {
        return true;
      }
    }
    return false;
  },

  get getIsAnyGscNotConnectedAccounts() {
    return self.googleProfiles?.filter(profile => !profile.hasCreds && profile.sitesConnected > 0).length;
  },

  get gscIsAnyAccHaveNoSitesConnected() {
    return self.googleProfiles?.filter(profile => profile.sitesConnected === 0).length;
  },

  get gscNoSitesConnectedAccList() {
    if (self.googleProfiles?.filter(profile => profile.sitesConnected === 0).length > 0) {
      return (self.googleProfiles?.filter(profile => profile.sitesConnected === 0)?.map(profile => profile.email));
    }

    return [];
  },

  get getNotConnectedGscAccounts() {
    if (self.googleProfiles?.filter(profile => !profile.hasCreds).length > 0) {
      return self.googleProfiles?.filter(profile => !profile.hasCreds)?.map(item => item.email);
    }
    return [];
  },

  get hasGoogleProfiles() {
    return self.googleProfiles?.length > 0;
  },

  // to be used when adding google account disconnecting functionality
  get profiles() {
    return self.googleProfiles?.slice(0, 6);
  },

  get getGoogleProfiles() {
    return toJS(self.googleProfiles);
  },
})).actions(self => {
  // to be used when adding google account disconnecting functionality
  const deleteGoogleProfile = flow(function* (email: string) {
    self.integrationLoading = true;
    try {
      yield integrationApi.removeConnectedGoogleAccount(email);
      loadGoogleProfiles();
    } catch (e) {
      isNetworkError(e, 'Failed to delete profile, Please try again later');
    } finally {
      self.integrationLoading = false;
    }
  });

  const deleteGoogleAnalyticsProfile = flow(function* (email: string) {
    try {
      yield integrationApi.removeConnectedGAAccount(email);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      // self.integrationLoading = false;
    }
  });

  const setShowNoGscConnectedOnPageBanner = (value: boolean) => self.showNoGscConnectedOnPageBanner = value;
  const setShowFailedCredsOnPageBanner = (value: boolean) => self.showFailedCredsOnPageBanner = value;
  const setUpdateMenuItems = value => self.updateMenuItems = value;

  /*
   *
   * @param {string} site currently selected site property url
   * returns true if any of the accounts that is connected to this site property has is_credentials_valid === false for that site property
   */
  const siteHasInvalidCredentials = (site: string) => {
    if (!Array.isArray(self.googleProfiles)) {
      return true;
    } else {
      return self.googleProfiles?.filter(profile => profile.siteproperties.filter(property => property.url === site && !property.isCredentialsValid).length);
    }
  };

  /*
   *
   * @param {string} site currently selected site property url
   * returms list of user emails where is_credentials_valid === false for that site property
   */
  const usersWhereSiteHasInvalidCredentials = (site: string) => {
    const usersArr = [];
    self.googleProfiles?.forEach(profile => profile.siteproperties.forEach(property => {
      if (property.url === site && !property.isCredentialsValid) {
        usersArr.push(profile.email);
      }
    }));

    return usersArr;
  };

  const setFromResponse = (data: any) => {
    self.googleProfiles = data;
  };

  const loadGoogleProfiles = flow(function* (isRepoll = false) {
    self.integrationLoading = true;
    let data = null;
    try {
      data = yield integrationApi.getConnectedGoogleProfiles();
      if (!data.isCancel) return setFromResponse(data);
      if (isRepoll) {
        yield new Promise(r => setTimeout(r, 5000));
        const now = new Date().getTime();
        const timeStamp:any = localStorage.getItem('connectGSCRepollTime');
        if (now - Number(timeStamp) < 1 * 60 * 1000) {
          loadGoogleProfiles(true);
        } else {
          localStorage.removeItem('connectGSCRepollTime');
        }
      }
    } catch (e) {
      isNetworkError(e, 'Failed to load google profile, Something went wrong');
    } finally {
      self.integrationLoading = false;
    }
  });

  const refreshEmail = flow(function* (email) {
    self.integrationLoading = true;
    try {
      yield integrationApi.refreshEmail(email);
      loadGoogleProfiles();
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.integrationLoading = false;
    }
  });

  return {
    setFromResponse,
    loadGoogleProfiles,
    deleteGoogleProfile,
    refreshEmail,
    deleteGoogleAnalyticsProfile,
    siteHasInvalidCredentials,
    usersWhereSiteHasInvalidCredentials,
    setShowNoGscConnectedOnPageBanner,
    setShowFailedCredsOnPageBanner,
    setUpdateMenuItems,
  };
});

export function initIntegration() {
  return Integration.create({
    googleProfiles: null,
    integrationLoading: true,
    showNoGscConnectedOnPageBanner: true,
    showFailedCredsOnPageBanner: true,
    updateMenuItems: false,
  });
}
