import styled from 'styled-components';
import {Modal} from '@/components/common-components';
import {Button} from '@/components/common-components/v2';

export const ModalStyled = styled(Modal)<{background?: string; borderRadius?: string; compare?: boolean}>`
    @media screen and (max-width: 830px) {
      width: 100% !important;
    }
  .rc-dialog-wrap {
    align-items: center !important;
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .rc-dialog-body {
    padding: ${p => p?.compare ? '10px 10px 20px 10px' : '30px 30px 10px 30px'} !important;
    border-radius: ${p => p?.borderRadius ? p?.borderRadius : '16px'} !important;
    background: ${p => p?.background ? p?.background : '#FBFBFB'} !important;
    @media screen and (max-width: 600px) {
      padding: 30px 15px 10px !important;
    }
  }
  .rc-dialog-close {
    opacity: 1 !important;
    @media screen and (max-width: 600px) {
      right: 13px;
      top: 8px;
    }
  }
  .rc-dialog-content {
    @media screen and (max-width: 830px) {
      width: 85% !important;
      margin-left: auto !important;
      margin-right: auto !important;
      max-height: 90vh !important;
      overflow-y: scroll;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  h1{
    margin: 0;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
`;

export const Description = styled.div`
  color: #4E5156;
  display: flex;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

export const DescriptionPoint = styled.div`
  color: #4E5156;
  word-wrap: break-word;
`;

export const ResultsWrapper = styled.div`
  width: 565px;
  height: 68vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  padding-right: 8px;
  padding-bottom: 20px;

  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 29px;
    @media screen and (max-width: 810px) {
      width: 0;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(78, 81, 86, 0.7);
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(78, 81, 86, 0.7);
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    max-height: 40vh;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 830px) {
    position: static;
    order: -4;
    border: 1px solid #D4D4D4;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    padding: 15px;
  }
`;

export const CardContainer = styled.div<{selected?: boolean; selectedColor?: string}>`
  background: ${p => p.selected ? `linear-gradient(0deg, ${p.selectedColor}0D, ${p.selectedColor}0D), #FFFFFF` : '#FFFFFF'};
  border: ${p => p.selected ? `1px solid ${p.selectedColor}BF` : '1px solid #E8E8E8'};
  box-shadow: ${p => p.selected ? '0px 3px 2px rgba(0, 0, 0, 0.12)' : '0px 2px 2px rgba(0, 0, 0, 0.05)'};
  border-radius: 12px;
  padding: 12px 18px;
  display: flex;
  align-items: center;
  .bottom-btn {
    display: none;
  }
  @media screen and (max-width: 655px) {
    width: 490px;
    .cardInner {
      width: 90% !important;
    }
  };
  @media screen and (max-width: 525px) {
    width: 390px;
    .bottom-btn {
      display: flex;
    }
    .right-btn {
      display: none;
    }
  };
  @media screen and (max-width: 440px) {
    width: 340px;
  }
`;

export const Numbering = styled.div<{selected?: boolean; selectedColor?: string}>`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${p => p.selected ? p?.selectedColor : '#A3A4A4'};
`;

export const SpaceDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 4px;
  flex-wrap: wrap;
`;

export const CardTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2D6CCA;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 660px) {
    max-width: 95% !important;
  }
`;

export const ViewOnGoogleBtn = styled.div`
  font-size: 10px;
  color: #4E5156;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Pill = styled.div<{background?: string}>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 10px 2px;
  width: 48px;
  height: 18px;
  background: ${p => p.background || ''};
  border-radius: 54px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
`;

export const CardKeyword = styled.div<{ellipsis?: boolean}>`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #121212;
  ${p => p.ellipsis ? `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  ` : ''}
`;

export const CardSubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
  margin-right: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ValuesDiv = styled.div`
  display: flex;
  gap: 6px;
`;

export const AnalysisHeading = styled.div`
  @media screen and (max-width: 800px) {
    font-size: 20px !important;
    line-height: 25px !important;
  }
  @media screen and (max-width: 600px) {
    font-size: 18px !important;
  }
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  height: 28px !important;
  width: 95px;
  padding: 0 10px;
  font-size: 12px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  background: #F9F9FB;
  color: #121212;
  margin: 0 !important;

  &:hover {
    border: 1px solid #CDCDCD !important;
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#CDCDCD !important;
    color: #121212 !important;
  }
  &:focus {
    border: 1px solid #CDCDCD !important;
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#CDCDCD !important;
    color: #121212 !important;
  }
  &:disabled {
    border: 1px solid #CDCDCD !important;
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#CDCDCD !important;
    color: #121212 !important;
  }
`;

export const ContentSectionWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 1200px) {
    display: block;
  }
  @media screen and (max-width: 600px) {
    #map_results_div {
      padding: 4px;
    }
    .business-card {
      width: 100% !important;
    }
  }
`;

export const MapWrapper = styled.div`
  width: 560px;
  height: 68vh;
  margin-left: 30px;
  margin-bottom: 30px;
  border-radius: 12px;
  border: 1px solid #A3A4A4;
  div div {
    border-radius: 12px;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-left: 0px;
  }
`;
