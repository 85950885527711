import {types, flow} from 'mobx-state-tree';
import {SITE_AUDITOR_API} from '@/api/SiteAuditor';

const LabelValue = types.model({
  label: types.string,
  value: types.number,
});

const ByLinkLocation = types.model({
  external: types.maybeNull(types.array(LabelValue)),
  internal: types.maybeNull(types.array(LabelValue)),
});

const DepthItem = types.model({
  0: types.maybeNull(types.number),
  1: types.maybeNull(types.number),
  2: types.maybeNull(types.number),
  3: types.maybeNull(types.number),
  4: types.maybeNull(types.number),
  5: types.maybeNull(types.number),
  orphaned: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
});

const IndexStatusItem = types.model({
  indexable: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
  nonIndexable: types.maybeNull(types.number),
});

const StatusCodeItem = types.model({
  'label': types.maybeNull(types.string),
  '2Xx': types.maybeNull(types.number),
  '3Xx': types.maybeNull(types.number),
  '4Xx': types.maybeNull(types.number),
  '5Xx': types.maybeNull(types.number),
});

const ByPageRank = types.model({
  depth: types.maybeNull(types.array(DepthItem)),
  indexStatus: types.maybeNull(types.array(IndexStatusItem)),
  statusCode: types.maybeNull(types.array(StatusCodeItem)),
});

const ByStatusCode = types.model({
  internal: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }))),
  external: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }))),
});

const ByType = types.model({
  external: types.maybeNull(types.array(LabelValue)),
  internal: types.maybeNull(types.array(LabelValue)),
});

const Header = types.model({
  brokenExternal: types.maybeNull(types.number),
  brokenInternal: types.maybeNull(types.number),
  external: types.maybeNull(types.number),
  internal: types.maybeNull(types.number),
});

const InternalAndExternalLinksByCrawlDepth = types.maybeNull(types.array(types.model({
  label: types.maybeNull(types.string),
  internal: types.maybeNull(types.number),
  external: types.maybeNull(types.number),
})));

const InternalPagesByIncomingDofollowLinks = types.maybeNull(types.array(LabelValue));

const LinksReport = types.model({
  byLinkLocation: types.maybeNull(ByLinkLocation),
  byPageRank: types.maybeNull(ByPageRank),
  byStatusCode: types.maybeNull(ByStatusCode),
  byType: types.maybeNull(ByType),
  header: types.maybeNull(Header),
  internalAndExternalLinksByCrawlDepth: types.maybeNull(InternalAndExternalLinksByCrawlDepth),
  internalPagesByIncomingDofollowLinks: types.maybeNull(InternalPagesByIncomingDofollowLinks),
  topExternalAnchors: types.maybeNull(types.array(LabelValue)),
  topInternalAnchors: types.maybeNull(types.array(LabelValue)),
  topPagesByInternalLinks: types.maybeNull(types.array(LabelValue)),
  topSubdomainsByInternalLinks: types.maybeNull(types.array(LabelValue)),
});

export const LinksReportStore = types.model({
  loading: types.boolean,
  linksReport: types.maybeNull(LinksReport),
}).actions(self => {
  const loadLinksReport = flow(function* (siteProperty: string) {
    self.loading = true;
    try {
      const response = yield SITE_AUDITOR_API.getLinksReport(siteProperty);
      self.linksReport = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });


  return {loadLinksReport};
});

export function initLinksReportStore() {
  return LinksReportStore.create({
    loading: true,
    linksReport: {
      byLinkLocation: null,
      byPageRank: null,
      byStatusCode: {
        internal: [],
        external: [],
      },
      byType: null,
      header: null,
      internalAndExternalLinksByCrawlDepth: [],
      internalPagesByIncomingDofollowLinks: [],
      topExternalAnchors: [],
      topInternalAnchors: [],
      topPagesByInternalLinks: [],
      topSubdomainsByInternalLinks: [],
    },
  });
}
