import {faX} from '@fortawesome/pro-duotone-svg-icons';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import ProfileApi from '@/api/account/customer/profile-api';
import moment from 'moment';


export const ServiceHealthAlert = () => {
  const [outages, setOutages] = useState<string[]>([]);
  const timer = useRef(null);
  const profileApi = new ProfileApi();


  const getOutage = async ()=>{
    try {
      const result = await profileApi.getOutage();
      if (!localStorage.getItem('outageCloseTime')) {
        setOutages(result?.data?.outages);
      }
    } catch (error) {
      notification.error('Error', apiError(error));
    }
  };

  const onClose = ()=>{
    localStorage.setItem('outageCloseTime', moment().format());
    clearInterval(timer.current);
    timer.current = null;
    setOutages([]);
    checkOutageTime();
  };

  const checkOutageTime = ()=>{
    let interval = null;

    interval = setInterval(()=>{
      const time = moment(localStorage.getItem('outageCloseTime'));
      const diff = moment().diff(time);
      if (diff > 1000 * 60 * 5) {
        localStorage.removeItem('outageCloseTime');
        clearInterval(interval);
        getOutage();
        startOutageInterval();
      }
    }, 1000);
  };

  const startOutageInterval = ()=> {
    clearInterval(timer.current);
    timer.current = setInterval(getOutage, 1000 * 60 * 2);
  };

  useEffect(()=>{
    const time = moment(localStorage.getItem('outageCloseTime'));
    const diff = moment().diff(time);
    if (!localStorage.getItem('outageCloseTime') || (diff > 1000 * 60 * 5)) {
      getOutage();
      startOutageInterval();
    } else {
      checkOutageTime();
    }
  }, []);

  return outages?.length > 0 ? (
    <div className={styles.serviceHealthWrapper}>
      <div/>
      <div className={styles.main}>
        <FontAwesomeIcon className={styles.xicon} color='#fff' icon={faTriangleExclamation}/>
        <div>
          <h1 className={styles.heading}>Service Outage: We&apos;re currently experiencing an issue with one or more of our services.</h1>
          <p className={styles.subHeading}>For updates and details, please visit our <a href='https://status.searchatlas.com/' rel='noreferrer' target='_blank'>status page</a>.</p>
        </div>
      </div>
      <FontAwesomeIcon onClick={onClose} icon={faX} width={12} className={styles.icon} color='#fff' height={12}/>
    </div>
  ) : null;
};
