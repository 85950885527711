import React from 'react';
import styles from './styles.module.scss';

interface SkeletonRingLoaderProps {
  width?: string; // css valid with, ex: 87px
  height?: string; // css valid heigh, ex: 400px
  align?: string; // css valid for justify-content, ex: center or left
  backgroundColor?: string;
  marginTop?: string | number;
}

export const SkeletonRingLoader: React.FC<SkeletonRingLoaderProps> = ({
  width = '100%',
  height ='134px',
  align = 'center',
  backgroundColor = '',
  marginTop = '',
}) => {
  return (
    <div className={styles.loaderContainer} style={{maxWidth: width, height: height, justifyContent: align, backgroundColor, marginTop: marginTop}}>
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
