import styled from 'styled-components';

export const FlexWrapper = styled.div<{align?: string; justifyContent?: string}>`
  display: flex;
  align-items: ${p=> p?.align};
  justify-content: ${p=> p?.justifyContent};
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7f4ead;
    border-color: #7f4ead;
  }
`;

export const HeaderCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7f4ead;
    border-color: #7f4ead;
  }
`;
export const HeadingWrapper = styled.div`
font-weight: 700;
font-size: 12px;
line-height: 14px;
color: #121212;
`;
