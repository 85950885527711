import {types, Instance} from 'mobx-state-tree';
import {RefdomainStore, initRefdomainStore} from './refdomains';
import {ProjectStore, initProjectStore} from './projects';
import {BannerVariant} from '@/components/common-components/components/banner';

export const BackLinkAnalyzerStore = types.model({
  loading: types.boolean,
  refdomainStore: RefdomainStore,
  projectStore: ProjectStore,
  banner: types.model({
    show: types.boolean,
    title: types.maybeNull(types.string),
    subtitle: types.maybeNull(types.string),
    variant: types.enumeration<BannerVariant>('BannerVariant', Object.values(BannerVariant)),
  }),
})
  .actions(self => {
    const setLoading = (loading: boolean) => {
      self.loading = loading;
    };

    const loadBacklinkAnalyzerData = (id: number) => {
      self.refdomainStore.loadRefdomains(id, '-domain_rating', 1, '');
      setLoading(false);
    };

    return {
      loadBacklinkAnalyzerData,
      setLoading,
    };
  });

export const initBackLinkAnalyzerStore = () => {
  return {
    refdomainStore: initRefdomainStore(),
    projectStore: initProjectStore(),
    loading: true,
    banner: {
      show: false,
      title: '',
      subtitle: '',
      variant: BannerVariant.ERROR,
    },
  };
};

export type BackLinkAnalyzerStoreType = Instance<typeof BackLinkAnalyzerStore>;
