import {types, flow, cast} from 'mobx-state-tree';
import {PPC_COMPETITORS_API} from '@/api/ppc-search';
import {toJS} from 'mobx';

const topKeywordsModel = types.model({
  trend: types.maybeNull(types.array(types.number)),
  keyword: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
});

const siteModel = types.model({
  topKeywords: types.array(topKeywordsModel),
  totalKeywords: types.maybeNull(types.number),
  totalEstTraffic: types.maybeNull(types.number),
  totalEstTrafficValue: types.maybeNull(types.number),
});

const topCompetitorsKeywordsModel = types.model({
  pageNumber: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  results: types.array(types.model({
    sv: types.maybeNull(types.number),
    cpc: types.maybeNull(types.number),
    trend: types.maybeNull(types.array(types.number)),
    domain: types.maybeNull(types.string),
    keyword: types.maybeNull(types.string),
    position: types.maybeNull(types.number),
    estTrafficValueUsd: types.maybeNull(types.number),
    topCompetitorsPositions: types.array(types.model({
      domain: types.maybeNull(types.string),
      position: types.maybeNull(types.number),
    })),
  })),
});

const topTwoCompetitorKeywordsOverlapModel = types.model({
  sets: types.array(types.string),
  size: types.maybeNull(types.number),
});

const sitelinksModel = types.model({
  link: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

const adsModel = types.model({
  link: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  sitelinks: types.maybeNull(types.array(sitelinksModel)),
  description: types.maybeNull(types.string),
  trackingLink: types.maybeNull(types.string),
  blockPosition: types.maybeNull(types.string),
  displayedLink: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  trafficSharePct: types.maybeNull(types.number),
  keywordSv: types.maybeNull(types.number),
  keywordCpc: types.maybeNull(types.number),
  keywordHistSv: types.maybeNull(types.array(types.number)),
  estTraffic: types.maybeNull(types.number),
  estTrafficValueUsd: types.maybeNull(types.number),
});

const competitorsModel = types.model({
  keywords: types.maybeNull(types.array(types.string)),
  domain: types.maybeNull(types.string),
  commonKeywords: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  totalEstTraffic: types.maybeNull(types.number),
  totalEstTrafficValue: types.maybeNull(types.number),
  ads: types.maybeNull(types.array(adsModel)),
});

const ppcCompetitorsModel = types.model({
  site: types.maybeNull(siteModel),
  topCompetitorsKeywords: topCompetitorsKeywordsModel,
  topTwoCompetitorKeywordsOverlap: types.maybeNull(types.array(topTwoCompetitorKeywordsOverlapModel)),
  competitors: types.array(competitorsModel),
});

const taskStatusesModel = types.model({
  gscKeywordsTaskStatus: types.maybeNull(types.string),
  svmetricsProcessingTaskStatus: types.maybeNull(types.string),
  serpsProcessingTaskStatus: types.maybeNull(types.string),
  landscapeProcessingTaskStatus: types.maybeNull(types.string),
});

export const PpcSearchReports = types.model({
  loading: types.boolean,
  ppcCompetitors: ppcCompetitorsModel,
  showProcessingBanner: types.boolean,
  processingIsActive: types.boolean,
  processingPercentage: types.maybeNull(types.number),
  taskStatuses: taskStatusesModel,
  competitorKeywordsPaginationLoading: types.boolean,
  topGoogleAdsPaginationLoading: types.boolean,
}).views(self => ({
  get getPpcCompetitors() {
    return toJS(self.ppcCompetitors);
  },
  get getPpcKeywords() {
    return toJS(self.ppcCompetitors.topCompetitorsKeywords.results);
  },
  get getPpcSites() {
    return toJS(self.ppcCompetitors.site.topKeywords);
  },
  get getCompetitors() {
    return toJS(self.ppcCompetitors.competitors);
  },
  get getTopTwoCompetitorKeywordsOverlap() {
    return toJS(self.ppcCompetitors.topTwoCompetitorKeywordsOverlap);
  },
})).actions(self => {
  const resetTaskStatuses = () => {
    self.taskStatuses.gscKeywordsTaskStatus = '';
    self.taskStatuses.svmetricsProcessingTaskStatus = '';
    self.taskStatuses.serpsProcessingTaskStatus = '';
    self.taskStatuses.landscapeProcessingTaskStatus = '';
  };

  const loadPpcCompetitors = flow(function* (url: string) {
    self.loading = true;
    try {
      const response = yield PPC_COMPETITORS_API.getPpcCompetitors(url);
      if (response) {
        if (response.taskStatuses === null) {
          // Determines should processing banner be visible
          self.showProcessingBanner = true;
          // Determine if processing has started, used to show second step of processing banner (loading process)
          self.processingIsActive = false;
          // Set processing percentage to starting point, so next site property start processing from zero
          self.processingPercentage = response.processingCompletion;
          // Reset taskStatuses to default values, so that they don't show up as finished when new site property starts processing
          resetTaskStatuses();
        } else {
          if (!Object.keys(response.taskStatuses).every(key => (response.taskStatuses[key] === 'SUCCESS' || response.taskStatuses[key] === 'FAILURE'))) {
            // Second step is active, processing is active
            self.processingIsActive = true;
            self.processingPercentage = response.processingCompletion;
            self.taskStatuses = response.taskStatuses;
            // If task status is not FAILURE or SUCCESS, poll the API every 10 secs
            yield new Promise(r => setTimeout(r, 10000));
            return loadPpcCompetitors(url);
          } else {
            self.processingPercentage = response.processingCompletion;
            if (response.site) {
              self.ppcCompetitors.site = cast(response.site);
            }
            if (response.competitors) {
              self.ppcCompetitors.competitors = cast(response.competitors);
            }
            if (response.topCompetitorsKeywords) {
              self.ppcCompetitors.topCompetitorsKeywords = cast(response.topCompetitorsKeywords);
            }
            if (response.topTwoCompetitorKeywordsOverlap) {
              self.ppcCompetitors.topTwoCompetitorKeywordsOverlap = cast(response.topTwoCompetitorKeywordsOverlap);
            }
            self.taskStatuses = response.taskStatuses;
            self.showProcessingBanner = false;
            self.processingIsActive = false;
          }
        }
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) {
          self.ppcCompetitors.site = cast({});
          self.ppcCompetitors.competitors = cast([]);
          self.ppcCompetitors.topCompetitorsKeywords = cast({});
          self.ppcCompetitors.topTwoCompetitorKeywordsOverlap = cast([]);
        }
      }
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  // API for processing new site properties
  const processPpcSiteProperty = flow(function* (url: string) {
    try {
      yield PPC_COMPETITORS_API.processSiteProperty(url);
      yield new Promise(r => setTimeout(r, 1000));
      loadPpcCompetitors(url);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  const handleTopPpcCompetitorKeywordsPagination = flow(function* (url: string, pageNumber: number, paginateGoogleAdsTable?: boolean) {
    if (paginateGoogleAdsTable) {
      self.topGoogleAdsPaginationLoading = true;
    } else {
      self.competitorKeywordsPaginationLoading = true;
    }
    try {
      const response = yield PPC_COMPETITORS_API.getPpcCompetitors(url, pageNumber);
      if (response) {
        self.ppcCompetitors.topCompetitorsKeywords = cast(response.topCompetitorsKeywords);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.competitorKeywordsPaginationLoading = false;
      self.topGoogleAdsPaginationLoading = false;
    }
  });

  return {loadPpcCompetitors, processPpcSiteProperty, handleTopPpcCompetitorKeywordsPagination};
});

export function initPpcSearchReportsStore() {
  return PpcSearchReports.create({
    loading: true,
    ppcCompetitors: {
      site: {},
      topCompetitorsKeywords: {},
      topTwoCompetitorKeywordsOverlap: [],
      competitors: [],
    },
    showProcessingBanner: false,
    processingIsActive: false,
    processingPercentage: 0,
    competitorKeywordsPaginationLoading: false,
    topGoogleAdsPaginationLoading: false,
    taskStatuses: {
      gscKeywordsTaskStatus: '',
      svmetricsProcessingTaskStatus: '',
      serpsProcessingTaskStatus: '',
      landscapeProcessingTaskStatus: '',
    },
  });
}
