import styles from './index.module.scss';
import {observer} from 'mobx-react-lite';
import {Form, Slider, Select, Input, Alert, Tooltip} from 'antd';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2/Button';
import React, {useEffect, useState} from 'react';
import {getSingleUrlParam, isSAPublicPage} from '@/utils/url';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {faSquareInfo} from '@fortawesome/pro-duotone-svg-icons';
import {UrlExclusionSection} from '../../site-audit-v2/common/CrawlSettingModal';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {LargeBadge} from '@/components/common-components/components/badge';
import {formatCrawlRules} from '../../site-audit-v2/audited-sites/siteCard';
import {parseCrawlRules} from '@/components/common-components/v2/crawlSettingsModal';
import {PRO_PLAN_ID} from '@/constants';
import {faLock} from '@fortawesome/pro-solid-svg-icons';
import {SwitchStyled} from './styledComponents';
const {Option} = Select;

interface Props {
  selectedProject?: any;
  setOpenModel?: (value: boolean) => void;
  data?: any;
  form?: any;
}

const CrawlSettings: React.FC<Props> = observer(({data, setOpenModel, selectedProject, form}) => {
  const {
    ottoV2Store: {
      loadOttoV2Project,
    },
    siteAuditorV2: {
      loadAuditedSitesList,
      handleCrwalSettings,
      loadingCrawlUpdate,
      crawlBudgetError,
      setCrawlBudgetError,
    },
    plans: {
      showSidebarPaymentDrawer,
    },
  } = useStore('');
  const {
    settings: {
      customer: {setAuthModal, profile: {plan}},
    },
  } = useStore('');
  const [crawlSpeedVal, setCrawlSpeedVal] = useState(5);
  const [crawlBudgetVal, setCrawlBudgetVal] = useState(100);
  const [userAgent, setUserAgent] = useState(null);
  const [crawlFrequency, setCrawlFrequency] = useState('');
  const [userAgentDetail, setUserAgentDetail] = useState('');
  // const [showCrawlModal, setShowCrawlModal] = useState(false);
  const [crawlSelectOption, setCrawlSelectOption] = useState(null);
  const uuid = getSingleUrlParam('uuid');
  const [conditions, setConditions] = useState([{action: 'Exclude', rule: 'Contain', value: ''}]);
  const excludeCondition = (conditions?.filter(condition => condition?.action === 'Exclude')?.length > 0);
  const [crawlCriteria, setCrawlCriteria] = useState<boolean>(false);

  const onCrawlFinish = async (value: any) => {
    const dataToPatch = {
      ...value,
      should_respect_robots_txt: crawlCriteria,
      selected_user_agent: userAgent,
      crawl_concurrency: crawlSpeedVal,
      crawl_rules: formatCrawlRules(conditions),
    };
    if (!isSAPublicPage()) {
      dataToPatch['crawl_budget'] = crawlBudgetVal;
      dataToPatch['next_processing_delta_days'] = crawlFrequency;
    }
    await handleCrwalSettings(selectedProject?.siteAudit, dataToPatch);
    setOpenModel(false);
    if (uuid) {
      await loadOttoV2Project(uuid, true);
    }
    loadAuditedSitesList(true);
  };

  const onCrawlSpeedChange = (value: any) => {
    setCrawlSpeedVal(value);
  };

  const onFrequencyChange = (value: any) => {
    const val = Number(value);
    if (val == 0) {
      setCrawlFrequency('');
    } else {
      if (val < 31 && val > -1) {
        setCrawlFrequency(val?.toString());
      }
    }
  };
  const onCrawlSpeedInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (value.target.value?.match(/^[0-9]+$/)) {
      if (val < 51) {
        setCrawlSpeedVal(val);
      }
    } else if (value.target.value == '') {
      setCrawlSpeedVal(0);
    }
  };

  const onCrawlBudgetChange = (value: any) => {
    if (!isSAPublicPage()) {
      setCrawlBudgetVal(value);
    }
  };

  const onCrawlBudgetInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (value.target.value?.match(/^[0-9]+$/)) {
      if (val < 10001) {
        setCrawlBudgetVal(val);
      }
    } else if (value.target.value == '') {
      setCrawlBudgetVal(0);
    }
  };
  const onUserAgentChange = (value: any) => {
    form.setFieldsValue({selected_user_agent: value});
    setUserAgent(value);
    setUserAgentDetail(userAgentData[value]);
  };

  const userAgentData = {
    google_chrome_desktop: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.113 Safari/537.36',
    google_chrome_mobile: 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75 Mobile/14E5239e Safari/602.1',
    googlebot_desktop: 'Mozilla/5.0 (compatible; Googlebot/2.1)',
    googlebot_mobile: 'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36 (compatible; Googlebot/2.1)',
    bingbot_desktop: 'Mozilla/5.0 (compatible; bingbot/2.0)',
    bingbot_mobile: 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11A465 Safari/9537.53 (compatible; bingbot/2.0)',
    slurp: 'Mozilla/5.0 (compatible; Yahoo! Slurp)',
    yandexbot: 'Mozilla/5.0 (compatible; YandexBot/3.0)',
    baiduspider: 'Baiduspider+',
    screaming_frog: 'Screaming Frog SEO Spider/2.11',
    duckduckgo: 'Mozilla/5.0 (compatible; DuckDuckGo-Favicons-Bot/1.0)',
    searchatlas: 'SearchAtlas Bot (https://www.searchatlas.com)',
  };

  useEffect(() => {
    if (crawlSelectOption) {
      switch (crawlSelectOption) {
        case 'daily':
          setCrawlFrequency('1');
          break;
        case 'weekly':
          setCrawlFrequency('7');
          break;
        case 'monthly':
          setCrawlFrequency('30');
          break;
        case 'never':
          setCrawlFrequency('0');
          break;
        default:
          break;
      }
    }
  }, [crawlSelectOption]);

  useEffect(() => {
    const siteAuditCrawlSettings = data?.siteAuditCrawlSettings;
    if (siteAuditCrawlSettings) {
      if (siteAuditCrawlSettings?.selectedUserAgent) {
        setUserAgent(siteAuditCrawlSettings?.selectedUserAgent);
        form.setFieldsValue({selected_user_agent: siteAuditCrawlSettings?.selectedUserAgent});
      }
      siteAuditCrawlSettings?.crawlRules && setConditions(parseCrawlRules(siteAuditCrawlSettings?.crawlRules));
      siteAuditCrawlSettings?.crawlBudget && setCrawlBudgetVal(siteAuditCrawlSettings?.crawlBudget);
      siteAuditCrawlSettings?.crawlConcurrency && setCrawlSpeedVal(siteAuditCrawlSettings?.crawlConcurrency);
      setCrawlCriteria(siteAuditCrawlSettings?.shouldRespectRobotsTxt);
      if (plan !== PRO_PLAN_ID && siteAuditCrawlSettings?.nextProcessingDeltaDays && siteAuditCrawlSettings?.nextProcessingDeltaDays < 7) {
        setCrawlFrequency('7');
        setCrawlSelectOption('weekly');
        form.setFieldsValue({crawl_frequency: 'weekly'});
      } else {
        if (siteAuditCrawlSettings?.nextProcessingDeltaDays) {
          switch (siteAuditCrawlSettings?.nextProcessingDeltaDays) {
            case 1:
              setCrawlFrequency('1');
              setCrawlSelectOption('daily');
              form.setFieldsValue({crawl_frequency: 'daily'});
              break;
            case 7:
              setCrawlFrequency('7');
              setCrawlSelectOption('weekly');
              form.setFieldsValue({crawl_frequency: 'weekly'});
              break;
            case 30:
              setCrawlFrequency('30');
              setCrawlSelectOption('monthly');
              form.setFieldsValue({crawl_frequency: 'monthly'});
              break;
            case 0:
              setCrawlFrequency('0');
              setCrawlSelectOption('never');
              form.setFieldsValue({crawl_frequency: 'never'});
              break;
            default:
              if (typeof siteAuditCrawlSettings?.nextProcessingDeltaDays === 'number' && siteAuditCrawlSettings?.nextProcessingDeltaDays > 0) {
                setCrawlFrequency(String(siteAuditCrawlSettings?.nextProcessingDeltaDays));
                setCrawlSelectOption('Custom');
                form.setFieldsValue({crawl_frequency: 'Custom'});
              }
              break;
          }
        }
      }
    }
  }, []);
  const handleConditionChange = (index: number, key: string, value: string) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][key] = value;
    setConditions(updatedConditions);
  };
  const handleDeleteCondition = (index: number) => {
    const updatedConditions = conditions.filter((_, i) => i !== index);
    const excludeCondition = (conditions?.filter(condition => condition?.action === 'Exclude')?.length > 1);
    if (updatedConditions?.length && !excludeCondition) {
      const excludeUpdatedCondition = [...updatedConditions];
      excludeUpdatedCondition[0] = {...excludeUpdatedCondition[0], action: 'Exclude'};
      setConditions(excludeUpdatedCondition);
    } else {
      setConditions(updatedConditions);
    }
  };
  const handleAddCondition = () => {
    if (conditions?.length) {
      setConditions([...conditions, {action: 'Exclude', rule: 'Contain', value: ''}]);
    } else {
      setConditions([{action: 'Exclude', rule: 'Contain', value: ''}]);
    }
  };

  const getDailyOptionLabel = () => {
    return plan !== PRO_PLAN_ID ? (
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: 'flex', gap: 6, alignItems: 'center'}}>
          Daily
          <div className='lockIcon' style={{backgroundColor: '#ededed', borderRadius: 50, fontSize: 12, paddingInline: 8, display: 'flex', alignItems: 'center', gap: 4, color: '#121212'}}>
            <FontAwesomeIcon icon={faLock} fontSize={12} />
            Pro only
          </div>
        </div>
        <UpgradeButton className='upgradeButton' type='button' onClick={() => showSidebarPaymentDrawer()}>Upgrade</UpgradeButton>
      </div>
    ) : 'Daily';
  };

  const isCrawlFrequencyError = () => {
    return plan !== PRO_PLAN_ID && crawlFrequency && Number(crawlFrequency) > 0 && Number(crawlFrequency) < 7;
  };

  return (
    <>
      <SiteAuditWrapper>
        {crawlBudgetError && <div style={{paddingTop: '10px', paddingBottom: '20px'}}> <Alert
          // message='Error Text'
          description={crawlBudgetError}
          type='error'
          closable
          onClose={() => setCrawlBudgetError('')}
        /> </div>}
        <Form onFinish={onCrawlFinish} form={form}>
          {/* <Form.Item style={{fontSize: 12}} label='Device Type'>
              <CommonInputField placeholder='Desktop'/>
            </Form.Item> */}
          <StyledFormItem
            name='selected_user_agent'
            rules={[{required: true, message: 'This field is required'}]}
          >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <p style={{fontSize: 12, marginTop: 15, color: '#121212'}}>User Agent</p>
              <StyledSelect
                suffixIcon={<FontAwesomeIcon color={'#121212'} style={{marginBottom: '8px'}} icon={faCaretDown} />}
                style={{width: 275, marginTop: 9}}
                className={styles.selectStyle}
                showSearch
                value={userAgent}
                placeholder='Select user agent'
                optionFilterProp='children'
                onChange={onUserAgentChange}
                filterOption={(input, option) =>
                  (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value='google_chrome_desktop'>Google Chrome Desktop</Option>
                <Option value='google_chrome_mobile'>Google Chrome Mobile</Option>
                <Option value='googlebot_desktop'>Googlebot Desktop</Option>
                <Option value='googlebot_mobile'>Googlebot Mobile</Option>
                <Option value='bingbot_desktop'>Bingbot Desktop</Option>
                <Option value='bingbot_mobile'>Bingbot Mobile</Option>
                <Option value='slurp'>Slurp</Option>
                <Option value='yandexbot'>Yandexbot</Option>
                <Option value='baiduspider'>Baiduspider</Option>
                <Option value='screaming_frog'>Screaming frog</Option>
                <Option value='duckduckgo'>Duckduckgo</Option>
                <Option value='searchatlas'>Searchatlas</Option>
              </StyledSelect>
            </div>
            {/* <CommonInputField placeholder='Mozila (mobile)'/> */}
            <CenterContent style={{marginLeft: 190, marginBottom: 0}}>{userAgentDetail} </CenterContent>
          </StyledFormItem>
          <StyledFormItem name='crawl_budget' style={isSAPublicPage() ? {pointerEvents: 'none', filter: 'opacity(0.5)'} : {}}>
            <div style={{display: 'flex', justifyContent: 'space-between', height: '20px'}}>
              <p style={{fontSize: 12, color: '#121212'}}>Pages to Crawl</p>
              <Slider
                style={{width: 265, marginTop: 0}}
                value={crawlBudgetVal}
                trackStyle={{backgroundColor: '#2D6CCA', height: 7}}
                className={styles.sliderStyle}
                defaultValue={data?.crawlBudgetVal}
                max={10000}
                min={1}
                onChange={onCrawlBudgetChange}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '130px'}}>
              <span style={{marginTop: 4, fontSize: 12, color: '#4E5156'}}>Top </span>
              <span>
                <div style={{width: '65px', marginLeft: 10, marginRight: 10}}>
                  <RoundInput
                    tabIndex={-1}
                    onChange={onCrawlBudgetInputChange}
                    value={crawlBudgetVal}
                    defaultValue={data?.crawlBudgetVal}
                  />
                </div>
              </span>
              <span style={{fontSize: 12, marginTop: 4, color: '#4E5156'}}>pages</span>
            </div>
            <div style={{fontSize: 12, marginTop: 4, marginLeft: 100, color: '#4E5156'}}>
              {/* Quota: {`${numberWithCommas(utilizedQuota)}/ ${numberWithCommas(totalQuota)}`} */}
            </div>
            {/* <CenterContent style={{marginBottom: 0}}>Top {crawlBudgetVal} Pages</CenterContent> */}
          </StyledFormItem>
          <StyledFormItem>
            <CrawlCriteria>
              <p style={{fontSize: 12, color: '#121212', marginBottom: 0}}>Crawl Criteria</p>
              <div style={{width: '218px', height: 35, marginLeft: 10, marginRight: 58, display: 'flex', gap: '10px', alignItems: 'center'}}>
                <SwitchStyled checked={crawlCriteria} onChange={checked=>{
                  setCrawlCriteria(checked);
                }}/>
                <span style={{width: '125px'}}>{crawlCriteria ? 'Respect' : 'Ignore'} robots.txt</span>
              </div>
            </CrawlCriteria>
          </StyledFormItem>
          <StyledFormItem
            name='crawl_concurrency'
            rules={[
              {
                pattern: /^(?:\d*)$/,
                message: 'Value should contain just number',
              },
              {
                pattern: /^[\d]{0,50}$/,
                message: 'Value should be less than 50 character',
              },
            ]}
          >
            <div style={{display: 'flex', justifyContent: 'space-between', height: '20px'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                <p style={{fontSize: 12, color: '#121212'}}>Crawl Speed</p>
                <Tooltip
                  overlayInnerStyle={{padding: '10px', backgroundColor: '#121212', borderRadius: '12px'}}
                  title={'Increase speed if you have large site for quick page crawls'}>
                  <FontAwesomeIcon icon={faSquareInfo} color='#A3A4A4' style={{marginBottom: '12px'}} />
                </Tooltip>
              </div>
              <Slider
                style={{width: 265, marginTop: 0}}
                value={crawlSpeedVal}
                trackStyle={{backgroundColor: '#2D6CCA', height: 7}}
                className={styles.sliderStyle}
                defaultValue={data?.crawlConcurrency ?? 5}
                max={50}
                onChange={onCrawlSpeedChange}
                min={1}

              />
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '116px'}}>
              <span style={{marginTop: 6, fontSize: 12, width: '115px', color: '#4E5156'}}>Pages per second</span>
              <span style={{width: '40px'}}><div>
                <RoundInput
                  onChange={onCrawlSpeedInputChange}
                  value={crawlSpeedVal}
                  defaultValue={data?.crawlConcurrency}
                />
              </div></span>
            </div>
            {/* <CenterContent style={{marginBottom: 0}}>Parallel Requests: {crawlSpeedVal}</CenterContent> */}
          </StyledFormItem>
          <StyledFormItem
            name='crawl_frequency'
            style={isSAPublicPage() ? {pointerEvents: 'none', filter: 'opacity(0.5)'} : {}}
            rules={[{required: true, message: 'This field is required'}]}
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <p style={{fontSize: 12, marginTop: 15, color: '#121212'}}>Crawl Frequency</p>
              <CrawlFrequencySelect
                suffixIcon={<FontAwesomeIcon color={'#121212'} icon={faCaretDown} />}
                style={{width: 275, marginTop: 9, borderRadius: '8px'}}
                className={styles.selectStyle}
                defaultValue={crawlSelectOption}
                showSearch
                tabIndex={-1}
                disabled={isSAPublicPage()}
                placeholder='Select Crawl Frequency'
                optionFilterProp='children'
                onChange={value => {
                  form.setFieldsValue({crawl_frequency: value});
                  setCrawlSelectOption(value);
                }}
                value={isSAPublicPage() ? `Daily (Registered only)` : crawlSelectOption}
              >
                <Option value='daily' disabled={plan !== PRO_PLAN_ID}><Tooltip title={plan !== PRO_PLAN_ID ? 'Available in PRO plan' : ''}>{getDailyOptionLabel()}</Tooltip></Option>
                <Option value='weekly'>Weekly</Option>
                <Option value='monthly'>Monthly</Option>
                <Option value='Custom'>Custom</Option>
                <Option value='never'>Never</Option>

              </CrawlFrequencySelect>
            </div>
            {crawlSelectOption == 'Custom' &&
                  <div style={{display: 'flex', marginLeft: 190}}>
                    <span style={{marginTop: 4, fontSize: 12, color: '#121212'}}>Every  </span>
                    <span>
                      <div style={{width: '65px', marginLeft: 10, marginRight: 10}}>
                        <RoundInput
                          crawlFrequency={crawlFrequency}

                          value={crawlFrequency}
                          onChange={e => {
                            onFrequencyChange(e?.target?.value);
                          }
                          }
                        />
                      </div>
                    </span>
                    <span style={{fontSize: 12, marginTop: 4, color: '#121212'}}>Days</span>
                  </div>}
            {isCrawlFrequencyError() && <div style={{color: 'red', marginLeft: 190}}>The crawl frequency must be at least 7 days</div>}

          </StyledFormItem>
          <div>
            <div style={{fontSize: 12, marginBottom: 10, display: 'flex'}}>URL Exclusion conditions<LargeBadge beta/></div>
            {conditions?.map((condition, index) => (
              <UrlExclusionSection key={index} style={{display: 'flex', gap: 8}}>
                <div key={index} className='condition-row'>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#4E5156', gap: 8}}>
                    <div style={{display: 'flex', color: '#4E5156', gap: 8}}>
                      <div>#{index+1}</div>
                      <div>
                        <Select dropdownMatchSelectWidth={false}
                          getPopupContainer={node=> node}
                          value={condition.action}
                          className='condition-dropdown'
                          onChange={value => handleConditionChange(index, 'action', value)}
                          suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                        >
                          <Option id='testm' value='Exclude'>Exclude</Option>
                          <Option id='testm' value='Include' disabled={!excludeCondition}>
                            <Tooltip title={!excludeCondition && 'Please an Exclude condition first'}>
                                    Include
                            </Tooltip>
                          </Option>
                        </Select>
                        <span style={{marginRight: 5}}>all URLs that</span>
                        <Select
                          getPopupContainer={node=> node}
                          dropdownMatchSelectWidth={false}
                          value={condition.rule}
                          className='condition-dropdown'
                          onChange={value => handleConditionChange(index, 'rule', value)}
                          suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                        >
                          <Option value='Contain'>contain</Option>
                          <Option value='Start with'>start with</Option>
                        </Select>
                        <Input
                          width={470}
                          placeholder='Type here e.g /blog/ ....'
                          className='condition-input'
                          value={condition.value}
                          onChange={e => handleConditionChange(index, 'value', (e.target.value)?.trim())}
                        />
                      </div>
                      <FontAwesomeIcon icon={faTrash} style={{fontSize: 14, color: '#A3A4A4', marginLeft: -25, marginTop: 2, cursor: 'pointer'}} onClick={() => handleDeleteCondition(index)}/>
                    </div>
                  </div>
                </div>
              </UrlExclusionSection>
            ))}
            <div onClick={handleAddCondition} style={{color: '#2D6CCA', fontSize: 12, cursor: 'pointer'}}>
                    + Add condition
            </div></div>
          {isSAPublicPage() && <KeywordBanner>
            <span onClick={() => {
              setAuthModal(true, false);
            }}>Start a free trial</span>&nbsp;
                to customize crawl settings.</KeywordBanner>}

          <Footer>
            <button className='cancel-button' type='button' onClick={() => {
              setOpenModel(false); setCrawlBudgetError('');
            }}>Cancel</button>
            <Tooltip title={(conditions?.length && !excludeCondition) ? 'Please add an Exclude condition first' : ''}>
              <div>
                <UpdateAiSettings disabled={Number(crawlFrequency) <= -1 || (conditions?.length && !excludeCondition) || isCrawlFrequencyError()} htmlType='submit' loading={loadingCrawlUpdate}>Update Crawl Settings</UpdateAiSettings>
              </div>
            </Tooltip>
          </Footer>

        </Form>
      </SiteAuditWrapper>
    </>
  );
});

export default CrawlSettings;

const SiteAuditWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 14px;

  .existing-site {
    display: flex;
    align-items: center;
    color: #121212;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    line-height: normal;
    color: #2D6CCA;
    cursor: pointer;
    gap: 8px;
    margin-bottom: 16px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 5px !important;
  .ant-form-item-explain-error {
    margin-left: 41%;
  }
`;

const UpdateAiSettings = styled(Button)`
  width: 196px;
  height: 44px;
  border-radius: 8px;
  background-color: #2D6CCA !important;
  color: #fff !important;
  &:hover {
      background-color: #2D6CCA !important;
    }
  &:focus {
           background-color: #2D6CCA !important;
    }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 31px;
  margin-top: 30px;
  .cancel-button {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
    background-color: transparent;
    border: 0px;
  }
`;

const KeywordBanner = styled.div`
  width: 100%;
  height:40px;
  background:#2D6CCA26;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  color:#121212 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
 span {
  color: #2D6CCA;
  text-decoration: underline;
  cursor: pointer;
 }
`;

const CenterContent = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #4E5156;
`;

// const ButtonWrapper = styled.div`
//   border-top: 1px solid #e8e8e8;
//   padding-top: 20px;
//   display:flex;
//   justify-content: right;
//   align-items: center;
// `;

const RoundInput = styled(Input) <{ crawlFrequency?: string }>`
  border-radius: 5px;
  font-size: 12px;
  border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
    box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    &:focus {
      border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    }
    &:hover {
      border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};

    }
`;

const StyledSelect = styled(Select)`
.ant-select-selector{
  border-radius: 8px !important;
}
  @media screen and (max-width: 390px) {
    .ant-select-arrow {
      top: 26%;
    } 
  }
`;

const UpgradeButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #2D6CCA;
`;

const CrawlFrequencySelect = styled(StyledSelect)`
.lockIcon {
    color: #121212 !important;
  }
  .ant-select-selection-item {
    .upgradeButton, .lockIcon {
      display: none !important;
    }
  }
`;

const CrawlCriteria = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    
    .ant-switch-checked {
      background: #2e904e !important;
    }
`;
