import {faWordpress} from '@fortawesome/free-brands-svg-icons';
import {faFeather, faGear, faImage, faLinkSimple, faSquareXmark, faBuilding} from '@fortawesome/pro-duotone-svg-icons';


export const colSpan = 12;
export const rowgutter = 20;
export const threeColSpan = 8;
export const threeColgutter = 12;
export const writingOptionsColSpan = 12.2;
export const writingOptionsRowgutter = 16;

export const aiModelOptions = [{label: 'ChatGPT (Open AI)', value: 'gpt'}, {label: 'Claude', value: 'claude'}, {label: 'Groq', value: 'groq'}];
export const shortToneOfVoiceOptions = ['Casual', 'Professional', 'Funny', 'Angry', 'Baby Yoda'];
export const pointOfViewOptions = ['First person', 'Second person', 'Third person'];
export const contentLengthOptions = ['Short', 'Medium', 'Long'];

export const searchIntents = [
  {label: 'Informational', value: 'informational'},
  {label: 'Commercial', value: 'commercial'},
  {label: 'Navigational', value: 'navigational'},
  {label: 'Transactional', value: 'transactional'},
];

export const navigation = [
  {label: 'General', slug: 'general', icon: faGear, id: 1},
  {label: 'Writing', slug: 'writing', icon: faFeather, id: 2},
  {label: 'Terms to exclude', slug: 'terms_to_exclude', icon: faSquareXmark, id: 3},
  {label: 'Images', slug: 'images', icon: faImage, id: 4},
  {label: 'Links to include', slug: 'links_to_include', icon: faLinkSimple, id: 5},
  {label: 'Company Info', slug: 'company_info', icon: faBuilding, id: 6},
  {label: 'WordPress', slug: 'wordPress', icon: faWordpress, id: 7},
];

export const writingRightOptions = [
  {label: 'Include lists', key: 'use_list', tooltipText: '40% chance to include markdown bullet and numeric lists.'},
  {label: 'Include tables', key: 'use_table', tooltipText: '30% chance to include tables.'},
  {label: 'Include tease', key: 'use_tease', tooltipText: 'Include a transition paragraph at the end of h2 sections (adds ~35 words per H2 Section).'},
  {label: 'Disable conclusion', tooltipText: 'Disable the last paragraph that is generating a conclusion for the entire article.', key: 'disable_conclusion'},
];

export const writingLeftOptions = [
  {label: 'Generate meta data', tooltipText: 'Generate Meta Title and Meta Description.', key: 'generate_metadata'},
  {label: 'Disable short paragraphs', tooltipText: '', key: 'disable_skinny_paragraph'},
  {label: 'Add key takeaways', tooltipText: 'Add a key takeaway section after the introduction.', key: 'enable_key_takeaways'},
  {label: 'Include table of contents', tooltipText: '', key: 'table_of_contents'},
  {label: 'Bold Focus Terms in AI Content', key: 'bold_terms_to_include', tooltipText: ''},
];

export const aspectRatioOptions = [{
  name: 'Article image (8:3)',
  value: '8:3',
}, {
  name: 'Wide screen image (16:9)',
  value: '16:9',
}, {
  name: 'Square image (1:1)',
  value: '1:1',
}, {
  name: 'Vertical image (2:3)',
  value: '2:3',
}];

export const transformToStateFormat = (requiredLinks, optionalLinks) => {
  const requiredItems = requiredLinks && requiredLinks.map(link => ({
    value: link?.url || link,
    anchorText: typeof(link?.anchor) == 'string' ? link?.anchor : '',
    label: 'Link',
    placeholder: 'Link',
    fields: [],
    required: true,
  }));

  const optionalItems = optionalLinks && optionalLinks.map(link => ({
    value: link.url || link,
    anchorText: typeof(link?.anchor) == 'string' ? link?.anchor : '',
    label: 'Link',
    placeholder: 'Link',
    fields: [],
    required: false,
  }));
  if (requiredItems == null && optionalItems == null) return;

  const items = [...(requiredItems?.length ? requiredItems : []), ...(optionalItems?.length ? optionalItems : [])];
  return {
    isEmpty: requiredItems?.length === 0 && optionalItems?.length === 0,
    items: [...items],
  };
};

export const readingLevel = ['5th grade', '6th grade', '7th grade', '8th & 9th grade', '10th to 12th grade', 'College', 'College graduate', 'Professional'];
