import {Instance, types} from 'mobx-state-tree';

export const CompetitorModel = types.model({
  hostname: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  snippets: types.maybeNull(types.array(types.string)),
});
const HeadingsHierarchyModel = types.model({
  content: types.maybeNull(types.string),
  level: types.maybeNull(types.number),
  tag: types.maybeNull(types.string),
});

const SingleCompetitorModel = types.model({
  contentScore: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  hostName: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  readability: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  headingsHierarchy: types.maybeNull(types.array(HeadingsHierarchyModel)),
  internalLinks: types.maybeNull(types.array(types.string)),
  externalLinks: types.maybeNull(types.array(types.string)),
  h1Count: types.maybeNull(types.number),
  h2Count: types.maybeNull(types.number),
  h3Count: types.maybeNull(types.number),
  schema: types.maybeNull(types.frozen()),
});
export const CompetitorDataModel = types.model({
  keyword: types.string,
  competitors: types.array(SingleCompetitorModel),
});

export const FocusTermCompetitorModel = types.model({
  count: types.maybeNull(types.number),
  results: types.array(CompetitorModel),
});
const ViewerUserModel = types.model({
  id: types.number,
  name: types.string,
  isMe: types.boolean,
  isStaff: types.boolean,
});
export const ViewerModel = types.model({
  user: ViewerUserModel,
  isEditor: types.boolean,
});
export const HeaderModel = types.model({
  type: types.maybeNull(types.string),
  values: types.maybeNull(types.array(types.string)),
  count: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
});
export const ImageModel = types.model({
  alt: types.maybeNull(types.string),
  src: types.maybeNull(types.string),
  size: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  loading: types.maybeNull(types.string),
  visitable: types.maybeNull(types.boolean),
});
export const IframeModel = types.model({
  name: types.maybeNull(types.string),
  src: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  visitable: types.maybeNull(types.boolean),
});
export const VideoModel = types.model({
  autoplay: types.maybeNull(types.string),
  src: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  size: types.maybeNull(types.string),
  muted: types.maybeNull(types.string),
  loop: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  visitable: types.maybeNull(types.boolean),
});
export const LinkModel = types.model({
  url: types.maybeNull(types.string),
  rel: types.maybeNull(types.array(types.string)),
  ordinal: types.maybeNull(types.number),
  anchor: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.string),
  isRedirect: types.maybeNull(types.boolean),
  statusCode: types.maybeNull(types.number),
});
export const SocialModel = types.model({
  type: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
export const JSModel = types.model({
  src: types.maybeNull(types.string),
  visitable: types.maybeNull(types.boolean),
});
export const avgSpeedIndexModel = types.model({
  avgSpeedIndex: types.maybeNull(types.model({
    score: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
  })),
});
export const QuestionsModel = types.model({
  keyword: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  usedCount: types.maybeNull(types.number),
});
export const FocusTermsGridItem = types.model({
  keyword: types.maybeNull(types.string),
  contentScores: types.maybeNull(types.array(types.maybeNull(types.number))),
  focusTermUsageBySites: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    values: types.maybeNull(types.array(types.number)),
  }))),
  sites: types.maybeNull(types.array(types.model({
    hostname: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
  }))),
});
export const KeywordSuggestionsGridItem = types.model({
  keyword: types.maybeNull(types.string),
  keywordSuggestionsUsageBySites: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    values: types.maybeNull(types.array(types.number)),
  }))),
  sites: types.maybeNull(types.array(types.model({
    hostname: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    wordCount: types.maybeNull(types.number),
    contentScore: types.maybeNull(types.number),
  }))),
});
const CitationSerpsModel = types.model({
  metaDesc: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
export const CommonCitationsModel = types.model({
  count: types.maybeNull(types.number),
  metaDesc: types.maybeNull(types.string),
  serps: types.maybeNull(types.array(CitationSerpsModel)),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
export const countriesModel = types.model({
  position: types.maybeNull(types.number),
  locationId: types.maybeNull(types.number),
  simpleLocation: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  reach: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
});
export const suggestedWordCountLimitsModel = types.model({
  lower: types.maybeNull(types.number),
  upper: types.maybeNull(types.number),
});
const InternalLinkModel = types.model({
  dt: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  path: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  snippet: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
  isUsed: types.maybeNull(types.boolean),
  isBookmarked: types.maybeNull(types.boolean),
  keywords: types.maybeNull(types.array(types.string)),
});
export const InternalLinkSuggestionsModel = types.model({
  keyword: types.maybeNull(types.string),
  results: types.maybeNull(types.array(InternalLinkModel)),
});
export const InternalLinkSuggestionsModelV2 = types.model({
  allLinks: types.maybeNull(types.array(InternalLinkModel)),
  clusteredLinks: types.maybeNull(types.array(InternalLinkSuggestionsModel)),
  keywords: types.maybeNull(types.array(types.string)),
});

export const ExtendedScoreModel = types.model({
  avg: types.maybeNull(types.number),
  top: types.maybeNull(types.number),
});
export const ScoreCountsModel = types.model({
  internalLinks: types.maybeNull(types.array(types.maybeNull(types.string))),
  internalLinksMax: types.maybeNull(types.number),
  internalLinksMin: types.maybeNull(types.number),
  externalLinks: types.maybeNull(types.array(types.maybeNull(types.string))),
  externalLinksMin: types.maybeNull(types.number),
  externalLinksMax: types.maybeNull(types.number),
  h1: types.maybeNull(types.number),
  h2: types.maybeNull(types.number),
  h3: types.maybeNull(types.number),
  h1Max: types.maybeNull(types.number),
  h1Min: types.maybeNull(types.number),
  h2Max: types.maybeNull(types.number),
  h2Min: types.maybeNull(types.number),
  h3Max: types.maybeNull(types.number),
  h3Min: types.maybeNull(types.number),
  headings: types.maybeNull(types.number),
  headingsMax: types.maybeNull(types.number),
  headingsMin: types.maybeNull(types.number),
  paragraphs: types.maybeNull(types.number),
  paragraphsMax: types.maybeNull(types.number),
  paragraphsMin: types.maybeNull(types.number),
  images: types.maybeNull(types.number),
  imagesMax: types.maybeNull(types.number),
  imagesMin: types.maybeNull(types.number),
});


export type QuestionModel = Instance<typeof QuestionsModel>;
export type SingleCompetitorType = Instance<typeof SingleCompetitorModel>;
export type CommonCitationsType = Instance<typeof CommonCitationsModel>;
export type InternalLinkType = Instance<typeof InternalLinkModel>;
