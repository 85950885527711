import {types, flow} from 'mobx-state-tree';

import {initPagesStore, Pages} from './pages';
import {initCurrentPage, CurrentPage} from './currentPage';
import {initFocusTermsStore, FocusTermsPage} from './focus-terms';
import {initContentIdeas, ContentIdeas} from './contentIdeas';
import {initContentFragment, ContentFragment} from './contentFragment';
import {initPruning, Pruning} from './pruningPage';
import {initAiOutline, AiOutline} from './aiOutline';
import {initWpPublishStore, WpPublishStore} from './wpPublish';
import {initAiMetaDataStore, AiMetaData} from './aiMetaData';

export const ContentOptimizerStore = types
  .model({
    pages: Pages,
    currentPage: CurrentPage,
    focusTermsPage: FocusTermsPage,
    contentIdeas: ContentIdeas,
    aiOutline: AiOutline,
    contentFragment: ContentFragment,
    pruningPage: Pruning,
    wpPublish: WpPublishStore,
    aiMetaData: AiMetaData,
  })
  .actions(self => {
    const loadCurrentPage = (uuid: string) => {
      try {
        self.currentPage.loadPage(uuid);
      } catch (e) {
        return e;
      }
    };

    const loadFocusTermsPage = flow(function* () {
      try {
        yield self.focusTermsPage.retrieveFocusTerms();
      } catch (err) {
        return Promise.reject(err);
      }
    });

    return {
      loadCurrentPage,
      loadFocusTermsPage,
    };
  });

export const initContentOptimizerStore = () => {
  return ContentOptimizerStore.create({
    pages: initPagesStore(),
    currentPage: initCurrentPage(),
    focusTermsPage: initFocusTermsStore(),
    contentIdeas: initContentIdeas(),
    aiOutline: initAiOutline(),
    contentFragment: initContentFragment(),
    pruningPage: initPruning(),
    wpPublish: initWpPublishStore(),
    aiMetaData: initAiMetaDataStore(),
  });
};
