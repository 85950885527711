import React from 'react';
import classnames from 'classnames';
import {Pagination as PaginationAntd} from 'antd';
import {PaginationProps as PaginationPropsAntd} from 'antd/lib/pagination';
import {useTranslation} from 'next-i18next';
import styles from './styles.module.scss';

interface PaginationProps extends PaginationPropsAntd {
  typeCase?: string;
  showTenThousandPlus?: boolean;
}


export const Pagination: React.FC<PaginationProps> = ({
  className, typeCase, showTenThousandPlus, ...props
}) => {
  const {t} = useTranslation('common');

  return (
    <PaginationAntd
      className={classnames(styles.pagination, className, styles[typeCase])}
      showTotal={(total, [from, to]) => {
        return (
          <span className={styles.fromTo}>
            {from}-{to} {t('pagination-of')} {showTenThousandPlus ? '10000+' : total} {t('pagination-results-shown')}
          </span>
        );
      }}
      showLessItems
      {...props}
    />
  );
};
