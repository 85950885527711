import React from 'react';
import classnames from 'classnames';
import {Pagination as PaginationAntd} from 'antd';
import {PaginationProps as PaginationPropsAntd} from 'antd/lib/pagination';
import styles from './styles.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAnglesLeft} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

interface PaginationProps extends PaginationPropsAntd {
  typeCase?: string;
}


export const NewPagination: React.FC<PaginationProps> = ({
  className, typeCase, ...props
}) => {
  const itemRender = (page, type) => {
    if (type == 'prev') {
      return <div>Prev</div>;
    }
    if (type == 'page' && page == 1) {
      return <div><FontAwesomeIcon icon={faAnglesLeft}/></div>;
    }
    if (type == 'next') {
      return <div>Next Page</div>;
    }
    return '';
  };
  return (
    <div className={styles.paginationContainer}>
      <StyledPagination
        className={classnames(styles.pagination, className, styles[typeCase])}
        showTotal={(total, [from, to]) => {
          return (
            <span className={styles.fromTo}>
              {from}-{to} results
            </span>
          );
        }}
        showLessItems
        showSizeChanger={false}
        itemRender={itemRender}
        {...props}
      />
    </div>
  );
};

const StyledPagination = styled(PaginationAntd)`
  .ant-pagination-total-text {
    height: auto !important;
  }
`;
