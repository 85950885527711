import React, {ReactNode} from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface PageHeaderProps {
  headerContent?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  headerContent,
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={classnames(styles.commonHeader, className)}>
      {headerContent ? headerContent : children}
    </div>
  );
};
