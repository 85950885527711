import {AUDITED_SITES_API_V2} from '@/api/SiteAuditorV2';
import {notification} from '@/utils/notification-v2';
import {types, cast, flow} from 'mobx-state-tree';
import {toJS} from 'mobx';

const emailRepientsModel = types.model({
  isActive: types.maybeNull(types.boolean),
  recipient: types.maybeNull(types.string),
});

const settingsListModel = types.model({
  alertType: types.maybeNull(types.string),
  emailEnabled: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  isIndividualAlert: types.maybeNull(types.boolean),
  emailRecipients: types.maybeNull(types.array(emailRepientsModel)),
  loading: types.optional(types.boolean, false),
});

const userSettingsAlertsListModel = types.model({
  key: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  isIndividualAlert: types.maybeNull(types.boolean),
});

const alerSettingModel = types.model({
  id: types.maybeNull(types.number),
  alertSubtype: types.maybeNull(types.string),
  alertType: types.maybeNull(types.string),
  emailEnabled: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  recipients: types.maybeNull(types.array(types.string)),
  loading: types.optional(types.boolean, false),
});
export const EmailSettings = types.model({
  userSettingsList: types.maybeNull(types.array(settingsListModel)),
  userSettingsAlertsList: types.maybeNull(types.array(userSettingsAlertsListModel)),
  loadingUserSettingList: types.maybeNull(types.boolean),
  loadingAlertSetting: types.maybeNull(types.boolean),
  swithLoading: types.maybeNull(types.string),
  alertSettingList: types.maybeNull(types.array(alerSettingModel)),
  updatedList: types.maybeNull(types.string),

}).views(self => ({
  get getUserSettingList() {
    return toJS(self.userSettingsList);
  },
  get getUserSettingsAlertsList() {
    return toJS(self.alertSettingList);
  },
})).actions(self => {
  const setUpdatedList=value=>{
    self.updatedList= value;
  };
  const loadListUserSettings = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.loadingUserSettingList = true;
    }
    try {
      const response = yield AUDITED_SITES_API_V2.getEmailSettingUsers();
      if (response?.length) {
        self.userSettingsList = cast(response);
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingUserSettingList = false;
    }
  });
  const loadAlertSetting = flow(function* (id, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingAlertSetting = true;
    }
    try {
      const response = yield AUDITED_SITES_API_V2.getEmailAlertSettingUsers(id);
      if (response?.length) {
        self.alertSettingList = cast(response?.map((item, idx) => {
          return {
            ...item,
            id: idx+1,
          };
        }));
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingAlertSetting = false;
    }
  });

  const listAvailableEmailAlerts = flow(function* () {
    try {
      const response = yield AUDITED_SITES_API_V2.getListAvailableEmailAlerts();
      if (response?.length) {
        self.userSettingsAlertsList = cast(response);
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingDepthNodes = false;
    }
  });

  const activateEmailAlert = flow(function* (id) {
    const getIndex = toJS(self.userSettingsList)?.findIndex(item => item?.id == id);
    self.userSettingsList[getIndex].emailEnabled = true;
    self.userSettingsList[getIndex].loading = true;
    try {
      const response = yield AUDITED_SITES_API_V2.onActivateEmailAlert(id);
      if (response?.id) {
        loadListUserSettings(true);
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingDepthNodes = false;
    }
  });

  const deactivateEmailAlert = flow(function* (id) {
    const getIndex = toJS(self.userSettingsList)?.findIndex(item => item?.id == id);
    self.userSettingsList[getIndex].emailEnabled = false;
    self.userSettingsList[getIndex].loading = true;
    try {
      const response = yield AUDITED_SITES_API_V2.onDeactivateEmailAlert(id);
      if (response?.id) {
        loadListUserSettings(true);
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingDepthNodes = false;
    }
  });

  const handleAddRecepient = flow(function* (id, emails) {
    try {
      const response = yield AUDITED_SITES_API_V2.onAddEmailRecepient(id, emails);
      if (response?.id) {
        loadListUserSettings(true);
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingDepthNodes = false;
    }
  });
  const handleAddRecepientAlert = flow(function* (key, emails, siteId) {
    try {
      const response = yield AUDITED_SITES_API_V2.onAddNewAlert(key, emails, siteId);
      if (response?.id) {
        loadListUserSettings(true);
      }
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingDepthNodes = false;
    }
  });

  const updateEmailAlert = flow(function* (data, isMain?: boolean) {
    if (Object.keys(data)?.includes('enabled')) {
      if (!isMain) {
        const getIndex = toJS(self.alertSettingList)?.findIndex(item => item?.id == data?.id);
        self.alertSettingList[getIndex].emailEnabled = true;
        self.alertSettingList[getIndex].loading = true;
      } else {
        self.swithLoading = data?.alert_type;
      }
    }
    try {
      yield AUDITED_SITES_API_V2.onUpdateEmailAlert(data);
      loadAlertSetting(data?.audit, true);
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingDepthNodes = false;
      self.swithLoading = '';
    }
  });

  return {
    loadListUserSettings,
    listAvailableEmailAlerts,
    activateEmailAlert,
    updateEmailAlert,
    deactivateEmailAlert,
    handleAddRecepient,
    handleAddRecepientAlert,
    loadAlertSetting,
    setUpdatedList,
  };
});

export function initEmailSettings() {
  return EmailSettings.create({
    loadingUserSettingList: true,
    loadingAlertSetting: true,
    swithLoading: '',
    alertSettingList: [],
    updatedList: '',
    // userSettingsList: [],
  });
}
