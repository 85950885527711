import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {getSingleUrlParam} from '@/utils/url';

interface Props {
  children: React.ReactNode;
}

const PublicPageFreezeWrapper = observer(({children} : Props) => {
  const isPublicPage = !!getSingleUrlParam('public_hash');

  if (isPublicPage) {
    return (
      <span style={{cursor: 'not-allowed'}} className='freeze-wrapper-span'>
        <Wrapper className='freeze-wrapper'>
          {children}
        </Wrapper>
      </span>
    );
  } else {
    return (
      <>
        {children}
      </>
    );
  }
});

export default PublicPageFreezeWrapper;

const Wrapper = styled.div`
  pointer-events: none;
  opacity: 0.8;
  display: inline-block;
  white-space: nowrap;
  cursor: not-allowed;
`;
