import {Dropdown, Menu, Input, Empty} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark, faCheck, faCaretDown, faSearch} from '@fortawesome/pro-solid-svg-icons';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {Banner, StyledIssuesCollapse} from '../../../../style';
import {showNotificationMess} from '../../../../Constants/functions';
import {newNotification} from '@/utils/notification-v3';
import FreezeWrapper from '../../../freezTooltip';
import {StyledSwitch} from '../../../GbpAudit/GbpOptimization/styles';
import {camelCaseToHumanize} from '@/utils/string';
import {notification} from '@/utils/notification-v2';
import {StyledSpin} from '../../../../Utils/styledSpin';
import {CustomTextareaV2} from '@/components/common-components/components/form/input/customTextareaV2';
import {DeployStatusButton, DropdownContainer, LabelWrapper, MenuStyled, SchemaWrapper, TopWrapper} from './StyledComponents';
import {schemaMarkupOrganizationTypes} from '@/utils/const';
import {apiError} from '@/utils/api';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: string;
  maxHeight?: string;
  removeTopBar?: boolean;
}

export const OrganizationSchema = observer(({componentIssueType, setPageChanged}: Props) => {
  const {ottoV2Store: {
    getOttoV2Project,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    updateOttoV2Project,
    setIsKnowledgeModalVisible,
  }} = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const [currentProject, setCurrentProject] = useState(null);
  const [typeSearchTerm, setTypeSearchTerm] = useState('');
  const [organizationType, setOrganizationType] = useState(null);
  const [updatingOrganization, setUpdatingOrganization] = useState(false);
  const [deployingSchema, setDeployingSchema] = useState(false);

  useEffect(() => {
    if (getOttoV2Project?.organizationSchemaType) {
      setOrganizationType(getOttoV2Project?.organizationSchemaType);
    }
  }, [getOttoV2Project?.organizationSchemaType]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const deployOttoSection = async (toDeploy: boolean) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    setDeployingSchema(true);
    try {
      await deployOttoUrls({toDeploy, issueType: componentIssueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(componentIssueType);
      setIsDeploying(true);
      if (uuid) {
        await loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(`Changes Deployed Successfully`, 2, 'deploy');
      } else {
        newNotification(`Changes Rolled Back`, 2, 'rollback');
      }
      setDeployingSchema(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setDeployingSchema(false);
    }
  };

  const organizationTypeList = typeSearchTerm?.trim() ? schemaMarkupOrganizationTypes?.filter(type => type?.value?.toLowerCase()?.trim()?.includes(typeSearchTerm?.toLowerCase()?.trim())) : schemaMarkupOrganizationTypes;

  const updateOrganizationType = async (newType: string) => {
    setUpdatingOrganization(true);
    try {
      await updateOttoV2Project(getOttoV2Project?.uuid || uuid, {organization_schema_type: newType});
      setPageChanged(true);
      setIssueTypeSelected(componentIssueType);
      setIsDeploying(true);
      await loadOttoV2Project(uuid, true);
    } catch (error) {
      const errorMessage = apiError(error);
      notification.error(errorMessage, 'Something went wrong');
    } finally {
      setUpdatingOrganization(false);
    }
  };

  const onSelectType = type => {
    setOrganizationType(type);
    updateOrganizationType(type);
  };

  const organizationMenu = (
    <MenuStyled>
      <div className='title'>Organization types</div>
      {organizationType !== 'SportsOrganization' && <div className='recommended'>Recommended: <Menu.Item className='recommendedOption' style={{color: '#2D6CCA', cursor: 'pointer'}} onClick={() => onSelectType('SportsOrganization')}>Sports Organization</Menu.Item></div>}
      <div className='optionsContainer'>
        <Input value={typeSearchTerm} onChange={e => setTypeSearchTerm(e?.target?.value)} className='searchInput' placeholder='Search...' prefix={<FontAwesomeIcon icon={faSearch} color='#A3A4A4' />} />
        <div className='options'>
          {organizationTypeList?.length ? organizationTypeList?.map(type => (
            <Menu.Item key={type?.key} style={{background: type?.key === organizationType ? 'rgba(24, 146, 59, 0.15)' : ''}} onClick={() => type?.key !== organizationType && onSelectType(type?.key)}>
              <div className='optionItem'>
                {type?.value}
                {type?.key === organizationType && <FontAwesomeIcon icon={faCheck} color='#2AC155' />}
              </div>
            </Menu.Item>
          )) : <Empty style={{marginTop: 6}} />}
        </div>
      </div>
    </MenuStyled>
  );

  const getStringifiedSchema = schemaText => {
    try {
      return JSON.stringify(schemaText, null, 4);
    } catch (error) {
      return '';
    }
  };

  const getNumberOfRows = jsonString => {
    return jsonString?.split('\n').length || 0;
  };
  const rows = Math.max(getNumberOfRows(getStringifiedSchema(getOttoV2Project?.organizationSchema)), 5);

  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
    >
      <Banner style={{maxHeight: 32}}>
        <FontAwesomeIcon color='#2D6CCA' icon={faCircleInfo}/>
        <div>Schema is utilizing information about your business from the Knowledge Graph.</div>
        <div className='open-knowledge-graph' onClick={() => {
          setIsKnowledgeModalVisible(true);
          setIssueTypeSelected(componentIssueType);
        }}>Click here to edit the data.</div>
      </Banner>

      <TopWrapper>
        <LabelWrapper>
          <div className='label'>Organization type</div>
          <FreezeWrapper>
            <Dropdown overlay={organizationMenu} trigger={['click']} disabled={updatingOrganization || deployingSchema} onOpenChange={visible => {
              if (!visible) {
                setTypeSearchTerm('');
              }
            }}>
              <DropdownContainer isDisabled={updatingOrganization || deployingSchema}>
                <div>{organizationType ? schemaMarkupOrganizationTypes?.find(type => type?.key === organizationType)?.value || camelCaseToHumanize(organizationType) : <span style={{color: '#9e9e9e'}}>Select an organization</span>}</div>
                {updatingOrganization ? <StyledSpin fontSize={12} color='#2D6CCA' /> : <FontAwesomeIcon icon={faCaretDown} />}
              </DropdownContainer>
            </Dropdown>
          </FreezeWrapper>
        </LabelWrapper>

        <LabelWrapper>
          <div className='label'>Status</div>
          <DeployStatusButton>
            <FreezeWrapper>
              <div className='button-container' style={{background: getOttoV2Project?.organizationSchemaIsApproved ? '#18923B26' : '#F4434326', cursor: (updatingOrganization || deployingSchema) ? 'not-allowed' : ''}} onClick={() => !updatingOrganization && !deployingSchema && deployOttoSection(!getOttoV2Project?.organizationSchemaIsApproved)}>
                <StyledSwitch disabled={updatingOrganization || deployingSchema} loading={deployingSchema} lockOn={getOttoV2Project?.organizationSchemaIsApproved} checked={getOttoV2Project?.organizationSchemaIsApproved} checkedChildren={<FontAwesomeIcon fontSize={12} icon={faCheck} color='#18923B' />}
                  unCheckedChildren={<FontAwesomeIcon icon={faXmark} color='#F44343' fontSize={12} />}/>
                <span>{getOttoV2Project?.organizationSchemaIsApproved ? 'Schema Deployed' : 'Schema not deployed'}</span>
              </div>
            </FreezeWrapper>
          </DeployStatusButton>
        </LabelWrapper>
      </TopWrapper>


      <LabelWrapper style={{marginTop: 20}}>
        <div className='label'>Schema preview</div>
        <SchemaWrapper>
          {getOttoV2Project?.organizationSchema ? <CustomTextareaV2 wrapperClassname='schemaTextarea' rows={rows || 20} minHeight='100%' variant='white' value={getOttoV2Project?.organizationSchema ? getStringifiedSchema(getOttoV2Project?.organizationSchema) : null} onChange={() => {}} /> : <Empty style={{margin: '12px 0'}} />}
          {updatingOrganization && <div className='loaderWrapper'>
            <StyledSpin fontSize={100} color='#2D6CCA' />
          </div>}
        </SchemaWrapper>
      </LabelWrapper>
    </StyledIssuesCollapse>
  );
});
