import styled from 'styled-components';
import {Button, Col, Menu, Table} from 'antd';
import {ButtonsWrapper, ModalStyled, Description, Heading} from '../styledComponents';

export const Modal = styled(ModalStyled)`
  margin-top: 100px;
  .rc-dialog-content {
    background-color: #f9f9fb !important;
  }
  .rc-dialog-close {
    font-size: 28px !important;
    color: white !important;
    left: 102% !important;
    top: -3px !important;
    opacity: 1 !important;
  }
  .ant-table-thead th {
    background-color: #f7f7f7 !important;
  }
`;

export const HeadingTitle = styled(Heading)`
justify-content: space-between;
    display: flex;
`;

export const UrlDescription = styled(Description)`
  color: #2D6CCA !important;
  .fa-arrow-up-right-from-square{
    margin-left: 5px;
  }
`;

export const ButtonsWrapperInner = styled(ButtonsWrapper)`
  justify-content: start !important;
  .orSpan {
    margin: 0 0 0 20px;
  }
  .firstBtn {
    width: 210px !important;
  }
  .lastBtn {
    background-color: rgba(45, 108, 202, 0.15);
    color: #2D6CCA;
  }
`;

export const ModalConfig = styled.p`
  margin: 0 18px 0 0;
  align-items: center;
  span {
    display: flex;
    align-items: center;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
    p {
      margin-bottom: 0;
    }
    .detail {
      width: 200px;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }
  }
`;

export const TableStyle = styled(Table)<{background?: string}>`
  height: 300px;
  background: ${p => p?.background};
  .ant-table-body {
    max-height: 233px !important;
  }
`;

export const IconPack = styled.div`
  svg {
    margin: 0 13px 0 0;
    height: 18px;
  }
`;

export const AddContact = styled.p`
  cursor: pointer;
  width: fit-content;
  align-items: center;
  display: flex;
  margin: 0px 0 10px 0;
  font-size: 14px;
  color: #2D6CCA;
  svg {
    margin: 0px 4px 0 0 !important;
    height: 15px;
  }
`;

export const InputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
`;

export const ImageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  background: #F9F9FB;
`;

export const ColStyled = styled(Col)`
  padding: 0 20px 20px 20px;
  .ant-upload {
    height: fit-content !important;
    background: white !important;
    padding: 0 !important;
    border: none;
  }
`;

export const PrevNextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6.75px;
  margin-bottom: 9px;
`;

export const PrevNextButton = styled(Button)<{height?: string | null}>`
  display: flex;
  align-items: center;
  gap: 6px;
  height: ${p => p?.height ? p?.height : '27px'};
  border-radius: 6px;
  background-color: #F3F3F7;
  font-family: Inter;
  font-size: 14px;
  color: #000000;
  border: 0px;
  &:hover, &:focus {
    background-color: #F3F3F7;
    color: #000000;
    border: 0px;
  }
`;

export const StyledMenu = styled(Menu)`
  width: 220px;
  border-radius: 12px !important;
  .ant-dropdown-menu-item {
    border-radius: 12px !important;
  }
  .divider {
    width: 100%;
    height: 2px;
    background: #D9D9D9;
  }
`;
