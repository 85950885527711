import {cast, flow, types} from 'mobx-state-tree';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {QUEST_API} from '@/api/quest';
import {toJS} from 'mobx';
import {SDAQuestMainTableFilterEnum} from '@/constants';

const SDA_QUEST_MAIN_TABLE_FILTER_LIST = [
  {id: 1, name: 'search_simple', header: SDAQuestMainTableFilterEnum.query, text: '', type: undefined, active: false, isSearch: true},
];

const Sources = types.model({
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const NestedResults = types.model({
  answer: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  sources: types.maybeNull(types.array(Sources)),
});

const DprCampaignModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  domains: types.maybeNull(types.array(types.string)),
  processingStatus: types.maybeNull(types.string),
});

const QuestListItem = types.model({
  createdAt: types.maybeNull(types.string),
  dprCampaign: types.maybeNull(DprCampaignModel),
  id: types.maybeNull(types.number),
  query: types.maybeNull(types.string),
  results: types.maybeNull(types.array(NestedResults)),
  taskErrorMessage: types.maybeNull(types.string),
  taskStatus: types.maybeNull(types.string),
  taskStatusUpdatedAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});


const SDAQuestMainTableFilterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  active: types.boolean,
  isSearch: types.maybeNull(types.boolean),
  customFilterValue: types.optional(types.string, ''),
  query: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  filterField: types.maybeNull(types.string),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
    inputType: types.maybeNull(types.string),
  }))),
});

export const QuestStore = types.model({
  loadingQuestList: types.boolean,
  questList: types.maybeNull(types.array(QuestListItem)),
  count: types.maybeNull(types.number),
  createQuestLoader: types.boolean,
  questDetail: types.maybeNull(QuestListItem),
  questDetailLoader: types.boolean,
  questMainTableFilterList: types.array(SDAQuestMainTableFilterListModel),
  deleteQuestLoader: types.boolean,
  page: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  sortingKey: types.maybeNull(types.string),
  shouldRepollQuest: types.boolean,
  shouldRepollQuestDetail: types.boolean,
}).views(self => ({
  get getQuestList() {
    return toJS(self.questList);
  },
  get getSingleQuestDetail() {
    return toJS(self.questDetail);
  },
})).actions(self => {
  const setPaginationSize = ({page, pageSize}) => {
    self.page = page;
    self.pageSize = pageSize;
  };

  const setQuestDetailLoader = (value: boolean) => {
    self.questDetailLoader = value;
  };
  const setShouldRepollQuest = (value: boolean) => {
    self.shouldRepollQuest = value;
  };
  const setShouldRepollQuestDetail = (value: boolean) => {
    self.shouldRepollQuestDetail = value;
  };
  const setQueryName = (params: {id: number; query: string}) => {
    self.questDetail = cast(params);
  };

  const loadQuestList = flow(function* (stopLoading=false) {
    if (!stopLoading) {
      self.loadingQuestList = true;
    }
    try {
      const params = {
        page: self.page,
        page_size: self.pageSize,
        ...(self.sortingKey && {ordering: self.sortingKey}),
        ...(self.questMainTableFilterList[0]?.query && {query: self.questMainTableFilterList[0].query}),
      };
      const data = yield QUEST_API.loadQuestList(params);
      if (data.isCancel) return;
      self.questList = cast(data?.results);
      self.count = cast(data?.count);
      self.loadingQuestList = false;
      if (data?.results?.find(x => x.taskStatus === 'PENDING') && self.shouldRepollQuest) {
        yield new Promise(r => setTimeout(r, 3000));
        return loadQuestList(true);
      }
    } catch (e) {
      self.loadingQuestList = false;
      const errorMessage = apiError(e) as string;
      notification.error('Error while getting quest', errorMessage);
    }
  });

  const getQuestDetail = flow(function* (id) {
    self.questDetailLoader = true;
    try {
      const data = yield QUEST_API.getQuestDetail(id);
      if (data.isCancel) return;
      if ((data?.taskStatus === 'PENDING' || data?.taskStatus === 'STARTED') && self.shouldRepollQuestDetail) {
        yield new Promise(r => setTimeout(r, 3000));
        return getQuestDetail(id);
      } else if (data?.taskStatus === 'FAILURE' || data?.taskStatus === 'SUCCESS') {
        self.questDetail = cast(data);
        self.shouldRepollQuestDetail = false;
        self.questDetailLoader = false;
      }
    } catch (e) {
      self.questDetailLoader = false;
      const errorMessage = apiError(e) as string;
      notification.error('Error while getting quest', errorMessage);
    }
  });

  const createQuest = flow(function* (payload) {
    self.createQuestLoader = true;
    try {
      const data = yield QUEST_API.createQuest(payload);
      if (data.isCancel) return;
      self.createQuestLoader = false;
      return data;
    } catch (e) {
      self.createQuestLoader = false;
      const errorMessage = apiError(e) as string;
      notification.error('Error while creating quest', errorMessage);
    }
  });

  const deleteQuest = flow(function* (id) {
    self.deleteQuestLoader = true;
    try {
      const data = yield QUEST_API.deleteQuest(id);
      if (data.isCancel) return;
      notification.success('Success', 'Quest deleted successfully!');
      self.deleteQuestLoader = false;
    } catch (e) {
      self.deleteQuestLoader = false;
      const errorMessage = apiError(e) as string;
      notification.error('Error while deleting quest', errorMessage);
    }
  });

  const updateQuestMainTableFilterList = filters => {
    self.questMainTableFilterList = cast(filters);
    loadQuestList();
  };

  const setSortingKey = sortingKey => {
    self.sortingKey = sortingKey;
    loadQuestList();
  };

  return {
    loadQuestList,
    createQuest,
    getQuestDetail,
    updateQuestMainTableFilterList,
    deleteQuest,
    setPaginationSize,
    setQuestDetailLoader,
    setSortingKey,
    setShouldRepollQuest,
    setShouldRepollQuestDetail,
    setQueryName,
  };
});

export const initQuestStore = () => {
  return QuestStore.create({
    loadingQuestList: true,
    count: null,
    createQuestLoader: false,
    questDetailLoader: false,
    questMainTableFilterList: SDA_QUEST_MAIN_TABLE_FILTER_LIST,
    deleteQuestLoader: false,
    page: 1,
    pageSize: 10,
    sortingKey: '',
    shouldRepollQuest: true,
    shouldRepollQuestDetail: true,
  });
};

