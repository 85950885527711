import {types, flow, cast} from 'mobx-state-tree';
import {reportsApi} from '@/api/gsc/index';
import {getFilter, formatSorters} from '@/utils/filters';
import {CriteriaType} from '@/store//gsc-store/criteria';
import {getPrecedingDate} from '@/utils/arrays';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {camelCase, isNil} from 'lodash';
import {getSingleUrlParamHash} from '@/utils/url';
const KEYWORD_FILTER_LIST = [
  {id: 1, name: 'countryCode', header: 'Country', customFilterValue: '', type: 'customFilter', active: true},
  {id: 2, name: 'startDate', header: 'Start Date', customFilterValue: '', type: 'customFilter', active: true},
  {id: 3, name: 'endDate', header: 'End Date', customFilterValue: '', type: 'customFilter', active: true},
  {id: 4, name: 'exclude', header: 'Keyword', text: '', type: undefined, active: false, isSearch: true},

  {id: 5, name: 'posCur', header: 'Position', customFilterValue: '', from: undefined, to: undefined, type: undefined, active: false,
    customOptionsTop: [
      {name: 'Top 3', min: '1', max: '3'},
      {name: 'Top 10', min: '1', max: '10'},
      {name: 'Top 20', min: '1', max: '20'},
      {name: 'Top 50', min: '1', max: '50'},
    ], customOptions: [
      {name: '#1', min: '0', max: '1'},
      {name: '#2-3', min: '2', max: '3'},
      {name: '#4-10', min: '4', max: '10'},
      {name: '#11-20', min: '11', max: '20'},
      {name: '#21-50', min: '21', max: '50'},
      {name: '#51-100', min: '51', max: '100'},
    ]},
  {id: 6, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'},
  ]},
  {id: 8, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '1,000,001+', min: '1000001', max: ''},
    {name: '100,001 - 1,000,000', min: '100001', max: '1000000'},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '0 - 100', min: '0', max: '100'},
  ]},
  {id: 7, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
  {id: 9, name: 'vol', header: 'Search Volume', from: undefined, to: undefined, type: undefined, active: false},
  {id: 10, name: 'val', header: 'Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
  {id: 11, name: 'potentialTrafficValueCur', header: 'Potential Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
  {id: 12, name: 'potentialTrafficCur', header: 'Potential Traffic', from: undefined, to: undefined, type: undefined, active: false},
  {id: 13, name: 'radioButtonsFilter', header: 'Watchlisted', filterTypes: [{label: 'Yes', value: 'exclude'}, {label: 'No', value: ''}], type: undefined, active: false},
];

const filterList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  filterField: types.maybeNull(types.string),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
});

const keywordsData = types.model({
  kw: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  pagesNum: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  searchIntent: types.maybeNull(types.string),
  isWatchlisted: types.maybeNull(types.boolean),
});

const keywordsTableDateRangeModel = types.model({
  pagesPeriod1Start: types.maybeNull(types.string),
  pagesPeriod1End: types.maybeNull(types.string),
  pagesPeriod2Start: types.maybeNull(types.string),
  pagesPeriod2End: types.maybeNull(types.string),
});
const updateKeyWordPage=types.model({
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
});
const KeywordMovementsModel = types.model({
  date: types.maybeNull(types.string),
  improved: types.maybeNull(types.number),
  declined: types.maybeNull(types.number),
  new: types.maybeNull(types.number),
  lost: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
});
export const keywordMovementsNew = types.model({
  keywords: types.array(keywordsData),
  updateKeyWordPages: types.array(updateKeyWordPage),
  keywordsCount: types.maybeNull(types.number),
  keywordsLoading: types.boolean,
  searchIntentLoading: types.boolean,
  isKeywordInit: types.boolean,
  keywordPageNumber: types.number,
  keywordPageSize: types.number,
  keywordFilterList: types.array(filterList),
  keywordsTableDateRange: types.maybeNull(keywordsTableDateRangeModel),
  keywordSortField: types.maybeNull(types.string),
  keywordSortDirection: types.maybeNull(types.string),
  keywordSearchTerm: types.maybeNull(types.string),
  exportToCSVLoader: types.boolean,
  loadingKeywordMovements: types.maybeNull(types.boolean),
  keywordMovements: types.maybeNull(types.array(KeywordMovementsModel)),
}).views(self => ({
  get keywordsData() {
    return toJS(self.keywords);
  },
  get getUpdateKeywordPages() {
    return toJS(self.updateKeyWordPages);
  },
  get getKeywordFilterList() {
    return toJS(self.keywordFilterList);
  },

})).actions(self => {
  const onKeywordPaginationChange = (pageNumber: number, pageSize: number) => {
    self.keywordPageNumber = pageNumber;
    self.keywordPageSize = pageSize;
  };

  const onKeywordFilterChange = (filters: any) => {
    self.keywordPageNumber = 1;
    self.keywordFilterList = cast(filters);
  };

  const onKeywordTableChange = (sortFields: string, order: string) => {
    self.keywordSortField = sortFields;
    self.keywordSortDirection = order;
  };

  const onKeywordUpdateSearchTerm = (searchTerm: string) => {
    self.keywordPageNumber = 1;
    self.keywordSearchTerm = searchTerm;
  };

  const updateKeywordColumnsList =properties=>{
    // console.log('properties', properties);
    self.updateKeyWordPages=properties;
    // self.pages = properties;
  };
  const clearFilterList = () => {
    self.keywordPageNumber = 1;
    self.keywordFilterList = cast(KEYWORD_FILTER_LIST);

    return KEYWORD_FILTER_LIST;
  };

  const loadKeywordMovements = flow(function* ({property, countryCode, currentPeriodStart, currentPeriodEnd}) {
    const publicHash = getSingleUrlParamHash('public_hash');
    const params = {
      selected_property: property,
      period_start: currentPeriodStart,
      period_end: currentPeriodEnd,
      country_code: countryCode,
      ...(publicHash && {site_share_hash: publicHash}),
    };

    self.loadingKeywordMovements = true;
    try {
      const response = yield reportsApi.getKeywordMovementsReportNew(params);
      if (response.isCancel) return;

      self.keywordMovements = cast(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingKeywordMovements = false;
    }
  });

  const loadKeywords = flow(function* ({property, countryCode, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, pageNumber, pageSize, filters, filterKeywordTerm, keywordPositionType}) {
    const publicHash = getSingleUrlParamHash('public_hash');
    const sorters = {
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      cpc: 'cpc',
      vol: 'volume',
      page: 'page',
      opportunity_score: 'opportunity_score',
      value: 'value',
      watchlist: 'watchlist',
      potentialTrafficCur: 'potential_traffic_cur',
      potentialTrafficValueCur: 'potential_traffic_value_cur',
      pagesNum: 'pages_num',
    };

    const formtatedSorters = formatSorters(sorters, self.keywordSortField, self.keywordSortDirection);
    const getStartDate = getFilter(filters, 'startDate')?.customFilterValue || currentPeriodStart;
    const getEndDate = getFilter(filters, 'endDate')?.customFilterValue || currentPeriodEnd;
    const keywordTablePeriod1Start = getPrecedingDate(getStartDate, getEndDate)?.previousStartDate ?? previousPeriodStart;
    const keywordTablePeriod1End = getPrecedingDate(getStartDate, getEndDate)?.previousEndDate ?? previousPeriodEnd;

    const keywordTablePeriod2Start = getFilter(filters, 'startDate')?.customFilterValue || currentPeriodStart;
    const keywordTablePeriod2End = getFilter(filters, 'endDate')?.customFilterValue || currentPeriodEnd;

    self.keywordsTableDateRange = {
      pagesPeriod1Start: keywordTablePeriod1Start,
      pagesPeriod1End: keywordTablePeriod1End,
      pagesPeriod2Start: keywordTablePeriod2Start,
      pagesPeriod2End: keywordTablePeriod2End,
    };

    const params = {
      selected_property: property,
      period1_start: keywordTablePeriod1Start ?? previousPeriodStart,
      period1_end: keywordTablePeriod1End ?? previousPeriodEnd,
      period2_start: keywordTablePeriod2Start,
      period2_end: keywordTablePeriod2End,
      country_code: getFilter(filters, 'countryCode')?.customFilterValue !== '' ? getFilter(filters, 'countryCode')?.customFilterValue ?? countryCode : countryCode,
      keyword: filters?.find(item=>item.header.toLowerCase()=='keyword')?.query ?? filterKeywordTerm,
      keyword_operator: filters?.find(item=> item.header.toLowerCase()== 'keyword')?.operator ?? 'contains',
      page_size: pageSize,
      page_number: pageNumber,
      position_from: getFilter(filters, 'posCur')?.from,
      position_to: getFilter(filters, 'posCur')?.to,
      clicks_from: getFilter(filters, 'clicksCur')?.from,
      clicks_to: getFilter(filters, 'clicksCur')?.to,
      impressions_from: getFilter(filters, 'impCur')?.from,
      impressions_to: getFilter(filters, 'impCur')?.to,
      cpc_from: getFilter(filters, 'cpc')?.from,
      cpc_to: getFilter(filters, 'cpc')?.to,
      volume_from: getFilter(filters, 'vol')?.from,
      volume_to: getFilter(filters, 'vol')?.to,
      value_from: getFilter(filters, 'val')?.from,
      value_to: getFilter(filters, 'val')?.to,
      opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
      potential_traffic_cur_form: getFilter(filters, 'potentialTrafficCur')?.from,
      potential_traffic_cur_to: getFilter(filters, 'potentialTrafficCur')?.to,
      potential_traffic_value_cur_form: getFilter(filters, 'potentialTrafficValueCur')?.from,
      potential_traffic_value_cur_to: getFilter(filters, 'potentialTrafficValueCur')?.to,
      sort_by: formtatedSorters,
      ...(publicHash && {site_share_hash: publicHash}),
    };
    if (filters?.find(z => z?.active && z?.name == 'radioButtonsFilter')) {
      params['watchlist_filter'] = !!getFilter(filters, 'radioButtonsFilter')?.type;
    }

    params['keyword_position_type'] = keywordPositionType;

    try {
      self.keywordsLoading = true;
      const response = yield reportsApi.getKeywordDataTableReports(params);
      if (response.keywordsTable) {
        const newList = [];
        for (let index1 = 0; index1 < toJS(response.keywordsTable.results)?.length; index1++) {
          const element1 = toJS(response.keywordsTable.results)[index1];
          newList?.push({
            ...element1,
            searchIntent: null,
          });
        }
        self.keywords = cast(newList);
        self.isKeywordInit = false;
        self.keywordsCount = response.keywordsTable.count;
        if (response?.keywordsTable?.results?.length && !publicHash) {
          loadSearchIntent(response?.keywordsTable?.results?.map(item => item.kw));
        }
      }
      self.keywordsLoading = false;
    } catch (e) {
      self.keywordsLoading = true;
      if (e.response?.status == 400) {
        notification.error('Error loading data', e.response?.data?.nonFieldErrors?.length ? e.response?.data?.nonFieldErrors[0] : '');
      } else {
        console.warn('Error loading data', e);
      }
      return Promise.reject(e);
    }
  });

  const loadSearchIntent = flow(function* (data: any) {
    try {
      self.searchIntentLoading = true;
      const response = yield reportsApi.searchIntent(data);
      if (response.isCancel) return;
      const newList = [];
      for (let index1 = 0; index1 < toJS(self.keywords)?.length; index1++) {
        const element1 = toJS(self.keywords)[index1];
        for (let index2 = 0; index2 < response?.keywordSearchIntent?.length; index2++) {
          const element2 = response?.keywordSearchIntent[index2];
          const formatedKw = camelCase(element1?.kw.replaceAll('-', ' '));
          if (!isNil(element2[formatedKw])) {
            newList?.push({
              ...element1,
              searchIntent: element2[formatedKw],
            });
          }
        }
      }
      self.keywords = cast(newList);
    } catch (e) {
      self.searchIntentLoading = true;
      if (e.response?.status == 400) {
        notification.error('Error loading data', '');
      }
      return Promise.reject(e);
    } finally {
      self.searchIntentLoading = false;
    }
  });

  const exportKeywordsToCSV = flow(function* (criteria: CriteriaType, type: 'pages_table' | 'keywords_table', {filters, exportAll, keywordSearchTerm, exportGoogleSheet=false}) {
    self.exportToCSVLoader = true;

    const sorters = {
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      cpc: 'cpc',
      vol: 'volume',
      page: 'page',
      opportunity_score: 'opportunity_score',
      value: 'value',
      watchlist: 'watchlist',
      potentialTrafficCur: 'potential_traffic_cur',
      potentialTrafficValueCur: 'potential_traffic_value_cur',
    };


    const formtatedSorters = formatSorters(sorters, self.keywordSortField, self.keywordSortDirection);

    const params = {
      selected_property: criteria.property,
      report: type,
      export_all: exportAll,
      keyword: keywordSearchTerm,
      period1_start: criteria.previousPeriodStart,
      period1_end: criteria.previousPeriodEnd,
      period2_start: criteria.currentPeriodStart,
      period2_end: criteria.currentPeriodEnd,
      country_code: criteria.countryCode,
      position_from: getFilter(filters, 'posCur')?.from,
      position_to: getFilter(filters, 'posCur')?.to,
      clicks_from: getFilter(filters, 'clicksCur')?.from,
      clicks_to: getFilter(filters, 'clicksCur')?.to,
      impressions_from: getFilter(filters, 'impCur')?.from,
      impressions_to: getFilter(filters, 'impCur')?.to,
      cpc_from: getFilter(filters, 'cpc')?.from,
      cpc_to: getFilter(filters, 'cpc')?.to,
      volume_from: getFilter(filters, 'vol')?.from,
      volume_to: getFilter(filters, 'vol')?.to,
      opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
      potential_traffic_cur_form: getFilter(filters, 'potentialTrafficCur')?.from,
      potential_traffic_cur_to: getFilter(filters, 'potentialTrafficCur')?.to,
      potential_traffic_value_cur_form: getFilter(filters, 'potentialTrafficValueCur')?.from,
      potential_traffic_value_cur_to: getFilter(filters, 'potentialTrafficValueCur')?.to,
      sort_by: formtatedSorters,
      export_to_google_sheet: exportGoogleSheet,
    };

    try {
      const data = yield reportsApi.getKeywordSearchTableCsv(params);
      return data;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.exportToCSVLoader = false;
    }
  });

  const setLoading = () => {
    self.keywordsLoading = true;
  };

  const onHeaderDateUpdate = (currentPeriodStart : string, currentPeriodEnd : string) => {
    self.keywordsTableDateRange = {
      pagesPeriod1Start: getPrecedingDate(currentPeriodStart, currentPeriodEnd)?.previousStartDate,
      pagesPeriod1End: getPrecedingDate(currentPeriodStart, currentPeriodEnd)?.previousEndDate,
      pagesPeriod2Start: currentPeriodStart,
      pagesPeriod2End: currentPeriodEnd,
    };
  };
  return {
    loadKeywords,
    loadSearchIntent,
    onKeywordPaginationChange,
    onKeywordFilterChange,
    onKeywordTableChange,
    onKeywordUpdateSearchTerm,
    setLoading,
    clearFilterList,
    exportKeywordsToCSV,
    onHeaderDateUpdate,
    updateKeywordColumnsList,
    loadKeywordMovements,
  };
});

export function initKeywordMovementsNew() {
  return keywordMovementsNew.create({
    keywords: [],
    updateKeyWordPages: [],
    exportToCSVLoader: true,
    keywordsLoading: true,
    searchIntentLoading: false,
    isKeywordInit: true,
    keywordPageNumber: 1,
    keywordPageSize: 50,
    keywordFilterList: KEYWORD_FILTER_LIST,
    keywordSortField: '',
    keywordSortDirection: '',
    keywordSearchTerm: '',
    loadingKeywordMovements: false,
    keywordMovements: [],
  });
}
