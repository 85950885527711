import {Pagination} from '@/components/common-components/components/pagination';
import {Popover, Select} from 'antd';
import styled from 'styled-components';

export const StyledSelect = styled(Select)<{background?: string}>`
 width: -webkit-fill-available;
 height: fit-content;
 .ant-select-selector {
   background: ${p=> p?.background} !important;
    max-width:180px !important;
    border-radius: 6px !important;
    border-color: 1px solid #E8E8E8 !important;
    padding: 6px 15px !important;
    height: 44px !important;
    cursor: pointer !important;
    line-height: unset !important;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }
`;

export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  contain: content;
  border-radius: 10px;
`;
export const BoldText = styled.div`
font-weight: 600;
`;

export const PaginationStyled = styled(Pagination)`
  padding: 20px 25px;  
  text-align: right;
  margin-top: 10px;
  border-top: 1px solid #E8E8E8!important;


.ant-pagination-item{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-prev {
  width:25px !important;
 height:25px !important;
}
.ant-pagination-next{
  width:25px !important;
 height:25px !important;
}
.ant-pagination-disabled{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-item-link  span  svg {
  display:flex;
  margin-bottom:2px;
  align-items:center;
  justify-content:center;
}
.ant-pagination-prev{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-next{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-options{
  height:25px;
}
.ant-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top:10px;
  margin-right:15px;
  
}
`;

export const PopoverWrapper = styled(Popover)`
.customPopover {
z-index: 3 !important;

  .ant-popover-content {
    .ant-popover-arrow {
      border-color: #25242b;
    }

    .ant-popover-inner {
      .ant-popover-inner-content {
        background-color: #fff;
        border-radius: 10px;
        color: #77757d;
        padding: 8px 15px;

        p {
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 0;
          padding: 4px 0;
        }

        span {
          color: #77757d;
          // margin-right: 4px;
        }
      }
    }
  }
}
`;
export const SpanWrapper = styled.span`
    border-radius: 5px;
    height: 30px;
    padding: 5px;
    width: 30px;
    cursor: pointer;
    &:hover{
    background-color: #dedede;
  }
  @media screen and (max-width: 800px) {
    padding-right: 10px !important;
  }
`;
