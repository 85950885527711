import {OTTO_API} from '@/api/OttoApi';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {flow, types, cast} from 'mobx-state-tree';
import {getFilter} from '@/utils/filters';
import {apiError} from '@/utils/api';


const INITIAL_FILTERS = [
  {id: 1, name: 'dr', header: 'Domain Rating', from: undefined, to: undefined, type: undefined, active: false},
  {id: 2, name: 'traffic_value', header: 'Domain Traffic', from: undefined, to: undefined, type: undefined, active: false},
];

const resultModel = types.model({
  batchGenerationDeltaDays: types.maybeNull(types.number),
  completedTasks: types.maybeNull(types.number),
  currentBatchExpiresAt: types.maybeNull(types.string),
  currentBatchGeneratedAt: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  totalTasks: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  ot: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  hasSiteAudit: types.maybeNull(types.boolean),
  shouldKeepIfAuditDeleted: types.maybeNull(types.boolean),
  hasGsc: types.maybeNull(types.boolean),
  gainedImpressions: types.maybeNull(types.number),
  gainedTraffic: types.maybeNull(types.number),
  backlinks: types.maybeNull(types.number),
  firstPageTaskCompletedAt: types.maybeNull(types.string),
  sitepropertyUrl: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  refdomains: types.maybeNull(types.number),
  siteAuditId: types.maybeNull(types.number),
});

const ottoDetailModel = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(resultModel)),
});

const ottoDaomainResultsModel = types.model({
  batch: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  isCompleted: types.maybeNull(types.boolean),
  isIgnored: types.maybeNull(types.boolean),
  type: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  completedByUser: types.maybeNull(types.string),
  taskData: types.maybeNull(types.frozen({})),
  minutesToComplete: types.maybeNull(types.number),
  week: types?.maybeNull(types?.number),
  assignedToUsers: types.maybeNull(types.array(types.string)),
});
const customerOttoDataModel = types.model({
  batchGenerationDeltaDays: types.maybeNull(types.number),
  completedTasks: types.maybeNull(types.number),
  currentBatchExpiresAt: types. maybeNull(types.string),
  currentBatchGeneratedAt: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  siteAuditId: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  ot: types.maybeNull(types.number),
  totalTasks: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  hasGsc: types.maybeNull(types.boolean),
  hasSiteAudit: types.maybeNull(types.boolean),
  firstPageTaskCompletedAt: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
});
const ottoDomainDataModel = types.model({
  count: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  results: types.maybeNull(types.array(ottoDaomainResultsModel)),
  customerOttoData: types.maybeNull(customerOttoDataModel),
});
const pagesGraphDataModel = types.model({
  date: types.maybeNull(types.string),
  totalTraffic: types.maybeNull(types.number),
  gainedTraffic: types.maybeNull(types.number),
  totalImpressions: types.maybeNull(types.number),
  gainedImpressions: types.maybeNull(types.number),
  isPredicted: types.maybeNull(types.boolean),
  trafficWithoutOtto: types.maybeNull(types.number),
  impressionsWithoutOtto: types.maybeNull(types.number),
});
const pagesMetricsModel = types.model({
  allGoalConversionRate: types.maybeNull(types.number),
  avgSessionDuration: types.maybeNull(types.number),
  avgTimeOnPage: types.maybeNull(types.number),
  bounceRate: types.maybeNull(types.number),
  bounces: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  ctrDelta: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  entranceRate: types.maybeNull(types.number),
  goalCompletionsAll: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  impressionDelta: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  keywordsCountDelta: types.maybeNull(types.number),
  newUsers: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  pageViews: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  previousCtr: types.maybeNull(types.number),
  serp13: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  sessionDuration: types.maybeNull(types.number),
  sessions: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  topKeywordByImpressions: types.maybeNull(types.string),
  totalEvents: types.maybeNull(types.number),
  transactionRevenue: types.maybeNull(types.number),
  uniquePurchases: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  users: types.maybeNull(types.number),
  isBookmarked: types.maybeNull(types.boolean),
});
const pagesModel = types.model({
  path: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
const tasksDataModel = types.model({
  count: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
  pages: types.maybeNull(types.array(pagesModel)),
});
const viewReportModel = types.model({
  pagesGraphData: types.maybeNull(types.array(pagesGraphDataModel)),
  pagesMetrics: types.maybeNull(types.array(pagesMetricsModel)),
  tasksData: types.maybeNull(types.array(tasksDataModel)),
  periodStart: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

const filterModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  text: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean),
  filterField: types.maybeNull(types.string),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.maybeNull(types.string),
    showPercent: types.maybeNull(types.boolean),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.maybeNull(types.string),
    showPercent: types.maybeNull(types.boolean),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
});
const paramsModal = types.model({
  search: types.maybeNull(types.string),
  ordering: types.maybeNull(types.string),
});
const growthReportModel = types.model({
  domain: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  lastEmailSentAt: types.maybeNull(types.string),
  shouldSendEmails: types.maybeNull(types.boolean),
});
const ottoEmailSettingModel = types.model({
  growthReport: types.maybeNull(types.array(growthReportModel)),
  projectCreation: types.maybeNull(types.boolean),
});
export const OttoStore = types.model({
  OttoDetail: types.maybeNull(ottoDetailModel),
  ottoDomainData: types.maybeNull(ottoDomainDataModel),
  loadingDomainData: types.maybeNull(types.boolean),
  loadingList: types.maybeNull(types.boolean),
  loadingOttoReport: types.maybeNull(types.boolean),
  shuffleLoading: types.maybeNull(types.number),
  viewReport: types.maybeNull(viewReportModel),
  ottoFiltersList: types.array(filterModel),
  seoBtnLoaing: types.boolean,
  listRepolling: types.boolean,
  // ottoSitesList: types.maybeNull(types.array(ottoSitesModel)),
  // pitchParams: types.maybeNull(pitchParamsModel),
  // selectedProject: types.maybeNull(types.string)
  params: types.maybeNull(paramsModal),
  ottoEmailSetting: ottoEmailSettingModel,
  tabelLoading: types.maybeNull(types.boolean),
  isUpdatingAssignees: types.maybeNull(types.boolean),
})
  .views(self => ({
    get getOttoSitesList() {
      return toJS(self.OttoDetail);
    },
    get getOttoEmailSetting() {
      return toJS(self.ottoEmailSetting);
    },
    get getOttoDomainData() {
      return toJS(self.ottoDomainData);
    },
    get getOttoReportData() {
      return toJS(self.viewReport);
    },
    get getOttoFiltersList() {
      return toJS(self.ottoFiltersList);
    },
  }))
  .actions(self => {
    // const setShowCreateHaroModal = value => {
    //   self.showCreateHaroModal = value;
    // };
    // const setReportModal = value => {
    //   self.reportModel = value;
    // };

    const loadOttoSitesList = flow(function* (noLoading?: boolean) {
      const formateFilters = {
        dr_from: getFilter(toJS(self.ottoFiltersList), 'dr')?.from,
        dr_to: getFilter(toJS(self.ottoFiltersList), 'dr')?.to,
        traffic_value_from: getFilter(toJS(self.ottoFiltersList), 'traffic_value')?.from,
        traffic_value_to: getFilter(toJS(self.ottoFiltersList), 'traffic_value')?.to,
      };
      const objectParams = {
        search: self.params.search,
        ...formateFilters,
      };
      if (!noLoading) {
        self.loadingList = true;
      }
      try {
        const response = yield OTTO_API.getOttoList(objectParams);
        if (!response.isCancel) {
          self.OttoDetail = cast(response);
          self.loadingList = false;
        }
        const checkStatus = response?.results?.some(z => ['PENDING'].includes(z?.status));
        if (checkStatus && self.listRepolling) {
          yield new Promise(r => setTimeout(r, 2000));
          return loadOttoSitesList(true);
        } else {
          self.OttoDetail = cast(response);
          self.loadingList = false;
        }
      } catch (err) {
        self.loadingList = false;
        const whiteLabel = localStorage.getItem('whitelabelOtto') || 'OTTO';
        const errorMessage = apiError(err, `Failed to fetch ${whiteLabel} sites list`) as string;
        notification.error('', errorMessage);
      }
    });
    const loadOttoSiteDetail = flow(function* (id: string) {
      try {
        const response = yield OTTO_API.getOttoListDetail(id);
        if (response) {
          // eslint-disable-next-line no-console
          console.log('===++++', response);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const setListRepolling = value => {
      self.listRepolling = value;
    };
    const loadOttoDomainDetail = flow(function* (doamin: string, noLoading?: boolean) {
      self.loadingOttoReport = true;
      if (!noLoading) {
        self.loadingDomainData = true;
      }
      try {
        const response = yield OTTO_API.getOttoDomainListDetail(doamin);
        if (response) {
          self.ottoDomainData = cast(response);
          if (response?.customerOttoData?.status == 'PENDING') {
            yield new Promise(r => setTimeout(r, 2000));
            return loadOttoDomainDetail(doamin, true);
          }
        }
        return response;
      } catch (e) {
        const errorMessage = apiError(e) as string;
        useErrorNotification({
          e,
          msg: errorMessage,
        });
      } finally {
        self.loadingDomainData = false;
        self.loadingOttoReport = false;
      }
    });

    const resetOttoDomainDetail = () => self.ottoDomainData = null;

    const ignoreTask = flow(function* (id: string) {
      try {
        yield OTTO_API.setIgnoreTask(id);
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const completeTask = flow(function* (id: string) {
      try {
        yield OTTO_API.setCompleteTask(id);
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const setShuffleTopics = flow(function* (id: number) {
      self.shuffleLoading = id;
      try {
        const response = yield OTTO_API.setShuffleTopic(id);
        if (response) {
          const findIndexOfData = self.ottoDomainData.results.findIndex(item=>item?.id == response.id);
          self.ottoDomainData.results[findIndexOfData] = response;
        }
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.shuffleLoading = null;
      }
    });
    const keepAuditProject = flow(function* (id: number) {
      try {
        yield OTTO_API.keepAuditProject(id);
        loadOttoSitesList(true);
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const deleteAuditProject = flow(function* (id: number) {
      try {
        yield OTTO_API.deleteAuditProject(id);
        loadOttoSitesList(true);
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const loadViewReport = flow(function* (id: string, isLoading?: boolean) {
      if (!isLoading) {
        self.loadingOttoReport = true;
        self.loadingDomainData = true;
      }
      try {
        const response = yield OTTO_API.getViewReport(id);
        if (response) {
          self.viewReport = cast(response);
        }
      } catch (err) {
        self.viewReport = null;
        if (err.response?.status != 406) {
          useErrorNotification({
            e: err,
            msg: err?.response?.data?.error || 'Failed to load report',
          });
        }
        return Promise.reject(err);
      } finally {
        self.loadingOttoReport = false;
        self.loadingDomainData = false;
      }
    });
    const resetViewReport = ()=> {
      self.viewReport = null;
    };
    // const setPitchParams = value => {
    //   if (self.pitchParams.search != value?.search) {
    //     value.page = 1;
    //   }
    //   self.pitchParams.search = value?.search;

    //   if (value?.page) {
    //     self.pitchParams.page = value?.page;
    //   }
    //   if (value?.page_size != null || value?.page_size != undefined) {
    //     self.pitchParams.page_size = value?.page_size;
    //   }
    // };
    const activateOttoProject = flow(function* (id: number) {
      try {
        yield OTTO_API.activateProject(id);
        return loadOttoSitesList(true);
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const getBookMarkListed = flow(function* (id: number, url: string) {
      try {
        const response = yield OTTO_API.bookMarkListed(id, url);
        if (!response?.isCancel) {
          self.viewReport.pagesMetrics = cast(response?.data || []);
        }
        loadViewReport(String(id), true);
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const getSeoTasks = flow(function* (domain: string) {
      self.seoBtnLoaing= true;
      try {
        const response = yield OTTO_API.loadNewSeoTasks(domain);
        loadOttoSitesList(false);
        return response;
      } catch (err) {
        if (err?.response?.status == 406) {
          notification?.error('Error', err.response?.data?.error || '');
        }
        if (err?.response?.status == 429) {
          const errorMessage = apiError(err) as string;
          notification.error('', errorMessage);
        }
        return Promise.reject(err);
      } finally {
        self.seoBtnLoaing= false;
      }
    });
    const loadOttoEmailSettings = flow(function* (isLoading?: boolean) {
      if (!isLoading) {
        self.tabelLoading = true;
      }
      try {
        const response = yield OTTO_API.ottoEmailSettings();
        self.ottoEmailSetting= cast(response);
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.tabelLoading= false;
      }
    });
    const onConnectToGsc = flow(function* (id: number, url: string, countryCode: string) {
      try {
        const response = yield OTTO_API.conncetGscAccount(id, url, countryCode);
        if (! response?.isCancel) {
          loadOttoSitesList(false);
        }
      } catch (e) {
        const errorMsg = apiError(e) as string;
        useErrorNotification({
          e,
          msg: errorMsg,
        });
        return Promise.reject(e);
      }
    });
    const loadUpdateOttoEmailList = flow(function* (id: number, payload: any) {
      try {
        yield OTTO_API.onUpdateOttoEmailList(id, payload);
      } catch (e) {
        return Promise.reject(e);
      }
    });
    const loadToggleProjectCreation= flow(function* () {
      try {
        yield OTTO_API.onProjectCreation();
        return loadOttoEmailSettings(true);
      } catch (e) {
        return Promise.reject(e);
      }
    });
    const onUpdateAssignInOttoTask= flow(function* (id, assignedToUsers) {
      try {
        self.isUpdatingAssignees = true;
        const response = yield OTTO_API.updateAssignInOttoTask(id, assignedToUsers);
        const findIndexOfData = self.ottoDomainData.results.findIndex(item=> item.id == id);
        self.ottoDomainData.results[findIndexOfData] = response.data;
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.isUpdatingAssignees = false;
      }
    });
    const setSerachParam= (value: string, isApiCall = true)=> {
      self.params.search = value;
      if (isApiCall) {
        loadOttoSitesList(true);
      }
    };
    const setOttoFiltersList = filters => {
      self.ottoFiltersList= filters;
      loadOttoSitesList(true);
    };
    const updateOttoEmailList = data => {
      self.ottoEmailSetting.growthReport = cast(data);
    };
    const setDomainLoading = () => {
      self.loadingDomainData = true;
    };
    return {
      // setShowCreateHaroModal,
      // setReportModal,
      loadToggleProjectCreation,
      setDomainLoading,
      loadUpdateOttoEmailList,
      updateOttoEmailList,
      getBookMarkListed,
      loadOttoEmailSettings,
      loadOttoSitesList,
      loadOttoSiteDetail,
      loadOttoDomainDetail,
      ignoreTask,
      completeTask,
      setListRepolling,
      resetOttoDomainDetail,
      setShuffleTopics,
      keepAuditProject,
      deleteAuditProject,
      activateOttoProject,
      loadViewReport,
      getSeoTasks,
      setSerachParam,
      setOttoFiltersList,
      onConnectToGsc,
      resetViewReport,
      onUpdateAssignInOttoTask,
      // setPitchParams,
    };
  });

export const initOttoStore = () => {
  return {
    showCreateHaroModal: false,
    OttoDetail: {},
    reportModel: false,
    listRepolling: false,
    tabelLoading: false,
    loadingOttoReport: true,
    loadingList: true,
    seoBtnLoaing: false,
    loadingDomainData: false,
    isUpdatingAssignees: false,
    ottoEmailSetting: {
      growthReport: [],
      projectCreation: false,
    },
    selectedProject: '',
    shuffleLoading: null,
    ottoFiltersList: INITIAL_FILTERS,
    params: {
      search: '',
      ot_from: '',
      ot_to: '',
      ordering: '',
    },
    pitchParams: {
      page_size: 10,
      page: 1,
      search: '',
    },
  };
};
