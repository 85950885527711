import {types, flow} from 'mobx-state-tree';
import {issueAnalyzerApi} from '@/api/account';
import {toJS} from 'mobx';


const headers = types.model({
  filter: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

const colorValueModal = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const Chart = types.model({
  colors: types.maybeNull(types.array(types.string)),
  xAxisLabels: types.maybeNull(types.array(types.string)),
  series: types.maybeNull(types.array(
    types.model({
      data: types.maybeNull(types.array(types.number)),
      filters: types.maybeNull(types.array(types.string)),
      name: types.maybeNull(types.string),
      type: types.maybeNull(types.string),
    }))),
});

export const PageSpeedReportStore = types.model({
  desktopCore: types.maybeNull(types.model({
    firstMeaningfulPaint: types.maybeNull(Chart),
    pageSpeedIndex: types.maybeNull(Chart),
    timeToFirstByte: types.maybeNull(Chart),
  })),
  desktopPageSpeedIndex: types.maybeNull(types.array(colorValueModal)),
  headers: types.maybeNull(types.array(headers)),
  loadTime: types.maybeNull(types.array(colorValueModal)),
  mobileCore: types.maybeNull(types.model({
    firstMeaningfulPaint: types.maybeNull(Chart),
    pageSpeedIndex: types.maybeNull(Chart),
    timeToFirstByte: types.maybeNull(Chart),
  })),
  mobilePageSpeedIndex: types.maybeNull(types.array(colorValueModal)),
  pageLoadTimeBy: types.maybeNull(types.model({
    depth: types.maybeNull(Chart),
    statusCode: types.maybeNull(Chart),
  })),
  sizeDistribution: types.maybeNull(types.array(colorValueModal)),
  loading: types.boolean,
}).views( self => ({
  get getPageSpeedHeaders() {
    return toJS(self.headers);
  },
  get getPageSpeedMobileSpeedIndex() {
    return toJS(self.mobilePageSpeedIndex);
  },
  get getPageSpeedDesktopSpeedIndex() {
    return toJS(self.desktopPageSpeedIndex);
  },
  get getPageSpeedLoadTime() {
    return toJS(self.loadTime);
  },
  get getPageSpeedSizeDistribution() {
    return toJS(self.sizeDistribution);
  },
  get getPageSpeedPageLoadTimeBy() {
    return toJS(self.pageLoadTimeBy);
  },
  get getPageSpeedDesktopCore() {
    return toJS(self.desktopCore);
  },
  get getPageSpeedMobileCore() {
    return toJS(self.mobileCore);
  },
})).actions(self => {
  const loadPageSpeedReport = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.pageSpeedReport(domain);
      self.headers = response?.headers;
      self.mobilePageSpeedIndex = response?.mobilePageSpeedIndex;
      self.desktopPageSpeedIndex = response?.desktopPageSpeedIndex;
      self.loadTime = response?.loadTime;
      self.sizeDistribution = response?.sizeDistribution;
      self.pageLoadTimeBy = response?.pageLoadTimeBy;
      self.desktopCore = response?.desktopCore;
      self.mobileCore = response?.mobileCore;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  return {
    loadPageSpeedReport,
  };
});

export function initPageSpeedReportStore() {
  return PageSpeedReportStore.create({
    loading: true,
  });
}
