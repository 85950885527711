import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import styled from 'styled-components';
import {Tooltip, Progress} from 'antd';
interface Props {
  isDockedSider?: boolean;
}
export const SiteHealthWithProgressPublic: React.FC<Props> = observer(({isDockedSider}) => {
  const {
    siteAuditorV2: {getSiteHealthAndPropertyUrl},
  } = useStore('');
  const actual = getSiteHealthAndPropertyUrl?.actual || 0;
  const total = getSiteHealthAndPropertyUrl?.total || 0;
  const color = getSiteHealthAndPropertyUrl?.color || '';

  return (
    <>

      <Tooltip title={'Health score is a way of measuring how well a website is doing in terms of SEO. A higher score means the website is doing better.'}>
        <HealthProgress>
          <div >
            <Progress type='circle'
              width={30}
              showInfo={false}
              strokeWidth={15}
              percent={(actual / total * 100) && 0}
              trailColor='#4E5156'
              strokeColor={color}
            />
          </div>
          {isDockedSider ?
            <BorderLine>
              <div style={{marginTop: '-5px', marginLeft: '5px'}}> Site Health <div style={{fontWeight: '500', color: '#FFFFFF', fontSize: 14}}> {actual} <span style={{color: '#E8E8E8', fontSize: 12, fontWeight: '400'}}>/ {total} </span>
              </div> </div>
            </BorderLine> : ''}
        </HealthProgress>
      </Tooltip>
    </>
  );
});

const HealthProgress = styled.div`
  width: fit-content;
  padding-top: 8px;
  padding-left:10px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #A3A4A4;
`;

const BorderLine = styled.div`
  padding: 0px 0px 0px 7px;
  white-space: nowrap;
  /* border-left: 1px solid #4e5156; */
 `;
