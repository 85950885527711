import {Tooltip, Dropdown} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {faSortDown} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {debounce} from 'lodash';
import styled from 'styled-components';
import {ComingSoonTag, UrlFilterInput, StyledMenu} from '../../../../style';


interface GBPPostTableHeaderProps {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: any;
  setSearchText: (value: string) => void;
  searchText: string;
  setIsActiveFilter: (value: string) => void;
  isActiveFilter: string;
  postCreatedAt: string;
  isResponsiveHeader?: boolean;
}

export const GBPReviewsTableHeader: React.FC<GBPPostTableHeaderProps> = observer(({
  componentIssueType,
  setPageChanged,
  setIssueTable,
  issueTable,
  setSearchText,
  searchText,
  isActiveFilter,
  // postCreatedAt,
  isResponsiveHeader,
}) => {
  const {ottoV2Store: {
    getOttoUrls,
    // setReplyKeyValue,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    setSelectedIssue,
    setSelectedReviewFilters,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    selectedIssue,
    ottoSearchTerm,
    selectedReviewFilters,
  },
  } = useStore('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedReply, setSelectedReply] = useState('All');
  const [selectedRating, setSelectedRating] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');

  const status = [
    'All',
    'Pending Review',
    'Scheduled',
    'Publishing',
    'Published',
    'Rejected',
    'Unpublished',
  ];
  const ratingOptions = [
    {key: 'All', value: 'All'},
    {key: 1, value: '1 stars'},
    {key: 2, value: '2 stars'},
    {key: 3, value: '3 stars'},
    {key: 4, value: '4 stars'},
    {key: 5, value: '5 stars'},
  ];
  // const reply = [{key: 'All', value: 'All'}, {key: true, value: 'Replied'}, {key: false, value: 'Not Replied'}];

  const uuid = getSingleUrlParam('uuid');
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2?.map(i => {
    if (i?.group === 'indexing') {
      const subGroups = i.subGroups.map(value => {
        if (value?.group === 'gsc_instant_indexing') {
          return {...value, subGroups: [
            {
              group: 'sitemap_indexing',
              label: 'Sitemap Indexing',
              requiredIntegration: null,
              isComingSoon: false,
              isSitewide: false,
            },
            {
              group: 'custom_url_based_indexing',
              label: 'Custom URL Based Indexing',
              requiredIntegration: null,
              isComingSoon: false,
              isSitewide: false,
            }]};
        }
        return value;
      });
      return {...i, subGroups: subGroups};
    }
    return i;
  });

  const category = selectedIssue === 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : selectedIssue;
  const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === category || issueObj.label === category)?.subGroups?.find(categoryObj => categoryObj?.group === selectedCategory);

  // @ts-ignore: Ignoring type error because the type of subGroups is uncertain at runtime
  const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === componentIssueType);

  const [ottoUrls, setOttoUrls] = useState([]);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);


  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, postType?: string, activeFilter?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          search: search,
          page,
          is_loading: false,
          ...(postType !== 'ALL') && {topic_type__in: postType === 'UPDATE' ? 'STANDARD' : postType},
          ...(activeFilter && !['All', 'all'].includes(activeFilter)) && {status__in: activeFilter === 'Draft' ? 'Pending Review, Unpublished' : activeFilter},
        };
        if (search) {
          params['search'] = search;
          selectedReviewFilters?.reply__status__in ? params['reply__status__in'] = selectedReviewFilters?.reply__status__in : null;
          selectedReviewFilters?.is_replied ? params['is_replied'] = selectedReviewFilters?.is_replied : null;
          selectedReviewFilters?.star_rating__in ? params['star_rating__in'] = selectedReviewFilters?.star_rating__in : null;
          setOttoSearchTerm(search);
        } else {
          params['search'] = '';
          selectedReviewFilters?.reply__status__in ? params['reply__status__in'] = selectedReviewFilters?.reply__status__in : null;
          selectedReviewFilters?.is_replied ? params['is_replied'] = selectedReviewFilters?.is_replied : null;
          selectedReviewFilters?.star_rating__in ? params['star_rating__in'] = selectedReviewFilters?.star_rating__in : null;
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, [selectedReviewFilters]);

  const debouncedChange = debounce((issueArray: string[], pageSize, page, searchText, ratingString, category) => {
    const value = searchText.trim();
    if (value) {
      loadIssueTables(issueArray, selectedIssue, category, 1, pageSize, true, value?.toLowerCase(), ratingString, isActiveFilter);
      setIssueTable(issueArray[0]);
      setPageChanged(true);
    } else {
      loadIssueTables(issueArray, selectedIssue, category, page ?? 1, pageSize, true, '', ratingString, isActiveFilter);
    }
  }, 1000);

  const handleSearch = useCallback(
    (issueArray: string[], pageSize, page, searchText, ratingString, category) => {
      if (searchText && searchText.length < 3) {
        return;
      }
      debouncedChange(issueArray, pageSize, page, searchText, ratingString, category), [selectedCategory];
    }, [selectedReviewFilters]);


  const onStatusChange = async (statusValue: string, issueType: string, pageSize: number, isSitewide: boolean) => {
    setSelectedReviewFilters({
      star_rating__in: selectedRating !== 'All' ? selectedRating : null,
      is_replied: selectedReviewFilters?.is_replied !== null ? selectedReviewFilters?.is_replied : null,
      reply__status__in: statusValue !== 'All' ? statusValue : null,
    });
    setSelectedStatus(statusValue);
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      is_loading: false,
    };
    if (statusValue) {
      statusValue !== 'All' ? params['reply__status__in'] = statusValue : null;
      searchText ? params['search'] = searchText : null;
      selectedReviewFilters?.is_replied !== null ? params['is_replied'] = selectedReviewFilters?.is_replied : null;
      selectedRating !== 'All' ? params['star_rating__in'] = selectedRating : null;
    }
    await loadIssueTableData(params, isSitewide);
  };

  // const onReplyChange = async (replyValue: any, issueType: string, pageSize: number, isSitewide: boolean) => {
  //   setSelectedReviewFilters({
  //     star_rating__in: selectedRating !== 'All' ? selectedRating : null,
  //     is_replied: replyValue !== 'All' ? replyValue : null,
  //     reply__status__in: selectedStatus !== 'All' ? selectedStatus : null,
  //   });
  //   setReplyKeyValue(replyValue);
  //   setSelectedReply(replyValue);
  //   const params = {
  //     uuid,
  //     otto_project: getOttoV2Project?.id,
  //     issue_type: issueType,
  //     page_size: pageSize,
  //     page: 1,
  //     is_loading: false,
  //   };
  //   if (replyValue) {
  //     selectedStatus !== 'All' ? params['reply__status__in'] = selectedStatus : null;
  //     searchText ? params['search'] = searchText : null;
  //     replyValue !== 'All' ? params['is_replied'] = replyValue : null;
  //     selectedRating !== 'All' ? params['star_rating__in'] = selectedRating : null;
  //   }
  //   await loadIssueTableData(params, isSitewide);
  // };


  const onRatingChange = async (rating: string, issueType: string, pageSize: number, isSitewide: boolean) => {
    setSelectedReviewFilters({
      star_rating__in: rating !== 'All' ? rating : null,
      is_replied: selectedReviewFilters?.is_replied !== null ? selectedReviewFilters?.is_replied : null,
      reply__status__in: selectedStatus !== 'All' ? selectedStatus : null,
    });
    setSelectedRating(rating);
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      is_loading: false,
    };
    if (rating) {
      selectedStatus !== 'All' ? params['reply__status__in'] = selectedStatus : null;
      searchText ? params['search'] = searchText : null;
      selectedReviewFilters?.is_replied !== null ? params['is_replied'] = selectedReviewFilters?.is_replied : null;
      rating !== 'All' ? params['star_rating__in'] = rating : null;
    }
    await loadIssueTableData(params, isSitewide);
  };

  const render = () => {
    return (
      <>
        <HeaderContainer className={isResponsiveHeader ? 'flex-wrap' : ''}>
          <div className='issues-collapse-header'>
            <div>
              <div style={{border: '1px solid rgba(205, 205, 205, 1)', borderRadius: '6px'}}>
                <Tooltip title={issueTable?.length > 0 && !ottoSearchTerm ? 'Loading. Please wait' : ''}>
                  <UrlFilterInput
                    style={{border: 'none'}}
                    placeholder={'Name or Review'}
                    prefix={<FontAwesomeIcon icon={faMagnifyingGlass}
                      color='#A3A4A4' />}
                    onChange={e => {
                      setSearchText(e.target.value);
                      handleSearch([url?.issueType], url?.pageSize ?? 10, 1, e?.target?.value, '', selectedCategory);
                    }}
                    disabled={issueTable?.length > 0 && !ottoSearchTerm}
                    value={searchText}
                  />
                </Tooltip>
              </div>
              <div style={{color: '#A3A4A4', fontSize: 12, fontStyle: 'italic', marginTop: '5px'}}>Search term must be at least 3 characters long.</div>
            </div>
            <StyledWrapper>
              <Dropdown
                trigger={['click']}
                overlay={<StyledMenu style={{width: '90px'}} selectedKeys={[selectedRating]} onClick={e => onRatingChange(e.key, url?.issueType, url?.pageSize, itemObject?.isSitewide)}>
                  {ratingOptions.map(type => (
                    <StyledMenu.Item key={type.key}>{type.value}</StyledMenu.Item>
                  ))}
                </StyledMenu>}
              >
                <StyledClicker isApplied={selectedRating !== 'All'}>
                  {selectedRating !== 'All' ? `${selectedRating} stars` : 'Rating'}
                  <FontAwesomeIcon icon={faSortDown} fontSize={14} color='#121212' />
                </StyledClicker>
              </Dropdown>
              {/* <Dropdown
                trigger={['click']}
                overlay={<StyledMenu style={{width: '110px'}} selectedKeys={[selectedReply]} onClick={e => onReplyChange(e.key, url?.issueType, url?.pageSize, itemObject?.isSitewide)}>
                  {reply.map(type => (
                    <StyledMenu.Item key={`${type.key}`}>{type.value}</StyledMenu.Item>
                  ))}
                </StyledMenu>}
              >
                <StyledClicker isApplied={selectedReply !== 'All'}>
                  {selectedReply == 'All' ? 'Reply' : selectedReply == 'true' ? 'Replied' : 'Not Replied'}
                  <FontAwesomeIcon icon={faSortDown} fontSize={14} color='#121212' />
                </StyledClicker>
              </Dropdown> */}
              <Dropdown
                trigger={['click']}
                overlay={<StyledMenu style={{width: '130px'}} selectedKeys={[selectedStatus]} onClick={e => onStatusChange(e.key, url?.issueType, url?.pageSize, itemObject?.isSitewide)}>
                  {status.map(type => (
                    <StyledMenu.Item key={type}>{type}</StyledMenu.Item>
                  ))}
                </StyledMenu>}
              >
                <StyledClicker isApplied={selectedStatus !== 'All'}>
                  {selectedStatus !== 'All' ? selectedStatus : 'Status'}
                  <FontAwesomeIcon icon={faSortDown} fontSize={14} color='#121212' />
                </StyledClicker>
              </Dropdown>
            </StyledWrapper>
          </div>
        </HeaderContainer>
      </>
    );
  };
  return (
    <>{!itemObject?.isComingSoon ? render() : <ComingSoonTag>Coming soon</ComingSoonTag>}</>
  );
});


const HeaderContainer = styled.div`
  padding: 0px 15px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;

  &.flex-wrap {
    flex-wrap: wrap;
  }
  .inner-fields-wrap {
    flex-wrap: wrap;
    justify-content: end;
    margin-left: auto;
  }
  @media screen and (max-width: 1260px) {
    flex-wrap: wrap;
    row-gap: 8px;
    .issues-collapse-header {
      min-height: 46px;
    }
    .issues-btn-wrapper {
      margin-left: auto;
    }
  }

  .issues-collapse-header {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  div:nth-child(1) {
    .anticon-right {
      display: none;
    }
  }
  .issues-collapse-header {
    .chevron-up-icon {
      display: block;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledClicker = styled.div<{ isApplied?: boolean }>`
  cursor: pointer;
  min-width: 68px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  color: #A3A4A4;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  gap: 6px;
  border-radius: 6px;
  background-color: ${p => p?.isApplied ? 'rgba(127, 78, 173, 0.17)' : '#ffffff'};
  border: ${p => p?.isApplied ? '1px solid rgba(180, 152, 208, 1)' : '1px solid rgba(232, 232, 232, 1)'};
  svg {
    margin-top: -6px;
  }
`;
