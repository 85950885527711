import {SEMANTIC_GRADER_API} from '@/api/content-optimizer/semantic-grader';
import {apiError} from '@/utils/api';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import {toJS} from 'mobx';
import {types, flow, cast} from 'mobx-state-tree';


const getActiveFiltersParams = filters => {
  const result = {};

  filters.forEach(filter => {
    if (filter.active && filter.name !== 'exclude' && filter.from !== null && filter.to !== null) {
      result[`${filter.name}_score_from`] = Number(filter.from);
      result[`${filter.name}_score_to`] = Number(filter.to);
    }

    if (filter.active) {
      if (filter.name === 'exclude' && filter.query) {
        result['search'] = filter.query;
      }
    }
  });

  return result;
};

const LlmKeysModel = types.model({
  // openaiSecretKey: types.maybeNull(KeyModel),
  // anthropicSecretKey: types.maybeNull(KeyModel),
});

const HtmlAnalysis = types.model({
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const SvTopCountries = types.model({
  countryCode: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
});

const TargetKeyword = types.model({
  id: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  rankingPotential: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  globalSv: types.maybeNull(types.number),
  totalSearchResults: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  kd: types.maybeNull(types.number),
  svTopCountries: types.maybeNull(types.array(SvTopCountries)),
  variations: types.maybeNull(types.array(types.string)),
  serpTaskStatus: types.maybeNull(types.string),
  serpUpdatedAt: types.maybeNull(types.string),
  serpFailureCount: types.maybeNull(types.number),
  kdTaskStatus: types.maybeNull(types.string),
  kdErrorMessage: types.maybeNull(types.string),
  kdUpdatedAt: types.maybeNull(types.string),
  contentAnalysisProcessedAt: types.maybeNull(types.string),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  processingTimeSeconds: types.maybeNull(types.number),
  processingStartedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  serpTaskStartedAt: types.maybeNull(types.string),
  serpTaskFinishedAt: types.maybeNull(types.string),
  entityTaskStartedAt: types.maybeNull(types.string),
  entityTaskFinishedAt: types.maybeNull(types.string),
});

const Details = types.model({
  entityScore: types.maybeNull(types.number),
  freshnessScore: types.maybeNull(types.number),
  humanEffortScore: types.maybeNull(types.number),
  pronounReviewScore: types.maybeNull(types.number),
  contentClarityScore: types.maybeNull(types.number),
  informationGainScore: types.maybeNull(types.number),
  numericalContentScore: types.maybeNull(types.number),
  semanticRelevanceScore: types.maybeNull(types.number),
  wordOrderSalienceScore: types.maybeNull(types.number),
  userIntentAlignmentScore: types.maybeNull(types.number),
  overallContextualFlowScore: types.maybeNull(types.number),
  queryRelevanceScore: types.maybeNull(types.number),
  readabilityScore: types.maybeNull(types.number),
});

const EnsembleScore = types.model({
  details: types.maybeNull(Details),
  overallScore: types.maybeNull(types.number),
});

const SemanticModal = types.model({
  id: types.maybeNull(types.number),
  htmlAnalysis: types.maybeNull(HtmlAnalysis),
  targetKeyword: types.maybeNull(TargetKeyword),
  taskStatus: types.maybeNull(types.string),
  overallScore: types.maybeNull(types.number),
  ensembleScore: types.maybeNull(EnsembleScore),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

const SemanticModalList = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(SemanticModal)),
  totalPages: types.maybeNull(types.number),
});

const filterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
});

export const SemanticGrader = types
  .model({
    semanticGraderLoader: types.boolean,
    llmApiKeys: types.maybeNull(LlmKeysModel),
    semanticGraderList: types.maybeNull(SemanticModalList),
    pageSize: types.maybeNull(types.number),
    page: types.maybeNull(types.number),
    createSemanticGraderLoader: types.boolean,
    semanticGraderDeleting: types.boolean,
    detailsAnalyzing: types.boolean,
    sgFilters: types.array(filterListModel),
    activeSort: types.maybeNull(types.string),
  })
  .views(self => ({
    get getSemanticGrader() {
      return toJS(self.semanticGraderList);
    },
    get getSgFilters() {
      return toJS(self.sgFilters);
    },
  }))
  .actions(self => {
    const getSemanticGraderList = flow(function* (noLoading?: boolean) {
      if (!noLoading) {
        self.semanticGraderLoader = true;
      }
      try {
        const activeFilters = getActiveFiltersParams(self.sgFilters);
        if (self.activeSort) {
          activeFilters['ordering'] = self.activeSort;
        }
        const response = yield SEMANTIC_GRADER_API.getSemanticGraderList(self.pageSize, self.page, activeFilters);
        if (response.isCancel) return;
        self.semanticGraderList = cast(response);
        const isRepulling = response.results.filter(item=> item.taskStatus != 'SUCCESS' && item?.taskStatus != 'FAILURE');
        if (isRepulling?.length > 0) {
          self.semanticGraderLoader = false;
          yield new Promise(r => setTimeout(r, 10000));
          return getSemanticGraderList(true);
        }
        self.semanticGraderLoader = false;
      } catch (e) {
        self.semanticGraderLoader = false;
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
        });
        return Promise.resolve(e);
      }
    });

    const deleteSemanticGrader = flow(function* (id, isLastRecord?: boolean) {
      self.semanticGraderDeleting = true;
      try {
        const response = yield SEMANTIC_GRADER_API.deleteSemanticGrader(id);
        if (response.isCancel) return;
        if (isLastRecord) {
          self.page = self.page == 1 ? 1 : (self.page - 1);
        }
        notification.success('Success', 'Page Deleted Successfully');
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
        });
        return Promise.resolve(e);
      } finally {
        self.semanticGraderDeleting = false;
      }
    });

    const getSemanticGraderAnalyse = flow(function* (id) {
      self.detailsAnalyzing = true;
      try {
        const response = yield SEMANTIC_GRADER_API.getSemanticGraderAnalyse(id);
        if (response.isCancel) return;
        return response;
      } catch (e) {
        self.detailsAnalyzing = false;
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
        });
        return Promise.resolve(e);
      } finally {
        self.detailsAnalyzing = false;
      }
    });


    const publicHash =() => {
      return getSingleUrlParam('public_hash');
    };
    const getSemanticGraderDetails = flow(function* (id, noLoading?: boolean) {
      if (!noLoading) {
        self.semanticGraderLoader = true;
      }
      try {
        const response = yield SEMANTIC_GRADER_API.getSemanticGraderDetail(id, publicHash());
        if (response.isCancel) return;
        return response;
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Something went wrong.',
          desc: '',
          permanent: false,
        });
        return Promise.resolve(e);
      } finally {
        self.semanticGraderLoader = false;
      }
    });

    const createSemanticGrader = flow(function* (params) {
      self.createSemanticGraderLoader = true;
      try {
        yield SEMANTIC_GRADER_API.createSemanticGrader(params);
        self.createSemanticGraderLoader = false;
      } catch (e) {
        const message = apiError(e) as string;
        notification.error(message, '');
        return Promise.resolve(e);
      } finally {
        self.createSemanticGraderLoader = false;
      }
    });
    // task_status
    const setSemanticGraderParams = value => {
      self.pageSize = value.pageSize;
      self.page = value.page;
    };

    const updateFilter = (filters, noApiCall?: boolean) => {
      const clonedFilters = JSON.parse(JSON.stringify(filters));
      self.sgFilters = cast(clonedFilters);
      self.page = 1;
      if (!noApiCall) {
        getSemanticGraderList();
      }
    };

    const setActiveSort = sorterField => {
      self.activeSort = sorterField;
    };

    return {
      getSemanticGraderList,
      getSemanticGraderDetails,
      setSemanticGraderParams,
      createSemanticGrader,
      deleteSemanticGrader,
      getSemanticGraderAnalyse,
      updateFilter,
      setActiveSort,
    };
  });

export const initSemanticGraderStore = () => {
  return SemanticGrader.create({
    semanticGraderLoader: false,
    pageSize: 10,
    page: 1,
    createSemanticGraderLoader: false,
    semanticGraderDeleting: false,
    detailsAnalyzing: false,
    activeSort: '',
  });
};
