import {NextRouter} from 'next/router';

export async function replaceRouterQueryParam(router: NextRouter, params: object) {
  let questionIdx = router.asPath.indexOf('?');
  if (questionIdx === -1) {
    questionIdx = router.asPath.length;
  }
  const pathname = router.asPath.slice(0, questionIdx);
  const query = router.asPath.slice(questionIdx + 1);
  const queryParams = new URLSearchParams(query);
  for (const [key, value] of Object.entries(params)) {
    queryParams.set(key, value);
  }
  return router.push({
    pathname,
    query: queryParams.toString(),
  });
}

export const openUrl = (url, blank = '') => {
  const link = document.createElement('a');
  link.href = url;
  link.target = blank;
  link.click();
};

export const linkgraphDomains = [
  'dashboard-staging.linkgraph.io',
  'dashboard.staging.searchatlas.com',
  'dashboard.linkgraph.com',
  'dashboard.linkgraph.io',
  'dashboard.searchatlas.com',
  'dashboard.searchatlas.io',
  'localhost',
];
