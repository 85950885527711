import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {InputNumber, Tooltip} from 'antd';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {Modal, Radio} from 'antd';
import {faXmark, faLockKeyhole, faMoneySimpleFromBracket} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {faCalendar, faCircleInfo, faCircleThreeQuarters, faCreditCard, faGift, faLayerGroup, faRotateRight} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown, faCaretUp, faPen} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import {ChangePaymentMethodModal} from '@/components/dashboard/billing/change-payment-method';
import {PaymentMethodModal} from '@/components/dashboard/billing/payment-method-modal';
import {numberWithCommas} from '@/utils/number';
import {notification} from '@/utils/notification-v2';
import {TopupModalV2} from './topupModalV2';

type optionsType = 'quantity'
| 'additionally_included'
| 'current_quota'
| 'used_card'
| 'recurring_payment'
| 'price'
| 'AI_generation'
| 'hyperdrive'
|'allowances'
;

export interface QuotaList {
  pricePerUnit: number;
  quotaKey: string;
  quotaPoints: number;
  shortName: string;
  topOptions: string[];
}

interface TopUpVisibilityProps {
  visibility: boolean;
  price: number;
  title: string;
  options: optionsType[];
  consumed: number | null;
  total: number | null;
  recurring: string;
  quotaKey: string;
  desc: string;
  nextPaymentAt?: string | null;
  buttonTxt: string;
  step?: number | null;
  localSeo?: boolean;
  overAllQuota?: number;
  multiplesOfStep?: boolean;
  unitIsMultipleOfStep?: boolean;
  recurringIsOptional?: boolean;
  recurringValue?: string;
  setRecurringValue?: Dispatch<SetStateAction<'no' | 'yes'>>;
  wholeCredits?: boolean;
  showSlider?: boolean;
  siteDesc?: string;
  multiQuotaList?: QuotaList[];
  isUnits?: boolean;
  showTotal?: boolean;
  quotaPoints?: number;
  showNextCharge?: boolean;
}

interface Props {
  topUpBulkVisibility: TopUpVisibilityProps;
  setTopUpBulkVisibility: (value: any) => void;
}

export const TopupModal = observer(({topUpBulkVisibility, setTopUpBulkVisibility}: Props) => {
  const {
    invoices: {getPaymentMethod},
    ottoV2Store: {ottoQuotaTopup, loadingOttoQuotaTopup, isTopUpHyperDrive, setTopupHyperDrive},
    settings: {customer: {getCustomerQuota, profile: {isLinkgraph, isViewer}}},
    contentOptimizer: {aiOutline: {addTopUpQuote, loadingAiQuota}},
  } = useStore('');

  const [changePrimaryCard, setChangePrimaryCard] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);

  const [addQuota, setAddQuota] = useState(topUpBulkVisibility.step || 1);

  useEffect(() => {
    setAddQuota(topUpBulkVisibility.step || 1);
  }, [topUpBulkVisibility.step]);
  const unit = (topUpBulkVisibility.unitIsMultipleOfStep && topUpBulkVisibility.multiplesOfStep) ? addQuota / topUpBulkVisibility.step : addQuota;

  const sections = {
    quantity: <SectionContainer>
      <StyledFontAwesomeIcon icon={faLayerGroup} />
      <div className='txt'>Quantity:</div>
      <InputQuota
        type='number'
        minLength={topUpBulkVisibility.step || 0}
        value={addQuota}
        onChange={(value:any) => {
          const inputValue = Number(value);
          if (inputValue < 0 || inputValue % 1 !== 0) {
            return false;
          }
          setAddQuota(Number(value));
        }}
        onMouseDown={(e:any) => e.stopPropagation()}
        upHandler={<FontAwesomeIcon icon={faCaretUp} color='#121212' />}
        downHandler={<FontAwesomeIcon icon={faCaretDown} color='#121212' />}
        step={topUpBulkVisibility.step}
        isLocalSeo={topUpBulkVisibility.localSeo || topUpBulkVisibility.multiplesOfStep}
      />
      <p className='total'>
      Total: <span className='total-value'> ${topUpBulkVisibility.localSeo ? numberWithCommas(addQuota * topUpBulkVisibility.price / 1000) || '-' : numberWithCommas(Number((unit * topUpBulkVisibility.price)?.toFixed(4))) || '-'}</span>
      </p>
    </SectionContainer>,
    additionally_included: <SectionContainer>
      <StyledFontAwesomeIcon icon={faGift} />
      <div className='txt'>Additionally included:</div>
      <div className='total-value'>+3 Cloud Stacks per month</div>
    </SectionContainer>,
    current_quota: <SectionContainer>
      <StyledFontAwesomeIcon icon={faCircleThreeQuarters} />
      <div className='txt'>Current quota:</div>
      <div className='total-value'>{topUpBulkVisibility.consumed || 0} used of {topUpBulkVisibility.title == 'Cloud Stacks Quota' || topUpBulkVisibility.title == 'AI Premium Quota' ? topUpBulkVisibility?.overAllQuota || 0 : topUpBulkVisibility?.total || 0}</div>
    </SectionContainer>,
    used_card: <SectionContainer>
      <StyledFontAwesomeIcon icon={faCreditCard} />
      <div className='txt'>Used card:</div>
      <div className='total-value'>Ending in {getPaymentMethod?.last4 || '-'}</div>
      <div className='edit' onClick={() => {
        setChangePrimaryCard(true);
        setTopUpBulkVisibility({...topUpBulkVisibility, visibility: false});
      }} ><FontAwesomeIcon icon={faPen} /> Edit</div>
    </SectionContainer>,
    recurring_payment: <SectionContainer>
      <StyledFontAwesomeIcon icon={faRotateRight} />
      <div className='txt'>Recurring payment:</div>
      {topUpBulkVisibility.recurringIsOptional && typeof topUpBulkVisibility.setRecurringValue === 'function' ? (
        <RadioGroup
          options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
          value={topUpBulkVisibility.recurringValue}
          onChange={e => topUpBulkVisibility.setRecurringValue(e?.target?.value)}
        />
      ) : (
        <div className='total-value'>{topUpBulkVisibility.recurring === 'monthly' ? 'Yes' : 'No'}</div>
      )}
    </SectionContainer>,
  };

  const onClose = () => {
    setAddQuota(topUpBulkVisibility.step || 1);
    typeof topUpBulkVisibility.setRecurringValue === 'function' && topUpBulkVisibility.setRecurringValue('no');
    setTopUpBulkVisibility({...topUpBulkVisibility, visibility: false});
    setTopupHyperDrive(false);
  };

  const closeModal = () => {
    setChangePaymentMethod(false);
    setChangePrimaryCard(false);
    setTopUpBulkVisibility({...topUpBulkVisibility, visibility: true});
  };
  const closeNewCardModal = () => {
    setChangePaymentMethod(false);
    setChangePrimaryCard(false);
    setTopUpBulkVisibility({...topUpBulkVisibility, visibility: true});
  };

  const setChangePaymentMethodModal = () => {
    setChangePrimaryCard(false);
    setChangePaymentMethod(true);
  };

  const handleButtonDisabled = () => {
    if (topUpBulkVisibility.localSeo) {
      return addQuota % 1000 !== 0 || addQuota <= 0;
    } else if (topUpBulkVisibility?.multiplesOfStep) {
      return addQuota % topUpBulkVisibility?.step !== 0 || addQuota <= 0;
    } else return addQuota <= 0;
  };

  const getDisabledButtonTooltip = () => {
    if (topUpBulkVisibility?.multiplesOfStep && addQuota % topUpBulkVisibility?.step !== 0 && handleButtonDisabled()) return `Quantity must be multiple of ${topUpBulkVisibility.step}`;
  };

  if (isLinkgraph || topUpBulkVisibility.quotaKey === 'quota_ca_allowed_otto_v2_projects') {
    return <TopupModalV2 topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />;
  }

  return (
    <>
      <ModalStyle
        width={780}
        centered
        closable={false}
        footer={null}
        mask={true}
        visible={topUpBulkVisibility.visibility}
        onCancel={onClose}
      >
        <div className='container' style={{position: 'relative'}}>
          <IconWrapper>
            <FontAwesomeIcon icon={faMoneySimpleFromBracket} color='#2AC155' />
          </IconWrapper>
          <PopoverTitle>Top up: {topUpBulkVisibility.title}</PopoverTitle>
          <PriceSection>
            <div className='price'>{topUpBulkVisibility.price}</div>
            <div>
              <div className='currency'>USD</div>
              <div className='per-quota'>/{topUpBulkVisibility.desc}</div>
            </div>
          </PriceSection>
          {isTopUpHyperDrive ? <>
            <div className='pinkBanner'> <FontAwesomeIcon icon={faCircleInfo} />{`You don't have enough HyperDrive Credits.`}</div>
            <div className='blueBanner'> <FontAwesomeIcon icon={faCircleInfo} />{`1 Cloud Stack costs 15 HyperDrive Credits.`}</div>
          </> :
            <Divider />}
          <div>
            {topUpBulkVisibility.options?.map(option => sections[option])}
            {topUpBulkVisibility.recurring === 'monthly' ? <SectionContainer>
              <FontAwesomeIcon icon={faCalendar} />
              <div className='txt'>Next charge:</div>
              <div className='total-value'>{topUpBulkVisibility?.nextPaymentAt ? moment(topUpBulkVisibility?.nextPaymentAt).format('MMM DD, YYYY') : '-'}</div>
            </SectionContainer> : ''}
          </div>
          <PopoverFooterContainer>
            <Tooltip title={topUpBulkVisibility.localSeo && addQuota % 1000 !== 0 && 'Quantity must be multiple of 1000' || getDisabledButtonTooltip()}>
              <span>
                <ButtonWrapper
                  style={{whiteSpace: 'normal'}}
                  color='green'
                  onClick={async () => {
                    if (isViewer) {
                      notification.error('', 'Only Admins can Top up quota ');
                      return;
                    }
                    if (topUpBulkVisibility.quotaKey === 'allowed_ai_premium_content_generation') {
                      await addTopUpQuote({units: addQuota});
                    } else {
                      await ottoQuotaTopup({
                        data: [
                          {
                            units: ((topUpBulkVisibility.unitIsMultipleOfStep && topUpBulkVisibility.multiplesOfStep && !topUpBulkVisibility?.wholeCredits) ? Math.round(addQuota / topUpBulkVisibility.step) : addQuota),
                            quota_key: topUpBulkVisibility.quotaKey,
                            quota_type: topUpBulkVisibility.recurring,
                          },
                        ],
                      });
                    }
                    await getCustomerQuota();
                    onClose();
                  }}
                  disabled={handleButtonDisabled()}
                  loading={loadingOttoQuotaTopup || loadingAiQuota}
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={faLockKeyhole}
                    style={{
                      height: '16px',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />{' '}
                  <ButtonText> Get {numberWithCommas(addQuota)} {topUpBulkVisibility.buttonTxt || ''}{addQuota > 1 && 's'} for ${topUpBulkVisibility.localSeo ? numberWithCommas(addQuota * topUpBulkVisibility.price / 1000) : numberWithCommas(Number((unit * topUpBulkVisibility.price)?.toFixed(4)))}{' '}</ButtonText>
                </ButtonWrapper>
              </span>
            </Tooltip>
            <ButtonWrapperCancel
              color='white'
              onClick={async () => {
                onClose();
              }}
            >
              Cancel
            </ButtonWrapperCancel>
          </PopoverFooterContainer>
          <div style={{position: 'absolute', top: -26, right: -47}}>
            <FontAwesomeIcon
              icon={faXmark}
              style={{height: '20px', color: '#FFFFFF', cursor: 'pointer'}}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </ModalStyle>
      {changePrimaryCard && <ChangePaymentMethodModal isOpen={changePrimaryCard} handleChangePayment={setChangePaymentMethodModal} setIsOpen={closeModal} getCustomerQuota={getCustomerQuota} />}
      {changePaymentMethod && <PaymentMethodModal isOpen={changePaymentMethod} setIsOpen={closeNewCardModal} getCustomerQuota={getCustomerQuota} />}
    </>
  );
});

const ModalStyle = styled(Modal)`
width: 538px !important;
.ant-modal-content {
  border-radius: 12px !important;
}
  .pinkBanner{
  background-color: #ff00002e;
    padding: 7px;
    border-radius: 9px;
    margin: 20px 0 10px 0;
    svg{
    color: #ff0000b0;
    margin: 0 10px;
    }
  }
      .blueBanner{
  background-color: #011fa921;
    padding: 7px;
    border-radius: 9px;
    margin-bottom: 20px;
    svg{
    color: #455fd3;
    margin: 0 10px;
    }
  }
@media screen and (max-width: 768px) {
  width: 300px !important;
  max-width: 300px !important;
  .container {
    padding: 0 !important;
  }
}
`;

const ButtonText = styled.span`
    margin-left: 7px;
    margin-top: 2px;
`;

const RadioGroup = styled(Radio.Group)`
  .ant-radio-checked .ant-radio-inner{
    border-color: #2D6CCA;
    background-color: #2D6CCA;
  }
  .ant-radio-inner:after {
    background: #fff !important;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    margin-left: -7px;
  }
  .ant-radio-inner {
    border-radius:50%; 
    border-color: #CDCDCD;
    background-color: #F9F9FB;
  }
`;

const IconWrapper = styled.div`
svg {
  width: 56px;
  height: 42px;
  border-radius: 100%;
  margin-right: auto;
}
`;
const PopoverTitle = styled.div`
 margin-top: 16px;
 font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  color: #121212;
`;

const PopoverFooterContainer = styled.div`
  display: flex;
  margin-top: 31px;
  margin-bottom: 6px;
  flex-wrap: wrap;
  @media screen and (max-width: 860px) {
    row-gap: 10px;
  }
`;

const InputQuota = styled(InputNumber)<{isLocalSeo?: boolean}>`
height: 33px;
${p => p?.isLocalSeo ? `min-width:  62px;` : 'width:  62px;'}
border: 1px solid #A3A4A4;
border-radius: 8px !important;
.ant-input{
  padding: 8px 11px !important;
}
.ant-input-number-handler-wrap {
  border-radius: 8px !important;
}
.ant-input-number-handler {
  border: 0px !important;
  height: 40%;
}
.ant-input-number-handler-wrap {
  padding-top: 3px;
  right: 2px;
}
@media screen and (max-width: 860px) {
  max-width: 55%;
  width: auto;
}
`;

const ButtonWrapper = styled(Button)`
    min-width: 266px;
    height: 44px;
    background-color: #1FAC47;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    margin: 0px 30px 0px 0px !important;
    border: 1px solid transparent;
    padding: 7px 0px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border:none;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    &:hover{
      background-color: #1FAC47;
      color: white;
      border:none;
    }
    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
      background-color: #1fac47b0 !important;
      color: white  !important;
      border:none !important;
    }
`;

const ButtonWrapperCancel = styled.button`
background-color: white;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 7px 20px;
    color: black;
    height: auto;
    border:none;
    cursor: pointer;
    &:hover{
      background-color: white;
      color: black;
      border:none;
    }
    &:focus {
      color: black;
    }
    @media screen and (max-width: 860px) {
      width: 100%;
      border: 1px solid #d1c5c5 !important;
    }
`;

const PriceSection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5pxsdvdf;
  .price {
    font-family: Inter;
    font-size: 42px;
    font-weight: 700;
    line-height: 50.83px;
    text-align: left;
    color: #121212;
  }
  .currency {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #121212;
  }
  .per-quota {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: #4E5156;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #D9D9D9;
  margin: 15px 0px;
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 16px;
  .txt {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
  }
  .total-value {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #121212;
  }
  .total {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
  }
  p {
    margin-bottom: 0px !important;
  }
  .edit {
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #2D6CCA;
    svg {
      color: #2D6CCA;
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: #4E5156;
`;
