import {getGoogleConnectUrl, getGoogleAnalyticsConnectUrl} from '@/api/gsc/utils';
import {getApiUrl, getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class IntegrationApi extends BaseApi {
  private static readonly googleConnectUrl: string = getGoogleConnectUrl();
  private static readonly googleAnalyticsConnectUrl: string = getGoogleAnalyticsConnectUrl();
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');


  public async getConnectedGoogleProfiles() {
    const response = await this.axios.get(`${IntegrationApi.baseUrl}/google-user-profiles/`, {
      headers: {Authorization: getAuthorizationHeader()},
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public getGoogleConnectUrl(param='') {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `${IntegrationApi.googleConnectUrl}?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `${IntegrationApi.googleConnectUrl}?jwt=${token}`;
    }
  }

  public getFacebookConnectUrl(param='') {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `https://gsc.searchatlas.com/oauth/start_facebook_ads/?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `https://gsc.searchatlas.com/oauth/start_facebook_ads/?jwt=${token}`;
    }
  }

  public getGoogleAdsUrl(param='') {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `https://gsc.searchatlas.com/oauth/start_google_ads/v2/?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `https://gsc.searchatlas.com/oauth/start_google_ads/v2/?jwt=${token}`;
    }
  }

  public getGoogleAnalyticsConnectUrl() {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `${IntegrationApi.googleAnalyticsConnectUrl}?jwt=${token}&redirect_path=${window.location.pathname}`;
    } else {
      return `${IntegrationApi.googleAnalyticsConnectUrl}?jwt=${token}`;
    }
  }

  public getGoogleMyBusinessConnectUrl(param='') {
    const token = getTokenFromCookies();
    if (typeof window !== 'undefined') {
      return `https://gsc.searchatlas.com/oauth/start_business/?jwt=${token}&redirect_path=${window.location.pathname}${param}`;
    } else {
      return `https://gsc.searchatlas.com/oauth/start_business/?jwt=${token}`;
    }
  }


  // To be used when adding disconnecting google accounts functionality
  public async removeConnectedGoogleAccount(email: string) {
    try {
      const response = await this.axios.delete(`${IntegrationApi.baseUrl}/google-user-profiles/${email}/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async refreshEmail(email: string) {
    try {
      const response = await this.axios.post(`${IntegrationApi.baseUrl}/google-user-profiles/${email}/refresh-gsc-account/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async removeConnectedGAAccount(email: string) {
    try {
      const response = await this.axios.patch(`${IntegrationApi.baseUrl}/google-user-profiles/${email}/disconnect-ga/`, {}, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default IntegrationApi;
