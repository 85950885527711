import {SubscriptionType} from '@/store/settings/customer/index';

const getProductFlag = (product: string) => {
  const productFlagsMap = {
    'gsc': 'gscEnabled',
    'landing-page-optimizer': 'caEnabled',
    'keyword-researcher': 'keEnabled',
    // 'competitor-researcher': 'crEnabled',
    'backlink-analyzer': 'blEnabled',
    'focus-terms': 'caEnabled',
  };
  return productFlagsMap[product];
};

const getProtectedProducts = () => [
  'gsc',
  'landing-page-optimizer',
  'keyword-researcher',
  // 'competitor-researcher',
  'backlink-analyzer',
  'focus-terms',
];

export const hasProductAccess = (subscription: SubscriptionType, product: string) => (
  subscription ? !!subscription[getProductFlag(product)] : true
);

export const isProtectedPath = (path: string) => {
  const cleanedPath = path.replaceAll('/', '').trim();
  const products = getProtectedProducts();
  for (const product of products) {
    if (cleanedPath.startsWith(product)) {
      return {
        isProtected: true,
        productFlag: getProductFlag(product),
      };
    }
  }
  return {
    isProtected: false,
    productFlag: null,
  };
};
