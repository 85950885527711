import React, {useState} from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {faXmark, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {Button, Divider, Modal} from 'antd';
import {notification} from '@/utils/notifications';
import {faCloudArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {DragimageContainerGbpPost} from '../../../imagePicker';

interface Props {
  setImages: any;
  images: any;
  hideExtra?: boolean;
  width?: string;
  types?: any;
  showImageType?: any;
}

const ImagePicker = ({setImages, images, hideExtra, width, types, showImageType,
}: Props) => {
  const [modalConfig, setModalConfig] = useState({
    visible: false,
    imageSource: '',
  });
  const [selectedImgIndex, setSelectedImgIndex] = useState(null);
  const getImageSrc = imageUrl => {
    let src;
    if (typeof imageUrl === 'string') {
      src = imageUrl;
    } else if (imageUrl instanceof Blob || imageUrl instanceof File) {
      src = URL?.createObjectURL(imageUrl);
    } else {
      src = '';
    }
    return src;
  };

  const handleImageDelete = (type, url='') => {
    setImages(prev => {
      const updatedImages = {...prev};
      type=='PROFILE' ? updatedImages.PROFILE=null : type=='COVER' ? updatedImages.COVER=null : updatedImages.ADDITIONAL=updatedImages?.ADDITIONAL?.filter(item => item?.url != url);
      return updatedImages;
    });
  };

  const [dropTriggered, setDropTriggered] = useState(false);

  const createDraggerProps = type => ({
    name: 'file',
    multiple: type === 'ADDITIONAL' || type === 'video',
    accept: type == 'video' ? 'video/mp4, video/quicktime, video/x-ms-wmv, .mov, .wmv' : 'image/png, image/jpg, image/jpeg',
    showUploadList: false,
    customRequest: async data => {
      if (!dropTriggered) {
        const {file} = data;
        if (file) {
          if (file.type?.includes('image')) {
            if (
              file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg'
            ) {
              handleImageUpload(file, type);
            } else {
              notification.error('Acceptable file formats: JPEG, PNG and JPG. Please try again.', 'topRight');
            }
          } else if (file.type?.includes('video')) {
            if (file.type === 'video/mp4' || file.type === 'video/quicktime' || file.type === 'video/x-ms-wmv') {
              handleVideoUpload(file);
            } else {
              notification.error('Acceptable file formats: MP4, MOV, WMV. Please try again.', 'topRight');
            }
          }
        }
      }
    },
    onDrop: async e => {
      setDropTriggered(true);
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length) {
        for (const file of files) {
          if (file.type?.includes('image')) {
            if (
              file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg'
            ) {
              handleImageUpload(file, type);
            } else {
              notification.error('Acceptable file formats: JPEG, PNG and JPG. Please try again.', 'topRight');
            }
          } else if (file.type?.includes('video')) {
            if (file.type === 'video/mp4' || file.type === 'video/quicktime' || file.type === 'video/x-ms-wmv') {
              handleVideoUpload(file);
            } else {
              notification.error('Acceptable file formats: MP4, MOV, JPG, WMV. Please try again.', 'topRight');
            }
          }
        }
      }
    },
  });

  const handleVideoUpload = file => {
    const MaxSize = 75000000;
    if (file.size > MaxSize) {
      return notification.error('Video(s) size must be upto 75MB', 'topRight');
    }
    setImages(prev => {
      const newItem = {url: file, format: 'VIDEO'};
      const updatedImages = {...prev};
      !(updatedImages.ADDITIONAL?.find((img, index) => index==selectedImgIndex)) ? updatedImages.ADDITIONAL = (prev?.ADDITIONAL ? [...prev.ADDITIONAL, newItem] : [newItem]) : updatedImages.ADDITIONAL[selectedImgIndex] = newItem;
      return updatedImages;
    });
  };

  const handleImageUpload = (file, type) => {
    const MAX_SIZE_BYTES = 5000000;
    if (file.size > MAX_SIZE_BYTES) {
      return notification.error('Image(s) size must be upto 5MB', 'topRight');
    } else if (file.size < 10241) {
      return notification.error('Image(s) size must be greater than 10.5kB', 'topRight');
    }
    setImages(prev => {
      const newItem = {url: file};
      const updatedImages = {...prev};
      type == 'PROFILE' ? updatedImages.PROFILE = newItem : type == 'COVER' ? updatedImages.COVER = newItem : !(updatedImages.ADDITIONAL?.find((img, index) => index==selectedImgIndex)) ? updatedImages.ADDITIONAL = (prev?.ADDITIONAL ? [...prev.ADDITIONAL, newItem] : [newItem]) : updatedImages.ADDITIONAL[selectedImgIndex] = newItem;
      return updatedImages;
    });
  };

  const getVideoUrl = videoUrl => {
    let src;
    if (typeof(videoUrl) === 'string') {
      src = videoUrl;
    } else {
      const fileURL = URL.createObjectURL(videoUrl);
      src = fileURL;
    }
    return src;
  };

  return (
    <div>
      <InputContainer>
        {types?.map((type, index) => {
          if (type === 'ADDITIONAL' && !showImageType) {
            return <>
              {images?.ADDITIONAL && images?.ADDITIONAL.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  style={{width: width || '280px', position: 'relative'}}
                >
                  <ImageContainer style={{height: 280, cursor: 'pointer', display: item?.format == 'VIDEO' && 'flex'}} width={width ?? '280px'}>
                    {item?.format == 'VIDEO' ?
                      <>
                        <video style={{width: '-webkit-fill-available', height: 'auto'}} controls>
                          <source src={getVideoUrl(item.url)}></source>
                        </video>
                      </> :
                      <img
                        id='selected-image'
                        alt='not found'
                        src={getImageSrc(item?.url)}
                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                        onClick={e => {
                          e.preventDefault();
                          const src = getImageSrc(item?.url);
                          setModalConfig({visible: true, imageSource: src});
                        }}
                      />
                    }
                    <Button
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: '#12121299',
                        border: 'none',
                        borderRadius: '50%',
                        padding: '8px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 46,
                        height: 46,
                      }}
                      onClick={() => {
                        handleImageDelete(type, item?.url);
                      }}
                    >
                      <FontAwesomeIcon fontSize='20px' color={'white'} icon={faTrashCan} />
                    </Button>
                  </ImageContainer>
                </div>
              ))}
              <StyledDragger key={'ADD-PHOTO'} className='dragger-container' {...createDraggerProps(type)} style={{width: width || '280px', height: 280}}>
                <div onClick={() => {
                  setDropTriggered(false);
                  setSelectedImgIndex(null);
                }} style={{display: 'flex'}}>
                  <DragRightContainer>
                    <DragimageContainer style={{height: 280}} width={width ?? '280px'}>
                      <div className='upload-icon'>
                        <img
                          src={'/img/photosDefaultImg.png'}
                          alt='default'
                        />
                      </div>
                      <Divider style={{backgroundColor: '#D8D8D8', height: 1}}/>
                      <DragText className='drag-text'>
                        <div style={{color: '#121212', fontWeight: 500}}>
                          {'Add a Photo'}
                        </div>
                        <div style={{color: '#4E5156', fontSize: 12}}>
                          {'Drag & drop your photo(s) here to upload them, or'}
                        </div>
                        <div style={{color: '#2D6CCA', fontSize: 12, display: 'inline-block'}}>
                          <FontAwesomeIcon color='#2D6CCA' style={{marginRight: 6}} fontSize={16} icon={faCloudArrowUp} />
                          {'Select file(s)'}
                        </div>
                      </DragText>
                    </DragimageContainer>
                  </DragRightContainer>
                </div>
                <div className='overlay' />
              </StyledDragger>
              <StyledDragger key={index} className='dragger-container' {...createDraggerProps('video')} style={{width: width || '280px', height: 280}}>
                <div onClick={() => {
                  setDropTriggered(false);
                  setSelectedImgIndex(null);
                }} style={{display: 'flex'}}>
                  <DragRightContainer>
                    <DragimageContainerGbpPost style={{height: 280}} width={width ?? '280px'}>
                      <div className='upload-icon-container'>
                        <img className='upload-icon' src={'/img/icon/gbp-upload-image.svg'}/>
                      </div>
                      <div className='drag-text-container'>
                        <div className='heading'>
                          Add Video
                        </div>
                        <div className='sub-heading'>
                          Drag & drop your video(s) here to upload them, or
                        </div>
                        <div className='link'>
                          <FontAwesomeIcon icon={faCloudArrowUp} /> Select file(s)
                        </div>
                      </div>
                    </DragimageContainerGbpPost>
                  </DragRightContainer>
                </div>
                <div className='overlay' />
              </StyledDragger>
            </>;
          }
          if (!hideExtra && ['PROFILE', 'COVER'].includes(type)) {
            const imageUrl = type === 'PROFILE' ? images?.PROFILE?.url : images?.COVER?.url;
            if (imageUrl) {
              return (
                <div
                  key={index}
                  style={{width: width || '280px', position: 'relative'}}
                >
                  <ImageContainer style={{height: '280px'}} width={width ?? '280px'}>
                    <img
                      id='selected-image'
                      alt='not found'
                      src={getImageSrc(imageUrl)}
                      style={{width: '100%', height: '100%', objectFit: 'cover'}}
                      onClick={e => {
                        e.preventDefault();
                        const src = getImageSrc(imageUrl);
                        setModalConfig({visible: true, imageSource: src});
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: '#12121299',
                        border: 'none',
                        borderRadius: '50%',
                        padding: '8px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 46,
                        height: 46,
                      }}
                      onClick={() => handleImageDelete(type)}
                    >
                      <FontAwesomeIcon fontSize='20px' color={'white'} icon={faTrashCan} />
                    </div>
                    <Button
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        backgroundColor: '#121212B2',
                        color: 'white',
                        border: 'none',
                        borderRadius: '90px',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Dragger {...createDraggerProps(type)}>
                        <span style={{color: 'white'}}>
                          {type === 'PROFILE' ? 'Change logo' : 'Change cover'}
                        </span>
                      </Dragger>
                    </Button>
                  </ImageContainer>
                </div>
              );
            } else {
              return (
                (showImageType == type.toLowerCase() || !showImageType) &&<StyledDragger className='dragger-container' key={index} {...createDraggerProps(type)} style={{width: width || '280px', height: 280}}>
                  <div onClick={() => {
                    setSelectedImgIndex(null);
                  }} style={{display: 'flex'}}>
                    <DragRightContainer>
                      <DragimageContainer style={{height: 280}} width={width ?? '280px'}>
                        <div className='upload-icon'>
                          <img
                            src={
                              type === 'PROFILE' ?
                                '/img/logoDefaultImg.png' :
                                type === 'COVER' ?
                                  '/img/coverDefaultImg.png' :
                                  '/img/photosDefaultImg.png'
                            }
                            alt='default'
                          />
                        </div>
                        <Divider style={{backgroundColor: '#D8D8D8', height: 1}}/>
                        <DragText className='drag-text'>
                          <div style={{color: '#121212', fontWeight: 500}}>
                            {type === 'PROFILE' ?
                              'Add a Logo' :
                              type === 'COVER' ?
                                'Add a Cover Photo' :
                                'Add a Photo'}
                          </div>
                          <div style={{color: '#4E5156', fontSize: 12}}>
                            {type === 'PROFILE' ?
                              'Drop your logo here, or:' :
                              type === 'COVER' ?
                                'Drop your cover photo here, or:' :
                                'Drag & drop your photo(s) here to upload them, or'}
                          </div>
                          <div style={{color: '#2D6CCA', fontSize: 12, display: 'inline-block'}}>
                            <FontAwesomeIcon color='#2D6CCA' style={{marginRight: 6}} fontSize={16} icon={faCloudArrowUp} />
                            {type === 'PROFILE' ?
                              'Select logo' :
                              type === 'COVER' ?
                                'Select cover photo' :
                                'Select file(s)'}
                          </div>
                        </DragText>
                      </DragimageContainer>
                    </DragRightContainer>
                  </div>
                  <div className='overlay' />
                </StyledDragger>
              );
            }
          }
          return null;
        })}      </InputContainer>
      <StyledModal
        zIndex={1060}
        open={modalConfig.visible}
        onCancel={() => setModalConfig({visible: false, imageSource: ''})}
        footer={false}
        width={1240}
        closeIcon={<FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/>}
      >
        <img style={{maxHeight: '80vh', maxWidth: '1240px'}} src={modalConfig.imageSource} />
      </StyledModal>
    </div>
  );
};

export default ImagePicker;

const InputContainer = styled.div`
display: flex;
gap: 20px;
flex-wrap: wrap;
  margin-top: 5px;
  flex-grow: 1;
  &:hover {
    .dragImageHover {
      border-color: #2D6CCA;
      color: #2D6CCA;
    }
    .dragSubtextHover {
      color: #2D6CCA;
    }
  }
  .ant-upload-btn {
    padding: 0 !important;
  }
  .ant-upload-drag {
    background: transparent !important;
    border: 0 !important;
  }
  .image-container {
  }
`;

const ImageContainer = styled.div<{width?: string}>`
  width: ${p => p?.width || '220px'};
  height: 125px;
  border: 1px solid #D8D8D8;
  border-radius: 12px;

  img {
    border-radius: 12px;
    height: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
  }
`;

const DragRightContainer = styled.div`
  flex-grow: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DragText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
  text-align: left;
  padding: 17px;
`;

const DragimageContainer = styled.div<{width?: string}>`
  text-align: center;
  width: ${p => p?.width || '280px'};
  height: 125px;
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  .upload-icon {
  height: 130px;
  background-color: #F1F3F4;
  width: 278px;
  border-radius: 12px 12px 0px 0px;
  }
  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    color: #2d6cca;
    font-weight: 500;
    height: 96%;
  }
`;

const StyledModal = styled(Modal)`
  width: fit-content !important;
  .ant-modal-close-x {
    position: absolute;
    top: -19px;
    left: 0px;
    right: -70px;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;

const StyledDragger = styled(Dragger)`
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 255, 0.1); 
    transition: background-color 0.3s ease;
  }

  &:hover .overlay {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(45, 108, 202, 0.1);
    opacity: 0;
    pointer-events: none;
  }
`;
