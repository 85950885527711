import {getApiUrl, getAuthorizationHeader, getAuthHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';

class KeywordExplorerApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');
  private static readonly baseUrlCluster: string = getApiUrl(BaseApi.CA_ENDPOINT, '/api');


  public async getContentIdeasList(params) {
    try {
      const {data} = await this.axios.get(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/`,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
          params: params,
        });
      return data;
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode==500) {
        notification.error('Error', 'Server Error 500');
      }
      return Promise.reject(error);
    }
  }

  public async getContentIdea(id: any, hash?: string) {
    const params = {};

    if (hash) {
      params['hash'] = hash;
    }
    try {
      const {data} = await this.axios.get(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${id}/`,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
          params: params,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async refetchContentIdea(id: number) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${id}/refetch/`, {},
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async postContentIdeaScaPage(id: number, payLoad: any) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${id}/page_uuid/`, payLoad,
        {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async createContentIdea(keyword: string, countryCode: string, url:string) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/`,
        {
          keyword,
          country_code: countryCode,
          domain: url,
        },
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('Error', errorMessage);
      return Promise.reject(error);
    }
  }

  public async createContentIdeaPublic(keyword: string, countryCode: string) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/`,
        {
          keyword,
          country_code: countryCode,
        },
        {
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async archiveIdea(id: number) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${id}/archive/`, {},
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async archiveClusterIdea(id: number, dataa) {
    const payload = {
      'cluster_id': dataa.id,
    };
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${id}/toggle_archive_cluster/`, payload,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createClusterArticlesBulk(payload) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrlCluster}/pages/bulk-create/`, payload,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async bulkClusteruuid(contentIdeaID, payload) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${contentIdeaID}/bulk-update-page-uuids/`, payload,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async starClusterIdea(id: number, data) {
    const payload = {
      'cluster_id': data.id,
    };
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${id}/toggle_star_cluster/`, payload,
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async refetchContentIdeas(id: number) {
    try {
      const {data} = await this.axios.post(`${KeywordExplorerApi.baseUrl}/v1/content_ideas/${id}/refetch/`, {},
        {
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
export const KEYWORD_EXPLORER_API = new KeywordExplorerApi();
