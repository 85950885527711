import React, {useState, useEffect} from 'react';
import FilterPopUp from '../filter-popup';
import FilterCheckboxPopUp from '../filter-checkbox-popup';
import FilterMultipleCheckboxPopUp from '../filter-multiple-checkbox-popup';
import {ChevronDown, ChevronUp} from 'react-feather';
import {useTranslation} from 'next-i18next';
import styles from './style.module.scss';
import FilterKeywordPopUp from '../filter-keyword-popup';
import FilterMultipleRadioPopUp from '../filter-multiple-radio-popup';
import {Popover, Badge} from 'antd';
import {titleCase} from '@/utils/string';
import {NextImg} from '@/utils/nextImg';

interface Props {
  mainFilters?: any;
  add?: boolean;
  filtersLength?:number;
  openFilters?:() => void;
  onChange?: (w:any)=>void;
  openFiltersContainer?: boolean;
  horizontalFiltersList?: boolean;
  onFilter?: Function;
  showCheckboxIcons?: boolean;
  isIssuePage?: boolean;
  drawerState?: boolean;
}

const Filters:React.FC<Props> = ({mainFilters, add=false, filtersLength, openFilters, horizontalFiltersList = false, onChange, openFiltersContainer, showCheckboxIcons, isIssuePage, drawerState}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isItemOpened, setIsItemOpened] = useState(false);
  const [filterItemId, setFilterItemId] = useState(undefined);
  const [filterTooltip, setFilterTooltip] = useState(false);
  const {t} = useTranslation('common');
  useEffect(() => {
    if (!isOpened) {
      setIsItemOpened(false);
      setFilterItemId(undefined);
    }
  }, [!isOpened]);
  const closeFilters = ( ) =>{
    setIsOpened(false);
  };

  useEffect(() => {
    closeFilters();
  }, [drawerState]);

  const handleHoverItem = (e, id) =>{
    e.stopPropagation();
    setFilterItemId(id);
    setIsItemOpened(true);
  };
  const getColor = value => {
    switch (value) {
      case 'Very easy':
        return '#2AC155';
      case 'Easy':
        return '#2AC155';
      case 'Moderate':
        return '#BFE15E';
      case 'Moderately difficult':
        return '#FF8536';
      case 'Challenging':
        return '#F44343';
      case 'Extremely challenging':
        return '#B62222';
      default:
        return '';
    }
  };
  const itemExists = id =>{
    const item = mainFilters.find(item => item.id=== id);
    return item.active;
  };
  const makeOptions = (filterTypes, icon=false) => {
    const type = filterTypes?.map(item => {
      return {label: <div style={{marginTop: 8, marginBottom: 8}}>{icon && <Badge style={{marginLeft: 4}} dot color={getColor(item)}/>} {titleCase(item)}</div>, value: item};
    });
    return type;
  };

  return (
    <>
      <div
        onClick={()=> (!add || horizontalFiltersList) && filtersLength ? openFilters() : setIsOpened(!isOpened)}
        style={add ? {backgroundColor: '#ECE4F3', color: '#7f4ead'}:{}}
        className={styles.filters}>
        <Popover placement='bottomLeft' visible={isOpened && !filterTooltip} overlayClassName={styles.filtersItems} content={
          <div>
            {mainFilters.map(item => (
              <Popover
                key={item.id} onVisibleChange={open => setFilterTooltip(open)} visible={isItemOpened} placement='rightTop' overlayClassName={styles.OptionContainer} content={(isItemOpened && filterItemId === item.id )&& (
                  item.name.includes('exclude') ?
                    <FilterKeywordPopUp
                      filtersLength={filtersLength}
                      add={add}
                      filterId={item.id}
                      onChange={onChange}
                      onClose={closeFilters}
                      filterLabel={item.header}
                      customFields={item?.customFields}
                      filterName={item.name}
                      filterField={item?.filterField}
                    /> : item?.filterTypes?.length ? (
                      item.name == 'checkboxes' ?
                        <FilterMultipleCheckboxPopUp
                          categories={item?.filterTypes}
                          options={makeOptions(item?.filterTypes, showCheckboxIcons)}
                          add={add}
                          className={styles.checkBoxFiltersStyled}
                          onChange={onChange}
                          onClose={closeFilters}
                          filterId={item.id}
                          filterLabel={item.header}
                          filterName={item.name}
                          customCategoryType={item?.customCategoryType}
                        /> : item?.name == 'radioButtonsFilter' ?
                          <FilterMultipleRadioPopUp
                            filtersLength={filtersLength}
                            options={item?.filterTypes}
                            add={add}
                            className={styles.checkBoxFiltersStyled}
                            onChange={onChange}
                            onClose={closeFilters}
                            filterId={item.id}
                            filterLabel={item.header}
                            filterName={item.name}
                            key={item.header}
                          /> :
                          <FilterCheckboxPopUp
                            categories={item?.filterTypes}
                            options={makeOptions(item?.filterTypes)}
                            add={add}
                            onChange={onChange}
                            onClose={closeFilters}
                            filterId={item.id}
                            filterLabel={item.header}
                            filterName={item.name}
                            customType={item?.customType}
                          />) :
                      <FilterPopUp
                        filtersLength={filtersLength}
                        add={add}
                        filterId={item.id}
                        onChange={onChange}
                        onClose={closeFilters}
                        filterLabel={item.header}
                        isIssuePage = {isIssuePage}
                        filterName={item.name}
                      />
                )}>
                <div
                  onMouseOver={e=> itemExists(item.id) ? setIsItemOpened(false) : handleHoverItem(e, item.id)}
                  className={`${styles.filtersItem} ${filterItemId === item.id && styles.currentRow} ${itemExists(item.id) && styles.gray}`}>
                  {item.header}
                </div>
              </Popover>
            ))
            }
          </div>
        }>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {add ? '+ Add' : (<>
              <NextImg src='/img/icon/filters-icon.svg' height={14} width={14} />
              <span className={styles.filtersText}> {t('filters')}</span>
              {(filtersLength >=1 || horizontalFiltersList) && (<>{filtersLength ? <span>:{` `}{filtersLength}</span> : ''}
                <span className={styles.chevron}>
                  {openFiltersContainer ? <ChevronUp /> : <ChevronDown />}
                </span></>)
              }
            </>)
            }
          </div>
        </Popover>
      </div>
    </>
  );
};

export default Filters;
