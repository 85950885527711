import React, {useEffect, useState, ReactNode} from 'react';
import {Tooltip} from 'antd';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import styles from './style.module.scss';
import LoginModal from '@/components/common-components/components/authentication-modal';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {NoSsr, Typography} from '@/components/common-components';
import moment from 'moment';
import {
  getLastPropertyFromCookies,
  getPeriodFromLocalStorage,
  getTokenFromCookies,
  setCountryCodeToLocalStorage,
  setLastPropertyIntoCookies,
} from '@/api/common-utils';
import {RenderSitePropertySelect} from '../header/headerComponents/renderSitePropertySelect';
import {RenderSaProjectSelect} from '../header/headerComponents/renderSaProjectSelect';
import {SiteHealthWithProgress} from '../header/headerComponents/siteHealthWithProgress';
import {RenderSiteEventsDateRangePicker} from '../header/headerComponents/siteEventsRangePicker';
import {RenderDateRangePicker} from '../header/headerComponents/rangePicker';
import {RenderCountrySelect} from '../header/headerComponents/renderCountrySelect';
import {getSingleUrlParam} from '@/utils/url';
import {useTranslation} from 'next-i18next';
import {CP_OPENED_FROM_KE, HOME_UPGRADE_CTA_CLICKED} from '@/constants/events';
import {NextImg} from '@/utils/nextImg';

import {MixPanel} from '@/utils/mixpanel';
import {getAbsoluteLegacyUrl} from '@/utils/legacy';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBallPile,
  faBullseyePointer,
  faCubes,
  faUsdCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import {SiteHealthWithProgressPublic} from '../header/headerComponents/siteHealthWithProgressPublic';
import {RenderDateRangePickerSA} from '../header/headerComponents/rangePickerSA';
import Link from 'next/link';
import classnames from 'classnames';
import {PostOnboardingModal} from '@/components/dashboard/pages/competitor-researcher/domain-analyzer-public/post-onboarding-modal';
import {RenderKrtProjectSelect} from '../header/headerComponents/renderKrtProjectSelect';
import {LoadingOutlined} from '@ant-design/icons';
import {GscConnectModal} from '@/components/dashboard/pages/site-audit-v2/common/gscConnectModal';
import {AiContentToolsDrawer} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/Drawers/AiContentToolsDrawer';
import {toJS} from 'mobx';
import {linkgraphDomain, routes} from '@/utils/const';
import {AiWriterSideDrawer} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/Drawers/AiWriterSideDrawer';
import {SmallBadge} from '@/components/common-components/components/badge';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';

type ChildPagesProps = {
  commingSoon?: boolean;
  disabledMsg?: string;
  color?: string;
  key: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  isNew?: boolean;
  unreadEmails?: boolean;
};

type SideBarProps = {
  commingSoon?: boolean;
  disabledMsg?: string;
  color?: string;
  key: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  isNew?: boolean;
  unreadEmails?: boolean;
  childPages?: ChildPagesProps[];
  type?: string;
  shortTitle?: string;
};

type SiderProps = {
  sideBarItems?: SideBarProps[];
  publicPage?: boolean;
};

export const CommonSidebar: React.FC<SiderProps> = observer(({sideBarItems, publicPage}) => {
  const [isLoader, setIsLoader]= useState(false);
  const [isDropDownSa, setDropDownSa] = useState(false);
  const [loaderInSideBar, setLoaderInSideBar] = useState('');
  const [contentRedirectLoader, setContentRedirectLoader] = useState(false);
  const currentCustomerId = localStorage.getItem('currentCustomerId');
  const router = useRouter();

  const getPercentage = (val1, val2) => {
    if (val1 && val2) {
      return val1/val2 * 100;
    } else {
      return 0;
    }
  };

  const {width} = useWindowSize();
  const {
    keywordResearcherV2: {loadSideBarKEList, sideBarKEListParams, setSideBarParam, getSideBarKEList, sideBarKeywordListPages, keywordDetails: {keSelectedId}},
    settings: {
      customer: {setAuthModal, profile, isInitialExtendedDetailsFetched},
    },
  } = useStore('');
  const {
    settings, navBar, gsc,
  } = useStore('');
  const {
    navBar: {isDockedSider, setIsDockedSider, loadSiteProperties},
    home: {isChild},
    settings: {integration: {hasGoogleProfiles}},
  } = useStore('');
  const {settings: {customer: {profile: {isWhitelabel, gscProductOverview, isVendastaClient, isVerified, logo, isSubscriber, isViewer}}}} = useStore('');
  const [customerLogo, setCustomerLogo] = useState(logo);

  useEffect(() => {
    setCustomerLogo(logo);
  }, [logo]);

  let htmlToDraft = null;
  if (typeof window === 'object') {
    htmlToDraft = require('html-to-draftjs').default;
  }

  const isGSCDashboard = router.pathname.match(/\/gsc.*/g);
  const isSiteEventsPage = router.pathname.match(/\/gsc\/site-events.*/g);
  const isKrtDetailsPage = router.pathname.match(/\/research\/keyword-rank-tracker\/.*/g);
  const isSitesListPage = router.pathname.match(/\/gsc\/sites-list.*/g);
  const isSiteAudit = router.pathname.match(/\/site-audit-old\/.*/g);
  const isSiteAuditV2 = router.pathname.match(/\/site-audit\/.*/g);
  const checkPublicPages = router.pathname?.includes('public');
  const isGscBacklinksPage = router.pathname.match(/\/gsc\/backlinks.*/g);
  const isSiteStructurePage = router.pathname.match(/\/gsc\/site-structure.*/g);
  const isTopPagesPage = router.pathname.match(/\/gsc\/pages.*/g);
  const isKeywordsPage = router.pathname.match(/\/gsc\/keywords.*/g);
  const isGscOverview = router.pathname.match(/\/gsc\/overview.*/g);
  const researchTools = ['/research/keyword-researcher', '/research/keyword-magic-tool', '/research/keyword-rank-tracker', '/research/keyword-gap-analysis'];
  const isKeywordPage = researchTools?.includes(router?.pathname);
  const {
    plans: {showSidebarPaymentDrawer},
    settings: {customer: {remainingExpiryDays, profile: {plan}}},
    contentOptimizer: {
      currentPage: {content},
      aiOutline: {
        isOpenAiWriterDrawer,
        aiArticleAlreadyReplaced,
      },
    },
  } = useStore('');

  const {siteAuditorV2: {getAuditSitesListBasic, getSiteAuditSpacificData, siteAuditSelectedProperty, gscLoading, openGscModal, callGscModalApi, setGscOpenModalValue, auditedSitesList}} = useStore('');

  const loading = content.aiInProgress;
  const [contentLength] = useState<'short' | 'medium' | 'long'>('medium');
  const [elapsedTime, setElapsedTime] = useState(0);
  const [hideSidebarBottomSection, setHideSidebarBottomSection] = useState(true);

  const {t} = useTranslation('common');
  const projectId = siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || router.query?.id);
  const isGscObject = getAuditSitesListBasic?.find(item=>item?.id == projectId) ?? getSiteAuditSpacificData;
  const showPropertiesPicker = (
    !settings.integration.integrationLoading &&
    !navBar.loading
  );

  useEffect(() => {
    if (isOpenAiWriterDrawer) {
      let totalTime = 100 * 1000;

      switch (contentLength) {
        case 'short':
          totalTime = 100 * 1000;
          break;
        case 'medium':
          totalTime = 180 * 1000;
          break;
        case 'long':
          totalTime = 300 * 1000;
          break;
        default:
          break;
      }

      if (!loading) return;

      const intervalId = setInterval(() => {
        if (elapsedTime < totalTime) {
          setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId); // Clean up the interval on component unmount
      };
    } else if (aiArticleAlreadyReplaced) {
      setElapsedTime(0);
    }
  }, [elapsedTime, loading, isOpenAiWriterDrawer]);

  const openLoginModal = () => {
    setAuthModal(true, true);
  };

  const defaultPreset = [
    moment().subtract(1, 'years').subtract(1, 'days'),
    moment().subtract(1, 'days'),
  ];

  const {criteria} = gsc;
  const googleProfiels = settings.integration.googleProfiles;
  const siteProperties = navBar.propertiesData;

  const updateDefaultCriteria = (url: string, lastPeriod?: any, countryCode?: string) => {
    const [currentPeriodStart, currentPeriodEnd] = defaultPreset;
    const diff = moment(currentPeriodEnd).diff(moment(currentPeriodStart), 'days') + 1;
    const previousPeriodStart = moment(currentPeriodStart).subtract(1, 'days').subtract(diff, 'days');
    const previousPeriodEnd = moment(currentPeriodStart).subtract(2, 'days');

    criteria.updateCriteria({
      property: url,
      previousPeriodStart: lastPeriod?.p1Start ? lastPeriod.p1Start : previousPeriodStart.format('YYYY-MM-DD'),
      previousPeriodEnd: lastPeriod?.p1End ? lastPeriod.p1End : previousPeriodEnd.format('YYYY-MM-DD'),
      currentPeriodStart: lastPeriod?.p2Start ? lastPeriod.p2Start : currentPeriodStart.format('YYYY-MM-DD'),
      currentPeriodEnd: lastPeriod?.p2End ? lastPeriod.p2End : currentPeriodEnd.format('YYYY-MM-DD'),
      periodStatus: lastPeriod?.periodStatus ? lastPeriod.periodStatus : '1 year',
      countryCode: countryCode ? countryCode : '',
    });

    gsc.sitePropertyEvents.siteEventCriteriaUpdate({
      property: url,
      previousPeriodStart: lastPeriod?.p1Start ? lastPeriod.p1Start : previousPeriodStart.format('YYYY-MM-DD'),
      previousPeriodEnd: lastPeriod?.p1End ? lastPeriod.p1End : previousPeriodEnd.format('YYYY-MM-DD'),
      currentPeriodStart: lastPeriod?.p2Start ? lastPeriod.p2Start : currentPeriodStart.format('YYYY-MM-DD'),
      currentPeriodEnd: lastPeriod?.p2End ? lastPeriod.p2End : currentPeriodEnd.format('YYYY-MM-DD'),
      periodStatus: lastPeriod?.periodStatus ? lastPeriod.periodStatus : '1 year',
      countryCode: countryCode ? countryCode : '',
    });
  };
  const closeGscModal = ()=>{
    setGscOpenModalValue(false);
  };

  const loadGSCSiteProperties = async () => {
    // Load G Profiles.
    const {integration} = settings;
    integration.loadGoogleProfiles();
    // Got G profiles, try to load site properties.
    const adminCode = getSingleUrlParam('gsc_admin_code');
    if (adminCode) {
      await loadSiteProperties(adminCode || '');
    }
    if (navBar.hasSiteProperties) {
      const {propertiesData} = navBar;
      const lastProperty = getLastPropertyFromCookies();
      const lastPeriod = JSON.parse(getPeriodFromLocalStorage());

      const property = propertiesData.filter(el => el.url === lastProperty).length > 0 ? propertiesData.filter(el => el.url === lastProperty) : propertiesData.filter(el => el.activeForCurrentCustomer);
      const urlProperty = router.query.sp?.includes('?') ? router.query.sp?.slice(0, -1) : router.query.sp;
      const selectedSiteProperty = navBar.getSiteProperty(urlProperty || (property.length ? property[0].url : null));
      const countryCodeParam = getSingleUrlParam('country_code');
      const matchCountryFromParam = selectedSiteProperty?.activeCountryCodes?.length ? selectedSiteProperty?.activeCountryCodes?.find(item => item == countryCodeParam) : '';
      const countryCode = matchCountryFromParam ? matchCountryFromParam : (selectedSiteProperty?.activeCountryCodes?.length ? selectedSiteProperty?.activeCountryCodes[0] : '');
      updateDefaultCriteria(selectedSiteProperty?.url, lastPeriod, countryCode);
      setCountryCodeToLocalStorage(countryCode);
      setLastPropertyIntoCookies(selectedSiteProperty?.url);
    } else {
      // In this case we will create a demo array of site properties to use
      const demoProperties = [`sc-domain:${linkgraphDomain}`];
      const selectedDemoProperty = demoProperties[0];
      !isWhitelabel && updateDefaultCriteria(selectedDemoProperty);
    }
  };

  useEffect(() => {
    // necessary for settings sidedrawer to get GSC list
    if (!publicPage) {
      loadGSCSiteProperties(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [
    googleProfiels,
    siteProperties,
    navBar.hasSiteProperties,
  ]);

  useEffect(() => {
    setHideSidebarBottomSection(true);

    const timer = setTimeout(() => {
      setHideSidebarBottomSection(false);
      clearTimeout(timer);
    }, 5000);
  }, []);

  useEffect(() => {
    if (router.asPath === '/home' || width < 1300) {
      setIsDockedSider(false);
    } else if (router.asPath.includes('/otto-page-v2/tasks')) {
      setIsDockedSider(false);
    } else {
      setIsDockedSider(true);
    }
    setContentRedirectLoader(false);
  }, [width, router]);


  let renderFreeConsultation = null;
  if (router.pathname !== '/checkout' && renderFreeConsultation == null ) {
    renderFreeConsultation= (
      <div className={styles.sidebarCTA}>
        <Typography tag='p'>
          {isWhitelabel ? t('ready-most-powerful').replace(' of Search Atlas', '') : t('ready-most-powerful')}
        </Typography >
        <ButtonComponent
          style='solid'
          color='green'
          onClick={() => {
            showSidebarPaymentDrawer();
            MixPanel.track(HOME_UPGRADE_CTA_CLICKED);
          }}
          className={ styles.freeConsultation }>
          {t('upgrade-to-pro')}
        </ButtonComponent>
        <div className={styles.borderCTA} />
      </div>
    );
  }

  const isPricingPage = router.pathname.match(/\/pricing.*/g);
  const isOrderBuilderPage = router.pathname.match(/\/order-builder.*/g);
  const isLinkBuildingPage = router.pathname.match(/\/orders\/link-building.*/g);
  const tableName = getSingleUrlParam('table');
  const isMagicTool = router.pathname?.includes('/keyword-magic-tool');
  const pagesToShowKEList = [
    '/research/keyword-researcher/[...keyword]',
    '/research/keyword-researcher-magic/[...keyword]',
  ];

  const handleKEList = async () => {
    setIsLoader(true);
    await loadSideBarKEList();
    setIsLoader(false);
  };

  const handleScroll = e => {
    if (sideBarKEListParams.page < sideBarKeywordListPages && !isLoader) {
      if ([e.target.scrollHeight + 1, e.target.scrollHeight, e.target.scrollHeight - 1].includes(Math.round(e.target.offsetHeight + e.target.scrollTop))) {
        setSideBarParam(sideBarKEListParams.page + 1);
        handleKEList();
      }
    }
  };

  useEffect(() => {
    if (pagesToShowKEList.includes(router?.pathname)) {
      setSideBarParam(1);
      handleKEList();
    }
  }, [router?.pathname]);
  let getSidebarItemHeight = null;
  const getSidebarItem = document.getElementById('sidebar_upper_items_div');
  if (getSidebarItem) {
    getSidebarItemHeight = getSidebarItem.clientHeight + 65 + 200;
  }
  const antIcon = <div style={{width: '100%', textAlign: 'center'}}><LoadingOutlined style={{fontSize: 20, color: '#A3A4A4'}} spin /></div>;
  const antIconSmall = <LoadingOutlined style={{fontSize: 12, marginLeft: '4px', color: '#A3A4A4'}} spin />;
  const handleKERedirection = async item => {
    setLoaderInSideBar(item?.name);
    localStorage.setItem('listname', item?.name);
    const selectedLocation = item?.locationId ? `locationId=${item.locationId}` : `countryCode=${item?.countryCode ? item.countryCode : ''}`;
    const checkPrevPage = isMagicTool && !tableName ? 'keyword-magic-tool' : 'keyword-researcher-magic';
    if (item?.keywords?.length == 1 && item?.id) {
      await router.push(`/research/keyword-researcher/${item.id}/${item.keywords ? item.keywords[0] : ''}?${selectedLocation}`);
    } else {
      await router.push(`/research/${checkPrevPage}/${item?.id}/${item?.keywords ? item?.keywords[0] : ''}?${selectedLocation}`);
    }
    setLoaderInSideBar('');
  };
  const handleKwReAndMaToolRedirection = item => {
    const getUrl = item.path + '-public';
    if (item.path == '/research/keyword-researcher' || item.path == '/research/keyword-magic-tool') {
      router.push(getUrl);
    } else {
      setAuthModal(true, true);
    }
  };
  const handleClickOnCluster = ()=> {
    setContentRedirectLoader(true);
    if (!publicPage) {
      MixPanel.track(CP_OPENED_FROM_KE);
      router?.push('/content/content-planner');
    } else {
      setContentRedirectLoader(false);
      return;
    }
  };

  const gbpAutomationsPaths = [
    routes?.myTasks,
    routes?.gbpAutomationsPosts,
    routes?.gbpAutomationsReviews,
    routes?.gbpAutomationsQuestions,
  ];

  return <NoSsr>
    {<SideBar isDockedSider={isDockedSider}>
      <div className='logo-container'>
        <NextImg className={`logo-responsive`}
          style={{height: '100%', padding: 5, overflow: 'hidden', marginLeft: '5px', display: 'flex', alignItems: 'center', transition: 'all 0.2s ease', width: profile.isWhitelabel ? '100%' : '160px', objectFit: 'contain'}}
          onClick={(checkPublicPages && !isSubscriber) ? () => setAuthModal(true, true) : ()=> router.push(routes?.home)}
          src={profile.isWhitelabel && customerLogo ? customerLogo : '/img/logo/searchAtlas_new_login.svg'}
          alt='logo'
        />
      </div>
      <AiContentToolsDrawer htmlToDraft={htmlToDraft}/>
      <AiWriterSideDrawer htmlToDraft={htmlToDraft} elapsedTime={elapsedTime} setElapsedTime={setElapsedTime} />
      <PostOnboardingModal />
      <SideBarWrapper id={'sidebar_upper_items_div'}>
        {!publicPage ? <div style={{height: 'fit-content'}}>
          {showPropertiesPicker && isGSCDashboard && !gscProductOverview && !isGscBacklinksPage && (
            <div style={!isSitesListPage ? {marginBottom: '20px'} : {}}>
              { !isSitesListPage && <>
                <RenderSitePropertySelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockeSideBar={isDockedSider} />
                {(!isKeywordsPage && !isTopPagesPage && !isGscOverview && !isSiteStructurePage) ?
                  isSiteEventsPage ? <RenderSiteEventsDateRangePicker /> : <RenderDateRangePicker setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} /> :
                  null}
                {(router.asPath.includes('gsc/reports/')) && <RenderCountrySelect isDocked={isDockedSider}/>}
              </>}
            </div>
          )}
          {(isGscBacklinksPage && navBar.hasSiteProperties) && (
            <div style={{marginBottom: '20px'}}>
              <RenderSitePropertySelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockeSideBar={isDockedSider} />
            </div>
          )}
          {(isSiteAudit || router.pathname == '/site-audit-old') && hasGoogleProfiles && (
            <div>
              <RenderSitePropertySelect />
            </div>
          )}
          {isSiteAuditV2 && !isSiteAuditV2?.includes('/site-audit/list') && <div style={{marginBottom: '20px'}}>
            <RenderSaProjectSelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider} />
            {isSiteAuditV2?.includes('/site-audit/overview') || isSiteAuditV2?.includes(`/site-audit/crawl-monitoring`) ?
              <RenderDateRangePickerSA setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider}/> : ''}
            {!isSiteAuditV2?.includes('/site-audit/site-visualization') ?
              <SiteHealthWithProgress isDockedSider={isDockedSider}/> : ''}
          </div>}

          {isKrtDetailsPage ? <RenderKrtProjectSelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider} /> : null}

        </div> : isSiteAuditV2 && !isSiteAuditV2?.includes('/site-audit/list-public') && <div style={{height: 'fit-content'}}>
          <RenderSaProjectSelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider} />
          {isSiteAuditV2?.includes('/site-audit/overview-public') ?
            <DateRangePublic> <RenderDateRangePickerSA isDockedSider={isDockedSider}/></DateRangePublic> : ''}
          <SiteHealthWithProgressPublic isDockedSider={isDockedSider}/>
        </div>}
        <div>
          {sideBarItems?.filter(z => !z?.hidden)?.map((item, idx) => {
            if (publicPage) {
              if (item?.path?.includes('/site-audit/') && item?.path !== '/site-audit/pruning-page') {
                const getUrlId = getSingleUrlParam('id') || getSingleUrlParam('audit') || toJS(auditedSitesList)[0]?.id;
                const getHash = getSingleUrlParam('public_hash') || toJS(auditedSitesList)[0]?.publicShareHash;
                const makePath = item?.path + `-public?id=${getUrlId || ''}&public_hash=${getHash || ''}`;
                return <Link legacyBehavior key={idx} href={makePath} passHref><a>
                  <Tooltip placement='right' title={isDockedSider ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                    <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)}>
                      <IconSpan>{item?.icon}</IconSpan> {isDockedSider ? item?.title : ''}
                    </PopoverItem>
                  </Tooltip>
                </a></Link>;
              } else {
                return <Tooltip key={idx} placement='right' title={isDockedSider ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)} onClick={() => {
                    (router.pathname?.includes('public') && !getTokenFromCookies()) && handleKwReAndMaToolRedirection(item);
                  }}>
                    <IconSpan>{item?.icon}</IconSpan> {isDockedSider ? item?.title : ''}
                  </PopoverItem>
                </Tooltip>;
              }
            } else {
              if (item?.commingSoon) {
                return (['GBP Automations', 'Local Listings'].includes(item?.title) ? <></> :
                  <Tooltip placement='right' title={item?.commingSoon ? (item?.disabledMsg || 'Feature coming soon.') : ''}>
                    <PopoverItem itemColor={item?.color} key={item.key} style={item?.commingSoon ? {opacity: '50%', position: 'relative'} : {position: 'relative'}} highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)}>
                      <IconSpan>{item?.icon}</IconSpan> {isDockedSider ? item?.title : ''}
                      {isDockedSider && item?.isAlpha && <SmallBadge alpha />}
                    </PopoverItem>
                  </Tooltip>
                );
              } else {
                if (item?.title == 'Page Pruning' && !isGscObject?.gscConnected) {
                  return <div key={idx} onClick={()=>setGscOpenModalValue(true, 'Google Search Console Settings', true, isGscObject?.id)}>
                    <Tooltip placement='right' title={isDockedSider ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                      <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)} style={{position: 'relative'}}>
                        <IconSpan>{item?.icon}</IconSpan> {isDockedSider ?<span> {item?.title}</span> : ''}
                        {isDockedSider && item?.isAlpha && <SmallBadge alpha />}
                        {isDockedSider && item?.isBeta && <SmallBadge beta />}
                        {isDockedSider && item?.isNew && <SmallBadge isNew />}
                      </PopoverItem>
                    </Tooltip>
                  </div>;
                } else {
                  if (item.type == 'group') {
                    return <GroupTitleStyled key={item.key}>{isDockedSider ? item.title : item.shortTitle}</GroupTitleStyled>;
                  } else {
                    return <div key={idx}>
                      <Link legacyBehavior href={item.path} passHref><a>
                        <Tooltip placement='right' title={isDockedSider ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                          <PopoverItem
                            itemColor={item?.color}
                            key={item.key}
                            highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path) || router.asPath?.includes(item?.path?.split('?')[0]) || (gbpAutomationsPaths?.includes(router.asPath) && item?.path?.includes('gbp-automations'))} style={{position: 'relative'}}>
                            <IconSpan>{item?.icon}</IconSpan> {isDockedSider ?<span> {item?.title} {item?.unreadEmails ? <UnreadEmails>{item?.unreadEmails}</UnreadEmails> : ''}</span> : ''}
                            {isDockedSider && item?.isAlpha && <SmallBadge alpha />}
                            {isDockedSider && item?.isBeta && <SmallBadge beta />}
                            {(isDockedSider && item?.isNew) ? item?.title === 'Topical Maps' ? <SmallBadge isNew={item?.isNew} customStyle={{backgroundColor: '#514568'}}/> : <SmallBadge isNew /> : ''}
                          </PopoverItem>
                        </Tooltip>
                      </a></Link>
                      <ChildPagesWrapper>
                        {item?.childPages && isDockedSider && item?.childPages?.map(child => !child?.hidden && <Link legacyBehavior key={child?.key} href={child?.path} passHref>
                          <a>
                            <ChildPage style={{borderBottom: router.asPath?.includes(child?.path?.split('?')[0]) ? '2px solid #FFFFFF' : ''}}>
                              {child?.title}
                              {isDockedSider && child?.isAlpha && <SmallBadge alpha customStyle={{left: '115px', top: '7px'}} />}
                              {isDockedSider && child?.isBeta && <SmallBadge beta customStyle={{left: '115px', top: '7px'}} />}
                            </ChildPage>
                          </a>
                        </Link>)}
                      </ChildPagesWrapper>
                    </div>;
                  }
                }
              }
            }
          })}
        </div>
      </SideBarWrapper>
      <div >
        {pagesToShowKEList.includes(router?.pathname) && getSideBarKEList?.length ? <WrapperList style={{height: `calc(100vh - ${getSidebarItemHeight}px)`}} onScroll={handleScroll}>
          <KeywordList>KEYWORD LISTS</KeywordList>
          {getSideBarKEList?.map((item, idx) => {
            return <KeywordItem key={`KeywordItem_${idx}`} selected={`${item?.id}` == keSelectedId} onClick={() => handleKERedirection(item)}>
              <div>{item?.name}<span>{loaderInSideBar == item?.name ? antIconSmall : ''}</span></div>
              <div>{item?.keywords?.length ?? '0'}</div>
            </KeywordItem>;
          })}
          {isLoader ? antIcon : ''}
        </WrapperList> : ''}
      </div>
      <div style={{width: '100%'}}>
        <div className={styles.opacity} />

        <div className={styles.trailSec} style={{width: isDockedSider ? '230px' : '65px'}} onClick={() => {
          if (publicPage && !isVerified) {
            openLoginModal();
          }
        }}>
          {!isInitialExtendedDetailsFetched || isVendastaClient ? '':
            <>
              {isDockedSider && remainingExpiryDays && Number(remainingExpiryDays) > -1 ?
                <div>
                  <p style={{marginTop: '10px', color: '#A3A4A4'}}>Your trial ends in {remainingExpiryDays} days</p>
                  <TrialBar>
                    <div style={{background: '#8860D4', height: 5, borderRadius: 3,
                      width: `${getPercentage(7- remainingExpiryDays, 7)/100}%`}}/>
                  </TrialBar>
                </div> :
                ''}
              {/* Hide link building form for ALL agency children */}
              {(profile.isWhitelabel && !profile.isParentAccount) || profile?.isShopifyCustomer || hideSidebarBottomSection || isViewer ? '' : <>
                <Tooltip overlayStyle={{position: 'fixed'}} placement='right' title={isDockedSider ? '' : `Link Building Form`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <a href={(!publicPage || isVerified) && getAbsoluteLegacyUrl('orders/link-building')} className={isLinkBuildingPage ? styles.active : ''}><span style={{minWidth: '36px'}}><Icon onClick={() => {}} icon={faBullseyePointer} /></span>{isDockedSider ? <span>Link Building Form</span> : ''}</a>
                </Tooltip></>}

              {/* Hide link building form for ALL agency children */}
              {/* We will enable it soon after we add toggle in settings menu */}
              {(profile.isWhitelabel && !profile.isParentAccount) || profile?.isShopifyCustomer || hideSidebarBottomSection || isViewer ? '' : <>
                <Tooltip overlayStyle={{position: 'fixed'}} placement='right' title={isDockedSider ? '' : `Order Builder`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <div
                    onClick={()=>{
                      (!publicPage || isVerified) && router.push('/order-builder');
                    }}
                    className={isOrderBuilderPage ? classnames(styles.pricing, styles.active) : styles.pricing}>
                    <span style={{minWidth: '36px'}}><Icon onClick={() => {}} icon={faCubes} /></span>{isDockedSider ? <span>Order Builder</span> : ''}
                  </div>
                </Tooltip></>}

              {(profile.isWhitelabel && !profile.isParentAccount && !profile.shouldShowPricingPage) || profile?.isShopifyCustomer || hideSidebarBottomSection || isViewer ? '' :
                <Tooltip overlayStyle={{position: 'fixed'}} placement='right' title={isDockedSider ? '' : `Pricing`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <div
                    onClick={()=>{
                      (!publicPage || isVerified) && router.push(`/pricing${profile.isWhitelabel?'-agency':''}${isChild ? `?&customerId=${currentCustomerId ? currentCustomerId: ''}` : ''}`);
                    }}
                    className={isPricingPage ? classnames(styles.pricing, styles.active) : styles.pricing}>
                    <span style={{minWidth: '36px'}}><Icon onClick={() => {}} icon={faUsdCircle} /></span>{isDockedSider ? <span>Pricing</span> : ''}</div>
                </Tooltip>}
            </>}
        </div>
      </div>


      {!plan && isDockedSider && !publicPage && !profile?.isShopifyCustomer ? renderFreeConsultation : ''}
      {(isKeywordPage && isDockedSider) && (
        <StyledKeywordBanner>
          <div style={{marginBottom: '10px'}}><FontAwesomeIcon icon={faBallPile} fontSize={22} color={'#fff'}/></div>
          <KeywordBannerDescription>Looking for a more modern way to find relevant keywords to target in SEO Campaigns? </KeywordBannerDescription>
          <Tooltip
            placement={'right'}
            title={`Content Planner finds keywords based on what the top 100 results on Google are ranking for and clusters them algorithmically, giving you much more powerful SEO insights than traditional keyword research.`}>
            <ButtonComponent
              style='solid'
              color='orange'
              disabled={contentRedirectLoader}
              onClick={handleClickOnCluster}
              className={styles.keywordBannerButton}
            >
              {'Create Clusters'}{contentRedirectLoader ? <div style={{marginLeft: '10px'}}><LoadingOutlined style={{fontSize: 16, color: '#fff'}} spin /> </div>: <FontAwesomeIcon style={{marginLeft: '10px'}} icon={faArrowRight} fontSize={16} color={'#fff'}/>}
            </ButtonComponent>
          </Tooltip>
        </StyledKeywordBanner>
      )}
    </SideBar>}
    <LoginModal />
    {openGscModal && <GscConnectModal loading= {gscLoading} openGscModal={openGscModal} closeGscModal={closeGscModal} callGscModalApi={callGscModalApi}/>}
  </NoSsr>;
});

const GroupTitleStyled = styled.div`
  color: #A3A4A4;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.31px;
  padding: 5px 13px;
`;


const SideBarWrapper = styled.div`
a{
  text-decoration: none;
}
`;

const StyledKeywordBanner = styled.div`
padding: 18px 6px 18px 13px;
background-color: rgba(255, 133, 54, 0.15);
border-radius: 8px;
margin-top: 36px;
`;
const KeywordBannerDescription = styled.div`
color: #fff;
font-family: 'Inter', sans-serif;
font-weight: 500;
font-size: 13px;
`;

const SideBar = styled.div<{isDockedSider: boolean}>`
  background-color: #24262A;
  z-index: 50;
  overflow-x: ${p => p.isDockedSider ? 'hidden' : 'unset'};
  width: 230px;
  height: 100%;
  width: ${p => p.isDockedSider ? '230px' : '65px'};
  padding-bottom: 30px;
  transition: width 0.2s;
  padding-left: 13px;
  padding-right: 8px;
  padding-top: 40px;
  min-height: calc(100vh - 66px);
  .logo-container {
    width: 100%;
    height: 45px;
    ${p => p.isDockedSider ? '' : 'display: none !important;'}
  }
  @media screen and (max-width: 768px) {
    padding-top: 16px;
    position: ${p => p.isDockedSider ? 'absolute' : ''};
    transition: none !important;
  }
  @media screen and (min-width: 770px) {
    .logo-responsive {
      display: none !important;
    }
    .logo-container {
      display: none !important;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px !important;
  margin-right: 5px !important;
  /* padding-right: 17px; */
 
`;

const IconSpan = styled.span`
  /* margin-right: 5px; */
  display: flex;
  align-items: center;
  height: fit-content;
  min-width:36px;
  svg{
    width: 20px;
    height: 20px;
  }
`;
const WrapperList = styled.div`
  overflow: auto;
  margin-top: 30px;
  /* height: 200px; */
  &::-webkit-scrollbar{
    height: 5px;
      width: 5px;
      }
  &::-webkit-scrollbar-thumb{
    background: rgba(138,138,138,0.32);
  }
  &::-webkit-slider-runnable-track{
    background: rgba(138,138,138,0.12);
  }

`;

const PopoverItem = styled.div<{highlight: boolean; itemColor: string}>`
  background: ${p => p.highlight ? `${p.itemColor}33` : 'transparent'};
  border-radius: 4px;
  color: ${p => p.highlight ? '#fff' : '#E8E8E8'};
  padding: 5px 13px;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.01em;
  height: 36px;
  display: flex;
  align-items: center;
  svg {
    color: ${p => p.highlight ? p.itemColor : '#E8E8E8'} !important;
    fill: ${p => p.highlight ? p.itemColor : '#E8E8E8'} !important;
  }

  &:hover {
    background: #34363B;
    color: #FFF;
  }
`;

const DateRangePublic=styled.div`
  pointer-events: none;
`;

const TrialBar = styled.div`
  width: 200px;
  background: #4E5156;
  margin: -10px 0 15px 0;
  height: 5px;
  border-radius:3px;
`;

const KeywordList = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  padding-left: 12px;
  margin-bottom: 10px;
`;

const KeywordItem = styled.div<{selected?: boolean}>`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: ${p => p.selected ? '#FFFFFF' : '#A3A4A4'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px;
  background: ${p => p.selected ? '#4E515666' : 'transparent'};
  border-radius: ${p => p.selected ? '5px' : '0'};
  cursor: pointer;
  &:hover {
    background: #4E515666;
    border-radius: 5px;
    color: #FFFFFF;
  }
`;

const UnreadEmails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 16px;
  position: absolute;
  top: 2px;
  right: 47%;
  padding-top: 3px;
  padding-right: 2px;
  background-color: #F44343;
  border-radius: 31px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #FFFFFF;
`;

const ChildPagesWrapper = styled.div`
  margin-left: 55px;
  line-height: 30px;
`;

const ChildPage = styled.div`
  width: max-content;
  position: relative;
  color: #FFFFFF;
`;
