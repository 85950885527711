import {types, flow, cast} from 'mobx-state-tree';
import {issueAnalyzerApi} from '@/api/account';
import {toJS} from 'mobx';


const headerModel = types.model({
  internalRedirects: types.maybeNull(types.number),
  externalRedirects: types.maybeNull(types.number),
  redirectChains: types.maybeNull(types.number),
  redirectLoops: types.maybeNull(types.number),
  brokenChians: types.maybeNull(types.number),
});

const loadTimeModel = types.model({
  internal: types.maybeNull(types.number),
  external: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
});

const labelValueModal = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const colorValueModal = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
});

const depthModel = types.model({
  label: types.maybeNull(types.string),
  301: types.maybeNull(types.number),
  302: types.maybeNull(types.number),
  308: types.maybeNull(types.number),
});


const internalRedirectByModel = types.model({
  chainLength: types.maybeNull(types.array(labelValueModal)),
  depth: types.maybeNull(types.array(depthModel)),
  incomingLinks: types.maybeNull(types.array(labelValueModal)),
});


export const RedirectsBrokenStore = types.model({
  header: types.maybeNull(headerModel),
  redirectedUrlsByDepth: types.maybeNull(types.array(loadTimeModel)),
  internalRedirectTypeDistribution: types.maybeNull(types.array(colorValueModal)),
  statusCodeDistribution: types.maybeNull(types.array(colorValueModal)),
  internalRedirectBy: types.maybeNull(internalRedirectByModel),
  topInternalRedirectsByIncomingLinks: types.maybeNull(types.array(labelValueModal)),
  loading: types.boolean,
}).views( self => ({
  get getHeader() {
    return toJS(self.header);
  },
  get getTopInternalRedirectsByIncomingLinks() {
    return toJS(self.topInternalRedirectsByIncomingLinks);
  },
  get getInternalRedirectByDistribution() {
    return toJS(self.internalRedirectTypeDistribution);
  },
  get getStatusCodeDistribution() {
    return toJS(self.statusCodeDistribution);
  },
  get getRedirectedUrlsByDepth() {
    return toJS(self.redirectedUrlsByDepth);
  },
  get getInternalRedirectsBy() {
    return toJS(self.internalRedirectBy);
  },
})).actions(self => {
  const loadRedirectsBrokenReport = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.redirectsBrokenReport(domain);
      if (response) {
        self.header = response.header;
        self.redirectedUrlsByDepth = response.redirectedUrlsByDepth;
        self.internalRedirectTypeDistribution = response.internalRedirectTypeDistribution;
        self.statusCodeDistribution = response.statusCodeDistribution;
        self.internalRedirectBy = response.internalRedirectBy;
        self.topInternalRedirectsByIncomingLinks = cast(response.topInternalRedirectsByIncomingLinks);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  return {
    loadRedirectsBrokenReport,
  };
});

export function initRedirectsBrokenReportStore() {
  return RedirectsBrokenStore.create({
    loading: true,
  });
}
