import {toJS} from 'mobx';
import {flow, types} from 'mobx-state-tree';
import CountriesApi from '@/api/countries/countries-api';
import COUNTRY_CODES from '@/constants/countryCodes';
const hardCodedStateData = require('@/constants/state.json');

const countriesApi = new CountriesApi();
const languageModel = types.model({
  code: types.string,
  name: types.string,
});

const CountriesModel = types.model({
  name: types.string,
  code: types.string,
  emoji: types.string,
  currencyCode: types.string,
  currencyName: types.string,
  currencySymbol: types.string,
  languages: types.array(languageModel),
});

interface LanguageType {
  code: string;
  name: string;
}
interface CountriesType {
  name: string;
  code: string;
  emoji: string;
  currencyCode: string;
  currencyName: string;
  currencySymbol: string;
  languages: Array<LanguageType>;
}

export const CountriesStore = types.model({
  countriesLoading: types.boolean,
  countriesData: types.array(CountriesModel),
}).views(self => ({
  get getCountriesData() {
    return toJS(self.countriesData) || [];
  },
  get getLanguages() {
    const languages = [];
    toJS(self.countriesData).forEach(d => {
      languages.push(...d.languages);
    });
    const uniqueLanguages = languages.filter((value, index) => {
      const _value = JSON.stringify(value);
      return index === languages.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      });
    });
    return uniqueLanguages;
  },
})).actions(self => {
  const loadCountriesData = flow(function* () {
    let data = null;
    self.countriesLoading = true;
    try {
      const response = yield countriesApi.getAll();
      if (response?.isCancel) return;
      data = response.map(item => {
        let currencies = {
          name: '',
          symbol: '',
          code: '',
        };
        if (Object.keys(item.currencies).length) {
          const code = Object.keys(item.currencies).pop();
          currencies = {...item.currencies[code], code: code};
        }
        return ({
          name: item.name.common,
          code: item.cca2,
          emoji: item.flags['png'],
          currencyCode: currencies.code,
          currencyName: currencies.name,
          currencySymbol: currencies.symbol,
          languages: Object.keys(item.languages).map(language => ({code: language, name: item.languages[language]})),
        });
      });
      self.countriesData = data;
    } catch (e) {
      // failed to fetch countries data
    } finally {
      self.countriesLoading = false;
    }
    return data;
  });
  const getSpecificCountryByCode = (countryCode: string) => {
    return countryCode ? self.getCountriesData.find(countries => countries.code.toUpperCase() == countryCode.toUpperCase()) : null;
  };

  const getSpecificCountryByName = (countryName: string) => {
    return countryName ? self.getCountriesData.find(countries => countries.name.toLowerCase() == countryName.toLowerCase()) : null;
  };

  const filteredCountries = (filterArray = COUNTRY_CODES): Array<CountriesType> => {
    return self.getCountriesData?.filter(countries => {
      return filterArray.some(filterCountries => {
        return filterCountries === countries.code;
      });
    }).sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
  };

  const getStatesOfCountry = (countryCode: string) => {
    return countryCode ? hardCodedStateData.filter(state => state.countryCode.toUpperCase() == countryCode.toUpperCase()) : [];
  };

  return {
    loadCountriesData,
    getSpecificCountryByCode,
    getSpecificCountryByName,
    filteredCountries,
    getStatesOfCountry,
  };
});

export function initCountriesStore() {
  return CountriesStore.create({
    countriesLoading: false,
    countriesData: [],
  });
}
