import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LoadingOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {faCircleExclamation, faCircleCheck, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {Spin} from 'antd';
import {observer} from 'mobx-react';
import FreezeWrapper from '../../../freezTooltip';
import {InputContainer, StyledLoader, StyledInput, StyledTextArea, ValueDiv, CheckIcon, CrossIcon} from '../styles';
import {SuggestionButton} from '../styles';

const StringField = observer(({field, handleSave, suggestion}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState<any>(field);
  const [isError, setIsError] = useState(false);
  const [iconDetails, setIconDetails] = useState<any>({
    color: '#F44343',
    icon: faCircleExclamation,
  });
  const [loader, setLoader] = useState(false);
  const [isUrl, setIsUrl] = useState(false);

  useEffect(() => {
    setIsUrl((field.fieldType == 'ATTRIBUTE' || field.fieldName == 'website_uri'));
  }, [field]);

  useEffect(() => {
    if (currentValue?.value) {
      if (currentValue?.value?.toString() == suggestion?.value?.toString() || !suggestion?.value?.toString()) {
        setIconDetails({
          color: '#2AC155',
          icon: faCircleCheck,
        });
      } else {
        setIconDetails({
          color: '#FF8536',
          icon: faTriangleExclamation,
        });
      }
    } else {
      setIconDetails({
        color: '#F44343',
        icon: faCircleExclamation,
      });
    }
  }, [currentValue?.value]);

  const handleSaveClick = async () => {
    setLoader(true);
    let value = currentValue?.value;
    if (field?.fieldType == 'ATTRIBUTE') {
      if (value?.includes('?')) {
        value = value?.slice(0, value?.indexOf('?'));
      }
      value = value ? field?.fieldName=='attributes/url_appointment' ? [value] : value : null;
    }
    if (field?.fieldName == 'website_uri' && !value?.includes('https://') && !value?.includes('http://') && value) {
      value = 'https://' + value;
    }
    const checking = await handleSave([{
      fieldName: field.fieldName,
      value: value,
      fieldType: field.fieldType,
    }]);
    setIsEditing(false);
    setLoader(false);
    !checking && setCurrentValue(prev => ({...prev, value: field?.value}));
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setCurrentValue(prev => ({...prev, value: field?.value}));
    setIsError(false);
  };

  const tooltipSuggestion = (
    <div>
      <div>You have a possible suggestion
        <div>{suggestion?.value}</div>
      </div>
      <div style={{marginTop: 5}}>
        <FreezeWrapper>
          <SuggestionButton onClick={() => {
            setIsEditing(true);
            setCurrentValue(prev => ({...prev, value: suggestion?.value}));
          }}>Accept OTTO Suggestion</SuggestionButton>
        </FreezeWrapper>
      </div>
    </div>
  );

  const openLink = () => {
    if (isUrl) {
      window.open(currentValue?.value, '_blank');
    }
  };

  return (
    <div style={{width: '100%'}}>
      {isEditing ? (
        <InputContainer className='input-actions'>
          {field.fieldName !== 'business_description' ? <StyledInput
            value={currentValue?.value}
            maxLength={field.fieldName == 'business_name' && 150}
            onChange={e => setCurrentValue(prev => ({...prev, value: e.target.value}))}
          /> : <StyledTextArea
            rows={4}
            autoSize={{minRows: 1, maxRows: 4}}
            value={currentValue?.value}
            onChange={e => {
              setCurrentValue(prev => ({...prev, value: e.target.value}));
              if (e.target.value.length > 750) {
                setIsError(true);
              } else setIsError(false);
            }}
          />}
          <div style={{display: 'flex'}}>
            <Tooltip title={!isError ? 'Save' : ''}>
              {loader ? <StyledLoader>
                <Spin indicator={<LoadingOutlined spin />} style={{color: 'white', fontSize: 10}} size='small' />
              </StyledLoader> : <CheckIcon color='white' bgColor={isError && field.fieldName == 'business_description' ? '#718cb5' : ''} onClick={() => !isError ? handleSaveClick() : {}} />}
            </Tooltip>
            <Tooltip title='Cancel'>
              <CrossIcon color='white' disabled={isError && field.fieldName == 'business_description'} onClick={handleCancelClick} />
            </Tooltip>
          </div>
        </InputContainer>
      ) : (
        <div style={{display: 'flex', width: '100%'}}>
          <Tooltip overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: (suggestion?.value && (suggestion?.value !== currentValue?.value)) && 391}} title={(suggestion?.value && (suggestion?.value !== currentValue?.value)) ? tooltipSuggestion : 'No possible suggestions'}>
            <FontAwesomeIcon
              style={{marginRight: 6}}
              color={iconDetails.color}
              icon={iconDetails.icon}
            />
          </Tooltip>
          {currentValue?.value ? (
            <>
              <Tooltip title={isUrl ? currentValue?.value : ''}>
                <ValueDiv isURL={isUrl}><span className={isUrl && 'click-link'} onClick={openLink}>{currentValue?.value}</span></ValueDiv>
              </Tooltip>
              <div className='edit-icon' style={{marginTop: -3}}>
                <FreezeWrapper>
                  <Tooltip title='Edit'>
                    <div onClick={() => setIsEditing(true)}>
                      <FontAwesomeIcon
                        style={{marginRight: 6, marginTop: 5}}
                        icon={faPen}
                        color='#2D6CCA'
                      />
                       Edit
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </>
          ) : (
            <FreezeWrapper>
              <div
                style={{color: '#2D6CCA', cursor: 'pointer', width: '90%'}}
                onClick={() => setIsEditing(true)}
              >
                {`Add ${field.label}`}
              </div>
              <div className='edit-icon' style={{marginTop: -3}}>
                <FreezeWrapper>
                  <Tooltip title='Edit'>
                    <div onClick={() => setIsEditing(true)}>
                      <FontAwesomeIcon
                        style={{marginRight: 6, marginTop: 5}}
                        icon={faPen}
                        color='#2D6CCA'
                      />
                       Edit
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </FreezeWrapper>
          )}
        </div>
      )}
      {isError && field.fieldName == 'business_description' && <p style={{margin: '0', color: 'red'}}>Business description limit exceeded (max: 750 characters)</p>}
    </div>
  );
});

export default StringField;
