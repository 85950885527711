import {BACKLINK_API} from '@/api/backlink-researcher';
import {BACKLINK_RESEARCHER_API_POLL_INTERVAL} from '@/constants';
import {apiError} from '@/utils/api';
import {formatSorters} from '@/utils/filters';
import {notification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import {toJS} from 'mobx';
import {types, flow, cast, Instance, getRoot} from 'mobx-state-tree';

const metrics = types.model({
  domainAuthority: types.maybeNull(types.number),
  initialProcessingStatus: types.maybeNull(types.string),
  linkingDomains: types.maybeNull(types.number),
  linkingPages: types.maybeNull(types.number),
  mode: types.maybeNull(types.string),
  pageAuthority: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});
const data = types.model({
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  metrics: types.maybeNull(metrics),
  searchedOn: types.maybeNull(types.string),
});
const gained = types.model({
  count: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const lost = types.model({
  count: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const historicalDomainAuthority = types.model({
  count: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const historicalLinkingDomains = types.model({
  count: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const historicalLinkingPages = types.model({
  count: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const historicalPageAuthority = types.model({
  count: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});
const historicalGainedAndLostLinkingDomains = types.model({
  gained: types.maybeNull(types.array(gained)),
  lost: types.maybeNull(types.array(lost)),
});
const historicalGainedAndLostLinkingPages = types.model({
  gained: types.maybeNull(types.array(gained)),
  lost: types.maybeNull(types.array(lost)),
});
const positionValue = types.model({
  position: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const urlMetrics = types.model({
  dofollowPages: types.maybeNull(types.number),
  dofollowPagesPct: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
  domainAuthorityDelta60Days: types.maybeNull(types.number),
  linkingDomains: types.maybeNull(types.number),
  linkingDomainsDelta60Days: types.maybeNull(types.number),
  linkingDomainsGained60Days: types.maybeNull(types.number),
  linkingDomainsLost60Days: types.maybeNull(types.number),
  linkingPages: types.maybeNull(types.number),
  linkingPagesDelta60Days: types.maybeNull(types.number),
  linkingPagesGained60Days: types.maybeNull(types.number),
  linkingPagesLost60Days: types.maybeNull(types.number),
  nofollowPagesPct: types.maybeNull(types.number),
  pageAuthority: types.maybeNull(types.number),
  pageAuthorityDelta60Days: types.maybeNull(types.number),
  historicalDomainAuthority: types.maybeNull(types.array(historicalDomainAuthority)),
  historicalLinkingDomains: types.maybeNull(types.array(historicalLinkingDomains)),
  historicalLinkingPages: types.maybeNull(types.array(historicalLinkingPages)),
  historicalPageAuthority: types.maybeNull(types.array(historicalPageAuthority)),
  historicalGainedAndLostLinkingDomains: types.maybeNull(historicalGainedAndLostLinkingDomains),
  historicalGainedAndLostLinkingPages: types.maybeNull(historicalGainedAndLostLinkingPages),
  linkingDomainAuthority: types.maybeNull(types.array(positionValue)),
  linkingDomainSpamScore: types.maybeNull(types.array(positionValue)),
  spamScore: types.maybeNull(types.number),
  urlRating: types.maybeNull(types.number),
});
const topPages = types.model({
  pageAuthority: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
});
const topLinkingDomains = types.model({
  domainAuthority: types.maybeNull(types.number),
  linkingDomain: types.maybeNull(types.string),
});
const topAnchorText = types.model({
  anchorText: types.maybeNull(types.string),
  linkingDomains: types.maybeNull(types.number),
});
const pagesTableData = types.model({
  anchorText: types.maybeNull(types.string),
  domainAuthority: types.maybeNull(types.number),
  isLost: types.maybeNull(types.boolean),
  isNofollow: types.maybeNull(types.boolean),
  isRedirect: types.maybeNull(types.boolean),
  page: types.maybeNull(types.string),
  pageAuthority: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
  targetPage: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});

const backlinkResearchData = types.model({
  domainRating: types.maybeNull(types.number),
  topPages: types.maybeNull(types.array(topPages)),
  screenshotUrl: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  initialProcessingStatus: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  urlMetrics: types.maybeNull(urlMetrics),
  topLinkingDomains: types.maybeNull(types.array(topLinkingDomains)),
  topAnchorText: types.maybeNull(types.array(topAnchorText)),
  topLinkingPages: types.maybeNull(types.array(pagesTableData)),
  mode: types.maybeNull(types.string),
});
const detailData = types.model({
  backlinkResearch: types.maybeNull(backlinkResearchData),
  searchedOn: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
});

const dateCount = types.model({
  count: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});

const linkingChartData = types.model({
  historicalLinkingDomains: types.maybeNull(types.array(dateCount)),
});

const linkingTableData = types.model({
  nofollowLinks: types.maybeNull(types.number),
  dofollowLinks: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
  linkingDomain: types.maybeNull(types.string),
  linkingDomainsCount: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
});
const linkingPagesChartData = types.model({
  historicalLinkingPages: types.maybeNull(types.array(dateCount)),
});

const linkingPagesTableData = types.model({
  anchorText: types.maybeNull(types.string),
  domainAuthority: types.maybeNull(types.number),
  isLost: types.maybeNull(types.boolean),
  isNofollow: types.maybeNull(types.boolean),
  isRedirect: types.maybeNull(types.boolean),
  page: types.maybeNull(types.string),
  pageAuthority: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
  targetPage: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});

const topPagesTableData = types.model({
  title: types.maybeNull(types.string),
  httpCode: types.maybeNull(types.number),
  linkingDomains: types.maybeNull(types.number),
  linkingPages: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  pageAuthority: types.maybeNull(types.number),
});


const anchorTextTableData = types.model({
  anchorText: types.maybeNull(types.string),
  linkingDomains: types.maybeNull(types.number),
  linkingPages: types.maybeNull(types.number),
});

const linkingDomainNewTableData = types.model({
  dateFound: types.maybeNull(types.string),
  nofollowLinks: types.maybeNull(types.number),
  dofollowLinks: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
  linkingDomain: types.maybeNull(types.string),
  linkingDomainsCount: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
});
const linkingDomainLostTableData = types.model({
  dateFound: types.maybeNull(types.string),
  dateLost: types.maybeNull(types.string),
  nofollowLinks: types.maybeNull(types.number),
  dofollowLinks: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
  linkingDomain: types.maybeNull(types.string),
  linkingDomainsCount: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
});


const linkingDomainLostChartData = types.model({
  historicalGainedAndLostLinkingDomains: types.maybeNull(historicalGainedAndLostLinkingPages),
});

const linkingDomainNew = types.model({
  chartData: types.maybeNull(linkingDomainLostChartData),
  results: types.maybeNull(types.array(linkingDomainNewTableData)),
});
const linkingDomainLost = types.model({
  chartData: types.maybeNull(linkingDomainLostChartData),
  results: types.maybeNull(types.array(linkingDomainLostTableData)),
});
const linkingPageLostTableData = types.model({
  dofollowLinks: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
  linkingDomain: types.maybeNull(types.string),
  linkingDomains_count: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
  anchorText: types.maybeNull(types.string),
  isLost: types.maybeNull(types.boolean),
  isNofollow: types.maybeNull(types.boolean),
  isRedirect: types.maybeNull(types.boolean),
  page: types.maybeNull(types.string),
  pageAuthority: types.maybeNull(types.number),
  targetPage: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});
const linkingDomainSpamScoreTableData = types.model({
  anchorText: types.maybeNull(types.string),
  domainAuthority: types.maybeNull(types.number),
  isLost: types.maybeNull(types.boolean),
  isNofollow: types.maybeNull(types.boolean),
  isRedirect: types.maybeNull(types.boolean),
  page: types.maybeNull(types.string),
  pageAuthority: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
  targetPage: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});


const linkingPageLostChartData = types.model({
  historicalGainedAndLostLinkingPages: types.maybeNull(historicalGainedAndLostLinkingPages),
});
const linkingSomainSpamScore = types.model({
  linkingDomainSpamScore: types.maybeNull(types.array(positionValue)),
});
const linkingPageNew = types.model({
  chartData: types.maybeNull(linkingPageLostChartData),
  results: types.maybeNull(types.array(linkingPageLostTableData)),
});
const linkingPageLost = types.model({
  chartData: types.maybeNull(linkingPageLostChartData),
  results: types.maybeNull(types.array(linkingPageLostTableData)),
});

const linkingDomainSpam = types.model({
  chartData: types.maybeNull(linkingSomainSpamScore),
  results: types.maybeNull(types.array(linkingDomainSpamScoreTableData)),

});
const chartssData = types.model({
});
const linkingDomainDrawerData = types.model({
  chartData: types.maybeNull(chartssData),
  results: types.maybeNull(types.array(linkingDomainSpamScoreTableData)),
});
const DomainAuthorityModel = types.model({
  date: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
});
const BacklinkProfileAnalysisDetailsModel = types.model({
  competitor: types.maybeNull(types.string),
  dofollowPages: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
  historicalDomainAuthority: types.maybeNull(types.array(DomainAuthorityModel)),
  historicalLinkingDomains: types.maybeNull(types.array(DomainAuthorityModel)),
  historicalLinkingPages: types.maybeNull(types.array(DomainAuthorityModel)),
  historicalPageAuthority: types.maybeNull(types.array(DomainAuthorityModel)),
  linkingDomains: types.maybeNull(types.number),
  linkingPages: types.maybeNull(types.number),
  nofollowPages: types.maybeNull(types.number),
  pageAuthority: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
});

const BacklinkGapResultsColumnsModel = types.model({
  columnName: types.maybeNull(types.string),
  isCompetitor: types.maybeNull(types.boolean),
});

const BacklinkGapAnalysisDetailsModel = types.model({
  columns: types.maybeNull(types.array(BacklinkGapResultsColumnsModel)),
  values: types.maybeNull(types.array(types.array(types.union(types.maybeNull(types.string), types.maybeNull(types.boolean), types.maybeNull(types.number))))),
  scope: types.maybeNull(types.string),
});
const BacklinkProfileCompetitorsModel = types.model({
  url: types.maybeNull(types.string),
  scope: types.maybeNull(types.string),
});
const BacklinkProfilePrimaryWebsitesModel = types.model({
  url: types.maybeNull(types.string),
  scope: types.maybeNull(types.string),
});
const BacklinkProfileAnalysisDataArrModel = types.model({
  competitorWebsites: types.maybeNull(types.array(BacklinkProfileCompetitorsModel)),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  primaryWebsite: types.maybeNull(BacklinkProfilePrimaryWebsitesModel),
  searchedOn: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
});


const BacklinkProfileAnalysisModel = types.model({
  results: types.maybeNull(types.array(BacklinkProfileAnalysisDataArrModel)),
  count: types.maybeNull(types.number),
});


const BacklinkGapAnalysisModelDataArrModel = types.model({
  competitorWebsites: types.maybeNull(types.array(BacklinkProfileCompetitorsModel)),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  primaryWebsite: types.maybeNull(BacklinkProfilePrimaryWebsitesModel),
  searchedOn: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  scope: types.maybeNull(types.string),
  minMatchingTargets: types.maybeNull(types.number),
});

const BacklinkGapAnalysisModel = types.model({
  results: types.maybeNull(types.array(BacklinkGapAnalysisModelDataArrModel)),
  count: types.maybeNull(types.number),
});

export type BacklinkProfileAnalysisType = Instance<typeof BacklinkProfileAnalysisDataArrModel>;
export type BacklinkGapAnalysisType = Instance<typeof BacklinkGapAnalysisModelDataArrModel>;
export type BacklinkProfileAnalysisDetailsType = Instance<typeof BacklinkProfileAnalysisDetailsModel>;

const ActiveCompetitorsModel = types.model({
  competitor: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  color: types.maybeNull(types.string),
});

type ActiveCompetitorsType = Instance<typeof ActiveCompetitorsModel>;

export const backlinkResearcherStore = types.model({
  data: types.maybeNull(types.array(data)),
  linkingDomainDrawerData: types.maybeNull(linkingDomainDrawerData),
  linkingLostDomainDrawerData: types.maybeNull(linkingDomainDrawerData),
  detailListApiCallCheck: types.boolean,
  detailData: types.maybeNull(detailData),
  activeBacklinkTab: types.maybeNull(types.string),
  linkingTableData: types.maybeNull(types.array(linkingTableData)),
  linkingChartData: types.maybeNull(linkingChartData),
  linkingPagesTableData: types.maybeNull(types.array(linkingPagesTableData)),
  linkingPagesChartData: types.maybeNull(linkingPagesChartData),
  linkingDomainTableNew: types.maybeNull(types.array(linkingDomainNewTableData)),
  linkingDomainChartNew: types.maybeNull(linkingDomainLostChartData),
  linkingDomainTableLost: types.maybeNull(types.array(linkingDomainLostTableData)),
  linkingPageTableNew: types.maybeNull(types.array(linkingPageLostTableData)),
  linkingPageTableLost: types.maybeNull(types.array(linkingPageLostTableData)),

  linkingDomainChartLost: types.maybeNull(linkingDomainLostChartData),
  linkingPagesChartLost: types.maybeNull(linkingPageLostChartData),
  linkingPagesChartNew: types.maybeNull(linkingPageLostChartData),
  linkingDomainSpamTable: types.maybeNull(types.array(linkingDomainSpamScoreTableData)),
  linkingDomainSpamChart: types.maybeNull(linkingSomainSpamScore),
  loading: types.maybeNull(types.boolean),
  topPagesTableData: types.maybeNull(types.array(topPagesTableData)),
  anchorTextTableData: types.maybeNull(types.array(anchorTextTableData)),
  linkingDomainNew: types.maybeNull(linkingDomainNew),
  linkingDomainLost: types.maybeNull(linkingDomainLost),
  linkingPageNew: types.maybeNull(linkingPageNew),
  linkingPageLost: types.maybeNull(linkingPageLost),
  linkingDomainSpam: types.maybeNull(linkingDomainSpam),
  isDrawerOpen: types.maybeNull(types.boolean),
  detailApiCallCheck: types.optional(types.boolean, false),
  tableLoading: types.maybeNull(types.boolean),
  mainTableLoading: types.maybeNull(types.boolean),
  newAndLostDrawerOpen: types.maybeNull(types.boolean),
  lostDrawerOpen: types.maybeNull(types.boolean),
  topPagesDrawerData: types.maybeNull(linkingDomainDrawerData),
  linkingNewDomainDrawerData: types.maybeNull(linkingDomainDrawerData),
  anchorTextDrawerData: types.maybeNull(linkingDomainDrawerData),
  anchorTextDrawerOpen: types.maybeNull(types.boolean),
  topPagesDrawerOpen: types.maybeNull(types.boolean),
  drawerLoading: types.maybeNull(types.boolean),
  drawerUrl: types.maybeNull(types.string),
  linkingDomainNewPageNumber: types.maybeNull(types.number),
  linkingDomainLostPageNumber: types.maybeNull(types.number),
  hideLoadingButton: types.maybeNull(types.boolean),
  linkingDomainTabKey: types.maybeNull(types.string),
  linkingPagesTabKey: types.maybeNull(types.string),
  profileAnalysisLoading: types.maybeNull(types.boolean),
  backlinkProfileAnalysisInitLoading: types.maybeNull(types.boolean),
  profileDetailsLoading: types.maybeNull(types.boolean),
  profileDetailsInitLoading: types.maybeNull(types.boolean),
  profileDetailsChartActiveCompetitors: types.maybeNull(types.array(ActiveCompetitorsModel)),
  backlinkProfileAnalysis: types.maybeNull(BacklinkProfileAnalysisModel),
  backlinkProfileAnalysisDetails: types.maybeNull(types.array(BacklinkProfileAnalysisDetailsModel)),
  blProfileAnalysisHistoryPage: types.maybeNull(types.number),
  blProfileAnalysisHistoryPageSize: types.maybeNull(types.number),
  backlinkProfileSortKey: types.maybeNull(types.string),
  backlinkProfileSortDirection: types.maybeNull(types.string),
  backlinkProfileSearchQuery: types.maybeNull(types.string),
  gapAnalysisLoading: types.maybeNull(types.boolean),
  analyzeLinksLoading: types.maybeNull(types.boolean),
  gapAnalysisHistoryPage: types.maybeNull(types.number),
  gapAnalysisHistoryPageSize: types.maybeNull(types.number),
  gapDetailsLoading: types.maybeNull(types.boolean),
  gapAnalysisInitLoading: types.maybeNull(types.boolean),
  backlinkGapAnalysis: types.maybeNull(BacklinkGapAnalysisModel),
  backlinkGapAnalysisDetails: types.maybeNull(BacklinkGapAnalysisDetailsModel),
  backlinkGapAnalysisDetailsType: types.maybeNull(types.string),
  backlinkGapSortKey: types.maybeNull(types.string),
  backlinkGapSortDirection: types.maybeNull(types.string),
  backlinkGapSearchQuery: types.maybeNull(types.string),
  showExportModal: types.maybeNull(types.boolean),
  linkingDomaisRowsCount: types.maybeNull(types.number),
  linkingPagesRowsCount: types.maybeNull(types.number),
  linkingDomainTableNewCount: types.maybeNull(types.number),
  linkingDomainTableLostCount: types.maybeNull(types.number),
  spamLinksCount: types.maybeNull(types.number),
  anchorTextRowsCount: types.maybeNull(types.number),
  topPagesCount: types.maybeNull(types.number),
  backlinkGapPage: types.maybeNull(types.number),
  backlinkGapDetailsnitLoading: types.maybeNull(types.boolean),
  showBacklinkGapExportModal: types.maybeNull(types.boolean),
  backlinkGapDetailsExporting: types.maybeNull(types.boolean),
  maxLookupReached: types.maybeNull(types.boolean),
  linkingPageTableLostCount: types.maybeNull(types.number),
  linkingPageTableNewCount: types.maybeNull(types.number),
  backlinkPublicShareHash: types.maybeNull(types.string),
  recrawling: types.maybeNull(types.boolean),
}).views(self => ({

  get pageDetail() {
    return self.detailData;
  },
  get isLoading() {
    return self.loading;
  },

  get getInitialCompetitors() {
    return self.backlinkProfileAnalysisDetails?.map(item => item.competitor);
  },

  get getLinkGapCompetitors() {
    const finalArr = [];
    if (self.backlinkGapAnalysisDetails?.columns?.length) {
      self.backlinkGapAnalysisDetails?.columns.forEach(item => {
        if (item.isCompetitor) finalArr.push(item.columnName);
      });
    }
    return finalArr;
  },

})).actions(self => {
  const setBacklinkPublicShareHash = (value:string) =>{
    self.backlinkPublicShareHash=value;
  };
  const setShowExportModal = (value: boolean) => {
    self.showExportModal = value;
  };
  const setTopPagesCount = (value: number) => {
    self.topPagesCount = value;
  };
  const setSpamLinksCount = (value: number) => {
    self.spamLinksCount = value;
  };
  const setLinkingDomainTableLostCount = (value: number) => {
    self.linkingDomainTableLostCount = value;
  };
  const setLinkingDomainTableNewCount = (value: number) => {
    self.linkingDomainTableNewCount = value;
  };

  const setLinkingPageTableNewCount = (value: number) => {
    self.linkingPageTableNewCount = value;
  };
  const setLinkingPageTableLostCount = (value: number) => {
    self.linkingPageTableLostCount = value;
  };

  const setAnchorTextRowsCount = (value: number) => {
    self.anchorTextRowsCount = value;
  };
  const setLinkingDomaisRowsCount = (value: number) => {
    self.linkingDomaisRowsCount = value;
  };
  const setLinkingPagesRowsCount = (value: number) => {
    self.linkingPagesRowsCount = value;
  };
  const setLinkingPagesTabKey = (value: string) => {
    self.linkingPagesTabKey = value;
  };
  const setLinkingDomainTabKey = (value: string) => {
    self.linkingDomainTabKey = value;
  };
  const setDetailApiCallCheck = value => {
    self.detailListApiCallCheck = value;
  };
  const setLinkingDomainNewPageNumber = value => {
    self.linkingDomainNewPageNumber = value;
  };
  const setLinkingDomainLostPageNumber = value => {
    self.linkingDomainLostPageNumber = value;
  };
  const setDomainDrawerUrl = value => {
    self.drawerUrl = value;
  };
  const setActiveBacklinkTab = (key: string) => {
    self.activeBacklinkTab = key;
  };
  const setLostDrawerOpen = (key: boolean) => {
    self.lostDrawerOpen = key;
  };
  const setAnchorTextDrawerOpen = (key: boolean) => {
    self.anchorTextDrawerOpen = key;
  };
  const setTopPagesDrawerOpen = (key: boolean) => {
    self.topPagesDrawerOpen = key;
  };

  const setIsDrawerOpen = (key: boolean) => {
    self.isDrawerOpen = key;
  };
  const setNewAndLostDrawerOpen = (key: boolean) => {
    self.newAndLostDrawerOpen = key;
  };
  const setDetailApiCall = value => {
    self.detailApiCallCheck = value;
  };
  const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && o1 !== null && Object?.keys(o1)?.length > 0 ?
      Object?.keys(o1)?.length === Object?.keys(o2)?.length &&
      Object?.keys(o1)?.every(p => objectsEqual(o1[p], o2[p])) :
      o1 === o2;

  const arraysEqual = (a1, a2) =>
    a1?.length === a2?.length && a1?.every((o, idx) => objectsEqual(o, a2[idx]));
  const getBacklinks = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.mainTableLoading = true;
    } try {
      const response = yield BACKLINK_API.getBacklinkKeywordOverview();
      if (!response?.isCancel) {
        const check = arraysEqual(response, toJS(self.data));
        if (!check) {
          self.data = response;
        }
        const metricsStatus = response?.filter(item => !['FAILURE', 'SUCCESS'].includes(item?.metrics?.initialProcessingStatus));
        if (metricsStatus?.length && self.detailListApiCallCheck) {
          yield new Promise(r => setTimeout(r, BACKLINK_RESEARCHER_API_POLL_INTERVAL));
          return getBacklinks(true);
        } else {
          self.data = response;
          self.mainTableLoading = false;
        }
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.mainTableLoading = false;
    }
  });

  const getDrawerData = flow(function* (id, page, drawer, url) {
    const publicHash = getSingleUrlParam('public_hash');
    self.drawerLoading = true;
    try {
      const response = yield BACKLINK_API.getDrawerData(id, page, drawer, url, publicHash);
      if (page == 'linking-domain-pages') {
        self.linkingDomainDrawerData = response;
      } else if (page == 'target-page-pages') {
        self.topPagesDrawerData = response;
      } else if (page == 'anchor-text-pages') {
        self.anchorTextDrawerData = response;
      }
    } catch (e) {
      self.drawerLoading = false;
      throw e;
    } finally {
      self.drawerLoading = false;
    }
  });


  const getLostDomainDrawerData = flow(function* (id, page, drawer, url, type, publicHash) {
    self.drawerLoading = true;
    try {
      const response = yield BACKLINK_API.getLostDomainDrawerData(id, page, drawer, url, type, publicHash);
      if (type == 'new') {
        self.linkingNewDomainDrawerData = response;
      } else if (type == 'lost') {
        self.linkingLostDomainDrawerData = response;
      }
      return response;
    } catch (e) {
      self.drawerLoading = false;
      throw e;
    } finally {
      self.drawerLoading = false;
    }
  });

  const deleteBacklinkDomain = flow(function* (id) {
    try {
      const response = yield BACKLINK_API.deleteBacklinkDetail(id);
      notification.success('Deleted Successfully', 'Backlink Deleted Successfully');
      return response;
    } catch (e) {
      notification?.error('Delete Processing FAILED', `${apiError(e)}`);
      return {};
    }
  });

  const getBacklinksDetail = flow(function* (id) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loading = true;
    try {
      const response = yield BACKLINK_API.getBacklinkDetail(id, publicHash);
      const metricsStatus = response?.backlinkResearch?.initialProcessingStatus == 'PENDING';
      if (metricsStatus) {
        yield new Promise(r => setTimeout(r, BACKLINK_RESEARCHER_API_POLL_INTERVAL));
        return getBacklinksDetail(id);
      } else {
        if (response?.backlinkResearch?.initialProcessingStatus === 'FAILURE') {
          notification?.error('Processing FAILED', `Fail to Process ${response?.competitorResearch?.mode} - ${response?.competitorResearch?.url}`);
        }
        self.detailData = response;
        self.loading = false;
        self.recrawling = false;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const postNewBacklink = flow(function* (payload) {
    try {
      const response = yield BACKLINK_API.findKeywordList(payload);
      if (response?.id) {
        notification.success('Success', 'Backlink analyze successfully');
        getBacklinksDetail(response?.id);
      }
      return response;
    } catch (e) {
      const rootStore = getRoot(self) as any;
      // domain analyzer
      if (e?.response?.status === 429 && rootStore?.settings?.customer?.profile?.isWhitelabel ) {
        notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        return false;
      } else if (e?.response?.status === 429 && !rootStore?.settings?.customer?.profile?.isWhitelabel ) {
        notification.warning('Competitor Researcher Quota Exceeded', e?.response?.data?.message, 'Update Plan', rootStore?.plans?.showSidebarPaymentDrawer);
        return false;
      } else if (e?.response?.status == 500) {
        notification?.error('Error', 'Server Error 500.');
        return false;
      } else {
        notification?.error('Processing FAILED', `${apiError(e)}`);
        return {};
      }
    }
  });

  const postNewBacklinkPublic = flow(function* (payload) {
    try {
      const response = yield BACKLINK_API.findKeywordList(payload);
      if (response) {
        self.maxLookupReached = false;
        notification.success('Success', 'Backlink analyze successfully');
        return response;
      }
    } catch (e) {
      if (e?.response?.status === 429) {
        notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        return false;
      }
      if (e.response.status === 400) {
        notification?.error('Error Submitting Backlink research', e.response.data.nonFieldErrors?.length ? e.response.data.nonFieldErrors[0] : '');
        return false;
      }
      if (e.response.status === 406) {
        // notification.error('Maximum number of lookups reached.', 'You cannot make more than 2 lookups as unregistered user.');
        self.maxLookupReached = true;
        return e;
      }
      return Promise.reject(e);
    }
  });

  const backlinkRecrawl = flow(function* (id) {
    const publicHash = getSingleUrlParam('public_hash');
    self.recrawling = true;
    try {
      const response = yield BACKLINK_API.recrawlBacklinkDetail(id, publicHash);
      if (response) {
        return getBacklinksDetail(id);
      }
      return response;
    } catch (e) {
      return {};
    }
  });

  const getBacklinksTabData = flow(function* (id, context, page) {
    const publicHash = getSingleUrlParam('public_hash');
    try {
      if (page == 1) {
        self.tableLoading = true;
      }
      const response = yield BACKLINK_API.getBacklinkTabDetail(id, context, page, publicHash);
      if (response) {
        self.hideLoadingButton = false;
        if (context == 'linking_domains') {
          if (response?.results?.length > 0) {
            self.linkingTableData = cast((page === 1) ? response?.results : [...toJS(self.linkingTableData), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          self.linkingChartData = response?.chartData;
          return response;
        } else if (context == 'linking_pages') {
          if (response?.results?.length > 0) {
            self.linkingPagesTableData = cast((page === 1) ? response?.results : [...toJS(self.linkingPagesTableData), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          self.linkingPagesChartData = response?.chartData;
          return response;
        } else if (context == 'top_pages') {
          if (response?.results?.length > 0) {
            self.topPagesTableData = cast((page === 1) ? response?.results : [...toJS(self.topPagesTableData), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          return response;
        } else if (context == 'anchor_text') {
          if (response?.results?.length > 0) {
            self.anchorTextTableData = cast((page === 1) ? response?.results : [...toJS(self.anchorTextTableData), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          return response;
        } else if (context == 'linking_domains_new') {
          if (response?.results?.length > 0) {
            self.linkingDomainTableNew = cast((page === 1) ? response?.results : [...toJS(self.linkingDomainTableNew), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          self.linkingDomainChartNew = response?.chartData;
          return response;
        } else if (context == 'linking_domains_lost') {
          if (response?.results?.length > 0) {
            self.linkingDomainTableLost = cast((page === 1) ? response?.results : [...toJS(self.linkingDomainTableLost), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          self.linkingDomainChartLost = response?.chartData;
          return response;
        } else if (context == 'linking_pages_new') {
          self.linkingPagesChartNew = response?.chartData;
          if (response?.results?.length > 0) {
            self.linkingPageTableNew = (page === 1) ? response.results : [...toJS(self.linkingPageTableNew), ...response.results];
          } else {
            self.hideLoadingButton = true;
          }
          return response;
        } else if (context == 'linking_pages_lost') {
          self.linkingPagesChartLost = response?.chartData;
          if (response?.results?.length > 0) {
            self.linkingPageTableLost = cast((page === 1) ? response?.results : [...toJS(self.linkingPageTableLost), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          return response;
        } else if (context == 'linking_domains_spam') {
          if (response?.results?.length > 0) {
            self.linkingDomainSpamTable = cast((page === 1) ? response?.results : [...toJS(self.linkingDomainSpamTable), ...response.results]);
          } else {
            self.hideLoadingButton = true;
          }
          self.linkingDomainSpamChart = response?.chartData;
          return response;
        }
      } else {
        self.hideLoadingButton = true;
      }
    } catch (e) {
      if (e?.response?.status === 429) {
        notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
        return false;
      }
      self.tableLoading = false;
      throw e;
    } finally {
      self.tableLoading = false;
    }
  });

  interface BacklinkProfileAnalysisHistoryPayload {
    page?: number;
    pageSize?: number;
  }

  const getBlProfileAnalysisHistory = flow(function* ({page, pageSize}: BacklinkProfileAnalysisHistoryPayload) {
    const sorters = {
      primaryWebsite: 'primary_website',
      searchedOn: 'searched_on',
    };

    self.profileAnalysisLoading = true;
    try {
      const response = yield BACKLINK_API.getBacklinkProfileAnalysisHistory(
        {
          page,
          pageSize,
          ...((self.backlinkProfileSortKey && self.backlinkProfileSortDirection) && {sortBy: formatSorters(sorters, self.backlinkProfileSortKey, self.backlinkProfileSortDirection)}),
          ...(self.backlinkProfileSearchQuery && {search: self.backlinkProfileSearchQuery}),
        });
      if (response.isCancel) return;
      self.backlinkProfileAnalysis = cast(response);
    } catch (e) {
      self.profileAnalysisLoading = false;
      return Promise.reject(e);
    } finally {
      self.profileAnalysisLoading = false;
      self.backlinkProfileAnalysisInitLoading = false;
    }
  });

  const getBlProfileAnalysis = flow(function* (payload) {
    const sorters = {
      primaryWebsite: 'primary_website',
      searchedOn: 'searched_on',
    };

    self.analyzeLinksLoading = true;
    try {
      const data = yield BACKLINK_API.getBacklinkProfileAnalysis(payload);
      if (data.isCancel) return;

      const response = yield BACKLINK_API.getBacklinkProfileAnalysisHistory({
        page: self.blProfileAnalysisHistoryPage,
        pageSize: self.blProfileAnalysisHistoryPageSize,
        ...((self.backlinkProfileSortKey && self.backlinkProfileSortDirection) && {sortBy: formatSorters(sorters, self.backlinkProfileSortKey, self.backlinkProfileSortDirection)}),
        ...(self.backlinkProfileSearchQuery && {search: self.backlinkProfileSearchQuery}),
      });
      self.backlinkProfileAnalysis = cast(response);
      notification.success('Success', 'Backlink analyzed successfully');
      return response;
    } catch (e) {
      self.analyzeLinksLoading = false;
      return Promise.reject(e);
    } finally {
      setLinkingPagesRowsCount;
      self.analyzeLinksLoading = false;
    }
  });
  const getUpdatedBacklinksProfile = flow(function* (id, payload) {
    self.profileAnalysisLoading = true;
    try {
      const data = yield BACKLINK_API.getUpdatedBacklinksProfile(id, payload);
      if (data.isCancel) return;
      notification.success('Success', 'Backlinks Comparison Updated Successfully');
      return data;
    } catch (e) {
      notification.error('Error', 'Backlinks Comparison Update Failed');
      return Promise.reject(e);
    } finally {
      self.profileAnalysisLoading = false;
    }
  });
  const getUpdatedGapAnalysis = flow(function* (id, payload) {
    self.profileAnalysisLoading = true;
    try {
      const data = yield BACKLINK_API.getUpdatedGapAnalysis(id, payload);
      if (data.isCancel) return;
      return data;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.profileAnalysisLoading = false;
    }
  });

  const getBlProfileAnalysisDetails = flow(function* (id: number | string, publicHash?:string) {
    self.profileDetailsLoading = true;
    try {
      const response = yield BACKLINK_API.getBacklinkProfileAnalysisDetails(id, publicHash);
      if (response.isCancel) return;

      if (response) {
        self.backlinkProfileAnalysisDetails = cast(response);
        const competitorsColors = ['#2D6CCA', '#34AEF3', '#78EFFF', '#007989', '#4EBBAB', '#E7FFA4'];
        const formatArr = response?.map(item => item.competitor);
        const finalArr = formatArr?.map((competitor, idx) => {
          return {
            competitor: competitor,
            isActive: true,
            color: competitorsColors[idx],
          };
        });

        self.profileDetailsChartActiveCompetitors = cast(finalArr);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.profileDetailsLoading = false;
      self.profileDetailsInitLoading = false;
    }
  });

  const setProfileAnalysisLoading = (value: boolean) => self.profileAnalysisLoading = value;
  const setProfileAnalysisInitLoading = (value: boolean) => self.backlinkProfileAnalysisInitLoading = value;
  const setProfileDetailsLoading = (value: boolean) => self.profileDetailsLoading = value;
  const setProfileDetailsHistoryPage = (value: number) => self.blProfileAnalysisHistoryPage = value;
  const setProfileDetailsHistorySize = (value: number) => self.blProfileAnalysisHistoryPageSize = value;
  const setBacklinkProfileSearchQuery = (value: string) => self.backlinkProfileSearchQuery = value;
  const setBacklinkProfileSortKey = (value: string) => self.backlinkProfileSortKey = value;
  const setBacklinkProfileSortDirection = (value: string) => self.backlinkProfileSortDirection = value;
  const setProfileDetailsInitLoading = (value: boolean) => self.profileDetailsInitLoading = value;
  const setProfileDetailsActiveCompetitors = (value: ActiveCompetitorsType[]) => self.profileDetailsChartActiveCompetitors = cast(value);


  interface GapAnalysisHistoryPayload {
    page?: number;
    pageSize?: number;
  }


  const getBlGapAnalysis = flow(function* (payload) {
    const sorters = {
      minMatchingTargets: 'min_matching_targets',
      primaryWebsite: 'primary_website',
      searchedOn: 'searched_on',
    };


    self.analyzeLinksLoading = true;
    try {
      const data = yield BACKLINK_API.getBacklinkGapAnalysis(payload);
      if (data.isCancel) return;

      const response = yield BACKLINK_API.getBacklinkGapAnalysisHistory(
        {
          page: self.gapAnalysisHistoryPage,
          pageSize: self.gapAnalysisHistoryPageSize,
          ...((self.backlinkGapSortKey && self.backlinkGapSortDirection) && {sortBy: formatSorters(sorters, self.backlinkGapSortKey, self.backlinkGapSortDirection)}),
          ...(self.backlinkGapSearchQuery && {search: self.backlinkGapSearchQuery}),
        });
      notification.success('Success', 'Successfully analyzed links');
      self.backlinkGapAnalysis = cast(response);
    } catch (e) {
      self.analyzeLinksLoading = false;
      return Promise.reject(e);
    } finally {
      self.analyzeLinksLoading = false;
      self.gapDetailsLoading = false;
    }
  });

  const getBlGapAnalysisHistory = flow(function* ({page, pageSize}: GapAnalysisHistoryPayload) {
    const sorters = {
      minMatchingTargets: 'min_matching_targets',
      primaryWebsite: 'primary_website',
      searchedOn: 'searched_on',
    };


    self.gapAnalysisLoading = true;
    try {
      const response = yield BACKLINK_API.getBacklinkGapAnalysisHistory({
        page,
        pageSize,
        ...((self.backlinkGapSortKey && self.backlinkGapSortDirection) && {sortBy: formatSorters(sorters, self.backlinkGapSortKey, self.backlinkGapSortDirection)}),
        ...(self.backlinkGapSearchQuery && {search: self.backlinkGapSearchQuery}),
      });
      if (response.isCancel) return;
      self.backlinkGapAnalysis = cast(response);
    } catch (e) {
      self.gapAnalysisLoading = false;
      self.gapDetailsLoading = false;
      self.gapAnalysisInitLoading = false;
      return Promise.reject(e);
    } finally {
      self.gapAnalysisLoading = false;
      self.gapDetailsLoading = false;
      self.gapAnalysisInitLoading = false;
    }
  });


  const getBlGapAnalysisDetails = flow(function* (id: number | string) {
    self.gapDetailsLoading = true;
    self.gapAnalysisLoading = true;
    try {
      const response = yield BACKLINK_API.getBacklinkGapAnalysisDetails(id, self.backlinkGapPage);
      if (response.isCancel) return;

      if (self.backlinkGapPage === 1) {
        self.backlinkGapAnalysisDetails = cast(response);
      } else {
        if (response?.columns?.length) {
          self.backlinkGapAnalysisDetails.columns = cast(response.columns);
        }

        if (response?.values?.length) {
          self.backlinkGapAnalysisDetails.values = cast([
            ...self.backlinkGapAnalysisDetails.values,
            ...response.values,
          ]);
        }
      }

      self.backlinkGapDetailsnitLoading = false;

      return response;
    } catch (e) {
      self.gapDetailsLoading = false;
      self.gapAnalysisLoading = false;
      return Promise.reject(e);
    }
  });
  const deleteGapAnalysisKeyword = flow(function* (id) {
    try {
      const response = yield BACKLINK_API.deleteGapAnalysisKeyword(id);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const deleteProfileGapAnalysis = flow(function* (id) {
    try {
      const response = yield BACKLINK_API.deleteProfileGapAnalysis(id);
      notification.success('Deleted Successfully', 'Website deleted successfully.');
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });


  const setGapAnalysisLoading = (value: boolean) => self.gapAnalysisLoading = value;
  const setGapDetailsLoading = (value: boolean) => self.gapDetailsLoading = value;
  const setBacklinkGapAnalysisDetailsType = (value: string) => self.backlinkGapAnalysisDetailsType = value;
  const setGapAnalysisInitLoading = (value: boolean) => self.gapAnalysisInitLoading = value;
  const setGapDetailsHistoryPage = (value: number) => self.gapAnalysisHistoryPage = value;
  const setGapDetailsHistorySize = (value: number) => self.gapAnalysisHistoryPageSize = value;
  const setBacklinkGapSearchQuery = (value: string) => self.backlinkGapSearchQuery = value;
  const setBacklinkGapSortKey = (value: string) => self.backlinkGapSortKey = value;
  const setBacklinkGapSortDirection = (value: string) => self.backlinkGapSortDirection = value;
  const setBacklinkPageNumber = (value: number) => self.backlinkGapPage = value;
  const clearBacklinkGapDetailsData = () => self.backlinkGapAnalysisDetails = null;
  const setBacklinkGapDetailsInitLoading = (value: boolean) => self.backlinkGapDetailsnitLoading = value;
  const setShowBacklinkGapExportModal = (value: boolean) => self.showBacklinkGapExportModal = value;
  const setBacklinkGapDetailsExporting = (value: boolean) => self.backlinkGapDetailsExporting = value;
  const setIsLoading = (value: boolean) => self.loading = value;

  return {
    setTopPagesCount,
    deleteProfileGapAnalysis,
    setSpamLinksCount,
    setLinkingDomainTableLostCount,
    setAnchorTextRowsCount,
    getUpdatedBacklinksProfile,
    getUpdatedGapAnalysis,
    setLinkingPagesRowsCount,
    setLinkingDomaisRowsCount,
    setLinkingDomainTableNewCount,
    setLinkingPageTableNewCount,
    setLinkingPageTableLostCount,
    setShowExportModal,
    setDomainDrawerUrl,
    setDetailApiCallCheck,
    deleteGapAnalysisKeyword,
    getBacklinksTabData,
    getBacklinks,
    postNewBacklink,
    getBacklinksDetail,
    postNewBacklinkPublic,
    setActiveBacklinkTab,
    setDetailApiCall,
    setIsDrawerOpen,
    setNewAndLostDrawerOpen,
    setAnchorTextDrawerOpen,
    setTopPagesDrawerOpen,
    getDrawerData,
    getLostDomainDrawerData,
    setLinkingPagesTabKey,
    setLostDrawerOpen,
    deleteBacklinkDomain,
    backlinkRecrawl,
    setLinkingDomainLostPageNumber,
    setLinkingDomainNewPageNumber,
    setLinkingDomainTabKey,
    getBlProfileAnalysis,
    setProfileAnalysisLoading,
    setProfileDetailsLoading,
    getBlProfileAnalysisHistory,
    getBlProfileAnalysisDetails,
    getBlGapAnalysis,
    setGapAnalysisLoading,
    setGapDetailsLoading,
    getBlGapAnalysisHistory,
    getBlGapAnalysisDetails,
    setBacklinkGapAnalysisDetailsType,
    setProfileDetailsHistoryPage,
    setProfileDetailsHistorySize,
    setProfileAnalysisInitLoading,
    setGapAnalysisInitLoading,
    setGapDetailsHistoryPage,
    setGapDetailsHistorySize,
    setBacklinkProfileSearchQuery,
    setBacklinkProfileSortKey,
    setBacklinkProfileSortDirection,
    setBacklinkGapSearchQuery,
    setBacklinkGapSortKey,
    setBacklinkGapSortDirection,
    setBacklinkPageNumber,
    setBacklinkGapDetailsInitLoading,
    clearBacklinkGapDetailsData,
    setShowBacklinkGapExportModal,
    setBacklinkGapDetailsExporting,
    setProfileDetailsInitLoading,
    setProfileDetailsActiveCompetitors,
    setBacklinkPublicShareHash,
    setIsLoading,
  };
});

export function initBacklinkResearcher() {
  return backlinkResearcherStore.create({
    loading: true,
    detailListApiCallCheck: false,
    linkingDomainNewPageNumber: 1,
    linkingDomainLostPageNumber: 1,
    hideLoadingButton: false,
    linkingDomainTabKey: '1',
    activeBacklinkTab: 'overview',
    profileAnalysisLoading: false,
    profileDetailsLoading: false,
    profileDetailsInitLoading: false,
    backlinkProfileAnalysisInitLoading: true,
    gapAnalysisLoading: false,
    analyzeLinksLoading: false,
    gapDetailsLoading: false,
    blProfileAnalysisHistoryPage: 1,
    blProfileAnalysisHistoryPageSize: 10,
    gapAnalysisInitLoading: false,
    gapAnalysisHistoryPage: 1,
    showExportModal: false,
    gapAnalysisHistoryPageSize: 10,
    backlinkGapPage: 1,
    showBacklinkGapExportModal: false,
    backlinkGapDetailsExporting: false,
    backlinkProfileAnalysisDetails: [],
    maxLookupReached: false,
    backlinkPublicShareHash: '',
    recrawling: false,
  });
}
