import React, {useEffect, useState} from 'react';
import {Modal, SkeletonHorizontalLoaderGray} from '@/components/common-components/components';
import styled from 'styled-components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {Dropdown, Empty, Input, Menu, Popover, Radio, Rate, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import {faMessageCheck, faMessageText, faPause} from '@fortawesome/pro-duotone-svg-icons';
import {faCaretDown, faSortDown} from '@fortawesome/pro-solid-svg-icons';
import {languages} from '../../content-ideas/drawer/languages';
import {useStore} from '@/store/root-store';
import {convertKeysToSnakeCase, truncate} from '@/utils/string';
import {notification} from '@/utils/notifications';
import styles from '../Component/style.module.scss';
import {useRouter} from 'next/router';
import {getStandAloneLocations} from '@/utils/functions';
import {LocationDetailModal} from '@/store/otto-v2-store';
import {Instance} from 'mobx-state-tree';

interface AutoRespondModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  standaloneTool?: boolean;
  gbpLocationDetail?: Instance<typeof LocationDetailModal>;
  isV2?: boolean;
  currentBussiness?: any;
}
const languageOptions = languages;
const toneOfVoiceOptions = [
  {
    key: 'professional',
    label: 'Professional',
  },
  {
    key: 'casual',
    label: 'Casual',
  },
  {
    key: 'funny',
    label: 'Funny',
  },
  {
    key: 'angry',
    label: 'Angry',
  },
  {
    key: 'baby-yoda',
    label: 'Baby Yoda',
  },
];
const lengthOptions = [
  {
    key: 'Short',
    label: 'Short',
  },
  {
    key: 'Medium',
    label: 'Medium',
  },
  {
    key: 'Long',
    label: 'Long',
  },
];
const iconMapping = {
  'AUTO_REPLY': faMessageCheck,
  'APPROVE_FOR_REPLY': '',
  'STATIC_TEMPLATE': faMessageText,
  'DO_NOTHING': faPause,
};
const reviewOptions = [
  {
    key: 'AUTO_REPLY',
    label: 'Auto Reply with AI',
  },
  {
    key: 'APPROVE_FOR_REPLY',
    label: 'Auto Reply with AI (approve before posting)',
  },
  {
    key: 'STATIC_TEMPLATE',
    label: 'Use a Static Template',
  },
  {
    key: 'DO_NOTHING',
    label: 'Do nothing',
  },
];
const hideViewExampleButton = ['STATIC_TEMPLATE', 'DO_NOTHING'];
const initialState = [
  {
    star_rating: 5,
    mode: 'AUTO_REPLY',
    prompt: '',
  },
  {
    star_rating: 4,
    mode: 'AUTO_REPLY',
    prompt: '',
  },
  {
    star_rating: 3,
    mode: 'AUTO_REPLY',
    prompt: '',
  },
  {
    star_rating: 2,
    mode: 'AUTO_REPLY',
    prompt: '',
  },
  {
    star_rating: 1,
    mode: 'AUTO_REPLY',
    prompt: '',
  },
];

export const AutoRespondModal: React.FC<AutoRespondModalProps> = ({openModal, currentBussiness, isV2, setOpenModal, gbpLocationDetail, standaloneTool}) => {
  const {
    contentOptimizer: {aiOutline: {userAiSettings}},
    ottoV2Store: {loadGBPLocationDetail, selectedBusiness, businessesList, setReviewSettings, loadReviewSettings, getReviewSettings, aiGenerateReviewExample, reviewExample, resetReviewState, getSelectedLocation, gbpLocations},
    settings: {
      customer: {getCustomerQuota},
    },
  } = useStore('');
  const [addingPost, setAddingPost] = useState(false);
  const [loadingReviewSettings, setLoadingReviewSettings] = useState(false);
  const [selectedToneOfVoice, setSelectedToneOfVoice] = useState('Professional');
  const [selectedLength, setSelectedLength] = useState('Short');
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [reviews, setReviews] = useState(initialState);
  const [openPopover, setOpenPopover] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedGbp, setSelectedGbp] = useState<any>({});
  const router = useRouter();
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const antIcon = <LoadingOutlined style={{fontSize: 20, color: '#2D6CCA'}} spin />;
  const smallIcon = <LoadingOutlined style={{fontSize: 10, color: '#2D6CCA'}} spin />;
  const loadReviewsData = async (location?: number, isV2?: boolean) => {
    await loadReviewSettings(location || '', isV2, standaloneTool);
    setLoadingReviewSettings(false);
  };
  useEffect(() => {
    if (!standaloneTool) {
      setLoadingReviewSettings(true);
      openModal && loadReviewsData();
    }
  }, [openModal]);

  useEffect(() => {
    if (!loadingReviewSettings && getReviewSettings) {
      const snakeCaseArray = getReviewSettings?.configByStars?.length ? convertKeysToSnakeCase(getReviewSettings?.configByStars) : initialState;
      setSelectedToneOfVoice(getReviewSettings?.toneOfVoice ?? 'Professional');
      setReviews(snakeCaseArray);
      setSelectedLength(getReviewSettings?.length ?? 'Short');
      setSelectedLanguage(getReviewSettings?.language ? getReviewSettings?.language : languages.find(item => item.language == userAiSettings?.defaultLanguage)?.language ?? 'English');
    }
  }, [JSON.stringify(getReviewSettings)]);

  const AddPost = async () => {
    setAddingPost(true);
    const payload = {
      ...(standaloneTool ? {location: isV2 ? currentBussiness?.id : selectedGbp?.id} : []),
      toneOfVoice: selectedToneOfVoice,
      length: selectedLength,
      language: selectedLanguage,
      configByStars: reviews,
    };

    try {
      await setReviewSettings(payload, standaloneTool);
      setAddingPost(false);
      setOpenModal(false);
      notification.success('Settings updated successfully.', '', 2);
      if (standaloneTool) {
        getCustomerQuota();
      }
    } catch (error) {
      setAddingPost(false);
      setOpenModal(false);
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    if (standaloneTool && selectedGbp?.id) {
      setLoadingReviewSettings(true);
      loadReviewsData(selectedGbp?.id);
    } else if (standaloneTool && selectedBusiness && isV2) {
      setLoadingReviewSettings(true);
      loadReviewsData(selectedBusiness[0].id, true);
    }
  }, [JSON.stringify(selectedGbp), JSON.stringify(selectedBusiness)]);

  const toneOfVoiceMenu = (
    <Menu onClick={e => setSelectedToneOfVoice(e.key)}>
      {toneOfVoiceOptions.map(item => (
        <Menu.Item key={item.label}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );
  const lengthMenu = (
    <Menu onClick={e => setSelectedLength(e.key)}>
      {lengthOptions.map(item => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const languageMenu = (
    <LanguageMenu onClick={e => setSelectedLanguage(e.key)}>
      {languageOptions.map(item => (
        <Menu.Item key={item.language}>{item.language}</Menu.Item>
      ))}
    </LanguageMenu>
  );

  const updateRatingState = (rating, mode, value) => {
    const reviewArray = [...reviews];
    const ratingIndex = reviewArray.findIndex(item=> item?.star_rating === rating);
    if (mode === 'mode' && value === 'DO_NOTHING') {
      reviewArray[ratingIndex] = {...reviewArray[ratingIndex], [mode]: value, ['prompt']: ''};
    } else {
      reviewArray[ratingIndex] = {...reviewArray[ratingIndex], [mode]: value};
    }
    setReviews(reviewArray);
  };

  const reviewMenu = (id: number) => (
    <Menu onClick={e => updateRatingState(id, 'mode', e?.key)}>
      {reviewOptions.map(item => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );
  const resetPopOver = () => {
    setLoadingReview(false);
    setSelectedReviewId('');
    setOpenPopover(false);
    resetReviewState();
  };
  const onViewExample = async (index, data) => {
    setSelectedReviewId(`${index}-review`);
    setLoadingReview(true);
    setOpenPopover(true);
    const payload = {
      star_rating: data?.star_rating,
      config: {
        tone_of_voice: selectedToneOfVoice,
        length: selectedLength,
        language: selectedLanguage,
      },
    };
    if (data?.prompt) {
      payload['prompt'] = data?.prompt;
    }
    try {
      await aiGenerateReviewExample(payload, standaloneTool);
      setLoadingReview(false);
    } catch (error) {
      resetPopOver();
      return;
    }
  };

  const onViewHandler = (index: number, mapItem) => {
    if (loadingReview) {
      return;
    } else if (selectedReviewId === `${index}-review` && openPopover) {
      resetPopOver();
    } else {
      onViewExample(index, mapItem);
    }
  };

  const popOverContent = (
    reviewExample && (
      <ReviewContent>
        <div className='feedback'>Feedback:</div>
        <div className='feedback-des'>{reviewExample?.review ?? ''}</div>
        <div className='reply'>Reply:</div>
        <div className='reply-des'>{reviewExample?.reply ?? ''}</div>
      </ReviewContent>
    )
  );

  const handleSelectedLocation = e => {
    setSelectedGbp(e);
  };
  const filteredLocations = standaloneTool ? getStandAloneLocations(gbpLocations, searchValue) : [];

  const combinedList = businessesList?.data?.map(item => ({id: item.id, ...item.attributes}));


  useEffect(() => {
    if (gbpLocations?.length && standaloneTool) {
      for (const item of gbpLocations) {
        if (item?.businessName) {
          setSelectedGbp(item);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isV2) {
      const selectedBusiness = combinedList?.find(business => business?.locationId == getSelectedLocation()['locationId']);
      setSelectedGbp(selectedBusiness);
      if (router?.query?.subGroup === 'unanswered_reviews') {
        loadGBPLocationDetail(+getSelectedLocation()?.id, standaloneTool);
      }
    } else {
      const selectedBusiness = filteredLocations?.find(business => business?.locationId == getSelectedLocation()['locationId']);
      setSelectedGbp(selectedBusiness);
      if (router?.query?.subGroup === 'unanswered_reviews') {
        loadGBPLocationDetail(+getSelectedLocation()?.id, standaloneTool);
      }
    }
  }, []);


  const locationList = (
    <DropdownContainer >
      <GbpSearchInput value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder='Search...'
        prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
      />
      {(isV2 ? combinedList?.length ? combinedList : filteredLocations?.length ? filteredLocations : [] : filteredLocations).map(business => (
        <Radio key={business?.locationId} style={{width: '100%'}} onChange={e=> handleSelectedLocation(e.target.value)}
          checked={
            String(isV2 ? currentBussiness?.id : selectedGbp?.id) === String(business?.id)
          } value={business}>
          <div>
            <BusinessName style={{fontWeight: 'bold', fontSize: '12px', width: '405px'}}>{business?.businessName}</BusinessName>
            <BusinessAddress style={{width: '405px'}}>{business?.businessAddress}</BusinessAddress>
          </div>
        </Radio>
      )) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </DropdownContainer>
  );


  return (
    <StyledTopicalMapsModal
      width={'600px'}
      closable={true}
      onClose={() => {
        setOpenModal(false);
      }}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/></div>}
      visible={openModal}
    >
      <StyledModalHeader>
        <div className='modal-heading'>Auto respond to reviews</div>
        <div>{getSelectedLocation()['businessName']}</div>
        <div className='description'>General AI Settings</div>
      </StyledModalHeader>
      {standaloneTool && <SettingsWrapper alignItems='flex-start'>
        <div className='label'>Business:</div>
        <DropdownStyle disabled={isV2}

          trigger={['click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={locationList} visible={showGbpDropdown} onVisibleChange={e => {
            setShowGbpDropdown(e);
            if (!e) {
              setSearchValue('');
            }
          }}>
          <BusinessDropdownWrapper >
            <div style={{width: '92%'}}>
              <BusinessName>{isV2 ? currentBussiness?.businessName : selectedGbp?.businessName}</BusinessName>
              <BusinessAddress>{isV2 ? currentBussiness[0]?.businessAddress : selectedGbp?.businessAddress}</BusinessAddress>
            </div>
            <FontAwesomeIcon icon={faCaretDown} />
          </BusinessDropdownWrapper>
        </DropdownStyle>
      </SettingsWrapper>}
      <SettingsWrapper>
        <InputWrapper>
          <div className='label'>Tone of voice:</div>
          <StyledDropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={toneOfVoiceMenu} trigger={['click']}>
            <div style={{width: '115px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              {loadingReviewSettings ? <Spin indicator={antIcon} style={{display: 'flex', alignItems: 'center'}} /> : toneOfVoiceOptions.find(item => item.label === selectedToneOfVoice)?.label} <FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} style={{marginTop: '-6px'}}/>
            </div>
          </StyledDropdown>
        </InputWrapper>
        <InputWrapper>
          <div className='label2'>Length:</div>
          <StyledDropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={lengthMenu} trigger={['click']}>
            <div style={{width: '88px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              {loadingReviewSettings ? <Spin indicator={antIcon} style={{display: 'flex', alignItems: 'center'}} /> : lengthOptions.find(item => item.key === selectedLength)?.label} <FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} style={{marginTop: '-6px'}}/>
            </div>
          </StyledDropdown>
        </InputWrapper>
        <InputWrapper>
          <div className='label2'>Language:</div>
          <StyledDropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={languageMenu} trigger={['click']} placement='bottom'>
            <div style={{width: '103px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden'}}>
              {loadingReviewSettings ? <Spin indicator={antIcon} style={{display: 'flex', alignItems: 'center'}} /> : truncate(languageOptions.find(item => item.language === selectedLanguage)?.language, 10)} <FontAwesomeIcon icon={faSortDown} color='#4E5156' fontSize={14} style={{marginTop: '-6px'}}/>
            </div>
          </StyledDropdown>
        </InputWrapper>
      </SettingsWrapper>
      <SettingsWrapper>
        <TextAreaInputWrapper>
          <div className='label'>Company info:</div>
          <StyledCompanyInfo>{loadingReviewSettings ? (
            <>
              <SkeletonHorizontalLoaderGray lightLoader width='200px' height= '15px' skeletonHeight='10px' align='center' />
              <SkeletonHorizontalLoaderGray lightLoader width='280px' height= '15px' skeletonHeight='10px' align='center' />
              <SkeletonHorizontalLoaderGray lightLoader width='400px' height= '15px' skeletonHeight='10px' align='center' />
              <SkeletonHorizontalLoaderGray lightLoader width='320px' height= '15px' skeletonHeight='10px' align='center' />
            </>
          ) : (
            <>
              {
                isV2 && standaloneTool ? selectedBusiness?.[0]?.businessDescription : gbpLocationDetail?.businessDescription
              }
              {standaloneTool ? selectedGbp?.businessDescription : gbpLocationDetail?.businessDescription}


            </>
          )}</StyledCompanyInfo>
        </TextAreaInputWrapper>
      </SettingsWrapper>
      <ReviewSection>
        <div className='section-heading'>Which reviews you want to reply to?</div>
        <ReviewsWrapper>
          {reviews?.length && reviews?.map((mapItem, index) => (
            <ReviewInput key={index}>
              <div className='label'>
                <div>{mapItem?.star_rating} Star</div>
                <StyledRating disabled defaultValue={mapItem?.star_rating} />
              </div>
              <BoxWrapper style={{border: mapItem?.mode === 'DO_NOTHING' && 'none'}}>
                <BoxHeader style={{border: mapItem?.mode === 'DO_NOTHING' && '1px solid #D8D8D8', borderRadius: mapItem?.mode === 'DO_NOTHING' && '8px'}}>
                  <DropdownWrapper>
                    <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={reviewMenu(mapItem?.star_rating)} trigger={['click']}>
                      <div style={{display: 'flex', alignItems: 'center', gap: '4px'}} onClick={resetPopOver}>
                        {loadingReviewSettings ? (
                          <SkeletonHorizontalLoaderGray lightLoader width='200px' height= '15px' skeletonHeight='10px' align='center' />
                        ) :(
                          <>
                            <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
                              {iconMapping[mapItem?.mode] ? <FontAwesomeIcon icon={iconMapping[mapItem?.mode]} color='#4E5156' fontSize={18} /> : <img src='/icons/revert-time.svg' style={{width: '18px'}}/>}
                              {reviewOptions.find(item => item.key === mapItem?.mode)?.label}
                            </div>
                            <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={14} style={{marginTop: '-6px'}}/>
                          </>
                        )}
                      </div>
                    </Dropdown>
                  </DropdownWrapper>
                  {!hideViewExampleButton.includes(mapItem?.mode) && (
                    <Popover
                      overlayClassName={styles.reviewPopover}
                      overlayStyle={{zIndex: 1060}}
                      placement='right'
                      overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '12px', width: '442px'}}
                      content={(selectedReviewId === `${index}-review` && !loadingReview) && popOverContent}
                      trigger='click'
                      visible={openPopover}
                      onVisibleChange={value => {
                        if (value === false) {
                          resetPopOver();
                        }
                      }}
                    >
                      <ViewButton
                        style={{cursor: loadingReview && 'not-allowed'}}
                        onClick={() => onViewHandler(index, mapItem)}>View example{(selectedReviewId === `${index}-review` && loadingReview) && <Spin indicator={smallIcon} style={{display: 'flex', alignItems: 'center'}} />}</ViewButton>
                    </Popover>)}
                </BoxHeader>
                {!loadingReviewSettings && mapItem?.mode !== 'DO_NOTHING' && <TextArea bordered={false} style={{resize: 'none'}} value={mapItem?.prompt} onChange={e => updateRatingState(mapItem?.star_rating, 'prompt', e?.target?.value)}/>}
              </BoxWrapper>
            </ReviewInput>
          ))}
        </ReviewsWrapper>
        {/* <StyledNote>
          <FontAwesomeIcon icon={faInfoCircle} color='#2D6CCA' fontSize={18}/>
          <div className='note-text'>All replies will be randomly posted between 2 hours and 4 days to mimic real human behaviour.</div>
        </StyledNote> */}
      </ReviewSection>
      <StyledModalFooter>
        <div className='cancel-btn' onClick={() => {
          setOpenModal(false);
        }}>
            Cancel
        </div>
        <ButtonComponent
          color='blue'
          style='solid'
          type='button'
          className='update-button'
          onClick={AddPost}
          disabled={addingPost}
        >
          {
            addingPost ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: 'white', marginLeft: 5}} spin />} /> : <></>
          }
          Update
        </ButtonComponent>
      </StyledModalFooter>
    </StyledTopicalMapsModal>
  );
};

const StyledTopicalMapsModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #fff;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
  }
  .description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #121212;
  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 15px;
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;
const SettingsWrapper = styled.div<{alignItems?: string}>`
  display: flex;
  align-items: ${p => p?.alignItems || 'center'};
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  .label {
    min-width: 90px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .label {
    min-width: 90px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #4E5156;
  }
  .label2 {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const StyledDropdown = styled(Dropdown)`
  border: 1px solid #D8D8D8;
  padding: 8px 10px;
  background: #F9F9FB;
  border-radius: 8px;
`;
const TextAreaInputWrapper = styled(InputWrapper)`
  width: 100%;
  align-items: flex-start;
`;
const StyledCompanyInfo = styled.div`
  width: 100%;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  background:#F9F9FB;
  overflow: hidden;
  height: 91px;
  padding: 8px 4px 8px 11px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
const ReviewSection = styled.div`
  .section-heading {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #121212;
    margin-bottom: 14px;
  }
`;
const ReviewsWrapper = styled.div`
  margin-bottom: 20px;
`;
const ReviewInput = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  .label {
    min-width: 90px !important;
    div {
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.73px;
    }
  }
`;
const BoxWrapper = styled.div`
  width: 100%;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  overflow: hidden;
`;
const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 13px;
  background:#F9F9FB;
  border-bottom: 1px solid #D8D8D8;
`;
const DropdownWrapper = styled.div``;

const StyledRating = styled(Rate)`
  .ant-rate-star:not(:last-child) {
    margin-right: 2px;
  }
  .ant-rate-star-full > div > .ant-rate-star-second > span > svg {
    fill: #F1AA3E;
  }
  .ant-rate-star-zero > div > .ant-rate-star-second > span > svg {
    fill: rgba(241, 170, 62, 25%);
  }
`;

const LanguageMenu = styled(Menu)`
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const ViewButton = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #2D6CCA;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
`;
const ReviewContent = styled.div`
  border-radius: 12px;
  background-color: #121212;
  width: 411px;
  .feedback, .reply {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #E8E8E8;
  }
  .feedback-des, .reply-des {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .reply-des {
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const DropdownContainer = styled.div `
  width: 450px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: rgba(138,138,138,0.32);
  }
          
  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: rgba(138,138,138,0.12);
  }
`;

const GbpSearchInput = styled(Input)`
  width: 422px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;

const BusinessDropdownWrapper = styled.div`
  width: 450px;
  padding: 7px 15px 8px 15px;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  align-items: center;  
  justify-content: space-between;
  cursor: pointer;
`;

const BusinessName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #121212;
`;

const BusinessAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #4E5156;
`;

const DropdownStyle = styled(Dropdown)<{disable?: boolean}>`
background-color: ${({disabled}) => (disabled ? '#f5f5f5' : '')};
  color: ${({disabled}) => (disabled ? '#bfbfbf' : '')};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({disabled}) =>
    disabled ? '#f5f5f5' : ''}; 
  }
`;
