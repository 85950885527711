import React, {useState} from 'react';
import {Alert, Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {
  CommonInput,
  Label,
  GoogleLoginButtonRedirect,
} from '@/components/common-components/components/index';
import {useTranslation} from 'next-i18next';
import {authApi} from '@/api/account';
import {notification} from '@/utils/notifications';
import {useRouter} from 'next/router';
import {setTokenIntoCookies, setHasRegisteredIntoStorage} from '@/api/common-utils';
import {MailOutlined, LockOutlined, LoadingOutlined} from '@ant-design/icons';
import Link from 'next/link';

import {AccountHeader} from '../components';

import styles from './styles.module.scss';
import {Eye, EyeOff} from 'react-feather';
import {languages} from '@/components/layoutV2';

import {MixPanel} from '@/utils/mixpanel';
import ButtonComponent from '@/components/common-components/components/button-component';
import {useStore} from '@/store/root-store';
import {routes} from '@/utils/const';

interface Props {
  isCheckoutPage?: boolean;
  openRegisterPage?: (value) => void;
  onForgetPassword?: (value) => void;
}

export const LoginSection: React.FC<Props> = observer(({onForgetPassword, isCheckoutPage, openRegisterPage}) => {
  const {invoices: {
    getPaymentOptions,
    getCurrentPayment,
  },
  settings: {
    customer: {setAuthModal, profile, loadProfile, modalCustomRoute},
  },
  settings: {
    integration: {gscAccountsConnected},
  },
  } = useStore('');
  const {t} = useTranslation('common');
  const router = useRouter();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);


  const onLogin = async ({email, password}: {email: string; password: string}) => {
    setLoading(true);
    try {
      const data = await authApi.login(email, password);
      setHasRegisteredIntoStorage();
      setTokenIntoCookies(data.token);

      MixPanel.identify(`${data.userId}`);

      // const savedUrl = localStorage.getItem('nextUrl');
      // when we want a custom route to be reached after login
      localStorage.setItem('signedInFrom', 'default');
      if (modalCustomRoute) {
        router.push(modalCustomRoute);
      } else if (!gscAccountsConnected && profile?.isVendastaClient) {
        router.push('/gsc/sites-list');
      } else {
        router.push(routes?.home);
      }
    } catch (error) {
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        notification.error(t('invalid-credits'));
      } else {
        notification.error(t('went-wrong'));
      }
    }
    if (!profile?.emailAddress) {
      await loadProfile();
    }
    if (isCheckoutPage && !getPaymentOptions?.length) {
      await getCurrentPayment();
    }
    setLoading(false);
    setAuthModal(false, false, null);
  };

  return (
    <>
      <AccountHeader
        className={styles.header}
        titleText={t('get-seo-insights')}
        subtitleText={t('register-faster-google')}
        caption={t('or-use-email')}
      >
        <div onClick={() => isCheckoutPage ? localStorage.setItem('verifyingFromCheckoutPage', 'TRUE') : ''}>
          <GoogleLoginButtonRedirect buttonText={t('sign-with-google')} />
        </div>
      </AccountHeader>

      {/* Login Form */}
      <Form className={styles.form} onFinish={onLogin}>
        {router.query.hasPasswordReset && (
          <Alert
            message={t('reset-success')}
            description={(
              <div className={styles.infoBox}>
                {t('sent-email-inbox')}
              </div>
            )}
            type='success'
            showIcon
          />
        )}
        <Label className={styles.loginLabel}>{t('email-address')}</Label>
        <Form.Item name='email' rules={[{required: true, message: t('input-email')}]}>
          <CommonInput
            cyId='emailLogin'
            inputType='email'
            placeholder={t('email-example')}
            name='email'
            disabled={loading}
            className={styles.inputOverrides}
            iconClasses={styles.inputIcon}
            icon={<MailOutlined />}
          />
        </Form.Item>
        <Label className={styles.loginLabel}>{t('password')}</Label>
        <Form.Item name='password' rules={[{required: true, message: t('input-password')}]}>
          <CommonInput
            cyId='passwordLogin'
            inputType={showPass ? 'text' : 'password'}
            name='password'
            placeholder={t('password')}
            disabled={loading}
            iconClasses={styles.inputIcon}
            className={styles.inputOverrides}
            icon={<LockOutlined /> }
          />
        </Form.Item>
        <span className={styles.passIcon} onClick={() => setShowPass(!showPass)}>
          {showPass ? <EyeOff width={20} /> : <Eye width={20} />}
        </span>

        <div className={styles.flexBetween}>
          <div style={{color: 'rgb(24, 144, 255)', cursor: 'pointer'}} onClick={() => onForgetPassword(true)}>{t('forgot-password-?')}</div>
        </div>

        <div className={styles.loginButton}>
          <ButtonComponent
            data-cy='loginButton'
            type='submit' className={styles.loginBtn}
            isFullWidth
            disabled={loading}
          >{t('log-in')}
            <LoadingOutlined className={styles.loading} hidden={!loading}/>
          </ButtonComponent>
        </div>

        <div>
          <p className={styles.signUp}>
            {t('not-registered')}{' '}
            <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={() => openRegisterPage(true)}>{t('create-account')}</span>
          </p>
        </div>
        <ul className={styles.languagesSwitcher}>
          {
            router.locales.map(locale => (
              <li key={locale}>
                <Link legacyBehavior href={router.asPath} locale={locale} >
                  {languages(locale)}
                </Link>
              </li>
            ))
          }
        </ul>
      </Form>
    </>
  );
});

