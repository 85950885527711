export const HOME_FREE_CONSULTATION_CLICKED = 'HOME_FREE_CONSULTATION_CLICKED';

export const HOME_UPGRADE_CTA_CLICKED = 'HOME_UPGRADE_CTA_CLICKED';
export const CP_OPENED_FROM_KE = 'CP_OPENED_FROM_KE';
export const GSC_UPGRADE_CTA_CLICKED = 'GSC_UPGRADE_CTA_CLICKED';
export const SCA_UPGRADE_CTA_CLICKED = 'SCA_UPGRADE_CTA_CLICKED';

export const SA_PAGE_PRUNING_PAGE_OPEN = 'SA_PAGE_PRUNING_PAGE_OPEN';

export const GSC_INSIGHTS_GSC_AUTHORIZE_CLICKED = 'GSC_INSIGHTS_GSC_AUTHORIZE_CLICKED';
export const PROJECTS_GSC_AUTHORIZE_CLICKED = 'PROJECTS_GSC_AUTHORIZE_CLICKED';
export const SETTINGS_GSC_AUTHORIZE_CLICKED = 'SETTINGS_GSC_AUTHORIZE_CLICKED';

export const SCA_CREATE_PAGE = 'SCA_CREATE_PAGE';
export const SCA_PAGE_ADD_KEYWORD = 'SCA_PAGE_ADD_KEYWORD';
export const SCA_PAGE_ADD_KEYWORD_SUGGESTION = 'SCA_PAGE_ADD_KEYWORD_SUGGESTION';
export const SCA_PAGE_UPDATE_KEYWORD_LOCATION = 'SCA_PAGE_UPDATE_KEYWORD_LOCATION';
export const SCA_PAGE_EXPORT_TO_DOCS = 'SCA_PAGE_EXPORT_TO_DOCS';
export const SCA_PAGE_CONTENT_IDEAS_GENERATE = 'SCA_PAGE_CONTENT_IDEAS_GENERATE';
export const SCA_PAGE_AI_OUTLINE_GENERATE = 'SCA_PAGE_AI_OUTLINE_GENERATE';
export const SCA_PAGE_AI_ONE_CLICK_GENERATE = 'SCA_PAGE_AI_ONE_CLICK_GENERATE';
export const SCA_PAGE_CONTENT_IDEA_COPY = 'SCA_PAGE_CONTENT_IDEA_COPY';
export const SCA_PAGE_CONTENT_IDEA_DOWNLOAD = 'SCA_PAGE_CONTENT_IDEA_DOWNLOAD';
export const SCA_PAGE_AI_OUTLINE_DOWNLOAD = 'SCA_PAGE_AI_OUTLINE_DOWNLOAD';
export const SCA_PAGE_SHARED = 'SCA_PAGE_SHARED';
export const SCA_PAGE_URL_UPDATE = 'SCA_PAGE_URL_UPDATE';
export const SCA_PAGE_INTERNAL_LINKS_SHOW_BROKEN = 'SCA_PAGE_INTERNAL_LINKS_SHOW_BROKEN';
export const SCA_PAGE_EXTERNAL_LINKS_SHOW_BROKEN = 'SCA_PAGE_EXTERNAL_LINKS_SHOW_BROKEN';
export const SCA_PAGE_IMAGES_SHOW_MISSING_ALT = 'SCA_PAGE_IMAGES_SHOW_MISSING_ALT';
export const SCA_PAGE_FOCUS_TERMS_TAB_OPEN = 'SCA_PAGE_FOCUS_TERMS_TAB_OPEN';
export const SCA_PAGE_QUESTIONS_TAB_OPEN = 'SCA_PAGE_QUESTIONS_TAB_OPEN';
export const SCA_PAGE_LINK_SUGGESTIONS_TAB_OPEN = 'SCA_PAGE_LINK_SUGGESTIONS_TAB_OPEN';

export const KEYWORD_RESEARCH_FIND_KEYWORD_CLICK = 'KEYWORD_RESEARCH_FIND_KEYWORD_CLICK';

export const KEYWORD_LISTS_UPDATE_FROM_DETAIL_PAGE = 'KEYWORD_LISTS_UPDATE_FROM_DETAIL_PAGE';

export const GSC_INSIGHTS_VIEW_PAGE_HISTORICAL_DATA = 'GSC_INSIGHTS_VIEW_PAGE_HISTORICAL_DATA';
export const GSC_INSIGHTS_VIEW_KEYWORD_HISTORICAL_DATA = 'GSC_INSIGHTS_VIEW_KEYWORD_HISTORICAL_DATA';
export const GSC_INSIGHTS_VIEW_KEYWORD_SERP_DETAILS_DATA = 'GSC_INSIGHTS_VIEW_KEYWORD_SERP_DETAILS_DATA';
export const GSC_INSIGHTS_UPDATE_DATE_RANGE = 'GSC_INSIGHTS_UPDATE_DATE_RANGE';
export const GSC_PROJECT_CHANGED = 'GSC_PROJECT_CHANGED';
export const GSC_CREATING_SITE_EVENT = 'GSC_CREATING_SITE_EVENT';
export const GSC_EDITING_SITE_EVENT = 'GSC_EDITING_SITE_EVENT';
export const GSC_CREATING_PAGE_GROUPINGS = 'GSC_CREATING_PAGE_GROUPINGS';
export const GSC_ADDING_PAGES_TO_GROUPING = 'GSC_ADDING_PAGES_TO_GROUPING';

export const CI_COPY_ARTICLE_TO_CLIPBOARD = 'CI_COPY_ARTICLE_TO_CLIPBOARD';
export const CI_EDIT_ARTICLE = 'CI_EDIT_ARTICLE';
export const CI_DOWNLOAD_ARTICLE = 'CI_DOWNLOAD_ARTICLE';
export const CI_GENERATE_SEO_METATAGS_IDEAS_CLICKED = 'CI_GENERATE_SEO_METATAGS_IDEAS_CLICKED';
export const CI_POPULAR_TEMPLATES_TAB_SWITCH = 'CI_POPULAR_TEMPLATES_TAB_SWITCH';

// Mixpanel for Pruning Page
export const PRUNING_PAGE_GRAPH_TRAFFIC_TAB = 'PRUNING_PAGE_GRAPH_TRAFFIC_TAB';
export const PRUNING_PAGE_GRAPH_IMPRESSIONS_TAB = 'PRUNING_PAGE_GRAPH_IMPRESSIONS_TAB';
export const PRUNING_PAGE_EXPORT_ALL = 'PRUNING_PAGE_EXPORT_ALL';
export const PRUNING_PAGE_OPENING_HISTORICAL_DATA_SIDEDRAWER = 'PRUNING_PAGE_OPENING_HISTORICAL_DATA_SIDEDRAWER';
export const PRUNING_PAGE_ADDING_PAGE_TO_WATCHLIST = 'PRUNING_PAGE_ADDING_PAGE_TO_WATCHLIST';

// Mixpanel for settings
export const SETTINGS_PROFILE_TAB = 'SETTINGS_PROFILE_TAB';
export const SETTINGS_API_KEY_TAB = 'SETTINGS_API_KEY_TAB';


export const SETTINGS_ACCOUNT_DETAILS_UPDATE = 'SETTINGS_ACCOUNT_DETAILS_UPDATE';
export const SETTINGS_PASSWORD_UPDATE = 'SETTINGS_PASSWORD_UPDATE';

export const SETTINGS_GSC_INTEGRATIONS_TAB = 'SETTINGS_GSC_INTEGRATIONS_TAB';
export const SETTINGS_DISCONNECT_ACCOUNT = 'SETTINGS_DISCONNECT_ACCOUNT';
export const SETTINGS_DISCONNECT_GA_ACCOUNT = 'SETTINGS_DISCONNECT_GA_ACCOUNT';

export const SETTINGS_EMAIL_UPDATES_TAB = 'SETTINGS_EMAIL_UPDATES_TAB';

export const SETTINGS_TEAM_MEMBERS_TAB = 'SETTINGS_TEAM_MEMBERS_TAB';
export const SETTINGS_ADDING_NEW_TEAM_MEMBER = 'SETTINGS_ADDING_NEW_TEAM_MEMBER';
export const SETTINGS_CHANGING_TEAM_MEMBER_ROLE = 'SETTINGS_CHANGING_TEAM_MEMBER_ROLE';
export const SETTINGS_ACTIVATING_USER = 'SETTINGS_ACTIVATING_USER';
export const SETTINGS_DEACTIVATING_USER = 'SETTINGS_DEACTIVATING_USER';

// Mixpanel when page is opened
export const DASHBOARD_PAGE_IS_OPENED = 'DASHBOARD_PAGE_IS_OPENED';


export const MY_ORDERS_ORDER_BUILDER_BUTTON_CLICKED = 'MY_ORDERS_ORDER_BUILDER_BUTTON_CLICKED';

// Mixpanel for DA onboarding flow
export const DOMAIN_ANALYZER_STARTED = 'DOMAIN_ANALYZER_STARTED';
export const DOMAIN_ANALYZER_COMPLETED = 'DOMAIN_ANALYZER_COMPLETED';
export const DOMAIN_ANALYZER_REGISTRATION_STARTED = 'DOMAIN_ANALYZER_REGISTRATION_STARTED';
export const DOMAIN_ANALYZER_REGISTRATION_CONFIRMED = 'DOMAIN_ANALYZER_REGISTRATION_CONFIRMED';

