import {types} from 'mobx-state-tree';

const topKeywordsModel = types.model({
  trend: types.maybeNull(types.array(types.number)),
  keyword: types.maybeNull(types.string),
  keywordSv: types.maybeNull(types.number),
});

const siteModel = types.model({
  totalKeywords: types.maybeNull(types.number),
  totalEstTraffic: types.maybeNull(types.number),
  totalEstTrafficValue: types.maybeNull(types.number),
  topKeywords: types.array(topKeywordsModel),
});

export const topCompetitorsKeywordsModel = types.model({
  keyword: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  keywordSv: types.maybeNull(types.number),
  estTraffic: types.maybeNull(types.number),
  keywordCpc: types.maybeNull(types.number),
  topCompetitors: types.array(types.model({
    domain: types.maybeNull(types.string),
    position: types.maybeNull(types.number),
  })),
  competitorDomain: types.maybeNull(types.string),
  competitorPosition: types.maybeNull(types.number),
  estTrafficValueUsd: types.maybeNull(types.number),
  competitorUrl: types.maybeNull(types.string),
});

const topTwoCompetitorKeywordsOverlapModel = types.model({
  sets: types.array(types.string),
  size: types.maybeNull(types.number),
});

const competitorsModel = types.model({
  domain: types.maybeNull(types.string),
  commonKeywords: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  totalEstTraffic: types.maybeNull(types.number),
  totalEstTrafficValue: types.maybeNull(types.number),
  keywords: types.array(types.string),
});

const topCompetitorsKeywords = types.model({
  pageNumber: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  results: types.array(topCompetitorsKeywordsModel),
});

export const organicCompetitorsModel = types.model({
  site: types.maybeNull(siteModel),
  topCompetitorsKeywords: topCompetitorsKeywords,
  topTwoCompetitorKeywordsOverlap: types.array(topTwoCompetitorKeywordsOverlapModel),
  competitors: types.array(competitorsModel),
});
