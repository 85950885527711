import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class LinkgraphApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api');

  public async revokeInvitation(id) {
    try {
      const response = await this.axios.post(`${LinkgraphApi.baseUrl}/customer/account/team/remove-invitation/`,
        {
          invitation_id: id,
        }, {
          headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const LINKGRAPH_API = new LinkgraphApi();
