import {types, flow, cast} from 'mobx-state-tree';
import {GetCheckerTaskPayload} from '@/api/bulk-da-checker/api';
import {BULK_DA_CHECKER_API} from '@/api/bulk-da-checker';
import {getUniqueData} from '@/utils/arrays';
import {notification} from '@/utils/notification-v2';
import {API_POLL_INTERVAL_BULK_DA_CHECKER} from '@/constants';

const BulkDaCheckerResultModel = types.model({
  id: types.maybeNull(types.number),
  domainUrl: types.maybeNull(types.string),
  mozPageAuthority: types.maybeNull(types.union(types.number, types.string)),
  mozDomainAuthority: types.maybeNull(types.union(types.number, types.string)),
  mozSubdomainSpamScore: types.maybeNull(types.union(types.number, types.string)),
  mozExternalRootDomainCount: types.maybeNull(types.union(types.number, types.string)),
  mozRootDomainsToRootDomain: types.maybeNull(types.union(types.number, types.string)),
});

const IntermediateResultsModel = types
  .model({
    data: types.array(BulkDaCheckerResultModel),
    totalCount: types.optional(types.maybeNull(types.number), 0),
    completedCount: types.optional(types.maybeNull(types.number), 0),
  })
  .actions(self => {
    const setData = ({data, totalCount, completedCount}) => {
      self.data = data;
      self.totalCount = totalCount;
      self.completedCount = completedCount;
    };
    return {setData};
  });

export const BulkDaCheckerStore = types
  .model({
    id: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    noRepolling: types.boolean,
    intermediateResults: types.maybeNull(IntermediateResultsModel),
    results: types.array(BulkDaCheckerResultModel),
  })
  .actions(self => {
    const requestCheckerTask = flow(function* (payload: GetCheckerTaskPayload) {
      try {
        const task = yield BULK_DA_CHECKER_API.getCheckerTask(payload);
        self.id = task.id;
        self.status = 'pending';

        return task;
      } catch (e) {
        const {status} = e.response;
        if (status == 400) {
          {e.response?.data?.code == 'limit.exceeded' ? notification.error('Error', e.response?.data?.error) :
            notification.error('Error', e.response?.data?.urls?.length ? e.response?.data?.urls[0] : 'Something went wrong. Please refresh the page or try again later.');}
        } else {
          throw e;
        }
      }
    });

    const requestCheckerStatus = flow(function* (id: number) {
      try {
        const {task} = yield BULK_DA_CHECKER_API.checkTaskStatus(id);
        self.status = task?.status;

        if (task?.status != 'completed' && !self.noRepolling) {
          yield new Promise(r => setTimeout(r, API_POLL_INTERVAL_BULK_DA_CHECKER));
          return requestCheckerStatus(task?.id);
        } else {
          if (task?.intermediateResults !== null) {
            self.intermediateResults = cast(task?.intermediateResults);
          }
          const uniqueData = getUniqueData(task?.results, 'id');
          self.results = cast(uniqueData);
          return task;
        }
      } catch (e) {
        const statusCode = e?.response?.status;
        if (statusCode == 400) {
          notification.error('Error', e.response.data.error);
        } else {
          throw e;
        }
      }
    });

    const stopRepolling = () => {
      self.noRepolling = true;
    };

    return {requestCheckerTask, requestCheckerStatus, stopRepolling};
  })
  .actions(self => {
    const requestBulkDaCheck = async (payload: GetCheckerTaskPayload) => {
      try {
        const {urls, recaptcha} = payload;
        const formattedUrls = urls.replace(/ /g, '').replace(',', '\n');

        const task = await self.requestCheckerTask({urls: formattedUrls, recaptcha});

        return task;
      } catch (e) {
        return {};
      }
    };
    return {requestBulkDaCheck};
  });

export const initBulkDaCheckerStore = () => {
  return BulkDaCheckerStore.create({
    id: null,
    status: null,
    noRepolling: false,
    intermediateResults: null,
    results: [],
  });
};
