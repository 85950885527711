import {PropsWithChildren} from 'react';
import styles from './style.module.scss';
import {Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import {formatNumber, numberWithCommas} from '@/utils/number';
import {Gain} from './gain';
import {Loss} from './loss';
import numeral from 'numeral';
import moment from 'moment';

type ProfitContainerProps = PropsWithChildren<{
  currentValue?: number;
  previousValue?: number;
  showTooltip?: boolean;
  isGain?: boolean;
  secondTooltip?: any;
  tooltipPlacement?:any;
  valueWithoutFormate?:boolean;
  isLost?: boolean;
}>;

export const ProfitContainer = observer(({valueWithoutFormate, children, currentValue = 0, previousValue = 0, tooltipPlacement='bottom', showTooltip = false, isGain, secondTooltip, isLost}: ProfitContainerProps) => {
  const {gsc: {criteria}} = useStore('');

  const deltaValue = currentValue - Math.max(previousValue, 0);
  const change = previousValue && `${numeral(Math.abs(deltaValue/previousValue||0)*100).format('0')}%`;

  return (
    <>
      {showTooltip ?
        <Tooltip overlayClassName={styles.tooltipInner}
          placement={tooltipPlacement}
          title={
            <div className={styles.tooltipContent}>
              <div className={styles.row} style={{marginBottom: '8px'}}>
                <div className={styles.label}>
                  <span style={{color: '#fff', marginRight: 5}}>
                    Change:
                  </span>
                </div>
                <div className={styles.value}>
                  {isLost ? <div style={{color: 'red'}}>Lost</div> : isGain ?
                    <Gain currentValue={currentValue || 0} previousValue={previousValue || 0}>{Math.abs(deltaValue/previousValue||0)*100 > 10000 ? <span>∞</span> : change}</Gain> : <Loss currentValue={currentValue || 0} previousValue={previousValue || 0}>{Math.abs(deltaValue/previousValue||0)*100 > 10000 ? <span>∞</span> : change}</Loss>}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>
                  <div>
                    <span style={{color: '#fff', marginRight: 5, fontWeight: 'bold'}}>
                      <div>{valueWithoutFormate ? 'Date:' : 'Date Range:'} {secondTooltip?.showData && secondTooltip?.startDateString}</div>
                    </span>
                    {valueWithoutFormate ? <span>{secondTooltip?.showData ? moment(secondTooltip?.tableDateRange?.pagesPeriod2End).format('DD MMMM YYYY') : criteria?.currentPeriodEnd ? moment(criteria?.currentPeriodEnd).format('DD MMMM YYYY') : '-'}</span> :
                      secondTooltip?.showData ? <span> {moment(secondTooltip?.tableDateRange?.pagesPeriod2Start).format('DD MMMM YYYY')} - {moment(secondTooltip?.tableDateRange?.pagesPeriod2End).format('DD MMMM YYYY')}</span> :
                        <span> {criteria?.currentPeriodStart ? moment(criteria?.currentPeriodStart).format('DD MMMM YYYY') : '-'} - {criteria?.currentPeriodEnd ? moment(criteria?.currentPeriodEnd).format('DD MMMM YYYY') : '-'}</span>}
                  </div>
                </div>
                <div className={styles.value}>
                  {valueWithoutFormate ? numberWithCommas(currentValue) : formatNumber(currentValue, 2)?.toString()?.replace('.00', '') }
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>
                  <div>
                    <span style={{color: '#fff', marginRight: 5, fontWeight: 'bold'}}>
                      <div>Compared to: {secondTooltip?.showData && secondTooltip?.endDateString}</div>
                    </span>
                    {valueWithoutFormate ? <span> {secondTooltip?.showData ? moment(secondTooltip?.tableDateRange?.pagesPeriod1Start).format('DD MMMM YYYY') : criteria?.previousPeriodStart ? moment(criteria?.previousPeriodStart).format('DD MMMM YYYY') : '-'}</span> :
                      secondTooltip?.showData ? <span> {moment(secondTooltip?.tableDateRange?.pagesPeriod1Start).format('DD MMMM YYYY')} - {moment(secondTooltip?.tableDateRange?.pagesPeriod1End).format('DD MMMM YYYY')}</span> :
                        <span>{criteria?.previousPeriodStart ? moment(criteria?.previousPeriodStart).format('DD MMMM YYYY') : '-'} - {criteria?.previousPeriodEnd ? moment(criteria?.previousPeriodEnd).format('DD MMMM YYYY') : '-'}</span>}
                  </div>
                </div>
                <div className={styles.value}>
                  {valueWithoutFormate ? numberWithCommas(previousValue) : formatNumber(previousValue, 2)?.toString()?.replace('.00', '') }
                </div>
              </div>
            </div>
          }
          overlayStyle={{
            maxWidth: 'fit-content',
          }}
          overlayInnerStyle={{
            borderRadius: 8,
            padding: '20, 15',
          }}
        >
          <div className={styles.profit}>
            {children}
          </div>
        </Tooltip> :
        <div className={styles.profit}>
          {children}
        </div>
      }
    </>
  );
});
