import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {Spin, Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {LoadingOutlined} from '@ant-design/icons';

type TooltipPlacement = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'bottom' | 'top' | 'left' | 'right';

interface Props {
  children: React.ReactNode;
  placement?: TooltipPlacement;
  removeTooltip?: boolean;
}

const FreezeWrapper = observer(({children, placement = 'top', removeTooltip} : Props) => {
  const {ottoV2Store: {getOttoV2Project, unfreezeLoading, unfreezeOttoProject}} = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const isPublicPage = !!getSingleUrlParam('public_hash');
  const shouldDisableChildren = isPublicPage ? !getOttoV2Project?.isFrozen || !removeTooltip : getOttoV2Project?.isFrozen && !removeTooltip;

  if (shouldDisableChildren) {
    return (
      <Tooltip title={getOttoV2Project?.isFrozen ? (<TooltipContent>
        <div>All modifications to Deep Frozen sites are paused.</div>
        {isPublicPage ? '' : unfreezeLoading ? <Spin indicator={<LoadingOutlined style={{fontSize: '14px', color: '#34AEF3'}} spin />} /> : <div className='unfreeze' onClick={() => !unfreezeLoading && unfreezeOttoProject(uuid)}>Unfreeze site?</div>}
      </TooltipContent>) : ''}
      overlayInnerStyle={{backgroundColor: '#000000', width: '304px', borderRadius: '8px'}}
      placement={placement}
      >
        <span style={{cursor: 'not-allowed'}}>
          <Wrapper>
            {children}
          </Wrapper>
        </span>
      </Tooltip>
    );
  } else {
    return (
      <>
        {children}
      </>
    );
  }
});

export default FreezeWrapper;

const TooltipContent = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  .unfreeze {
    color: #34AEF3;
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  pointer-events: none;
  opacity: 0.8;
  display: inline-block;
  white-space: nowrap;
  cursor: not-allowed;
`;
