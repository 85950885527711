import {PAGES_API} from '@/api/content-optimizer';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {cast, flow, getRoot, types} from 'mobx-state-tree';

const ContentFragmentModel = types.model({
  type: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  resultCount: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  results: types.maybeNull(types.array(types.string)),
  uuid: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  html: types.maybeNull(types.string),
});

export const ContentFragment = types.model({
  fragment: types.maybeNull(ContentFragmentModel),
  fragmentLoading: types.boolean,
})
  .actions(self => {
    const fetchFragment = flow(function* (keyword: string, isReplace: boolean = false) {
      try {
        self.fragmentLoading = true;
        const response = yield PAGES_API.createContentFragment(keyword, isReplace);
        self.fragment = cast(response);
        self.fragmentLoading = false;
      } catch (e) {
        self.fragmentLoading = false;
        const {status} = e.response;
        if (status === 429) {
          if (self.fragment.html) self.fragment.html = null;
          if (self.fragment.text) self.fragment.text = null;
          const rootStore = getRoot(self) as any;
          rootStore?.settings?.customer?.profile?.matchAiConsumedTotalQuota();
          const totalAiQuota = rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
          const quotaTitle = totalAiQuota ? `You've consumed all quota points ${totalAiQuota}/${totalAiQuota}.` : `You've consumed all quota points.`;
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        } else if (status === 406) {
          notification.warning('', e.response.data.error);
        } else {
          useErrorNotification({
            e,
            msg: 'Error loading Content Ideas',
            desc: 'The Content Ideas data did not load properly. To fix the issue:',
            handleStatuses: [{
              statuses: [401, 403],
              msg: 'Please sign up to edit this article.',
              permanent: false,
              showDetails: false,
            }],
          });
        }

        return Promise.reject(e);
      }
    });

    const clearContentFragments = () => self.fragment.results.length = 0;

    return {
      fetchFragment,
      clearContentFragments,
    };
  });

export const initContentFragment = () => {
  return ContentFragment.create({
    fragment: {
      type: '',
      keyword: '',
      resultCount: 0,
      createdAt: '',
      updatedAt: '',
      results: [],
      uuid: '',
    },
    fragmentLoading: false,
  });
};
