import {Button, Pagination} from '@/components/common-components/components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Collapse, Input, Menu, Radio, Rate, Select, Table, Checkbox, Empty} from 'antd';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import dynamic from 'next/dynamic';

const ReactQuill = dynamic(
  () => import('react-quill'),
  {ssr: false},
);

export const StyledMenu = styled(Menu)`
  border-radius: 12px;
  .results-container {
    max-height: 300px !important;
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
            
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
  }
`;
export const MenuItem = styled(Menu.Item)`
  background: #fff !important;
  font-size: 12px !important;
  &:hover {
    background: #f5f5f5 !important;
  }
`;

export const MenuStyled = styled(Menu)`
  min-width: 200px;
  border: 1px solid #D9D9D9;
  padding: 11px 12px;
  border-radius: 12px;
  .menu-item {
    background: #fff !important;
    width: fit-content !important;
    padding: 8px 0 0 !important;
    &:first-child {
      padding-top: 0 !important;
    }
  }
  .btn-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #fff !important;
    border: 1px solid #2D6CCA;
  }

.ant-radio-checked .ant-radio-inner {
  background-color: #2D6CCA !important;
}
`;

export const TechnicalFixesWrapper = styled.div``;
export const StyledTechnicalFixesCollapse = styled(Collapse)`
  .ant-collapse-item {
    margin-bottom: 20px;
    .ant-collapse-header {
      padding: 0px;
      .ant-collapse-arrow {
        width: 17px;
        margin-right: 5px;
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }
`;

export const TasksCard = styled.div<{ selected?: boolean; disabled?: boolean}>`
  border: ${p => p.disabled ? '1px solid #E4E4E4' : p.selected ? '1px solid #2D6CCA' : '1px solid #E4E4E4'};
  background: ${p => p.selected ? 'rgba(45, 108, 202, 0.08)' : '#F9F9FB'};
  border-radius: 10px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
  cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
  :hover {
    border: ${p => p.disabled ? '1px solid #E4E4E4' : '1px solid #2D6CCA'};
    background-color: ${p => p.disabled ? '#F9F9FB' : 'rgba(45, 108, 202, 0.08)'};
  }
`;
export const TaskContent = styled.div<{ selected?: boolean; disabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
    row-gap: 4px;
    .task-icon {
      margin-left: 4px !important;
    }
  }

  .title {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${p => p.disabled ? '#A3A4A4' : '#121212'};
    line-height: normal;
  }
  .value {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: ${p => p.disabled ? '#A3A4A4' : '#4E5156'};
    line-height: normal;
    white-space: nowrap;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    row-gap: 4px;
    align-items: flex-start;
    .value {
      margin-left: 26px;
    }
  }
`;
export const StyledIssuesCollapse = styled(StyledTechnicalFixesCollapse)`
  .ant-collapse-item {
    margin-top: 10px;
    .ant-collapse-header {
      padding: 0px 15px;
      cursor: auto;
      .header-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 8px;
        .issues-btn-wrapper {
          margin-left: auto;
        }
        @media screen and (max-width: 1260px) {
          .issues-collapse-header {
            min-height: 46px;
          }
        }
        
        .issues-collapse-header {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    
      div:nth-child(1) {
        .anticon-right {
          display: none;
        }
      }
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      .header-container {
        .issues-collapse-header {
        }
      }
    }
  }
`;

export const StylesScheduledFor = styled.div`
  .ant-picker-input {
    input {
      font-size: 13px;
    }
  }
`;
export const CloudStackStatusButton = styled(ButtonComponent)<{background?: string; textColor?: string; isWhite?: boolean; backgroundDisabledColor?: string; width?: string; height?: string; centered?: boolean}>`
  height: ${p => p?.height ? p?.height : '26px'} !important;
  ${p => p?.width ? `width: ${p?.width};` : ''}
  ${p => p?.centered ? `
    display: flex;
    justify-content: center;
    align-items: center;
  `: ''}
  white-space: nowrap;
  background: ${p => p?.background} !important;
  color: ${p => p?.textColor || '#fff'} !important;
  border-radius: 6px;
  transition: none !important;
  ${p => p?.isWhite ? 'padding-left: 0;' : ''}
  ${p => p?.isWhite ? 'background-color: inherit !important;' : ''}
  &:hover {
    ${p => p?.isWhite ? 'background-color: inherit !important;' : ''}
  }
  &:disabled {
    ${p => p?.backgroundDisabledColor ? `
      background: ${p?.backgroundDisabledColor} !important;
      cursor: auto !important;
    ` : ''}
  }
  span {
    font-size: 13px;
    line-height: 15.73px;
    font-weight: 400;
    display: flex;
    gap: 6px;
    color: ${p => p?.textColor || '#fff'} !important;
  }
`;
export const StyledIssuesTable = styled(Table)<{isInPopOver?: boolean; paddingInCells?: boolean; marginTop?: string; maxHeight?: string}>`
  margin-top: ${p => p?.marginTop ? p?.marginTop : '14px'};
  border-radius: ${p => p.isInPopOver ? '6px' : ''};
  .ant-table-body {
    ${p => p?.maxHeight ? `max-height: ${p?.maxHeight};` : ''}
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
            
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
  }
  .ant-table-cell {
    vertical-align: top !important;
    &.text-align-middle {
      vertical-align: middle !important;
    }
    &.unanswered-proposed-answer {
      vertical-align: top !important;
    }
    &.images-fadin {
      vertical-align: top !important;
    }
  }
  .ant-spin-nested-loading {
    .ant-spin-container {
    .ant-table {
      .ant-table-container {
        .ant-table-content {
            height: ${p => p.isInPopOver ? '300px' : ''};
            overflow: ${p => p.isInPopOver ? 'auto' : ''};
            &::-webkit-scrollbar {
              height: 5px;
              width: 5px;
            }
          
            &::-webkit-scrollbar-thumb {
              background: rgba(138,138,138,0.32);
            }
          
            &::-webkit-scrollbar-track {
              background: rgba(138,138,138,0.12);
            }
          }
          table {
            .ant-table-thead {
              tr {
                th {
                  padding: 6px 14px !important;
                  @media screen and (max-width: 1100px) {
                    white-space: nowrap;
                  }
                  &.header-text-align-middle {
                    vertical-align: middle !important;
                  }
                  &.p-vertical-12 {
                    padding: 14px !important;
                  }
                  &.pr-5 {
                    padding-right: 5px !important;
                  }
                  &.radish-column {
                    background-color: #feeded;
                  }
                  &.greenish-column {
                    background-color: #E9F8EE;
                  }
                  &:nth-child(1) {
                    cursor: pointer;
                    svg {
                      vertical-align: 0px;
                      margin-left: 5px;
                    }
                  } 
                  .column-title {
                    font-family: 'Inter', sans-serif;
                    color: #121212;
                    font-size: 12px;
                    font-weight: 600;
                    &.align-right {
                      text-align: right;
                    }
                  }
                  .nlp-term-name {
                    width: 415px !important;
                  }
                  border-bottom: 0px;
                  background-color: #F2F2F5;
                  padding: 13px 16px;
                  &:nth-child(1) {
                    border-radius: 6px 0px 0px 6px;
                  }
                  &:nth-last-child(1) {
                    border-radius: 0px 6px 6px 0px;
                  }

                  &::before {
                    display: none;
                  }
                } 
              }
            }
            .ant-table-tbody {
              tr {
                td {
                  padding: 6px 16px !important;
                  &.text-align-top {
                    vertical-align: top;
                  }
                  &.pr-5 {
                    padding-right: 5px !important;
                  }
                  &.no-padding-right[colspan="2"] {
                    padding-right: 0 !important;
                  }
                  &.max-width-120 {
                    max-width: 120px !important;
                    @media screen and (max-width: 1780px) {
                      max-width: 140px !important;
                    }
                  }
                  &.max-width-150 {
                    max-width: 150px !important;
                    @media screen and (max-width: 1780px) {
                      max-width: 170px !important;
                    }
                  }
                }
              }
            }
        }
      }
    }
    ${p => p?.paddingInCells ? `
      padding-left: 6px !important;
      padding-right: 6px !important;
    ` : ''}
    .ant-pagination {
      align-items: center;
      .ant-pagination-total-text {
        color: #a3a4a4 !important;
        height: 30px !important;
      }
      .ant-pagination-prev, .ant-pagination-next {
        display: flex;
        align-items: center;
        .ant-pagination-item-link {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none !important;
          background: #f7f7f8 !important;
          width: 25px;
          height: 25px;
          border-radius: 4px !important;
        }
      }
      .ant-pagination-options {
        height: 25px !important;
        .ant-select {
          background: #f7f7f8 !important;
          border: 1px solid #e8e8e8 !important;
          border-radius: 4px !important;
          height: 28px;
          justify-content: center;
          margin-bottom: 0;
          padding: 0 5px;
          z-index: 1 !important;
          .ant-select-selector {
            background-color: transparent !important;
            border: 0 !important;
            height: 1.75em !important;
            align-items: center;
          }
        }
      }
      .ant-pagination-item {
        min-width: 25px !important;
        height: 25px !important;
        background:#f7f7f8 !important;
        border: 1px solid #e8e8e8 !important;
        border-radius: 4px !important;
        box-sizing: border-box !important;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        &.ant-pagination-item-active {
          background: #2d2f34 !important;
          a {
            color: #fff !important;
          }
        }
        a {
          color: #121212 !important;
          text-decoration: none !important;
        }
      }
    }
  }
  }
`;

export const PressReleaseStatusWrapper = styled.div`
  button, .ant-btn {
    cursor: auto !important;
  }
  .tooltip-btn {
    text-decoration: underline;
    color: #2D6CCA !important;
    cursor: pointer;
  }
`;

export const OnlyHeaderTAble = styled(StyledIssuesTable)`

  .ant-table-thead {
    tr {
      th {
        &.ant-table-cell-scrollbar {
          display: none !important;
        }
        &:nth-last-child(2) {
          border-radius: 0px 6px 6px 0px !important;
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      &.ant-table-row-level-0 {
        display: none !important;
      }
    }
  }
`;
export const MiddleInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  gap: 8px;
  .nlp-terms-input {
    padding: 2px 10px;
    border-radius: 4px;
    border: 1px solid #A3A4A4;
  }
  .nlp-faq {
    width: 100%;
    .nlp-faq-input {
      margin-bottom: 6px;
      border-radius: 4px;
      border: 1px solid #A3A4A4;
      resize: none;
      height: 42px;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .nlp-terms-add-btn {
    padding: 1px 12px !important;
  }
  .cross-icon {
    height: 28px;
    width: 26px;
    background-color: #4E5156;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
export const StatusMenu = styled(Menu)`
  border-radius: 8px;
  .ant-dropdown-menu-item {
    line-height: normal;
    padding: 6px 12px;
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .dropdown-btn {
    height: 32px !important;
    font-size: 14px;
    line-height: 16.94px;
  }
  .ant-checkbox-wrapper {
    margin-right: 7px;
  }
  .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-inner {
    border-radius: 4px !important;
  }
  .ant-checkbox:not(.ant-checkbox-disabled).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2AC155 !important;
    border-color: #2AC155 !important;
    overflow: hidden;
  }

  .ant-checkbox:not(.ant-checkbox-disabled).ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff !important;
  }
  .ant-checkbox-wrapper-disabled .ant-checkbox-inner::after {
    border-color: #fff !important;
  }
  .ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(163, 164, 164, 0.5) !important;
  }
  ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #F9F9FB;
    }

    ::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 5px;
    }

  &.double {
    display: flex;
    padding: 0px;
    border-bottom: none;
    .main-container {
      background: #F9F9FB;
      height: fit-content;
      border: 1px solid rgba(220, 220, 220, 1);
      border-radius: 12px;
      .header {
        display: flex;
        .title {
          font-family: Inter;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          color: rgba(18, 18, 18, 1);
          margin: 10px 5px;
        }
        .bar-right {
          width: 30px;
          background: #eaf9ee;
          border-radius: 12px 0 0 0;
        }
        .bar-left {
          width: 30px;
          background: #e8e8ea;
          border-radius: 12px 0 0 0;
        }
        
      }
      .body {
        display: flex;
        .bar-right {
          width: 30px;
          background: #eaf9ee;
          border-radius: 0 0 0 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          .step {
            color: #A3A4A4;
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
          }
        }
        .bar-left {
          width: 30px;
          background: #e8e8ea !important;
          color: #A3A4A4;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          border-radius: 0 0 0 12px;
          padding-bottom: 10px;
        .step {
          color: #A3A4A4;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
        }
      }
      }
    }
  }
`;
export const StyledDropdownTable = styled(StyledIssuesTable)<{marginTop?: string; left?: string}>`
  margin-top: ${p => p?.marginTop ? p?.marginTop : 0} !important;
  .ant-table-tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    max-height: 26px !important;
    border-bottom: none !important;
    padding-left: 10px !important;
  }
  .ant-switch-checked {
    background-color: #2AC155;
  }
  table {
    .ant-table-thead {
      tr {
        th {
          padding: 6px 10px !important;
        }
      }
    }
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    left: ${p => p?.left ? p?.left : '42%'} !important;
  }
  .ant-spin-blur {
    opacity: 0.2 !important;
  }
  .comingSoon {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: #121212;
  }
`;
export const NlpTableWrapper = styled.div`
  max-height: 300px !important;
  height: 300px !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
            
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
    .ant-table-body {
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
            
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
`;
export const StatusWrapper = styled.div<{status: boolean; isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  ${p => p?.isDisabled ? 'cursor: not-allowed !important;': ''}
  span {
    color: ${p => p.status ? '#219843' : '#A3A4A4'};
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;

  }
`;
export const UrlWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  max-width: 260px;
  color: #2D6CCA;
  cursor: pointer;
  .source-delete-icon {
    display: none;
    cursor: pointer;
  }
  &:hover {
    .source-delete-icon {
      display: inline-block;
    }
  }
`;
export const UrlInternalLinkWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  max-width: 260px;
  color: #2D6CCA;
  .link {
    cursor: pointer;
  }
`;
export const CloudRefreshButton = styled.button`
  display: block;
  padding-left: 0;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #2D6CCA;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  span:hover {
    text-decoration: underline;
  }
`;
export const DescriptionWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-wrap;
  // text-align: justify;
  .icons-wrapper {
    svg {
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        color: #2D6CCA;
      }
    }
    .content-wrapper {
      display: flex;
      padding: 4px 9px 4px 0px;
      gap: 5px;
      border-radius: 4px;
      display: flex;
      background: #F9F9FB;
      border: 1px solid #E8E8E8;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
    }
  }
  .bar-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    .countStyling {
      font-size: 11px;
      font-family: 'Inter';
      font-weight: 400;
      line-height: 13.31px;
      color: #4E5156;
      margin-bottom: 0px;
    }
  }
`;

export const StyledButton = styled(ButtonComponent)`
  width: 86px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-size: 13px;
  span {
    display: inline-block;
    line-height: 15px !important;
  }
`;

export const MissingDescriptionWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  color: #A3A4A4;
`;

export const StyledCloudStackInput = styled(Input)`
  width: 260px;
  height: 26px;
  border-radius: 6px !important;
  border: 1px solid #CDCDCD;
  &:hover {
    border-color: #CDCDCD !important;
  }
  &:focus {
    border-color: #CDCDCD !important;
    box-shadow: none;
  }
  &::placeholder {
    font-size: 13px;
    font-weight: 500;
  }
`;

export const StyledInput = styled.textarea`
  font-family: 'Inter', sans-serif;
  text-align: justify;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #A3A4A4;
  width: 100%;

  &:focus-visible {
    border: 1px solid #A3A4A4;
    outline: none;
  }
`;

export const CloseButton = styled.div`
  width: 36px;
  height: 30px;
  border-radius: 4px;
  background-color: #4E5156;
  align-items: center;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const PaginationStyled = styled(Pagination)`
  padding: 20px 25px;  
  text-align: right;
  margin-top: 10px;

.ant-pagination-item{
  min-width: 25px !important;
  height:25px !important;
}
.ant-pagination-prev {
  width:25px !important;
  height:25px !important;
}
.ant-pagination-next{
  width:25px !important;
  height:25px !important;
}
.ant-pagination-disabled{
  min-width: 25px !important;
  height:25px !important;
}
.ant-pagination-item-link  span  svg {
  display:flex;
  margin-bottom:2px;
  align-items:center;
  justify-content:center;
}
.ant-pagination-prev{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-next{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-options{
  height:25px;
}
.ant-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top:10px;
  margin-right:15px;
  
}
.ant-pagination-total-text {
  height: 25px !important;
}
`;
export const PopUpWrapper = styled.div`
  width: 426px;
  height: fit-content;
  overflow: auto;
  max-height: 445px;
  scrollbar-width: thin;
  /* border: 2px solid red; */
  scrollbar-color:rgb(196, 196, 196) transparent;
  
  &::-webkit-scrollbar {
    margin-right: 40px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    margin-right: 40px;

    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    margin-right: 40px;

    background: #888;
    border-radius: 3px;
    min-height: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .container {
    width: 100%;
    overflow: auto;
    height: 100%;
    word-break: break-word;
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
  }
`;

export const ImagesDropdownWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 15px 14px;

  .container {
    max-height: 400px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #F9F9FB;
    }

    ::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 5px;
    }
  }
`;

export const UrlFilterInput = styled(Input)`
  max-width: 360px;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`;

export const StyledSelect = styled(Select)<{width?: string; height?: string; arrowMargin?: string}>`
  width: ${p => p?.width ? p?.width : '250px'} !important;
  ${p => p.height ? `height: ${p.height};` : ''}
  overflow: hidden;
  .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid #CDCDCD !important;
  }
  .ant-select-arrow {
    margin-top: ${p => p?.arrowMargin ? p?.arrowMargin : '-16px'};
    height: 32px;
    right: 2px;
    border-radius: 0px 6px 6px 0px;
    overflow: hidden;
    div {
      padding: 0px 6px;
    }
  }
  .ant-select-selection-item {
    max-width: 165px !important;
    overflow: hidden;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  gap: 10px;

  .image {
    height: 32px; 
    width: 32px; 
    border-radius: 6px; 
    cursor: pointer;
    border: 1px solid #bfbebe;
  }

  .container {
    position: relative;
    height: 68px;
    width: 125px;

    .image {
      position: absolute;
      width: 100%;
      height: 68px;
      border-radius: 6px; 
      cursor: pointer;
      border: 1px solid #bfbebe;
    }

    .hover-wrapper {
      border-radius: 6px; 
      position: absolute  ;
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      padding: 10px 20px;
      background-color: #0000005c;
      opacity: 0;
    }
  }

  .container:hover .hover-wrapper {
    opacity: 1;
  }


.url {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  max-width: 260px;
  color: #2D6CCA;
  line-height: normal;
  cursor: pointer;
}
.content-title {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #121212;
}
.redeem-url {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: #2D6CCA;
  cursor: pointer;
}
.dates-and-code-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  .dates-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    .date {
      display: flex;
      align-items: center;
      gap: 2px;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #4E5156;
      line-height: normal;

      .link{
        cursor: pointer;
        font-style: italic;
        color: #2D6CCA;
      }
    }
  }
  .code-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #4E5156;
    line-height: normal;
  }
}
.terms-and-conditions {
  display: flex;
  gap: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #4E5156;
}
`;

export const SitesWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 6px 5px 15px;
  min-width: 400px;
  .check-buttons {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    div {
      cursor: pointer;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #2D6CCA;
    }
  }
  .checkboxes-heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .info-banner {
    padding: 8px 14px;
    border-radius: 8px;
    background-color: rgba(45, 108, 202, 0.15);
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    div {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #121212;
      line-height: 16.94px;
    }
    a {
      font-size: 14px;
      margin-left: auto;
      font-weight: 500;
      line-height: 16.94px;
      color: #2D6CCA;
      border-bottom: 1px solid #2D6CCA;
    }
    .link-placeholder {
      margin-left: auto;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      color: #2D6CCA;
      border-bottom: 1px solid #2D6CCA;
      cursor: 'not-allowed';
    }
  }
  .sites-list {
    display: flex;
    max-width: 320px;
    flex-wrap: wrap;
    margin-left: 10px;
    column-gap: 46px;
    row-gap: 8px;
    .site {
      min-width: 137px;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #2AC155 !important;
        border-color: #2AC155 !important;
        border-radius: 4px !important;
        overflow: hidden;
      }
    }
    .pr-checkbox {
      .ant-checkbox:not(.ant-checkbox-disabled).ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #fff !important;
      }
      .ant-checkbox-wrapper-disabled .ant-checkbox-inner::after {
        border-color: #fff !important;
      }
      .ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner {
        background-color: rgba(163, 164, 164, 0.5) !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #d9d9d9 !important;
      }
    }
  }
  `;
export const CloudStackBanner = styled.div<{inProgress: boolean}>`
  margin: 0 5px;
  padding: 9px 14px;
    border-radius: 8px;
    background-color: ${p => p.inProgress ? '#FFF' : 'rgba(42, 193, 85, 0.15)'};
    margin-bottom: 5px;
    div {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 16.94px;
    }
    a {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      color: #2D6CCA;
      border-bottom: 1px solid #2D6CCA;
    }
    .banner-heading {
      display: flex;
      justify-content: space-between;
      .banner-title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
`;

export const RedirectWrapper = styled.div`
  .link-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
    border-bottom: 1px solid #E8E8E8;
    div {
      a:hover {
        text-decoration: underline;
      }
    }
    &:hover {
      border-radius: 8px;
      background-color: rgba(45, 108, 202, 10%) !important;
    }
  }
`;

export const StyledLink = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #121212;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const TableBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F2F5;
  border-radius: 8px;
  padding: 8px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  color: #121212;
  margin-bottom: 10px;
`;

export const ComingSoonTag = styled.div`
padding: 2px 8px 2px 8px;
border-radius: 17px;
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: right;
color: #4E5156;
background:#4E515626;
`;

export const WpButtonStyled = styled(Button)`
  height: 32px;
  padding: 8px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  &:hover {
    background: #7f4ead1a;
  }
  &:active {
    background: #7f4ead1a;
  }
  &:focus {
    background: #7f4ead1a;
  }
  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const StyledRatingSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 6px !important;
    min-width: 170px;
  }
  .ant-select-arrow {
    top: 46% !important;
  }
`;

export const OptionContent = styled.div`
  display: flex;
  gap: 8px;
  .content {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #121212;
    .review-wrapper {
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      color: #4E5156;
    }
    .ratings {
      font-size: 13px !important;
      .ant-rate-star {
        margin-right: 0px !important;
      }
      .ant-rate-star > div {
        .ant-rate-star-second {
          span {
            svg {
              width: 14px !important;
            }
          }
        }
      }
    }
  }
`;

export const LinkDeployBtn = styled.div<{color: String}>`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: ${p => p.color ? p.color : '#121212'};
  cursor: pointer;
`;

export const StyledTrashIcon = styled(FontAwesomeIcon)`
cursor: pointer;
&:hover {
  color: #F44343;
}
`;

export const KeywordWrapper = styled.div`
  .keyword-delete-icon {
    display: none;
    cursor: pointer;
  }
  &:hover {
    .keyword-delete-icon {
      display: inline-block;
    }
  }
`;

export const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  .date {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
  .refresh-button {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #2D6CCA;
    cursor: pointer;
  }
  .text-nowrap {
    white-space: nowrap;
  }
`;

export const CustomContentHTML = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #4E5156;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CustomContentHTMLKey = styled.span`
  font-weight: 600;
  color: #121212;
`;

export const DeleteColumn = styled.div`
  font-size: 18px;
  margin-left: 5px;
`;
export const ErrorDiv = styled.div`
  color: #F44343;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
  padding-left: 2px;
`;
export const DeleteButton = styled(Button)`
  background-color: #ff6262;
  border: 1px solid #ff6262;
  border-radius: 5px;
  color: #fff;
  font-weight: 400;
  width: 25%;
    &:hover{
      background: transparent;
      color: #323134; 
    }
`;
export const BackButton = styled(Button)`
  background-color: #fff;
  border-color: #696777;
  margin-top: 8px;
  width: 25%;
  color: #696777;
`;
export const ModalWrapper = styled.div`
 
  align-items: center;
  color: $color-gray-dark;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 17px;
  padding: 20px 0;

  .warningText {
    color: $color-secondary-text;
  }

`;

export const Wrapper = styled.div`
  .customConfirmationModal {
  position: relative;

  .rc-dialog-content {
   .rc-dialog-close {
      color: #fff;
      opacity: 0.8 !important;
      font-size: 40px;
      right: -50px;
      top: -14px;
    }
  }
}
`;

export const InputDomainWrapper = styled.div`
display: flex;
align-items: center;
border: 1px solid #a4a3a366;
border-radius: 8px;
width: 100%;
.domain {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #A3A4A4;
  padding-left: 10px;
}
.ant-input{
  border: none !important;
  padding-left: 0px;
}
.anchor{
  padding-left: 10px !important;
}
.slug {
  padding: 5px 0px;
}
`;
export const ComplaintTag = styled.div`
  background-color: rgba(42, 193, 85, 0.16);
  font-family: 'Inter', sans-serif;
  padding: 4px 6px;
  border-radius: 37px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  color: #268B43;
`;
export const Banner = styled.div`
  background: rgba(45, 108, 202, 0.2);
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0px 0px 12px;
  .open-knowledge-graph {
    color: rgb(45, 108, 202);
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (max-width: 1250px) {
    flex-wrap: wrap;
    row-gap: 0;
  }
`;

export const GbpDeleteIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  &:hover {
    color: #F44343 !important;
  }
`;

export const ReviewerName = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #121212;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledReviewsRating = styled(Rate)`
  font-size: 13px !important;
  white-space: nowrap;
  .ant-rate-star {
    margin-right: 0px !important;
    &.ant-rate-star-zero {
      display: none;
    }
  }
  .ant-rate-star > div {
    .ant-rate-star-second {
      span {
        svg {
          width: 14px !important;
        }
      }
    }
  }
`;

export const StatusContainer = styled.div`
  background-color: #FFFF;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
  border-radius: 8px;
  .ant-dropdown-menu-item {
    line-height: normal;
    padding: 6px 12px;
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .dropdown-btn {
    height: 32px !important;
    font-size: 14px;
    line-height: 16.94px;
  }
  .ant-checkbox-wrapper {
    margin-right: 7px;
  }
  .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-inner {
    border-radius: 4px !important;
  }
  .ant-checkbox:not(.ant-checkbox-disabled).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2AC155 !important;
    border-color: #2AC155 !important;
    overflow: hidden;
  }

  .ant-checkbox:not(.ant-checkbox-disabled).ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff !important;
  }
  .ant-checkbox-wrapper-disabled .ant-checkbox-inner::after {
    border-color: #fff !important;
  }
  .ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(163, 164, 164, 0.5) !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 10px;
    .status {
      display: flex;
      align-items: center;
      gap: 10px;
      .heading {
        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.73px;
        color: #121212;
      }
      .button-wrap {
        display: flex;
        align-items: center;
        gap: 10px;
        .button {
          cursor: pointer; 
          font-family: Inter;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.73px;
          display: flex;
          gap: 4px;
        }
        .red {
          color: #F44343;
        }
        .green {
          color: #219843;
        }
      }
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      .blue-button {
        cursor: pointer; 
        background-color: rgba(45, 108, 202, 0.15);
        border-radius: 6px;
        padding: 4px 10px;
        color: #2D6CCA; 
        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.73px;
      }
    }
  }
`;

export const TypeWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  border: 1px solid #D0D0D0;
  padding: 8px 12px;
  border-radius: 6px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #121212;
  margin: 8px 0px;
`;

export const FieldContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  margin: 8px 0px;

  &.max-with-33 {
    max-width: 33.3%;
  }

  .label {
    width: fit-content;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #4E5156;
  }

  .input {
    width: 294px;
    padding: 5px 10px;
    border-radius: 6px;

    &.auto-width {
      width: auto;
    }
  }
`;
export const StatusDropdown = styled.div<{backgroundColor: string; textColor: string}>`
  padding: 6px 12px;
  background-color: ${p => p.backgroundColor};
  border-radius: 8px;
  width: fit-content;
  .status-text {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: ${p => p.textColor};
    line-height: normal;
    .fa-sort-down {
      margin-top: -4px;
    }
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #2D6CCA !important;
  }
  .ant-radio-inner::after {
    background-color: #2D6CCA !important;
  }
`;

export const FooterSection = styled.div`
  margin-top: 23px;
  padding: 15px 18px;
  background: #F9F9FB;
  border-top: 1px solid #DCDCDC;
  .credits {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;

  }
  .label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #4E5156;
  }
  .value {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: right;
    color: #121212;
  }
  .divider {
    width: 250px;
    height: 1px;
    margin: 15px 0px;
    background-color: #D9D9D9;
  }
`;

export const Section = styled.div<{addCssProperties?: boolean}>`
${p => p?.addCssProperties ? `
    box-shadow: 0px 3px 2px 0px #00000005;
    border: 1px solid #E8E8E8;
    background: #FFFFFF;
    padding: 20px 10px 10px 10px;
    border-radius: 12px;
    margin-bottom: 22px;
  ` : ''}
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-switch-checked {
    background-color: #2AC155;
  }
  /* .switch-button {
      background-color: #2AC155;
    } */
  .ant-switch-handle {
    &::before {
      background-color: #fff;
    }
  }
  .enabled-disabled-txt {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
    color: #A3A4A4;
  }
  .add-green-color {
    color: #18923B !important;
  }`;

export const StyledReactQuill = styled(ReactQuill)<{editorError: boolean}>`
  .ql-container {
    background-color: #fff;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-color: ${p => p.editorError ? '#ff4d4f' : '#E0E0E0'};
    overflow: hidden;
    .ql-editor {
      overflow-y: auto;
      resize: vertical;
    }
  }
  .ql-toolbar {
    background-color: #fff;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-color: ${p => p.editorError ? '#ff4d4f' : '#E0E0E0'};
  }

  .ql-tooltip {
    z-index: 1;
  }
`;

export const MessagesCount = styled.div`
  width: fit-content;
  height: 16px;
  padding: 4px 6px 4px 6px;
  border-radius: 31px;
  background: #F44343;
  font-size: 10px;
  color: #FFFFFF;
  font-weight: 600;
  display: flex;
  line-height: normal;
  align-items: center;
`;
export const BulkArticleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  box-shadow: 0px 3px 6px 0px #0000001A;
  border: 1px solid #A3A4A459;
  border-radius: 10px;
  background-color: #F2F2F5;
  margin-top: 15px;
  .left-wrapper {
    display: flex;
    align-items: center;
    gap: 14px;
    .selected-count {
      display: flex;
      align-items: center;
      gap: 6px;
      padding-right: 10px;
      border-right: 1px solid #A3A4A480;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      div {
        font-weight: 500;
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
      .select-all {
        color: #2D6CCA;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
      }
      .deselect-all {
        color: #F44343;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
  .right-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    .cross-icon {
      cursor: pointer;
    }
  }
`;
export const PurpleCheckbox = styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #7F4EAD !important;
    }
    .ant-checkbox-inner {
      border-radius: 4px !important;
      &::after {
        top: 42%;
      }
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #7F4EAD;
        border-color: #7F4EAD;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #7F4EAD !important;
      }
    }
  }
`;

export const ViewButton = styled.div`
width: 178px;
cursor: pointer;
display: flex;
align-items: center;
gap: 6px;
border-radius: 6px;
padding: 8px 12px;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-weight: 400;
color: #7F4EAD;
background-color: rgba(127, 78, 173, 0.15);
line-height: normal;
`;
export const GenerateArticleMenu = styled(Menu)`
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px #00000040;
  border: 1px solid #D2D2D2;
  background-color: #FFFFFF;
  overflow: hidden;
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      gap: 9px;
    }
  }
`;

export const LandingPageButton = styled.button<{disabled?: boolean}>`
  width: 68px;
  height: 27px;
  border-radius: 6px;
  border: 1px solid #D2D2D2;
  background: #F9F9FB;
  outline: none !important;
  color: #4E5156;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
`;

export const ChannelStatus = styled.div<{background: string; color: string}>`
  display: flex;
  align-items: center;
  gap: 7px;
  width: fit-content;
  height: 24px;
  border-radius: 8px;
  padding: 5px 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  background-color: ${p => p.background};
  color: ${p => p.color};
`;
export const TopicalMapStepsWrapper = styled.div`
position: relative;
border: 1px solid #DCDCDC;
border-radius: 8px;
background-color: #F9F9FB;
overflow: hidden;
margin-top: 8px;
width: fit-content;
.steps {
  display: flex;
  max-height: 34px;
}
.section {
  flex-grow: 1;
  padding: 4px 6px;
  border-bottom: 2px solid #e8e8e8 !important;
  display: flex;
  align-items: center;
  gap: 8px;

  &.active-section {
    z-index: 1;
    border-color: #7F4EAD !important;
  }
  &.disabled-btn {
    border-color: #cccccc !important;
  }
  &.restricted {
    cursor: default;
  }
  .step {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #4e5156;

    &.active {
      font-weight: 500;
      color: #121212;
    }
  }
}
`;
export const PluginButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 8px 2px 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  background: #4E5156;
  border-radius: 6px;
  color: #121212;
  padding: 9px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-left: 3px;

  .dot {
    border-radius: 50%;
    height: 6px;
    margin-right: 10px;
    width: 6px;
  }
`;
export const GrayViewButton = styled(Button)<{
  styleObject?: {
    bgColor?: string;
    borderRadius?: string;
    padding?: string;
    border?: string;
    color?: string;
    bgHoverColor?: string;
    fontSize?: string | number;
    fontWeight?: number;
    width?: string | number;
  };
  disabled?: boolean;
  }>`
  font-family: 'Inter', sans-serif;
  background: ${p => p?.styleObject?.bgColor ? p.styleObject?.bgColor : '#F9F9FB'};
  border-radius: ${p => p?.styleObject?.borderRadius ? p.styleObject.borderRadius : '4px'};
  padding: ${p => p?.styleObject?.padding ? p.styleObject?.padding : '7px 12px'};
  border: ${p => p?.styleObject?.border ? p.styleObject.border : '1px solid #E8E8E8'} !important;
  color: ${p => p?.styleObject?.color ? p.styleObject.color : '#121212'};
  font-size: ${p => p?.styleObject?.fontSize ? p.styleObject.fontSize : '16px'};
  font-weight: ${p => p?.styleObject?.fontWeight ? p.styleObject.fontWeight : 400};
  cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'} !important;
  pointer-events: ${p => p?.disabled && 'auto'} !important;
  width: ${p => p?.styleObject?.width} !important;
  &:hover {
    background-color: ${p => p?.styleObject?.bgHoverColor ? p.styleObject.bgHoverColor : '#F9F9FB'};
  }
`;

export const CopyViewLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 10px 30px 18px 30px;
  div {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
    color: #2D6CCA;
    cursor: pointer;
  }
`;
export const StyledEmpty = styled(Empty)`
  position: absolute;
`;

export const DropdownButtonWrapper = styled.div<{filterIsActive?: boolean}>`
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  background: ${p => p?.filterIsActive ? 'rgba(127, 78, 173, 0.17)': ''};
  cursor: pointer;
  .container {
    color: ${p => p?.filterIsActive ? '#000000': '#A3A4A4'};
    max-width: 127px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
`;

export const ToggleAutomated = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  background: #18923B26;
  padding-left: 7px;
  padding-right: 12px;
  .ant-switch {
    background-color: #f44343;
    &.ant-switch-checked {
      background: #18923B;
    }
  }
  .custom-divider {
    margin: 0px 5px;
    width: 1px;
    height: 14px;
    background-color: #C1C1C1;
  }
  .text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #121212;
  }
  .settingsBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .setings {
    width: 14px;
    height: 14px;
  }
`;
export const AddKeywordsWrapper = styled.div`
  background-color: rgba(45, 108, 202, 12%);
  padding: 12px 14px;
  border-radius: 8px;
  .inner-wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    min-width: 150px;
    .title {
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      padding-left: 13px !important;
      color: #4E5156;
    }
    .input-wrapper {
      border-radius: 6px;
      border: 1px solid #D2D2D2;
      width: 100%;
      background-color: #fff;
      padding: 6px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .keywords-wrapper {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        .keyword {
          padding: 4px 10px;
          background-color: #E6E6E6;
          border-radius: 18px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      .input {
        border: none;
      }
    }
    .keyword-error {
      width: 100%;
      margin-top: -6px;
      margin-left: 4px;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #F44343;
    }
    .buttons-wrapper {
      display: flex;
      align-items: center;
      gap: 26px;
      .cancel-button {
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer !important;
        color: #4E5156;
      }
    }
  }
`;
