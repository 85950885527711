import {Button, DatePicker, Input, Menu, Radio} from 'antd';
import styled from 'styled-components';
import {Modal} from '@/components/common-components/components';
import {Modal as AntModal} from 'antd';

export const TutorialsContainer = styled.div`
display: flex;
flex-wrap: wrap;
gap: 15px;
justify-content: space-between;
`;
export const StyledThumbnail = styled.img`
  width: 175px;
  height: 98px;
  left: 398px;
  top: 2324px;
  border-radius: 8px;
`;
export const StyledTitle = styled.div`
  width: 175px;
  height: 36px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4E5156;
  margin-top: 11px;
`;
export const PlayBtnStyled = styled.div`
  position: absolute;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: end;
  top: 0;
  z-index: 3;
  right: 0;
  padding: 7px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.35) 100%);
  height: 100%;
  width: 100%;
  
  img {
    margin-right: 72px;
    margin-top: 25px;
  }

`;


export const ProjectName = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
`;

export const DropdownContainer = styled.div<{boxShadow?: string}>`
  ${p => p?.boxShadow ? `box-shadow: ${p?.boxShadow};` : ''}
  width: 320px;
  border-radius: 12px;
  background: #F9F9FB;
  padding: 14px 12px;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .total-otto-sites {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.52px;
      text-align: left;
      color: #121212;
    }
    .add-new-side {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: right;
      color: #2D6CCA;
      cursor: pointer;
    }
  }
  .project-list::-webkit-scrollbar {
   width: 5px;
   height: 5px;
  }

  .project-list::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  .project-list::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
  .project-list {
    margin-top: 11px;
    max-height: 152px;
    overflow-y: auto;
    .project-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 32px;
      padding: 5px 6px;
      border-radius: 8px;
      cursor: pointer;
      .project-image-name {
        display: flex;
        align-items: center;
        gap: 9px;
      }
      .selected {
        margin-right: 8px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: right;
        color: rgba(78, 81, 86, 1);
      }
    }
    .project-container:hover {
      background-color: rgba(163, 164, 164, 0.2);
    }
    .load-more {
      display: flex;
      justify-content: center;
      .load-more-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: transparent;
        border: 0px;
        color: #2D6CCA;
        cursor: pointer;
      }
    }
  }
`;

export const OttoProjectSearchInput = styled(Input)`
  width: 300px;
  height: 32px;
  margin-top: 9px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 5px;
`;

export const BoldText = styled.div`
  color: #121212;
  font-weight: 500;
  font-size: 14px;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledWidgetModal = styled(Modal)<{removePadding?: boolean}>`
  .rc-dialog-content {
    .rc-dialog-body {
      border-radius: 10px;
      overflow: hidden !important;
      padding: 0px !important;
      .modal-video {
        height: 230px;
        background-color: #4e0db1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .content-wrapper {
        ${p => p?.removePadding ? '' : 'padding: 25px 25px;'}
        .body-heading {
          font-size: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          margin-bottom: 10px;
          color: #121212;
        }
        .site {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #121212;
          margin-bottom: 10px;
        }
        .inputs-wrapper {
          margin-bottom: 8px;
          .label-wrapper {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            margin-bottom: 6px;
            .label {
              color: #121212;
            }
            .heading {
              color: #2D6CCA;
              cursor: pointer;
            }

          }
          .domain-input {
            padding: 10px 11px !important;
            color: #A3A4A4 !important;
            border-radius: 8px !important;
          }
          .snippet-wrapper {
            border-radius: 8px;
            background-color: #F2F2F5;
            padding: 14px 12px;

            .snippet-buttons {
              display: flex;
              align-items: center;
              gap: 20px;
              button {
                margin: 0px !important;
              }
              .email-button {
                display: flex;
                align-items: center;
                gap: 6px; color: #2D6CCA;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
                :hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .website-wrapper {
          margin-bottom: 12px;
          width: 100%;
          display: flex !important;
          align-items: center;
          justify-content: space-between !important;
          .label {
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            color: #121212;
            margin-bottom: 6px;
          }
          .domain-input {
            padding: 10px 11px !important;
            border-radius: 12px !important;
            width: 100%;
            height: 38px;
          }
        }

        .embed-successfully {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background-color: rgba(42, 193, 85, 0.15);
          border-radius: 6px;
          margin-bottom: 6px;
          gap: 4px;

          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;
          }
        }
        .not-embed {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background-color: rgba(244, 67, 67, 0.15);
          border-radius: 6px;
          gap: 4px;
          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;
            gap: 2px;

            .check-again-button {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 12px;
              font-family: 'Inter', sans-serif;
              color: #2D6CCA;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .already-embed {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          border: 1px solid #E8E8E8;
          border-radius: 6px;
          margin-bottom: 6px;
          gap: 4px;

          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;

            .link {
              color: #2D6CCA;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .banner {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            background-color: rgba(45, 108, 202, 0.14);
            margin-bottom: 10px;
            span {
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-weight: 400;
            }
          }
        .steps-wrapper {
          position: relative;
          margin-bottom: 8px;

          .steps {
            display: flex;
          }

          .border-line {
            bottom: 3px;
            position: relative;
            width: 100%;
            height: 2px;
            border-bottom: 2px solid #e8e8e8 !important;
          }
          .section {
            flex-grow: 1;
            cursor: pointer;

            &.active-section {
              z-index: 1;
              padding-bottom: 8px;
              border-bottom: 4px solid rgba(45, 108, 202, 1);
            }
            &.restricted {
              cursor: default;
            }
            .step {
              font-size: 12px;
              line-height: 14px;
              font-weight: 400;
              color: #4e5156;

              &.active {
                font-weight: 500;
                color: #121212;
              }
            }
          }
        }
      }
    }
    
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.8;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
  .freeze-banner {
    .txt {
      font-size: 13px !important;
    }
  }
`;

export const AddServiceAccountButton = styled(Button)<{disabled?: boolean}>`
  /* width: 190px; */
  height: 28px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #2D6CCA !important;
  color: #FFFFFF !important;
  ${p => p?.disabled ? `
    cursor: not-allowed !important;
    opacity: 50%;
  ` : ``}
  &:hover, &:focus {
    background: #2D6CCA;
    color: #FFFFFF;
  }
`;

export const AddServiceAccountModal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .control {
    position: absolute;
    top: 0;
    width: 550px;
    height: 250px;
    border-radius: 12px 12px 0px 0px;
    background: #07172480;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
      width: 66px;
      height: 66px;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
`;

export const EnableIndexingAccessCont = styled.div`
  padding: 30px;
  .heading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color:  #121212;
  }
  .desc {
    margin-top: 9px;
    max-width: 440px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #121212;
  }
  .label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.96px;
    text-align: left;
    color: #121212;
  }
  .copy-address-container {
    margin-top: 27px;
    .address-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 9px;
      height: 36px;
      border-radius: 8px;
      border: 1px solid #D8D8D8;
      .txt {
        margin-left: 12px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        color: #4E5156;
      }
      .copy {
        margin-right: 11px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        text-align: left;
        color: #2D6CCA;
        cursor: pointer;
      }
    }
  }
  .add-to-gsc-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .left-sec{
      max-width: 370px;
      .point-2-desc {
        margin-top: 5px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: #4E5156;
      }
      .bold {
        font-family: Inter;
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 16.94px !important;
        text-align: left !important;
        color: #121212 !important;
        }
    }
    .open-gsc-button {
      width: 106px;
      height: 32px;
      border-radius: 8px;
      background: #2D6CCA26;
      border: 0px;
      color: #2D6CCA;
      cursor: pointer;
    }
  }
  .footer {
    margin-top: 39px;
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    .cancel-button {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #4E5156;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }
  }
`;

export const VerifyButton = styled(Button)`
  width: 200px;
  height: 44px;
  border-radius: 8px;
  background-color: #2D6CCA;
  color: #FFFFFF;
  &:hover, &:focus {
    background-color: #2D6CCA;
    color: #FFFFFF;
  }
`;

export const OverlayContainer = styled(Menu)`
  border-radius: 12px;
  &:hover, &:focus {
    border-radius: 12px !important;
  }
  .ant-dropdown-menu-item {
    border-radius: 12px !important;
  }

  .menu {
    cursor: pointer;
    svg {
      width: 4.15px;
      height: 16px;
    }
  }
`;

export const DeepFreezeSiteModal = styled(AntModal)`
 .ant-modal-content {
  border-radius: 12px;
 }

`;

export const BoldTitle = styled.div`
  margin-top: 21px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  color: #121212;
`;

export const PriceSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;

  .price {
    margin-right: 5px;
    font-family: Inter;
    font-size: 42px;
    font-weight: 700;
    line-height: 50.83px;
    text-align: left;
    color: #121212;
  }
  .currency {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #121212;
  }
  .desc {
    display: flex;
    align-items: center;
    gap: 7px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #4E5156;
  }
  .discount {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 180px;
    height: 19px;
    border-radius: 25px;
    background: #21984326;
    color: #167431;
  }
`;

export const PointLabel = styled.div`
  margin-top: 22px;
  margin-bottom: 7px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #121212;
`;

export const SubPoints = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 5px;
  align-items: flex-start;
  .icon {
    svg {
      width: 16px;
      height: 16px;
    }
    margin-top: 2px;
  }
  .label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #4E5156;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;
  margin-top: 20px;
  .cancel-button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #4E5156;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }
`;

export const DeepFreezeButton = styled(Button)`
  width: 199px;
  height: 44px;
  border-radius: 8px;
  background: #2D6CCA !important;
  color: #FFFFFF !important;
  &:disabled {
    opacity: 60%;
  }
`;

export const SettingsModal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .heading {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color: #121212;
  }
  .desc {
    margin-top: 4px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #4E5156;
  }
  .fields-container {
    margin-top: 15px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 36px;
    margin-top: 81px;
    .cancel-button {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #4E5156;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }
    .update-button {
      width: 156px;
      height: 44px;
      border-radius: 8px;
      background-color: #2D6CCA !important;
      border: 0px !important;
      color: #FFFFFF !important;
    }
  }
  .close-icon {
    width: 19px;
    height: 19px;
    position: absolute;
    right: -30px;
    top: 0;
    cursor: pointer;
  }
`;

export const FieldsContainer = styled.div`
  .field-label {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-top: 7px;
    svg {
      width: 18px;
      height: 18px;
    }
    .text {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #121212;
    }
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  width: 530px;
  height: fit-content;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  .ant-radio-wrapper {
    width: 100%;
    border-radius: 10px;
    padding: 11px 13px;
  }
  .selected {
    background-color: #2D6CCA14;
    border: 1px solid #2D6CCA;
  }
  .ant-radio-checked::after {
    border: 1px solid #2D6CCA;
  }
  .ant-radio-inner::after {
    background-color: #2D6CCA;
  }
  .radio-heading-active {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #121212;
  }
  .radio-heading {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #4E5156;
  }
  .radio-desc {
    margin-top: 2px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #4E5156;
  }
  span.ant-radio + * {
    width: 100%;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 170px;
  height: 30px;
  margin-top: 7px;
  border-radius: 6px;
  border: 1px solid #C9C9C9;
`;

export const StyledTextArea = styled(Input.TextArea)`
  width: 530px;
  height: 120px;
  border-radius: 10px;
`;

export const UpgradeButton = styled.button`
outline: none;
border: none;
background: transparent;
cursor: pointer;
color: #2D6CCA;
`;
