import React from 'react';
import ImagePicker from './imagePicker';
import styled from 'styled-components';
import {Button,
} from 'antd';
import {useStore} from '@/store/root-store';
import {notification} from '@/utils/notification-v2';

export const ImagesModal = ({images, setImages, updateImages, setModalLoader, showImageType}) => {
  const types = ['COVER', 'PROFILE', 'ADDITIONAL'];

  const {
    ottoV2Store: {ottoGBPUploadImage, ottoGBPUploadVideo},
  } = useStore('');

  const handleImagesUpdate = async () => {
    const errorUrls = [];
    const processImage = async (key, item) => {
      if (Array.isArray(item)) {
        const updatedItemsArray = [];
        for (let [index, subItem] of Object.entries(item)) {
          if (subItem?.url && typeof subItem.url !== 'string') {
            if (subItem?.format == 'VIDEO') {
              const formData = new FormData();
              formData.append('file', subItem.url);
              try {
                const response = await ottoGBPUploadVideo(formData);
                subItem.url = response?.url;
              } catch (error) {
                errorUrls?.push(+index);
                subItem = null;
              }
            } else {
              const formData = new FormData();
              formData.append('image', subItem.url);
              formData.append('format', 'JPEG');
              key=='COVER' && formData.append('aspect_ratio', '1.777778');
              try {
                const response = await ottoGBPUploadImage(formData);
                subItem.url = response;
              } catch (error) {
                errorUrls?.push(+index);
                notification.error('Failed to upload image:', error.message);
              }
            }
          } else if (!subItem?.url && subItem?.format === 'VIDEO') {
            errorUrls?.push(+index);
          }
          subItem && updatedItemsArray.push(subItem);
        }
        images[key] = updatedItemsArray;
      } else if (item?.url && typeof item.url !== 'string') {
        const formData = new FormData();
        formData.append('image', item.url);
        formData.append('format', 'JPEG');
        key=='COVER' && formData.append('aspect_ratio', '1.777778');
        try {
          const response = await ottoGBPUploadImage(formData);
          images[key].url = response;
        } catch (error) {
          notification.error('Failed to upload media:', error.message);
        }
      }
    };

    const processAllImages = async () => {
      setModalLoader(true);
      const entries = Object.entries(images);

      for (const [key, item] of entries) {
        await processImage(key, item);
      }
      if (errorUrls?.length) {
        setModalLoader(false);
        return;
      } else {
        await updateImages(images);
      }
    };
    processAllImages();
  };

  return (
    <>
      <StyledContainer>
        <ImagePicker
          setImages={setImages} images={images} types={types} showImageType={showImageType}/>
      </StyledContainer>
      <ButtonContainer>
        <StyledButton
          onClick={handleImagesUpdate}>Save</StyledButton>
      </ButtonContainer>
    </>
  );
};

const StyledContainer = styled.div`
  max-height: 650px;
  overflow: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 0px 20px;
`;

const StyledButton = styled(Button)`
  background-color: #2D6CCA;
  color: white;
  padding: 14px 25px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;

  &:hover, &:focus {
    background-color: #2D6CCA;
    border: none;
    color: white;
  }

  &:disabled,
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus {
    background-color: #2D6CCA;
    border: none;
    color: white;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

