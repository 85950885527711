import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import ReactEcharts from 'echarts-for-react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import {useEffect, useState} from 'react';
import {Button, Checkbox, Spin} from 'antd';
import {fbImage} from '@/utils/icons';
import {SkeletonHorizontalLoaderGray, TabPane, Tabs} from '@/components/common-components';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import {faAngleRight, faX} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {customNumberWithCommas, formatLargeNumber} from '@/utils/number';
import moment from 'moment';
import {getFormatedDate} from '@/utils/moment';
import {LoadingOutlined} from '@ant-design/icons';
import {getSingleUrlParam} from '@/utils/url';


export const ImpressionsFB = observer(() => {
  const {reportBuilder: {details: {loadingFbAge, loadingFbGender, reportsFbListAge, reportsFbListGender}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={{height: '126px'}}>
    {loadingFbAge || loadingFbGender ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><span>{fbImage()}Impressions</span></p>
        <Values>{(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['impressions']? customNumberWithCommas(Number((reportsFbListAge || reportsFbListGender)['totals']['impressions'])) : '-'}</Values>
      </div>}
  </div>;
});

export const ClicksFB = observer(() => {
  const {reportBuilder: {details: {loadingFbAge, loadingFbGender, reportsFbListAge, reportsFbListGender}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={{height: '126px'}}>
    {loadingFbAge || loadingFbGender ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><span>{fbImage()}CPC</span></p>
        <Values>{(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['cpc'] ? '$' : ''}
          {(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['cpc']? Number((reportsFbListAge || reportsFbListGender)['totals']['cpc'])?.toFixed(2) : '-'}</Values>
      </div>}
  </div>;
});

export const AverageCpcFB = observer(() => {
  const {reportBuilder: {details: {loadingFbAge, loadingFbGender, reportsFbListAge, reportsFbListGender}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={{height: '126px'}}>
    {loadingFbAge || loadingFbGender ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><span>{fbImage()}CTR</span></p>
        <Values>{(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['ctr']? Number((reportsFbListAge || reportsFbListGender)['totals']['ctr'])?.toFixed(2) + '%' : '-'}</Values>
      </div>}
  </div>;
});

export const VTConversionFB = observer(() => {
  const {reportBuilder: {details: {loadingFbAge, loadingFbGender, reportsFbListAge, reportsFbListGender}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={{height: '126px'}}>
    {loadingFbAge || loadingFbGender ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><span style={{width: '215px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{fbImage()}Clicks</span></p>
        <Values>{(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['clicks']? customNumberWithCommas(Number((reportsFbListAge || reportsFbListGender)['totals']['clicks'])) : '-'}</Values>
      </div>}
  </div>;
});


export const CostFB = observer(() => {
  const {reportBuilder: {details: {loadingFbAge, loadingFbGender, reportsFbListAge, reportsFbListGender}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={{height: '126px'}}>
    {loadingFbAge || loadingFbGender ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><span>{fbImage()}Frequency</span></p>
        <Values>{(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['frequency'] &&
        (reportsFbListAge || reportsFbListGender)['totals']['frequency']? Number((reportsFbListAge || reportsFbListGender)['totals']['frequency'])?.toFixed(2) : '-'}</Values>
      </div>}
  </div>;
});

export const ConversionFB = observer(() => {
  const {reportBuilder: {details: {loadingFbAge, loadingFbGender, reportsFbListAge, reportsFbListGender}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`} style={{height: '126px'}}>
    {loadingFbAge || loadingFbGender ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{width: '100%'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><span>{fbImage()}Spend</span></p>
        <Values>{(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['spend'] ? '$' : ''}
          {(reportsFbListAge || reportsFbListGender) && (reportsFbListAge || reportsFbListGender)['totals'] && (reportsFbListAge || reportsFbListGender)['totals']['spend'] && (reportsFbListAge || reportsFbListGender)['totals']['spend']? Number((reportsFbListAge || reportsFbListGender)['totals']['spend']) : '-'}</Values>
      </div>}
  </div>;
});

export const FbAge = observer(() => {
  const {reportBuilder: {details: {isConfig, reportsFbListAge, loadingFbAge}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const [activeSeries, setActiveSeries] = useState({
    impressions: true,
    cpc: true,
    ctr: true,
    clicks: true,
    spend: true,
  });

  const hideAndShowSeries = key => {
    setActiveSeries({...activeSeries, [key]: !activeSeries[key]});
  };

  const options = {
    grid: {
      left: '5%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {show: false},
        data: reportsFbListAge ? reportsFbListAge['data']?.map(item=> item.age) : [],
      },
    ],
    yAxis: [
      {
        show: false,
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Impressions',
        type: 'bar',
        barGap: 0.5,
        color: '#2D6CCA',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [20, 20, 0, 0],
        },
        data: activeSeries.impressions ? reportsFbListAge ? reportsFbListAge['data']?.map(item=> item.impressions) : [] : [],
      },
      {
        name: 'CPC',
        type: 'bar',
        color: '#34AEF3',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [20, 20, 0, 0],
        },
        data: activeSeries.cpc ? reportsFbListAge ? reportsFbListAge['data']?.map(item=> item.cpc) : [] : [],
      },
      {
        name: 'CTR',
        type: 'bar',
        color: '#66DBEA',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [20, 20, 0, 0],
        },
        data: activeSeries.ctr ? reportsFbListAge ? reportsFbListAge['data']?.map(item=> item.ctr?.toFixed(2)) : [] : [],
      },
      {
        name: 'Clicks',
        type: 'bar',
        color: '#7F4EAD',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [20, 20, 0, 0],
        },
        data: activeSeries.clicks ? reportsFbListAge ? reportsFbListAge['data']?.map(item=> item.clicks) : [] : [],
      },
      {
        name: 'Spend',
        type: 'bar',
        color: '#B98CE4',
        barWidth: 10,
        itemStyle: {
          borderRadius: [20, 20, 0, 0],
        },
        emphasis: {
          focus: 'series',
        },
        data: activeSeries.spend ? reportsFbListAge ? reportsFbListAge['data']?.map(item=> item.spend) : [] : [],
      },
    ],
  };

  const legendsData = [
    {keyName: 'impressions', color: '#2D6CCA', name: 'Impressions'},
    {keyName: 'cpc', color: '#34AEF3', name: 'CPC'},
    {keyName: 'ctr', color: '#66DBEA', name: 'CTR'},
    {keyName: 'clicks', color: '#7F4EAD', name: 'Clicks'},
    {keyName: 'spend', color: '#B98CE4', name: 'Spend'},
  ];

  return isLoaded ? <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{fbImage()}Age</Title></div>
    {reportsFbListAge && reportsFbListAge['data']?.length ?<>
      <div style={{display: 'flex'}}>
        {legendsData?.map((item, index) => {
          return <div key={index} className={classnames(styles.legends, styles.legendsTop, !activeSeries[item.keyName] && styles.legendsInactive)} onClick={() => hideAndShowSeries(item.keyName)}>
            <div className={styles.left}>
              <div className={styles.legendTitle} style={{lineHeight: '22px', whiteSpace: 'nowrap', marginLeft: '20px', color: 'black'}}><CheckboxStyled color={item?.color} defaultChecked={true} checked={activeSeries[item.keyName]}></CheckboxStyled>{item.name}</div>
            </div>
          </div>;
        })}
      </div>
      <ReactEcharts style={{width: '100%', height: '400px'}}
        option={options}
      /></> : <NoData>{loadingFbAge ? <Spin indicator={<LoadingOutlined style={{color: '#4e5156', fontSize: '15px'}} spin/>}/> : 'No Data'}</NoData>}
  </Wrapper> : null;
});

export const FbGender = observer(() => {
  const {reportBuilder: {details: {isConfig, reportsFbListGender, loadingFbGender}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const [activeSeries, setActiveSeries] = useState({
    impressions: true,
    cpc: true,
    ctr: true,
    clicks: true,
    spend: true,
  });

  const hideAndShowSeries = key => {
    setActiveSeries({...activeSeries, [key]: !activeSeries[key]});
  };

  const options = {
    grid: {
      left: '13%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        show: false,
        type: 'value',
        axisTick: {show: false},
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: reportsFbListGender ? reportsFbListGender['data']?.map(item=> item.gender) : [],
      },
    ],
    series: [
      {
        name: 'Impressions',
        type: 'bar',
        barGap: 0.5,
        color: '#2D6CCA',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [0, 20, 20, 0],
        },
        data: activeSeries.impressions && reportsFbListGender ? reportsFbListGender['data']?.map(item=> item.impressions) : [],
      },
      {
        name: 'CPC',
        type: 'bar',
        color: '#34AEF3',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [0, 20, 20, 0],
        },
        data: activeSeries.cpc && reportsFbListGender ? reportsFbListGender['data']?.map(item=> item.cpc) : [],
      },
      {
        name: 'CTR',
        type: 'bar',
        color: '#66DBEA',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [0, 20, 20, 0],
        },
        data: activeSeries.ctr && reportsFbListGender ? reportsFbListGender['data']?.map(item=> item.ctr?.toFixed(2)) : [],
      },
      {
        name: 'Clicks',
        type: 'bar',
        color: '#7F4EAD',
        barWidth: 10,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          borderRadius: [0, 20, 20, 0],
        },
        data: activeSeries.clicks && reportsFbListGender ? reportsFbListGender['data']?.map(item=> item.clicks) : [],
      },
      {
        name: 'Spend',
        type: 'bar',
        color: '#B98CE4',
        barWidth: 10,
        itemStyle: {
          borderRadius: [0, 20, 20, 0],
        },
        emphasis: {
          focus: 'series',
        },
        data: activeSeries.spend && reportsFbListGender ? reportsFbListGender['data']?.map(item=> item.spend) : [],
      },
    ],
  };

  const legendsData = [
    {keyName: 'impressions', color: '#2D6CCA', name: 'Impressions'},
    {keyName: 'cpc', color: '#34AEF3', name: 'CPC'},
    {keyName: 'ctr', color: '#66DBEA', name: 'CTR'},
    {keyName: 'clicks', color: '#7F4EAD', name: 'Clicks'},
    {keyName: 'spend', color: '#B98CE4', name: 'Spend'},
  ];

  return isLoaded ? <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{fbImage()}Gender</Title></div>
    {reportsFbListGender && reportsFbListGender['data']?.length ?<>
      <div style={{display: 'flex'}}>
        {legendsData?.map((item, index) => {
          return <div key={index} className={classnames(styles.legends, styles.legendsTop, !activeSeries[item.keyName] && styles.legendsInactive)} onClick={() => hideAndShowSeries(item.keyName)}>
            <div className={styles.left}>
              <div className={styles.legendTitle} style={{lineHeight: '22px', whiteSpace: 'nowrap', marginLeft: '20px', color: 'black'}}><CheckboxStyled color={item?.color} defaultChecked={true} checked={activeSeries[item.keyName]}></CheckboxStyled>{item.name}</div>
            </div>
          </div>;
        })}
      </div>
      <ReactEcharts style={{width: '100%', height: '400px'}}
        option={options}
      /></> : <NoData>{loadingFbGender ? <Spin indicator={<LoadingOutlined style={{color: '#4e5156', fontSize: '15px'}} spin/>}/> : 'No Data'}</NoData>}
  </Wrapper> : null;
});

const dateRangeObj = {
  last_1_month: 'One month',
  last_3_months: 'Three month',
  last_6_months: 'Six month',
  last_year: 'One year',
};

export const FbCampaignTable = observer(({selectedProject}: any) => {
  const {reportBuilder: {details: {isConfig}}} = useStore('');
  const {settings: {customer: {profile: {whitelabelOtto}}}}= useStore('');
  const {reportBuilder: {details: {reportsFbListUser, property, loadgetFacebookAdsTableData, loadingFb, loadgetFacebookAdSetData, loadgetFacebookAdsData, singleProjectData}}} = useStore('');
  const [isSelectedTabOne, setIsSelectedTabOne] = useState(false);
  const [isSelectedTabTwo, setIsSelectedTabTwo] = useState(false);
  const [activeTabs, setActiveTabs] = useState('1');
  const [rowSelectionCamp, setRowSelectionCamp] = useState([]);
  const [rowSelectionCampTwo, setRowSelectionCampTwo] = useState([]);
  const numberOfPastMonths = dateRangeObj[`${singleProjectData?.dateRange}`] == 'One month' ? 'oneMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Three month' ? 'threeMonth' : dateRangeObj[`${singleProjectData?.dateRange}`] == 'Six month' ? 'sixMonth' : 'oneYear';
  const params = {
    period_start: singleProjectData?.dateRangeEnd ? singleProjectData.dateRangeStart : moment(getFormatedDate(numberOfPastMonths, moment()?.format('YYYY-MM-DD')))?.format('YYYY-MM-DD'),
    period_end: singleProjectData?.dateRangeEnd ? singleProjectData.dateRangeEnd : moment()?.format('YYYY-MM-DD'),
  };
  const getDomainUrl = getSingleUrlParam('domain');

  useEffect(()=> {
    activeTabs == '1' && getDomainUrl && selectedProject != 'Select Project' && loadgetFacebookAdsTableData({startDate: params.period_start, endDate: params.period_end, id: singleProjectData?.reportsData.facebookAds?.facebookAdsAccountId, reportId: singleProjectData?.id});
    activeTabs == '2' && loadgetFacebookAdSetData({startDate: params.period_start, endDate: params.period_end, id: singleProjectData?.reportsData.facebookAds?.facebookAdsAccountId, campaign_ids: rowSelectionCamp.join(',')});
    activeTabs == '3' && loadgetFacebookAdsData({startDate: params.period_start, endDate: params.period_end, id: singleProjectData?.reportsData.facebookAds?.facebookAdsAccountId, ad_set_ids: rowSelectionCampTwo.join(',')});
    if (activeTabs == '1') {
      setRowSelectionCampTwo([]);
      setRowSelectionCamp([]);
    }
  }, [activeTabs]);

  useEffect(()=> {
    if (!getDomainUrl) {
      setActiveTabs('1');
      setRowSelectionCampTwo([]);
      setRowSelectionCamp([]);
    }
  }, [property]);

  useEffect(() => {
    setActiveTabs('1');
  }, [JSON.stringify(singleProjectData)]);

  const loadMore = () => {
    const isLoaded = reportsFbListUser['nextPageToken'] ? {next_page: reportsFbListUser['nextPageToken']} : {};
    activeTabs == '1' && loadgetFacebookAdsTableData({startDate: params.period_start, endDate: params.period_end, id: singleProjectData?.reportsData.facebookAds?.facebookAdsAccountId, reportId: singleProjectData?.id, ...isLoaded});
    activeTabs == '2' && loadgetFacebookAdSetData({startDate: params.period_start, endDate: params.period_end, id: singleProjectData?.reportsData.facebookAds?.facebookAdsAccountId, campaign_ids: rowSelectionCamp.join(','), ...isLoaded});
    activeTabs == '3' && loadgetFacebookAdsData({startDate: params.period_start, endDate: params.period_end, id: singleProjectData?.reportsData.facebookAds?.facebookAdsAccountId, ad_set_ids: rowSelectionCampTwo.join(','), ...isLoaded});
  };

  const tableFooter = () => (
    reportsFbListUser && reportsFbListUser['nextPageToken'] ? <div style={{display: 'flex', justifyContent: 'center'}}>
      <Button type='link' onClick={loadMore}>
      Load more
      </Button>
    </div> : <></>
  );

  const columns = [
    ...(activeTabs == '3' ? [{
      title: 'Ad NAME',
      dataIndex: 'campaignName',
      key: 'campaignName',
      align: 'left' as 'left',
      width: 250,
      render: (data, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          {record?.adName}
        </div>;
      },
    }] : activeTabs == '1' ? [{
      title: 'CAMPAIGN',
      dataIndex: 'campaignName',
      key: 'campaignName',
      align: 'left' as 'left',
      width: 250,
      render: (data, record) => {
        const campaignName = record?.campaignName.includes('OTTO') ? record?.campaignName.replace('OTTO', whitelabelOtto) : record?.campaignName;
        const isAdded = rowSelectionCamp.find(item=> item == record.campaignId);
        return <div style={{whiteSpace: 'pre-wrap', color: '#2D6CCA'}}>
          {isSelectedTabOne && activeTabs == '1' && <Checkbox checked={isAdded} onChange={ e => e.target.checked ? setRowSelectionCamp([...rowSelectionCamp, record.campaignId]) : setRowSelectionCamp([...rowSelectionCamp.filter(item=> item != record.campaignId)])} style={{marginLeft: '-20px', marginRight: '8px'}}/>}
          {campaignName}
        </div>;
      },
    }]:[{
      title: 'ADSET NAME',
      dataIndex: 'campaignName',
      key: 'campaignName',
      align: 'left' as 'left',
      width: 250,
      render: (data, record) => {
        const isAddedTwo = rowSelectionCampTwo.find(item=> item == record.adsetId);
        return <div style={{whiteSpace: 'pre-wrap', color: '#2D6CCA'}}>
          {isSelectedTabTwo && activeTabs == '2' && <Checkbox checked={isAddedTwo} onChange={ e => e.target.checked ? setRowSelectionCampTwo([...rowSelectionCampTwo, record.adsetId]) : setRowSelectionCampTwo([...rowSelectionCampTwo.filter(item=> item != record.adsetId)])} style={{marginLeft: '-20px', marginRight: '8px'}}/>}
          {record.adsetName}
        </div>;
      },
    }]),
    {
      title: 'IMPRESSIONS',
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'right' as 'right',
      width: 120,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          {formatLargeNumber(record?.impressions, 1)?.toString()?.replace('.0', '') || '-'}
        </div>;
      },
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'right' as 'right',
      width: 120,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          ${record?.cpc ? record?.cpc.toFixed(2) : record?.cpc}
        </div>;
      },
    },
    {
      title: 'CTR',
      dataIndex: 'ctr',
      key: 'ctr',
      align: 'right' as 'right',
      width: 90,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          {record?.ctr ? record?.ctr?.toFixed(2) : record?.ctr}%
        </div>;
      },
    },
    {
      title: 'CLICKS',
      dataIndex: 'clicks',
      key: 'clicks',
      align: 'right' as 'right',
      width: 120,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          {formatLargeNumber(record?.clicks, 1)?.toString()?.replace('.0', '') || '-'}
        </div>;
      },
    }, {
      title: 'FREQUENCY',
      dataIndex: 'frequency',
      key: 'frequency',
      align: 'right' as 'right',
      width: 120,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          {record?.frequency?.toFixed(2)}
        </div>;
      },
    }, {
      title: 'SPEND',
      dataIndex: 'spend',
      key: 'spend',
      align: 'right' as 'right',
      width: 120,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          ${record?.spend}
        </div>;
      },
    }, {
      title: 'DATE START',
      dataIndex: 'dateStart',
      key: 'dateStart',
      align: 'right' as 'right',
      width: 120,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap'}}>
          {record?.dateStart ? moment(record?.dateStart).format('MMM DD YYYY') : '-'}
        </div>;
      },
    }, {
      title: <p style={{margin: '0 10px 0 0'}}>DATE END</p>,
      dataIndex: 'dateStop',
      key: 'dateStop',
      align: 'right' as 'right',
      width: 120,
      render: (_, record) => {
        return <div style={{whiteSpace: 'pre-wrap', marginRight: '10px'}}>
          {record?.dateStop ? moment(record?.dateStop).format('MMM DD YYYY') : '-'}
        </div>;
      },
    },
  ];
  return <Wrapper className={isConfig && 'no-border'}>
    <Tabs
      tabBarGutter={5}
      activeKey={activeTabs}
      tabBarStyle={{'padding': '0 !important'}}
      defaultActiveKey={'1'}
      onChange={key => {
        if (key == '2' && rowSelectionCamp?.length > 0) {
          setActiveTabs(key);
        } else if (key == '3' && rowSelectionCampTwo?.length > 0) {
          setActiveTabs(key);
        } else if (key == '1') {
          setActiveTabs(key);
        }
      }}
    >
      <TabPane tab={<div className='title'><p>{'Campaigns'} {rowSelectionCamp?.length > 0 && <Span>{rowSelectionCamp?.length} selected <FontAwesomeIcon icon={faX} onClick={()=> {
        setRowSelectionCamp([]);
        setActiveTabs('1');
      }} /></Span>}</p><span className='tabTitle'><FontAwesomeIcon color={'lightgray'} fontSize={16} icon={faAngleRight} /></span></div>} key='1' style={{'outline': 'none'}}>

        <div className='krtTable'>
          <div style={{margin: '10px 10px 20px 10px', display: 'flex'}}>Campaigns: {reportsFbListUser && reportsFbListUser['data']?.length > 0 ? reportsFbListUser['data']?.length : 0}
            {reportsFbListUser && reportsFbListUser['data']?.length > 0 && <span style={{cursor: 'pointer', color: '#2D6CCA'}} className='selections' onClick={()=> rowSelectionCamp?.length> 0 ? setActiveTabs('2') : setIsSelectedTabOne(true)}>
              {isSelectedTabOne ? `View ${rowSelectionCamp?.length} of ${reportsFbListUser && reportsFbListUser['data']?.length} campaigns`: 'Select Multiple'}</span>}</div>

          <TableStyled
            style={{height: '390px', background: 'white'}}
            columns={columns}
            dataSource={reportsFbListUser && reportsFbListUser['data'] || []}
            loading={loadingFb}
            pagination={false}
            sticky={true}
            loadingRows={5}
            footer={tableFooter}
          />
        </div>
      </TabPane>
      <TabPane tab={<div className='title' style={{cursor: rowSelectionCamp?.length >0 ? 'pointer' : 'not-allowed'}}><p style={{color: rowSelectionCamp?.length == 0 ? 'gray' : 'black'}}>{activeTabs =='2' ? `AdSets for ${rowSelectionCamp?.length} Campaign` : 'AdSets'} {rowSelectionCampTwo?.length > 0 &&
        <Span>{rowSelectionCampTwo?.length} selected <FontAwesomeIcon icon={faX} onClick={()=> {
          setRowSelectionCampTwo([]);
          setActiveTabs('2');
        }} /></Span>}</p><span className='tabTitle'><FontAwesomeIcon color={'lightgray'} fontSize={16} icon={faAngleRight} /></span></div>} key='2' style={{background: '#F9F9FB'}}>
        <div className='krtTable'>
          <div style={{margin: '10px 10px 20px 10px'}}>{isSelectedTabTwo ? `Selected AdSets: ${rowSelectionCampTwo?.length} of ${reportsFbListUser && reportsFbListUser['data']?.length}` : `AdSets : ${reportsFbListUser && reportsFbListUser['data']?.length}`}
            <span style={{cursor: 'pointer', color: '#2D6CCA'}} className='selections' onClick={()=> rowSelectionCampTwo?.length > 0 ? setActiveTabs('3') : setIsSelectedTabTwo(true)}>{isSelectedTabTwo ? `View Ads for ${rowSelectionCampTwo?.length} AdSets` : 'Select Multiple'}</span></div>

          <TableStyled
            style={{height: '390px', background: 'white'}}
            columns={columns}
            dataSource={reportsFbListUser && reportsFbListUser['data'] || []}
            loading={loadingFb}
            pagination={false}
            sticky={true}
            loadingRows={5}
            footer={tableFooter}
          />
        </div>
      </TabPane>
      <TabPane tab={<p style={{color: rowSelectionCampTwo?.length == 0 ? 'gray' : 'black', margin: '0', cursor: rowSelectionCampTwo?.length > 0 ? 'pointer' : 'not-allowed'}}>
        {activeTabs == '3' ? `Ads for ${rowSelectionCampTwo?.length} AdSets` : activeTabs == '2' ? `Ads for ${rowSelectionCamp?.length} Campaign` : 'Ads'}</p>} key='3' style={{background: '#F9F9FB'}}>
        <div className='krtTable'>
          <div style={{margin: '10px 10px 20px 10px'}}>Ads: {reportsFbListUser && reportsFbListUser['data']?.length}</div>

          <TableStyled
            style={{height: '390px', background: 'white'}}
            columns={columns}
            dataSource={reportsFbListUser && reportsFbListUser['data'] || []}
            loading={loadingFb}
            pagination={false}
            sticky={true}
            loadingRows={5}
            footer={tableFooter}
          />
        </div>

      </TabPane>
    </Tabs>
  </Wrapper>;
});

const NoData = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
    height: 83%;
`;

const Title = styled.h2`
font-size: 16px;
font-weight: 600;
margin-bottom: 20px;
align-items: center;
    display: flex;

  img {
    margin-top: 0px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
`;

const Span = styled.span`
padding: 3px 3px 3px 13px;
    border-radius: 20px;
    background: #62627B;
    color: white;
    svg{
    margin-left: 10px;
    font-size: 12px;
    }
`;


const Wrapper = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  padding: 20px;
  background-color: white;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #00000005;
  border-radius: 16px;
  // margin-top: 20px;
  h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-top: 10%;
  }
  svg {
    margin-right: 10px;
  }
     .ant-table-header{
  overflow: visible !important;
  }
  .ant-table-sticky-scroll{
  display: none;
  }
  td {
  white-space: inherit !important;
  }
  .rc-tabs-nav {
  background: transparent !important;
  .rc-tabs-tab-btn{
  color: black !important;
  padding: 5px 5px !important;
  }
  .rc-tabs-ink-bar{
  background: #2D6CCA;
  height: 6px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  }
  .selections{
  margin-left: 30px;
  }
  .krtTable{
  padding-left: 0px !important;
  }
  .tabTitle{
    position: absolute;
    right: 0;
    margin-right: -25px;
    align-items: center;
    display: flex;
    }
    .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
    margin: 0;
    }
    }
    .echarts-for-react{
    &::first-child{
    widht: 300px;}
    }
`;

const CheckboxStyled = styled(Checkbox)<{color}>`
.ant-checkbox-inner {
    background-color:${p => p.color};
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color:${p => p.color};
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
`;

const Values = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: justify;
`;
