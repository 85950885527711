import {getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

interface KeywordGapAnalysisHistoryPayload {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  search?: string;
}
interface KeywordGapDetailsPayload {
  id: number | string;
  context?: string;
  sortBy?: string;
  search?: string;
  page?: number;
  pageSize?: number;
  payload?: any[];
}
class KeywordOverviewApi extends BaseApi {
  public async getKeywordGapAnalysis(paylaod) {
    const response = await this.axios.post(`/api/v1/keyword-gap-analysis/`, paylaod, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getKeywordGapAnalysisHistory({page, pageSize, sortBy, search}: KeywordGapAnalysisHistoryPayload) {
    const response = await this.axios.get(`/api/v1/keyword-gap-analysis/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      params: {
        'page': page,
        'page_size': pageSize,
        ...(sortBy && {ordering: sortBy}),
        ...(search && {search: search}),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getKeywordGapAnalysisDetails({id, context, page, pageSize, sortBy, search}: KeywordGapDetailsPayload) {
    const path = `/api/v1/keyword-gap-analysis/${id}/`;
    const response = await this.axios.get(path, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      params: {
        'page': page,
        'page_size': pageSize,
        ...(context && {'context': context}),
        ...(sortBy && {'order_by': sortBy}),
        ...(search && {search: search}),
        // ...(topParams && {topParams}),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getFilteredList({id, context, page, pageSize, sortBy, search, payload, topFilters}) {
    const path = `/api/v1/keyword-gap-analysis/${id}/filter-keyword-gap/`;
    const data = {
      'page': page,
      'page_size': pageSize,
      ...(topFilters && topFilters),
    };
    if (context) {
      data['context'] = context;
    }
    if (sortBy) {
      data['order_by'] = sortBy;
    }
    if (search) {
      data['search'] = search;
    }
    if (payload?.length) {
      data['filter_config'] = payload;
    }
    if (topFilters) {
      data[`top_${topFilters}_keywords`] = !!topFilters;
    }
    const response = await this.axios.post(path, data, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    });
    return response.data;
  }
}

export default KeywordOverviewApi;
