import {KEYWORD_API} from '@/api/keyword-researcher';
import {apiError} from '@/utils/api';
import {formatSorters} from '@/utils/filters';
import {notification} from '@/utils/notification-v2';
import {types, flow, cast, Instance} from 'mobx-state-tree';

const KeywordGapResultsColumnsModel = types.model({
  columnName: types.maybeNull(types.string),
  isCompetitor: types.maybeNull(types.boolean),
});

const KeywordGapResultsChartValuesSingleModel = types.model({
  url: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const KeywordGapResultsChartValuesModel = types.model({
  traffic: types.maybeNull(types.array(KeywordGapResultsChartValuesSingleModel)),
  position1Keywords: types.maybeNull(types.array(KeywordGapResultsChartValuesSingleModel)),
  top10Keywords: types.maybeNull(types.array(KeywordGapResultsChartValuesSingleModel)),
  top100Keywords: types.maybeNull(types.array(KeywordGapResultsChartValuesSingleModel)),
});

const KwGapCompetitorDetailModel= types.model({
  position: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const KeywordGapResultsModel = types.model({
  columns: types.maybeNull(types.array(KeywordGapResultsColumnsModel)),
  totalCount: types.maybeNull(types.number),
  values: types.maybeNull(types.array(types.array(types.union(types.maybeNull(types.string), types.maybeNull(types.boolean), types.maybeNull(types.number), KwGapCompetitorDetailModel)))),
});

const KeywordGapAnalysisDetailsModel = types.model({
  results: types.maybeNull(KeywordGapResultsModel),
  chartData: types.maybeNull(KeywordGapResultsChartValuesModel),
  totalCount: types.maybeNull(types.number),
});
const KeywordGapCompetitorsModel = types.model({
  url: types.maybeNull(types.string),
  scope: types.maybeNull(types.string),
});
const KeywordGapPrimaryWebsitesModel = types.model({
  url: types.maybeNull(types.string),
  scope: types.maybeNull(types.string),
});
const KeywordGapAnalysisDataArrModel = types.model({
  competitorWebsites: types.maybeNull(types.array(KeywordGapCompetitorsModel)),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  primaryWebsite: types.maybeNull(KeywordGapPrimaryWebsitesModel),
  searchedOn: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
});


const KeywordGapAnalysisModel = types.model({
  results: types.maybeNull(types.array(KeywordGapAnalysisDataArrModel)),
  count: types.maybeNull(types.number),
});

export type KeywordGapAnalysisType = Instance<typeof KeywordGapAnalysisDataArrModel>;

export const keywordResearcherStore = types.model({
  kwGapAnalysisLoading: types.maybeNull(types.boolean),
  keywordGapAnalysisInitLoading: types.maybeNull(types.boolean),
  kwGapDetailsLoading: types.maybeNull(types.boolean),
  keywordGapAnalysis: types.maybeNull(KeywordGapAnalysisModel),
  keywordGapAnalysisDetails: types.maybeNull(KeywordGapAnalysisDetailsModel),
  primaryWebsite: types.maybeNull(types.string),
  detailsId: types.maybeNull(types.number),
  keywordGapAnalysisHistoryPage: types.maybeNull(types.number),
  keywordGapAnalysisHistoryPageSize: types.maybeNull(types.number),
  keywordGapAnalysisHistorySortKey: types.maybeNull(types.string),
  keywordGapAnalysisHistorySortDirection: types.maybeNull(types.string),
  keywordGapAnalysisHistorySearchQuery: types.maybeNull(types.string),
  keywordGapDetailsSortKey: types.maybeNull(types.string),
  keywordGapDetailsSortDirection: types.maybeNull(types.string),
  keywordGapDetailsSearchQuery: types.maybeNull(types.string),
  keywordGapDetailsPage: types.maybeNull(types.number),
  keywordGapDetailsPageSize: types.maybeNull(types.number),
  activeTabKey: types.maybeNull(types.string),
  activeExportKey: types.maybeNull(types.string),
  keywordGapDetailsInitLoading: types.boolean,
  showExportModal: types.boolean,
  kwGapDetailsExporting: types.boolean,
  isMasterExportModal: types.boolean,
  showNoDataBanner: types.maybeNull(types.boolean),
}).actions(self => {
   interface KeywordGapAnalysisHistoryPayload {
    page?: number;
    pageSize?: number;
  }

   const getKwGapAnalysisHistory = flow(function* ({page, pageSize}: KeywordGapAnalysisHistoryPayload) {
     const sorters = {
       primaryWebsite: 'primary_website',
       searchedOn: 'searched_on',
     };

     self.kwGapAnalysisLoading = true;
     try {
       const response = yield KEYWORD_API.getKeywordGapAnalysisHistory({
         page,
         pageSize,
         ...((self.keywordGapAnalysisHistorySortKey && self.keywordGapAnalysisHistorySortDirection) && {sortBy: formatSorters(sorters, self.keywordGapAnalysisHistorySortKey, self.keywordGapAnalysisHistorySortDirection)}),
         ...(self.keywordGapAnalysisHistorySearchQuery && {search: self.keywordGapAnalysisHistorySearchQuery}),
       });
       if (response.isCancel) return;
       self.keywordGapAnalysis = cast(response);
     } catch (e) {
       const errorMessage = apiError(e) as string;
       notification.error('', errorMessage);
     } finally {
       self.kwGapAnalysisLoading = false;
       self.keywordGapAnalysisInitLoading = false;
     }
   });

   const getKwGapAnalysis = flow(function* (payload) {
     const sorters = {
       primaryWebsite: 'primary_website',
       searchedOn: 'searched_on',
     };

     self.kwGapAnalysisLoading = true;
     try {
       const data = yield KEYWORD_API.getKeywordGapAnalysis(payload);
       if (data.isCancel) return;

       const response = yield KEYWORD_API.getKeywordGapAnalysisHistory({
         page: self.keywordGapAnalysisHistoryPage,
         pageSize: self.keywordGapAnalysisHistoryPageSize,
         ...((self.keywordGapAnalysisHistorySortKey && self.keywordGapAnalysisHistorySortDirection) && {sortBy: formatSorters(sorters, self.keywordGapAnalysisHistorySortKey, self.keywordGapAnalysisHistorySortDirection)}),
         ...(self.keywordGapAnalysisHistorySearchQuery && {search: self.keywordGapAnalysisHistorySearchQuery}),
       });
       self.keywordGapAnalysis = cast(response);
     } catch (e) {
       self.kwGapAnalysisLoading = false;
       return Promise.reject(e);
     } finally {
       self.kwGapAnalysisLoading = false;
     }
   });

   const getKwGapAnalysisDetails = flow(function* (id: number | string, loading = true) {
     const sorters = {
       keyword: 'keyword',
       searchVolume: 'search_volume',
     };

     self.keywordGapAnalysisDetails?.results?.columns?.map(column => {
       sorters[column.columnName] = column.columnName;
     });

     self.showNoDataBanner = false;
     if (loading) self.kwGapDetailsLoading = true;
     if (loading && self.keywordGapAnalysisDetails?.results?.values) {
       self.keywordGapAnalysisDetails.results.values.length = 0;
     }
     if (loading && self.keywordGapAnalysisDetails?.results?.totalCount) {
       self.keywordGapAnalysisDetails.results.totalCount = 0;
     }

     try {
       const response = yield KEYWORD_API.getKeywordGapAnalysisDetails({
         id,
         context: self.activeTabKey,
         page: self.keywordGapDetailsPage,
         pageSize: self.keywordGapDetailsPageSize,
         ...((self.keywordGapDetailsSortKey && self.keywordGapDetailsSortDirection) && {sortBy: formatSorters(sorters, self.keywordGapDetailsSortKey, self.keywordGapDetailsSortDirection)}),
         ...(self.keywordGapDetailsSearchQuery && {search: self.keywordGapDetailsSearchQuery}),
       });
       if (response.isCancel) return;
       if (!response.totalCount) self.showNoDataBanner = true;
       self.keywordGapAnalysisDetails = cast(response);
       return response;
     } catch (e) {
       self.showNoDataBanner = true;
       return Promise.reject(e);
     } finally {
       self.kwGapDetailsLoading = false;
       self.keywordGapDetailsInitLoading = false;
     }
   });
   const loadFilterList = flow(function* (id, payload?: any, topFilters?: any) {
     const sorters = {
       keyword: 'keyword',
       searchVolume: 'search_volume',
     };

     self.keywordGapAnalysisDetails?.results?.columns?.map(column => {
       sorters[column.columnName] = column.columnName;
     });

     try {
       const response = yield KEYWORD_API.getFilteredList({
         id: id,
         context: self.activeTabKey,
         page: self.keywordGapDetailsPage,
         pageSize: self.keywordGapDetailsPageSize,
         ...((self.keywordGapDetailsSortKey && self.keywordGapDetailsSortDirection) && {sortBy: formatSorters(sorters, self.keywordGapDetailsSortKey, self.keywordGapDetailsSortDirection)}),
         ...(self.keywordGapDetailsSearchQuery && {search: self.keywordGapDetailsSearchQuery}),
         payload: payload?.filter_config,
         topFilters: topFilters,
       });
       if (response) {
         self.keywordGapAnalysisDetails = cast({
           ...self.keywordGapAnalysisDetails,
           results: response?.results,
         });
       }
       return response;
     } catch (err) {
       return Promise.reject(err);
     }
   });

   const setKwGapAnalysisLoading = (value: boolean) => self.kwGapAnalysisLoading = value;
   const setKwGapDetailsLoading = (value: boolean) => self.kwGapDetailsLoading = value;
   const setPrimaryWebsite = (value: string) => self.primaryWebsite = value;
   const setDetailsId = (id: number) => self.detailsId = id;
   const setKeywordGapAnalysisHistoryPage = (value: number) => self.keywordGapAnalysisHistoryPage = value;
   const setKeywordGapAnalysisHistoryPageSize = (value: number) => self.keywordGapAnalysisHistoryPageSize = value;
   const setKeywordGapAnalysisInitLoading = (value: boolean) => self.keywordGapAnalysisInitLoading = value;
   const setKeywordDetailsInitLoading = (value: boolean) => self.keywordGapDetailsInitLoading = value;
   const setSetKeywordGapSearchQuery = (value: string) => self.keywordGapAnalysisHistorySearchQuery = value;
   const setSetKeywordGapSortKey = (value: string) => self.keywordGapAnalysisHistorySortKey = value;
   const setSetKeywordGapSortDirection = (value: string) => self.keywordGapAnalysisHistorySortDirection = value;
   const setSetKeywordGapDetailsSearchQuery = (value: string) => self.keywordGapDetailsSearchQuery = value;
   const setSetKeywordGapDetailsSortKey = (value: string) => self.keywordGapDetailsSortKey = value;
   const setSetKeywordGapDetailsSortDirection = (value: string) => self.keywordGapDetailsSortDirection = value;
   const setActiveTabKey = (value: string) => self.activeTabKey = value;
   const setActiveExportKey = (value: string) => self.activeExportKey = value;
   const setKeywordGapDetailsPage = (value: number) => self.keywordGapDetailsPage = value;
   const setKeywordGapDetailsPageSize = (value: number) => self.keywordGapDetailsPageSize = value;
   const setShowExportModal = (value: boolean) => self.showExportModal = value;
   const setKwGapDetailsExporting = (value: boolean) => self.kwGapDetailsExporting = value;
   const setIsMasterExportModal = (value: boolean) => self.isMasterExportModal = value;

   return {
     getKwGapAnalysisHistory,
     getKwGapAnalysis,
     getKwGapAnalysisDetails,
     loadFilterList,
     setKwGapAnalysisLoading,
     setKwGapDetailsLoading,
     setPrimaryWebsite,
     setDetailsId,
     setKeywordGapAnalysisHistoryPage,
     setKeywordGapAnalysisHistoryPageSize,
     setKeywordGapAnalysisInitLoading,
     setSetKeywordGapSearchQuery,
     setSetKeywordGapSortKey,
     setSetKeywordGapSortDirection,
     setActiveTabKey,
     setSetKeywordGapDetailsSearchQuery,
     setSetKeywordGapDetailsSortKey,
     setSetKeywordGapDetailsSortDirection,
     setKeywordDetailsInitLoading,
     setKeywordGapDetailsPage,
     setKeywordGapDetailsPageSize,
     setShowExportModal,
     setKwGapDetailsExporting,
     setActiveExportKey,
     setIsMasterExportModal,
   };
}).views(self=> ({
  get getFilteredData() {
    const formatArr = [];
    self?.keywordGapAnalysisDetails?.results?.values?.map(item => {
      const tempObject = {};
      item.map((value, idx) => {
        tempObject[self.keywordGapAnalysisDetails?.results?.columns[idx]?.columnName] = value;
      });
      formatArr.push(tempObject);
    });

    return formatArr;
  },

}));


export function initKeywordResearcher() {
  return keywordResearcherStore.create({
    kwGapAnalysisLoading: false,
    keywordGapAnalysisInitLoading: false,
    keywordGapDetailsInitLoading: true,
    kwGapDetailsLoading: false,
    primaryWebsite: '',
    detailsId: null,
    showNoDataBanner: false,
    keywordGapAnalysisHistoryPage: 1,
    keywordGapAnalysisHistoryPageSize: 10,
    keywordGapDetailsPage: 1,
    keywordGapDetailsPageSize: 100,
    activeTabKey: 'common',
    showExportModal: false,
    kwGapDetailsExporting: false,
    isMasterExportModal: false,
  });
}

