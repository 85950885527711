import React, {useRef, useEffect, ReactNode} from 'react';
import html2canvas from 'html2canvas';

let JSpdf = null;
if (typeof window !== 'undefined') {
  import('jspdf').then(module => {
    JSpdf = module.default;
  });
}

interface Props {
    fileName: string;
    html?: ReactNode;
}
export const ExportPdf: React.FC<Props> = (
  {
    fileName,
    html,
  },
) => {
  const pdfRef = useRef(null);

  const exportNode = node => {
    html2canvas(node)
      .then(canvas => {
        canvas.style.display = 'none';
        const image = canvas.toDataURL();
        const pdf = new JSpdf({
          orientation: 'p',
          unit: 'pt',
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(image, 'JPEG', 0, 0);
        pdf.save(`${fileName}.pdf`);
      });
  };

  useEffect(() => {
    exportNode(pdfRef.current);
  });

  return (
    <div ref={pdfRef}>
      {html}
    </div>
  );
};
