import {getApiUrl, getAuthHeader, getHeaders} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class OttoApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.CA_ENDPOINT, '/api');

  public async getOttoList(params) {
    try {
      const response = await this.axios.get(`${OttoApi.baseUrl}/customer-otto-suggestions/`, {
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  // getOttoDomainListDetail
  public async getOttoListDetail(id) {
    try {
      const response = await this.axios.get(`${OttoApi.baseUrl}/otto-tasks/${id}`, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getOttoDomainListDetail(domain) {
    const params = {domain: domain};
    try {
      const response = await this.axios.get(`${OttoApi.baseUrl}/otto-tasks/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async setIgnoreTask(id) {
    try {
      const response = await this.axios.put(`${OttoApi.baseUrl}/otto-tasks/${id}/ignore/`, {}, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async setCompleteTask(id) {
    try {
      const response = await this.axios.put(`${OttoApi.baseUrl}/otto-tasks/${id}/complete/`, {}, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async setShuffleTopic(id: number) {
    try {
      const response = await this.axios.post(`${OttoApi.baseUrl}/otto-tasks/${id}/shuffle-topics/`, {}, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async keepAuditProject(id:number) {
    try {
      const response = await this.axios.put(`${OttoApi.baseUrl}/customer-otto-suggestions/${id}/`, {'should_keep_if_audit_deleted': true}, getHeaders());
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async deleteAuditProject(id:number) {
    try {
      const response = await this.axios.delete(`${OttoApi.baseUrl}/customer-otto-suggestions/${id}/`, getHeaders());
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async getViewReport(id:string) {
    try {
      const response = await this.axios.get(`${OttoApi.baseUrl}/customer-otto-suggestions/${id}/dashboard/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async activateProject(id: number) {
    try {
      const response = await this.axios.put(`${OttoApi.baseUrl}/customer-otto-suggestions/${id}/activate/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async bookMarkListed(id: number, url: string) {
    try {
      const response = await this.axios.put(`${OttoApi.baseUrl}/customer-otto-suggestions/${id}/bookmark-page/`, {url}, {
        headers: getAuthHeader(),
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async loadNewSeoTasks(domain: string) {
    try {
      const resposne = await this.axios.post(`${OttoApi.baseUrl}/customer-otto-suggestions/`, {domain}, {
        headers: getAuthHeader(),
      });
      return resposne;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async conncetGscAccount(id: number, url: string, countryCode: string) {
    try {
      const response = await this.axios.post(`${OttoApi.baseUrl}/customer-otto-suggestions/${id}/connect-gsc/`, {
        'property_url': url,
        'country_code': countryCode,
      }, {
        headers: getAuthHeader(),
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  public async ottoEmailSettings() {
    const response = await this.axios.get(`${OttoApi.baseUrl}/customer-otto-suggestions/email-settings/`,
      {
        headers: getAuthHeader(),
      },
    );
    return response.data;
  }
  public async onUpdateOttoEmailList(id, payload) {
    const response = await this.axios.put(`${OttoApi.baseUrl}/customer-otto-suggestions/${id}/`, {'should_send_emails': payload},
      {headers: getAuthHeader()},
    );
    return response.data;
  }
  public async onProjectCreation() {
    const response = await this.axios.put(`${OttoApi.baseUrl}/customer-otto-suggestions/toggle-creation-email/`, {},
      {headers: getAuthHeader()},
    );
    return response.data;
  }
  public async updateAssignInOttoTask(id: number, assignedToUsers: Array<string>) {
    try {
      const response = await this.axios.patch(`${OttoApi.baseUrl}/otto-tasks/${id}/`, {assigned_to_users: assignedToUsers}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
}


export const OTTO_API = new OttoApi();
