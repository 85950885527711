import {types} from 'mobx-state-tree';

export const FilterModel = types.model({
  filterFrom: types.maybeNull(types.string),
  filterTo: types.maybeNull(types.string),
  isClear: types.boolean,
}).actions(self => {
  const setFilterFrom = (filter: string) => {
    self.filterFrom = filter;
  };
  const setFilterTo = (filter: string) => {
    self.filterTo = filter;
  };
  const setIsClear = (filter: boolean) => {
    self.isClear = filter;
  };
  return {
    setFilterFrom,
    setFilterTo,
    setIsClear,
  };
});

export function initFilters() {
  return FilterModel.create({
    filterFrom: '',
    filterTo: '',
    isClear: true,
  });
}
