import React, {ReactNode} from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

interface TagProps {
  color?: string;
  className?: string;
  containerClassName?: string;
  hideDot?: boolean;
  labelClassName?: string;
  children: ReactNode;
  title?: string;
  backgroundColor?:string;
  onClick?:()=>void;
}

export const Tag: React.FC<TagProps> = ({
  color = '#a9d62c',
  className,
  containerClassName,
  labelClassName,
  children,
  hideDot,
  title,
  onClick,
  backgroundColor,
}) => {
  return (
    <div style={{backgroundColor: backgroundColor}} className={classnames(styles.tag, className)}>
      <span onClick={onClick} className={classnames(styles.container, containerClassName)}>
        {hideDot ? null : <span className={styles.dot} style={{background: color}} />}
        <span title={title ? title : ''} className={classnames(styles.label, labelClassName)}>
          {children}
        </span>
      </span>
    </div>
  );
};
