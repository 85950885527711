import {camelCase, isEmpty} from 'lodash';
import {Map} from 'immutable';
import {DefaultDraftInlineStyle, genKey} from 'draft-js';

const defaultPreTagStyling = [
  ['padding', '9.5px'],
  ['margin', '0 0 10px'],
  ['border', '1px solid rgb(204, 204, 204)'],
  ['background', 'rgb(245, 245, 245)'],
];

export const customStyleMap = (() => {
  const styleMap = {...DefaultDraftInlineStyle};
  return styleMap;
})();

let tableKey;

/**
 * functions for converting html into draft.js data structure state
 */
export const stateFromHtmlOptions = {
  // collect block level metadata
  customBlockFn: element => {
    const style = element.getAttribute('style') || '';
    const className = element.getAttribute('class') || '';
    let data = convertStyleStringToObject(style) || {} as any;
    data = className
      .split(' ')
      .filter(c => c.length)
      .reduce((map, c) => {
        const key = c.includes('depth') ? 'depth' : c;
        const val = key === 'depth' ? +c.slice(5) : 'class';
        map[key] = val;
        return map;
      }, data);
    // identify lists that were pasted in from another source rather than created natively in the editor. These get handled as a custom block type.
    if (
      element.tagName === 'LI' &&
      (element.parentNode.getAttribute('start') || element.style.listStyleType !== 'none') &&
      !element.className.split(' ').find(c => ['ordered-list-item', 'unordered-list-item'].includes(c))
    ) {
      const listType = element.parentNode.tagName === 'UL' ? 'ul' : 'ol';
      if (element.parentNode.firstElementChild === element) {
        data.listStyles = convertStyleStringToObject(element.parentNode.getAttribute('style') ?? 'margin-left: 36px;');
        data.listStart =
          element.getAttribute('start') ?? element.parentNode.getAttribute('start') ?? (listType === 'ul' ? 0 : 1);
        let start = data.listStart;
        for (const child of element.parentNode.children) {
          if (listType === 'ul') {
            child.setAttribute('start', 0);
          } else {
            child.setAttribute('start', start++);
          }
        }
      } else {
        data.listStart = element.getAttribute('start');
      }
      data['list-style-type'] = element.style.listStyleType || (listType === 'ul' ? 'disc' : 'decimal');
      return {type: 'unordered-list-item', data};
    }

    if (element.firstChild && element.firstChild.tagName === 'IMG') {
      let style = element.firstChild.getAttribute('style');
      style = convertStyleStringToObject(style);
      data = {
        ...data,
        // eslint-disable-next-line new-cap
        ...(style && {imgStyle: Map(style)}),
      };
      return {type: 'atomic', data};
    }
    if (element.tagName === 'PRE') {
      if (!data.background) {
        data = convertStyleStringToObject(defaultPreTagStyling.map(v => v.join(': ')).join('; '));
      }
      return {type: 'code-block', data};
    }
    if (/break-after:|break-before:/.test(element.style.cssText)) {
      return {type: 'page-break', data};
    }
    if (element.tagName === 'P') {
      const noMargin =
        element.style.margin?.startsWith('0') ||
        (element.style.marginTop?.startsWith('0') && element.style.marginBottom?.startsWith('0'));
      if (noMargin) return {type: 'unstyled', data};
      return {type: 'paragraph', data};
    }
    if ((element.innerText || '').startsWith('---hr---')) {
      return {type: 'horizontal-rule', data};
    }
    if (['TD', 'TH'].includes(element.tagName)) {
      /**
       * To preserve tables when converting html into Draft block types, we store the full
       * table specifications with the first "cell", and save the table position for the others
       */
      const tableEl = element.closest('table');
      const tHeadEl = element.closest('thead') ?? tableEl.querySelector('thead');
      const tBodyEl = element.closest('tbody') ?? tableEl.querySelector('tbody');
      const tableRows = tableEl.querySelectorAll('tr');
      // But if this table has a nested table within it
      // don't render the outer table or Draft-js will crash
      if (tableEl.querySelector('table')) {
        return {type: 'unstyled', data};
      }

      // empty elements get ignored and can break a table, replace unrendered characters,
      // ensure at minimum there is an non-breaking space
      if (isEmpty(element.textContent.replace(/\s/g, ''))) {
        element.innerHTML = '&nbsp;';
      }

      const prevCell = element.previousElementSibling;
      const row = element.parentNode;
      const prevRow = row.previousElementSibling;
      // Check if this is not the first cell in the table, if it's not then we traverse the table
      // structure just far enough to get the cell's position and store it in the data used to create
      // the corresponding Draft block
      if (prevCell || prevRow || (tHeadEl && [tableEl, tBodyEl].includes(row.parentNode))) {
        let found = false;
        for (let i = 0, rows = tableRows, rowCount = rows.length; i < rowCount; i++) {
          for (let j = 0, cells = rows[i].children, colCount = cells.length; j < colCount; j++) {
            if (cells[j] === element) {
              data.tableKey = tableKey;
              data.tablePosition = `${tableKey}-${i}-${j}`;
              data.colspan = cells[j].getAttribute('colspan');
              data.rowspan = cells[j].getAttribute('rowspan');
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
        return {type: 'table', data};
      }
      // Only the first cell in the table will go through the processing below, so the Draft block
      // created for it will have all the necessary data to render the empty table structure into
      // which we render the rest of the table blocks.
      const colgroup = tableEl.querySelector('colgroup');
      const tableShape = [];
      tableKey = genKey();
      data.tableKey = tableKey;
      data.tablePosition = `${tableKey}-0-0`;
      data.tableStyle = convertStyleStringToObject(tableEl.getAttribute('style')) || {
        margin: '15px 0',
        width: '100%',
      };
      data.tableStyle['border-collapse'] = 'collapse';
      for (let i = 0, rows = tableRows, rowCount = rows.length; i < rowCount; i++) {
        tableShape.push([]);
        const defaultStyle = {};
        if (i === 0) {
          if (element.tagName === 'TH') {
            defaultStyle['background-color'] = 'rgba(240, 240, 240, 0.8)';
          }
          data.rowStyle = [convertStyleStringToObject(rows[i].getAttribute('style')) || defaultStyle];
        } else {
          data.rowStyle.push(convertStyleStringToObject(rows[i].getAttribute('style')) || defaultStyle);
        }
        for (let j = 0, cells = rows[i].children, colCount = cells.length; j < colCount; j++) {
          const defaultStyle = {'border': '1px solid rgba(0, 0, 0, 0.2)', 'padding': '6px', 'text-align': 'center'};
          if (cells[j].tagName === 'TH') {
            defaultStyle['font-weight'] = 'bold';
          }
          const cellStyle = convertStyleStringToObject(cells[j].getAttribute('style')) || defaultStyle;
          tableShape[i][j] = {
            element: cells[j].tagName === 'TD' ? 'td' : 'th',
            style: cellStyle,
            colspan: cells[j].getAttribute('colspan'),
            rowspan: cells[j].getAttribute('rowspan'),
          };
        }
      }

      data.tableShape = tableShape;
      data.tableColgroup = colgroup?.outerHTML;
      return {type: 'table', data};
    }
    return {data};
  },

  // collect inline style data - inline type elements are passed through this function (span, img, a, etc.)
  customInlineFn: (element, {Style, Entity}) => {
    if (element.tagName === 'IMG') {
      // image styling is handled in the customBlockFn above
      return null;
    }
    if (element.tagName === 'A') {
      let data = {};
      if (element.hasAttribute('target')) {
        data = {target: element.getAttribute('target'), rel: 'noreferrer'};
      }
      // eslint-disable-next-line new-cap
      return Entity('LINK', {...data, url: element.getAttribute('href')});
    }

    if (element.style.fontWeight === 'bold' || element.style.fontWeight === '700') {
      // eslint-disable-next-line new-cap
      return Style('BOLD');
    }
    if (element.style.fontStyle === 'italic') {
      // eslint-disable-next-line new-cap
      return Style('ITALIC');
    }
    if (element.style.textDecoration === 'underline') {
      // eslint-disable-next-line new-cap
      return Style('UNDERLINE');
    }

    let styleElement = element.getAttribute('style');

    if (!styleElement) {
      return null;
    }

    // if the element has multiple styles applied pass them all together as-is because the html import library's
    // "Style" function currently doesn't support processing multiple styles separately
    if (styleElement.includes(';')) {
      // eslint-disable-next-line new-cap
      return Style(styleElement);
    }
    // otherwise format the style to match the customStyleMap
    styleElement = styleElement.split(':');
    const key = camelCase(styleElement.shift().trim());
    const val = styleElement.join(':').trim();
    styleElement = `${key}.${val}`;
    if (styleElement === 'textDecoration.underline') {
      return null;
    } // underline is handled automatically, don't override it
    // eslint-disable-next-line new-cap
    return Style(styleElement);
    // return null;
  },
};

// helper function converts style attribute string into key-value pairs
const convertStyleStringToObject = (style = '', data = {}) => {
  if (!style) {
    return null;
  }
  return style
    .split(';')
    .filter(s => s.includes(':'))
    .map(s => s.split(':'))
    .reduce((map, s) => {
      const key = s.shift().trim();
      const val = s.join(':').trim();
      map[key] = val;
      return map;
    }, data);
};
