import styled from 'styled-components';
import {Button} from '@/components/common-components/v2/Button';
import {Divider, Empty, Menu, Radio} from 'antd';
import {Table} from '@/components/common-components/components/table';
import {Pagination} from '@/components/common-components/components/pagination';
import {Checkbox} from 'antd';

export const ResearchTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #FFFFFF;
`;
export const HeaderBlackDiv = styled.div`
  background: #24262A;
  padding: 40px 40px 120px 40px;
  max-width: 1500x;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 768px) {
    padding: 40px 15px 120px 15px;
  }
`;
export const FlexWrapper = styled.div<{align?: string; justifyContent?: string; isResponsive?: boolean; isButtonResponsive?: boolean; isThirdOptionResponsive?: boolean; isThirdOptionInsideResponsive?: boolean}>`
  display: flex;
  align-items: ${p=> p?.align};
  justify-content: ${p=> p?.justifyContent};

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7f4ead;
    border-color: #7f4ead;
  }
  .checkBoxWrapper{
    width: 59%;
    margin-top: 11px;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 824px) {
    .checkBoxWrapper{
    width: 100%;
    margin-top: 11px;
    flex-wrap: wrap;
  }
  }
  ${p => p.isResponsive ? `
    @media screen and (max-width: 529px) {
      display: block;
      flex-wrap: nowrap;
    }
  ` : ''}
  ${p => p?.isButtonResponsive ? `
    @media screen and (max-width: 618px) {
      justify-content: flex-start;
      .organic-search {
        margin-top: 20px;
      }
    }
  ` : ''}
    ${p => p?.isThirdOptionResponsive ? `
    @media screen and (max-width: 605px) {
      display: block;
      .organic-search {
        margin-top: 20px;
      }
    }
  ` : ''}
  ${p => p.isThirdOptionInsideResponsive ? `
    @media screen and (max-width: 605px) {
      justify-content: start;
      .organic-search {
        margin-top: 20px;
      }
    }
  ` : ''}
`;
export const GrayText = styled.div`
  color: #E8E8E8;
  margin-left: 12px;
`;
export const BoldLightText = styled.div<{fontSize?: number; marginLeft?: string}>`
   font-weight: 500;
   font-size: ${p=> p?.fontSize ? `${p?.fontSize}px` : '14px'};
   color: #FFFFFF;
   margin-left: ${p=> p?.marginLeft};
   @media screen and (max-width: 768px) {
    font-size: 18px !important;
   }
`;
export const GrayTextDark = styled.div`
   font-weight: 600;
   text-transform: uppercase;
   color: #A3A4A4;
   margin: 5px 0;
   white-space: nowrap;
   font-size: 12px;
   @media screen and (max-width: 768px) {
    font-size: 10px;
   }
`;
export const DomainPowerWrapper = styled.div`
  position: relative;
  background: linear-gradient(148.75deg, rgba(52, 174, 243, 0.5) -85.53%, rgba(30, 101, 141, 0.11) 64.79%);
  border: 1px solid #34AEF3;
  padding: 8px 13px;
  border-radius: 10px;
  width: 130px;
`;
export const NewTag = styled.div`
  position: absolute;
  padding: 2px 7px 2px 7px;
  border-radius: 28px;
  background: #34AEF3;
  font-size: 12px;
  color: #FFFFFF;
  top: -12px;
  right: -10px;
`;
export const CountText = styled.div`
  font-size: 14px;
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
`;
export const DividerStyled = styled(Divider)`
  height: 65px;
  background: #4E5156;
  margin: 10px;
  @media screen and (max-width: 605px) {
    display: none;
  }
`;

export const SwitchButtonRight = styled.div<{selected?: boolean}>`
  padding: 5px 10px 6px;
  gap: 20px;
  width: 73px;
  height: 28px;
  background: ${p => p.selected ? 'rgba(127, 78, 173, 0.17)' : 'rgba(78, 81, 86, 0.5)'};
  border: ${p => p.selected ? '1px solid rgba(127, 78, 173, 0.75)' : ''};
  border-radius: 6px 0px 0px 6px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const RadioStyled = styled(Radio)`
  color: #fff;
  white-space: nowrap;
  .ant-radio-inner {
    background-color: #4E5156 !important;
    border-color: #4E5156 !important;
    box-shadow: initial !important;
  }
  .ant-radio-checked {
    .ant-radio-inner {
    background-color: #7F4EAD !important;
    border-color: #7F4EAD !important;
    &::after {
      background-color: #fff !important;
    }
  }
  }
  @media screen and (max-width: 600px) {
    .ant-radio-wrapper {
      font-size: 12px;
    }
  }
`;
export const SwitchButtonLeft = styled.div<{selected?: boolean}>`
  padding: 5px 10px 6px;
  height: 28px;
  border-radius: 6px 0px 0px 6px;
  color: #fff;
  padding: 5px 10px 6px;
  gap: 6px;
  width: 49px;
  background: ${p => p.selected ? 'rgba(127, 78, 173, 0.17)' : 'rgba(78, 81, 86, 0.5)'};
  border: ${p => p.selected ? '1px solid rgba(127, 78, 173, 0.75)' : ''};
  border-radius: 0px 6px 6px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ButtonStyled = styled(Button)`
  border: 1px solid #4E5156;
  margin: 0 !important;
  height: 32px;
`;

export const LargeCard = styled.div`
  background: #282B2F !important;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  padding: 20px 20px;
  margin-top: 15px;
  height: 370px;
  @media (max-width: 1025px) {
    height: 100% !important;
    .country-contribution {
      display: block;
    }
  }
`;

export const DarkTableStyled = styled(Table)`
width: 400px !important;
color: #fff !important;

@media (max-width: 1025px) {
  width: 100% !important;
  margin-bottom: 20px;
}

.ant-empty-description {
  color: #ffffff;
}
th {
  background: rgba(78, 81, 86, 0.5) !important;
  color: #fff !important;
}
.ant-table-tbody tr td {
  background: #282B2F !important;
  color: #fff !important;
}

 .ant-table-body {
  &::-webkit-scrollbar {
      height: 2px;
      width: 4px;
      border-radius: 50%;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #313439;
    }
  
    &::-webkit-scrollbar-track {
      background: #282B2F;
    }
}

.ant-table-content {
  border-radius: 6 !important;
  height: '300px' !important;
}

.ant-table-column-sorters-with-tooltip:not(:first-child) {
  text-align: center;
}

table {
  background: #282B2F !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 0 !important;
  .ant-table-cell-scrollbar {
    display: none;
  }

  th::before {
    width: 0 !important;
  }

  .ant-table-column-sorter {
    display: flex;
    padding-left: 3px !important;
  }

  .ant-table-column-sorters {
    display: inline-flex !important;
    padding: 5px !important;
  }

  tr td {
    &:first-child {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  .ant-table-tbody {
    tr {

      td {
        box-shadow: none;
        font-weight: 400;
        white-space: nowrap;
      }

      &:hover {
        td {
          background: #3b4046 !important;
          cursor: pointer !important;
        }
      }
    }
  }

  .ant-table-thead {
    th {
     background: rgba(78, 81, 86, 0.5) !important;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: 0 !important;
    }

    .ant-table-cell-fix-left {
      text-align: left !important;

      &:first-child {
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
      }
    }

    th:first-child {
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
    }

    th:nth-child(2) {
      font-size: 12px;
      font-weight: 600;
    }

    th:last-child {
      border-bottom-right-radius: 8px !important;
      border-top-right-radius: 8px !important;
        }
  }

  .ant-table-cell-fix-left-last {
    text-align: left !important;
  }

  tr th {
    height: 50px !important;
    line-height: 22px;
    white-space: nowrap;
  }

  .ant-column-sorters {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  tr td {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
  }

  tr th:first-child,
  tr td:first-child {
    padding-left: 25px !important;
    padding-right: 25px !important;
    background: #4E5156;
  }

  tr th:last-child,
  tr td:last-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
`;

export const TitleWhite = styled.div<{size?: string}>`
font-weight: 600;
font-size: ${p=> p?.size ? p?.size : '16px'};
line-height: 22px;
color: #FFFFFF;
`;
export const TitleBlack = styled.div<{isResponsive?: boolean}>`
font-weight: 500;
font-size: 14px;
line-height: 22px;
color: #121212;
  ${p => p?.isResponsive ? `
    position: absolute;
    top: 15px;
    left: 20px;
    @media screen and (max-width: 505px) {
      top: 6px;
    }
  ` : ''}
`;

export const ContentContainet = styled.div`
  margin: -80px 40px 40px 40px;
  @media screen and (max-width: 768px) {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
`;

export const FlexCardsWrapper = styled.div<{isResponsive?: boolean; height?: string}>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  height: ${p => p?.height ? p?.height : ''};
  .kw-intent-responsive {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  ${p => p.isResponsive ?
    `@media only screen and (max-width: 824px) {
      display: block;
      width: 100%;
      height: 100%;
      .kw-intent-responsive {
        width: 100%;
      }
    }`:
    ''}
    .keyword-intent-wrapper {
        display: none;
    }
    .branded-traffic-wrapper {
      display: none;

    }
    @media screen and (max-width: 824px) {
      .keyword-intent-wrapper {
        display: block;
      }
      .branded-traffic-wrapper {
        display: block;
      }
      .top-keyword-intent-wrapper {
        display: none;
      }
      .top-branded-traffic-wrapper {
        display: none;
      }
    }
`;

export const WhiteCard = styled.div<{width?: string; margin?: string; position?: string; isResponsive?: boolean}>`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  width: ${p => p.width ?? '50%'};
  ${p => p?.margin && `margin: ${p?.margin}`};
  ${p => p?.position && `position: ${p?.position}`};
  ${p => p.isResponsive ?
    `@media only screen and (max-width: 824px) {
      margin-bottom: 8px;
      width: 100%;
    }`:
    ''}
  .custom-column-width {
    max-width: 320px;
    text-overflow: ellipsis;
    min-width: 160px;
    @media screen and (max-width: 450px) {
      max-width: 200px;
   }
  }
  .ant-btn, .ant-btn-default {
    margin-left: 0 !important;
    @media screen and (max-width: 768px) {
       margin: 0 !important;      
    }
  }
`;
export const Intent = styled.div<{intent: any}>`
display: flex;
justify-content: center;
align-items: center;
margin-left: 9px;
width: fit-content;
padding: 4px 10px;
height: 23px;
border-radius: 4px;
text-transform: capitalize;
font-weight: 500;
font-size: 13px;
line-height: 16px;
background: ${p=> p?.intent?.background};
color: ${p=> p?.intent?.color};
`;
export const CardsContainer = styled.div`
display: flex;
flex-wrap: wrap;
gap: 15px;
justify-content: space-between;
@media only screen and (max-width: 824px) {
  display: block;
}
`;
export const BoxEmpty = styled.div`
width: 370px;
`;
export const EmptyStyled = styled(Empty)`
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: #A3A4A4;
`;
export const BoxTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #144198;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const BoxLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #0C872F;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  margin-top: 3px;
`;

export const Description = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-top: 9px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

export const Box = styled.div<{isAdsSection?: boolean}>`
width: 370px;
height: ${p=> p?.isAdsSection ? '100%' : '165px'};
background: #F9F9FB;
border: 1px solid #E8E8E8;
box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
border-radius: ${p=> p?.isAdsSection ? '12px' : '8px'};
padding: 20px;
@media only screen and (max-width: 824px) {
  width: 100%;
  margin-bottom: 8px;
}
`;
export const TypeWrapper = styled.div<{background?: any}>`
display: flex;
justify-content: center;
align-items: center;
padding: 7px 12px 8px;
gap: 6px;
width: fit-content;
background: ${p=> `${p?.background}12`};
border-radius: 6px;
`;
export const LargerDivider = styled.div<{color?: string}>`
width: auto;
height: 2px;
background: ${p=> p?.color ? p?.color : '#34AEF3'};
margin-top: 9px;
margin-bottom: 17px;
`;
export const BlueText = styled.div<{color?: string; cursor?: string}>`
 color: ${p=> p?.color ? p?.color : '#2D6CCA'};
 line-height: 17px;
 font-size: 14px;
 cursor: ${p=> p?.cursor ? p?.cursor : 'pointer'};
`;

export const TabsStyled = styled.div<{isBacklinkPage?: boolean}>`
padding: 0 40px 20px 40px;
 @media only screen and (max-width:768px) {
padding: 0 15px 20px 15px;
 }
  .rc-tabs-nav {
    border-bottom: ${p => p?.isBacklinkPage ? '' : '1px solid #4E5156'};
    height: 70px !important;
  }
  .rc-tabs-content-holder {
    border-radius: 13px;
    background: transparent !important;
  }
  .rc-tabs-tab-btn {
    font-size: 16px !important;
    color: #A3A4A4 !important;
  }
  .rc-tabs-tab-active {
    .rc-tabs-tab-btn  {
     font-size: 16px !important;
     font-weight: 500 !important;
     line-height: 22px !important;
     color: #fff !important;
    }
  }
  .rc-tabs-ink-bar {
    position: absolute !important;
    bottom: 0px;
    height: 5px !important;
    background: #7F4EAD !important;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02) !important;
    border-radius: 3px 3px 0px 0px !important;
  }
`;
export const TooltipContent = styled.div`
  background-color: #121212 !important;
  display: flex;
  flex-direction: column;
`;
export const TooltipSimpleText = styled.div`
  width: 200px;
  white-space: break-spaces !important;
  @media screen and (max-width: 930px) {
    width: 140px;
  }
`;
export const TooltipTitle = styled.div`
  font-size: 12px;
  color: #121212;
  font-weight: 600;
`;
export const TooltipItems = styled.div`
 display: flex;
 flex-direction: column;
`;
export const TooltipItem = styled.div`
 display: flex;
 align-items: center;
 font-size: 12px;
`;

export const PaginationStyled = styled(Pagination)`
  padding: 20px 45px 20px 25px;
  text-align: right;
  margin-top: 10px;
  border-top: 1px solid #E8E8E8!important;


.ant-pagination-item{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-prev {
  width:25px !important;
  height:25px !important;
}
.ant-pagination-next{
  width:25px !important;
  height:25px !important;
}
.ant-pagination-disabled{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-item-link  span  svg {
  display:flex;
  margin-bottom:2px;
  align-items:center;
  justify-content:center;
}
.ant-pagination-prev{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-next{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-options{
  height:25px;
}
.ant-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top:10px;
  margin-right:15px;
  
}
`;
export const StyledImg = styled.img`
  width: 147px;
  height: 84px;
  border-radius: 8px;
  margin-right: 9px;
  object-fit: cover;
  object-position: 0 0;
  @media screen and (max-width: 529px) {
    width: 100%;
    object-fit: cover;
  }
`;
export const HomePageStyledImg = styled.img<{screenshotUrl?: boolean}>`
  width: 147px;
  height: 84px;
  border-radius: 8px;
  margin-right: 9px;
  @media only screen and (max-width: 1214px) {
  ${p => p.screenshotUrl ? `
    max-height: 300px;
    width: 100%;
    height: auto;
  ` : `
    margin-top: 15px;
    min-height: 150px;
    max-width: 351px;
    min-width: 234px;
    object-fit: cover;
  `}
  }

  @media screen and (max-width: 724px) {
    max-height: 300px;
    width: 100%;
    height: auto;
    max-width: 100%;
  }
`;

export const MetaDescription = styled.div`
  max-width: 520px;
  line-height: 20px;
  color: #A3A4A4;
  font-size: 14px;
`;
export const AnchorTag = styled.a`
 color: #2D6CCA !important;
 font-size: 14px !important;
 display: block;
 overflow: hidden;
 text-overflow: ellipsis;
`;
export const CheckboxStyled = styled(Checkbox)<{color}>`
.ant-checkbox-inner {
    background-color:${p => p.color};
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color:${p => p.color};
    border-color: ${p => p.color};
    border-radius: 4px;
    margin-right: 4px;
}
`;
export const CheckboxAbsoluteWrapper = styled.div`
 display: flex;
 position: absolute;
 right: 20px;
 top: 15px;
 z-index: 1;
 @media screen and (max-width: 505px) {
  top: 25px;
  right: 0px;
  left: 0px;
 }
`;

export const BoxStyled = styled.div`
width: 147px;
height: 60px;
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
margin-right: 20px;
`;
export const CompetitorWrapper = styled.div`
  display: flex;
  align-items: center;
  div {
    cursor: pointer;
    color: #2D6CCA;
    font-size: 14px;
  }
  a {
    color: #a3a4a4 !important;
    font-size: 14px;
    &:hover {
      color: #2D6CCA !important;
    }
  }
  .link {
    cursor: pointer;
    color: #2D6CCA !important;
    font-size: 14px;
  }
`;
export const HeaderCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7f4ead;
    border-color: #7f4ead;
  }
`;
export const HeadingWrapper = styled.div`
font-weight: 700;
font-size: 12px;
line-height: 14px;
color: #121212;
`;
export const StyledLeftWrapper = styled.div`
 display: flex;
 align-items: center;
   p {
    color: #4E5156;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
   }
`;
export const SelectedSpan = styled.span`
    font-weight: 600;
    color: #121212;
    margin-left: 5px;
`;
export const StyledDeselect = styled.span`
color: #2D6CCA;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
margin-left: 10px;
`;
export const ButtonWrapper = styled(Button)<{border?: boolean}>`
margin-top: 15px;
margin-bottom: 15px;
margin-right: 0;
width: fit-content;
height: 32px;
border: ${p => p?.border ? '1px solid #E8E8E8' : ''};
background: ${p => p?.border ? '#F7F7F8' : ''};
&:hover {
  color:  ${p => p?.border ? '#121212' : ''};
}
`;
export const ListContainer = styled.div`
  max-height: 280px;
  min-height: 100px;
  overflow: auto;
  margin-top: 10px;;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;
export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  min-height: 25px;
  cursor: pointer;
  &:hover {
    background-color: #2D6CCA1A;
  }
`;
export const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #121212;
  display: flex;
  align-items: center;
`;
export const Value = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #4E5156;
`;
export const ProjectSelectMenu = styled(Menu)`
padding: 10px 0px;
.ant-dropdown-menu-item {
padding: 0px;
background-color: #fff;
&:hover {
  background-color: #fff;
}
}
`;
export const SearchContainer = styled.div`
height: 38px;
margin: 0px 10px;
background: #F2F2F5;
border-radius: 8px;
`;
export const InfoContainer = styled.div`
height: 40px;
display: flex;
margin: 8px 10px;
background: #34AEF333;
border-radius: 8px;
align-items: center;
padding: 7px 12px;
gap: 10px;
svg {
  g {
    .fa-secondary {
      fill: #2D6CCA;
    }
    .fa-primary {
      fill: rgb(45, 108, 202);
    }
  }
}
`;
export const InfoText = styled.div`
color: #121212;
font-family: 'Inter', sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
width: 190px;
line-height: 13px;
`;
export const MenuItem = styled(Menu.Item)``;

