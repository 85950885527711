import {types} from 'mobx-state-tree';

const TABLE =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

export enum DEVICE {
  desktop = 'desktop',
  ios = 'ios',
  android = 'android',
}

interface DEVICE_CONFIG {
  adTest: string;
  userAgent: string;
}

export const LocalSearchResultStore = types
  .model({
    location: types.string,
    query: types.string,
    countryCode: types.string,
    device: types.enumeration('DEVICE', Object.values(DEVICE)),
    url: types.string,
  })
  .views(self => ({
    get isMobile(): boolean {
      return self.device === DEVICE.android || self.device === DEVICE.ios;
    },
  }))
  .actions(self => {
    const setData = (location: string, query: string, countryCode: string) => {
      self.location = location;
      self.query = query;
      self.countryCode = countryCode;
    };
    const setDevice = (device: DEVICE) => {
      self.device = device;
    };

    const generateUrl = () => {
      if (!self.location || !self.query) return '';
      const uule = generateUule(self.location);
      const {adTest, userAgent} = generateDeviceConfig(self.device);

      self.url = `https://www.google.com/search?q=${self.query}&ip=0.0.0.0&source_ip=0.0.0.0&gl=&hl=en&igu=1&num=100&pws=0&uule=${uule}&gl=${self.countryCode}&adtest=${adTest}&adtest-useragent=${userAgent}`;
    };

    const generateUule = (location: string) => {
      const len = location?.length;
      const key = TABLE[len];
      return (
        'w+CAIQICI' + key + Buffer.from(location, 'ascii').toString('base64')
      );
    };

    const generateDeviceConfig = (device: DEVICE): DEVICE_CONFIG => {
      switch (device) {
        case DEVICE.desktop:
          return {adTest: 'off', userAgent: 'current'};
        case DEVICE.ios:
          return {
            adTest: 'on',
            userAgent:
              'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
          };
        case DEVICE.android:
          return {
            adTest: 'on',
            userAgent:
              'Mozilla/5.0 (Linux; Android 8.0; Pixel 2 Build/OPD3.170816.012) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/78.0.3904.87 Mobile Safari/537.36',
          };
      }
    };

    return {setData, setDevice, generateUrl, generateUule, generateDeviceConfig};
  });

export const initLocalSearchResultStore = () => {
  return LocalSearchResultStore.create({
    location: '',
    query: '',
    countryCode: '',
    device: DEVICE.desktop,
    url: '',
  });
};
