import {contentReWritingApi} from '@/api/account';
import {cast, flow, types} from 'mobx-state-tree';

const contentRewriteModel = types.model({
  keyword: types.maybeNull(types.string),
  html: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  results: types.maybeNull(types.array(types.string)),
});
export const ContentRewritingStore = types.model({
  contentRewriteData: types.maybeNull(contentRewriteModel),
  rewritingContent: types.boolean,
}).actions(self => {
  const contentRewriting = flow(function* (value: any) {
    self.rewritingContent = true;
    try {
      const payload = {
        keyword: value,
        type: 'rewrite_content',
      };
      const response = yield contentReWritingApi.getContentRewritingResponse(payload);
      if (response?.isCancel) return;
      self.contentRewriteData = cast(response);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.rewritingContent = false;
    }
  });
  return {
    contentRewriting,
  };
});

export const initContentRewritingStore = () => {
  return ContentRewritingStore.create({
    rewritingContent: false,
    contentRewriteData: {
      keyword: '',
      html: '',
      text: '',
      results: [],
    },
  });
};

