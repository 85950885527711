import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class ContentAssistantApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.SA_ENDPOINT, '/api');

  public async getLinksReport(siteProperty) {
    try {
      const response = await this.axios.get(`${ContentAssistantApi.baseUrl}/links-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const SITE_AUDITOR_API = new ContentAssistantApi();
