import {types, flow, cast, getRoot} from 'mobx-state-tree';
import {CONTENT_RESEARCHER_API} from '@/api/content-researcher';
import {notification as notify} from '@/utils/notification-v2';
import {isNil} from 'lodash';
import {CR_API_MAX_NUMBER_OF_POLLS} from '@/constants';


const svTopCountriesModel = types.model({
  countryCode: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
});

const topPagesModel = types.model({
  contentAnalysisProcessedAt: types.maybeNull(types.string),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  globalSv: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  kd: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  rankingPotential: types.maybeNull(types.string),
  serpTaskStatus: types.maybeNull(types.string),
  serpUpdatedAt: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  svTopCountries: types.maybeNull(types.array(svTopCountriesModel)),
  totalSearchResults: types.maybeNull(types.number),
});

const serpsModel = types.model({
  metaDesc: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const commonCitationsModel = types.model({
  count: types.maybeNull(types.number),
  metaDesc: types.maybeNull(types.string),
  serps: types.maybeNull(types.array(serpsModel)),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const competitorsModel = types.model({
  dr: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  contentScore: types.maybeNull(types.number),
  readability: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});

const keywordsModel = types.model({
  keywords: types.maybeNull(types.array(types.string)),
  seed: types.maybeNull(types.string),
});

const keywordDiscoveryModel = types.model({
  keywords: types.maybeNull(types.array(keywordsModel)),
  seed: types.maybeNull(types.string),
});

const similarKeywordsModel = types.model({
  cpc: types.maybeNull(types.number),
  histSv: types.maybeNull(types.array(types.number)),
  kd: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  ppcd: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  isKwIncluded: types.maybeNull(types.boolean),
});

const svTrendModel = types.model({
  date: types.maybeNull(types.string),
  volume: types.maybeNull(types.number),
});

const sitesModel = types.model({
  contentScore: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  readability: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});


const termsGridModel = types.model({
  contentScores: types.maybeNull(types.array(types.number)),
  focusTermUsageBySites: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    values: types.maybeNull(types.array(types.number)),
  }))),
  sites: types.maybeNull(types.array(sitesModel)),
});

const typicalTopModel = types.model({
  averageContentScores: types.maybeNull(types.number),
  averageDr: types.maybeNull(types.number),
  averageReadability: types.maybeNull(types.string),
  averageWordCount: types.maybeNull(types.number),
});


const keywordDetailModel = types.model({
  commonCitations: types.maybeNull(types.array(commonCitationsModel)),
  commonCitationsTaskStatus: types.maybeNull(types.string),
  competitors: types.maybeNull(types.array(competitorsModel)),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  kd: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  keywordDiscovery: types.maybeNull(types.array(keywordDiscoveryModel)),
  rankingPotential: types.maybeNull(types.string),
  serpTaskStatus: types.maybeNull(types.string),
  shouldRepoll: types.maybeNull(types.boolean),
  similarKeywords: types.maybeNull(types.array(similarKeywordsModel)),
  sv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  ads: types.maybeNull(types.number),
  svTrend: types.maybeNull(types.array(svTrendModel)),
  termsGrid: types.maybeNull(termsGridModel),
  totalSearchResults: types.maybeNull(types.number),
  typicalTop10: types.maybeNull(types.model({
    typicalTop10: types.maybeNull(typicalTopModel),
  })),
  updatedAt: types.maybeNull(types.string),
});

export const OverviewPageStore = types.model({
  loading: types.boolean,
  isSaving: types.boolean,
  isSaved: types.boolean,
  isExporting: types.boolean,
  activeCompetitorTab: types.boolean,
  activeKeywordtab: types.boolean,
  activeFocusTermsTab: types.boolean,
  activeOverviewTab: types.boolean,
  isRemoving: types.boolean,
  articleLoading: types.boolean,
  detailApiCall: types.boolean,
  reTry: types.optional(types.number, 0),
  targetKeywords: types.maybeNull(topPagesModel),
  keywordDetail: types.maybeNull(keywordDetailModel),
  isContentResearchDrawerOpen: types.maybeNull(types.boolean),
  filteredKeywords: types.maybeNull(types.array(types.string)),
  loadingArray: types.array(types.string),
}).views(self => ({
  get getTargetKeyword() {
    return self.targetKeywords;
  },
})).actions(self => {
  const loadTargetKeywordsList = flow(function* () {
    self.loading = true;
    try {
      yield CONTENT_RESEARCHER_API.getOverviewPage();
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });


  const addToLoadingArray = (keyword: string) => {
    if (keyword) {
      const loadingArr = [...self.loadingArray];
      loadingArr.push(keyword);
      self.loadingArray = cast(loadingArr);
    }
  };

  const removeFromLoadingArray = (keywords: string[]) => {
    if (!isNil(keywords)) {
      const loadingArr = [...self.loadingArray];
      const filteredArr = [];

      loadingArr.forEach(item => {
        if (!loadingArr.includes(item)) filteredArr.push(item);
      });

      self.loadingArray = cast(filteredArr);
    }
  };

  const saveKeyword = flow(function* (id) {
    self.isSaving = true;
    try {
      yield CONTENT_RESEARCHER_API.saveTargetKeyword(id);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.isSaving = false;
      self.isSaved = true;

      notify.success('Keyword Saved Successfully', '');
    }
  });

  const removeKeyword = flow(function* (id) {
    self.isRemoving = true;
    try {
      yield CONTENT_RESEARCHER_API.removeTargetKeyword(id);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.isRemoving = false;
    }
  });

  const updateKeywordId = data => {
    self.targetKeywords = data;
  };

  const setFilteredKeywords = data => {
    self.filteredKeywords = cast([...(data ?? [])]);
  };


  const findNewTargetKeyword = flow(function* (data) {
    self.loading = true;
    self.isSaved = false;
    self.keywordDetail=null;
    let getData = null;
    try {
      const response = yield CONTENT_RESEARCHER_API.findTargetKeyword(data);
      getData = response;
      self.targetKeywords = response;
    } catch (e) {
      if (e?.response?.status && e?.response?.status === 429) {
        const rootStore = getRoot(self) as any;
        rootStore.plans.showSidebarPaymentDrawer(null, {title: 'You need more Focus Terms quota to continue', subtitle: '',
          text: `Current limit: ${rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedFocusTerms?.total} lookups`});
        notify.error('', e?.response?.data?.message, false);
        self.loading = false;
      } else {
        return Promise.reject(e);
      }
    }
    return getData;
  });

  const setDetailApiCall = stopApi => {
    self.detailApiCall = stopApi;
  };

  const getKeywordDetail = flow(function* (id) {
    self.loading = true;
    try {
      const response = yield CONTENT_RESEARCHER_API.getTargetKeywordDetail(id);
      if (response.shouldRepoll && !self.detailApiCall) {
        self.reTry = cast(self.reTry + 1);
        if (self.reTry <= CR_API_MAX_NUMBER_OF_POLLS) {
          yield new Promise(r => setTimeout(r, 2000));
          self.keywordDetail = cast(response);
          return getKeywordDetail(id);
        } else {
          self.reTry = 0;
          self.keywordDetail = cast(response);
          self.loading = false;
          self.detailApiCall = false;
        }
      } else {
        self.reTry = 0;
        self.keywordDetail = cast(response);
        self.loading = false;
      }
      self.loading = false;
    } catch (e) {
      notify.error('Keyword data failed processing', 'Our developers are working hard to fix the issues. Thank you for your patience.', false);
      return Promise.reject(e);
    }
  });

  const createNewArticle = flow(function* (id) {
    self.articleLoading = true;
    let data = null;
    try {
      const response = yield CONTENT_RESEARCHER_API.getNewArticleDetail(id);
      data = response?.uuid;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.articleLoading = false;
    }
    return data;
  });


  const openContentResearchDrawer = () => {
    self.isContentResearchDrawerOpen = true;
  };

  const closeContentResearchDrawer = () => {
    self.isContentResearchDrawerOpen = false;
  };

  const downloadFileToTxt = () => {
    const keyword = `KEYWORD: ${self.keywordDetail?.keyword}`;
    const countryCode = `COUNTRY CODE: ${self.keywordDetail?.countryCode}`;
    const keywordDifficulty = `KEYWORD DIFFICULTY: ${self.keywordDetail?.kd}`;
    const SearchVolume = `KEYWORD SEARCH VOLUME: ${self.keywordDetail?.sv}`;
    const rankingPotential = `RANKING POTENTIAL: ${self.keywordDetail?.rankingPotential}`;
    const totalSearchResults = `TOTAL SEARCH RESULTS: ${self.keywordDetail?.totalSearchResults}`;
    const commonCitations = self.keywordDetail?.commonCitations?.length ? self.keywordDetail?.commonCitations?.map(item => `\n** TITLE: ${item.title.trim()}\n COUNT: ${item.count};\n META DESCRIPTION: ${item.metaDesc};\n SERPS DATA: ${item.serps?.map(obj =>
      `META DESCRIPTION: ${obj.metaDesc};
    POSITION: ${obj.position};
    TITLE: ${obj.title};
    URL: ${obj.url}`).join('\n')}
    
    `).join('\n') : '';

    const competitors = self.keywordDetail?.competitors?.length ? self.keywordDetail?.competitors?.map(item => `\n** CONTENT SCORE: ${item.contentScore}
    DOMAIN RATING: ${item.dr};
    HOST NAME: ${item.hostname};
    POSITION: ${item.position};
    READABILITY: ${item.readability};
    TITLE: ${item.title};
    TRAFFIC: ${item.traffic};
    URL: ${item.url};
    WORD COUNT: ${item.wordCount};
    
    `).join('\n') : '';

    const similarKeywords = self.keywordDetail?.similarKeywords?.length ? self.keywordDetail?.similarKeywords?.map(item => `\n** CPC: ${item.cpc}
    HIST SV: ${item.histSv?.map(item => `${item}`)};
    KD: ${item.kd};
    KEYWORD: ${item.keyword};
    POSITION: ${item.position};
    PPCD: ${item.ppcd};
    SV: ${item.sv};
    
    `).join('\n') : '';

    const svTrend = self.keywordDetail?.svTrend?.length ? self.keywordDetail?.svTrend?.map(item => `\n** DATE: ${item.date}
    VOLUME: ${item.volume};
    `).join('\n') : '';

    const contentScores = self.keywordDetail?.termsGrid?.contentScores?.length ? self.keywordDetail?.termsGrid?.contentScores?.map(item => `${item}`) : '';

    const focusTermUsageBySites = self.keywordDetail?.termsGrid?.focusTermUsageBySites?.length ? self.keywordDetail?.termsGrid?.focusTermUsageBySites?.map(item => `\n* NAME: ${item.name}
    VALUES: ${item.values?.map(item => `${item}`)};
    
    `).join('\n') : '';

    const sites = self.keywordDetail?.termsGrid?.sites?.length ? self.keywordDetail?.termsGrid?.sites?.map(item => `\n* CONTENT SCORE: ${item.contentScore}
    HOST NAME: ${item.hostname};
    READABILITY: ${item.readability};
    URL: ${item.url};
    WORD COUNT: ${item.wordCount};
    
    `).join('\n') : '';

    const averageContentScores = `KEYWORD: ${self.keywordDetail?.typicalTop10?.typicalTop10?.averageContentScores}`;
    const averageDr = `COUNTRY CODE: ${self.keywordDetail?.typicalTop10?.typicalTop10?.averageDr}`;
    const averageReadability = `KEYWORD DIFFICULTY: ${self.keywordDetail?.typicalTop10?.typicalTop10?.averageReadability}`;
    const averageWordCount = `KEYWORD SEARCH VOLUME: ${self.keywordDetail?.typicalTop10?.typicalTop10?.averageWordCount}`;

    const convertedText = `##PAGE INFORMATION \n\n${keyword}\n${countryCode}\n${keywordDifficulty}\n${SearchVolume} \n${rankingPotential}\n${totalSearchResults}
    \n\n\n\n\n\n##COMMON CITATIONS: \n ${commonCitations}\n\n\n\n\n\n##COMPETITORS: \n${competitors}\n\n\n\n\n\n\n##SIMILAR KEYWORDS: ${similarKeywords}\n\n\n\n\n\n\n##SIMILAR KEYWORDS: ${svTrend}
    \n\n\n\n\n\n##TERMS GRID: \n **CONTENT SCORE: \nDATA: ${contentScores}
    \n\n**FOCUS TERMS USAGE BY SITES:${focusTermUsageBySites}
    \n**SITES: ${sites}
    \n\n\n\n\n\n##TYPICAL TOP 10: 
    \nAVERAGE CONTENT SCORE: ${averageContentScores}
    \nAVERAGE DR: ${averageDr}
    \nAVERAGE READABILITY: ${averageReadability}
    \nAVERAGE WORD COUNT: ${averageWordCount}`;

    const element = document.createElement('a');
    const file = new Blob([convertedText], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `ContentResearcher.txt`;
    document.body.appendChild(element);
    element.click();
  };


  const setExportPDF = exporting => {
    self.isExporting = exporting;
  };
  const setActiveCompetitorTab = (activeCompetitorTab:boolean) => {
    self.activeCompetitorTab = activeCompetitorTab;
  };
  const setActiveFocusTermsTab = (activeFocusTermsTab:boolean) => {
    self.activeFocusTermsTab = activeFocusTermsTab;
  };
  const setActiveOverviewTab = (activeOverviewTab:boolean) => {
    self.activeOverviewTab = activeOverviewTab;
  };

  const setActiveKeywordtab = (activeKeywordtab:boolean) => {
    self.activeKeywordtab = activeKeywordtab;
  };

  const clearTableData = ()=>{
    self.filteredKeywords = cast([]);
  };


  return {
    loadTargetKeywordsList,
    findNewTargetKeyword,
    updateKeywordId,
    getKeywordDetail,
    createNewArticle,
    openContentResearchDrawer,
    setFilteredKeywords,
    closeContentResearchDrawer,
    downloadFileToTxt,
    saveKeyword,
    removeKeyword,
    setExportPDF,
    setActiveCompetitorTab,
    setActiveKeywordtab,
    setActiveFocusTermsTab,
    setActiveOverviewTab,
    addToLoadingArray,
    removeFromLoadingArray,
    setDetailApiCall,
    clearTableData,
  };
});

export function initOverviewPageStore() {
  return OverviewPageStore.create({
    loading: true,
    isSaving: false,
    isSaved: false,
    articleLoading: false,
    isRemoving: false,
    isExporting: false,
    activeCompetitorTab: false,
    activeKeywordtab: false,
    activeFocusTermsTab: false,
    activeOverviewTab: false,
    isContentResearchDrawerOpen: false,
    loadingArray: [],
    detailApiCall: false,
  });
}
