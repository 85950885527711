import {types, flow, cast, Instance} from 'mobx-state-tree';
import {reportsApi} from '@/api/gsc/index';
import {getFilter, formatSorters} from '@/utils/filters';
import {filterDataInMemory} from '@/utils/filter-data';
import {CriteriaType} from '@/store//gsc-store/criteria';
import {notification} from '@/utils/notification-v2';
import {getPrecedingDate} from '@/utils/arrays';
import {toJS} from 'mobx';
import {getTokenFromCookies} from '@/api/common-utils';
import {apiError} from '@/utils/api';
import {getSingleUrlParam} from '@/utils/url';

const INITIAL_FILTER_LIST = [
  {id: 1, name: 'countryCode', header: 'Country', customFilterValue: '', type: 'customFilter', active: true},
  {id: 2, name: 'startDate', header: 'Start Date', customFilterValue: '', type: 'customFilter', active: true},
  {id: 3, name: 'endDate', header: 'End Date', customFilterValue: '', type: 'customFilter', active: true},
  {id: 4, name: 'exclude', header: 'Page Url', filterField: 'page', text: '', type: undefined, active: false, isSearch: true, customFields: [
    {label: 'Contains', operator: 'contains'},
    {label: 'Doesn’t contain', operator: 'does_not_contains'},
    {label: 'Starts With', operator: 'starts_with'},
    {label: 'Ends With', operator: 'ends_with'},
  ]},
  {id: 5, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'},
  ]},
  {id: 6, name: 'posCur', header: 'Avg. Position', from: undefined, to: undefined, type: undefined, active: false,
    customOptionsTop: [
      {name: 'Top 3', min: '1', max: '3'},
      {name: 'Top 10', min: '1', max: '10'},
      {name: 'Top 20', min: '1', max: '20'},
      {name: 'Top 50', min: '1', max: '50'},
    ], customOptions: [
      {name: '#1', min: '0', max: '1'},
      {name: '#4-10', min: '4', max: '10'},
      {name: '#11-20', min: '11', max: '20'},
      {name: '#21-50', min: '21', max: '50'},
      {name: '#51-100', min: '51', max: '100'},
    ]},
  {id: 7, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '1,000,001+', min: '1000001', max: ''},
    {name: '100,001 - 1,000,000', min: '100001', max: '1000000'},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '0 - 100', min: '0', max: '100'},
  ]},
  {id: 8, name: 'exclude', header: 'Top Keyword', filterField: 'topKeyword', text: '', type: undefined, active: false, customFields: [
    {label: 'Contains', operator: 'contains'},
    {label: 'Doesn’t contain', operator: 'does_not_contains'},
    {label: 'Starts With', operator: 'starts_with'},
    {label: 'Ends With', operator: 'ends_with'},
  ]},
  {id: 9, name: 'radioButtonsFilter', header: 'Watchlisted', filterTypes: [{label: 'Yes', value: 'exclude'}, {label: 'No', value: ''}], type: undefined, active: false},
  {id: 10, name: 'opportunityScore', header: 'SEO Potential', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: 'High', min: '71', max: '100', showPercent: true},
    {name: 'Medium', min: '31', max: '70', showPercent: true},
    {name: 'Low', min: '0', max: '30', showPercent: true},
  ]},
  {id: 11, name: 'keywordsCount', header: 'Total Keywords', from: undefined, to: undefined, type: undefined, active: false},
  {id: 12, name: 'users', header: 'Users', from: undefined, to: undefined, type: undefined, active: false},
  {id: 13, name: 'sessions', header: 'Sessions', from: undefined, to: undefined, type: undefined, active: false},
  {id: 14, name: 'bounces', header: 'Bounces', from: undefined, to: undefined, type: undefined, active: false},
  {id: 15, name: 'sessionDuration', header: 'Session Duration', from: undefined, to: undefined, type: undefined, active: false},
  {id: 16, name: 'goalCompletionsAll', header: 'goal Completions', from: undefined, to: undefined, type: undefined, active: false},
  {id: 17, name: 'uniquePurchases', header: 'Unique Purchases', from: undefined, to: undefined, type: undefined, active: false},
  {id: 18, name: 'avgSessionDuration', header: 'Avg. Session Duration', from: undefined, to: undefined, type: undefined, active: false},
  {id: 19, name: 'newUsers', header: 'New Users', from: undefined, to: undefined, type: undefined, active: false},
  {id: 20, name: 'avgTimeOnPage', header: 'Avg. Time on Page', from: undefined, to: undefined, type: undefined, active: false},
  {id: 21, name: 'pageViews', header: 'Page Views', from: undefined, to: undefined, type: undefined, active: false},
  {id: 22, name: 'totalEvents', header: 'Total Events', from: undefined, to: undefined, type: undefined, active: false},
  {id: 23, name: 'transactionRevenue', header: 'Revenue', from: undefined, to: undefined, type: undefined, active: false},
  {id: 24, name: 'bounceRate', header: 'Bounce Rate', from: undefined, to: undefined, type: undefined, active: false},
  {id: 25, name: 'goalConversionRateAll', header: 'Goal Conversion Rate', from: undefined, to: undefined, type: undefined, active: false},
];
const ga4MetricsModel = types.model({
  name: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});
const pagesData = types.model({
  clicksCur: types.maybeNull(types.number),
  impressionDelta: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  serp13: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
  users: types.maybeNull(types.number),
  sessions: types.maybeNull(types.number),
  bounces: types.maybeNull(types.number),
  sessionDuration: types.maybeNull(types.number),
  goalCompletionsAll: types.maybeNull(types.number),
  uniquePurchases: types.maybeNull(types.number),
  avgSessionDuration: types.maybeNull(types.number),
  newUsers: types.optional(types.maybeNull(types.number), null),
  avgTimeOnPage: types.optional(types.maybeNull(types.number), null),
  pageViews: types.optional(types.maybeNull(types.number), null),
  totalEvents: types.optional(types.maybeNull(types.number), null),
  transactionRevenue: types.optional(types.maybeNull(types.number), null),
  bounceRate: types.optional(types.maybeNull(types.number), null),
  goalConversionRateAll: types.optional(types.maybeNull(types.number), null),
  ga4Metrics: types.maybeNull(types.array(ga4MetricsModel)),
});
export type PagesTableType = Instance<typeof pagesData>;

const PageGroupsData = types.model({
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const filterList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  text: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean),
  filterField: types.maybeNull(types.string),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.maybeNull(types.string),
    showPercent: types.maybeNull(types.boolean),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.maybeNull(types.string),
    showPercent: types.maybeNull(types.boolean),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
});

const pagesTableDateRangeModel = types.model({
  pagesPeriod1Start: types.maybeNull(types.string),
  pagesPeriod1End: types.maybeNull(types.string),
  pagesPeriod2Start: types.maybeNull(types.string),
  pagesPeriod2End: types.maybeNull(types.string),
});

const updatePage=types.model({
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
});

export const pagesSearchTable = types.model({
  pages: types.array(pagesData),
  updatePages: types.maybeNull(types.array(updatePage)),
  pageGroups: types.array(PageGroupsData),
  pagesCount: types.maybeNull(types.number),
  pageGroupsCount: types.maybeNull(types.number),
  pagesLoading: types.boolean,
  pageGroupsLoading: types.boolean,
  isPagesInit: types.boolean,
  isPageGroupsInit: types.boolean,
  pageNumber: types.number,
  pageGroupsNumber: types.number,
  pageSize: types.number,
  pageGroupsSize: types.number,
  filterList: types.array(filterList),
  pageGroupsFilterList: types.array(filterList),
  sortField: types.maybeNull(types.string),
  pageGroupsSortField: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  pageGroupsSortDirection: types.maybeNull(types.string),
  searchTerm: types.maybeNull(types.string),
  pageGroupsSearchTerm: types.maybeNull(types.string),
  exportToCSVLoader: types.boolean,
  exportLoader: types.boolean,
  pagesTableDateRange: types.maybeNull(pagesTableDateRangeModel),
  shouldShowGaBanner: types.boolean,
}).views(self => ({
  get pagesData() {
    return toJS(self.pages);
  },
  get pageGroupsData() {
    return self.pageGroups;
  },
  get getUpdatePages() {
    return toJS(self.updatePages);
  },
  get getFilterList() {
    return toJS(self.filterList);
  },
})).actions(self => {
  const loadPages = flow(function* (component: 'pageGroups' | 'pages', {property, countryCode, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, pageNumber, pageSize, filters, filterKeywordTerm}) {
    const sorters = {
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      pageCur: 'page',
      top_keyword: 'top_keyword',
      keywords_count: 'keywords_count',
      opportunity_score: 'opportunity_score',
      watchlist: 'watchlist',
    };
    const pageGroupsSorters = {
      page: 'page',
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
    };

    const formtatedSorters = formatSorters(sorters, self.sortField, self.sortDirection);
    const pageGroupsFormtatedSorters = formatSorters(pageGroupsSorters, self.pageGroupsSortField, self.pageGroupsSortDirection);
    const getStartDate = getFilter(filters, 'startDate')?.customFilterValue ? getFilter(filters, 'startDate')?.customFilterValue : currentPeriodStart;
    const getEndDate = getFilter(filters, 'endDate')?.customFilterValue ? getFilter(filters, 'endDate')?.customFilterValue : currentPeriodEnd;

    const pagesPeriod1Start = getPrecedingDate(getStartDate, getEndDate)?.previousStartDate ?? previousPeriodStart;
    const pagesPeriod1End = getPrecedingDate(getStartDate, getEndDate)?.previousEndDate ?? previousPeriodEnd;

    self.pagesTableDateRange = {
      pagesPeriod1Start: pagesPeriod1Start,
      pagesPeriod1End: pagesPeriod1End,
      pagesPeriod2Start: getStartDate,
      pagesPeriod2End: getEndDate,
    };
    const publicHash = getSingleUrlParam('hash') || getSingleUrlParam('public_hash');
    const params = {
      selected_property: property,
      period1_start: pagesPeriod1Start ?? previousPeriodStart,
      period1_end: pagesPeriod1End ?? previousPeriodEnd,
      period2_start: getStartDate ?? currentPeriodStart,
      period2_end: getEndDate ?? currentPeriodEnd,
      country_code: getFilter(filters, 'countryCode')?.customFilterValue !== '' ? getFilter(filters, 'countryCode')?.customFilterValue ?? countryCode : countryCode,
      page_query: filters.find(item=>item.header.toLowerCase()=='page url')?.query ?? filterKeywordTerm,
      page_operator: filters.find(item=> item.header.toLowerCase()== 'page url')?.operator ?? '',
      top_keyword_query: filters.find(item=>item.header.toLowerCase()=='top keyword')?.query,
      top_keyword_operator: filters.find(item=>item.header.toLowerCase()=='top keyword')?.operator,
      page_size: pageSize,
      page_number: pageNumber,
      position_from: getFilter(filters, 'posCur')?.from,
      position_to: getFilter(filters, 'posCur')?.to,
      clicks_from: getFilter(filters, 'clicksCur')?.from,
      clicks_to: getFilter(filters, 'clicksCur')?.to,
      impressions_from: getFilter(filters, 'impCur')?.from,
      impressions_to: getFilter(filters, 'impCur')?.to,
      total_keywords_from: getFilter(filters, 'keywordsCount')?.from,
      total_keywords_to: getFilter(filters, 'keywordsCount')?.to,
      opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
      users_from: getFilter(filters, 'users')?.from,
      users_to: getFilter(filters, 'users')?.to,
      sessions_from: getFilter(filters, 'sessions')?.from,
      sessions_to: getFilter(filters, 'sessions')?.to,
      bounces_from: getFilter(filters, 'bounces')?.from,
      bounces_to: getFilter(filters, 'bounces')?.to,
      sort_by: component === 'pages' ? formtatedSorters : pageGroupsFormtatedSorters,
      ...(publicHash && {site_share_hash: publicHash}),
      // watchlist_filter: !!getFilter(filters, 'radioButtonsFilter')?.type,
    };
    if (filters?.find(z => z?.active && z?.name == 'radioButtonsFilter')) {
      params['watchlist_filter'] = !!getFilter(filters, 'radioButtonsFilter')?.type;
    }

    // because we are using same Endpoint for 2 componentes
    // we've split store into 2 models, and are using 2 diferent data casting logic
    // depending on a passed prop
    if (component === 'pageGroups') {
      try {
        self.pageGroupsLoading = true;
        const response = yield reportsApi.getPagesDataTableReports(params);
        if (response.pagesTable) {
          self.pageGroups.length = 0;
          self.pageGroups = cast(response.pagesTable.results);
          self.pageGroupsCount = response.pagesTable.count;
        }
        self.pageGroupsLoading = false;
      } catch (e) {
        self.pageGroupsLoading = true;
        throw e;
      } finally {
        self.isPageGroupsInit = false;
      }
    }

    if (component === 'pages') {
      self.isPagesInit = true;
      self.pages = cast([]);
      try {
        self.pagesLoading = true;
        const response = yield reportsApi.getPagesDataTableReports(params);
        if (response?.pagesTable) {
          self.pages = cast(response?.pagesTable?.results);
          self.pagesCount = response?.pagesTable?.count;
        } else if (response.isCancel) {
          const response = yield reportsApi.getPagesDataTableReports(params);
          if (response?.pagesTable) {
            self.pages = cast(response?.pagesTable?.results);
            self.pagesCount = response?.pagesTable?.count;
          }
        }
        self.pagesLoading = false;
      } catch (e) {
        if (e.response?.status == 400) {
          notification.error('Error loading data', e.response?.data?.nonFieldErrors?.length ? e.response?.data?.nonFieldErrors[0] : '');
          self.isPagesInit = false;
          self.pagesLoading = false;
        } else {
          const errorMessage = apiError(e) as string;
          notification.error('Error loading data', errorMessage);
        }
      } finally {
        self.isPagesInit = false;
      }
    }
  });

  const onPaginationChange = (pageNumber: number, pageSize: number) => {
    self.pageNumber = pageNumber;
    self.pageSize = pageSize;
  };

  const onFilterChange = (filters: any) => {
    self.pageNumber = 1;
    self.filterList = cast(filters);
  };
  const clearFilterList = () => {
    self.pageNumber = 1;
    self.filterList = cast(INITIAL_FILTER_LIST);
  };

  const exportPagesToCSV = flow(function* (criteria: CriteriaType, type: 'pages_table' | 'keywords_table', {filters, exportAll, searchTerm, exportGoogleSheet=false}) {
    self.exportToCSVLoader = true;
    self.exportLoader = true;


    const sorters = {
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      pageCur: 'page',
      top_keyword: 'top_keyword',
      keywords_count: 'keywords_count',
      opportunity_score: 'opportunity_score',
      watchlist: 'watchlist',
    };

    const formtatedSorters = formatSorters(sorters, self.sortField, self.sortDirection);

    const params = {
      selected_property: criteria.property,
      report: type,
      export_all: exportAll,
      page: searchTerm,
      period1_start: criteria.previousPeriodStart,
      period1_end: criteria.previousPeriodEnd,
      period2_start: criteria.currentPeriodStart,
      period2_end: criteria.currentPeriodEnd,
      country_code: criteria.countryCode,
      position_from: getFilter(filters, 'posCur')?.from,
      position_to: getFilter(filters, 'posCur')?.to,
      clicks_from: getFilter(filters, 'clicksCur')?.from,
      clicks_to: getFilter(filters, 'clicksCur')?.to,
      impressions_from: getFilter(filters, 'impCur')?.from,
      impressions_to: getFilter(filters, 'impCur')?.to,
      total_keywords_from: getFilter(filters, 'keywordsCount')?.from,
      total_keywords_to: getFilter(filters, 'keywordsCount')?.to,
      opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
      sort_by: formtatedSorters,
      export_to_google_sheet: exportGoogleSheet,
    };

    try {
      const data = yield reportsApi.getKeywordSearchTableCsv(params);
      return data;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.exportToCSVLoader = false;
      self.exportLoader = false;
    }
  });
  const exportPagesToCSVV2 = flow(function* (criteria: CriteriaType, type: 'pages_table' | 'keywords_table', exportType: 'csv' | 'xls' | 'gsheets', whatToExport: 'all' | 'filtered_items' | 'current_page', {filters, searchTerm, pageSize}) {
    self.exportToCSVLoader = true;
    self.exportLoader = true;


    const sorters = {
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      pageCur: 'page',
      top_keyword: 'top_keyword',
      keywords_count: 'keywords_count',
      opportunity_score: 'opportunity_score',
      watchlist: 'watchlist',
    };

    const formtatedSorters = formatSorters(sorters, self.sortField, self.sortDirection);
    const filteredItems = {
      page: filters.find(item=>item.header.toLowerCase()=='page url')?.query ?? searchTerm,
      top_keyword_query: filters.find(item=>item.header.toLowerCase()=='top keyword')?.query,
      top_keyword_operator: filters.find(item=>item.header.toLowerCase()=='top keyword')?.operator,
      position_from: getFilter(filters, 'posCur')?.from,
      position_to: getFilter(filters, 'posCur')?.to,
      clicks_from: getFilter(filters, 'clicksCur')?.from,
      clicks_to: getFilter(filters, 'clicksCur')?.to,
      impressions_from: getFilter(filters, 'impCur')?.from,
      impressions_to: getFilter(filters, 'impCur')?.to,
      total_keywords_from: getFilter(filters, 'keywordsCount')?.from,
      total_keywords_to: getFilter(filters, 'keywordsCount')?.to,
      opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
    };

    const params = {
      selected_property: criteria.property,
      table: type,
      export_type: exportType,
      page: searchTerm,
      period1_start: criteria.previousPeriodStart,
      period1_end: criteria.previousPeriodEnd,
      period2_start: criteria.currentPeriodStart,
      period2_end: criteria.currentPeriodEnd,
      country_code: criteria.countryCode,
      ...(whatToExport === 'filtered_items' && filteredItems),
      ...(whatToExport === 'current_page' && {page_size: pageSize}),
      sort_by: formtatedSorters,
    };
    if (filters?.find(z => z?.active && z?.name == 'radioButtonsFilter')) {
      params['watchlist_filter'] = !!getFilter(filters, 'radioButtonsFilter')?.type;
    }

    try {
      if (exportType == 'xls') {
        const link = document.createElement('a');
        const response = yield reportsApi.getKeywordSearchTableExportXlsLink(params);
        const token = getTokenFromCookies();
        link.href = `${response}&jwt=${token}`;
        link.target = '_blank';
        link.click();
      } else {
        const data = yield reportsApi.exportGscTables(params);
        return data;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.exportToCSVLoader = false;
      self.exportLoader = false;
    }
  });

  const onTableChange = (sortFields: string, order: string) => {
    self.sortField = sortFields;
    self.sortDirection = order;
  };

  const onUpdateSearchTerm = (searchTerm: string) => {
    self.pageGroupsNumber = 1;
    self.searchTerm = searchTerm;
  };

  const onPageGroupsPaginationChange = (pageNumber: number, pageSize: number) => {
    self.pageGroupsNumber = pageNumber;
    self.pageGroupsSize = pageSize;
  };

  const onPageGroupsFilterChange = (filters: any) => {
    self.pageGroupsNumber = 1;
    self.pageGroupsFilterList = cast(filters);
  };

  const onPageGroupsTableChange = (sortFields: string, order: string) => {
    self.pageGroupsSortField = sortFields;
    self.pageGroupsSortDirection = order;
  };

  const onPageGroupsUpdateSearchTerm = (searchTerm: string) => {
    self.pageGroupsSearchTerm = searchTerm;
  };
  const updatePageColumnsList =properties=>{
    self.updatePages=properties;
  };


  const onHeaderDateUpdate = (currentPeriodStart : string, currentPeriodEnd : string) => {
    self.pagesTableDateRange = {
      pagesPeriod1Start: getPrecedingDate(currentPeriodStart, currentPeriodEnd)?.previousStartDate,
      pagesPeriod1End: getPrecedingDate(currentPeriodStart, currentPeriodEnd)?.previousEndDate,
      pagesPeriod2Start: currentPeriodStart,
      pagesPeriod2End: currentPeriodEnd,
    };
  };

  const setShouldShowGaBanner = (value: boolean) => self.shouldShowGaBanner = value;

  return {
    loadPages,
    onPaginationChange,
    onFilterChange,
    onTableChange,
    clearFilterList,
    onUpdateSearchTerm,
    onPageGroupsPaginationChange,
    onPageGroupsFilterChange,
    onPageGroupsTableChange,
    onPageGroupsUpdateSearchTerm,
    exportPagesToCSV,
    onHeaderDateUpdate,
    updatePageColumnsList,
    setShouldShowGaBanner,
    exportPagesToCSVV2,
  };
});

export function initPagesSearchTable() {
  return pagesSearchTable.create({
    pages: [],
    updatePages: [],
    pagesLoading: true,
    exportToCSVLoader: true,
    exportLoader: false,
    pageGroups: [],
    pageGroupsLoading: true,
    isPagesInit: true,
    isPageGroupsInit: true,
    pageNumber: 1,
    pageGroupsNumber: 1,
    pageSize: 100,
    pageGroupsSize: 20,
    filterList: INITIAL_FILTER_LIST,
    pageGroupsFilterList: [
      {id: 1, name: 'posCur', header: 'Position', from: undefined, to: undefined, type: undefined, active: false},
      {id: 2, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 3, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
    ],
    sortField: 'opportunity_score',
    pageGroupsSortField: '',
    sortDirection: 'descend',
    pageGroupsSortDirection: '',
    searchTerm: '',
    pageGroupsSearchTerm: '',
    shouldShowGaBanner: false,
  });
}


const HistoricalDataModel = types.model({
  averagePos: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  impressions: types.maybeNull(types.number),
  kwCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
});

const HistoricalTableDataModel = types.model({
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  kw: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  pagesNum: types.maybeNull(types.number),
});

const DisplayedHistoricalTableDataModel = types.model({
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  kw: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  pagesNum: types.maybeNull(types.number),
});

const HistoricalKeywordFiltersModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean),
});

export const HistoricalData = types.model({
  selectedChart: types.maybeNull(types.number),
  isOpenHistoricalModal: types.boolean,
  isLoadingHistorical: types.boolean,
  historicalDataArray: types.maybeNull(types.array(HistoricalDataModel)),
  historicalSelectedUrl: types.maybeNull(types.string),
  historicalDataType: types.maybeNull(types.string),
  isHistoricalTableLoading: types.boolean,
  historicalTableData: types.maybeNull(types.array(HistoricalTableDataModel)),
  displayedHistoricalTableData: types.maybeNull(types.array(DisplayedHistoricalTableDataModel)),
  historicalPageNumber: types.maybeNull(types.number),
  historicalPageSize: types.maybeNull(types.number),
  historicalFilteredDataSize: types.maybeNull(types.number),
  historicalSortField: types.maybeNull(types.string),
  historicalSortDirection: types.maybeNull(types.string),
  historicalFilterKeywordTerm: types.maybeNull(types.string),
  historicalFilterList: types.maybeNull(types.array(HistoricalKeywordFiltersModel)),
  historicalFilterKeywordColumns: types.maybeNull(types.array(types.string)),
  isPageDrawerOpen: types.maybeNull(types.boolean),
  showHistoricalDataInDrawer: types.maybeNull(types.boolean),
  drawerUrl: types.maybeNull(types.string),
  isBannerVisible: types.boolean,
  warningNotification: types.maybeNull(types.string),
  previousPosition: types.maybeNull(types.number),
  previousTraffic: types.maybeNull(types.number),
  previousImpressions: types.maybeNull(types.number),
  latestPosition: types.maybeNull(types.number),
  latestTraffic: types.maybeNull(types.number),
  latestImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  totalImpressions: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  trafficDelta: types.maybeNull(types.number),
  latestKeywords: types.maybeNull(types.number),
  previousKeywords: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  keywordsDelta: types.maybeNull(types.number),
}).views(self => ({
  get getIsLoadingHistorical() {
    return self.isLoadingHistorical;
  },

  get getHistoricalSelectedUrl() {
    return self.historicalSelectedUrl;
  },

  get getSelectedChart() {
    return self.selectedChart;
  },

  get getHistoricalData() {
    return self.historicalDataArray;
  },
  get getHistoricalTableData() {
    return self.historicalTableData;
  },

  get getDisplayedHistoricalTableData() {
    return self.displayedHistoricalTableData;
  },

})).actions(self => {
  const startLoadingHistoricalData = () => {
    self.isLoadingHistorical = true;
  };

  const stopLoadingHistoricalData = () => {
    self.isLoadingHistorical = true;
  };

  const handleFilterRenderHistoricalData = (filterKeywordTerm, filters, pageNumber, pageSize, sortField, sortDirection) => {
    self.isHistoricalTableLoading = true;
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.historicalTableData, self.historicalFilterKeywordTerm, self.historicalFilterKeywordColumns, filters, pageNumber, pageSize, sortField, sortDirection);
    self.historicalFilteredDataSize = dataFilteredSize;
    self.displayedHistoricalTableData.length = 0;
    const newArray = dataFiltered.map(item => {
      return {
        clicksCur: item.clicksCur,
        clicksPrev: item.clicksPrev,
        cpc: item.cpc,
        impCur: item.impCur,
        impPrev: item.impPrev,
        kw: item.kw,
        page: item.page,
        posCur: item.posCur,
        posPrev: item.posPrev,
        vol: item.vol,
        pagesNum: item.pagesNum,
      };
    });
    self.displayedHistoricalTableData = cast(newArray);
    self.isHistoricalTableLoading = false;
  };

  const handleHistoricalPaginationChange = async (pageNumber: number, pageSize: number) => {
    self.historicalPageNumber = pageNumber;
    self.historicalPageSize = pageSize;
    handleFilterRenderHistoricalData(self.historicalFilterKeywordTerm, self.historicalFilterList, self.historicalPageNumber, self.historicalPageSize, self.historicalSortField, self.historicalSortDirection);
  };

  const handleHistoricalTableChange = (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortField} = column || {};
    self.historicalSortField = sortField;
    self.historicalSortDirection = order;
    handleFilterRenderHistoricalData(self.historicalFilterKeywordTerm, self.historicalFilterList, self.historicalPageNumber, self.historicalPageSize, self.historicalSortField, order);
  };

  const handleHistoricalKeywordTermChange = searchTerm => {
    if (self.historicalFilterKeywordTerm !== searchTerm) {
      self.historicalFilterKeywordTerm = searchTerm;
      self.historicalPageNumber = 1;
      handleFilterRenderHistoricalData(searchTerm, self.historicalFilterList, 1, self.historicalPageSize, self.historicalSortField, self.historicalSortDirection);
    }
  };

  const handleOnHistoricalFilterChange = filters => {
    self.historicalPageNumber = 1;
    self.historicalFilterList = cast(filters);
    handleFilterRenderHistoricalData(self.historicalFilterKeywordTerm, filters, 1, self.historicalPageSize, self.historicalSortField, self.historicalSortDirection);
  };

  const doHandleOpenHistoricalModal = flow(function* (criteria: any, url: string) {
    self.selectedChart = 2;
    self.historicalPageNumber = 1;
    self.historicalSelectedUrl = url;
    if (self.historicalDataArray) {
      self.historicalDataArray.length = 0;
    }
    if (self.historicalTableData) {
      self.historicalTableData.length = 0;
    }


    const params = {
      property: criteria.property,
      period1Start: criteria.previousPeriodStart,
      period1End: criteria.previousPeriodEnd,
      periodStart: criteria.currentPeriodStart,
      periodEnd: criteria.currentPeriodEnd,
      countryCode: criteria.countryCode,
      page: url,
    };

    const respHistoricalData = yield reportsApi.getPageHistoricalPerformance(params);
    self.warningNotification = respHistoricalData.warningNotification;
    let _historicalData = respHistoricalData?.data?.monthly;
    self.historicalDataType = 'monthly';
    if (!respHistoricalData?.data?.monthly.length) {
      self.historicalDataType = 'daily';
      _historicalData = respHistoricalData?.data?.daily;
    }
    self.historicalDataArray = _historicalData;
    self.historicalTableData = cast(respHistoricalData?.keywords?.results);
    self.previousPosition = respHistoricalData?.data?.previousPosition;
    self.previousTraffic = respHistoricalData?.data?.previousTraffic;
    self.previousImpressions = respHistoricalData?.data?.previousImpressions;
    self.latestPosition = respHistoricalData?.data?.latestPosition;
    self.latestTraffic = respHistoricalData?.data?.latestTraffic;
    self.latestImpressions = respHistoricalData?.data?.latestImpressions;
    self.totalTraffic = respHistoricalData?.data?.totalTraffic;
    self.totalImpressions = respHistoricalData?.data?.totalImpressions;
    self.impressionsDelta = respHistoricalData?.data?.impressionsDelta;
    self.positionDelta = respHistoricalData?.data?.positionDelta;
    self.trafficDelta = respHistoricalData?.data?.trafficDelta;
    self.latestKeywords = respHistoricalData?.data?.latestKeywords;
    self.previousKeywords = respHistoricalData?.data?.previousKeywords;
    self.totalKeywords = respHistoricalData?.data?.totalKeywords;
    self.keywordsDelta = respHistoricalData?.data?.keywordsDelta;
  });

  const handleOpenHistoricalModal = flow(function* (criteria: any, url: string) {
    self.isLoadingHistorical = true;
    yield doHandleOpenHistoricalModal(criteria, url);
    self.isLoadingHistorical = false;
  });

  const closeHistoricalModal = (value: boolean) => {
    self.isOpenHistoricalModal = value;
  };

  const onChartClick = (chartIndex: number) => {
    self.selectedChart = chartIndex;
  };

  const handleOpenDrawer = (url: string) => {
    self.showHistoricalDataInDrawer = true;
    self.drawerUrl = url;
    self.isPageDrawerOpen = true;
  };


  const handleOpenPageInsightsDrawer = (url: string) => {
    self.isPageDrawerOpen = true;
    self.showHistoricalDataInDrawer = false;
    self.drawerUrl = url;
  };


  const handleCloseDrawer = () => {
    self.isPageDrawerOpen = false;
    self.showHistoricalDataInDrawer = true;
  };

  const handleChangeDrawerData = async () => {
    self.showHistoricalDataInDrawer = !self.showHistoricalDataInDrawer;
  };

  const setIsBannerVisible = (visible: boolean) => {
    self.isBannerVisible = visible;
  };
  const resetHistoricalFilterList = ()=>{
    self.historicalFilterList=cast([
      {id: 2, name: 'posCur', header: 'Position', customFilterValue: '', from: undefined, to: undefined, type: undefined, active: false},
      {id: 3, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
      {id: 5, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
      {id: 6, name: 'vol', header: 'Search Volume', from: undefined, to: undefined, type: undefined, active: false},
    ]);
  };

  const setSelectedHistoricalUrl = (value: string) => self.historicalSelectedUrl = value;

  return {
    handleFilterRenderHistoricalData,
    handleHistoricalPaginationChange,
    handleHistoricalTableChange,
    handleHistoricalKeywordTermChange,
    handleOnHistoricalFilterChange,
    handleOpenHistoricalModal,
    closeHistoricalModal,
    onChartClick,
    handleOpenDrawer,
    handleOpenPageInsightsDrawer,
    handleCloseDrawer,
    handleChangeDrawerData,
    startLoadingHistoricalData,
    stopLoadingHistoricalData,
    setIsBannerVisible,
    setSelectedHistoricalUrl,
    resetHistoricalFilterList,
  };
});

export function initHistoricalData() {
  return HistoricalData.create({

    selectedChart: -1,
    isOpenHistoricalModal: false,
    isBannerVisible: false,
    isLoadingHistorical: true,
    historicalDataArray: [],
    historicalSelectedUrl: '',
    historicalDataType: 'monthly',
    isHistoricalTableLoading: false,
    historicalTableData: [],
    displayedHistoricalTableData: [],
    historicalPageNumber: 1,
    historicalPageSize: 50,
    historicalFilteredDataSize: 0,
    historicalSortField: '',
    historicalSortDirection: 'descend',
    historicalFilterKeywordTerm: '',
    historicalFilterList: [
      // {id: 1, name: 'exclude', header: 'Keyword', text: '', type: undefined, active: false},
      {id: 3, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 2, name: 'posCur', header: 'Position', customFilterValue: '', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
      {id: 5, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
      {id: 6, name: 'vol', header: 'Search Volume', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 8, name: 'potentialTrafficCur', header: 'Potential Traffic', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 9, name: 'potentialTrafficValueCur', header: 'Potential Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
    ],
    historicalFilterKeywordColumns: ['kw'],
    isPageDrawerOpen: false,
    showHistoricalDataInDrawer: true,
    drawerUrl: '',
    warningNotification: null,
  });
}
