import {SubscriptionType} from '@/store/settings/customer/index';
import {routes} from '@/utils/const';
import {hasProductAccess} from '@/utils/subscription';
import {
  faLink,
  faPencil,
  faChartLine,
  faListAlt,
  faCheckDouble,
  faArrowsRotate,
  faShuffle,
  faHome,
  faGrid2,
  faArrowUpArrowDown,
  faMagnifyingGlassChart,
  faFileChartColumn as faFileChartColumnDuotone,
} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {ThunderboltOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import Menu, {ItemsGroup} from '.';
import {
  faList,
  faChartSimple,
  faFile,
  faKeySkeleton as faKeySkeletonRegular,
  faMagnifyingGlass as faMagnifyingGlassRegular,
  faLinkSimple,
  faCalendar,
  faFileChartColumn,
  faPenToSquare,
  faGrid2Plus,
  faLightbulb,
  faChartSimpleHorizontal,
  faArrowUpRightDots,
  faFileMagnifyingGlass,
  faSitemap,
  faBug,
  faEnvelopeOpenText,
  faChartGantt,
  faHighlighter,
  faArrowRightArrowLeft,
  faDiagramVenn,
  faChartLineUp,
  faFileCircleExclamation,
  faFolderTree,
  faGlobe,
  faRectangleAd,
  faNotebook,
  faMegaphone,
  faFileExport,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {faClockRotateLeft, faWandMagicSparkles} from '@fortawesome/pro-solid-svg-icons';
import {useRouter} from 'next/router';
import {getTokenFromCookies} from '@/api/common-utils';
import {getSingleUrlParam} from '@/utils/url';
import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons';

type ItemsBarProps = {
  handleSideBarItems?: (items) => void;
};

export const MenuItemsBar = observer(({handleSideBarItems}: ItemsBarProps) => {
  const {settings: {customer: {profile: {subscription, isLinkgraph}}}, siteAuditorV2: {siteAuditSpacificData}} = useStore('');
  const {competitorResearcherV2: {getCompetitorResearcherId, competitorResearcherUrl}} = useStore('');
  const publicHash = getSingleUrlParam('public_hash') || localStorage.getItem('siteExplorerPublicHash');
  const router = useRouter();
  const checkPublicPages = router.pathname?.includes('public') && !getTokenFromCookies();
  const getSiteExplorerDomain = getSingleUrlParam('domain');

  const getMenuItems = (subscription: SubscriptionType) => {
    return [
      {
        title: 'Home',
        key: 'home',
        color: '#F1AA3E',
        path: '/home',
        icon: <Icon onClick={() => {}} icon={faHome} color='#A3A4A4' />,
        items: [],
      },
      {
        title: 'Content',
        key: 'content',
        color: '#1FAC47',
        icon: <Icon onClick={() => {}} icon={faPencil} color='#A3A4A4' />,
        items: [
          {
            title: 'Content Genius',
            key: `/${routes.landingPageOptimizerPublic}/c3c1644f-0602-4169-995f-04f0fb4bf742`,
            path: `/${routes.landingPageOptimizerPublic}/c3c1644f-0602-4169-995f-04f0fb4bf742`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faPenToSquare} color='#1FAC47'/>,
          },
          {
            title: 'Onpage Audit',
            key: `/${routes.onPageAuditPublic}`,
            path: `/${routes.onPageAuditPublic}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faFileChartColumn} color='#A3A4A4'/>,
          },
          {
            title: 'AI Content Writer',
            key: `/${routes.aiContentGeneratorPublic}`,
            path: `/${routes.aiContentGeneratorPublic}`,
            color: '#1FAC47',
            isBeta: false,
            icon: <Icon onClick={() => {}} icon={faLightbulb} color='#A3A4A4'/>,
          },
          {
            title: 'Content Planner',
            key: '/content/content-planner-public',
            path: '/content/content-planner-public',
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faGrid2Plus} color='#A3A4A4'/>,
          },
          {
            title: 'Scholar',
            key: '/content/semantic-grader-public',
            path: '/content/semantic-grader-public',
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faGrid2Plus} color='#A3A4A4'/>,
          },
          {
            title: 'Content Planner',
            key: '/content/content-planner/public',
            path: '/content/content-planner/public',
            color: '#1FAC47',
            hidden: true,
            icon: <Icon onClick={() => {}} icon={faGrid2Plus} color='#A3A4A4'/>,
          },

          {
            title: 'Onpage Audit',
            key: `/content/onpage-audit/detail-public`,
            path: `/content/onpage-audit/detail-public`,
            color: '#1FAC47',
            hidden: true,
            icon: <Icon onClick={() => {}} icon={faFileChartColumn} color='#A3A4A4'/>,
          },
          {
            title: 'Meta & Map Creator',
            key: `/${routes.contentIdeas}`,
            path: `/${routes.contentIdeas}`,
            hidden: !isLinkgraph,
            isAlpha: true,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faLightbulb} color='#A3A4A4'/>,
          },
          {
            title: 'Content Rewriting',
            key: '/content/rewriting-tool-public',
            path: '/content/rewriting-tool-public',
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faArrowsRotate} color='#A3A4A4'/>,
          },
          {
            title: 'Content Brief',
            key: '/content/content-brief',
            path: '/content/content-brief',
            hidden: true,
            isAlpha: true,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faArrowsRotate} color='#A3A4A4'/>,
          },
          {
            title: 'Focus Terms',
            key: `/${routes.focusTerms}`,
            path: `/${routes.focusTerms}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faHighlighter} color='#A3A4A4' />,
          },
        ],
      },
      {
        title: 'Site Explorer',
        key: 'site explorer',
        color: '#7F4EAD',
        icon: <Icon onClick={() => {}} icon={faMagnifyingGlassChart} color='#A3A4A4' />,
        items: [
          {
            title: 'Projects List',
            key: `/${routes.competitorResearcher.defaultPublic}`,
            path: `/${routes.competitorResearcher.defaultPublic}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: `/${routes.competitorResearcher.detailPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.detailPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faChartSimple} color='#A3A4A4'/>,
          },
          {
            title: 'Keywords',
            key: `/${routes.competitorResearcher.keywordsPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.keywordsPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Pages',
            key: `/${routes.competitorResearcher.pagesPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.pagesPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
          },
          {
            title: 'Subdomains',
            key: `/${routes.competitorResearcher.subdomainsPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.subdomainsPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faGlobe} color='#A3A4A4'/>,
          },
          {
            title: 'Google Ads',
            key: `/${routes.competitorResearcher.googlePagePublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.googlePagePublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faRectangleAd} color='#A3A4A4'/>,
          },
          {
            title: 'Facebook Ads',
            key: `${routes.competitorResearcher.facebookAdsPublic}/${getCompetitorResearcherId}?public_hash=${publicHash || ''}`,
            path: `${routes.competitorResearcher.facebookAdsPublic}/${getCompetitorResearcherId}?public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFacebookSquare} color='#A3A4A4'/>,
            isNew: true,
          },
          {
            title: 'Position Changes',
            key: `/${routes.competitorResearcher.positionChangesPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.positionChangesPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faArrowUpArrowDown} color='#A3A4A4'/>,
          },
          {
            title: 'Backlinks',
            key: `/${routes.competitorResearcher.backlinksPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.backlinksPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faLinkSimple} color='#A3A4A4'/>,
          },
          {
            title: 'Competitors',
            key: `/${routes.competitorResearcher.competitorsPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            path: `/${routes.competitorResearcher.competitorsPublic}/${getCompetitorResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl}&public_hash=${publicHash || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faArrowUpRightDots} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: 'Keywords',
        key: 'research',
        color: '#FF8536',
        icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4' />,
        items: [
          {
            title: 'Keyword Research',
            key: `/research/keyword-researcher-public`,
            path: `/research/keyword-researcher-public`,
            color: '#FF8536',
            isBeta: false,
            hidden: !router?.pathname?.includes('public') && !hasProductAccess(subscription, 'keyword-researcher'),
            icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Magic Tool',
            key: `/research/keyword-magic-tool-public`,
            path: `/research/keyword-magic-tool-public`,
            color: '#FF8536',
            isBeta: true,
            icon: <Icon onClick={() => {}} icon={faWandMagicSparkles} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Gap',
            key: `${routes.keywordGapAnalysis}`,
            path: `${routes.keywordGapAnalysis}`,
            color: '#FF8536',
            icon: <Icon onClick={() => {}} icon={faChartGantt} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Rank Tracker',
            key: `${routes.keywordRankTracker}`,
            path: `${routes.keywordRankTracker}`,
            color: '#FF8536',
            icon: <Icon onClick={() => {}} icon={faShuffle} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: 'Site Audit',
        key: 'site-audit',
        color: '#7F4EAD',
        icon: <ThunderboltOutlined style={{color: '#A3A4A4'}} />,
        items: [
          {
            title: 'Projects List',
            key: '/site-audit-list-public',
            path: '/site-audit/list-public',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: '/site-audit/overview-public',
            path: '/site-audit/overview-public',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faChartSimple} color='#A3A4A4'/>,
          },
          {
            title: 'Page Explorer',
            key: '/site_audit/page_explorer-public',
            path: '/site-audit/page-explorer-public',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFileMagnifyingGlass} color='#A3A4A4'/>,
          },
          {
            title: 'Domain-level',
            key: '/site_audit/sitewide_data-public',
            path: '/site-audit/sitewide-data-public',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faSitemap} color='#A3A4A4'/>,
          },
          {
            title: 'Issues',
            key: `${routes.saIssuesPagePublic}`,
            path: `${routes.saIssuesPagePublic}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => { }} icon={faBug} color='#A3A4A4' />,
          },
          {
            title: 'Page Pruning',
            key: 'page pruning',
            path: '/site-audit/pruning-page',
            color: '#7F4EAD',
            isBeta: false,
            hidden: siteAuditSpacificData?.gscConnected,
            icon: <Icon onClick={() => { }} icon={faFileCircleExclamation} color='#A3A4A4' />,
          },
          {
            title: 'Email Alerts',
            key: 'email-alerts',
            path: '/site-audit/email-alerts',
            color: '#7F4EAD',
            hidden: checkPublicPages,
            icon: <Icon onClick={() => {}} icon={faEnvelopeOpenText} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: <div>GSC Performance</div>,
        key: 'gsc',
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faChartLine} color='#A3A4A4' size='sm' />,
        items: [
          {
            title: 'Projects List',
            key: '/gsc/overview',
            path: '/gsc/overview',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: '/gsc/overview',
            path: '/gsc',
            hidden: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: 'gsc/overview',
            path: `/${routes.overview}`,
            hidden: !hasProductAccess(subscription, 'gsc'),
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
          },
          {
            title: 'Pages',
            key: 'gsc/pages',
            path: `/${routes.topPagesNew}`,
            hidden: false,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
            isAlpha: false,
          },
          {
            title: 'Top Keywords',
            key: 'gsc/top-keywords',
            path: `/${routes.topKeywords}`,
            hidden: !hasProductAccess(subscription, 'gsc'),
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Backlinks',
            key: 'gsc/backlinks',
            path: `/${routes.backlinks}`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faLinkSimple} color='#A3A4A4'/>,
          },
          {
            title: 'Site Structure',
            key: `/${routes.siteStructure}`,
            path: `/${routes.siteStructure}`,
            hidden: !isLinkgraph,
            isAlpha: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFolderTree} color='#A3A4A4'/>,
          },
          {
            title: 'Competitor Discovery',
            key: 'gsc/competitor-descovery',
            path: `/${routes.competitorDiscovery}`,
            hidden: !isLinkgraph,
            isAlpha: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faArrowUpRightDots} color='#A3A4A4'/>,
          },
          {
            title: 'Site Events',
            key: 'site events',
            path: '/gsc/site-events',
            isBeta: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faCalendar} color='#A3A4A4'/>,
          },
          {
            title: 'Reports',
            key: '/gsc/reports',
            path: '/gsc/reports',
            hidden: !isLinkgraph,
            isAlpha: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFileChartColumn} color='#A3A4A4'/>,
          },
          {
            title: 'Additional Info',
            key: '/gsc/additional-info',
            path: '/gsc/additional-info',
            isBeta: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faGrid2} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: 'Digital PR',
        key: 'digital-pr',
        color: '#019197',
        icon: <Icon onClick={() => {}} icon={faMegaphone} color='#A3A4A4'/>,
        items: [
          {
            title: 'PR Outreach',
            key: `/${routes.digitalPr}`,
            path: `/${routes.digitalPr}`,
            isAlpha: true,
            hidden: !isLinkgraph,
            color: '#019197',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#A3A4A4'/>,
          },
          {
            title: 'Journalist Details',
            key: `/${routes.journalistDetails}`,
            path: `/${routes.journalistDetails}`,
            hidden: true,
            color: '#019197',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#A3A4A4'/>,
          },
          {
            title: 'Media Outlets',
            key: `/${routes.mediaOutletsDetail}`,
            path: `/${routes.mediaOutletsDetail}`,
            hidden: true,
            color: '#019197',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: 'Report Builder',
        key: '/report-builder',
        color: '#ffffff',
        isBeta: true,
        icon: <Icon onClick={() => {}} icon={faFileChartColumnDuotone} color='#A3A4A4'/>,
        items: [
          {
            title: 'Report Builder',
            key: `/${routes.reportBuilder.bulkExport}`,
            path: `/${routes.reportBuilder.bulkExport}`,
            color: '#ffffff',
            icon: <Icon onClick={() => {}} icon={faFileExport} color='#A3A4A4' />,
          },
          {
            title: 'Individual Reports',
            key: `/${routes.reportBuilder.detail}`,
            path: `/${routes.reportBuilder.detail}`,
            color: '#ffffff',
            icon: <Icon onClick={() => {}} icon={faPlus} color='#A3A4A4' />,
          },
          {
            title: 'History',
            key: `/${routes.reportBuilder.history}`,
            path: `/${routes.reportBuilder.history}`,
            color: '#ffffff',
            hidden: true,
            icon: <Icon onClick={() => {}} icon={faClockRotateLeft} color='#A3A4A4' />,
          },
        ],
      },
      {
        title: 'Bulk DA Checker',
        key: '/bulk-da-checker-public',
        color: '#fff',
        icon: <Icon onClick={() => {}} icon={faCheckDouble} color='#A3A4A4' />,
        path: '/bulk-da-checker-public',
      },
      {
        title: 'Schema Creator',
        key: '/schema',
        path: '/schema',
        color: '#fff',
        hidden: true,
        isAlpha: true,
        icon: <Icon onClick={() => {}} icon={faListAlt} color='#A3A4A4' />,
      },
      {
        title: 'Backlinks',
        key: 'backlink',
        color: '#34AEF3',
        icon: <Icon onClick={() => {}} icon={faLink} color='#A3A4A4' />,
        items: [{
          title: 'Backlink Research',
          key: `${routes.backlinkResearcherPublic}`,
          path: `${routes.backlinkResearcherPublic}`,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
        },
        {
          title: 'Link Gap Analysis',
          key: `${routes.backlinkGapAnalysis}`,
          path: `${routes.backlinkGapAnalysis}`,
          hidden: false,
          isBeta: false,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faChartGantt} color='#A3A4A4'/>,
        },
        {
          title: 'Compare Backlinks',
          key: `${routes.backlinkProfileAnalysis}`,
          path: `${routes.backlinkProfileAnalysis}`,
          hidden: false,
          isBeta: false,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faArrowRightArrowLeft} color='#A3A4A4'/>,
        },
        {
          title: 'Topical Relevance',
          key: `/${routes.topicalRelevance}`,
          path: `/${routes.topicalRelevance}`,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faDiagramVenn} color='#A3A4A4'/>,
          commingSoon: true,
        },
        {
          title: 'Backlink Velocity',
          key: `/${routes.backlinkVelocity}`,
          path: `/${routes.backlinkVelocity}`,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faChartLineUp} color='#A3A4A4'/>,
          commingSoon: true,
        },
        ],
      },
    ];
  };
  const menuItems = getMenuItems(subscription);

  return <Menu groups={menuItems as ItemsGroup[]} handleSideBarItems={handleSideBarItems}/>;
});

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px !important;
`;
