import classnames from 'classnames';
import {Typography} from '@/components/common-components';
import css from './styles.module.scss';
import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
// import {NextImg} from '@/utils/nextImg';

type AccountHeaderProps = {
    titleText: string;
    subtitleText?: string;
    className?: string;
    caption?:string;
    isRegisterPage?:boolean;
    children?: React.ReactNode;
    icon?: string;
};

export const AccountHeader: React.FC<AccountHeaderProps> = observer(({
  titleText,
  subtitleText,
  className,
  caption,
  isRegisterPage,
  children,
  icon= '/img/Vector.svg',
}) => {
  const {settings: {customer: {profile: {logo, isWhitelabel, signUpLogo}}}} = useStore('');

  const checkLogo = (url: string) => {
    if (!url) return null;
    if (url.includes('/Logo_SVG.svg')) return null;

    return url;
  };

  // const addDefaultSrc = e => {
  //   e.target.src = '/icons/handicon.svg';
  // };

  return (
    <div className={classnames(css.header, className)}>
      <div className={css.topImg}>
        <img
          style={{width: '100%', maxHeight: 60}}
          src={isWhitelabel ? (checkLogo(signUpLogo) ?? checkLogo(logo) ?? '') : icon}
          // onError={e => addDefaultSrc(e)}
        />
      </div>
      <Typography tag='h1' className={`${css.title} ${isRegisterPage && css.registerSize}`}>{titleText}</Typography>
      <Typography tag='p' className={css.subTitle}>{subtitleText}</Typography>
      {children}
      <div className={css.caption}>
        <Typography className={css.emailCaption} tag='p'>{caption}</Typography>
      </div>
    </div>
  );
});
