import React, {ReactNode, useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {Menu as AntdMenu, Tooltip} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import styles from './style.module.scss';
import classnames from 'classnames';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import {routes} from '@/utils/const';
import {faLocationDot, faQuestion} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getTokenFromCookies} from '@/api/common-utils';
import Link from 'next/link';
import {Button} from '@/components/common-components/v2';
import {faPenNib, faClipboardList, faHighlighter, faBoltAuto, faMagnifyingGlass, faListUl, faMapLocationDot, faChartSimpleHorizontal, faMessageLines} from '@fortawesome/pro-regular-svg-icons';
import {LargeBadge} from '@/components/common-components/components/badge';
import {NextImg} from '@/utils/nextImg';
import {isAllowedPage} from '@/utils/functions';
import {faQuoteLeft, faStarHalfStroke} from '@fortawesome/pro-solid-svg-icons';

type BaseMenuItem = {
  path?: string;
  key: string;
};

type PlainMenuItem = BaseMenuItem & {
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  color?: string;
  commingSoon?: boolean;
};

type MenuItem = PlainMenuItem & {
  items?: PlainMenuItem[];
};

export type ItemsGroup = {
  title: string;
  key: string;
  items?: MenuItem[];
  hidden?: boolean;
  isShow?: boolean;
  isBeta?: boolean;
  isNew?: boolean;
  isAlpha?: boolean;
  disabled?: boolean;
  color?: string;
  path?: string;
  icon?: ReactNode;
  unreadEmails?: boolean | null;
};

type MenuProps = {
  groups: ItemsGroup[];
  reportBuilderId?: any;
  handleSideBarItems?: (items) => void;
  isSmallScreen?: boolean;
};

const Menu: React.FC<MenuProps> = observer(({handleSideBarItems, groups, isSmallScreen, ...props}: MenuProps) => {
  const [selectedKey, setSelectedKey] = useState([]);
  const router = useRouter();
  const id = router?.query?.id?.length ? Number(router?.query?.id[0]) : null;
  const {
    navBar: {isDockedSider},
    settings: {
      customer: {WLchildrenPages, setAuthModal, profile: {isLinkgraph, isParentAccount, isWhitelabel}},
    },
    reportBuilder: {getReportsProjectsList},
    competitorResearcherV2: {getCompetitorResearcherId},
  } = useStore('');
  function renderPlainItem({key, disabled, icon, title, path, isAlpha=false, isNew=false, color='', isBeta=false, items, hidden=false}: ItemsGroup) {
    return !hidden && <AntdMenuItemStyled itemColor={color} onClick={async () => {
      if (router.pathname?.includes('public') && !getTokenFromCookies()) {
        setAuthModal(true, true);
      } else if (!disabled) {
        if (path?.includes('gsc')) {
          handleSideBarItems(items);
        }
      }
    }} key={key} className={classnames( (disabled ? styles.disabled : (key == '/tutorials' || key == '/otto-page' ? styles.firstItemStyle : '')), `${key}-submenu`)}>
      {disabled ? <Tooltip
        trigger={'hover'}
        title={disabled ? <PopupStyled>
          <NextImg src='/img/icon/linkIconCircle.svg'/>
          <PopupTitle>Discover the backlinks of any site.</PopupTitle>
          <PopupSubTitle>The Backlinks tool is available in the Growth, Pro and Custom plans.</PopupSubTitle>
          <a href={!router.pathname?.includes('public') && `${location.origin}/pricing`}><Button style={{marginTop: '20px'}} color={'green'}>View Pricing</Button></a>
        </PopupStyled> : ''}
        overlayClassName={styles.popupCustomStlye}
        placement={'bottom'}><AntdMenuItemStyled itemColor={color} key={key} className={classnames( (disabled ? styles.disabled : (key == '/tutorials' || key == '/otto-page' ? styles.firstItemStyle : '')), `${key}-submenu`)}>
          <div style={{display: 'flex', alignItems: 'center'}}><span className={styles.icon}>{icon}</span> {title} {disabled && <LockOutlined/>}{isAlpha && <LargeBadge alpha /> }{isBeta && <LargeBadge beta /> }{isNew && <LargeBadge isNew /> }</div>
        </AntdMenuItemStyled>;
      </Tooltip> :
        <Link legacyBehavior href={disabled ? '' : `${items?.length ? items[0]?.path : (path ? path : '')}`} passHref>
          <a onClick={e => {
            if (router.pathname?.includes('public') && !getTokenFromCookies()) {
              setAuthModal(true, true);
              e.preventDefault();
              return false;
            }
          }}>
            <div style={{display: 'flex', alignItems: 'center'}}><span className={styles.icon}>{icon}</span> {title} {disabled && <LockOutlined/>}{isAlpha && <LargeBadge alpha /> }{isBeta && <LargeBadge beta /> }{isNew && <LargeBadge isNew /> }</div>
          </a>
        </Link>
      }
    </AntdMenuItemStyled>;
  }

  const localSeoItems = [
    {
      title: 'My businesses',
      key: `${routes.localSeoProjectsList}`,
      path: `${routes.localSeoProjectsList}`,
      color: '#1FAC47',
      icon: <Icon onClick={() => {}} icon={faListUl} color='#A3A4A4'/>,
      ...(!isParentAccount && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
    },
    {
      title: 'Local SEO Heatmap',
      key: `${routes.localSeo}`,
      path: `${routes.localSeo}`,
      color: '#1FAC47',
      icon: <Icon onClick={() => {}} icon={faMapLocationDot} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
    },
    {
      title: 'Local Listings',
      key: `''`,
      path: `''`,
      commingSoon: true,
      color: '#1F2125',
      icon: <Icon onClick={() => {}} icon={faClipboardList} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
    },
    {
      title: 'Local Citations',
      key: `${routes.localCitations}`,
      path: `${routes.localCitations}`,
      color: '#1FAC47',
      icon: <Icon onClick={() => {}} icon={faHighlighter} color='#A3A4A4'/>,
      isBeta: false,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
    },
    {
      title: 'GBP Automations',
      key: `${routes.gbpAutomations}`,
      path: `${routes.gbpAutomations}`,
      color: '#1FAC47',
      isBeta: true,
      icon: <Icon onClick={() => {}} icon={faBoltAuto} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
      childPages: [
        {
          title: 'My Tasks',
          key: `${routes.myTasks}`,
          path: `${routes.myTasks}`,
          color: '#1FAC47',
          isBeta: true,
        },
        {
          title: 'Posts',
          key: `${routes.gbpAutomationsPosts}`,
          path: `${routes.gbpAutomationsPosts}`,
          color: '#1FAC47',
          isBeta: true,
        },
        {
          title: 'Reviews',
          key: `${routes.gbpAutomationsReviews}`,
          path: `${routes.gbpAutomationsReviews}`,
          color: '#1FAC47',
          isBeta: true,
        },
        {
          title: 'Questions & Answers',
          key: `${routes.gbpAutomationsQuestions}`,
          path: `${routes.gbpAutomationsQuestions}`,
          color: '#1FAC47',
          isBeta: true,
        },
        {
          title: 'GBP Optimization',
          key: `${routes.gbpAutomationsOptimization}`,
          path: `${routes.gbpAutomationsOptimization}`,
          color: '#1FAC47',
          isBeta: true,
        },
      ],
    },
    {
      title: 'Local SERPs',
      key: '/local-search-results',
      path: '/local-search-results',
      color: '#1FAC47',
      icon: <Icon onClick={() => {}} icon={faMagnifyingGlass} color='#A3A4A4' />,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
    },
    {
      title: 'AI GBP Writer',
      key: `${routes.aiGmbWriter}`,
      path: `${routes.aiGmbWriter}`,
      color: '#1FAC47',
      hidden: !isParentAccount && isWhitelabel ? !isAllowedPage('enableLocalSeo', WLchildrenPages) ? true : !isLinkgraph : !isLinkgraph,
      isAlpha: true,
      icon: <Icon onClick={() => {}} icon={faPenNib} color='#A3A4A4'/>,
    },
  ];
  const localSeoItemsV2 = [
    {
      title: 'GBP Projects',
      key: routes.gbpProjectsV2,
      path: routes.gbpProjectsV2,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faListUl} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
    {
      title: 'Overview',
      key: routes.gbpProjectOverview,
      path: routes.gbpProjectOverview,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
    {
      title: 'AI AUTOMATIONS',
      key: 'ai-automations',
      type: 'group',
      shortTitle: 'AI',
    },
    {
      title: 'Posts',
      key: routes.gbpPosts,
      path: routes.gbpPosts,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faMessageLines} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
    {
      title: 'Reviews',
      key: routes.gbpReviews,
      path: routes.gbpReviews,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faStarHalfStroke} color='#A3A4A4'/>,
      isBeta: false,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
    {
      title: 'Q&As',
      key: routes.gbpQAs,
      path: routes.gbpQAs,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faQuestion} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
    {
      title: 'LOCAL SEO TOOLS',
      key: 'local-seo-tools',
      type: 'group',
      shortTitle: 'SEO',
    },
    {
      title: 'Local SEO Heatmaps',
      key: routes.localSeoHeatmaps,
      path: routes.localSeoHeatmaps,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faMapLocationDot} color='#A3A4A4'/>,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
    {
      title: 'Local Citations',
      key: routes.localCitationsV2,
      path: routes.localCitationsV2,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faQuoteLeft} color='#A3A4A4'/>,
      isBeta: false,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
    {
      title: 'Local SERPS',
      key: routes.localSerpsV2,
      path: routes.localSerpsV2,
      color: '#2D6CCA',
      icon: <Icon onClick={() => {}} icon={faMagnifyingGlass} color='#A3A4A4' />,
      ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeoV2', WLchildrenPages)}),
    },
  ];
  const localSeoTools = [
    `${routes.localSeoProjectsList}`,
    `${routes.localSeo}`,
    `${routes.localSeo}/${id}`,
    `${routes.aiGmbWriter}`,
    `${routes.localCitations}`,
    `${routes.myTasks}`,
    `${routes.gbpAutomations}`,
    `${routes.gbpAutomationsPosts}`,
    `${routes.gbpAutomationsReviews}`,
    `${routes.gbpAutomationsQuestions}`,
    `${routes.gbpAutomationsOptimization}`,
    `/local-search-results`,
  ];
  const localSeoToolsV2 = [
    routes.gbpProjectsV2,
    routes.gbpProjectOverview,
    routes.gbpPosts,
    routes.gbpReviews,
    routes.gbpQAs,
    routes.localSeoHeatmaps,
    routes.localSeoHeatmapsDetails,
    `${routes.localSeoHeatmapsDetails}/${id}`,
    routes.localCitationsV2,
    routes.localSerpsV2,
  ];
  useEffect(() => {
    const activeKey = router.asPath?.split('?')[0];
    const mainTool = groups?.find(item => item?.path == activeKey || (router?.pathname?.includes(item?.path) && router?.pathname?.includes('site-explorer')));
    const checkItemPath = groups?.find(item => item?.items?.find(z => z?.path == activeKey || router?.pathname?.includes(z?.path) || (router?.asPath?.includes(z?.path?.split('?')[0]) && (router?.pathname?.includes('report-builder') || router?.pathname?.includes('site-explorer')))));
    const acitiveMenu = mainTool?.key ? mainTool.key : (checkItemPath?.key ? checkItemPath?.key : (localSeoTools.includes(activeKey) ? `${routes.localSeo}` : (localSeoToolsV2).includes(activeKey) ? `${routes.gbpProjectsV2}` : activeKey == '/otto-page' ? activeKey : ''));
    setSelectedKey([acitiveMenu]);
    handleSideBarItems(mainTool?.key ? mainTool?.items : checkItemPath?.key ? checkItemPath?.items : (localSeoTools.includes(activeKey) ? localSeoItems : (localSeoToolsV2).includes(activeKey) ? localSeoItemsV2 : []));
  }, [router, getCompetitorResearcherId, props?.reportBuilderId, getReportsProjectsList?.length, groups]);

  return (
    <>
      <StyledAntdMenu
        className={styles.menu}
        mode='horizontal'
        isDockedSider={isDockedSider}
        rootClassName={`${styles.rootMenuStyle} ${styles.menuPopupOffset}`}
        {...props}
        selectedKeys={selectedKey}
      >
        {groups?.map(item => {
          return <>{renderPlainItem(item)}</>;
        })}
      </StyledAntdMenu>
      {!isSmallScreen && (
        <AntdMenu
          className={classnames(styles.menu, styles.singleItemMenu)}
          mode='horizontal'
          rootClassName={styles.rootMenuStyle}
          {...props}
          selectedKeys={selectedKey}
        >
          {renderPlainItem({
            title: 'Local SEO',
            key: `${routes.localSeo}`,
            path: `${routes.localSeo}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faLocationDot} color='#A3A4A4'/>,
            items: localSeoItems,
            ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
          })}
          {renderPlainItem({
            title: 'Local SEO V2',
            key: `${routes.gbpProjectsV2}`,
            path: `${routes.gbpProjectsV2}`,
            color: '#2d6cca',
            icon: <Icon onClick={() => {}} icon={faLocationDot} color='#A3A4A4'/>,
            items: localSeoItemsV2,
            ...(!isParentAccount && isWhitelabel && {hidden: !isAllowedPage('enableLocalSeo', WLchildrenPages)}),
          })}
        </AntdMenu>
      )}
    </>
  );
});

export default Menu;

const StyledAntdMenu = styled(AntdMenu)<{isDockedSider?: boolean}>`
  margin-left: ${p => p.isDockedSider ? '25px' : '30px'};
  max-width: 780px;
  min-width: 160px;
  @media screen and (max-width: 600px) {
    margin-left: 20px !important;
  }
`;

const AntdMenuItemStyled = styled(AntdMenu.Item)<{itemColor: string}>`
  &.ant-menu-item {
    color: #E8E8E8 !important;
    font-weight: 500 !important;
    background: transparent !important;
    &::after {
      border-bottom: 0px solid !important;
      transition: unset  !important;
    }
    &:hover {
      border-bottom: 0px solid !important;
      &::after {
        border-bottom: 0px solid !important;
        transition: unset !important;
      }

      .ant-menu-title-content {
        color: #fff;
        svg {
          color: ${p => p.itemColor} !important;
          fill: ${p => p.itemColor} !important;
        }
      }
      a {
        color: #fff !important;
        font-weight: 500 !important;
        background: transparent !important;
      }
    }
    a {
      color: #E8E8E8 !important;
      font-weight: 500 !important;
      background: transparent !important;
    }
    .ant-menu-title-content > span {
          height: inherit;
        }
  }
  &.ant-menu-item-selected {
   
      .ant-menu-title-content {
        color: #fff;
        font-weight: 500 !important;
        display: block;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          top: 90%;
          bottom: 0;
          left: 1%;
          right: 1%;
          height: 5px;
          width: 100%;
          background-color: ${p => p.itemColor};
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
      }

        svg {
          color: ${p => p.itemColor} !important;
          fill: ${p => p.itemColor} !important;
        }
      }
      a {
        color: #fff !important;
        font-weight: 500 !important;
        background: transparent !important;
      }
    }
`;

const Icon = styled(FontAwesomeIcon)`
  height: 16px !important;
  width: 18px !important; 
`;

const PopupStyled = styled.div`
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

const PopupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;

`;

const PopupSubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #E8E8E8;
  margin-top: 6px;
`;
