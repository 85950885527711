import React, {useEffect, useState} from 'react';
import {SettingsModal, StyledDatePicker} from '../styleComponents';
import {Form} from 'antd';
import {faCalendars, faFeather, faSquareCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faCaretDown, faPlay, faXmark} from '@fortawesome/pro-solid-svg-icons';
import CustomSlider from './customSlider';
import moment from 'moment';
import ModalContent from './modalContent';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {snakeToCamel} from '@/utils/string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  isStandalone?: boolean;
  locationId?: any;
}

const isFutureDate = date => {
  const inputDate = moment(date);
  return inputDate.isAfter(moment());
};

const disabledDate = current => {
  return current && current.isSameOrBefore(moment().endOf('day'));
};

const AutomateQandASettingsModal = observer(({visible, setVisible, isStandalone, locationId}: Props) => {
  const {ottoV2Store: {qAndASettings}} = useStore('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const fields = [
    {
      label: 'Q&A frequency ',
      icon: faCalendars,
      fieldType: 'radio',
      key: 'frequency_type',
      defaultValue: qAndASettings?.frequencyType || 'OPTIMIZED',
      resetKey: 'frequency',
      resetVal: 1,
      radioOptions: [
        {
          heading: 'OTTO Optimized frequency',
          desc: 'OTTO automatically adjusts the frequency based on a weekly analysis of over 2,000 similar businesses, helping you rank higher, faster.',
          content: <></>,
          value: 'OPTIMIZED',
        },
        {
          heading: 'Set a custom frequency',
          desc: null,
          content: <CustomSlider defaultValue={qAndASettings?.frequency} form={form} disabled={loading}/>,
          value: 'CUSTOM',
        },
      ],
    },
    {
      label: 'Start date',
      icon: faPlay,
      fieldType: 'radio',
      key: 'start_date_type',
      defaultValue: isFutureDate(qAndASettings?.startAt) ? 'future' : 'today',
      resetKey: 'start_at',
      resetVal: moment().add(1, 'day'),
      radioOptions: [
        {
          heading: 'Start posting today',
          desc: null,
          content: <></>,
          value: 'today',
        },
        {
          heading: 'Choose a future date',
          desc: null,
          content: <Form.Item name='start_at'>
            <StyledDatePicker
              disabled={loading}
              disabledDate={disabledDate}
              defaultValue={moment().add(1, 'day')}
              format={'MMMM DD, YYYY'}
              suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212' />}
            />
          </Form.Item>,
          value: 'future',
        },
      ],
    },
    {
      label: <>AI writing tips <br/> (optional)</>,
      icon: faFeather,
      fieldType: 'textarea',
      key: 'help_prompt',
      props: {
        placeholder: 'Share any specific writing tips or preferences for the AI to craft your Google Business Profile Q&As',
        rows: 6,
        defaultValue: qAndASettings?.helpPrompt || '',
      },
    },
    {
      label: 'Approval',
      icon: faSquareCheck,
      fieldType: 'radio',
      key: 'approval_type',
      defaultValue: qAndASettings?.approvalType || 'AUTO_PUBLISH',
      radioOptions: [
        {
          heading: 'Post automatically (no approval required)',
          desc: null,
          content: <></>,
          value: 'AUTO_PUBLISH',
        },
        {
          heading: 'Approve before posting',
          desc: null,
          content: <></>,
          value: 'APPROVE_FOR_PUBLISH',
        },
      ],
    },
  ];

  const [fieldsArr, setFieldsArr] = useState(fields);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const initalValues = () => {
    if (!qAndASettings) return;
    const keysArr = fieldsArr.map(i => i?.key);
    const obj = {};
    keysArr.forEach(i => {
      if (snakeToCamel(i) in qAndASettings) {
        obj[i] = qAndASettings[snakeToCamel(i)];
      }
    });
    obj['start_at'] = isFutureDate(qAndASettings?.startAt) ? moment(qAndASettings?.startAt) : moment().add(1, 'day');
    obj['frequency'] = qAndASettings?.frequency;
    obj['start_date_type'] = isFutureDate(qAndASettings?.startAt) ? 'future' : 'today';
    return obj;
  };

  useEffect(() => {
    setFieldsArr(fields);
    form.setFieldsValue(initalValues());
  }, [JSON.stringify(qAndASettings), isFutureDate(qAndASettings?.startAt), visible]);

  useEffect(() => {
    setFieldsArr(fields);
  }, [loading]);

  return (
    <SettingsModal
      visible={visible}
      footer={false}
      closable={false}
      onCancel={onClose}
      width={760}
    >
      <FontAwesomeIcon icon={faXmark} className='close-icon' color='#FFFFFF' onClick={onClose} />
      <ModalContent fieldsArr={fieldsArr} form={form} onClose={onClose} initalValues={initalValues} loading={loading} setLoading={setLoading} isStandalone={isStandalone} locationId={locationId}/>
    </SettingsModal>
  );
});

export default AutomateQandASettingsModal;
