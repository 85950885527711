import {EChartOption} from 'echarts';
import numeral from 'numeral';
import {types, flow, cast, getParent} from 'mobx-state-tree';
import moment from 'moment';
import {KeywordDetails} from './keyword-details';
import {KeywordSerpDetails} from './serp-details';
import {OVERVIEW_DETAIL_API} from '@/api/keyword-explorer';
import {kFormatter} from '@/utils/string';
import {notification} from '@/utils/notifications';
import {Identifier, OverviewFilters} from './overview';
import {BannerVariant} from '@/components/common-components/components/banner';
import {TaskStatus} from '@/api/keyword-explorer/overview/overview-api';
import {KEYWORD_DETAIL_TIME_INTERVAL, KEYWORD_DETAIL_API_POLL_INTERVAL} from '@/constants';

const RawChartData = types.model({
  date: types.string,
  volume: types.number,
});

const GlobalVolumeData = types.model({
  countryCode: types.string,
  searchVolume: types.number,
});

const FocusedKeywordDetails = types
  .model({
    keyword: types.maybeNull(types.string),
    difficulty: types.maybeNull(types.number),
    requiredBacklinks: types.maybeNull(types.number),
    searchVolume: types.maybeNull(types.number),
    searchVolumeData: types.array(RawChartData),
    trendsData: types.array(RawChartData),
    globalVolume: types.maybeNull(types.number),
    globalVolumeData: types.array(GlobalVolumeData),
  })
  .views(self => ({
    get formattedSearchVolume() {
      return numeral(self.searchVolume).format('0,0a').toUpperCase();
    },
    get searchVolumeChart() {
      const options: EChartOption = {
        yAxis: {
          type: 'value',
          show: true,
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
          axisLabel: {
            color: '#77757D',
            formatter: value => {
              return kFormatter(value);
            },
          },
        },
        grid: {
          left: '0px',
          right: '0px',
          bottom: '0px',
          containLabel: true,
        },
        xAxis: {
          data: self.searchVolumeData.map(datum => datum.date),
          type: 'category',
          show: true,
          axisLabel: {
            interval: 5,
            formatter: label => {
              const date = moment(label);
              return `{month|${date.format('MMM')}}\n{year|${date.format('YYYY')}}`;
            },
            rich: {
              month: {
                color: '#77757D',
                lineHeight: 15.54,
              },
              year: {
                color: '#77757D',
                fontSize: 12,
                lineHeight: 12.95,
              },
            },
          },
        },
        series: [
          {
            itemStyle: {
              color: '#7F4EAD',
            },
            barWidth: '40%',
            type: 'bar',
            data: self.searchVolumeData.map(datum => datum.volume),
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: ([xAxis]: any) => {
            return (`
              <div>${moment(xAxis.name).format('MMM YYYY')}</div>
              <strong style="font-size: 14px">Search Volume: ${kFormatter(xAxis.value)}</strong>
            `);
          },
          padding: 10,
          extraCssText: 'background: linear-gradient(180deg, #383542 0%, #25242B 100%); box-shadow: 0px 5px 13px rgba(0, 14, 88, 0.2); border-radius: 10px;',
        },
      };
      return options;
    },
    get trendsChart() {
      const options: EChartOption = {
        yAxis: {
          type: 'value',
          show: true,
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        grid: {
          left: '20px',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          show: true,
        },
        series: [
          {
            symbol: 'none',
            lineStyle: {
              color: '$color-purple-medium',
            },
            type: 'line',
            data: self.trendsData.map(datum => datum.volume),
          },
        ],
      };

      return options;
    },
    get formattedGlobalVolumeData() {
      return self.globalVolumeData?.slice()
        .sort((a, b) => a.searchVolume < b.searchVolume ? 1 : -1)
        .filter(record => record.countryCode != '')
        .map(datum => ({
          ...datum,
          searchVolume: numeral(datum.searchVolume).format('0a').toUpperCase(),
        }));
    },
    get formattedGlobalValue() {
      return numeral(self.globalVolume).format('0,0a').toUpperCase();
    },
  }));

export const FocusedKeywordStore = types
  .model({
    loading: types.optional(types.boolean, false),
    fromListPage: types.optional(types.boolean, false),
    searchedKeyword: types.array(types.string),
    details: FocusedKeywordDetails,
    relatedKeywords: types.array(KeywordDetails),
    autocomplete: types.array(KeywordDetails),
    questions: types.array(KeywordDetails),
    serpOverview: types.maybeNull(types.array(KeywordSerpDetails)),
    filters: OverviewFilters,
    tasksComplete: types.optional(types.boolean, false),
    reTry: types.optional(types.number, 0),
    isRecentSearch: types.boolean,
  })
  .views(self => ({
    getTaskStatus() {
      return self.tasksComplete;
    },

    getTwoLowestDr() {
      const filteredData = self.serpOverview.map(item => {
        return {
          page: item.page,
          dr: item.dr,
        };
      });

      const newDataArray = [];
      for (let i = 0; i < 10; i++) {
        const min = i * 10;
        const max = min === 90 ? min + 10 : min + 9;
        newDataArray.push({
          min: min,
          max: max,
          data: filteredData.filter(item => item.dr >= min && item.dr <= max),
        });
      }

      const sortedDataArray = newDataArray.filter(item => item.data.length > 0);
      const finalDataArray = sortedDataArray.filter((item, i) => i < 2);

      return finalDataArray;
    },

    getAutocomplete(query: string) {
      const formattedQuery = query.toLowerCase();
      const filtered = self.autocomplete.filter(record => record.keyword.toLowerCase().includes(formattedQuery));

      const data = filtered.map(item => ({
        ...item,
        trend: item.trendSeries,
        cpc: item.formattedCpc,
        cpcCloned: item.formattedCpc != '-' ? parseFloat(item.formattedCpc.replaceAll('$', '')) : 0,
        ppcd: item.formattedPpcd,
        kd: item.formattedKd,
        sv: item.formattedSv ? numeral(item.formattedSv).format('0,0a').toUpperCase(): item.formattedSv,
        svCloned: item.formattedSv != '-' ? +(item.formattedSv.replaceAll(',', '')) : 0,
        traffic: item.formattedTraffic,
      }));

      return data;
    },
    getQuestions(query: string) {
      const formattedQuery = query.toLowerCase();
      const filtered = self.questions.filter(record => record.keyword.toLowerCase().includes(formattedQuery));

      const data = filtered.map(item => ({
        ...item,
        trend: item.trendSeries,
        cpc: item.formattedCpc,
        cpcCloned: item.formattedCpc != '-' ? parseFloat(item.formattedCpc.replaceAll('$', '')) : 0,
        ppcd: item.formattedPpcd,
        kd: item.formattedKd,
        sv: item.formattedSv ? numeral(item.formattedSv).format('0,0a').toUpperCase(): item.formattedSv,
        svCloned: item.formattedSv != '-' ? +(item.formattedSv.replaceAll(',', '')) : 0,
        traffic: item.formattedTraffic,
      }));

      return data;
    },
    get serpOverviewData() {
      const data = self.serpOverview?.map(record => ({
        url: {
          title: record.title,
          page: record.page,
          position: record.position,
        },
        dr: record.dr,
        ur: record.ur,
        domains: record.formattedDomains ? numeral(record.formattedDomains).format('0,0a').toUpperCase(): record.formattedDomains,
        domainsCloned: record.formattedDomains != '-' ? +(record.formattedDomains.replaceAll(',', '')) : 0,
        traffic: record.formattedTraffic ? numeral(record.formattedTraffic).format('0,0a').toUpperCase(): record.formattedTraffic,
        trafficCloned: record.formattedTraffic != '-' ? +(record.formattedTraffic.replaceAll(',', '')) : 0,
        keywords: record.fomattedKeywords,
        cs: record.cs,
        cl: record.formattedCl,
        psi: record.psi,
      }));

      return data? data : [];
    },
    getComingFromList() {
      return self.fromListPage;
    },
    getSearchedKeywordsList() {
      return self.searchedKeyword;
    },
    get checkStatus() {
      if (!self.tasksComplete && self.reTry >= KEYWORD_DETAIL_TIME_INTERVAL) {
        return false;
      }
      return true;
    },
  }))
  .actions(self => {
    const setBannerError = () => {
      const parent = getParent(self) as any;
      parent.setBanner(true, 'Could not get keyword details', 'Please reach out to support or your account manager.', BannerVariant.ERROR);
    };

    const getDetailOverview = flow(function* (payload, countryCode, excludeKd, siteProperty) {
      try {
        const updatedPayload = payload.replaceAll('+', ' ');
        const data = yield OVERVIEW_DETAIL_API.postKeywordDetail(updatedPayload, countryCode, null, excludeKd, siteProperty);
        return data;
      } catch (e) {
        setBannerError();
        notification.error('Something went wrong');
        return false;
      }
    });

    const setIsRecentSearch = (value: boolean) => {
      self.isRecentSearch = value;
    };

    const startLoading = () => {
      self.loading = true;
    };
    const comingFromListPage = val => {
      self.fromListPage = val;
    };
    const stopLoading = () => {
      self.loading = false;
    };
    const setSearchedKeywords = kw => {
      self.searchedKeyword = kw;
    };
    const resetPreviousData = () => {
      self.details.keyword = null;
      self.details.difficulty = 0;
      self.details.globalVolume = 0;
      self.details.searchVolumeData = cast([]);
      self.details.searchVolume = 0;
      self.details.trendsData = cast([]);
      self.serpOverview = cast([]);
      self.questions = cast([]);
      self.autocomplete = cast([]);
      self.relatedKeywords = cast([]);
    };
    const changeKeyword = flow(function* (keyword: string, siteProperty: string, countryCode?: string, excludeKd?: boolean) {
      if (keyword) {
        const response = yield getDetailOverview(keyword, countryCode, excludeKd, siteProperty);
        if (response) {
          self.details.keyword = keyword;
          self.details.difficulty = response.results?.difficulty;
          self.details.globalVolume = response.results?.globalVolume;
          self.details.searchVolumeData = response.results?.searchVolumeData;
          self.details.searchVolume = response.results?.searchVolume;
          self.details.trendsData = response.results?.searchVolumeData;
          self.details.globalVolumeData = response.results.topCountriesSearchVolume;
          self.serpOverview = cast(response.results?.serpOverview);
          self.questions = cast(response.results?.questions);
          self.autocomplete = cast(response.results?.autocomplete);
          self.relatedKeywords = cast(response.results?.relatedKeywords);
          self.reTry = cast(self.reTry + 1);
          self.tasksComplete = Object.keys(response.results?.taskStatus).every(key => response.results?.taskStatus[key] === TaskStatus.SUCCESS);
          if (!self.tasksComplete) {
            yield new Promise(r => setTimeout(r, KEYWORD_DETAIL_API_POLL_INTERVAL));
            // setTimeout(yield changeKeyword(keyword, countryCode), KEYWORD_DETAIL_API_POLL_INTERVAL);
            return changeKeyword(keyword, countryCode);
          } else {
            stopLoading();
          }
        }
      } else {
        self.details.keyword = keyword;
      }
    });

    return {
      changeKeyword, setIsRecentSearch, startLoading, stopLoading, resetPreviousData, comingFromListPage, setSearchedKeywords, setBannerError,
    };
  });

export const initFocusedKeywordStore = () => {
  return {
    loading: false,
    isRecentSearch: false,
    fromListPage: false,
    searchedKeyword: [],
    details: {
      keyword: null,
      difficulty: 0,
      requiredBacklinks: 0,
      searchVolume: 0,
      searchVolumeData: [],
      trendsData: [],
      globalVolume: 0,
      globalVolumeData: [],
    },
    relatedKeywords: [],
    autocomplete: [],
    questions: [],
    serpOverview: [],
    filters: {
      active: '' as Identifier,
      kd: null,
      sv: null,
      traffic: null,
      cpc: null,
      ppcd: null,
      query: '',
    },
  };
};
