import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import {flow, getParent, Instance, types} from 'mobx-state-tree';
import uniq from 'lodash/uniq';
import lowerCase from 'lodash/lowerCase';

import {GetCompetitorRefdomainResponse, GetRefdomainResponse, TaskStatus} from '@/api/backlink-analyzer/backlink-analyzer.model';
import type {BackLinkAnalyzerStoreType} from '.';
import {BACKLINK_API_API_POLL_INTERVAL} from '@/constants';

const CategoryModel = types.model({
  value: types.number,
  color: types.maybeNull(types.string),
});

const RefdomainModel = types.model({
  id: types.identifierNumber,
  ahrefsRank: types.number,
  ahrefsRankColor: types.maybeNull(types.string),
  ahrefsTop: types.number,
  alt: types.maybeNull(types.string),
  anchor: types.maybeNull(types.string),
  categories: types.model({
    adult: types.maybeNull(CategoryModel),
    arts: types.maybeNull(CategoryModel),
    business: types.maybeNull(CategoryModel),
    technology: types.maybeNull(CategoryModel),
    games: types.maybeNull(CategoryModel),
    home: types.maybeNull(CategoryModel),
    science: types.maybeNull(CategoryModel),
    shopping: types.maybeNull(CategoryModel),
    society: types.maybeNull(CategoryModel),
    recreation: types.maybeNull(CategoryModel),
    regional: types.maybeNull(CategoryModel),
    reference: types.maybeNull(CategoryModel),
    sports: types.maybeNull(CategoryModel),
    news: types.maybeNull(CategoryModel),
    health: types.maybeNull(CategoryModel),
    world: types.maybeNull(CategoryModel),
  }),
  citationFlow: types.maybeNull(types.number),
  domainFrom: types.maybeNull(types.string),
  domainRating: types.number,
  domainTo: types.maybeNull(types.string),
  encoding: types.maybeNull(types.string),
  firstSeen: types.maybeNull(types.string),
  httpCode: types.number,
  isNofollow: types.number,
  isOriginal: types.number,
  isSponsored: types.number,
  isUgc: types.number,
  language: types.maybeNull(types.string),
  lastVisited: types.maybeNull(types.string),
  linkType: types.maybeNull(types.string),
  linksExternal: types.number,
  linksInternal: types.number,
  pageSize: types.number,
  prevVisited: types.maybeNull(types.string),
  redirectCode: types.number,
  textPost: types.maybeNull(types.string),
  domainRatingColor: types.maybeNull(types.string),
  citationFlowColor: types.maybeNull(types.string),
  trustFlowColor: types.maybeNull(types.string),
  textPre: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  totalBacklinks: types.number,
  trustFlow: types.maybeNull(types.number),
  urlFrom: types.maybeNull(types.string),
  urlFromFirstSeen: types.maybeNull(types.string),
  urlTo: types.maybeNull(types.string),
  selected: types.optional(types.boolean, false),
});

type RefdomainModelType = Instance<typeof RefdomainModel>;

const RefDomainContent = types.model({
  next: types.maybeNull(types.string),
  pageSize: types.number,
  previous: types.maybeNull(types.string),
  totalPages: types.number,
  refdomainsCount: types.number,
  websiteId: types.number,
  data: types.array(RefdomainModel),
});

type RefDomainContentType = Instance<typeof RefDomainContent>;

export const RefdomainStore = types
  .model({
    contentCached: types.array(RefDomainContent),
    content: types.maybeNull(types.late(() => types.reference(RefDomainContent, {
      set(value) {
        return value.websiteId;
      },
      get(identifier, parent) {
        return parent.contentCached.find((content: RefDomainContentType) => content.websiteId === identifier) || {
          websiteId: 0,
          data: [],
        } as RefDomainContentType;
      },
    }))),
    loading: types.optional(types.boolean, false),
    isInitLoad: types.boolean,
    selectedProject: types.maybeNull(types.string),
    tasksComplete: types.optional(types.boolean, false),
  })
  .views(self => ({
    get backlinkSize() {
      return self.content?.data.length || 0;
    },
    get getSelectedProject() {
      return self.selectedProject;
    },
    get filterCategories() {
      const data: RefdomainModelType[] = self.content?.data || [];
      const categories = data.reduce((acc, curr) => {
        const category1 = Object.keys(curr.categories)[0];
        const category2 = Object.keys(curr.categories)[1];
        const category3 = Object.keys(curr.categories)[2];

        acc['category1'].push(category1);
        acc['category2'].push(category2);
        acc['category3'].push(category3);

        return acc;
      }, {
        category1: [],
        category2: [],
        category3: [],
      });

      const uniqueCategories = {
        category1: uniq(categories.category1),
        category2: uniq(categories.category2),
        category3: uniq(categories.category3),
      };

      return Object.keys(uniqueCategories).reduce((acc, curr) => {
        acc[curr] = uniqueCategories[curr].map(item => ({
          text: item,
          value: lowerCase(item),
        })).filter(item => item.value);

        return acc;
      }, {});
    },
  }))
  .actions(self => {
    const loadRefdomains = flow(function* (id: number, ordering?:string, page?:number, keyword?:string, filters?:any, categories?:string[]) {
      const parent = getParent<BackLinkAnalyzerStoreType>(self);
      const projectStore = parent.projectStore;
      self.loading = true;
      try {
        const data = <GetRefdomainResponse> (yield BacklinkAnalyzerClient.getRefdomains(id, ordering, page, keyword, filters, categories));
        self.tasksComplete = data.backlinks == TaskStatus.SUCCESS;

        if (!self.tasksComplete) {
          yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
          return loadRefdomains(id, ordering, page, keyword, filters, categories);
        } else {
          if (data) {
            const newContent = RefDomainContent.create({
              next: data.next,
              pageSize: data.pageSize,
              previous: data.previous,
              totalPages: data.totalPages,
              refdomainsCount: data.refdomainsCount,
              websiteId: id,
              data: data.content,
            });

            const index = self.contentCached.findIndex(x => x.websiteId === newContent.websiteId);

            if (index === -1) {
              self.contentCached.push(newContent);
            } else {
              self.contentCached[index] = newContent;
            }
            self.content = newContent;
          }
          if (!self.selectedProject) {
            self.selectedProject = projectStore.currentProject?.primaryWebsite.domainName;
          }
        }
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.loading = false;
        self.isInitLoad = false;
      }
    });
    const updateRefdomains = flow(function* (id: number, competitorPk:number, ordering?:string, page?:number, keyword?:string) {
      self.loading = true;
      try {
        const data = <GetCompetitorRefdomainResponse>(yield BacklinkAnalyzerClient.getCompetitorRefdomains(id, competitorPk, ordering, page, keyword));
        self.tasksComplete = data.backlinks == TaskStatus.SUCCESS;

        if (!self.tasksComplete) {
          yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
          return updateRefdomains(id, competitorPk, ordering, page, keyword);
        } else {
          if (data) {
            const newContent = RefDomainContent.create({
              next: data.next,
              pageSize: data.pageSize,
              previous: data.previous,
              totalPages: data.totalPages,
              refdomainsCount: data.refdomainsCount,
              websiteId: id,
              data: data.content,
            });

            const index = self.contentCached.findIndex(x => x.websiteId === newContent.websiteId);

            if (index === -1) {
              self.contentCached.push(newContent);
            } else {
              self.contentCached[index] = newContent;
            }
            self.content = newContent;
          }
        }
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const toggleSelect = (id: number) => {
      const data = self.content?.data || [];
      const item = data.find(item => item.id === id);
      item.selected = !item.selected;
    };

    const generateDisavow = () => {
      const contentData = self.content?.data || [];
      const data = contentData.filter(backlink => backlink.selected).map(backlink => backlink.urlFrom);
      return data;
    };

    const selectCurrentProject = (project: string) => {
      self.selectedProject = project;
    };

    return {
      loadRefdomains,
      updateRefdomains,
      toggleSelect,
      generateDisavow,
      selectCurrentProject,
    };
  });

export const initRefdomainStore = () => {
  return {
    contentCached: [],
    content: null,
    loading: true,
    selectedProject: null,
    isInitLoad: true,
    tasksComplete: false,
  };
};
