import {getAuthorizationHeader, getAuthHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {AxiosResponse} from 'axios';
import {isbacklinkProfileAnalysisPublicPage} from '@/utils/url';

interface keywordDetailPayload {
  keyword: string;
}

interface BacklinkProfileAnalysisHistoryPayload {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  search?: string;
}
interface GapAnalysisHistoryPayload {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  search?: string;
}

class BacklinkOverviewApi extends BaseApi {
  public async findKeywordList(paylaod) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/backlink/backlink-research/`, paylaod, {
      headers: getAuthHeader(),
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getBacklinkResearchlist(params) {
    try {
      const response = await this.axios.get(`/backlink/backlink-research/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getBacklinkKeywordOverview() {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/backlink/backlink-research/`, {
      headers: getAuthHeader(),
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getBacklinkDetail(id, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/backlink/backlink-research/${id}/`, {
      headers: getAuthHeader(),
      params: params,
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async deleteBacklinkDetail(id) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.delete(`/backlink/backlink-research/${id}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async recrawlBacklinkDetail(id, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/backlink/backlink-research/${id}/recrawl/`, {
      headers: getAuthHeader(),
      params: params,
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getBacklinkTabDetail(id, context, page, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/backlink/backlink-research/${id}/view-all/?context=${context}&page=${page}`, {
      headers: getAuthHeader(),
      params: params,
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getBacklinkKeywordDetail(id) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/backlink/backlink-research/${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async updateKeywordlist(data) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.put(`/v1/keywordslist/${data.id}/`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async deleteKeywordlist(id) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.delete(`/v1/keywordslist/${id}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getDrawerData(id, page, drawer, url, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    const response = await this.axios.get(`/backlink/backlink-research/${id}/${page}/?${drawer}=${url}`, {
      headers: getAuthHeader(),
      params: params,
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getLostDomainDrawerData(id, page, drawer, url, type, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    const response = await this.axios.get(`/backlink/backlink-research/${id}/${page}/?${drawer}=${url}&type=${type}`, {
      headers: getAuthHeader(),
      params: params,
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getBacklinkProfileAnalysis(paylaod) {
    const response = await this.axios.post(`/backlink/backlink-profile-analysis/`, paylaod, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getBacklinkProfileAnalysisHistory({page, pageSize, sortBy, search} : BacklinkProfileAnalysisHistoryPayload) {
    const response = await this.axios.get(`/backlink/backlink-profile-analysis/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      params: {
        'page': page,
        'page_size': pageSize,
        ...(sortBy && {ordering: sortBy}),
        ...(search && {search: search}),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getBacklinkProfileAnalysisDetails(id: number | string, publicHash?:string) {
    const response = await this.axios.get(isbacklinkProfileAnalysisPublicPage() ? `/backlink/backlink-profile-analysis/${id}?hash=${publicHash}`:`/backlink/backlink-profile-analysis/${id}/`, {
      headers: getAuthHeader(),
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getBacklinkGapAnalysis(paylaod) {
    const response = await this.axios.post(`/backlink/backlink-gap-analysis/`, paylaod, {
      headers: getAuthHeader(),

      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getUpdatedBacklinksProfile(id, paylaod) {
    const response = await this.axios.put(`/backlink/backlink-profile-analysis/${id}/`, paylaod, {
      headers: getAuthHeader(),

      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getUpdatedGapAnalysis(id, paylaod) {
    const response = await this.axios.put(`/backlink/backlink-gap-analysis/${id}/`, paylaod, {
      headers: getAuthHeader(),

      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getBacklinkGapAnalysisHistory({page, pageSize, sortBy, search} : GapAnalysisHistoryPayload) {
    const response = await this.axios.get(`/backlink/backlink-gap-analysis/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      params: {
        'page': page,
        'page_size': pageSize,
        ...(sortBy && {ordering: sortBy}),
        ...(search && {search: search}),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async deleteGapAnalysisKeyword(id) {
    const response = await this.axios.delete(`backlink/backlink-gap-analysis/${id}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async deleteProfileGapAnalysis(id) {
    const response = await this.axios.delete(`backlink/backlink-profile-analysis/${id}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getBacklinkGapAnalysisDetails(id: number | string, page: number) {
    const response = await this.axios.get(`/backlink/backlink-gap-analysis/${id}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      params: {
        'page': page,
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
}

export default BacklinkOverviewApi;
