import {observer} from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import {EChartOption} from 'echarts';
import React from 'react';

interface pieChartProps {
    backgroundColor?: string;
    trigger: string;
    name?: string;
    chartData: Array<any>;
    labelPosition: string;
    labelColor: string;
    labelLine?: boolean;
    labelLineColor?: string;
    roseType?: string;
}

const PieChart: React.FC<pieChartProps> = observer(({
  backgroundColor = 'transparent',
  trigger = 'item',
  name = '',
  chartData = [],
  labelPosition = 'outside',
  labelColor = '#ddd',
  labelLine = true,
  labelLineColor = '#ddd',
  roseType = 'radius',
}) => {
  return (
    <ReactEcharts
      option = {
            {
              backgroundColor: backgroundColor,
              tooltip: {
                trigger: trigger,
              },
              series: [
                {
                  name: name,
                  type: 'pie',
                  radius: '80%',
                  itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 3,
                  },
                  center: ['50%', '50%'],
                  data: chartData,
                  roseType: roseType,
                  label: {
                    show: true,
                    position: labelPosition,
                    color: labelColor,
                  },
                  labelLine: {
                    show: labelLine,
                    lineStyle: {
                      color: labelLineColor,
                    },
                    smooth: 0.2,
                    length: 10,
                    length2: 20,
                  },
                },
              ],
            } as EChartOption
      }
    />
  );
});

export default PieChart;
