import {replaceRouterQueryParam} from '@/utils/router';
import {Instance, types, cast, flow, getParent} from 'mobx-state-tree';
import {NextRouter} from 'next/router';
import {sitesApi} from '@/api/gsc';
import {GSC_PROPERTIES_PAGE_SIZE} from '@/constants';
import {notification} from '@/utils/notification-v2';
import {getDomain, getProjectName, getSingleUrlParam} from '@/utils/url';
import {toJS} from 'mobx';
import {getPrimaryWebsite} from './utils';
import numeral from 'numeral';
import {BannerVariant} from '@/components/common-components/components/banner';
import {EMAIL_NOTIFICATIONS_API} from '@/api/email-notifications';
import {apiError} from '@/utils/api';

const DailySerpHistogramsAndClicks = types.model({
  clicks: types.number,
  date: types.string,
  impressions: types.number,
  trafficValue: types.number,
  trafficValueV2: types.number,
});

const TopPages = types.model({
  clicks: types.number,
  url: types.string,
  impressions: types.number,
  key: types.number,
});

const KeywordCountHistoricalChart = types.model({
  date: types.string,
  kwCount: types.number,
});

const TotalKeywords = types.model({
  title: types.string,
  current: types.number,
});

const CountryBaseValue = types.model({
  countryCode: types.maybeNull(types.string),
  value: types.maybeNull(types.union(types.number, types.string, types.boolean)),
});

const Reports = types.model({
  dailySerpHistogramsAndClicks: types.maybeNull(types.array(DailySerpHistogramsAndClicks)),
  keywordCountHistoricalChart: types.maybeNull(types.array(KeywordCountHistoricalChart)),
  topPages: types.maybeNull(types.array(TopPages)),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(TotalKeywords),
  trafficValue: types.maybeNull(types.number),
  trafficValueV2: types.maybeNull(types.number),
});

const Property = types.model({
  id: types.number,
  activeCountryCodes: types.maybeNull(types.array(types.string)),
  activeForCurrentCustomer: types.maybeNull(types.boolean),
  alpha2CountryCode: types.maybeNull(types.string),
  isGaConnected: types.maybeNull(types.boolean),
  isGaSettingsActive: types.maybeNull(types.boolean),
  possibleGaMismatch: types.maybeNull(types.boolean),
  hasSiteGaCredentials: types.maybeNull(types.boolean),
  activatedAnalyticsSettingsId: types.maybeNull(types.number),
  availableCountryCodes: types.maybeNull(types.array(types.string)),
  countryBreakdownLastFetchedAt: types.maybeNull(types.string),
  countryBreakdownStatus: types.maybeNull(types.string),
  countryCodeOptions: types.maybeNull(types.array(types.model({
    isProcessingComplete: types.maybeNull(types.boolean),
    saPctProgress: types.maybeNull(types.number),
    countryCode: types.maybeNull(types.string),
  }))),
  deactivated: types.maybeNull(types.boolean),
  deviceBreakdownLastFetchedAt: types.maybeNull(types.string),
  deviceBreakdownStatus: types.maybeNull(types.string),
  domainRating: types.optional(types.maybeNull(types.number), 0),
  evotLastFetchedAt: types.maybeNull(types.array(CountryBaseValue)),
  evotTaskStatus: types.maybeNull(types.array(CountryBaseValue)),
  histogramAggTaskStatus: types.maybeNull(types.string),
  maxTrafficValue: types.maybeNull(types.number),
  isProcessingComplete: types.maybeNull(types.array(CountryBaseValue)),
  monthlyCost: types.maybeNull(types.array(CountryBaseValue)),
  saDataLoadTimeLeft: types.maybeNull(types.array(CountryBaseValue)),
  saDataPoints: types.maybeNull(types.array(CountryBaseValue)),
  saHistoricalLastFetchedAt: types.maybeNull(types.array(CountryBaseValue)),
  saHistoricalStatus: types.maybeNull(types.array(CountryBaseValue)),
  saMostrecentDataDt: types.maybeNull(types.array(CountryBaseValue)),
  saOldestDataDt: types.maybeNull(types.array(CountryBaseValue)),
  saPctProgress: types.maybeNull(types.array(CountryBaseValue)),
  saRecentdataTaskStatus: types.maybeNull(types.array(CountryBaseValue)),
  saTotalKwCount: types.maybeNull(types.array(CountryBaseValue)),
  searchAppearanceLastFetchedAt: types.maybeNull(types.string),
  searchAppearanceStatus: types.maybeNull(types.string),
  sitemapsLastFetchedAt: types.maybeNull(types.string),
  sitemapsStatus: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.array(CountryBaseValue)),
  trafficValue: types.maybeNull(types.array(CountryBaseValue)),
  trafficValueV2: types.maybeNull(types.array(CountryBaseValue)),
  trafficValueLastProcessedAt: types.maybeNull(types.array(CountryBaseValue)),
  trafficValueStatus: types.maybeNull(types.array(CountryBaseValue)),
  url: types.maybeNull(types.string),
  reports: types.maybeNull(Reports),
  favicon: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  backlinksProcessed: types.maybeNull(types.boolean),
  activatedGaPropertyId: types.maybeNull(types.string),
});

export type PropertyType = Instance<typeof Property>;

const sitesModel = types.model({
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  alpha2CountryCode: types.maybeNull(types.string),
  searchperformanceLastFetchedAt: types.maybeNull(types.string),
  searchperformanceTaskStatus: types.maybeNull(types.string),
  availableCountryCodes: types.maybeNull(types.array(types.string)),
});

const searchPerformanceStatusModel = types.model({
  sites: types.maybeNull(types.array(sitesModel)),
});

/* Sites */
const KeywordData = types.model({
  date: types.maybeNull(types.string),
  kwCount: types.maybeNull(types.number),
});
const ImpressionData = types.model({
  date: types.maybeNull(types.string),
  impressions: types.maybeNull(types.number),
});
const TrafficData = types.model({
  date: types.maybeNull(types.string),
  trafficValue: types.maybeNull(types.number),
});
const ClicksData = types.model({
  date: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
});
const AvgPositiondata = types.model({
  countryCode: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});
const AnchorText = types.model({
  label: types.string,
  total: types.maybeNull(types.number),
  percentage: types.number,
}).views(self => ({
  get totalFormat() {
    return self.total ? numeral(self.total).format('0,0') : '-';
  },
}));
const Categories = types.model({
  name: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  score: types.maybeNull(types.number),
}).views(self => ({
  get scoreFormat() {
    return self.score ? numeral(self.score).format('0,0') : '-';
  },
}));
const TopCategories = types.model({
  name: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  score: types.maybeNull(types.number),
}).views(self => ({
  get scoreFormat() {
    return self.score ? numeral(self.score).format('0,0') : '-';
  },
}));
const CategoryData = types.model({
  categories: types.array(Categories),
  topCategory: TopCategories,
});
const KeywordPosition = types.model({
  name: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});
const positionHistogramDataModel = types.model({
  date: types.maybeNull(types.string),
  serp1: types.maybeNull(types.number),
  serp2To3: types.maybeNull(types.number),
  serp4To10: types.maybeNull(types.number),
  serp11To20: types.maybeNull(types.number),
  serp2150: types.maybeNull(types.number),
  serp51100: types.maybeNull(types.number),
});
const WebsiteDetailsModel = types.model({
  id: types.maybeNull(types.number),
  hostname: types.string,
  refdomainCount: types.maybeNull(types.number),
  keywordCount: types.maybeNull(types.number),
  trafficvalue: types.maybeNull(types.number),
  activeCountryCodes: types.maybeNull(types.array(types.string)),
  keywordData: types.maybeNull(types.array(KeywordData)),
  nonProjectedKeywordData: types.maybeNull(types.array(KeywordData)),
  impressionData: types.maybeNull(types.array(ImpressionData)),
  trafficData: types.maybeNull(types.array(TrafficData)),
  clicksData: types.maybeNull(types.array(ClicksData)),
  avgPosition: types.maybeNull(types.array(AvgPositiondata)),
  impressionsHistogramsPctChange: types.maybeNull(types.number),
  organicTrafficHistogramsPctChange: types.maybeNull(types.number),
  screenshotImage: types.maybeNull(types.string),
  ot: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  color: types.optional(types.string, ''),
  topPages: types.maybeNull(types.array(types.string)),
  countryCodesAndTrafficValue: types.maybeNull(types.array(types.model({
    countryCode: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }))),
  anchorTextDistribution: types.maybeNull(types.array(AnchorText)),
  categoryData: types.maybeNull(CategoryData),
  url: types.maybeNull(types.string),
  reportsForCountry: types.maybeNull(types.string),
  toxicityPct: types.maybeNull(types.number),
  keywordPosition: types.maybeNull(types.array(KeywordPosition)),
  positionHistogramData: types.maybeNull(types.array(positionHistogramDataModel)),
  activeForCurrentCustomer: types.maybeNull(types.boolean),
}).views(self => ({
  get formattedValue() {
    return {
      hostname: getDomain(self.hostname),
      ot: self.ot ? numeral(self.ot).format('0,0') : '-',
      ok: self.keywordCount ? numeral(self.keywordCount).format('0,0') : '-',
      rd: self.refdomainCount ? numeral(self.refdomainCount).format('0,0') : '-',
      color: self.color,
    };
  },
  get domainName() {
    return getDomain(self.hostname);
  },
  get pieSeriesData() {
    const data = self.categoryData?.categories.map(category => {
      return {value: category.percentage, name: category.name, score: category.score};
    });

    return {
      name: '',
      type: 'pie',
      radius: ['50%', '90%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'left',
      },
      itemStyle: {
        borderRadius: 1,
        borderColor: '#2D2F34',
        borderWidth: 2,
      },
      labelLine: {
        show: false,
      },
      data,
    };
  },
}));

const ProjectModel = types.model({
  pk: types.maybeNull(types.identifierNumber),
  isGscProperty: types.optional(types.boolean, false),
  primaryWebsite: types.maybeNull(WebsiteDetailsModel),
}).actions(self => {
  const setIsGscProperty = (isGscProperty: boolean) => {
    self.isGscProperty = isGscProperty;
  };

  const setStatsData = (statsData, url) => {
    self.primaryWebsite.keywordData = cast(statsData.keywordCountHistoricalChart?.map(data => {
      return {date: data.date, kwCount: data.kwCount};
    }));
    self.primaryWebsite.impressionData = cast(statsData.dailySerpHistogramsAndClicks?.map(data => {
      return {date: data.date, impressions: data.impressions};
    }));
    self.primaryWebsite.clicksData = cast(statsData.dailySerpHistogramsAndClicks?.map(data => {
      return {date: data.date, clicks: data.clicks};
    }));
    self.primaryWebsite.trafficData = cast(statsData.dailySerpHistogramsAndClicks?.map(data => {
      return {date: data.date, trafficValue: data.trafficValue};
    }));
    self.primaryWebsite.refdomainCount = statsData.totalImpressions;
    self.primaryWebsite.ot = statsData.totalTraffic;
    self.primaryWebsite.keywordCount = statsData.totalKeywords?.current;
    self.primaryWebsite.trafficvalue = statsData.trafficValue;
    self.primaryWebsite.topPages = statsData.topPages?.map(value => value.url);
    self.primaryWebsite.url = url;
    const dailySerpHistogramsAndClicks = statsData.dailySerpHistogramsAndClicks[statsData.dailySerpHistogramsAndClicks?.length-1];
    self.primaryWebsite.keywordPosition = cast([
      {
        name: '# 1-3',
        value: numeral(dailySerpHistogramsAndClicks?.serp1To3).format('0a'),
      },
      {
        name: '# 4-10',
        value: numeral(dailySerpHistogramsAndClicks?.serp4To10).format('0a'),
      },
      {
        name: '# 11-20',
        value: numeral(dailySerpHistogramsAndClicks?.serp11To20).format('0a'),
      },
      {
        name: '# 20+',
        value: numeral(dailySerpHistogramsAndClicks?.serp21).format('0a'),
      },
    ]);
  };

  return {
    setStatsData,
    setIsGscProperty,
  };
});

const paramsModal = types.model({
  date: types.maybeNull(types.string),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
});

const propertiesModel = types.model({
  countryBreakdownLastFetchedAt: types.maybeNull(types.string),
  countryBreakdownStatus: types.maybeNull(types.string),
  deviceBreakdownLastFetchedAt: types.maybeNull(types.string),
  deviceBreakdownStatus: types.maybeNull(types.string),
  evotLastFetchedAt: types.maybeNull(types.string),
  evotTaskStatus: types.maybeNull(types.string),
  favicon: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isProcessingComplete: types.maybeNull(types.boolean),
  monthlyCost: types.maybeNull(types.string),
  saDataLoadTimeLeft: types.maybeNull(types.number),
  saDataPoints: types.maybeNull(types.number),
  saHistoricalLastFetchedAt: types.maybeNull(types.string),
  saHistoricalStatus: types.maybeNull(types.string),
  saMostrecentDataDt: types.maybeNull(types.string),
  saOldestDataDt: types.maybeNull(types.string),
  saPctProgress: types.maybeNull(types.number),
  saTotalKwCount: types.maybeNull(types.number),
  searchAppearanceLastFetchedAt: types.maybeNull(types.string),
  searchAppearanceStatus: types.maybeNull(types.string),
  sitemapsLastFetchedAt: types.maybeNull(types.string),
  sitemapsStatus: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
const gscArchivedSitesModel = types.model({
  pk: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
});
/* Sites */
export const NavBarStore = types.model({
  search: types.string,
  propertiesData: types.optional(types.array(Property), []),
  count: types.maybeNull(types.number),
  loading: types.boolean,
  activatePropertiesDrawerOpen: types.boolean,
  isGoogleUser: types.boolean,
  loadingSearchPerformance: types.boolean,
  detailApiCall: types.boolean,
  searchPerformanceStatus: types.maybeNull(searchPerformanceStatusModel),
  siteTaskStatuses: types.maybeNull(types.array(types.maybeNull(types.string))),
  isDockedSider: types.boolean,
  showMaintenanceInfo: types.maybeNull(types.boolean),
  activeSitesCount: types.maybeNull(types.number),
  inActiveSitesCount: types.maybeNull(types.number),


  /* Sites */
  loadingGscHome: types.boolean,
  gscProjectLoading: types.optional(types.boolean, false),
  gscProjectsActive: types.array(ProjectModel),
  gscProjectsInActive: types.maybeNull(types.array(ProjectModel)),
  totalGSCProject: types.number,
  activeGSCProjectCount: types.number,
  inActiveGSCProjectCount: types.number,
  gscActiveProjectsLocations: types.array(types.string),
  projectLoading: types.boolean,

  pageNumberActive: types.number,
  pageNumberInactive: types.number,
  sitesListParams: types.maybeNull(paramsModal),

  gscArchiveLoading: types.optional(types.boolean, false),

  properties: types.maybeNull(types.array(propertiesModel)),

  activeSiteproperties: types.array(types.number),
  inactiveSiteproperties: types.array(types.number),
  selectedSites: types.array(types.number),
  unSelectedSites: types.array(types.number),

  users: types.maybeNull(types.array(types.model({
    id: types.maybeNull(types.number),
    email: types.maybeNull(types.string),
  }))),
  emailSettings: types.maybeNull(types.array(types.model({
    siteproperty: types.maybeNull(types.string),
    watchlistEmail: types.maybeNull(types.array(types.string)),
    keywordPerformanceEmail: types.maybeNull(types.array(types.string)),
    positionTrackingEmail: types.maybeNull(types.array(types.string)),
    newKeywordsEmail: types.maybeNull(types.array(types.string)),
  }))),
  gscArchivedSites: types.array(gscArchivedSitesModel),
  isDeactivationFinished: types.boolean,
  payloadDeactivationSiteproperties: types.array(types.model({
    id: types.maybeNull(types.number),
    countryCode: types.maybeNull(types.array(types.string)),
    site_url: types.maybeNull(types.string),
  })),
  isActivationFinished: types.boolean,
  emailNotificationsLoading: types.boolean,
  customerEmailSettingLoading: types.boolean,
  loadingSiteProperties: types.maybeNull(types.boolean),
}).views(self => ({
  get hasSiteProperties() {
    return self.propertiesData.length > 0;
  },
  get getSiteProperties() {
    return toJS(self.propertiesData) || [];
  },
  get getSearchPerformanceStatus() {
    return self.searchPerformanceStatus;
  },

  /* Sites */
  get getActiveList() {
    return toJS(self.gscProjectsActive);
  },
  get getInActiveList() {
    return toJS(self.gscProjectsInActive);
  },
  get getTotalGSCProject() {
    return self.totalGSCProject;
  },
  get getProjectLoading() {
    return self.projectLoading;
  },
  get getlocationsFilterList() {
    return self.gscActiveProjectsLocations;
  },
  get getSelectedSites() {
    return self.selectedSites;
  },
  get getUnSelectedSites() {
    return self.unSelectedSites;
  },
  // should remove this one after v2 endpoint is fully utilized
  get getDeactivationPayload() {
    return self.payloadDeactivationSiteproperties;
  },
})).actions(self => {
  const setSearch = (value: string) => self.search = value;
  const setProperties = properties => {
    self.propertiesData = properties?.sites;
    self.count = properties?.count;
    self.activeSitesCount = properties?.activeSitesCount;
  };
  const submitSearch = (value: string, router: NextRouter) => {
    setSearch(value);
    replaceRouterQueryParam(router, {q: value}).then();
  };
  const setIsGoogleUser = () => {
    return self.isGoogleUser = true;
  };
  const getDomainRatingForActiveGSCProject = activeProject => {
    return self.propertiesData.find(element => getProjectName(element.url) == getProjectName(activeProject))?.domainRating || 0;
  };
  const setIsActivatePropertiesDrawerOpen = () => {
    return self.activatePropertiesDrawerOpen = true;
  };
  const setIsActivatePropertiesDrawerClose = () => {
    return self.activatePropertiesDrawerOpen = false;
  };

  const getSiteProperty = (url: string | string[]) => {
    /*
    Retrieve site property given the URL, returns first
    site property by default if url does not match to any
    site properties in store.
    */
    const selectedSiteProperties = self.propertiesData?.filter(sp => sp.url === url);
    return selectedSiteProperties.length > 0 ? selectedSiteProperties[0] : (self.propertiesData?.length ? self.propertiesData[0] : null);
  };
  const getTotalDataPoints = () => {
    // const totalDataPoints = self.propertiesData.reduce((prev: number, property: PropertyType) => {
    //   return prev + property.saDataPoints;
    // }, 0);
    // return totalDataPoints;
  };
  const getTotalSitesCount = () => {
    return self.propertiesData.length;
  };

  const getCompletedSitesCount = () => {
    const isLoading = (property: PropertyType) => property.isProcessingComplete;
    return self.propertiesData.filter(isLoading).length;
  };

  const doLoadSiteProperties = flow(function* (adminCode='', includeReports=false, pageSize=10, pageNumber=1) {
    const countryCodeParam = getSingleUrlParam('country_code');
    self.loading = true;
    let data = null;
    try {
      data = yield sitesApi.getSites(adminCode, includeReports, pageSize, pageNumber, countryCodeParam);
      if (data?.isCancel) return;
      self.loading = false;
      return data;
    } catch {
      self.loading = false;
      return data;
      // most likely this is triggered when gsc properties are not fully imported, we need to work on this in the future
      // notification.warning('We’re bringing you a better experience', 'You may experience slower loading times until the dashboard update is completed. Thank you for your patience.');
    }
  });

  const loadSitePropertiesNoLoaders = flow(function* (adminCode='', includeReports=false, pageSize=GSC_PROPERTIES_PAGE_SIZE, pageNumber=1, payload?: any) {
    const countryCodeParam = getSingleUrlParam('country_code');
    let data = null;
    try {
      data = yield sitesApi.getSites(adminCode, includeReports, pageSize, pageNumber, countryCodeParam, null, payload);
      if (data?.isCancel) return;
      setProperties(data);
      self.activeSitesCount = data?.activeSitesCount;
      self.inActiveSitesCount = data?.inActiveSitesCount;
      return data;
    } catch {
      // most likely this is triggered when gsc properties are not fully imported, we need to work on this in the future
      // notification.warning('We’re bringing you a better experience', 'You may experience slower loading times until the dashboard update is completed. Thank you for your patience.');
      return data;
    }
  });

  const loadActiveGscProperties = flow(function* (includeReports=false, pageSize=GSC_PROPERTIES_PAGE_SIZE, pageNumber=1, payload=self.sitesListParams) {
    self.loading = true;
    self.loadingGscHome = true;
    self.projectLoading = true;
    let prevListActive = [];
    if (pageNumber == 1) {
      self.gscProjectLoading = true;
    } else {
      prevListActive = toJS(self.gscProjectsActive)?.length ? toJS(self.gscProjectsActive) : [];
    }
    try {
      const GSCProperties = yield sitesApi.getSites('', includeReports, pageSize, pageNumber, payload?.countryCode, true, payload);
      if (GSCProperties?.isCancel || !GSCProperties) return GSCProperties;
      self.totalGSCProject = GSCProperties.count;
      self.activeGSCProjectCount = GSCProperties.activeSitesCount;
      // self.inActiveGSCProjectCount = GSCProperties.inactiveSitesCount;
      if (GSCProperties) {
        const formatedData = GSCProperties?.sites?.map(property => {
          return {
            pk: property.id,
            isGscProperty: true,
            primaryWebsite: getPrimaryWebsite(property),
          };
        });
        const totalSitesList = prevListActive?.length ? prevListActive?.concat(formatedData) : formatedData;
        const removeDuplicates = totalSitesList?.filter((v, i, a)=>a.findIndex(t=>(t.pk===v.pk))===i);
        self.gscProjectsActive = cast(removeDuplicates);
        // this check is important so we only repopulate this list on page reload
        if (!self.gscActiveProjectsLocations?.length) {
          const output = [];

          removeDuplicates?.forEach(item => {
            item.primaryWebsite?.activeCountryCodes?.forEach(code => {
              if (!output.includes(code)) output.push(code);
            });
          });

          self.gscActiveProjectsLocations = cast(output.sort((a, b) => a - b));
        }
      }
      self.loading = false;
      self.loadingGscHome = false;
      self.gscProjectLoading = false;
      self.projectLoading = false;
    } catch (err) {
      if (err?.resoponse?.status == 500) {
        const parent = getParent(self) as any;
        parent.gsc.setBanner(true, 'We’re currently facing some technical issues.', 'Our developers are working hard to fix the issues. Thank you for your patience.', BannerVariant.ERROR);
      } else {
        const errorMessage = apiError(err, 'Failed to fetch GSC sites') as string;
        notification.error('', errorMessage);
      }
    }
  });

  const loadInActiveGscProperties = flow(function* (includeReports=false, pageSize=GSC_PROPERTIES_PAGE_SIZE, pageNumber=1, payload=self.sitesListParams, stopPageOneLoader=false) {
    self.loading = true;
    self.loadingGscHome = true;
    let prevListInActive = [];
    if (pageNumber == 1 && !stopPageOneLoader) {
      self.projectLoading = true;
    } else {
      prevListInActive = toJS(self.gscProjectsInActive)?.length ? toJS(self.gscProjectsInActive) : [];
    }
    try {
      const GSCProperties = yield sitesApi.getSites('', includeReports, pageSize, pageNumber, null, false, payload);
      if (GSCProperties?.isCancel) return;
      self.totalGSCProject = GSCProperties.count;
      // self.activeGSCProjectCount = GSCProperties.activeSitesCount;
      self.inActiveGSCProjectCount = GSCProperties.inactiveSitesCount;
      if (GSCProperties) {
        const formatedData = GSCProperties?.sites?.map(property => {
          return {
            pk: property.id,
            isGscProperty: true,
            primaryWebsite: property ? getPrimaryWebsite(property) : null,
          };
        });
        const totalSitesList = prevListInActive?.length ? prevListInActive?.concat(formatedData) : formatedData;
        const removeDuplicates = totalSitesList?.filter((v, i, a)=>a.findIndex(t=>(t.pk===v.pk))===i);
        self.gscProjectsInActive = cast(removeDuplicates);
      }
      self.loading = false;
      self.loadingGscHome = false;
      self.projectLoading = false;
    } catch (err) {
      if (err?.resoponse?.status == 500) {
        const parent = getParent(self) as any;
        parent.gsc.setBanner(true, 'We’re currently facing some technical issues.', 'Our developers are working hard to fix the issues. Thank you for your patience.', BannerVariant.ERROR);
      } else {
        const errorMessage = apiError(err, 'Failed to fetch GSC sites') as string;
        notification.error('', errorMessage);
      }
    }
  });

  const setPageNumberActive = val => self.pageNumberActive = val;
  const setPageNumberInactive = val => self.pageNumberInactive = val;
  const resetSortOrder = val => self.sitesListParams.ordering = val;

  const setDeactivationPayload = (id: number, countryCode: string[], siteUrl: string) => {
    self.payloadDeactivationSiteproperties = cast([...self.payloadDeactivationSiteproperties, {id: id, countryCode: countryCode, site_url: siteUrl}]);
  };

  const setDefaultDeactivatePayload = () => {
    const newArr = [...self.payloadDeactivationSiteproperties];
    newArr.splice(0, newArr.length);
    self.payloadDeactivationSiteproperties = cast(newArr);
  };

  const setGscSitesListParams = flow(function* (data, activeKeys) {
    self.sitesListParams = data;
    if (activeKeys?.includes('1')) {
      setPageNumberActive(1);
      const res = yield loadActiveGscProperties(true, GSC_PROPERTIES_PAGE_SIZE, 1, data);
      if (res?.isCancel) return;
    }
    if (activeKeys?.includes('2')) {
      setPageNumberInactive(1);
      yield loadInActiveGscProperties(true, GSC_PROPERTIES_PAGE_SIZE, 1, data);
    }
  });

  const removeGSCProject = projectId => {
    const indexActive = self.gscProjectsActive?.findIndex(item => item?.pk === projectId);
    self.gscProjectsActive?.splice(indexActive, 1);
    const indexInActive = self.gscProjectsInActive?.findIndex(item => item?.pk === projectId);
    self.gscProjectsInActive?.splice(indexInActive, 1);
  };

  const setUpdatedSiteProperties = sites => {
    if (sites) {
      const sitepropertySettings = sites.sitepropertySettings;
      self.activeSiteproperties = cast(sitepropertySettings.activeSiteproperties);
      self.inactiveSiteproperties = cast(sitepropertySettings.inactiveSiteproperties);
    }
  };

  const setSiteProperties = sites => {
    const archiveData = [];
    if (sites && sites.length) {
      const sitepropertySettings = sites[0].sitepropertySettings;
      self.activeSiteproperties = cast(sitepropertySettings?.activeSiteproperties);
      self.inactiveSiteproperties = cast(sitepropertySettings.inactiveSiteproperties);
      self.selectedSites = cast(sitepropertySettings.activeSiteproperties);
      self.unSelectedSites = cast(sitepropertySettings.inactiveSiteproperties);
      const archivedSiteproperties = cast(sites[0].archivedSiteproperties);

      archivedSiteproperties.map(item=>{
        const properties = self.properties.find(element=>element.id == item.pk);
        if (properties) {
          archiveData.push({
            pk: item.pk,
            date: item.date,
            url: properties.url,
            icon: properties.favicon,
          });
        }
      });
      self.gscArchivedSites = cast(archiveData);
    }
  };

  // Function for GSC site active and deactive
  const onDeactivateSite = flow(function* (payload) {
    self.loading = true;
    self.isDeactivationFinished = false;
    let data = null;
    const newArr = [...self.getDeactivationPayload];
    const newArrIds = newArr.map(item => item.id);

    try {
      data = yield sitesApi.deactivateSite(payload);
      if (data) {
        const dataIds = data?.siteproperties?.filter(item => newArrIds.includes(item.id));
        notification.success(dataIds.length > 1 ? `${dataIds.length} Sites Successfully Deactivated` : `${dataIds[0]?.url} Successfully Deactivated`, 'You will be able to view GSC Insights and run Site Audits for the selected site.');
        self.isDeactivationFinished = true;
      }
    } catch (err) {
      if (err?.resoponse?.status == 500) {
        const parent = getParent(self) as any;
        parent.gsc.setBanner(true, 'We’re currently facing some technical issues.', 'Our developers are working hard to fix the issues. Thank you for your patience.', BannerVariant.ERROR);
      }
      const {status} = err.response;
      if (status === 429) {
        const {data} = err.response;
        notification.error('Error', data.message);
      }
      self.isDeactivationFinished = false;
    } finally {
      self.loading = false;
    }
    return data;
  });

  function areEqual(array1, array2) {
    if (array1.length === array2.length) {
      return array1.every(element => {
        if (array2.includes(element)) {
          return true;
        }

        return false;
      });
    }

    return false;
  }

  function getMatch(a, b) {
    const matches = [];
    const notMatch = [];

    for ( let i = 0; i < a.length; i++ ) {
      for ( let e = 0; e < b.length; e++ ) {
        if (a[i]?.id == b[e]?.id ) {
          const testArr = areEqual(a[i]?.activeCountryCodes, b[e]?.country_codes);
          if (testArr) {
            matches?.push(b[e]?.site_url);
          } else {
            notMatch?.push(b[e]?.site_url);
          }
        }
      }
    }
    return notMatch;
  }

  const onActivateSite = flow(function* (payload) {
    self.loading = true;
    const parent = getParent(self) as any;
    self.isActivationFinished = false;
    let data = null;
    const getProjectsList = toJS(parent.navBar.propertiesData);
    const previousList = getProjectsList?.filter(item => item?.activeForCurrentCustomer);
    // const payloadIds = payload?.map(item => item?.id);
    // const deactivatedSites = previousList?.filter(item => !payloadIds.includes(item?.id));
    const activatedSites = payload.filter(o1 => !previousList.some(o2 => o1.id === o2?.id));
    const getCountryChanges = getMatch(getProjectsList?.filter(x => !activatedSites?.some(z => z?.site_url == x?.url)), payload);
    try {
      data = yield sitesApi.activateSiteV2(payload);
      if (data) {
        if (getCountryChanges?.length) {
          notification.success(getCountryChanges.length > 1 ? `${getCountryChanges.length} Sites Successfully Updated` : `${getCountryChanges[0]} Successfully Updated`, 'You will be able to view GSC Insights and run Site Audits for the selected site.');
        }
        if (activatedSites?.length) {
          notification.success(activatedSites.length > 1 ? `${activatedSites.length} Sites Successfully Activated` : `${activatedSites[0]?.site_url} Successfully Activated`, 'You will be able to view GSC Insights and run Site Audits for the selected site.');
        }
        // if (deactivatedSites?.length) {
        //   newNotification.success(deactivatedSites.length > 1 ? `${deactivatedSites.length} Sites Deactivated` : `${deactivatedSites[0]?.url} was Deactivated`, 'You will not see any GSC related data for this site.');
        // }
        self.isActivationFinished = true;
      }
    } catch (err) {
      if (err?.resoponse?.status == 500) {
        const parent = getParent(self) as any;
        parent.gsc.setBanner(true, 'We’re currently facing some technical issues.', 'Our developers are working hard to fix the issues. Thank you for your patience.', BannerVariant.ERROR);
      }
      const {status} = err.response;
      if (status === 429) {
        const {data} = err.response;
        notification.error('Error', data.message);
      }
      self.isActivationFinished = false;
    } finally {
      self.loading = false;
    }
    return data;
  });

  const onActivateSiteV2 = flow(function* (payload) {
    self.loading = true;
    self.isActivationFinished = false;
    let data = null;
    const parent = getParent(self) as any;
    try {
      data = yield sitesApi.activateSiteV3(payload);

      if (data) {
        self.isActivationFinished = true;
      }
    } catch (err) {
      if (!window.location?.pathname?.includes('gsc/')) {
        parent.navBar.setIsActivatePropertiesDrawerClose();
      }
      if (err?.resoponse?.status == 500) {
        parent.gsc.setBanner(true, 'We’re currently facing some technical issues.', 'Our developers are working hard to fix the issues. Thank you for your patience.', BannerVariant.ERROR);
      }
      const {status} = err.response;
      if (status === 429) {
        const {data} = err.response;
        notification.error('Error', data.message);
      } else {
        parent.navBar.setIsActivatePropertiesDrawerClose();
      }
      self.isActivationFinished = false;
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
    return data;
  });

  const setDeactivateIds = (siteId: number) => {
    const newList = self.selectedSites.filter(item => item !== siteId);
    self.selectedSites = cast(newList),
    self.unSelectedSites = cast([...self.unSelectedSites, siteId]);
  };

  const setActivateIds = (siteId: number) => {
    const newList = self.unSelectedSites.filter(item => item !== siteId);
    self.unSelectedSites = cast(newList),
    self.selectedSites = cast([...self.selectedSites, siteId]);
  };

  const deactivateSite = flow(function* (payload) {
    setUpdatedSiteProperties(yield onDeactivateSite(payload));
  });

  const activateSite = flow(function* (payload) {
    // if (siteId && siteId?.length !== 0)
    setUpdatedSiteProperties(yield onActivateSite(payload));
  });
  const activateSiteV2 = flow(function* (payload) {
    // if (siteId && siteId?.length !== 0)
    setUpdatedSiteProperties(yield onActivateSiteV2(payload));
  });


  const onUnArchiveGscSite = flow(function* (siteId) {
    self.gscArchiveLoading = true;
    let data = null;
    try {
      data = yield sitesApi.unArchiveGscSites(siteId);
      if (data.siteproperties) {
        self.properties = data?.siteproperties;
      }
      setSiteProperties([data]);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.gscArchiveLoading = false;
    }
    return data;
  });

  // Function for GSC site archive and unarchive
  const onArchiveGscSite = flow(function* (siteId) {
    self.gscArchiveLoading = true;
    let data = null;
    try {
      data = yield sitesApi.archiveGscSites(siteId);
      if (data.siteproperties) {
        self.properties = data?.siteproperties;
      }
      setSiteProperties([data]);
      removeGSCProject(siteId);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.gscArchiveLoading = false;
    }
    return data;
  });

  // Function for user Subscription and unsubscription
  const unsubscribeUser = flow(function* (email: Array<string>, sitePropertyIds: Array<string>, emailType: Array<string>) {
    self.emailNotificationsLoading = true;
    try {
      const response = yield EMAIL_NOTIFICATIONS_API.unsubscribeUser(email, sitePropertyIds, emailType);
      if (response) {
        self.emailSettings = cast(response.emailSettings);
        self.users = cast(response.users);
      }
      self.emailNotificationsLoading = false;
    } catch (e) {
      self.loading = true;
      self.emailNotificationsLoading = true;
    }
  });

  const subscribeUser = flow(function* (email: Array<string>, sitePropertyIds: Array<string>, emailType: Array<string>) {
    self.emailNotificationsLoading = true;
    try {
      const response = yield EMAIL_NOTIFICATIONS_API.subscribeUser(email, sitePropertyIds, emailType);
      if (response) {
        self.emailSettings = cast(response.emailSettings);
        self.users = cast(response.users);
        // self.properties = cast(response.siteproperties);
      }
      self.emailNotificationsLoading = false;
    } catch (e) {
      self.loading = true;
      self.emailNotificationsLoading = true;
    }
  });

  const refreshEmailSettings = () =>{
    return self.emailSettings;
  };

  const loadSiteProperties = flow(function* (adminCode='', includeReports=false, pageSize=GSC_PROPERTIES_PAGE_SIZE, pageNumber=1) {
    self.loadingSiteProperties = true;
    try {
      const data =yield doLoadSiteProperties(adminCode, includeReports, pageSize, pageNumber);
      if (data[0]?.emailSettings) {
        self.emailSettings = data[0]?.emailSettings;
      }
      if (data[0]?.users) {
        self.users = data[0]?.users;
      }

      if (data[0]?.siteproperties) {
        self.properties = data[0]?.siteproperties;
      }
      setProperties(data);
      self.activeSitesCount = data?.activeSitesCount;
      self.inActiveSitesCount = data?.inActiveSitesCount;
      setSiteProperties(data);
    } catch (err) {
      if (err?.resoponse?.status == 500) {
        const parent = getParent(self) as any;
        parent.gsc.setBanner(true, 'We’re currently facing some technical issues.', 'Our developers are working hard to fix the issues. Thank you for your patience.', BannerVariant.ERROR);
      }
    } finally {
      self.loadingSiteProperties = false;
    }
  });

  const refetchSiteProperties = flow(function* () {
    self.loading = true;
    try {
      const response = yield sitesApi.refetchSites();
      if (response) {
        if (response?.success === true) {
          notification.success('Success!', 'Sites fetched successfully!');
        } else {
          notification.error('Fetching failed', 'There was an error while refetching sites', true);
        }
      }
    } catch (e) {
      notification.error('Fetching failed', 'There was an error while refetching sites', true);
    } finally {
      loadSiteProperties();
      self.loading = false;
    }
  });

  const loadSearchPerformanceStatus = flow(function* () {
    self.loadingSearchPerformance = true;
    try {
      const response = yield sitesApi.getSearchPerformanceStatus();
      let siteTaskStatuses;
      let searchPerformanceStatus;
      if (response) {
        siteTaskStatuses = cast(response?.sites?.map(item => item.searchperformanceTaskStatus));
        searchPerformanceStatus = response;
      }
      const checkStatuses = self.siteTaskStatuses?.every(item => ['SUCCESS', 'FAILURE'].includes(item));

      if (!checkStatuses && self.detailApiCall) {
        yield new Promise(r => setTimeout(r, 2000));
        return loadSearchPerformanceStatus();
      } else {
        self.siteTaskStatuses = cast(siteTaskStatuses);
        self.searchPerformanceStatus = searchPerformanceStatus;

        self.loadingSearchPerformance = false;
      }
    } catch (e) {
      self.loadingSearchPerformance = false;
      Promise.reject(e);
    }
  });

  const getSearchConsoleList = flow(function* () {
    try {
      const response = yield sitesApi.getSearchConsoleList();
      if (response?.isCancel) return;
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  });

  const getCustomerEmailSetting = flow(function* () {
    try {
      self.customerEmailSettingLoading = true;
      const response = yield sitesApi.getCustomerEmailSetting();
      if (response?.isCancel) return;
      self.customerEmailSettingLoading = false;
      return response;
    } catch (err) {
      self.customerEmailSettingLoading = false;
      return Promise.reject(err);
    }
  });

  const updateBulkCustomerEmailSetting = flow(function* (id, action: string) {
    try {
      const response = yield sitesApi.updateBulkCustomerEmailSetting(id, action);
      if (response) {
        getCustomerEmailSetting();
      }
      notification.success('Bulk Email Settings Updated', 'Bulk Email settings Updated successfully');
      if (response?.isCancel) return;
    } catch (err) {
      const errorMessage = apiError(err) as string;
      notification.error('', errorMessage);
      return Promise.reject(err);
    }
  });

  const setDetailApiCall = (value: boolean) => {
    self.detailApiCall = value;
  };

  const setIsDockedSider = (value: boolean) => {
    self.isDockedSider = value;
  };

  const setShowMaintenanceInfo = (value: boolean) => {
    self.showMaintenanceInfo = value;
  };

  const setLoading = (value: boolean) => {
    self.loading = value;
  };


  return {
    setSearch,
    submitSearch,
    setProperties,
    getTotalDataPoints,
    getTotalSitesCount,
    getCompletedSitesCount,
    getDomainRatingForActiveGSCProject,
    loadSiteProperties,
    getSiteProperty,
    refetchSiteProperties,
    setIsActivatePropertiesDrawerOpen,
    setIsActivatePropertiesDrawerClose,
    setIsGoogleUser,
    setDetailApiCall,
    loadSearchPerformanceStatus,
    setIsDockedSider,
    setShowMaintenanceInfo,
    loadSitePropertiesNoLoaders,
    loadActiveGscProperties,
    loadInActiveGscProperties,
    setGscSitesListParams,
    removeGSCProject,
    onArchiveGscSite,
    setActivateIds,
    setDeactivationPayload,
    setDefaultDeactivatePayload,
    setDeactivateIds,
    deactivateSite,
    activateSite,
    activateSiteV2,
    onUnArchiveGscSite,
    resetSortOrder,
    setPageNumberInactive,
    setPageNumberActive,
    refreshEmailSettings,
    subscribeUser,
    unsubscribeUser,
    setLoading,
    getCustomerEmailSetting,
    updateBulkCustomerEmailSetting,
    getSearchConsoleList,
  };
});

export function initNavBarStore(search: string) {
  return NavBarStore.create({
    search: search,
    loadingSearchPerformance: false,
    detailApiCall: false,
    propertiesData: [],
    loading: true,
    activatePropertiesDrawerOpen: false,
    isGoogleUser: false,
    isDockedSider: true,
    showMaintenanceInfo: true,

    /* Sites */
    loadingGscHome: true,
    gscProjectLoading: true,
    totalGSCProject: 0,
    activeGSCProjectCount: 0,
    inActiveGSCProjectCount: 0,
    projectLoading: false,

    pageNumberActive: 1,
    pageNumberInactive: 1,

    gscArchiveLoading: false,
    activeSiteproperties: [],
    inactiveSiteproperties: [],
    selectedSites: [],
    unSelectedSites: [],
    users: [],
    properties: [],
    emailSettings: null,
    gscArchivedSites: [],
    isDeactivationFinished: false,
    isActivationFinished: false,
    emailNotificationsLoading: false,
    customerEmailSettingLoading: false,
    loadingSiteProperties: false,
  });
}
