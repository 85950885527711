import {cast, flow, types} from 'mobx-state-tree';
import {CAMPAIGN_API} from '@/api/campaigns';
import {EditorState} from 'draft-js';
import {toJS} from 'mobx';
import {DEFAULT_PARAMS, DETAIL_FILTER} from './data';
import {CAMPAIGN_OPPORTUNITY_API} from '@/api/campaigns/opportunities';
import {useErrorNotification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {getFilter} from '@/utils/filters';
import {notification} from '@/utils/notification-v2';

const CampaignsContactOpportunity = types.model({
  id: types.number,
  wasContacted: types.maybeNull(types.boolean),
  emailAddress: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  photoUrl: types.maybeNull(types.string),
  linkedinUrl: types.maybeNull(types.string),
  twitterUrl: types.maybeNull(types.string),
  githubUrl: types.maybeNull(types.string),
  facebookUrl: types.maybeNull(types.string),
  websiteUrl: types.maybeNull(types.string),
  opportunity: types.maybeNull(types.number),
});

const CampaignsOpportunity = types.model({
  id: types.number,
  ahrefsDr: types.maybeNull(types.number),
  ahrefsOk: types.maybeNull(types.number),
  ahrefsOt: types.maybeNull(types.number),
  ahrefsRefdomains: types.maybeNull(types.number),
  contacted: types.maybeNull(types.number),
  contacts: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  isScheduled: types.maybeNull(types.boolean),
  metaDesc: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const filterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
});

const CampaignsResultsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  project: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  targetKeywords: types.maybeNull(types.array(types.string)),
  opportunities: types.maybeNull(types.number),
  sentEmails: types.maybeNull(types.number),
  repliedEmails: types.maybeNull(types.number),
  emailResponseRate: types.maybeNull(types.number),
  domains: types.maybeNull(types.array(types.string)),
});

const Campaigns = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(CampaignsResultsModel)),
});

const CampaignsCustomerEmailsResultsModel = types.model({
  id: types.number,
  emailAddress: types.maybeNull(types.string),
  emailType: types.maybeNull(types.string),
  isLinked: types.maybeNull(types.boolean),
  dailyLimitByEmail: types.maybeNull(types.number),
});

const CampaignsCustomerEmails = types.model({
  count: types.number,
  next: types.maybeNull(types.string),
  pageSize: types.number,
  totalPages: types.number,
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(CampaignsCustomerEmailsResultsModel)),
});

const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const FoldersResultsModel = types.model({
  id: types.number,
  name: types.maybeNull(types.string),
  customer: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
});

const FoldersDataModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  results: types.maybeNull(types.array(FoldersResultsModel)),
});

const ExistingTemplatesResultsModel = types.model({
  id: types.number,
  name: types.maybeNull(types.string),
  subject: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
});

const updateModalValuesModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  subject: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  isUpdate: types.maybeNull(types.boolean),
});

const ExistingTemplatesModel = types.model({
  count: types.number,
  next: types.maybeNull(types.string),
  pageSize: types.number,
  totalPages: types.number,
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(ExistingTemplatesResultsModel)),
});

const EmailTrendModel = types.model({
  date: types.maybeNull(types.string),
  emailSent: types.maybeNull(types.number),
  emailReplied: types.maybeNull(types.number),
});

const ChartDataModel = types.model({
  id: types.number,
  name: types.maybeNull(types.string),
  project: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  targetKeywords: types.maybeNull(types.array(types.string)),
  opportunities: types.maybeNull(types.number),
  sentEmails: types.maybeNull(types.number),
  repliedEmails: types.maybeNull(types.number),
  emailResponseRate: types.maybeNull(types.number),
  emailTrend: types.maybeNull(types.array(EmailTrendModel)),
  createdAt: types.maybeNull(types.string),
  tld: types.maybeNull(types.string),
  ahrefsDrMin: types.maybeNull(types.number),
  ahrefsDrMax: types.maybeNull(types.number),
  ahrefsOtMin: types.maybeNull(types.number),
  ahrefsOtMax: types.maybeNull(types.number),
  ahrefsOkMin: types.maybeNull(types.number),
  ahrefsOkMax: types.maybeNull(types.number),
  ahrefsRefdomainsMin: types.maybeNull(types.number),
  ahrefsRefdomainsMax: types.maybeNull(types.number),
  template: types.maybeNull(types.model({
    name: types.maybeNull(types.string),
    subject: types.maybeNull(types.string),
    body: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
  })),
  linkedEmailId: types.maybeNull(types.number),
  followupEmails: types.maybeNull(types.array(types.frozen())),
  countryCode: types.maybeNull(types.string),
  tlds: types.maybeNull(types.array(types.string)),
  domains: types.maybeNull(types.array(types.string)),
  maxPage: types.maybeNull(types.number),
});

const CampaignSettings = types.model({
  id: types.maybeNull(types.number),
  daysOfWeek: types.maybeNull(types.array(types.number)),
  sendFrom: types.maybeNull(types.string),
  sendTo: types.maybeNull(types.string),
  dailyLimit: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  campaign: types.maybeNull(types.number),
  outreachOpportunities: types.maybeNull(types.number),
});

const opportunityMetricsModel = types.model({
  ahrefsDr: types.maybeNull(types.number),
  ahrefsOk: types.maybeNull(types.number),
  ahrefsOt: types.maybeNull(types.number),
  ahrefsRefdomains: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  contacts: types.maybeNull(types.number),
});

export const CampaignStore = types.model({
  campaigns: types.maybeNull(Campaigns),
  campaignsList: types.maybeNull(types.array(CampaignsResultsModel)),
  campaignSettings: types.maybeNull(CampaignSettings),
  campaignsCustomerEmails: types.maybeNull(CampaignsCustomerEmails),
  campaignsCustomerEmailsLoader: types.boolean,
  campaignsLoader: types.boolean,
  pageNumber: types.number,
  pageSize: types.number,
  opPageNumber: types.number,
  opPageSize: types.number,
  keywordSuggestions: types.maybeNull(types.array(types.string)),
  keywordSuggestionsLoading: types.boolean,
  isEditorFocus: types.maybeNull(types.boolean),
  currentEditorState: types.frozen({}),
  focusTermsClassNames: types.frozen({}),
  highlights: types.optional(types.boolean, true),
  detailFilter: types.array(filterListModel),
  campaignFilter: types.array(filterListModel),
  allDetailParams: types.maybeNull(paramsModel),
  postNewCampaignLoading: types.boolean,
  foldersLoading: types.boolean,
  foldersData: types.maybeNull(FoldersDataModel),
  visible: types.boolean,
  existingTemplates: types.maybeNull(ExistingTemplatesModel),
  existingTemplatesLoading: types.boolean,
  opportunityCompaign: types.maybeNull(types.array(CampaignsOpportunity)),
  contactOpportunityCampaign: types.array(CampaignsContactOpportunity),
  opportunityCount: types.maybeNull(types.number),
  showErrors: types.boolean,
  addNewMailLoading: types.boolean,
  showConfigureModal: types.boolean,
  chartDataLoading: types.boolean,
  chartData: types.maybeNull(ChartDataModel),
  campaignsOpLoader: types.boolean,
  toogleStatusLoading: types.boolean,
  updateCampaignLoading: types.boolean,
  deleteCampaignLoading: types.boolean,
  sendEmailInBulkLoading: types.boolean,
  addToOutreachListLoading: types.boolean,
  campaignsContactLoading: types.boolean,
  contactsDeleteLoading: types.boolean,
  updateOpportunityStatusLoading: types.boolean,
  foldersModalVisible: types.boolean,
  createFolderLoading: types.boolean,
  updateFolderLoading: types.boolean,
  newFolderCreate: types.boolean,
  folderUpdate: types.boolean,
  deleteFolderLoading: types.boolean,
  saveContactLoading: types.boolean,
  showDragger: types.boolean,
  file: types.frozen(),
  websiteUrl: types.string,
  linkedinUrl: types.string,
  facebookUrl: types.string,
  twitterUrl: types.string,
  gitlabUrl: types.string,
  emailAddress: types.string,
  fullName: types.string,
  firstName: types.string,
  lastName: types.string,
  title: types.string,
  showError: types.boolean,
  createTempModalVisible: types.boolean,
  allOpportunityParams: types.maybeNull(paramsModel),
  allCampaignParams: types.maybeNull(paramsModel),
  updateSettingsLoading: types.boolean,
  newFolderValue: types.string,
  opportunityMetrics: types.maybeNull(opportunityMetricsModel),
  updateModalValues: types.maybeNull(updateModalValuesModel),
  campaignRepollingLoading: types.maybeNull(types.number),
  contactsCount: types.maybeNull(types.number),
  createModalVisible: types.boolean,
  loadingImportFromCsv: types.boolean,
  loadingAddGlobalBlockList: types.boolean,
  globalBlocklist: types.maybeNull(types.array(types.string)),
  campaignBlocklist: types.maybeNull(types.array(types.string)),
  loadingUpdateCampaignBlockList: types.boolean,
  deletingOpportunity: types.boolean,
  isEditModal: types.boolean,
  addingEmail: types.boolean,
  deletingConnectedEmailsIds: types.array(types.number),
}).views(self => ({
  get getCurrentEditorState() {
    return self.currentEditorState;
  },
  get getDetailFilter() {
    return toJS(self.detailFilter);
  },
  get getCampaignFilter() {
    return toJS(self.campaignFilter);
  },
  get getOpportunityCompaign() {
    return self.opportunityCompaign;
  },
  get getOpportunityCount() {
    return self.opportunityCount;
  },
  get getOpportunityContact() {
    return toJS(self.contactOpportunityCampaign);
  },
  get getDeletingConnectedEmailsIds() {
    return self.deletingConnectedEmailsIds;
  },

})).actions(self => {
  const setCampaignPagination = (page: number, pageSize: number, ottoProjectId?: number) => {
    self.allCampaignParams = {...self.allCampaignParams, page: page, page_size: pageSize};
    if (ottoProjectId) {
      getCampaigns(false, null, ottoProjectId);
    } else {
      getCampaigns();
    }
  };
  const setCampaignOrdering = (data: any) => {
    self.allCampaignParams = data;
    getCampaigns();
  };
  const updateCampaignFilter = (filters, ottoProjectId?: number) => {
    self.allCampaignParams = {...self.allCampaignParams, page: 1};
    self.campaignFilter = cast(filters);
    if (ottoProjectId) {
      getCampaigns(false, null, ottoProjectId);
    } else {
      getCampaigns();
    }
  };
  const setOpPagination = (page: number, pageSize: number, id: string, isScheduled?: string) => {
    self.allOpportunityParams = {...self.allOpportunityParams, page: page, page_size: pageSize};
    getCampaignsOpportunity(id, isScheduled);
  };
  const setOpOrdering = (data: any, id: string) => {
    self.allOpportunityParams = data;
    getCampaignsOpportunity(id);
  };
  const updateOpportunityFilter = (filters, id, isScheduled?: string) => {
    self.allOpportunityParams = {...self.allOpportunityParams, page: 1};
    if (filters) {
      self.detailFilter = cast(filters);
    }
    getCampaignsOpportunity(id, isScheduled);
  };
  const setAllOrganicKeywordsParams = (data, id) => {
    self.allDetailParams = data;
    if (id) {
      // loadAllOrganicKeywords(id, toJS(self.organicKeywordsFilter)?.filter(i => i?.active));
    }
  };

  const setUpdateModalValues = value => {
    self.updateModalValues = value;
  };

  const setAddContactStatesToDefault = () => {
    self.file = null;
    self.websiteUrl = '';
    self.linkedinUrl = '';
    self.facebookUrl = '';
    self.twitterUrl = '';
    self.gitlabUrl = '';
    self.emailAddress = '';
    self.fullName = '';
    self.firstName = '';
    self.lastName = '';
    self.title = '';
    self.showError = false;
    self.showDragger = false;
  };
  const getCampaigns = flow(function* (noLoading = false, id?: number, ottoProjectId?: number) {
    if (!noLoading) self.campaignsLoader = true;
    try {
      const addFilter = (filters, filterType, paramName) => {
        const filter = getFilter(filters, filterType);
        return filter && filter.from && {[paramName + '_from']: filter.from, [paramName + '_to']: filter.to};
      };
      const activeFilters = self.campaignFilter?.filter(z => z?.active);
      const params = {
        ...self.allCampaignParams,
        ...(getFilter(activeFilters, 'exclude') && {'search': getFilter(activeFilters, 'exclude')?.query}),
        ...(getFilter(activeFilters, 'radioButtonsFilter') && getFilter(activeFilters, 'radioButtonsFilter')?.header === 'Status' && {'is_active': getFilter(activeFilters, 'radioButtonsFilter')?.type == 'active' || false}),
        ...addFilter(activeFilters, 'opportunities', 'opportunities_total'),
        ...addFilter(activeFilters, 'emailed', 'sent_emails_total'),
        ...addFilter(activeFilters, 'replies', 'sent_emails_replied'),
        ...addFilter(activeFilters, 'resp-rate', 'response_rate'),
        ...(activeFilters.find((filter:any)=>filter?.header == 'Folder') && {'project_id': activeFilters.find((filter:any)=>filter?.header == 'Folder')?.type}),
        ...(ottoProjectId) && {otto_project_id: ottoProjectId},
      };
      const response = yield CAMPAIGN_API.getCampaigns(params);
      if (response?.isCancel) return;
      if (response) {
        self.campaigns = response;
      }
      if (!id) {
        return response.results;
      }
      const campaign = response.results.find(i => i.id === id);
      if (campaign) {
        if (campaign?.processingStatus === 'FAILURE' || campaign?.processingStatus === 'SUCCESS') {
          self.campaignRepollingLoading = null;
          return response.results;
        } else {
          self.campaignRepollingLoading = id;
          yield new Promise(r => setTimeout(r, 3000));
          return getCampaigns(true, id, ottoProjectId);
        }
      }
    } catch (e) {
      self.campaignsLoader = false;
      return Promise.reject(e);
    } finally {
      self.campaignsLoader = false;
    }
  });

  const getFullCampaignsList = flow(function* () {
    try {
      const response = yield CAMPAIGN_API.getFullCampaignsList();
      if (response?.isCancel) return;
      if (response) {
        self.campaignsList = response;
      }
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const getCampaignsCustomerEmails = flow(function* () {
    self.campaignsCustomerEmailsLoader = true;
    try {
      const params = {
        pageNumber: self.pageNumber ?? 1,
        pageSize: self.pageSize ?? 10,
      };
      const response = yield CAMPAIGN_API.getCampaignsCustomerEmails(params);
      if (response?.isCancel) return;
      if (response) {
        self.campaignsCustomerEmails = response;
      }
      return response.results;
    } catch (e) {
      self.campaignsCustomerEmailsLoader = false;
      return Promise.reject(e);
    } finally {
      self.campaignsCustomerEmailsLoader = false;
    }
  });

  const updateDailyLimit = flow(function* (updatedData) {
    try {
      const response = yield CAMPAIGN_API.updateDailyLimit(updatedData);
      if (response?.isCancel) return;
      const updatedEmail = toJS(self.campaignsCustomerEmails?.results)?.find(email => email?.id == updatedData?.id);
      if (response) {
        notification.success('', `Limit updated to ${response?.dailyLimitByEmail} emails per day for ${updatedEmail?.emailAddress}`);
      }
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const getKeywordSuggestions = flow(function* (keyword: string) {
    self.keywordSuggestionsLoading = true;
    try {
      const params = {
        keyword: keyword,
      };
      const response = yield CAMPAIGN_API.getKeywordSuggestions(params);
      if (response?.isCancel) return;
      if (response) {
        self.keywordSuggestions = response;
      }
      return response.results;
    } catch (e) {
      self.keywordSuggestionsLoading = false;
      return Promise.reject(e);
    } finally {
      self.keywordSuggestionsLoading = false;
    }
  });

  const getCampaignsOpportunity = flow(function* (id, isScheduled?: string) {
    self.campaignsOpLoader = true;
    try {
      const addFilter = (filters, filterType, paramName) => {
        const filter = getFilter(filters, filterType);
        return filter && filter.from && {[paramName + '_from']: filter.from, [paramName + '_to']: filter.to};
      };
      const activeFilters = self.detailFilter?.filter(z => z?.active);
      const params = {
        ...self.allOpportunityParams,
        ...(getFilter(activeFilters, 'exclude')?.name == 'exclude' && {'search': getFilter(activeFilters, 'exclude')?.query}),
        ...addFilter(activeFilters, 'contacts', 'contacts_total'),
        ...addFilter(activeFilters, 'rating', 'ahrefs_dr'),
        ...addFilter(activeFilters, 'traffic', 'ahrefs_ot'),
        ...addFilter(activeFilters, 'keywords', 'ahrefs_ok'),
        ...addFilter(activeFilters, 'ref-domains', 'ahrefs_refdomains'),
        ...(isScheduled !== 'all' && (isScheduled === '' || isScheduled === 'outreach' || isScheduled === 'discovered') && {is_scheduled: isScheduled === 'outreach' || false}),
      };
      const response = yield CAMPAIGN_OPPORTUNITY_API.getCampaignsOpportunity(params, id);
      if (response?.isCancel) return;
      if (response) {
        self.opportunityCompaign = cast(response.results || []);
        self.opportunityCount = response.count || 0;
      }
      return response.results;
    } catch (e) {
      self.campaignsOpLoader = false;
      return Promise.reject(e);
    } finally {
      self.campaignsOpLoader = false;
    }
  });

  const updateCampaignOpportunity = flow(function* (data) {
    self.updateSettingsLoading = true;
    try {
      yield CAMPAIGN_OPPORTUNITY_API.postOpportunityConfig(data);
      getCampaignSettings(data?.campaign);
    } catch (e) {
      self.updateSettingsLoading = false;
      return Promise.reject(e);
    } finally {
      self.updateSettingsLoading = false;
    }
  });

  const getCampaignsContact = flow(function* (id) {
    self.campaignsContactLoading = true;
    try {
      const params = {
        pageNumber: self.pageNumber ?? 1,
        pageSize: self.pageSize ?? 10,
        id: id,
      };
      const response = yield CAMPAIGN_OPPORTUNITY_API.getCampaignsContacts(params);
      if (response) {
        self.contactOpportunityCampaign = cast(response.results || []);
        self.opportunityMetrics = cast(response.opportunityMetrics || []);
        self.contactsCount = cast(response.count || 0);
        // self.opportunityCount = response.count || 0;
      }
      return response.results;
    } catch (e) {
      self.campaignsContactLoading = false;
      return Promise.reject(e);
    } finally {
      self.campaignsContactLoading = false;
    }
  });

  const getCampaignsContactsDelete = flow(function* (opportunityId, contactId) {
    self.contactsDeleteLoading = true;
    try {
      const params = {
        contactId: contactId,
        opportunityId: opportunityId,
      };
      yield CAMPAIGN_OPPORTUNITY_API.getCampaignsContactsDelete(params);
    } catch (e) {
      self.contactsDeleteLoading = false;
      return Promise.reject(e);
    } finally {
      self.contactsDeleteLoading = false;
    }
  });

  const campaignsContactBulkDelete = flow(function* (contactIds, opportunityId) {
    self.contactsDeleteLoading = true;
    try {
      const response = yield CAMPAIGN_OPPORTUNITY_API.campaignsContactBulkDelete(contactIds, opportunityId);
      return response;
    } catch (e) {
      self.contactsDeleteLoading = false;
      return Promise.reject(e);
    } finally {
      self.contactsDeleteLoading = false;
    }
  });

  const sendEmailContact = flow(function* (data, opportunity, campaign) {
    self.campaignsLoader = true;
    try {
      const response = yield CAMPAIGN_OPPORTUNITY_API.postEmail(data, opportunity, campaign);
      if (response) {
        return notification.success('', 'Email Sent Successfully.');
      }
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'We are unable to sent the Email.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'We are unable to sent the Email.',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.campaignsLoader = false;
    }
  });

  const sendEmailInBulk = flow(function* (data, campaign) {
    self.sendEmailInBulkLoading = true;
    try {
      const response = yield CAMPAIGN_OPPORTUNITY_API.sendEmailInBulk(data, campaign);
      if (response) {
        return notification.success('', 'Email Sent Successfully.');
      }
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'We are unable to sent the Email.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'We are unable to sent the Email.',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.sendEmailInBulkLoading = false;
    }
  });

  const addToOutreachList = flow(function* (data, campaign, isScheduled?: string) {
    self.addToOutreachListLoading = true;
    try {
      yield CAMPAIGN_OPPORTUNITY_API.addToOutreachList(data, campaign);
      getCampaignsOpportunity(campaign, isScheduled);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.addToOutreachListLoading = false;
    }
  });

  const saveContact = flow(function* (data, opportunity) {
    self.saveContactLoading = true;
    try {
      yield CAMPAIGN_OPPORTUNITY_API.saveContact(data, opportunity);
      getCampaignsContact(opportunity);
      setAddContactStatesToDefault();
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.saveContactLoading = false;
    }
  });

  const getFolders = flow(function* () {
    self.foldersLoading = true;
    try {
      const response = yield CAMPAIGN_API.getFolders();
      if (response?.isCancel) return;
      self.newFolderCreate = false,
      self.folderUpdate = false,
      self.foldersData = response;
    } catch (e) {
      self.foldersLoading = false;
      return Promise.reject(e);
    } finally {
      self.foldersLoading = false;
    }
  });

  const createFolder = flow(function* (payload?: any) {
    self.createFolderLoading = true;
    try {
      const response = yield CAMPAIGN_API.createFolder(payload);
      if (response?.isCancel) return;
      setNewFolderCreate(false);
      setFolderUpdate(false);
      setNewFolderValue('');
      setShowError(false);
      getFolders();
    } catch (e) {
      self.createFolderLoading = false;
      return Promise.reject(e);
    } finally {
      self.createFolderLoading = false;
    }
  });

  const updateFolder = flow(function* (payload: any, id: number) {
    self.updateFolderLoading = true;
    try {
      const response = yield CAMPAIGN_API.updateFolder(payload, id);
      if (response?.isCancel) return;
      setNewFolderCreate(false);
      setFolderUpdate(false);
      setNewFolderValue('');
      setShowError(false);
      getFolders();
      getCampaigns();
    } catch (e) {
      self.updateFolderLoading = false;
      return Promise.reject(e);
    } finally {
      self.updateFolderLoading = false;
    }
  });

  const deleteFolder = flow(function* (id: number, ottoProjectId?: number) {
    self.deleteFolderLoading = true;
    try {
      const response = yield CAMPAIGN_API.deleteFolder(id);
      if (response?.isCancel) return;
      getFolders();
      if (ottoProjectId) {
        getCampaigns(false, null, ottoProjectId);
      } else {
        getCampaigns();
      }
    } catch (e) {
      self.deleteFolderLoading = false;
      return Promise.reject(e);
    } finally {
      self.deleteFolderLoading = false;
    }
  });

  const getExistingTemplates = flow(function* (search?: string) {
    self.existingTemplatesLoading = true;
    try {
      const response = yield CAMPAIGN_API.getExistingTemplates(search || '');
      if (response?.isCancel) return;
      self.existingTemplates = cast(response);
      return response?.results;
    } catch (e) {
      self.existingTemplatesLoading = false;
      return Promise.reject(e);
    } finally {
      self.existingTemplatesLoading = false;
    }
  });

  const addNewMail = flow(function* () {
    self.addNewMailLoading = true;
    try {
      const response = yield CAMPAIGN_API.addNewMail();
      if (response?.isCancel) return;
      return response;
    } catch (e) {
      self.addNewMailLoading = false;
      return Promise.reject(e);
    } finally {
      self.addNewMailLoading = false;
    }
  });

  const updateOpportunityStatus = flow(function* (opportunityId: number, campaignId: number, closeModal = true, isScheduled?: string) {
    self.updateOpportunityStatusLoading = true;
    try {
      const response = yield CAMPAIGN_OPPORTUNITY_API.updateOpportunityStatus(opportunityId, campaignId);
      if (response?.isCancel) return;
      if (closeModal) {
        self.visible = false;
      }
      getCampaignsOpportunity(campaignId, isScheduled);
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.updateOpportunityStatusLoading = false;
    }
  });

  const getChartData = flow(function* (id: number) {
    self.chartDataLoading = true;
    try {
      const response = yield CAMPAIGN_API.getChartData(id);
      if (response?.isCancel) return;
      self.chartData = response;
    } catch (e) {
      self.chartDataLoading = false;
      return Promise.reject(e);
    } finally {
      self.chartDataLoading = false;
    }
  });

  const toogleStatus = flow(function* (id: number, screen: string, ottoProjectId?: number) {
    self.toogleStatusLoading = true;
    try {
      const response = yield CAMPAIGN_API.toogleStatus(id);
      if (response?.isCancel) return;
      if (screen === 'campaigns') {
        if (ottoProjectId) {
          getCampaigns(false, null, ottoProjectId);
        } else {
          getCampaigns();
        }
      } else if (screen === 'opportunity') {
        getChartData(id);
      }
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });

      return e;
    } finally {
      self.toogleStatusLoading = false;
    }
  });

  const postNewCampaign = flow(function* (payload: any) {
    self.postNewCampaignLoading = true;
    try {
      const response = yield CAMPAIGN_API.postNewCampaign(payload);
      if (response?.isCancel) return;
      if (response) {
        if (payload?.otto_project_id) {
          getCampaigns(true, response.id, payload.otto_project_id);
        } else {
          getCampaigns(true, response.id);
        }
        self.visible = false;
      }
      return response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.postNewCampaignLoading = false;
    }
  });

  const editCampaign = flow(function* (payload: any, id: any) {
    self.postNewCampaignLoading = true;
    try {
      const response = yield CAMPAIGN_API.editCampaign(payload, id);
      if (response) {
        self.createModalVisible = false;
      }
      return response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Something went wrong.',
        desc: '',
        permanent: false,
        handleStatuses: [
          {
            statuses: [401, 403],
            msg: 'Something went wrong',
            permanent: false,
            showDetails: false,
          },
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
    } finally {
      self.postNewCampaignLoading = false;
    }
  });


  const updateCampaign = flow(function* (payload: any, id: number, ottoProjectId?: number) {
    self.updateCampaignLoading = true;
    try {
      const response = yield CAMPAIGN_API.updateCampaign(payload, id);
      if (response) {
        if (ottoProjectId) {
          getCampaigns(false, null, ottoProjectId);
        } else {
          getCampaigns();
        }
      }
    } catch (e) {
      self.updateCampaignLoading = false;
      return Promise.reject(e);
    } finally {
      self.updateCampaignLoading = false;
    }
  });

  const deleteCampaign = flow(function* (id: number, ottoProjectId?: number) {
    self.deleteCampaignLoading = true;
    try {
      yield CAMPAIGN_API.deleteCampaign(id);
      if (ottoProjectId) {
        getCampaigns(false, null, ottoProjectId);
      } else {
        getCampaigns();
      }
    } catch (e) {
      self.deleteCampaignLoading = false;
      return Promise.reject(e);
    } finally {
      self.deleteCampaignLoading = false;
    }
  });

  const getCampaignSettings = flow(function* (id: number) {
    try {
      const response = yield CAMPAIGN_API.getCampaignSettings(id);
      self.campaignSettings = response;
    } catch (e) {
      return Promise.reject(e);
    }
  });


  const createTemplate = flow(function* (data) {
    try {
      yield CAMPAIGN_API.createTemplate(data);
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const updateTemplate = flow(function* (data, id) {
    try {
      yield CAMPAIGN_API.updateTemplate(data, id);
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const deleteTemplate = flow(function* (id) {
    try {
      yield CAMPAIGN_API.deleteTemplate(id);
      getExistingTemplates();
      notification.success('', 'Template deleted successfully.');
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    }
  });

  const addOpportunitiesFromCsv = flow(function* (id, payload) {
    self.loadingImportFromCsv = true;
    try {
      yield CAMPAIGN_API.addOpportunitiesFromCsv(id, payload);
      getCampaignsOpportunity(id);
      notification.success('', 'Opportunities added successfully.');
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      self.loadingImportFromCsv = false;
    }
  });

  const addGlobalBlockList = flow(function* (payload) {
    self.loadingAddGlobalBlockList = true;
    try {
      yield CAMPAIGN_API.addGlobalBlockList(payload);
      getGlobalBlockList();
      notification.success('', 'Global blacklist updated successfully.');
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingAddGlobalBlockList = false;
    }
  });

  const getGlobalBlockList = flow(function* () {
    try {
      const response = yield CAMPAIGN_API.getGlobalBlockList();
      if (response?.isCancel) return;
      self.globalBlocklist = response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    }
  });

  const getCampaignBlockList = flow(function* (id) {
    try {
      const response = yield CAMPAIGN_API.getCampaignBlockList(id);
      self.campaignBlocklist = response || [];
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    }
  });

  const updateCampaignBlockList = flow(function* (id, payload) {
    self.loadingUpdateCampaignBlockList = true;
    try {
      yield CAMPAIGN_API.updateCampaignBlockList(id, payload);
      getCampaignBlockList(id);
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      self.loadingUpdateCampaignBlockList = false;
    }
  });

  const deleteOpportunity = flow(function* (opportunityId, campaignId) {
    self.deletingOpportunity = true;
    try {
      yield CAMPAIGN_OPPORTUNITY_API.deleteOpportunity(opportunityId, campaignId);
      getCampaignsOpportunity(campaignId);
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      self.deletingOpportunity = false;
    }
  });

  const addEmailConfigration = flow(function* (payload) {
    self.addingEmail = true;
    try {
      yield CAMPAIGN_OPPORTUNITY_API.addEmailConfig(payload);
      notification.success('', 'Email account connected successfully.');
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.addingEmail = false;
    }
  });

  const deleteConnectedEmail = flow(function* (linkedEmailId: number) {
    try {
      self.deletingConnectedEmailsIds = cast([...self.deletingConnectedEmailsIds, linkedEmailId]);
      yield CAMPAIGN_API.deleteConnectedEmail(linkedEmailId);
      getCampaignsCustomerEmails();
      notification.success('', 'Connected email removed successfully.');
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      self.deletingConnectedEmailsIds = cast(self.deletingConnectedEmailsIds.filter(id => id !== linkedEmailId));
    }
  });

  const setIsEditorFocus = (value: boolean) => self.isEditorFocus = value;
  const setVisible = (value: boolean) => self.visible = value;
  const setCreateModalVisible = (value: boolean, openEditModal=false) => {
    self.isEditModal = openEditModal;
    self.createModalVisible = value;
  };
  const setFoldersModalVisible = (value: boolean) => self.foldersModalVisible = value;
  const setShowErrors = (value: boolean) => self.showErrors = value;
  const setShowConfigureModal = (value: boolean) => self.showConfigureModal = value;
  const setKeywordSuggestions = (value: any) => self.keywordSuggestions = value;
  const setNewFolderCreate = (value: boolean) => self.newFolderCreate = value;
  const setFolderUpdate = (value: boolean) => self.folderUpdate = value;
  const setShowDragger = (value: boolean) => self.showDragger = value;

  const setFile = (value: any) => self.file = value;
  const setWebsiteUrl = (value: string) => self.websiteUrl = value;
  const setLinkedinUrl = (value: string) => self.linkedinUrl = value;
  const setFacebookUrl = (value: string) => self.facebookUrl = value;
  const setTwitterUrl = (value: string) => self.twitterUrl = value;
  const setGitlabUrl = (value: string) => self.gitlabUrl = value;
  const setEmailAddress = (value: string) => self.emailAddress = value;
  const setFullName = (value: string) => self.fullName = value;
  const setFirstName = (value: string) => self.firstName = value;
  const setLastName = (value: string) => self.lastName = value;
  const setTitle = (value: string) => self.title = value;
  const setShowError = (value: boolean) => self.showError = value;
  const setNewFolderValue = (value: string) => self.newFolderValue = value;

  const setCreateTempModalVisible = (value: boolean) => self.createTempModalVisible = value;
  const setCampaignValue = (value: any) => self.opportunityCompaign = value;

  const setCurrentEditorState = (editorState: EditorState) => {
    self.currentEditorState = cast(editorState);
  };

  const setFocusTermsClassNames = focusTerms => {
    self.focusTermsClassNames = cast(focusTerms);
  };

  const setHighlights = highlight => {
    self.highlights = cast(highlight);
  };

  return {
    getCampaigns,
    getCampaignsOpportunity,
    getCampaignsCustomerEmails,
    updateDailyLimit,
    setCampaignPagination,
    setOpPagination,
    setAllOrganicKeywordsParams,
    getKeywordSuggestions,
    setIsEditorFocus,
    setCurrentEditorState,
    setFocusTermsClassNames,
    setHighlights,
    postNewCampaign,
    getFolders,
    setVisible,
    getExistingTemplates,
    setShowErrors,
    getCampaignsContact,
    getCampaignsContactsDelete,
    addNewMail,
    setShowConfigureModal,
    getChartData,
    updateCampaignOpportunity,
    toogleStatus,
    sendEmailContact,
    updateCampaign,
    saveContact,
    deleteCampaign,
    sendEmailInBulk,
    addToOutreachList,
    updateOpportunityStatus,
    setKeywordSuggestions,
    setFoldersModalVisible,
    createFolder,
    updateFolder,
    setNewFolderCreate,
    setFolderUpdate,
    deleteFolder,
    setShowDragger,
    setFile,
    setWebsiteUrl,
    setLinkedinUrl,
    setFacebookUrl,
    setTwitterUrl,
    setGitlabUrl,
    setEmailAddress,
    setFullName,
    setFirstName,
    setLastName,
    setTitle,
    setShowError,
    setAddContactStatesToDefault,
    setCreateTempModalVisible,
    getCampaignSettings,
    setOpOrdering,
    updateOpportunityFilter,
    setCampaignOrdering,
    updateCampaignFilter,
    setNewFolderValue,
    createTemplate,
    setUpdateModalValues,
    updateTemplate,
    deleteTemplate,
    setCreateModalVisible,
    editCampaign,
    addOpportunitiesFromCsv,
    addGlobalBlockList,
    getGlobalBlockList,
    getCampaignBlockList,
    updateCampaignBlockList,
    deleteOpportunity,
    campaignsContactBulkDelete,
    getFullCampaignsList,
    addEmailConfigration,
    setCampaignValue,
    deleteConnectedEmail,
  };
});

export const initCampaignStore = () => {
  return CampaignStore.create({
    campaignsLoader: false,
    campaignsCustomerEmailsLoader: false,
    pageNumber: 1,
    pageSize: 10,
    opPageNumber: 1,
    opPageSize: 10,
    keywordSuggestionsLoading: false,
    currentEditorState: EditorState.createEmpty(),
    allDetailParams: DEFAULT_PARAMS,
    detailFilter: DETAIL_FILTER,
    postNewCampaignLoading: false,
    foldersLoading: false,
    visible: false,
    existingTemplatesLoading: false,
    opportunityCount: 0,
    showErrors: false,
    addNewMailLoading: false,
    showConfigureModal: false,
    chartDataLoading: false,
    campaignsOpLoader: false,
    toogleStatusLoading: false,
    updateCampaignLoading: false,
    deleteCampaignLoading: false,
    sendEmailInBulkLoading: false,
    addToOutreachListLoading: false,
    campaignsContactLoading: false,
    contactsDeleteLoading: false,
    updateOpportunityStatusLoading: false,
    foldersModalVisible: false,
    createFolderLoading: false,
    updateFolderLoading: false,
    newFolderCreate: false,
    folderUpdate: false,
    deleteFolderLoading: false,
    saveContactLoading: false,
    showDragger: false,
    file: null,
    websiteUrl: '',
    linkedinUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    gitlabUrl: '',
    emailAddress: '',
    fullName: '',
    firstName: '',
    lastName: '',
    title: '',
    showError: false,
    createTempModalVisible: false,
    updateSettingsLoading: false,
    allOpportunityParams: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    allCampaignParams: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    newFolderValue: '',
    updateModalValues: {
      id: null,
      name: '',
      subject: '',
      body: '',
      isUpdate: false,
    },
    createModalVisible: false,
    loadingImportFromCsv: false,
    loadingAddGlobalBlockList: false,
    campaignBlocklist: null,
    loadingUpdateCampaignBlockList: false,
    deletingOpportunity: false,
    isEditModal: false,
    addingEmail: false,
    deletingConnectedEmailsIds: [],
    opportunityCompaign: null,
  });
};
