import {getApiUrl, getAuthHeader, getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import Router from 'next/router';

const qeryString = (data: Record<string, any>) => {
  let query = '';
  Object.keys(data).forEach((key: string) => {
    if (typeof data[key] !== 'undefined') {
      query = query + `&${key}=${data[key]}`;
    }
  });
  return query;
};

class AuditedSitesApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.SA_ENDPOINT, '/api');

  public async getAuditedSitesList(params, basicData?: boolean) {
    if (basicData) {
      params['basic'] = '1';
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/`, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getCompetitorContentVelocity(id, frequencyType, year) {
    try {
      const response = await this.axiosWithoutCamelCase.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/competitor-content-velocity/?frequency_type=${frequencyType}${frequencyType == 'yearly' ? '' : `&year=${year}`}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getContentVelocityByDay(id, duration, sitemapIds) {
    try {
      const response = await this.axiosWithoutCamelCase.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/content-velocity/?duration=${duration}&sitemap_ids=${sitemapIds}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getContentVelocityByMonth(id, sitemapIds) {
    try {
      const response = await this.axiosWithoutCamelCase.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/content-velocity/?duration=by_month&sitemap_ids=${sitemapIds}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getContentVelocityByYear(id, sitemapIds) {
    try {
      const response = await this.axiosWithoutCamelCase.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/content-velocity/?duration=by_year&sitemap_ids=${sitemapIds}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async createSegment(data, projectId, filters) {
    try {
      const qery = qeryString(data).concat(`&${filters}`);
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/page-explorer/create-segment/?audit=${projectId}${qery}`, {}, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async modifySegment(data, filters) {
    try {
      const qery = qeryString(data).concat(`&${filters}`);
      const response = await this.axios.put(`${AuditedSitesApi.baseUrl}/v2/page-explorer/update-segment/?audit=${data?.audit}${qery}`, {}, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteSegment(id, segmentName) {
    try {
      const response = await this.axios.delete(`${AuditedSitesApi.baseUrl}/v2/page-explorer/delete-segment/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
        data: {
          audit: id,
          segment: segmentName,
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSpecificIdResponse(id) {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/project-details/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSiteAuditIssuesList(id, name, publicHash?: string) {
    const token = getTokenFromCookies();
    const params = {};
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    if (name) {
      params['segment']= name;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/issues/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.status == 404) {
        localStorage.removeItem('lastPropertySiteAudit');
        if (!window?.location?.pathname.includes('otto')) {
          Router.push('/site-audit/list');
        }
      }
      return Promise.reject(error);
    }
  }

  public async exportIssuesPage(id) {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/issues/?export=${id}`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadAuditAffectedPages(id, payload, segmentValue, publicHash?: string) {
    const params = payload;
    if (payload?.issueName) {
      params['issue'] = payload?.issueName;
    }
    if (payload?.groupName) {
      params['group'] = payload?.groupName;
    }
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    if (segmentValue) {
      params['segment'] = segmentValue;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/issues-affected-pages/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async ignoreIssuesApi(id, payload, stopIgnoring?: boolean) {
    const params = {};
    if (payload?.issueName) {
      params['issue'] = payload?.issueName;
    }
    if (payload?.groupName) {
      params['group'] = payload?.groupName;
    }
    if (payload?.pageId) {
      params['page_id'] = payload?.pageId;
    }

    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/ignore-issue/${stopIgnoring ? '?stop_ignoring=True' : ''}`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSiteWideDetail(id, publicHash?: string) {
    const params = {};
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/domain-analysis/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.status == 404) {
        localStorage.removeItem('lastPropertySiteAudit');
        if (!window?.location?.pathname.includes('otto')) {
          Router.push('/site-audit/list');
        }
      }
      return Promise.reject(error);
    }
  }

  public async loadExplorerList(id, properties, pageExplorerParams, param: any, publicHash: string, name: string) {
    const params = {...pageExplorerParams};
    const newParams= {
      page: params.page,
      page_size: params.page_size,
      url_contains: params.search,
    };
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      newParams['public_share_hash'] = publicHash;
    }
    if (name) {
      newParams['segment_names_contains'] = name;
    }
    try {
      let url = `${AuditedSitesApi.baseUrl}/v2/page-explorer/?audit=${id}&fields=${properties?.join(',')}`;
      if (param) {
        url += `&${param}`;
      }
      const response = await this.axios.get(url, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: newParams,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadExplorerDetail(id, auditId, publicHash?: string) {
    const params = {audit: auditId};
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/page-explorer/${id}/page-insights/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getEmailSettingUsers() {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/user-settings/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getEmailAlertSettingUsers(id: any) {
    const params = {audit: id};
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/alert-settings/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getListAvailableEmailAlerts() {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/user-settings/alerts/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async onActivateEmailAlert(id) {
    try {
      const response = await this.axios.put(`${AuditedSitesApi.baseUrl}/user-settings/${id}/activate/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async onDeactivateEmailAlert(id) {
    try {
      const response = await this.axios.put(`${AuditedSitesApi.baseUrl}/user-settings/${id}/deactivate/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async onAddEmailRecepient(id, emails) {
    try {
      const response = await this.axios.put(`${AuditedSitesApi.baseUrl}/user-settings/${id}/update-recipients/`, {recipients: emails}, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async onAddNewAlert(key, emails, siteId) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/user-settings/add-alert/`, {alert_type: key, recipients: emails, site_id: siteId}, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async onUpdateEmailAlert(payload) {
    try {
      const response = await this.axios.put(`${AuditedSitesApi.baseUrl}/v2/alert-settings/update-settings/`, payload, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async recrawlExplorerDetailApi(id, siteAuditId) {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/page-explorer/${id}/page-insights/?reprocess=true&audit=${siteAuditId}`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async archiveAuditedSite(id) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/archive/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async crawlSettings(id, values) {
    try {
      const response = await this.axios.patch(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/`, values, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async bulkSiteAudit(list) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/site-audit/bulk-create/`, list, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async recrawlAuditProperty(id) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/recrawl/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadIssuesChartTable(id, value, publicHash?: string) {
    const params = {issues_changes: value};
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/crawl-changes/${id}/issues-changes/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadPagesChartTable(id, value, publicHash?: string) {
    const params = {pages_changes: value};
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/crawl-changes/${id}/pages-changes/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async getDepthNodes(audit, type = '', sizeParam = '') {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/node-visualization/depth-nodes/`, {
        params: {
          audit: audit,
          type,
          // size_param: sizeParam,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAuditedSiteDetail(id, auditPeriodStart, auditPeriodEnd, publicHash: string, segmentId?: string) {
    const params = {};
    if (auditPeriodStart && auditPeriodEnd) {
      params['period_start'] = auditPeriodStart;
      params['period_end'] = auditPeriodEnd;
    }
    if (segmentId) {
      params['scope'] = 'segment';
      params['segment_id'] = segmentId;
    }
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/`, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.status == 404) {
        localStorage.removeItem('lastPropertySiteAudit');
        if (!window?.location?.pathname.includes('otto')) {
          Router.push('/site-audit/list');
        }
      }
      return Promise.reject(error);
    }
  }
  public async postNewSiteAudit(property, values) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/site-audit/`, {'siteproperty': property, ...values}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async siteAuditConnectGsc(id, data={}) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/connect-gsc/`,
        data,
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async siteAuditUpdateGscSetting(id, data) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/connect-gsc/`, data,
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async addCompetitor(data) {
    try {
      const response = await this.axios.post(`${AuditedSitesApi.baseUrl}/v2/sitemap-competitor/`, data,
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async delteCompetitorSitemap(id) {
    try {
      const response = await this.axios.delete(`${AuditedSitesApi.baseUrl}/v2/sitemap-competitor/${id}/`,
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAlertsList(data, publicHash?: string) {
    const params = data;
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/alerts/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getAlertDetailDrawerData(auditId, alertID, publicHash?: string) {
    const params = {};
    const token = getTokenFromCookies();
    if (!token && publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${auditId}/alerts/${alertID}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getNotStandardData(id) {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/site-audit/${id}/not-standard-urls/`, {
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response?.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getCrawlActivity(params) {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/otto-projects/crawl-activity/`, {
        params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getBotCrawlAnalysis(params) {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/otto-projects/bot-crawl-analysis/`, {
        params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getBotCrawlPages(params) {
    try {
      const response = await this.axios.get(`${AuditedSitesApi.baseUrl}/v2/otto-projects/bot-crawled-pages/`, {
        params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const AUDITED_SITES_API_V2 = new AuditedSitesApi();
