import {topKeywordsApi} from '@/api/gsc';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {types, flow, cast} from 'mobx-state-tree';
import {getFilter, formatSorters} from '@/utils/filters';


const filterList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.boolean,
});

const keywordsModal = types.model({
  kw: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  searchVol: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  traffic_value: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  avgPosCur: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
});

const keywordPositionChangesTotalModel = types.model({
  totalImprovedKeywords: types.maybeNull(types.number),
  totalLostKeywords: types.maybeNull(types.number),
  totalNewKeywords: types.maybeNull(types.number),
});

const keywordCountModel = types.model({
  kwCount: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});

const keywordPositionBucketChartModel = types.model({
  newKeywordsChart: types.maybeNull(types.array(keywordCountModel)),
  improvedKeywordsChart: types.maybeNull(types.array(keywordCountModel)),
  lostKeywordsChart: types.maybeNull(types.array(keywordCountModel)),
});

const keywordPositionChangesTableModel = types.model({
  keywordPositionBucketChart: types.maybeNull(keywordPositionBucketChartModel),
  keywordPositionChangeTotal: types.maybeNull(keywordPositionChangesTotalModel),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
});

export const TopKeywordsChangesStore = types.model({
  totalImprovedKeywords: types.maybeNull(types.number),
  totalLostKeywords: types.maybeNull(types.number),
  totalNewKeywords: types.maybeNull(types.number),
  newKeywords: types.maybeNull(types.array(keywordsModal)),
  improvedKeywords: types.maybeNull(types.array(keywordsModal)),
  lostKeywords: types.maybeNull(types.array(keywordsModal)),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
  loadingKeywords: types.boolean,
  keywordFilterList: types.array(filterList),
  keywordSortField: types.maybeNull(types.string),
  keywordSortDirection: types.maybeNull(types.string),
  keywordSearchTerm: types.maybeNull(types.string),
  notKeywordTerm: types.maybeNull(types.string),
  topChangesNewKeywords: types.maybeNull(types.array(keywordsModal)),
  topChangesImprovedKeywords: types.maybeNull(types.array(keywordsModal)),
  topChangesLostKeywords: types.maybeNull(types.array(keywordsModal)),
  loadingTopKeywordsChanges: types.boolean,
  isKeywordInit: types.boolean,
  detailDataNew: types.boolean,
  detailDataLost: types.boolean,
  detailDataImproved: types.boolean,
  keywordPositionChangesTable: types.maybeNull(keywordPositionChangesTableModel),
  loadingKeywordPositionChangesTable: types.boolean,

}).views(self => ({
  get getAllNewKeywords() {
    return toJS(self.newKeywords);
  },
  get getAllImprovedKeywords() {
    return toJS(self.improvedKeywords);
  },
  get getAllLostKeywords() {
    return toJS(self.lostKeywords);
  },
  get getTotalImprovedKeywords() {
    return self.totalImprovedKeywords;
  },
  get getTotalNewKeywords() {
    return self.totalNewKeywords;
  },
  get getTotalLostKeywords() {
    return self.totalLostKeywords;
  },
  get getAllTopChangesNewKeywords() {
    return toJS(self.topChangesNewKeywords);
  },
  get getAllTopChangesImprovedKeywords() {
    return toJS(self.topChangesImprovedKeywords);
  },
  get getAllTopChangesLostKeywords() {
    return toJS(self.topChangesLostKeywords);
  },
  get getKeywordPositionChangesTable() {
    return toJS(self.keywordPositionChangesTable);
  },
  get getKeywordPositionChangeTotal() {
    return toJS(self.keywordPositionChangesTable?.keywordPositionChangeTotal);
  },
  get getKeywordPositionBucketChart() {
    return toJS(self.keywordPositionChangesTable?.keywordPositionBucketChart);
  },
})).actions(self => {
  const loadTopKeywordsPositionChanges = flow(function* ({property, countryCode, previousPeriodStart, previousPeriodEnd,
    currentPeriodStart, filterKeywordTerm, currentPeriodEnd, pageSize, pageNumber, keywordsReportType}) {
    self.loadingTopKeywordsChanges = true;
    const params = {
      selected_property: property,
      period1_start: previousPeriodStart,
      period1_end: previousPeriodEnd,
      period2_start: currentPeriodStart,
      period2_end: currentPeriodEnd,
      country_code: countryCode,
      keyword: filterKeywordTerm,
      page_size: pageSize,
      page_number: pageNumber,
      keywords_report_type: keywordsReportType,
    };

    try {
      const response = yield topKeywordsApi.getKeywordPositionChanges(params);
      if (response) {
        if (response.keywordPositionChangesTable) {
          self.topChangesNewKeywords = cast(response.keywordPositionChangesTable.newKeywords);
          self.topChangesImprovedKeywords = cast(response.keywordPositionChangesTable.improvedKeywords);
          self.topChangesLostKeywords = cast(response.keywordPositionChangesTable.lostKeywords);
          self.totalImprovedKeywords = response.keywordPositionChangesTable.totalImprovedKeywords;
          self.totalLostKeywords = response.keywordPositionChangesTable.totalLostKeywords;
          self.totalNewKeywords = response.keywordPositionChangesTable.totalNewKeywords;
          return true;
        }
      }
    } catch (e) {
      notification.error('An error has occurred', 'There was an error fetching top pages data. Please try again later.', true);
      return Promise.reject(e);
    } finally {
      self.loadingTopKeywordsChanges = false;
    }
  });

  const setDetailDataNew = value => {
    self.detailDataNew = value;
  };

  const setDetailDataLost = value => {
    self.detailDataLost = value;
  };

  const setDetailDataImproved = value => {
    self.detailDataImproved = value;
  };

  const loadKeywordPositionChangesTable = flow(function* ({property, countryCode, previousPeriodStart, previousPeriodEnd,
    currentPeriodStart, currentPeriodEnd, keywordsReportType}) {
    self.loadingKeywordPositionChangesTable = true;
    const params = {
      selected_property: property,
      period1_start: previousPeriodStart,
      period1_end: previousPeriodEnd,
      period2_start: currentPeriodStart,
      period2_end: currentPeriodEnd,
      country_code: countryCode,
      keywords_report_type: keywordsReportType,
    };
    try {
      const response = yield topKeywordsApi.getKeywordPositionChangesChart(params);
      if (response) {
        if (response.keywordPositionChangesTable) {
          self.keywordPositionChangesTable = response.keywordPositionChangesTable;
        }
      }
    } catch (e) {
      notification.error('An error has occurred', 'There was an error fetching top pages data. Please try again later.', true);
      return Promise.reject(e);
    } finally {
      self.loadingKeywordPositionChangesTable = false;
    }
  });

  const loadKeywordsPositionChanges = flow(function* ({property, countryCode, previousPeriodStart, previousPeriodEnd,
    currentPeriodStart, filters, filterKeywordTerm, notKeywordTerm, currentPeriodEnd, pageSize, pageNumber, keywordsReportType}) {
    self.loadingKeywords = true;
    self.isKeywordInit = true;

    const sorters = {
      serp: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      cpc: 'cpc',
      vol: 'volume',
      page: 'page',
      opportunityScore: 'opportunity_score',
      value: 'value',
      watchlist: 'watchlist',
      potentialTrafficCur: 'potential_traffic_cur',
      potentialTrafficValueCur: 'potential_traffic_value_cur',
    };

    const formtatedSorters = formatSorters(sorters, self.keywordSortField, self.keywordSortDirection);

    const params = {
      selected_property: property,
      period1_start: previousPeriodStart,
      period1_end: previousPeriodEnd,
      period2_start: currentPeriodStart,
      period2_end: currentPeriodEnd,
      keyword: filterKeywordTerm,
      page_size: pageSize,
      country_code: countryCode,
      not_keyword: notKeywordTerm,
      page_number: pageNumber,
      keywords_report_type: keywordsReportType,
      position_from: getFilter(filters, 'posCur')?.from,
      position_to: getFilter(filters, 'posCur')?.to,
      clicks_from: getFilter(filters, 'clicksCur')?.from,
      clicks_to: getFilter(filters, 'clicksCur')?.to,
      impressions_from: getFilter(filters, 'impCur')?.from,
      impressions_to: getFilter(filters, 'impCur')?.to,
      cpc_from: getFilter(filters, 'cpc')?.from,
      cpc_to: getFilter(filters, 'cpc')?.to,
      volume_from: getFilter(filters, 'vol')?.from,
      volume_to: getFilter(filters, 'vol')?.to,
      opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
      potential_traffic_cur_form: getFilter(filters, 'potentialTrafficCur')?.from,
      potential_traffic_cur_to: getFilter(filters, 'potentialTrafficCur')?.to,
      potential_traffic_value_cur_form: getFilter(filters, 'potentialTrafficValueCur')?.from,
      potential_traffic_value_cur_to: getFilter(filters, 'potentialTrafficValueCur')?.to,
      sort_by: formtatedSorters,
    };

    try {
      const response = yield topKeywordsApi.getKeywordPositionChanges(params);
      if (response) {
        if (response.keywordPositionChangesTable) {
          self.totalImprovedKeywords = response.keywordPositionChangesTable.totalImprovedKeywords;
          self.totalLostKeywords = response.keywordPositionChangesTable.totalLostKeywords;
          self.totalNewKeywords = response.keywordPositionChangesTable.totalNewKeywords;
          self.newKeywords = cast(response.keywordPositionChangesTable.newKeywords);
          self.improvedKeywords = cast(response.keywordPositionChangesTable.improvedKeywords);
          self.lostKeywords = cast(response.keywordPositionChangesTable.lostKeywords);
          self.isKeywordInit = false;
        }
      }
    } catch (e) {
      notification.error('An error has occurred', 'There was an error fetching top pages data. Please try again later.', true);
      return Promise.reject(e);
    } finally {
      self.loadingKeywords = false;
    }
  });

  const onKeywordPaginationChange = (pageNumber: number, pageSize: number) => {
    self.pageNumber = pageNumber;
    self.pageSize = pageSize;
  };

  const onKeywordFilterChange = (filters: any) => {
    self.pageNumber = 1;
    self.keywordFilterList = cast(filters);
  };

  const onKeywordTableChange = (sortFields: string, order: string) => {
    self.keywordSortField = sortFields;
    self.keywordSortDirection = order;
  };

  const onKeywordUpdateSearchTerm = (searchTerm: string) => {
    self.keywordSearchTerm = searchTerm;
  };
  const onUpdateNotKeywordTerm = (notKeywordTerm: string) => {
    self.notKeywordTerm = notKeywordTerm;
  };
  const setIsKeywordInit = (value: boolean) => {
    self.isKeywordInit = value;
  };
  const clearFilterList = () => {
    self.pageNumber = 1;
    self.keywordFilterList = cast([
      {id: 1, name: 'exclude', header: 'Exclude text', text: '', type: undefined, active: false},
      {id: 2, name: 'opportunityScore', header: 'SEO Potential', from: undefined, to: undefined, type: undefined, active: false},
      {id: 3, name: 'posCur', header: 'Position', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 5, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
      {id: 6, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
      {id: 7, name: 'vol', header: 'Search volume', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 8, name: 'potentialTrafficCur', header: 'Potential Traffic', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 9, name: 'potentialTrafficValueCur', header: 'Potential Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
    ]);
  };

  return {
    loadKeywordsPositionChanges,
    setIsKeywordInit,
    loadTopKeywordsPositionChanges,
    onKeywordPaginationChange,
    setDetailDataNew,
    setDetailDataLost,
    setDetailDataImproved,
    onKeywordFilterChange,
    onKeywordUpdateSearchTerm,
    onUpdateNotKeywordTerm,
    onKeywordTableChange,
    clearFilterList,
    loadKeywordPositionChangesTable,
  };
});

export const initTopKeywordsChangesStore = () => {
  return TopKeywordsChangesStore.create({
    loadingKeywords: false,
    loadingKeywordPositionChangesTable: false,
    loadingTopKeywordsChanges: false,
    pageNumber: 1,
    pageSize: 20,
    keywordFilterList: [
      {id: 1, name: 'exclude', header: 'Exclude text', text: '', type: undefined, active: false},
      {id: 2, name: 'opportunityScore', header: 'SEO Potential', from: undefined, to: undefined, type: undefined, active: false},
      {id: 3, name: 'posCur', header: 'Position', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 5, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
      {id: 6, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
      {id: 7, name: 'vol', header: 'Search volume', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 8, name: 'potentialTrafficCur', header: 'Potential Traffic', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 9, name: 'potentialTrafficValueCur', header: 'Potential Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
    ],
    keywordSortField: '',
    keywordSortDirection: '',
    keywordSearchTerm: '',
    isKeywordInit: true,
    detailDataNew: false,
    detailDataLost: false,
    detailDataImproved: false,
  });
};
