export const getPercentageColor = (percentage: number): string => {
  const green = '#52D477';
  const orange = '#FF8536';
  const red = '#F44343';
  return percentage >= 70 ? green : percentage >= 40 ? orange : red;
};
export const getInversePercentageColor = (percentage: number): string => {
  const green = '#52D477';
  const red = '#F44343';
  return percentage <= 99 ? green : red;
};
export const getTwoGreenPercentageColor = (percentage: number): string => {
  const lessThanHalfColor = '#BFE15E';
  const moreThanHalfColor = '#1FAC47';

  return percentage >= 50 ? moreThanHalfColor : lessThanHalfColor;
};

export const getPageHealthColor = val => {
  if (val< 401) {
    return '#f44343';
  } else if (val < 601) {
    return '#ff8536';
  } else if (val < 751) {
    return '#bfe15e';
  } else if (val < 1001) {
    return '#1fac47';
  }
};
export const getKdBgAndColor =value => {
  if (value>=0 && value<=10) {
    return '#1FAC47';
  } else if (value>=11 && value<=30) {
    return '#BFE15E';
  } else if (value>=31 && value<=70) {
    return '#FF8536';
  } else {
    return '#F44343';
  }
};

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'Good':
      return '#52D577';
    case 'Average':
      return '#FF8536';
    case 'Bad':
      return '#F44343';
    case 'Missing':
      return '#F44343';
    case 'Not Included':
      return '#A3A4A4';
    default:
      return '#ffffff';
  }
};


export const getHttpStatusColor = (status: number) => {
  if (status >= 100 && status < 200 ) {
    return '#34AEF3';
  } else if (status >= 200 && status < 300) {
    return '#1FAC47';
  } else if (status >= 300 && status < 400) {
    return '#F1AA3E';
  } else if (status >= 400 && status < 500) {
    return '#F44343';
  } else if (status >= 500) {
    return '#B62222';
  } else {
    return '#e5e5e5';
  }
};

export const dotColorCondition = value => {
  if (value > 0 && value < 11) {
    return '#0C872F';
  } else if (value > 10 && value < 21) {
    return '#1FAC47';
  } else if (value > 20 && value < 31) {
    return '#1FAC47';
  } else if (value > 30 && value < 41) {
    return '#BFE15E';
  } else if (value > 40 && value < 51) {
    return '#F1AA3E';
  } else if (value > 50 && value < 61) {
    return '#F1AA3E';
  } else if (value > 60 && value < 71) {
    return '#FF8536';
  } else if (value > 70 && value < 81) {
    return '#F44343';
  } else if (value > 80 && value < 91) {
    return '#F44343';
  } else if (value > 90 && value < 101) {
    return '#B62222';
  } else {
    return '#e5e5e5';
  }
};

export const getSearchIntentBgAndColor =searchIntent=>{
  switch (searchIntent) {
    case 'commercial':
      return {
        background: '#F1AA3E33',
        color: '#F1AA3E',
      };
    case 'navigational':
      return {
        background: '#B98CE433',
        color: '#B98CE4',
      };
    case 'informational':
      return {
        background: '#34AEF333',
        color: '#34AEF3',
      };
    case 'transactional':
      return {
        background: '#1FAC4733',
        color: '#1FAC47',
      };
    default:
      break;
  }
};

export const randomColors = ['#63B598', '#CE7D78', '#EA9E70', '#A48A9E', '#C6E1E8', '#648177', '#0D5AC1',
  '#F205E6', '#1C0365', '#14A9AD', '#4CA2F9', '#A4E43F', '#D298E2', '#6119D0',
  '#D2737D', '#C0A43C', '#F2510E', '#651BE6', '#79806E', '#61DA5E', '#CD2F00',
  '#9348AF', '#01AC53', '#C5A4FB', '#996635', '#B11573', '#4BB473', '#75D89E',
  '#2F3F94', '#2F7B99', '#DA967D', '#34891F', '#B0D87B', '#CA4751', '#7E50A8',
  '#C4D647', '#E0EEB8', '#11DEC1', '#289812', '#566CA0', '#FFDBE1', '#2F1179',
  '#935B6D', '#916988', '#513D98', '#AEAD3A', '#9E6D71', '#4B5BDC', '#0CD36D',
  '#250662', '#CB5BEA', '#228916', '#AC3E1B', '#DF514A', '#539397', '#880977',
  '#F697C1', '#BA96CE', '#679C9D', '#C6C42C', '#5D2C52', '#48B41B', '#E1CF3B',
  '#5BE4F0', '#57C4D8', '#A4D17A', '#225b8', '#BE608B', '#96B00C', '#088BAF',
  '#F158BF', '#E145BA', '#EE91E3', '#05D371', '#5426E0', '#4834D0', '#802234',
  '#6749E8', '#0971F0', '#8FB413', '#B2B4F0', '#C3C89D', '#C9A941', '#41D158',
  '#FB21A3', '#51AED9', '#5BB32D', '#807fb', '#21538E', '#89D534', '#D36647',
  '#7FB411', '#0023B8', '#3B8C2A', '#986B53', '#F50422', '#983F7A', '#EA24A3',
  '#79352C', '#521250', '#C79ED2', '#D6DD92', '#E33E52', '#B2BE57', '#FA06EC',
  '#1BB699', '#6B2E5F', '#64820F', '#1c271', '#21538E', '#89D534', '#D36647',
  '#7FB411', '#0023B8', '#3B8C2A', '#986B53', '#F50422', '#983F7A', '#EA24A3',
  '#79352C', '#521250', '#C79ED2', '#D6DD92', '#E33E52', '#B2BE57', '#FA06EC',
  '#1BB699', '#6B2E5F', '#64820F', '#1c271', '#9CB64A', '#996C48', '#9AB9B7',
  '#06E052', '#E3A481', '#0EB621', '#FC458E', '#B2DB15', '#AA226D', '#792ED8',
  '#73872A', '#520D3A', '#CEFCB8', '#A5B3D9', '#7D1D85', '#C4FD57', '#F1AE16',
  '#8FE22A', '#EF6E3C', '#243EEB', '#1dc18', '#DD93FD', '#3F8473', '#E7DBCE',
  '#421F79', '#7A3D93', '#635F6D', '#93F2D7', '#9B5C2A', '#15B9EE', '#0F5997',
  '#409188', '#911E20', '#1350CE', '#10E5B1', '#FFF4D7', '#CB2582', '#CE00BE',
  '#32D5D6', '#17232', '#608572', '#C79BC2', '#00F87C', '#77772A', '#6995BA',
  '#FC6B57', '#F07815', '#8FD883', '#060E27', '#96E591', '#21D52E', '#D00043',
  '#B47162', '#1EC227', '#4F0F6F', '#1D1D58', '#947002', '#BDE052', '#E08C56',
  '#28FCFD', '#bb09b', '#36486A', '#D02E29', '#1AE6DB', '#3E464C', '#A84A8F',
  '#911E7E', '#3F16D9', '#0F525F', '#AC7C0A', '#B4C086', '#C9D730', '#30CC49',
  '#3D6751', '#FB4C03', '#640FC1', '#62C03E', '#D3493A', '#88AA0B', '#406DF9',
  '#615AF0', '#4be47', '#2A3434', '#4A543F', '#79BCA0', '#A8B8D4', '#00EFD4',
  '#7AD236', '#7260D8', '#1DEAA7', '#06F43A', '#823C59', '#E3D94C', '#DC1C06',
  '#F53B2A', '#B46238', '#2DFFF6', '#A82B89', '#1A8011', '#436A9F', '#1A806A',
  '#4CF09D', '#C188A2', '#67EB4B', '#B308D3', '#FC7E41', '#AF3101', '#ff065',
  '#71B1F4', '#A2F8A5', '#E23DD0', '#D3486D', '#00F7F9', '#474893', '#3CEC35',
  '#1C65CB', '#5D1D0C', '#2D7D2A', '#FF3420', '#5CDD87', '#A259A4', '#E4AC44',
  '#1BEDE6', '#8798A4', '#D7790F', '#B2C24F', '#DE73C2', '#D70A9C', '#25b67',
  '#88E9B8', '#C2B0E2', '#86E98F', '#AE90E2', '#1A806B', '#436A9E', '#0EC0FF',
  '#F812B3', '#B17FC9', '#8D6C2F', '#D3277A', '#2CA1AE', '#9685EB', '#8A96C6',
  '#DBA2E6', '#76FC1B', '#608FA4', '#20F6BA', '#07D7F6', '#DCE77A', '#77ECCA'];

export const getInverseProgressColor = (value: number, min: number, average: number, max?: number) => {
  if (value <= min) {
    return '#52D477';
  }
  if (value <= average && value > min) {
    return '#BFE15E';
  }

  if (value > average) {
    if (max && value < max) {
      return '#FF8536';
    }
    return '#F44343';
  }
  return '#F44343';
};

export const getProgressColor = (value: number, min: number, average: number, max?: number) => {
  if (value <= min) {
    return '#F44343';
  }
  if (value <= average && value > min) {
    return '#FF8536';
  }
  if (value > average) {
    if (max && value < max) {
      return '#BFE15E';
    }
    return '#52D477';
  }
  return '#52D477';
};

export const getRankingPotentialColor = value => {
  switch (value) {
    case 'Very easy':
      return '#2AC155';
    case 'Easy':
      return '#2AC155';
    case 'Moderate':
      return '#BFE15E';
    case 'Moderately difficult':
      return '#FF8536';
    case 'Challenging':
      return '#F44343';
    case 'Extremely challenging':
      return '#B62222';
    case 'Not-Begun':
      return '#F44343';
    case 'In-Progress':
      return '#34AEF3';
    case 'Complete':
      return '#1FAC47';
    case 'Archive':
      return '#A3A4A4';
    default:
      return '';
  }
};

export const getSiteHealthColor = value => {
  switch (true) {
    case (value >= 0 && value <= 500):
      return '#F44343';
    case (value >= 501 && value <= 650):
      return '#FF8536';
    case (value >= 651 && value <= 850):
      return '#BFE15E';
    case (value >= 851 && value <= 1000):
      return '#1FAC47';
    default:
      return '';
  }
};

const opacityMap = {
  100: 'FF',
  99: 'FC',
  98: 'FA',
  97: 'F7',
  96: 'F5',
  95: 'F2',
  94: 'F0',
  93: 'ED',
  92: 'EB',
  91: 'E8',
  90: 'E6',
  89: 'E3',
  88: 'E0',
  87: 'DE',
  86: 'DB',
  85: 'D9',
  84: 'D6',
  83: 'D4',
  82: 'D1',
  81: 'CF',
  80: 'CC',
  79: 'C9',
  78: 'C7',
  77: 'C4',
  76: 'C2',
  75: 'BF',
  74: 'BD',
  73: 'BA',
  72: 'B8',
  71: 'B5',
  70: 'B3',
  69: 'B0',
  68: 'AD',
  67: 'AB',
  66: 'A8',
  65: 'A6',
  64: 'A3',
  63: 'A1',
  62: '9E',
  61: '9C',
  60: '99',
  59: '96',
  58: '94',
  57: '91',
  56: '8F',
  55: '8C',
  54: '8A',
  53: '87',
  52: '85',
  51: '82',
  50: '80',
  49: '7D',
  48: '7A',
  47: '78',
  46: '75',
  45: '73',
  44: '70',
  43: '6E',
  42: '6B',
  41: '69',
  40: '66',
  39: '63',
  38: '61',
  37: '5E',
  36: '5C',
  35: '59',
  34: '57',
  33: '54',
  32: '52',
  31: '4F',
  30: '4D',
  29: '4A',
  28: '47',
  27: '45',
  26: '42',
  25: '40',
  24: '3D',
  23: '3B',
  22: '38',
  21: '36',
  20: '33',
  19: '30',
  18: '2E',
  17: '2B',
  16: '29',
  15: '26',
  14: '24',
  13: '21',
  12: '1F',
  11: '1C',
  10: '1A',
  9: '17',
  8: '14',
  7: '12',
  6: '0F',
  5: '0D',
  4: '0A',
  3: '08',
  2: '05',
  1: '03',
  0: '00',
};

/**
 * Returns provided HEX color with added opacity
 *
 * @param {string} color -required- given HEX color
 * @param {number} opacity -optional- opaicty value between 1 and 100 (will be used as percent, but passed only as number)
 * @return {string} opaque hex color
 */
export const opaqueColor = (color: string, opacity?: number): string => {
  // if opacity is not provided or is 0 we only return color
  if (!opacity) return color;
  // if opacity is greter than 100 return color only
  if (opacity > 100) return color;
  // if given opacity value doesnt match any value in opacity map, return color only
  if (!opacityMap[opacity]) return color;

  return `${color}${opacityMap[opacity]}`;
};

export const convertToTransparent = (color: string, transparency: string | number) => {
  // Function to convert hex to RGB
  function hexToRgb(hex) {
    // Remove the leading #
    hex = hex.replace(/^#/, '');
    // Parse r, g, b values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return {r: r, g: g, b: b};
  }
  function parseRgbString(rgbString) {
    const result = rgbString.match(/\d+/g).map(Number);
    return {r: result[0], g: result[1], b: result[2]};
  }

  let r;
  let g;
  let b;

  if (!color) {
    throw new Error('Provide color string');
  } else if (typeof color !== 'string') {
    throw new Error('Color should mut be string');
  } else if (color?.startsWith('#')) {
    // It's a hex color
    const rgb = hexToRgb(color);
    r = rgb.r;
    g = rgb.g;
    b = rgb.b;
  } else if (color?.startsWith('rgb')) {
    // It's an RGB color
    const rgb = parseRgbString(color);
    r = rgb.r;
    g = rgb.g;
    b = rgb.b;
  }

  return `rgba(${r}, ${g}, ${b}, ${transparency})`;
};
