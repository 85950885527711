import {
  FocusTermModel,
  IFocusTermToClassNameMap,
} from '@/store/content-optimizer/currentPage';
import escapeRegExp from 'lodash/escapeRegExp';
import isString from 'lodash/isString';
import uniq from 'lodash/uniq';
import {multiWordBoundaryRegex, multiWordRegex} from '@/constants/regex';

export function isScaPublicPage() {
  return location.pathname.match(/\/content\/seo-content-assistant-public\/.*/g);
}

export function mapFocusTerms(
  focusTerms?: FocusTermModel[],
): IFocusTermToClassNameMap {
  const focusTermClassName: IFocusTermToClassNameMap = {};
  if (focusTerms) {
    const sortedFocusTerms = focusTerms
      .slice()
      .sort((a, b) => b.name.length - a.name.length);

    sortedFocusTerms.forEach(word => {
      const className = {
        ['included']: 'highlighted--green',
        ['include-less']: 'highlighted--gray',
        ['include-more']: 'highlighted--yellow',
        ['internal-link-suggestion']: 'highlighted--blue',
      }[word.status];
      const sorted = [word.name, ...(word?.variations ?? [])].sort(
        (a, b) => b.length - a.length,
      );

      sorted.forEach(n => (focusTermClassName[n] = className));
    });
  }

  return focusTermClassName;
}

const prepareFocusTermsSearchRegex = (terms: string[]): string => {
  return terms
    .map(escapeRegExp)
    .filter((term: any) => term.length > 0)
    .sort((a: any, b: any) => b.length - a.length)
    .map((x: any) => x.replace(/\s+/g, '[\\s\\-]'))
    .join('|');
};

const escapeWhitespace = (term: string): string =>
  term.replace(/-+/g, ' ').replace(/\s+/g, ' ').toLocaleLowerCase();

export const prepareFinalTerms = (term: FocusTermModel[]) =>
  uniq(
    term
      ?.flatMap(x => [x.name, ...(x?.variations ?? [])])
      .map(escapeWhitespace)
      .sort((a, b) => b.length - a.length),
  );

export const multiWordCountRegex = (terms: string[], wordBoundary: boolean = true): RegExp => {
  const normalizedTerms = prepareFocusTermsSearchRegex(terms);

  if (wordBoundary) {
    return multiWordBoundaryRegex(normalizedTerms);
  }
  return multiWordRegex(normalizedTerms);
};

function formatPrice(
  price: number,
  options?: Intl.NumberFormatOptions,
): string {
  const finalOptions = options == null ? {} : options;
  finalOptions.style = 'currency';
  finalOptions.currency = 'USD';
  finalOptions.minimumFractionDigits = 0;
  return price.toLocaleString('en', finalOptions);
}

export const searchVolumeFormatter = (sv?: number | string): string => {
  if (sv || sv === 0) {
    if (sv < 10) {
      return '<10';
    } else {
      const finalNumber = isString(sv) ? parseFloat(sv as string) : sv;
      const steps = [
        {limit: 1000, suffix: '', divider: 1},
        {limit: 1000000 - 500, suffix: 'K', divider: 1000},
        {limit: 1000000000 - 50000, suffix: 'M', divider: 1000000},
        {limit: 1000000000000, suffix: 'B', divider: 1000000000},
      ];

      let finalStep = steps.find(x => sv < x.limit);
      if (finalStep == null) {
        finalStep = steps[-1];
      }

      const finalValue =
        Math.round((finalNumber as any * 10) / finalStep.divider) / 10;
      return `${finalValue}${finalStep.suffix}`;
    }
  }
  return '-';
};

export const costPerClickFormatter = (cpc?: number) => {
  if (cpc || cpc === 0) {
    if (cpc < 10) {
      return formatPrice(cpc);
    } else {
      return formatPrice(Math.round(cpc));
    }
  }
  return '-';
};
