import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {Col, Row, Checkbox, Dropdown, Menu, Radio, Tooltip} from 'antd';
import {notification} from '@/utils/notifications';
import {useStore} from '@/store/root-store';
import {convertToHierarchy} from '@/utils/scripts';
import {addProtocolToDomain, getSingleUrlParam} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {faGear, faSortDown, faTrashXmark} from '@fortawesome/pro-solid-svg-icons';
import {faBoxArchive, faDiagramSankey, faTableCells} from '@fortawesome/pro-duotone-svg-icons';
import {toJS} from 'mobx';
import {BackButton, DeleteButton, DropdownButtonWrapper, ModalWrapper} from '../../../style';
import {timeNotification} from '@/utils/notification-timer';
import FreezeWrapper from '../../freezTooltip';
import {TopicalMapModalGraphContent} from './topicalMapModalGraphContent';
import {TopicalMapModalTableContent} from './topicalMapModalTableContent';
import {observer} from 'mobx-react-lite';
import {FreezeBanner} from '../../ottoHeaderV2';
import {handleOnChangeFilterTab, isNodeSelected} from '../../../Utils/helper-func';
import {titleCase} from '@/utils/string';
import {FiltersV2} from '@/components/common-components/v2/filtersV2';
import {OTTOTopicalModalTableFilterEnum, OTTOTopicalModalTableFilterList, PRO_PLAN_ID} from '@/constants';
import {ExportButton} from '../../../Utils/exportButton';
import {notification as notificationV2} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {useRouter} from 'next/router';
import {PAGES_API} from '@/api/content-optimizer';

interface TopicalMapModalContentProps {
  openTopicalMapModal?: boolean;
  setOpenTopicalMapModal?: Dispatch<SetStateAction<boolean>>;
  topicalMapName?: string;
  componentIssueType: string;
  activeCategories: any[];
  setActiveCategories: Dispatch<SetStateAction<any[]>>;
  categoriesOptions: any[];
  setCategoriesOptions: Dispatch<SetStateAction<any[]>>;
  isInContentTopicMap?: boolean;
  topicId?: number;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}
const getCorrectUrl = (url, domain) => {
  const regex = /^(https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)$/;
  if (!url) {
    return addProtocolToDomain(domain);
  } else if (regex.test(url)) {
    return url;
  } else if (url[0] === '/') {
    return `${addProtocolToDomain(domain)}${url}`;
  } else {
    return `${addProtocolToDomain(domain)}/${url}`;
  }
};

let myChart;
const radioButtonsOptions = [
  {
    label: 'Diagram',
    desc: 'Visual summary',
    value: 'diagram',
    icon: color => <FontAwesomeIcon icon={faDiagramSankey} color={color} fontSize={20}/>,
  },
  {
    label: 'Table',
    desc: 'Detailed breakdown',
    value: 'table',
    icon: color => <FontAwesomeIcon icon={faTableCells} color={color} fontSize={20} />,
  },
];
const filterRadioButtons = [
  {
    label: 'All Ideas',
    value: 'all-ideas',
  },
  {
    label: 'Content Created',
    value: 'created',
  },
  {
    label: 'Published',
    value: 'published',
  },
];

const filterRadioButtonsCA = [
  {
    label: 'All Ideas',
    value: 'all-ideas',
  },
  {
    label: 'Topic map',
    value: 'selected',
  },
];

const tableDataKeyMapping = {
  'published': 'wpPublishedAtUrl',
  'created': 'pageUuid',
  'selected': 'isSelected',
};

export const flattenChildren = data => {
  if (!data || !Array.isArray(data)) {
    return [];
  }
  return data.flatMap(item => {
    if (item.children && item.children.length > 0) {
      return flattenChildren(item.children);
    } else {
      return item;
    }
  });
};

const defaultCounty = {
  countryCode: 'US',
  location: 'United States',
  locationId: 2840,
};

export const TopicalMapModalContent: React.FC<TopicalMapModalContentProps> = observer((
  {openTopicalMapModal,
    setOpenTopicalMapModal,
    topicalMapName,
    componentIssueType,
    activeCategories,
    setActiveCategories,
    categoriesOptions,
    setCategoriesOptions,
    isInContentTopicMap,
    topicId,
    selectedTab,
    setSelectedTab,
  }) => {
  const {ottoV2Store: {storeSelectedTitles, createTopicalMapBulkArticles, topicalMapUpdateArticle, loadIssueTableData, ottoUrls, deleteOttoTopicalMap, deletingTopicalMap, getOttoV2Project},
    contentOptimizer: {
      pages: {content, setActiveProjectId, incrementInTotalArticle},
      aiOutline: {setAiWriter, aiWriterController, setIsEdit, setOpenUpdateProjectModal, setTopUpBulkVisibility},
      wpPublish: {fetchWpPublishData},
      contentIdeas: {getTopicalMapData, getSingleTopicalMapData, loadSingleTopicMapData},
    },
    settings: {customer: {profile: {plan}, getCustomerQuota}},
  } = useStore('');
  const router = useRouter();
  const wpContainerRef = useRef(null);
  const dataList = getSingleTopicalMapData ? [{...getSingleTopicalMapData}]: [];
  const [dataLoader, setDataLoader] = useState(true);
  const [clickedValue, setClickedValue] = useState<any>(0);
  const [errorId, setErrorId] = useState([]);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(defaultCounty);
  const [topicalMapDataState, setTopicalMapDataState] = useState<any>();
  const [topicalMapDataList, setTopicalMapDataList] = useState<any>([]);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [tableData, setTableData] = useState('all-ideas');
  const [openCategoryDropdown, setOpenCategoryDropdown] = useState(false);
  const [openSearchIntentDropdown, setOpenSearchIntentDropdown] = useState(false);
  const [searchIntentList, setSearchIntentList] = useState([]);
  const [selectedSearchIntent, setSelectedSearchIntent] = useState([]);
  const [zoom, setZoom] = useState(1);
  const uuid = getSingleUrlParam('uuid');
  const mode = getSingleUrlParam('mode');
  const data = dataList ?? [];
  // const getOttoUrlsData = getOttoUrls?.find(ottoUrl => ottoUrl.issueType === 'topical_maps_and_supplemental_content')?.issueTable?.results;
  const topicalMap = getSingleTopicalMapData;
  const ottoProject = (typeof topicalMap?.ottoProject === 'string') && topicalMap?.ottoProject?.split('.com');
  const pageUrl = getCorrectUrl(topicalMap?.contentPage, ottoProject?.length ? ottoProject[0] + '.com' : '');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletingTopicalMapId, setDeletingTopicalMapId] = useState(null);
  const [bulkArticlesRepolling, setBulkArticlesRepolling] = useState(false);
  const [topicalMapArticle, setTopicalMapArticle] = useState([]);
  const [selectedArticlesToGenerate, setSelectedArticlesToGenerate] = useState([]);
  const [generatingBulkArticles, setGeneratingBulkArticles] = useState(false);
  const [activatedFilters, setActivatedFilters] = useState(OTTOTopicalModalTableFilterList);
  const [keysWithChildren, setKeysWithChildren] = useState([]);
  const [addingIdeaInTopicMap, setAddingIdeaInTopicMap] = useState(false);
  const [triggerZoom, setTriggerZoom] = useState(false);

  const initialTopicMapDataList = topicalMapDataState?.children?.map((category, index) => ({
    name: category.name,
    key: index + 1,
    children: category?.children,
  }));

  const topicMapDataList = tableData === 'all-ideas' ? initialTopicMapDataList: handleOnChangeFilterTab(tableDataKeyMapping[tableData], initialTopicMapDataList);

  const dependency = getSingleTopicalMapData;
  const inProgress = Array.isArray(data) ? data?.some(topicMap =>
    Array.isArray(topicMap?.formattedData) && topicMap?.formattedData?.some((data: any) =>
      data?.keywords?.some(keyword =>
        keyword?.titles?.some(title => !title?.pageUuid && title?.aiGenerationStatus === 'PENDING'),
      ),
    ),
  ): false;
  const flattenTopicalMapDataList = flattenChildren(topicalMapDataList?.children);
  const flattenTopicalMapDataState = flattenChildren(topicalMapDataState?.children);
  const allIdeasCondition = flattenTopicalMapDataList?.length > flattenTopicalMapDataState?.length;
  const tableDataMapping = {
    'all-ideas': flattenTopicalMapDataState?.length,
    'published': flattenTopicalMapDataList?.filter(item => item?.wpPublishedAtUrl)?.length,
    'created': flattenTopicalMapDataList?.filter(item => item?.pageUuid)?.length,
    'selected': flattenTopicalMapDataList?.filter(item => item?.isSelected)?.length,
  };
  const allCategoriesActive = categoriesOptions.every(category => activeCategories.includes(category?.value));

  useEffect(() => {
    if (openTopicalMapModal || topicId || topicalMapName) {
      if (activeCategories?.length) {
        setActiveCategories(activeCategories);
      } else if (categoriesOptions?.length) {
        setActiveCategories(categoriesOptions?.map(item => item?.value));
      }
    }
  }, [openTopicalMapModal, categoriesOptions, topicId, topicalMapName]);

  useEffect(() => {
    if (dataLoader) {
      getTreeData();
      if (!inProgress) {
        setBulkArticlesRepolling(false);
        setSelectedArticlesToGenerate([]);
      }
    } else if (allCategoriesActive) {
      if (!activatedFilters?.filter(item => item?.active).length) {
        setTopicalMapDataState(topicalMapDataList);
      }
    } else {
      if (topicalMapDataList?.children) {
        setTopicalMapDataState({});
        const data = topicalMapDataList?.children?.filter(child => activeCategories.includes(child?.name));
        if (data?.length) {
          const mapData = {name: topicalMapDataList?.name, children: data};
          setTopicalMapDataState(mapData);
        }
      }
    }
  }, [JSON.stringify(dependency), activeCategories, topicalMapDataList]);

  useEffect(() => {
    if (inProgress || bulkArticlesRepolling) {
      getTreeData();
      setBulkArticlesRepolling(true);
      if (generatingBulkArticles) {
        setGeneratingBulkArticles(false);
        setSelectedArticlesToGenerate([]);
      }
      if (!inProgress) {
        setBulkArticlesRepolling(false);
      }
    }
    const updatedData = topicalMapArticle?.map(item => {
      const contentData = content?.find(data => data?.uuid === item?.pageUuid);
      if (contentData) {
        return {
          ...item,
          isLoading: contentData?.aiInProgress,
        };
      }
      return item;
    });
    setTopicalMapArticle(updatedData);
  }, [JSON.stringify(dependency), JSON.stringify(content)]);

  useEffect(() => {
    const initialTopicMapDataList = topicalMapDataState?.children?.map((category, index) => ({
      name: category.name,
      key: index + 1,
      children: category?.children,
    }));
    const topicMapDataList = tableData === 'all-ideas' ? initialTopicMapDataList: handleOnChangeFilterTab(tableDataKeyMapping[tableData], initialTopicMapDataList);
    setKeysWithChildren(topicMapDataList?.filter(item => item.children.length > 0).map(item => item.key) || []);
  }, [topicalMapDataState]);

  useEffect(() => {
    if (addingIdeaInTopicMap) {
      updateSelectedTitles();
      setAddingIdeaInTopicMap(false);
    }
  }, [addingIdeaInTopicMap]);

  const deleteTopicalMap = async () => {
    try {
      const onDelayEnd = async () => {
        const filter = ottoUrls?.filter(d => d.issueType === 'topical_maps_and_supplemental_content');
        await deleteOttoTopicalMap(deletingTopicalMapId);
        const params = {
          uuid,
          issue_type: 'topical_maps_and_supplemental_content',
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          is_loading: false,
        };
        setOpenDeleteModal(false);
        setDeletingTopicalMapId(null);
        setOpenTopicalMapModal(false);
        if (isInContentTopicMap) {
          await getTopicalMapData();
        } else {
          await loadIssueTableData(params);
        }
      };
      setOpenDeleteModal(false);
      const handleUndo = () => {
        setOpenDeleteModal(false);
        setDeletingTopicalMapId(null);
      };
      timeNotification('#FF0000', faTrashXmark, 4, `Issue deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
    } catch (error) {
      setOpenDeleteModal(false);
      setDeletingTopicalMapId(null);
      notification.error('Something went wrong, please try again', '', 2);
    }
  };

  const func = (val = null) => {
    if (!val) {
      getTreeData();
    }
    if (allCategoriesActive) {
      setTopicalMapDataList(val);
      setTopicalMapDataState(val);
    } else {
      const clone = topicalMapDataList;
      for (let index = 0; index < clone?.children?.length; index++) {
        if (val?.children?.some(child => child?.name === clone?.children[index]?.name)) {
          clone.children[index] = val?.children?.find(child => child?.name === clone?.children[index]?.name);
        }
      }

      setTopicalMapDataList(clone);
      setTopicalMapDataState(val);
    }
  };

  const UnSelectedTooltipContent = ({value}) => (
    <UnSelectedTooltipContentContainer>
      <div>
        Save the idea to your Topic Map to enable AI generation.
      </div>
      <div style={{color: '#34AEF3', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setClickedValue(value)}>
        + Add to Topic Map
      </div>
    </UnSelectedTooltipContentContainer>
  );

  const updateChildrenMethod = (originalChildren, updateChildren) => {
    if (updateChildren?.children && originalChildren?.children) {
      originalChildren.children = originalChildren.children.filter(originalChild =>
        updateChildren.children.some(updateChild => updateChild.value === originalChild.value),
      );
      updateChildren.children.forEach(updateChild => {
        const originalChild = originalChildren.children.find(item => item.value === updateChild.value);
        if (originalChild?.name) {
          updateChildrenMethod(originalChild, updateChild);
        }
      });
    } else if (Object.prototype.hasOwnProperty.call(updateChildren, 'aiGenerationStatus')) {
      Object.assign(originalChildren, {
        ...updateChildren,
        isSelected: originalChildren?.isSelected,
        itemStyle: originalChildren?.itemStyle,
        lineStyle: originalChildren?.lineStyle,
        symbol: originalChildren?.symbol,
      });
    }
  };

  const updateAiGenerationStatus = (data, updateData) => {
    data.children = data.children.filter(dataChild =>
      updateData.children.some(updateChild => updateChild.value === dataChild.value),
    );
    updateData.children.forEach(updateChild => {
      const dataChild = data.children.find(item => item.value === updateChild.value);
      if (dataChild?.name) {
        updateChildrenMethod(dataChild, updateChild);
      }
    });
    return data;
  };

  const handleResetZoom = () => {
    setTriggerZoom(prev => !prev);
  };

  const handleSettingModalOpen = async (record, id) => {
    fetchWpPublishData(false);
    setOpenUpdateProjectModal(true);
    setIsEdit(true);
    setAiWriter({
      ...toJS(aiWriterController ?? []),
      templateUsed: 'complete-seo-article',
    });
    if (id) setActiveProjectId(id);
  };

  const getTreeData = async () => {
    setDataLoader(true);
    try {
      if (dependency) {
        let mapData = convertToHierarchy(topicalMap, isInContentTopicMap);
        if (topicalMap?.contentTopic && mapData) {
          if (topicalMapDataList?.children?.length && (inProgress || bulkArticlesRepolling)) {
            const updatedData = updateAiGenerationStatus(topicalMapDataList, mapData);
            mapData = updatedData;
          }
          if (!allCategoriesActive) {
            setTopicalMapDataState({});
            const data = mapData?.children?.filter(child => activeCategories.includes(child?.name));
            const filteredMapData = {name: mapData?.name, children: data};
            setTopicalMapDataState(filteredMapData);
            setTopicalMapDataList(mapData);
          } else {
            setTopicalMapDataState(mapData);
            setTopicalMapDataList(mapData);
          }
          const searchIntentArr = [];
          if (mapData?.children) {
            for (let i = 0; i < mapData.children.length; i++) {
              for (let j = 0; j < mapData.children[i].children.length; j++) {
                if (mapData.children[i].children[j].children?.length) {
                  for (let k = 0; k < mapData.children[i].children[j]?.children.length; k++) {
                    for (let l = 0; l < mapData.children[i].children[j].children[k].searchIntent.length; l++) {
                      if (!searchIntentArr?.map(item => item.toLowerCase())?.includes(mapData.children[i].children[j].children[k].searchIntent[l].toLowerCase())) {
                        searchIntentArr.push(titleCase(mapData.children[i].children[j].children[k].searchIntent[l].toLowerCase()));
                      }
                    }
                  }
                } else {
                  for (let l = 0; l < mapData.children[i].children[j].searchIntent.length; l++) {
                    if (!searchIntentArr?.map(item => item.toLowerCase())?.includes(mapData.children[i].children[j].searchIntent[l].toLowerCase())) {
                      searchIntentArr.push(titleCase(mapData.children[i].children[j].searchIntent[l].toLowerCase()));
                    }
                  }
                }
              }
            }
            setSearchIntentList(searchIntentArr);
            setSelectedSearchIntent(searchIntentArr);
          }
        }
        if ((!inProgress && !bulkArticlesRepolling || !categoriesOptions.length) && topicalMap && Array.isArray(topicalMap?.formattedData)) {
          const category = topicalMap?.formattedData?.filter((data: any) => data?.keywords?.length).map((map: any) => ({
            label: map?.name,
            value: map?.name,
          }));
          setCategoriesOptions(category);
        }
      }
      setDataLoader(false);
    } catch (error) {
      setDataLoader(false);
      notification.error('Error while getting tree data', '', 2);
    }
  };

  const onChangeCategory = (value: string) => {
    setTableData('all-ideas');
    setActivatedFilters(OTTOTopicalModalTableFilterList);
    setActiveCategories(prev =>activeCategories.includes(value) ? prev?.filter(item => item !== value) : [...prev, value]);
  };

  const handleOnChangeSearch = (titleQuery: string, keywordQuery: string) => {
    setActiveCategories(categoriesOptions?.map(item => item?.value));
    setSelectedSearchIntent(searchIntentList);
    setTableData('all-ideas');
    const filterChildren = node => {
      const filteredChildren = node.children?.map(child => {
        const filteredChild = filterChildren(child);
        if (filteredChild.children?.length) {
          const matchingChildren = filteredChild.children.filter(grandchild =>{
            const matchesPillar = titleQuery ? grandchild.titleName?.toLowerCase().includes(titleQuery?.toLowerCase().trim()) : true;
            const matchesKeyword = keywordQuery ? grandchild.name?.toLowerCase().includes(keywordQuery?.toLowerCase().trim()) : true;
            return matchesPillar && matchesKeyword;
          });
          if (matchingChildren.length) {
            return {...filteredChild, children: matchingChildren};
          }
        } else if (
          filteredChild.titleName?.toLowerCase().includes(titleQuery?.toLowerCase().trim()) &&
          filteredChild.name?.toLowerCase().includes(keywordQuery?.toLowerCase().trim())
        ) {
          return filteredChild;
        }
        return null;
      }).filter(Boolean);
      return {...node, children: filteredChildren};
    };
    const filteredTopicalMapData = {
      ...topicalMapDataList,
      children: topicalMapDataList.children
        .map(filterChildren)
        .filter(node => node.children?.length),
    };
    setTopicalMapDataState(filteredTopicalMapData);
    if (myChart) {
      myChart.dispatchAction({
        type: 'restore',
      });
    }
  };

  const onChangeSearchIntentValue = value => {
    handleResetZoom();
    setActivatedFilters(OTTOTopicalModalTableFilterList);
    let selectedValues = selectedSearchIntent?.map(si => si?.toLowerCase());

    if (selectedSearchIntent.includes(value)) {
      const updatedItems = selectedSearchIntent?.filter(item => item !== value);
      setSelectedSearchIntent(updatedItems);
      selectedValues = updatedItems?.map(si => si?.toLowerCase());
    } else {
      setSelectedSearchIntent([...selectedSearchIntent, value]);
      selectedValues = [...selectedSearchIntent, value]?.map(si => si?.toLowerCase());
    }

    // Helper function to recursively filter nested children
    const filterChildrenBySearchIntent = node => {
      const filteredChildren = node.children?.map(child => {
        const filteredChild = filterChildrenBySearchIntent(child);

        if (filteredChild.children?.length) {
          const matchingChildren = filteredChild.children.filter(grandchild =>
            grandchild.searchIntent?.some(intent => selectedValues.includes(intent?.toLowerCase())),
          );

          if (matchingChildren.length) {
            return {...filteredChild, children: matchingChildren};
          }
        } else if (
          filteredChild.searchIntent?.some(intent => selectedValues.includes(intent?.toLowerCase()))
        ) {
          return filteredChild;
        }

        return null;
      }).filter(Boolean);

      return {...node, children: filteredChildren};
    };

    // Apply the recursive filtering to the entire data structure
    const filteredTopicalMapData = {
      ...topicalMapDataList,
      children: topicalMapDataList.children
        .map(filterChildrenBySearchIntent)
        .filter(node => node.children?.length),
    };

    // Update the state with the filtered data
    setTopicalMapDataState(filteredTopicalMapData);
    handleResetZoom();
  };

  const onChangeCategoryVisibility = (value: boolean) => {
    setOpenCategoryDropdown(value);
  };

  const onChangeSearchIntentVisibility = (value: boolean) => {
    setOpenSearchIntentDropdown(value);
  };

  const updateSelectedTitles = async () => {
    const mapListData = convertToHierarchy(topicalMap, isInContentTopicMap);
    const fullList = flattenTopicalMapDataList?.filter(data => data?.isSelected === true ) ?? [];
    const initialList = flattenChildren(mapListData?.children)?.filter(data => data?.isSelected === true ) ?? [];
    setUpdatingStatus(true);
    try {
      const uuidArr = [];
      for (let index = 0; index < fullList.length; index++) {
        if (fullList[index]?.titleUuid) {
          uuidArr.push(fullList[index]?.titleUuid);
        }
      }
      await storeSelectedTitles({id: topicalMap?.id, uuids: uuidArr});
      const params = {
        uuid,
        issue_type: 'topical_maps_and_supplemental_content',
        page_size: 10,
        page: 1,
        is_loading: false,
      };
      if (isInContentTopicMap) {
        notification.success(`Idea ${fullList > initialList ? 'saved to': 'removed from'} Topic Map`, '', 2);
        setUpdatingStatus(false);
        await loadSingleTopicMapData(Number(router.query['topicId']), true);
      } else {
        await loadIssueTableData(params);
        notification.success('Updated', 'Selected titles status updated', 2);
        setUpdatingStatus(false);
      }
      if (openTopicalMapModal) {
        setOpenTopicalMapModal(false);
      }
    } catch (error) {
      setUpdatingStatus(false);
      if (openTopicalMapModal) {
        setOpenTopicalMapModal(false);
      }
      notification.error('Something went wrong, please try again', '', 2);
    }
  };

  const onFiltersChange = filters => {
    setActivatedFilters(filters);
    const activeFilters = filters?.filter(item => item?.active);
    if (activeFilters?.length) {
      const titleQuery = activeFilters.find(item => item?.header === OTTOTopicalModalTableFilterEnum.title)?.query?.toLowerCase() || '';
      const keywordQuery = activeFilters.find(item => item?.header === OTTOTopicalModalTableFilterEnum.Keyword)?.query?.toLowerCase() || '';
      handleOnChangeSearch(titleQuery, keywordQuery);
    } else {
      handleResetZoom();
      handleOnChangeSearch('', '');
    }
    handleResetZoom();
  };

  const groupById = data => {
    const result = {};
    data.forEach(item => {
      if (!result[item.id]) {
        result[item.id] = [];
      }
      result[item.id].push(item.titleUuid);
    });
    return result;
  };

  const onGenerateArticle = async (articles: any[], singleArticle?: boolean) => {
    setGeneratingBulkArticles(true);
    try {
      const articleGroup = groupById(articles);
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      for (let index = 0; index < Object.keys(articleGroup).length; index++) {
        const key = Object.keys(articleGroup)[index];
        const uuids = articleGroup[key];
        const payload = {
          'uuids': uuids,
        };
        await createTopicalMapBulkArticles(key, payload, singleArticle);
      }
      setDataLoader(true);
      const params = {
        uuid,
        issue_type: componentIssueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
      };
      setDeleteModalData(null);
      if (isInContentTopicMap) {
        await loadSingleTopicMapData(Number(router.query['topicId']), true);
      } else {
        await loadSingleTopicMapData(Number(topicId), true);
        await loadIssueTableData(params, false, true);
      }
      await getCustomerQuota();
      incrementInTotalArticle();
      notificationV2.success('Success', `Article${singleArticle ? '' : 's'} created successfully.`);
    } catch (e) {
      setGeneratingBulkArticles(false);
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error(`Could not generate ${singleArticle ? 'article' : 'bulk articles'}`, e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notificationV2.error('', errorMessage);
      }
    }
  };

  const updateTopicalMapArticle = async articleData => {
    const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
    const payload = {
      id: articleData?.id,
      titleUuid: articleData?.titleUuid,
      pageUuid: articleData?.pageUuid,
    };
    await topicalMapUpdateArticle(payload);
    const params = {
      uuid,
      issue_type: componentIssueType,
      page_size: filter?.length ? filter[0].pageSize : 10,
      page: filter?.length ? filter[0].page : 1,
      is_loading: false,
    };
    if (isInContentTopicMap) {
      await loadSingleTopicMapData(Number(router.query['topicId']), true);
    } else {
      await loadSingleTopicMapData(Number(topicId), true);
      await loadIssueTableData(params, false, true);
    }
  };

  const onOutline = async (child: any) => {
    const alreadyExist = topicalMapArticle.some(i => i?.titleUuid === child?.titleUuid);
    if (!alreadyExist) {
      const newArticle = {
        id: child?.id,
        titleUuid: child?.titleUuid,
        title: child?.name,
        isLoading: true,
        pageUuid: '',
      };
      setTopicalMapArticle(prev => [...prev, newArticle]);
    } else {
      setTopicalMapArticle(prev => prev.map(i => {
        if (i?.title === child?.name) {
          return {...i, isLoading: true};
        }
        return i;
      }));
    }
    const payload = {
      ...{
        'should_generate_outline': true,
        'keywords_list': [child?.name],
        'location': 'United States',
        'location_id': 2840,
        'title': child?.titleName,
      },
      ...(topicalMap?.project && {'project_id': topicalMap?.project}),
      ...(selectedCountry?.location && {location: selectedCountry?.location}),
      ...(selectedCountry?.locationId && {location_id: selectedCountry?.locationId}),
    };

    const topicalMapArticleDataParam = [...topicalMapArticle, {
      id: child?.id,
      titleUuid: child?.titleUuid,
      title: child?.name,
      isLoading: true,
      pageUuid: '',
    }];
    try {
      const res = await PAGES_API.generateOutlineWithoutContent(payload);
      setTimeout( async ()=> {
        if (res?.uuid) {
          getCustomerQuota();
          incrementInTotalArticle();
          const selectedArticle = topicalMapArticleDataParam?.find(i => i?.titleUuid === child?.titleUuid);
          const articleData = {...selectedArticle, pageUuid: res?.uuid};
          if (articleData?.pageUuid) {
            await updateTopicalMapArticle(articleData);
            setTopicalMapArticle(prev => {
              const updatedData = prev.filter(i => {
                if (i?.titleUuid === child?.titleUuid) {
                  return false;
                }
                return true;
              });
              return updatedData;
            });
            setDataLoader(true);
            notificationV2.success('Success', 'Outline generated successfully.');
          }
        }
      }, 1000);
    } catch (e) {
      setTopicalMapArticle(prev => {
        const updatedData = prev.filter(i => {
          if (i?.titleUuid === child?.titleUuid) {
            return false;
          }
          return true;
        });
        return updatedData;
      });
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error('Could not generate Outline', e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notificationV2.error('', errorMessage);
      }
    }
  };

  const searchIntentMenu = (
    <StyledCategoryMenu selectedKeys={selectedSearchIntent}>
      {searchIntentList?.map(searchIntent => (
        <Menu.Item key={searchIntent} onClick={() => onChangeSearchIntentValue(searchIntent)}>
          <Checkbox checked={selectedSearchIntent.includes(searchIntent)} onChange={() => onChangeSearchIntentValue(searchIntent)}/> {searchIntent}
        </Menu.Item>
      ))}
    </StyledCategoryMenu>
  );

  const categoryMenu = (
    <StyledCategoryMenu selectedKeys={activeCategories}>
      {categoriesOptions?.map(category => (
        <Menu.Item onClick={e => onChangeCategory(e?.key)} key={category?.value}>
          <Checkbox checked={activeCategories.includes(category?.value)} onChange={() => onChangeCategory(category?.value)}/> {category?.label}
        </Menu.Item>))}
    </StyledCategoryMenu>
  );

  const settingAndDelete = (
    <div style={{display: 'flex', alignItems: 'center', gap: '18px'}}>
      {topicalMapName ? <PillarPage>Seed keyword: <div className='keyword'>{topicalMapName}</div></PillarPage> : <></>}
      <Tooltip title={!topicalMapDataState?.children?.length && 'No idea available for AI Settings'}>
        <AiSettingButton style={{cursor: !topicalMapDataState?.children?.length ? 'not-allowed' : ''}} onClick={()=> topicalMapDataState?.children?.length && handleSettingModalOpen(topicalMap, topicalMap?.project)}><FontAwesomeIcon icon={faGear} color='#4E5156' fontSize={16} />AI Settings</AiSettingButton>
      </Tooltip>
      <FreezeWrapper>
        <AiSettingButton onClick={() => {
          setDeletingTopicalMapId(topicalMap?.id);
          setOpenDeleteModal(true);
        }}>
          <FontAwesomeIcon icon={faTrash} fontSize={16} color='#4E5156'/>
        </AiSettingButton>
      </FreezeWrapper>
    </div>
  );

  const searchInput = (
    <FiltersV2
      mainFilters={activatedFilters ? activatedFilters : OTTOTopicalModalTableFilterList}
      hideTopSection={true}
      onChange={onFiltersChange}
      noMargin={true}
      width={'max-content'}
      maxSelectedTextWidth={'80px'}
    />
  );

  const radioButton = isInContentTopicMap ? filterRadioButtonsCA : filterRadioButtons;
  const tableDataFilter = (
    <FiltersRadioGroup value={tableData} onChange={e => {
      handleOnChangeSearch('', '');
      setTableData(e.target.value);
    }}>
      {radioButton.map((option, index) => (
        <Radio.Button value={option.value} key={index}>
          <div>{option.label === 'All Ideas'? allIdeasCondition ? `Idea${flattenTopicalMapDataState?.length > 1 ? 's' : ''}` : option.label : option.label}({tableDataMapping[option.value]})</div>
        </Radio.Button>
      ))}
    </FiltersRadioGroup>
  );

  const searchIntentDropdown = (
    <Dropdown
      overlay={searchIntentMenu}
      trigger={['click']}
      getPopupContainer={node => node}
      placement='bottomLeft'
      visible={openSearchIntentDropdown}
      onVisibleChange={value => onChangeSearchIntentVisibility(value)}
    >
      <Tooltip title={searchIntentList?.length !== selectedSearchIntent?.length ? selectedSearchIntent.join(', ') || 'All Categories are unselected' : ''}>
        <DropdownButtonWrapper filterIsActive={searchIntentList?.length !== selectedSearchIntent?.length}>
          <span className='container'>
            {searchIntentList?.length !== selectedSearchIntent?.length ?
              <>
                SI:&nbsp;
                <b>
                  {
                    selectedSearchIntent.join(', ') || 'All Categories are unselected'
                  }
                </b>
              </>: 'Search Intent'
            }
          </span>
          <FontAwesomeIcon icon={faSortDown} color={searchIntentList?.length !== selectedSearchIntent?.length ? '#000000': '#A3A4A4'} fontSize={14} style={{marginLeft: '5px'}} />
        </DropdownButtonWrapper>
      </Tooltip>
    </Dropdown>
  );

  const categoryIntentDropdown = (
    <Dropdown
      overlay={categoryMenu}
      trigger={['click']}
      getPopupContainer={node => node}
      placement='bottomLeft'
      visible={openCategoryDropdown}
      onVisibleChange={value => onChangeCategoryVisibility(value)}
    >
      <Tooltip title={categoriesOptions?.length !== activeCategories?.length ? activeCategories.join(', ') || 'All Categories are unselected' : ''}>
        <DropdownButtonWrapper filterIsActive={categoriesOptions?.length !== activeCategories?.length}>
          <span className='container'>
            {categoriesOptions?.length !== activeCategories?.length ?
              <>
                Category:&nbsp;
                <b>
                  {
                    activeCategories.join(', ') || 'All Categories are unselected'
                  }
                </b>
              </>: 'Category'
            }
          </span>
          <FontAwesomeIcon icon={faSortDown} color={categoriesOptions?.length !== activeCategories?.length ? '#000000': '#A3A4A4'} fontSize={14} style={{marginLeft: '5px'}} />
        </DropdownButtonWrapper>
      </Tooltip>
    </Dropdown>
  );

  const header = (
    <StyledModalHeader style={{alignItems: 'inherit'}} isInContentTopicMap={isInContentTopicMap}>
      <Col span={12} xl={isInContentTopicMap ? 16 : 17}>
        <Row>
          <Col span={24} className='modal-heading' style={{display: isInContentTopicMap && 'flex', alignItems: isInContentTopicMap && 'center', gap: isInContentTopicMap && '10px', marginBottom: isInContentTopicMap && '10px', flexWrap: isInContentTopicMap ? 'wrap' : 'nowrap'}}>
            {isInContentTopicMap ?
              selectedTab === 'table' ? (
                <>
                  {mode && mode === 'edit' && tableDataFilter}
                </>
              ) : <>
                <FiltersDropdownWrapper>
                  {searchIntentDropdown}
                </FiltersDropdownWrapper>
                {searchInput}
              </> : 'Edit Topical Map'}
          </Col>
          {isInContentTopicMap && selectedTab === 'table' && (<Col span={24} style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <FiltersDropdownWrapper>
              {categoryIntentDropdown}
              {searchIntentDropdown}
              {searchInput}
            </FiltersDropdownWrapper>
          </Col>)}
        </Row>
        {isInContentTopicMap ? '' : (<>
          <Row style={{display: 'flex', alignItems: 'center', gap: isInContentTopicMap ? '6px': '12px'}} gutter={!isInContentTopicMap && [8, 8]}>
            <PillarPage>
              <div style={{width: '80px'}}>Pillar page:</div>
              <StyledPageUrl onClick={() => openUrl(pageUrl, '_blank')}>
                {topicalMap?.contentPage ? topicalMap?.contentPage : ottoProject[0] ? (ottoProject[0] + '.com/') : '-'}
              </StyledPageUrl>
            </PillarPage>
            {settingAndDelete}
          </Row>
        </>)}
      </Col>
      {(mode && mode === 'edit' || !isInContentTopicMap) ? <Col span={12} xl={isInContentTopicMap ? 8 : 7} style={{display: 'flex', justifyContent: 'flex-end'}}>
        <StyledRadioGroup defaultValue={selectedTab} buttonStyle='solid' onChange={e => {
          setTableData('all-ideas');
          setSelectedTab(e.target.value);
        }}>
          {radioButtonsOptions.map((option, index) => (
            <Radio.Button value={option.value} key={index} >
              {option.icon(selectedTab === option.value ? '#7F4EAD' : '#A3A4A4')}
              <div>{option.label}<span>{option.desc}</span></div>
            </Radio.Button>
          ))}
        </StyledRadioGroup>
      </Col> : <></>}
    </StyledModalHeader>
  );

  return (
    <>
      {(getOttoV2Project?.isFrozen && !isInContentTopicMap) ? <FreezeBanner style={{padding: 8, minHeight: 43}}>
        <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
        <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
        </div>
      </FreezeBanner> : ''}
      {header}
      <div style={{height: '100%'}}>
        <StyledContentWrapper style={{height: (!isInContentTopicMap && selectedTab === 'table') ? '98%' : '98%'}} isInContentTopicMap={isInContentTopicMap} selectedTab={selectedTab}>
          {selectedTab === 'diagram' ? (
            <TopicalMapModalGraphContent
              func={func}
              isNodeSelected={isNodeSelected}
              searchInput={searchInput}
              dependency={dependency}
              topicalMap={topicalMap}
              ExportButton={ExportButton}
              dataLoader={dataLoader}
              topicalMapDataState={topicalMapDataState}
              zoom={zoom}
              setZoom={setZoom}
              setActivatedFilters={setActivatedFilters}
              searchIntentDropdown={searchIntentDropdown}
              activeCategories={activeCategories}
              setActiveCategories={setActiveCategories}
              categoriesOptions={categoriesOptions}
              isInContentTopicMap={isInContentTopicMap}
              setAddingIdeaInTopicMap={setAddingIdeaInTopicMap}
              selectedArticlesToGenerate={selectedArticlesToGenerate}
              setSelectedArticlesToGenerate={setSelectedArticlesToGenerate}
              onGenerateArticle={onGenerateArticle}
              onOutline={onOutline}
              topicalMapArticle={topicalMapArticle}
              generatingBulkArticles={generatingBulkArticles}
              setErrorId={setErrorId}
              errorId={errorId}
              setDataLoader={setDataLoader}
              wpContainerRef={wpContainerRef}
              topicMapDataList={topicMapDataList}
              triggerZoom={triggerZoom}
            />
          ) : (
            <TopicalMapModalTableContent
              func={func}
              keysWithChildren={keysWithChildren}
              topicMapDataList={topicMapDataList}
              generatingBulkArticles={generatingBulkArticles}
              selectedArticlesToGenerate={selectedArticlesToGenerate}
              setSelectedArticlesToGenerate={setSelectedArticlesToGenerate}
              topicalMapArticle={topicalMapArticle}
              setDataLoader={setDataLoader}
              topicalMap={topicalMap}
              componentIssueType={componentIssueType}
              isInContentTopicMap={isInContentTopicMap}
              tableDataFilter={tableDataFilter}
              ExportButton={ExportButton}
              searchIntentDropdown={searchIntentDropdown}
              categoryIntentDropdown={categoryIntentDropdown}
              searchInput={searchInput}
              topicalMapDataState={topicalMapDataState}
              setKeysWithChildren={setKeysWithChildren}
              searchIntentListLength={searchIntentList?.length}
              setAddingIdeaInTopicMap={setAddingIdeaInTopicMap}
              setClickedValue={setClickedValue}
              clickedValue={clickedValue}
              UnSelectedTooltipContent={UnSelectedTooltipContent}
              setDeleteModalData={setDeleteModalData}
              deleteModalData={deleteModalData}
              onGenerateArticle={onGenerateArticle}
              setSelectedCountry={setSelectedCountry}
              onOutline={onOutline}
              setErrorId={setErrorId}
              errorId={errorId}
              wpContainerRef={wpContainerRef}
              topicId={topicId}
              setSelectedTab={setSelectedTab}
            />
          )}
        </StyledContentWrapper>
        <StyledModalFooter isInContentTopicMap={isInContentTopicMap}>
          {isInContentTopicMap ? '' : <FreezeWrapper>
            <ButtonComponent
              color='Purple'
              style='solid'
              type='button'
              className='update-button'
              disabled={updatingStatus}
              onClick={updateSelectedTitles}
            >
              {updatingStatus ? 'Updating..': 'Update'}
            </ButtonComponent>
          </FreezeWrapper>}
        </StyledModalFooter>
      </div>
      {openDeleteModal && <StyledDeleteModal
        onClose={() => {
          setOpenDeleteModal(false);
          setDeletingTopicalMapId(null);
        }}
        visible={openDeleteModal}
        className={'customConfirmationModal'}
      >
        <ModalWrapper>
          <p>Are you sure you want to delete this record? </p>
          <p className={'warningText'}>This action cannot be undone.</p>

          <DeleteButton
            buttonType='transparent' size='lg'
            loading={deletingTopicalMap}
            onClick={deleteTopicalMap}>
            Delete
          </DeleteButton>
          <BackButton onClick={() => {
            setOpenDeleteModal(false);
            setDeletingTopicalMapId(null);
          }}
          buttonType='transparent'
          size='lg'>
            Go Back
          </BackButton>

        </ModalWrapper>
      </StyledDeleteModal>}
    </>
  );
});

const StyledModalHeader = styled(Row)<{isInContentTopicMap?: boolean}>`
  padding: ${p => p?.isInContentTopicMap ? '10px' : '0px'} !important;
  margin-bottom: ${p => p?.isInContentTopicMap ? '0px' : '16px'};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: ${p => p?.isInContentTopicMap && '#ffffff'};
  border-radius: ${p => p?.isInContentTopicMap && '12px 12px 0px 0px'};
  div {
    .modal-heading {
      font-family: 'Inter', sans-serif;
      font-size: 24px;
      font-weight: 600;
      color: #121212;
    }
    .modal-description {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #4E5156;
    }
  }
`;

const StyledContentWrapper = styled.div<{isInContentTopicMap?: boolean; selectedTab?: string}>`
  margin-bottom: ${p => p?.isInContentTopicMap ? '' : '15px'};
  overflow: hidden;
  box-shadow: 0px 3px 2px 0px #00000005;
  border: ${p => p?.isInContentTopicMap ? '' : '1px solid #E8E8E8'};
  background-color: #FFFFFF;
  border-radius: ${p => p?.isInContentTopicMap ? '0px' : '12px'};
  padding: 10px 10px 0px 10px;
`;

const StyledModalFooter = styled.div<{isInContentTopicMap?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  background-color: ${p => p?.isInContentTopicMap ? '#ffffff' : ''};
  margin-bottom: ${p => p?.isInContentTopicMap ? '20px' : ''};
  padding: ${p => p?.isInContentTopicMap ? '10px 10px' : ''};
  border-radius: ${p => p?.isInContentTopicMap ? '0px 0px 12px 12px' : ''};
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;

const UnSelectedTooltipContentContainer = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
`;

const StyledPageUrl = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #2D6CCA;
  cursor: pointer;
  /* max-width: 400px; */
  word-break: break-all;
`;

const PillarPage = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #4E5156;
  gap: 4px;
  .keyword {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #121212;
  }
`;

const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-dropdown-trigger {
    border: 1px solid #CDCDCD;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #121212;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      margin-top: -8px;
    }
  }
`;

const FiltersDropdownWrapper = styled(FilterButtons)`
  .ant-dropdown-trigger {
    padding: 3px 10px;
  }
`;

export const AiSettingButton = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  line-height: normal;
  padding: 5px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #4E51561A;
  color: #121212;
  cursor: pointer;
  &.light {
    background-color: #4E5156B2;
    color: #ffffff;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    height: 50px;
    border: 1px solid #4E51561F;
    background-color: #4E515612;
    .ant-radio-button {
      .ant-radio-button-inner {
        display: none;
      }
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: #7F4EAD33;
      border: 1px solid #7F4EAD1F;
    }
    &:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: transparent;
    }
    span:nth-child(2) {
      height: 46px;
      display: flex;
      align-items: center;
      gap: 10px;
      div {
        display: flex;
        flex-direction: column;
        line-height: normal;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #121212;
        span {
          line-height: normal;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 400;
          color: #4E5156;
        }
      }
    }
    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }
`;

const FiltersRadioGroup = styled(StyledRadioGroup)`
  .ant-radio-button-wrapper {
    height: 30px;
    border: 1px solid #CDCDCD;
    background-color: #FFFFFF;
    span:nth-child(2) {
      height: 28px;
    }
  }
`;

const StyledCategoryMenu = styled(Menu)`
  padding: 0px;
  border-radius: 8px;
  overflow: hidden;
  .ant-dropdown-menu-item {
    margin: 1px 0px;
    background-color: #F9F9FB;
    &.ant-dropdown-menu-item-selected {
      background-color: #7F4EAD33;
    }
    .ant-dropdown-menu-title-content {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: #121212;
      .ant-checkbox-wrapper {
        .ant-checkbox .ant-checkbox-inner {
          background-color: transparent !important;
          border-color: transparent !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: transparent !important;
          border-color: transparent !important;
          border-radius: 0px !important;
          overflow: hidden;
          &::after {
            border-color: #121212;
          }
        }
        .ant-checkbox:hover::after {
          visibility: hidden;
        }
        .ant-checkbox-checked::after {
          border-color: transparent !important;
        }
      }
    }
  }
`;

const StyledDeleteModal = styled(Modal)`
  top: 20px !important;
  .rc-dialog-content {
    background-color: #f7f6f6 !important;
    .rc-dialog-close {
      .rc-dialog-close-x:after {
        color: #121212 !important;
      }
    }
  }
`;
