import classnames from 'classnames';
import {Table as TableAntd} from 'antd';
import {TableProps as TablePropsAntd} from 'antd/es/table';
import styles from './style.module.scss';
import {observer} from 'mobx-react-lite';
import {TableSkeleton} from './skeleton';
import React from 'react';


const customBreak: React.CSSProperties = {
  wordBreak: 'break-word',
};

interface TableProps extends TablePropsAntd<any> {
  loadingRows?: number;
  containerClassName?: string;
  rowClass?: any;
  loading?: boolean;
  isEmpty?: boolean;
  loaderClassName?: string;
  isMiniTable?: boolean;
}

export const Table: React.FC<TableProps> = observer(({
  loadingRows = 6,
  loading = false,
  isEmpty,
  containerClassName,
  rowClass,
  loaderClassName,
  isMiniTable = false,
  ...props
}: TableProps) => {
  return (
    <div
      className={classnames(isMiniTable ? styles.minitables : '', styles.table, {
        [styles.rounded]: isEmpty,
        [containerClassName]: containerClassName,
      })}
    >
      {loading ?
        <TableSkeleton
          isGrayLoader
          style={customBreak}
          tableProps={props}
          loadingRows={loadingRows}
          loaderClassName={loaderClassName}
        /> :
        <TableAntd
          rowClassName={rowClass}
          className={styles.tableInner} style={{display: 'block', wordBreak: 'break-word'}} {...props} />}
    </div>
  );
});
