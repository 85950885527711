import {getApiUrl, getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {profileApi} from '@/api/account';
import {getDomain} from '@/utils/url';

class PlansApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer');
  private static readonly orderBuilderUrl: string = 'https://dashboard.linklaboratory.com/api';

  public async getPlans(params?: any) {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    try {
      const response = await this.axios.get(`${PlansApi.baseUrl}/payment/plans/`, {
        headers: headers,
        ...(params && {params}),
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCustomers() {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    try {
      const response = await this.axios.get(`${PlansApi.baseUrl}/child-accounts/`, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCustomersPlanOptions() {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    try {
      const response = await this.axios.get(`${PlansApi.baseUrl}/child-accounts/plan-options`, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async updateCustomerPlan(customerId, planId) {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    const data = {
      id: planId,
    };
    try {
      const response = await this.axios.post(`${PlansApi.baseUrl}/child-accounts/${customerId}/subscribe/`, data, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteCustomer(id) {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    try {
      const response = await this.axios.get(`${PlansApi.baseUrl}/child-accounts/${id}/remove-account/`, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async downgradeCustomer(id) {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    try {
      const response = await this.axios.get(`${PlansApi.baseUrl}/child-accounts/${id}/downgrade/`, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async getCustomerJwt(id: number) {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    try {
      const response = await this.axios.get(`${PlansApi.baseUrl}/child-accounts/${id}/`, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async subscribePlan(data: object) {
    try {
      let res = null;
      const currentDomain = getDomain(window.location.hostname);
      if (currentDomain == 'dashboard-staging.linkgraph.io' || currentDomain == 'dashboard.staging.searchatlas.com' || currentDomain == 'localhost') {
        res = await profileApi.getProfile();
      }
      const response = await this.axios.post(`${PlansApi.baseUrl}/payment/charge/${res?.isTestAccount ? 'v2/' : ''}`, data, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async orderCheckout(data: object) {
    try {
      const response = await this.axios.post(`${PlansApi.baseUrl}/orders/link-building/create/v2/`, data, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async validatePromoCode(data: object) {
    try {
      const response = await this.axios.post(`${PlansApi.baseUrl}/payment/validate-promo-code/`, data, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async buyCredits(data: object) {
    try {
      const response = await this.axios.post(`${PlansApi.baseUrl}/payment/buy-credits/`, data, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async creatNewUser(data: any) {
    try {
      const response = await this.axios.post(`${PlansApi.baseUrl}/new-customer/`, data, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createWhiteLabel(data: any) {
    try {
      const response = await this.axios.patch(`${PlansApi.baseUrl}/whitelabel-dashboard/`, data, {
        headers: {
          'Authorization': getAuthorizationHeader(),
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async validatePromoCodeForOderBuilder(data: object) {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    try {
      const response = await this.axios.post(`${PlansApi.orderBuilderUrl}/customer/payment/validate-promo-code/`, data, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSubscriptions() {
    try {
      const headers = {};
      const token = getTokenFromCookies();
      if (token) {
        headers['Authorization'] = getAuthorizationHeader();
      }
      const response = await this.axios.get(`${PlansApi.orderBuilderUrl}/customer/product/product-catalog/`, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postServices(payLoad) {
    try {
      const headers = {};
      const token = getTokenFromCookies();
      if (token) {
        headers['Authorization'] = getAuthorizationHeader();
      }
      const response = await this.axios.post(`${PlansApi.orderBuilderUrl}/customer/cart/`, payLoad, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async payWithCreditsOrder(payLoad, id) {
    try {
      const headers = {};
      const token = getTokenFromCookies();
      if (token) {
        headers['Authorization'] = getAuthorizationHeader();
      }
      const response = await this.axios.post(`${PlansApi.orderBuilderUrl}/customer/checkout/${id}/`, payLoad, {
        headers: headers,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async checkoutCartApi(id, payLoad) {
    try {
      const response = await this.axios.post(`${PlansApi.orderBuilderUrl}/customer/checkout/${id}/`, payLoad, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async addAdditionalNotes(payLoad) {
    try {
      const response = await this.axios.post(`${PlansApi.orderBuilderUrl}/customer/order-additional-notes/`, payLoad, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getPreviousListById(id) {
    try {
      const response = await this.axios.get(`${PlansApi.orderBuilderUrl}/customer/cart/${id}/`, {
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default PlansApi;
