import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PopupContainer, StyledImages, StyledModal} from '../styles';
import {faCircleCheck, faCircleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {Tooltip} from 'antd';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import FreezeWrapper from '../../../freezTooltip';

const MediaField = ({field, openModal, suggestion}) => {
  const [images, setImages] = useState([]);
  const [valueArray, setValueArray] = useState([]);
  const [currentValue, setCurrentValue] = useState<any>();
  const [visibleImages, setVisibleImages] = useState<any>([]);
  const [iconDetails, setIconDetails] = useState<any>({
    color: '#F44343',
    icon: faCircleExclamation,
  });

  const [modalConfig, setModalConfig] = useState({
    visible: false,
    imageSource: '',
    format: null,
  });

  useEffect(() => {
    if (field) {
      setValueArray(field);
    }
  }, [field]);

  useEffect(() => {
    valueArray && setValues();
  }, [valueArray]);

  const setValues = async () => {
    const images = [];
    if (field?.value) {
      Object.keys(field?.value)?.forEach(item => {
        if (item === 'ADDITIONAL') {
          if (Array.isArray(field?.value['ADDITIONAL'])) {
            field?.value['ADDITIONAL']?.forEach(i => images.push(i));
          }
        } else {
          if (field?.value[item]) {
            images.push(field?.value[item]);
          }
        }
      });
    }
    setImages(images);
    setCurrentValue(field?.value);
    if (images?.length <= 15) {
      setVisibleImages(images);
    } else {
      setVisibleImages(images?.slice(0, 15));
    }
  };

  useEffect(() => {
    if (images?.length >= 3) {
      setIconDetails({
        color: '#2AC155',
        icon: faCircleCheck,
      });
    } else {
      setIconDetails({
        color: '#F44343',
        icon: faCircleExclamation,
      });
    }
  }, [images]);

  const loadMore = () => {
    const startIdx = visibleImages?.length;
    const endIdx = startIdx + 15;
    const nextImages = images?.slice(startIdx, endIdx);
    setVisibleImages(prev => [...prev, ...nextImages]);
  };

  return (
    <>

      <div style={{display: 'flex', width: '100%', cursor: !images?.length && 'pointer'}}>
        <Tooltip title='No possible suggestions' overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212'}}>
          <FontAwesomeIcon style={{marginRight: 6, marginTop: '2px'}} icon={iconDetails.icon} color={iconDetails.color} />
        </Tooltip>
        <PopupContainer className='image-status'>
          { images?.length ? (
            <div style={{display: 'flex'}}>
              <div style={{width: 'calc(100% - 10px)'}}>
                <div>{images?.length} media files</div>
                <StyledImages>{ visibleImages?.map((image, idx) => (
                  (image?.format == 'VIDEO' ?
                    <video style={{width: 60, height: 'auto'}}
                      key={idx}
                      onClick={e => {
                        e.preventDefault();
                        const src = image?.url;
                        setModalConfig({visible: true, imageSource: src, format: 'VIDEO'});
                      }}
                    >
                      <source src={image.url} />
                    </video> :
                    <img
                      style={{cursor: 'pointer'}}
                      key={idx}
                      width={200}
                      src={image.url}
                      onClick={e => {
                        e.preventDefault();
                        const src = image?.url;
                        setModalConfig({visible: true, imageSource: src, format: 'PHOTO'});
                      }}
                    />
                  )
                ))}
                </StyledImages>
                {(visibleImages?.length !== images?.length) && <div style={{cursor: 'pointer', color: '#2D6CCA', paddingTop: 5, textAlign: 'center'}} onClick={loadMore}>Load More</div>}
              </div>
              <div className='edit-icon'>
                <FreezeWrapper>
                  <Tooltip title='Edit'>
                    <div onClick={() => openModal(suggestion, currentValue)}>
                      <FontAwesomeIcon
                        style={{marginRight: 6}}
                        icon={faPen}
                        color='#2D6CCA'
                      />
                        Edit
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </div>
          ): (
            <>
              <FreezeWrapper><span style={{color: '#2D6CCA', marginTop: 3, lineHeight: 'normal'}} onClick={() => openModal(suggestion, currentValue)}>Add atleast 3 images</span></FreezeWrapper>
              <div className='edit-icon' style={{marginTop: !images?.length && -16}}>
                <FreezeWrapper>
                  <Tooltip title='Edit'>
                    <div onClick={() => openModal(suggestion, currentValue)}>
                      <FontAwesomeIcon
                        style={{marginRight: 6}}
                        icon={faPen}
                        color='#2D6CCA'
                      />
                      Edit
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </>
          )}
        </PopupContainer>
      </div>
      <StyledModal
        zIndex={1060}
        visible={modalConfig.visible}
        onClose={() => setModalConfig({visible: false, imageSource: '', format: null})}
        footer={false}
        width={1080}
        closeIcon={<FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/>}
      >
        {modalConfig?.format == 'VIDEO' ?
          <video style={{width: '80vh', height: 'auto'}} controls>
            <source src={modalConfig.imageSource} />
          </video> :
          <img style={{maxHeight: '80vh', maxWidth: '1080px'}} src={modalConfig.imageSource} />
        }
      </StyledModal>
    </>
  );
};

export default MediaField;
