import {types, Instance, flow, cast, getParent} from 'mobx-state-tree';
import {CriteriaType} from '@/store/gsc-store/criteria';
import {pageGroupsApi} from '@/api/gsc/index';
import {BannerVariant} from '@/components/common-components/components/banner';
import {toJS} from 'mobx';
import {getSingleUrlParam} from '@/utils/url';

export enum SORT_DIRECTION {
  ascend = 'ascend',
  descend = 'descend'
}

type FilterPageGroupProps = {
  filterKeywordTerm: string;
  filterKeywordColumns: string[];
  filters: {name: string; header: string; from: string; to: string}[];
  pageNumber: number; pageSize: number;
  sortField: string; sortDirection: SORT_DIRECTION;
};

const PageGroup = types.model({
  id: types.number,
  pages: types.optional(types.array(types.string), []),
  name: types.maybeNull(types.string),
  total_pages: types.maybeNull(types.number),
  sumChecked: types.maybeNull(types.number),
}).actions(self => {
  const setPageGroup = (pages: string[], name: string, append: boolean = true) => {
    if (append) {
      pages.map((_page: string) => {
        if (!self.pages.includes(_page)) {
          self.pages.push(_page);
        }
      });
    } else {
      self.pages = cast(pages);
    }
    self.name = cast(name);
  };
  return {
    setPageGroup,
  };
});

export type PageGroupType = Instance<typeof PageGroup>;

export const PageGroupStore = types.model({
  pageGroups: types.maybeNull(types.array(PageGroup)),
  loading: types.boolean,
  selectedPages: types.optional(types.array(types.string), []),
  selectedGroupId: types.optional(types.number, 0),
  sumChecked: types.optional(types.number, 0),
  searchTerm: types.maybeNull(types.boolean),
  // modals
  isVisibleGroupAssignModal: types.boolean,

  // loading
  isLoadingUpdatePageGroup: types.boolean,
  sideDrawer: types.maybeNull(types.boolean),
  groupingDetail: types.maybeNull(types.boolean),
  groupingCreateUpdate: types.maybeNull(types.boolean),
  activeGroupButton: types.boolean,
  createNewGroup: types.maybeNull(types.boolean),
  undo: types.maybeNull(types.boolean),
  undoMessage: types.maybeNull(types.string),
  seTimeOutId: types.maybeNull(types.number),
  isPopoverOpen: types.boolean,
  interrupt: types.boolean, // TODO: Remove this, added here from component to avoid breaking pending refactoring Pages and Groups tables.
}).views(self => ({
  get getGroupingDrawer() {
    return self.sideDrawer;
  },
  get getPageGroups() {
    return toJS(self.pageGroups);
  },
  get getUndo() {
    return self.undo;
  },
  get getIsPopoverOpen() {
    return self.isPopoverOpen;
  },
  get geTimeOutId() {
    return self.seTimeOutId;
  },
  get getUndoMessage() {
    return self.undoMessage;
  },
  get isCreateNewGroup() {
    return self.createNewGroup;
  },
  get getGroupingCreateUpdate() {
    return self.groupingCreateUpdate;
  },
  get getGroupingDetailDrawer() {
    return self.groupingDetail;
  },
  get getActiveGroupButton() {
    return self.activeGroupButton;
  },
  get selectedPageGroup(): PageGroupType {
    if (self.selectedGroupId) {
      return self.pageGroups.find(group => group.id === self.selectedGroupId);
    }
    return undefined;
  },
  filterPageGroups({
    filterKeywordTerm, filterKeywordColumns, filters, pageNumber, pageSize, sortField, sortDirection,
  }: FilterPageGroupProps): {filteredData: PageGroupType[]; filteredDataSize: number} {
    let dataFiltered = self.pageGroups.filter( d => d);

    // TODO: this filtering function can be improved, or move this logic to backend to avoid paginating in memory
    // sorting data if defined
    if (sortField && sortDirection) {
      dataFiltered.sort( (a, b) => {
        const _dir = sortDirection === 'ascend' ? 1 : -1;
        if (a[sortField] > b[sortField]) return 1 * _dir;
        if (a[sortField] < b[sortField] * _dir) return -1 * _dir;
        return 0;
      });
    }

    // filtering by input term
    if (filterKeywordTerm) {
      dataFiltered = dataFiltered.filter( k => {
        const includeInResult = filterKeywordColumns.filter( col => {
          return k[col] && k[col].toLowerCase().includes(filterKeywordTerm.toLowerCase());
        }).length > 0;
        return includeInResult;
      });
    }
    if (filters && filters.length) {
      dataFiltered = dataFiltered.filter( k => {
        const shouldBeExcluded = filters.filter( f => {
          const itemValue = k[f.name];
          if (f.from === '' && f.to === '') return false;
          if (isNaN(itemValue)) return false;
          let isValid = true;
          if (f.from !== '') {
            const from = parseFloat(f.from);
            if (itemValue < from) isValid = false;
          }
          if (f.to !== '') {
            const to = parseFloat(f.to);
            if (itemValue > to) isValid = false;
          }
          return !isValid;
        }).length > 0;
        return !shouldBeExcluded;
      });
    }
    // Slice using pagination
    const startPaginationIndex = (pageNumber * pageSize) - pageSize;
    const endPaginationIndex = startPaginationIndex + pageSize;
    dataFiltered = dataFiltered.slice(startPaginationIndex, endPaginationIndex);
    return {filteredData: dataFiltered, filteredDataSize: dataFiltered.length};
  },
}))
  .actions(self => {
    const setFromResponse = data => {
      if (data && !data.isCancel) {
        data.map(d => {
          d.total_pages = d.pages.length;
        });
        self.pageGroups = data;
      }
    };
    const showCreateUpdateGropingDrawer = () => {
      self.groupingCreateUpdate= true;
    };
    const setCreateNewGroup = value => {
      self.createNewGroup= value;
    };
    const hideCreateUpdateGropingDrawer = () => {
      self.groupingCreateUpdate= false;
    };
    const showSidebarGroupingDrawer = () => {
      self.sideDrawer= true;
    };
    const setSearchTerm = value => {
      self.searchTerm= value;
    };
    const hideSidebarGroupingDrawer = () => {
      self.sideDrawer= false;
    };
    const showSidebarGroupingDetailDrawer = () => {
      self.groupingDetail= true;
    };
    const hideSidebarGroupingDetailDrawer = () => {
      self.groupingDetail= false;
      self.selectedGroupId = 0;
    };
    const setUndo = value => {
      self.undo = value;
    };
    const setTimeOut = value => {
      self.seTimeOutId = value;
    };
    const setUndoMessage = value => {
      self.undoMessage = value;
    };

    const doLoadPageGroupRules = flow(function* (criteria: CriteriaType) {
      const publicHash = (getSingleUrlParam('public_hash') || getSingleUrlParam('hash'));
      let data = null;
      self.loading = true;
      try {
        data = yield pageGroupsApi.getPageGroups(criteria, publicHash);
      } catch {
        const parent = getParent(self) as any;
        parent.setBanner(true, 'Page Groups not actualized', 'The Page Groupings data did not load properly. To fix the issue:', BannerVariant.ERROR);
      } finally {
        self.loading = false;
      }
      return data;
    });

    const loadPageGroups = flow(function* (criteria: CriteriaType) {
      setFromResponse(yield doLoadPageGroupRules(criteria));
    });

    const setSumChecked = sumChecked => {
      self.sumChecked = sumChecked;
    };

    const updatePageGroup = flow(function* (pageGroup: PageGroupType, name: string, pages: string[], append: boolean = true) {
      self.isLoadingUpdatePageGroup = true;
      pageGroup?.setPageGroup(pages, pageGroup.name, append);
      const _pageGroup = yield pageGroupsApi.updatePageGroup({id: pageGroup.id, name: name, pages: pageGroup.pages});
      self.pageGroups.map((pg: PageGroupType, index: number) => {
        if (pg.id === _pageGroup.id) {
          self.pageGroups[index] = _pageGroup;
        }
      });
      self.isLoadingUpdatePageGroup = false;
    });

    const addPageGroup = flow(function* (property: string, name: string, pages: string[]) {
      const pageGroup = yield pageGroupsApi.createPageGroup({property: property, name: name, pages: pages});
      self.pageGroups.push(PageGroup.create(pageGroup));
      return pageGroup.id;
    });

    const deletePageGroup = flow(function* (pageGroupId: number) {
      yield pageGroupsApi.deletePageGroup(pageGroupId);
      self.pageGroups = cast(self.pageGroups.filter((_pageGroup: PageGroupType) => _pageGroup.id !== pageGroupId));
    });

    // setters
    const setSelectedPages = (url: string[]) => {
      self.selectedPages = cast(url);
    };
    const resetSelectedPages = () => {
      self.selectedPages = cast([]);
    };
    const setActiveGroupButton = (value: boolean) => {
      self.activeGroupButton = value;
    };
    const setSelectedGroupId = (groupId: number) => {
      self.selectedGroupId = groupId;
    };
    const setIsVisibleGroupAssignModal = (isVisible: boolean) => {
      self.isVisibleGroupAssignModal = isVisible;
    };
    const toggleInterrupt = () => {
      self.interrupt = !self.interrupt;
    };
    const setPopover = (value: boolean) => {
      self.isPopoverOpen = value;
    };
    return {
      setSearchTerm,
      loadPageGroups,
      updatePageGroup,
      addPageGroup,
      deletePageGroup,
      setSelectedPages,
      setSelectedGroupId,
      setIsVisibleGroupAssignModal,
      toggleInterrupt,
      resetSelectedPages,
      setActiveGroupButton,
      setSumChecked,
      showSidebarGroupingDrawer,
      hideSidebarGroupingDrawer,
      showSidebarGroupingDetailDrawer,
      hideSidebarGroupingDetailDrawer,
      showCreateUpdateGropingDrawer,
      hideCreateUpdateGropingDrawer,
      setCreateNewGroup,
      setUndoMessage,
      setTimeOut,
      setPopover,
      setUndo,
    };
  });

export function initPageGroupStore() {
  return PageGroupStore.create({
    searchTerm: false,
    pageGroups: [],
    loading: false,
    sumChecked: 0,
    selectedGroupId: 0,
    selectedPages: [],
    isVisibleGroupAssignModal: false,
    activeGroupButton: false,
    isPopoverOpen: false,
    isLoadingUpdatePageGroup: false,
    interrupt: false, // TODO: remove, this is temporal to avoid breaking pending refactoring in Pages and Groups tables.
  });
}
