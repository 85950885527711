import {types} from 'mobx-state-tree';

import {initIntegration, Integration} from '@/store/settings/integration';
import {Customer, initCustomer} from '@/store/settings/customer/index';
import {TeamMembers, initTeamMembersStore} from '@/store/settings/team-members/index';

export const Settings = types.model({
  integration: Integration,
  customer: Customer,
  teamMembers: TeamMembers,
});

export function initSettings() {
  return Settings.create({
    integration: initIntegration(),
    customer: initCustomer(),
    teamMembers: initTeamMembersStore(),
  });
}
