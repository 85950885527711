import {StyledSpin} from '@/components/dashboard/pages/otto-page-detail/Utils/styledSpin';
import {addDefaultSrc, addProtocolToDomain, findValidDomain, isValidDomain} from '@/utils/url';
import {faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {faSortDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Dropdown, DropDownProps, Empty, Input, Menu} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
interface CommonDropdownProps extends DropDownProps {
  list: any[];
  selectedList: any[];
  title?: string;
  loading?: boolean;
  onSelectAll?: (list: string[]) => void;
  onDeselectAll?: (list: string[]) => void;
  onChange?: (value: string, state: boolean) => void;
  useId?: boolean;
  keyValue?: string;
}
export const CommonDropdown: React.FC<CommonDropdownProps> = ({list, selectedList, loading, title, onSelectAll, onDeselectAll, onChange, useId, keyValue}) => {
  const [search, setSearch] = useState('');

  const [filteredList, setFilteredList] = useState([]);

  const keyMapping = {
    'local_seo': 'business',
  };

  useEffect(() => {
    if (list?.length) {
      setFilteredList([...list]);
    }
  }, [list]);

  useEffect(() => {
    if (list?.length) {
      const data = list?.filter(item => item?.value?.toLowerCase()?.includes(search?.toLowerCase()));
      setFilteredList(data);
    } else {
      setFilteredList([]);
    }
  }, [search]);

  const selectedLength = list?.filter(item => selectedList?.some(key => key === (useId ? item?.id : item?.value)))?.length;
  const menu = (
    <StyledMenu>
      <div className='top-text'>{title}</div>
      {loading ? <div style={{display: 'flex', justifyContent: 'center'}}><StyledSpin /></div> : <><div className='buttons'>
        <div className='container'>
          <div className='counts'>Selected: {selectedLength} of {list?.length}</div>
          {
            search ?
              <div className='counts'>Search Data: {filteredList?.length} out of {list?.length}</div> : <></>
          }
        </div>
        <div className='buttons-wrapper'>
          <div className='select-all' onClick={() => onSelectAll(filteredList?.map(item => useId ? item?.id : item?.value))}>Select all</div>
          <div className='deselect-all' onClick={() => onDeselectAll(filteredList?.map(item => useId ? item?.id : item?.value))}>Deselect all</div>
        </div>
      </div>
      <Input className='search-bar' value={search} onChange={e => setSearch(e?.target?.value)} placeholder='Search...' prefix={<FontAwesomeIcon icon={faMagnifyingGlass} color='#A3A4A4' fontSize={16} />}/>
      <div className='list-wrapper'>
        {!filteredList?.length ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
          filteredList.map((item, index) => {
            const url = `https://www.google.com/s2/favicons?sz=64&domain_url=${addProtocolToDomain(item?.logo)}`;
            return (
              <div key={index} className='list-item' onClick={()=>onChange(useId ? item?.id : item?.value, !selectedList?.includes(useId ? item?.id : item?.value))}>
                <Checkbox className='item-checkbox' checked={selectedList?.includes(useId ? item?.id : item?.value)} onChange={()=>onChange(useId ? item?.id : item?.value, !selectedList?.includes(useId ? item?.id : item?.value))}/>
                {
                  useId ?
                    <Img onError={e => addDefaultSrc(e)} src={isValidDomain(item?.logo) ? url : `https://www.google.com/s2/favicons?sz=64&domain_url=${findValidDomain(item?.logo)?.includes('.com') ? findValidDomain(item?.logo) : `${findValidDomain(item?.logo)}.com`}`} alt={item?.logo}/> : <></>
                }
                {item?.value}
              </div>
            );
          })
        }
      </div></>}
    </StyledMenu>
  );

  return (
    <StyledDropdown overlay={menu} trigger={['click']} placement='bottom' onOpenChange={key=>key && setSearch('')}>
      <div className='selected-item'>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: loading ? 'center' : 'space-between'}}>
          {
            loading ? <div style={{display: 'flex', justifyContent: 'center'}}><StyledSpin color='rgba(45, 108, 202, 1)' /></div> :
              <>
                {useId && selectedLength === list?.length ? 'All ' : ''}{selectedLength ? selectedLength : ''} {useId ? keyMapping[keyValue] ? keyMapping[keyValue] : 'site': 'Recipient'}
                {selectedLength > 1 ? useId ? keyMapping[keyValue] ? 'es' : 's' : 's' : ''} <FontAwesomeIcon style={{marginTop: '-5'}} icon={faSortDown} color='#121212' fontSize={16}/>
              </>
          }
        </div>
      </div>
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  border-radius: 6px;
  border: 1px solid #D2D2D2;
  padding: 6px 12px;
  /* width: 132px; */
  max-width: 200px;
  &.selected-item {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #121212;
    cursor: pointer;
  }
`;
const StyledMenu = styled(Menu)`
  padding: 16px 15px;
  box-shadow: 0px 4px 30px 0px #00000047;
  border-radius: 12px;
  .top-text {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: baseline;
    .container {

      .counts {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: #4E5156;
      }
    }
    .buttons-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      .select-all, .deselect-all {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: #2D6CCA;
        cursor: pointer;
      }
    }
  }
  .search-bar {
    border-radius: 7px;
  }
  .list-wrapper {
    margin-top: 10px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    height: 148px !important;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: #7a7a7a; 
  }
    .list-item {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #121212;
      padding: 5px 4px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .item-checkbox {
        line-height: normal;
        /* For unchecked state (default state) */
        .item-checkbox .ant-checkbox > .ant-checkbox-inner {
          border-radius: 8px !important;
          background-color: white; /* White background for unchecked */
          border-color: black; /* Black border for unchecked */
        }

        /* For checked state */
        .item-checkbox .ant-checkbox-checked > .ant-checkbox-inner {
          border-radius: 8px !important;
          background-color: #2D6CCA; /* Background color for checked */
          border-color: #2D6CCA; /* Border color for checked */
        }

        /* Remove the checkmark display on checked state */
        .item-checkbox .ant-checkbox-checked::after {
          display: none;
        }
      }
    }
  }
`;

const Img = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;
