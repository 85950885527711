import {Checkbox, Divider, Menu, Radio} from 'antd';
import styled from 'styled-components';
import {Button} from '../Button';


export const MainFilterContainer = styled.div<{noMargin?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${p => p?.noMargin ? '' : 'margin: 10px 0;'}
  .data-count, .font-adjust {
  margin: 0 !important;
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
}
`;

export const FilterTag = styled.div<{selected?: boolean; isDarkTheme?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 28px;
  background: ${p=> p?.isDarkTheme ? '#4E515680' : '#FFFFFF'};
  border: ${p=> p?.isDarkTheme ? '1px solid #4E515680' : '1px solid #CDCDCD'};
  color: ${p=> p?.isDarkTheme && '#fff'};
  border-radius: 6px;
  cursor: pointer;
  ${p => p.selected ? `
    background: rgba(127, 78, 173, 0.17);
    border: 1px solid #B498D0;
    ` : p?.isDarkTheme ? `
    &:hover {
      background: #4e5156;
    }
    &.ant-dropdown-open {
      background: #4e5156;
    }
    ` :
    `
    &:hover {
      background: #E8E8E8;
    }
    &.ant-dropdown-open {
      background: #E8E8E8;
    }
  `}
`;

export const FilterTagText = styled.div<{selected?: boolean; isDarkTheme?: boolean}>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${p => p.selected ? p?.isDarkTheme ? '#fff' : '#121212' : '#A3A4A4'};
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
`;

export const FiltersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .ant-select {
    align-items: center;
    align-items: center;
    background: #f7f7f8;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 30px !important;
    justify-content: center;
    

    .ant-select-selection-item {
      color: #121212 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
`;

export const DividerStyled = styled(Divider)`
  margin: 6px 0;
`;

export const TextBlack = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

export const TextBlackField = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  text-wrap: nowrap;
`;

export const TextGray = styled.div`
  line-height: 17px;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #A3A4A4;
`;

export const CustomRangeContainer = styled.div`
  padding: 10px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const RangeInputContainer = styled.div<{isError?: boolean}>`
  display: flex;
  align-items: center;
  max-width: 100%;
  input {
    height: 32px;
    width: 50%;
    background: #F9F9FB;
    border: 1px solid #E8E8E8;
    &:focus-visible {
      outline: none;
      > :first-child {
        border-color: #7F4EAD !important;
      } 
      > :last-child {
        border-color: ${p=> p?.isError ? '#F44343' : '#7F4EAD'} !important;
      }
    }
  }
  > :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  > :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: ${p=> p?.isError ? '#F44343' : '#E8E8E8'};
  }
`;

export const ApplyBtnWrapper = styled(Menu.Item)`
  width: 100%;
  padding: 0 !important;
  background-color: transparent !important;
  height: fit-content !important;
  margin-bottom: 0 !important;
`;

export const BothBtnWrapper = styled(Menu.Item)`
  /* width: 100%; */
  padding: 0 !important;
  background-color: transparent !important;
  height: fit-content !important;
  margin-bottom: 0 !important;
  .ant-dropdown-menu-title-content{
    display: flex;
    flex-direction: row;
  }
`;

export const ApplyButton = styled(Button)`
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  /* width: 100%; */
`;

export const CancelButton = styled(Button)<{border?: boolean}>`
  margin-bottom: 15px;
  margin-right: 0;
  width: fit-content;
  height: 34px;
  border: ${p => p?.border ? '1px solid #E8E8E8' : ''};
  color: #121212;
  background: ${p => p?.border ? '#F7F7F8' : ''};
  &:hover, &:focus {
    color:  ${p => p?.border ? '#121212' : ''};
    background: ${p => p?.border ? '#F7F7F8 !important' : ''};
  }

`;

export const InputStyled = styled.input`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  background: #F9F9FB;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  &:focus-visible {
    outline: none;
    border-color: #7F4EAD !important;
  }
`;

export const InputTextStyled = styled.textarea`
  display: flex;
  align-items: center;
  width: 100%;
  background: #F9F9FB;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding-right: 10px;
  &:focus-visible {
    outline: none;
    border-color: #7F4EAD !important;
  }
`;

export const RadioStyled = styled(Radio)`
width: 100%;
.ant-radio-checked .ant-radio-inner{
  border-color: #7F4EAD;
  background-color: #7F4EAD;
}

.ant-radio-inner:after {
  background: #fff !important;
}
.ant-radio-inner {
  border-radius:50%; 
  border-color: #CDCDCD;
  background-color: #F9F9FB;
}
`;

export const RadioGropContainer = styled.div<{columnAligned?: boolean}>`    
  width: 100%;
  overflow: auto;
  max-height: 300px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
  .ant-radio-checked .ant-radio-inner{
    border-color: #7F4EAD;
    background-color: #7F4EAD;
  }
  .ant-radio-inner:after {
    background: #fff !important;
  }
  .ant-radio-inner {
    border-radius:50%; 
    border-color: #CDCDCD;
    background-color: #F9F9FB;
  }
  ${p => p?.columnAligned && '.ant-radio-group {display: flex !important; flex-direction: column !important;}'}
`;

export const CheckboxGropContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    border-color: #7F4EAD;
    background-color: #7F4EAD;
  }

  .ant-checkbox-inner {
    background: #F9F9FB;
    border: 1px solid #CDCDCD;
    border-radius: 5px;
  }
`;

export const PopupItemStyled = styled.div<{selected?: boolean; coloredHover?: boolean}>`
  cursor: pointer;
  height: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 15px;
  &:hover {
    background: ${p => p?.selected ? '' : p.coloredHover ? '#F1AA3E40' : '#E8E8E8'};
  }
  ${p => (p.selected && p.coloredHover) ? `
    background: #AF860026;
  ` : p.selected ? `
    background: #7F4EAD26;
  ` : ''}
`;

export const PopupItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const SpecialFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F1AA3E1A;
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const SortItem = styled.div<{selected?: boolean; isComingSoon?: boolean}>`
  ${p => p?.isComingSoon && 'opacity: 50%'};
  white-space: nowrap;
  height: 28px;
  background: ${p => p.selected ? 'rgba(127, 78, 173, 0.15)' : '#fff'};
  border: 1px solid ${p => p.selected ? 'rgba(127, 78, 173, 0.3)' : '#CDCDCD'};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 8px 0 6px;
  ${p => p.selected ? '' : `
    &:hover {
      background: #E8E8E8;
      border: 1px solif #CDCDCD;
    }
  `}
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
`;

export const DropdownOverlay = styled.div`
   background: #fff;
   border-radius: 8px;
   max-height: 200px;
   overflow: auto;
   &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
`;

export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen  and  (max-width: 450px) {
    width: 300px;
    }
`;

export const DataWrapper = styled.div`
  padding-bottom: 10px;
  padding-top: 5px;
  width:100%;
`;

export const CheckboxStyled = styled(Checkbox)`
  padding-right: 8px;
`;

export const Name = styled.div`
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 13px;
  color: #121212;
    @media screen and (max-width: 450px) {
    width: 290px; 
  }
`;

export const Address = styled.div`
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 12px;
  color: #4E5156;
  @media screen and (max-width: 450px) {
    width: 290px;
    }
`;

export const LoadMoreContainer = styled.div`
  align-items: center;
  display: flex;
  .ant-btn[disabled], .ant-btn[disabled]:hover{
    background-color: transparent;
    border: none;
    color: white;
  }
`;

export const LoadMoreBtn = styled(Button)<{marginTop?: string}>`
  background-color: transparent;
  border: none;
  color: #2AC155;
  margin-top: ${p => p?.marginTop ? p?.marginTop : ''} !important;
  &:hover {
    background: transparent !important;
    border: none !important;
    color: #2AC155 !important;
    margin-top: ${p => p?.marginTop ? p?.marginTop : ''} !important;
  }
  &:focus {
    background: transparent !important;
    border: none !important;
    color: #2AC155 !important;
    margin-top: ${p => p?.marginTop ? p?.marginTop : ''} !important;
  }
  &:active {
    background: transparent !important;
    border: none !important;
    color: #2AC155 !important;
    margin-top: ${p => p?.marginTop ? p?.marginTop : ''} !important;
  }
`;

export const LocationFilter = styled.div`
  .ant-select {
    height: 28px !important;
    .select-option {
      vertical-align: baseline !important;
      min-width: auto !important;
      width: 140px;
    }
    .ant-select-selector {
      border: 1px solid #CDCDCD !important;
      min-width: auto !important;
      .ant-select-selection-search-input {
        position: relative;
        top: -4px;
      }
    }
    .ant-select-selection-placeholder {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #A3A4A4 !important;
      line-height: 26px !important;
      padding-right: 24px!important;
      padding-left: 4px !important;
    }
    .ant-select-arrow {
      font-size: 14px !important;
    }
  }
`;

export const RadioGroupStyled = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
`;

export const StyledBoldText = styled.b<{maxWidth?: string}>`
  max-width: ${p => p?.maxWidth ? p?.maxWidth : ''} !important;
  overflow: ${p => p?.maxWidth ? 'hidden' : ''} !important;
  overflow-x: ${p => p?.maxWidth ? 'scroll' : ''} !important;
  &::-webkit-scrollbar {
    height: 2px;
    width: 5px;
  }
          
  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }
          
  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
