import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {getApiUrl, getAuthHeader} from '@/api/common-utils';
import axios from 'axios';
import {OTTO_V2_API} from '@/api/otto-v2';
import StringField from './formFields/StringField';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PopupField from './formFields/PopupFields';
import ReviewQAField from './formFields/ReviewQAField';
import OpenHoursField from './formFields/OpenHoursField';
import MediaField from './formFields/MediaField';
import AttributeField from './formFields/AttributeField';
import styled from 'styled-components';
import {Modal} from '@/components/common-components';
import {faArrowUpRightFromSquare, faBarsStaggered, faClock, faComments, faMagnifyingGlass, faPhone, faSquareList, faStar, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faCalendar, faList, faLocationDot, faEarthAmericas, faIdBadge, faImage} from '@fortawesome/pro-light-svg-icons';
import {faTwitter, faFacebookF, faInstagram, faYoutube, faTiktok, faLinkedinIn, faPinterest} from '@fortawesome/free-brands-svg-icons';
import {useStore} from '@/store/root-store';
import {Dropdown, Empty, Radio, Tooltip} from 'antd';
import {notification} from '@/utils/notification-v2';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {BaseApi} from '@/api/base-api';
import {Preview} from './preview';
import {ModalForms} from './ModalForms';
import {humanize, truncate} from '@/utils/string';
import FreezeWrapper from '../../freezTooltip';
import {Badge, DeployContainer, Header, ModalStyled, NoDataDiv, StyledContainer, StyledDivider, StyledSwitch, TooltipContent, TopSection, GBPDropdownWrapper, GBPDropdownContainer, GbpSearchInput, BusinessName, BusinessAddress} from './styles';
import {faCaretDown, faLock, faLockOpen, faRotate, faShieldCheck, faTriangleExclamation, faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {toJS} from 'mobx';
import {addStoreCode, getStandAloneLocations, removeZeroWidthCharactersFromString} from '@/utils/functions';
import {apiError} from '@/utils/api';
import {snakeCase} from 'lodash';
import {Button} from '@/components/common-components';
import {faCodePullRequest} from '@fortawesome/pro-regular-svg-icons';
import {FieldStatus} from './styles';
import {addProtocolToDomainHttps, getDomain} from '@/utils/url';
import {AddPhoneNumberModal} from '../MyTasks/modals/addPhoneNumberModal';
import {TooltipContainer} from '../MyTasks';
import {ButtonStyled as StyledButton} from '../MyTasks';
interface Props {
  searchValue?: string;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  selectedGbp?: any;
  showGbpDropdown?: boolean;
  setShowGbpDropdown?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectedLocation?: any;
  isInLocalSeo?: boolean;
  maxHeight?: string;
  removeTopBar?: boolean;
  isInGbpProjectOverview?: boolean;
  handleLockInProjectOverview?: boolean;
}

export const GbpOptimization = observer(({searchValue, setSearchValue, selectedGbp, showGbpDropdown, setShowGbpDropdown, handleSelectedLocation, isInLocalSeo, maxHeight, removeTopBar = false, isInGbpProjectOverview = false, handleLockInProjectOverview}: Props) => {
  const [attributes, setAttributes] = useState<any>();
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState<any>();
  const [selectedField, setSelectedField] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState<any>(null);
  const [suggestions, setSuggestions] = useState(0);
  const [loader, setLoader] = useState(false);
  const [key, setKey] = useState(Math.random().toFixed(3));
  const [services, setServices] = useState([]);
  const [isLockOn, setIsLockOn] = useState(false);
  const [modalSuggestion, setModalSuggestion] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<any>();
  const socialMediaAttributes = ['attributes_url_instagram', 'attributes_url_youtube', 'attributes_url_linkedin', 'attributes_url_facebook', 'attributes_url_appointment', 'attributes_url_tiktok', 'attributes_url_twitter', 'attributes_url_pinterest'];
  const [modalLoader, setModalLoader] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<any>({});
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [unviewedIncidents, setUnviewedIncidents] = useState(0);
  const [incidents, setIncidents] = useState([]);
  const [selectedLocationCountryCode, setSelectedLocationCountryCode] = useState('');
  const [isDeploying, setIsDeploying] = useState(false);
  const [lockLoader, setLockLoader] = useState(false);
  const [stats, setStats] = useState(null);
  const [fieldsList, setFieldsList] = useState([
    {
      label: 'Reviews',
      fieldName: 'reviews',
      icon: faStar,
      customFieldType: 'reviews',
    },
    {
      label: 'Q&A',
      fieldName: 'q_a',
      icon: faComments,
      customFieldType: 'q_a',
    },
    {
      label: 'Media',
      fieldName: 'images',
      icon: faImage,
      fieldType: 'MEDIA',
      customFieldType: 'media',
      value: null,
    },
    {
      label: 'Name',
      fieldName: 'business_name',
      icon: faIdBadge,
      fieldType: 'FIELD',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'Description',
      fieldName: 'business_description',
      icon: faIdBadge,
      fieldType: 'FIELD',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'Category',
      fieldName: 'categories',
      icon: faList,
      fieldType: 'FIELD',
      customFieldType: 'popup',
      value: null,
    },
    {
      label: 'Services',
      fieldName: 'services',
      icon: faSquareList,
      fieldType: 'FIELD',
      customFieldType: 'popup',
      value: null,
    },
    {
      label: 'Attributes',
      fieldName: 'attributes',
      icon: faBarsStaggered,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'attributes',
      value: null,
    },
    {
      label: 'Address',
      fieldName: 'address_lines',
      icon: faLocationDot,
      fieldType: 'FIELD',
      customFieldType: 'popup',
      value: null,
    },
    {
      label: 'Opening Date',
      fieldName: 'open_info',
      icon: faCalendar,
      fieldType: 'FIELD',
      customFieldType: 'popup',
      value: null,
    },
    {
      label: 'Open hours',
      fieldName: 'open_hours',
      icon: faClock,
      fieldType: 'FIELD',
      customFieldType: 'open_hours',
      value: null,
    },
    {
      label: 'Booking link',
      fieldName: 'attributes/url_appointment',
      icon: faCalendar,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'Website URL',
      fieldName: 'website_uri',
      icon: faEarthAmericas,
      fieldType: 'FIELD',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'Phone number',
      fieldName: 'phone_numbers',
      icon: faPhone,
      fieldType: 'FIELD',
      customFieldType: 'popup',
      value: null,
    },
    {
      label: 'Instagram URL',
      fieldName: 'attributes/url_instagram',
      icon: faInstagram,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'YouTube URL',
      fieldName: 'attributes/url_youtube',
      icon: faYoutube,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'LinkedIn URL',
      fieldName: 'attributes/url_linkedin',
      icon: faLinkedinIn,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'Facebook URL',
      fieldName: 'attributes/url_facebook',
      icon: faFacebookF,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'Twitter URL',
      fieldName: 'attributes/url_twitter',
      icon: faTwitter,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'TikTok URL',
      fieldName: 'attributes/url_tiktok',
      icon: faTiktok,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
    {
      label: 'Pinterest URL',
      fieldName: 'attributes/url_pinterest',
      icon: faPinterest,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
      value: null,
    },
  ]);
  const [openPhoneNumberModal, setOpenPhoneNumberModal] = useState({visible: false, type: null});

  const {ottoV2Store: {
    selectedLocation,
    gbpLocations,
    getGBPLocations,
    refreshingBulkGBPOptimization,
    ottoBulkImportOptimization,
    setGbpLocationRepoll,
    loadingAllGBPLocations,
    allGBPLocations,
    setSelectedGbpProject,
    selectedGbpProject,
  }, settings: {customer: {profile: {whitelabelOtto}}},
  gsc: {googleAnalytics: {getGMBData}},
  } = useStore('');

  useEffect(() => {
    if (!gbpLocations?.length && !isInGbpProjectOverview) {
      getGBPLocations();
    }
  }, []);

  const [optimizationV2Data, setOptimizationV2Data] = useState(null);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [pendingFields, setPendingFields] = useState(0);
  useEffect(() => {
    const location = gbpLocations?.find(location => location?.id == selectedLocation?.id);
    setSelectedLocationCountryCode(location?.addressRegionCode);
  }, [gbpLocations, selectedLocation?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if ((isInGbpProjectOverview && selectedBusiness?.length) || selectedLocation?.id) {
          await setLoader(true);
          await getAttributeSchema();
          await callApi();
          await getIncidentsCount();
          let data = localStorage.getItem('deployStatusTaskId');
          data = data ? JSON.parse(data) : null;
          if (data && data['locationId'] === selectedLocation?.id) {
            setIsDeploying(true);
            await getDeployStatus(data['taskId'], true);
          }
          await getServices();
        } else {
          setOptimizationV2Data(null);
        }
      } catch {
        setOptimizationV2Data(null);
        setLoader(false);
        return Promise.reject;
      }
    };
    fetchData();
  }, [JSON.stringify(selectedLocation?.id), selectedBusiness]);

  useEffect(() => {
    if (optimizationV2Data) {
      Object?.keys(optimizationV2Data?.data?.attributes)?.map(attribute => {
        setFieldsList(prev => prev.map(item => {
          if (item?.fieldName == 'images') {
            if (attribute?.includes('media')) {
              item.value = item?.value || {};
              if (attribute === 'mediaCover') {
                item.value['COVER'] = optimizationV2Data?.data?.attributes[attribute];
              } else if (attribute === 'mediaProfile') {
                item.value['PROFILE'] = optimizationV2Data?.data?.attributes[attribute];
              } else {
                item.value['ADDITIONAL'] = optimizationV2Data?.data?.attributes[attribute];
              }
            }
          }
          if (item?.fieldName==snakeCase(attribute)) {
            item['value'] = optimizationV2Data?.data?.attributes[attribute];
            return item;
          } else if (Object?.keys(optimizationV2Data?.data?.attributes?.attributes)?.includes(item?.fieldName) || (item?.fieldType=='ATTRIBUTE' && item?.customFieldType=='string')) {
            item['value'] = optimizationV2Data?.data?.attributes?.attributes[item?.fieldName];
            return item;
          } else {
            return item;
          }
        }));
      });
    }
  }, [optimizationV2Data, JSON.stringify(selectedLocation)]);

  useEffect(() => {
    if (isModalOpen) {
      if ((selectedField == 'categories' || selectedField == 'services') && !categories?.length) {
        getCategories();
      }
    }
  }, [isModalOpen, selectedField]);

  useEffect(() => {
    if (optimizationV2Data) {
      arrangeData();
    } else {
      setSuggestions(0);
    }
  }, [optimizationV2Data]);

  useEffect(() => {
    const foundBusiness = findLocationById(getGMBData, selectedLocation?.locationId);
    setSelectedBusiness(foundBusiness);
  }, [getGMBData, selectedLocation?.locationId]);

  const getSuggestions = async () => {
    try {
      await OTTO_V2_API.generateOptimizations(selectedLocation?.id);
    } catch (error) {
      notification.error('Suggestions', 'Fetching Suggestions Failed');
    }
  };

  const getAttributeSchema = async () => {
    try {
      const attributesResponse = await axios.get(`${getApiUrl(BaseApi.SA_ENDPOINT, '/api')}/gbp/v1/metadata/schema/attributes?location=${selectedLocation?.id}`, {
        headers: getAuthHeader({}, isInLocalSeo),
      });
      delete attributesResponse?.data['$schema'];
      if (!Object.keys(attributesResponse?.data?.properties).includes('attributes/url_appointment')) {
        setFieldsList(prev => prev.filter(item => item.fieldName !== 'attributes/url_appointment'));
      }
      (Object.keys(attributesResponse?.data?.properties).forEach(key => {
        if (socialMediaAttributes.includes(key)) {
          delete attributesResponse.data.properties[key];
        }
      }));
      setAttributes(attributesResponse.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getServices = async () => {
    const servicesResponse = await OTTO_V2_API.geMetadataServices({location: selectedLocation?.id}, isInLocalSeo);
    setServices(servicesResponse?.services);
  };

  const getCategories = async () => {
    const categoryResponse = await OTTO_V2_API.getMetadataCategories({location: selectedLocation?.id}, isInLocalSeo);
    setCategories(categoryResponse?.categories);
  };

  const callApi = async () => {
    try {
      await getSuggestions();
      await getOptimizationsV2();
      const questionsResponse = await OTTO_V2_API.getQuestionStats(selectedLocation?.id, isInLocalSeo);
      setQuestions(questionsResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOptimizationsV2 = async () => {
    try {
      const optimizationResponse = await OTTO_V2_API.getOptimizationsV2(selectedLocation?.id, isInLocalSeo);
      setStats(optimizationResponse?.included[0].attributes.reviews);
      fetchLabels(optimizationResponse);
      setSelectedGbpProject(optimizationResponse?.data);
      setLoader(false);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const fetchLabels = async response => {
    if (response) {
      response?.meta?.suggestions?.forEach(suggestion => {
        if (suggestion?.fieldName === 'categories') {
          suggestion.value = suggestion?.value?.map(val =>
            response?.meta?.categories?.find(cat => cat?.key === val) || val,
          );
        } else if (suggestion?.fieldName === 'services') {
          suggestion.value = suggestion?.value?.map(val => {
            const foundService = response?.meta?.services?.find(service => service?.key === val?.serviceTypeId);
            return foundService ? {...val, serviceTypeId: {key: val?.serviceTypeId, label: foundService?.label}} : val;
          });
        }
      });
      Object.entries(response?.data?.attributes || {}).forEach(([key, item]: any) => {
        if (key === 'categories') {
          response.data.attributes[key] = item?.map(cat => typeof cat === 'string' ? response?.meta?.categories?.find(val => val.key === cat) : cat);
        } else if (key === 'services') {
          response.data.attributes[key] = item?.map(ser => {
            if ('serviceTypeId' in ser) {
              if (typeof ser.serviceTypeId === 'string') {
                ser.serviceTypeId = response?.meta?.services?.find(val => val.key === ser.serviceTypeId);
              }
            }
            return ser;
          });
        } else if (key === 'attributes') {
          response.data.attributes[key] = Object.entries(response.data.attributes.attributes)
            .reduce((acc, [key, item]) => {
              const formattedKey = snakeCase(key).replace('attributes_', 'attributes/');
              if (Array.isArray(item)) {
                acc[formattedKey] = item;
              } else {
                acc[formattedKey] = item;
              }

              setPendingFields(response?.data?.attributes?.pendingSyncFields?.filter(field => field !== 'attributes')?.length);

              return acc;
            }, {});
        }
      });
    }
    setOptimizationV2Data(response);
  };

  const arrangeData = () => {
    const attributes = optimizationV2Data?.data?.attributes;
    setIsLockOn(attributes?.isLockedOn ?? false);
    setSuggestions(optimizationV2Data?.meta?.suggestions?.length ?? 0);
    const categories = attributes?.categories || [];
    setPrimaryCategory(categories.length > 0 ? categories[0] : null);
    if (optimizationV2Data) {
      setLoader(false);
    }
  };

  const openModals = (fieldName, suggestions, value) => {
    if (fieldName == 'attributes') {
      const attrObj = {};
      Object.entries(value)?.forEach(([key, item]) => {
        attrObj[key] = item;
      });
      setSelectedAttributes(attrObj);
    }
    setSelectedField(fieldName);
    setSelectedValue(value ? JSON.parse(JSON.stringify(value)) : {});
    setModalSuggestion(suggestions);
    if (fieldName === 'phone_numbers') {
      setOpenPhoneNumberModal({visible: true, type: 'phoneNumber'});
    } else if (fieldName === 'categories') {
      setOpenPhoneNumberModal({visible: true, type: 'businessCategory'});
    } else {
      setIsModalOpen(true);
    }
    setKey(Math.random().toFixed(3));
  };

  const updateData = async payload => {
    try {
      const updatePayload = [];
      payload.forEach(dataItem => {
        let value = dataItem.value;
        if (dataItem.fieldName == 'categories') {
          value = value.map(item => item.key);
        } else if (dataItem.fieldName == 'services') {
          value = value.map(item => {
            if (Object.keys(item).includes('categoryName')) {
              return {
                display_name: item.displayName,
                category_name: item.categoryName,
              };
            } else if (Object.keys(item).includes('serviceTypeId')) {
              return {service_type_id: item.serviceTypeId.key};
            }
          }).filter(item => item);
        } else if (dataItem.fieldName == 'attributes/url_appointment') {
          while (Array.isArray(value) && Array.isArray(value[0])) {
            value = value[0];
          }
          if (Array.isArray(value)) {
            value = value[0]?.startsWith('http') ? value : [`http://${value[0]}`];
          } else {
            value = value.startsWith('http') ? value : `http://${value}`;
          }
        }
        updatePayload.push({
          field_name: dataItem.fieldName,
          field_type: dataItem.fieldType,
          value: typeof value === 'string' ? removeZeroWidthCharactersFromString(value) : value,
        });
      });
      const v2Payload = {};
      updatePayload?.forEach(item => {
        if (item.field_type == 'MEDIA') {
          v2Payload['media_' + (item.field_name == 'ADDITIONAL' ? 'items' : item.field_name.toLowerCase())] = item.value;
        } else if (item.field_type == 'ATTRIBUTE') {
          v2Payload['attributes'] = optimizationV2Data?.data?.attributes?.attributes;
          if (!v2Payload['attributes']) v2Payload['attributes'] = {};
          v2Payload['attributes'][item.field_name] = item.value;
        } else {
          v2Payload[item.field_name] = item.value;
        }
      });
      const data = {
        data: {
          type: 'locations',
          id: selectedLocation?.id,
          attributes: v2Payload,
        },
      };
      await OTTO_V2_API.updateOptimizationsV2(selectedLocation?.id, data, isInLocalSeo);

      setLoader(true);
      setIsModalOpen(false);
      setOpenPhoneNumberModal({visible: false, type: null});
      await getOptimizationsV2();

      notification.success('Update Successful', 'The field has been updated');
    } catch (error) {
      setLoader(false);
      const response = error?.response?.data?.errors;
      if (response) {
        const messagesArray = response?.map(item => item?.detail);
        if (messagesArray?.length) {
          messagesArray.forEach(item => {
            notification.error('Update Failed', item);
          });
        }
      } else {
        notification.error('Update Failed', 'Failed to Update the Current Field');
      }
      return null;
    } finally {
      setModalLoader(false);
    }
  };

  const getDeployStatus = async (taskId, first=false) => {
    try {
      const response = await OTTO_V2_API.taskResults(taskId);
      if (response.status == 'FAILURE') {
        notification.error('Deployment Failed', response.errorMessage);
        localStorage.setItem('deployStatusTaskId', null);
        setIsDeploying(false);
      } else if (response.status == 'SUCCESS') {
        getIncidentsCount();
        notification.success('Deployment Successful', 'Deployment was successful');
        localStorage.setItem('deployStatusTaskId', null);
        setIsDeploying(false);
      } else {
        localStorage.setItem('deployStatusTaskId', JSON.stringify({taskId: taskId, locationId: selectedLocation?.id}));
        getDeployStatus(taskId);
      }
      if (response.status == 'SUCCESS' || response.status == 'FAILURE' || (response.status == 'PENDING' && first)) {
        await setLoader(true);
        await getOptimizationsV2();
      }
    } catch {
      return Promise.reject;
    }
  };

  const lockOnSwitchHandler = async value => {
    setLockLoader(true);
    try {
      const v2Payload = {
        is_locked_on: value,
      };
      const data = {
        data: {
          type: 'locations',
          id: selectedLocation?.id,
          attributes: v2Payload,
        },
      };
      await OTTO_V2_API.updateOptimizationsV2(selectedLocation?.id, data, isInLocalSeo);
      setIsLockOn(value);
      setLockLoader(false);
      await getOptimizationsV2();
      notification.success('Update Successful', `GBP Information Lock Turned ${value ? 'On' : 'Off'} successfully`);
    } catch (e) {
      setLockLoader(false);
      const message = e?.response?.data?.errors?.map(item => item?.detail)?.join(', ');
      notification.error('Update Failed', (message ?? 'Lock On Status Updated Failed'));
    }
  };

  useEffect(() => {
    if (Object.entries(selectedGbpProject).length && handleLockInProjectOverview!==null && selectedGbpProject?.attributes?.isLockedOn!=handleLockInProjectOverview) {
      lockOnSwitchHandler(handleLockInProjectOverview);
    }
  }, [handleLockInProjectOverview]);

  const generateIncidentKey = incident =>
    `${incident.createdAt}-${incident.fieldName}-${incident.value}`;

  const getIncidentsCount = () => {
    const fetchOptimizations = async () => {
      try {
        const optimizationResponse = await OTTO_V2_API.getOptimizations(selectedLocation?.id, {show: 'ALL', event_type: 'LOCK_INCIDENT,LOCK_FIXED'}, isInLocalSeo);
        const newIncidents = optimizationResponse?.optimizations?.map(field => {
          if (field.fieldName == 'categories') {
            field.value = field.value?.map(cat => optimizationResponse?.included?.categories?.find(val => val.key == cat));
          } else if (field.fieldName == 'services') {
            field.value = field.value?.map(ser => {
              if (Object.keys(ser).includes('serviceTypeId')) {
                ser.serviceTypeId = optimizationResponse?.included?.services?.find(val => val.key == ser.serviceTypeId);
              }
              return ser;
            });
          }
          return field;
        });
        const newIncidentKeys = newIncidents.map(generateIncidentKey);

        const viewedIncidents = JSON.parse(localStorage.getItem('viewedIncidents')) || [];

        const unviewedIncidentKeys = newIncidentKeys.filter(
          key => !viewedIncidents.includes(key),
        );

        setIncidents(newIncidents);

        if (unviewedIncidentKeys.length > 0) {
          setUnviewedIncidents(unviewedIncidentKeys.length);
        } else {
          setUnviewedIncidents(0);
        }
      } catch {
        return Promise.reject;
      }
    };
    setTimeout(() =>{
      fetchOptimizations();
    }, 5000);
  };

  const markAsViewed = () => {
    const incidentKeys = incidents.map(generateIncidentKey);
    localStorage.setItem('viewedIncidents', JSON.stringify(incidentKeys));
    setUnviewedIncidents(0);
  };

  const viewIncidents = () => {
    setSelectedField('incidents');
    setIsModalOpen(true);
    markAsViewed();
  };

  function findLocationById(data, targetLocationId) {
    if (data) {
      for (const account of data) {
        for (const businessAccount of account.businessAccounts) {
          for (const location of businessAccount.locations) {
            if (location.locationId === targetLocationId) {
              return location;
            }
          }
        }
      }
    }
    return null;
  }

  const locationList = (
    <GBPDropdownContainer>
      {loadingAllGBPLocations? <RingLoaderV2 height='225px' fontSize='40px' /> :
        <>
          <GbpSearchInput value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder='Search...'
            prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
          />
          {getStandAloneLocations(toJS(gbpLocations), searchValue)?.length ? addStoreCode(toJS(gbpLocations), searchValue, toJS(allGBPLocations))?.map(business => (
            <Radio key={business?.locationId} style={{width: '100%'}} onChange={e=> handleSelectedLocation(e.target.value)} checked={String(selectedGbp?.id) === String(business?.id)} value={business}>
              <div>
                <BusinessName style={{fontWeight: 'bold', fontSize: '12px', width: '405px'}}>{business?.businessName}</BusinessName>
                <BusinessAddress style={{width: '405px'}}>{business?.businessAddress ? business?.businessAddress : business?.storeCode ? `Store code: ${business?.storeCode}` : ''}</BusinessAddress>
              </div>
            </Radio>
          )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </>}
    </GBPDropdownContainer>
  );


  const onRefresh = async () => {
    try {
      setGbpLocationRepoll(true);
      await ottoBulkImportOptimization(true, isInLocalSeo);
      setGbpLocationRepoll(false);
      setLoader(true);
      await getOptimizationsV2();
    } catch (error) {
      setGbpLocationRepoll(false);
      setLoader(false);
      const errorMessage = apiError(error, 'Failed to refresh data') as string;
      notification.error('', errorMessage);
    }
  };

  const getFieldSuggestion = field => {
    const suggestionItem = optimizationV2Data?.meta?.suggestions?.find(suggestion => suggestion?.fieldName == field?.fieldName);
    if (suggestionItem) {
      if (suggestionItem.fieldName === 'website_uri') {
        const domain = getDomain(suggestionItem.value);
        suggestionItem.value = addProtocolToDomainHttps(domain);
      }
    }
    return suggestionItem;
  };

  const checkDeployStatus = fieldName => {
    if (fieldName == 'images') {
      return !optimizationV2Data?.data?.attributes?.pendingSyncFields?.some(item => item.includes('media'));
    } else if (fieldName.includes == 'attributes') {
      const ignoreAttributes = ['attributes/url_appointment', 'attributes/url_facebook', 'attributes/url_youtube', 'attributes/url_instagram', 'attributes/url_twitter', 'attributes/url_linkedin', 'attributes/url_pinterest', 'attributes/url_tiktok'];
      return optimizationV2Data?.data?.attributes?.pendingSyncFields?.filter(item => item.includes('attributes') && !ignoreAttributes.includes(item))?.length;
    } else {
      return !optimizationV2Data?.data?.attributes?.pendingSyncFields?.includes(fieldName);
    }
  };

  const getTooltipData = () => {
    const tooltipData = optimizationV2Data?.data?.attributes?.pendingSyncFields?.map(item => {
      const data = {
        name: item.name,
        tasks: item.pendingTasks?.map(task => humanize(task.replace('attributes/', '')).replace('Url', 'URL')),
      };
      return data;
    }).filter(item => item.tasks?.length);
    return <TooltipContainer>{tooltipData?.map(item => (<div key={item.name}>
      <div>{item.name}</div>
      <ul style={{paddingLeft: 30}}>
        {item.tasks.map(task => (<li key={task}>{task}</li>))}
      </ul>
    </div>))}</TooltipContainer>;
  };

  return (
    <>
      {loader ? (<div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}><RingLoaderV2 width='500px' height='500px' fontSize={50} /></div> ): (
        <div style={{overflow: 'auto', maxHeight}}>
          {isInGbpProjectOverview && <div style={{display: 'flex', marginTop: 24}}>
            <div style={{display: 'flex', width: '70%', justifyContent: 'space-between', marginRight: 31}}>
              <div>
                <div style={{fontWeight: 500, fontSize: 16, color: '#121212'}}>Business Data Optimizations</div>
                <div style={{color: '#4E5156', fontSize: 12}}>Publishing changes may take some time</div>
              </div>
              <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                <FreezeWrapper>
                  <Tooltip title={
                    (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ))? 'No business selected' : ''
                  }>
                    <div style={{cursor: (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id )) ? 'not-allowed' : 'pointer', color: '#2D6CCA'}}
                      onClick={() => !refreshingBulkGBPOptimization && ((isInLocalSeo && selectedGbp?.id) || (!isInLocalSeo && JSON.stringify(selectedLocation?.id ))) && ((pendingFields > 0 ? setIsConfirmationModal(true) : onRefresh()))}>
                      <FontAwesomeIcon icon={faRotate} style={{margin: '0 5px', cursor: `${isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ) ? 'pointer' : 'not-allowed !important'}`}} fontSize={12} color='#2D6CCA' className={refreshingBulkGBPOptimization? 'fa-spin': ''}/>
                    Refresh</div></Tooltip>
                </FreezeWrapper>
                {isConfirmationModal && (
                  <StyledConfirmationModal
                    visible={isConfirmationModal}
                    onClose={() => setIsConfirmationModal(false)}
                    closable={true}
                    closeIcon={<FontAwesomeIcon icon={faXmark} />}
                    mask={true}

                    title='Refresh Confirmation'
                  >
                    <p>Refreshing will override your undeployed changes. Do you want to continue?</p>
                    <StyledModalFooter>
                      <ButtonStyled onClick={() => {
                        setIsConfirmationModal(false); onRefresh();
                      }}>
          Confirm
                      </ButtonStyled>
                      <Button
                        onClick={() => setIsConfirmationModal(false)}
                        style={{color: '#4E5156', cursor: 'pointer', background: 'transparent', border: 'none'}}
                      >
          Cancel
                      </Button>
                    </StyledModalFooter>

                  </StyledConfirmationModal>
                )}
                <Tooltip title={!isDeploying ? (pendingFields > 0 ? getTooltipData() : 'No Pending fields found for deployment') : 'Fields are recently deployed. Wait for few minutes for next deployment'}>
                  <StyledButton loading={isDeploying} style={{cursor: (isDeploying || !pendingFields) ? 'not-allowed' : 'pointer', padding: '7px 12px', fontSize: 14, backgroundColor: '#18923B', pointerEvents: 'auto', color: '#fff'}} disabled={isDeploying || !pendingFields} onClick={async () => {
                    await setIsDeploying(true);
                    const response = await OTTO_V2_API.deployOptimizationsV2(selectedLocation?.id, isInLocalSeo);
                    await getDeployStatus(response.data.id);
                  }}>
                    <FontAwesomeIcon icon={faCodePullRequest} color='#fff' style={{marginRight: 6}}/>{'Deploy all changes'}
                  </StyledButton>
                </Tooltip>
              </div>
            </div>
            <div style={{width: '30%', fontWeight: 500, fontSize: 16, color: '#121212'}}>Currently Live in Maps</div>
          </div>}
          {!isInGbpProjectOverview && <DeployContainer style={{flexWrap: 'wrap'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              {
                isInLocalSeo ?
                  <TopSection>
                    <Dropdown disabled={!selectedGbp?.id} trigger={['click']} overlay={locationList} visible={showGbpDropdown} onVisibleChange={e => {
                      setShowGbpDropdown(e);
                      if (!e) {
                        setSearchValue('');
                      }
                    }}>
                      <Tooltip title={!selectedGbp?.id ? 'No businesses available' : ''}>
                        <GBPDropdownWrapper location={selectedGbp?.id}>
                          <FontAwesomeIcon icon={faLocationDot} fontSize={14} />
                          Business:
                          <div>{truncate(selectedGbp?.businessName, 30)}</div>
                          <FontAwesomeIcon icon={faCaretDown} fontSize={14} />
                        </GBPDropdownWrapper>
                      </Tooltip>
                    </Dropdown>
                  </TopSection> : <></>
              }
              <span>{`${whitelabelOtto} Suggestions:`} <span style={{fontWeight: '600'}}>{suggestions}</span></span>
            </div>
            <div className='buttons-container' style={{width: 'auto'}}>
              <FreezeWrapper>
                <Tooltip title={
                  (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ))? 'No business selected' : ''
                }>
                  <div className='refresh-button' style={{cursor: (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id )) ? 'not-allowed' : 'pointer'}}
                    onClick={() => !refreshingBulkGBPOptimization && ((isInLocalSeo && selectedGbp?.id) || (!isInLocalSeo && JSON.stringify(selectedLocation?.id ))) && ((pendingFields > 0 ? setIsConfirmationModal(true) : onRefresh()))}>
                    <FontAwesomeIcon icon={faRotate} style={{margin: '0 5px', cursor: `${isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ) ? 'pointer' : 'not-allowed !important'}`}} fontSize={12} color='#2D6CCA' className={refreshingBulkGBPOptimization? 'fa-spin': ''}/>
                    Refresh</div></Tooltip>
              </FreezeWrapper>
              {isConfirmationModal && (
                <StyledConfirmationModal
                  visible={isConfirmationModal}


                  onClose={() => setIsConfirmationModal(false)}
                  closable={true}
                  closeIcon={<FontAwesomeIcon icon={faXmark} />}
                  mask={true}

                  title='Refresh Confirmation'
                >
                  <p>Refreshing will override your undeployed changes. Do you want to continue?</p>
                  <StyledModalFooter>
                    <ButtonStyled onClick={() => {
                      setIsConfirmationModal(false); onRefresh();
                    }}>
          Confirm
                    </ButtonStyled>
                    <Button
                      onClick={() => setIsConfirmationModal(false)}
                      style={{color: '#4E5156', cursor: 'pointer', background: 'transparent', border: 'none'}}
                    >
          Cancel
                    </Button>
                  </StyledModalFooter>

                </StyledConfirmationModal>
              )}

              <FreezeWrapper>
                <Tooltip title={ (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ))? 'No business selected' : !isDeploying ? (pendingFields ? getTooltipData() : 'No Pending fields found for deployment') : 'Fields are recently deployed. Wait for few minutes for next deployment'}>
                  <StyledButton loading={isDeploying} style={{cursor: (isDeploying || !pendingFields || (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ))) ? 'not-allowed' : 'pointer', padding: '7px 12px', fontSize: 14, backgroundColor: '#18923B', pointerEvents: 'auto', color: '#fff'}}
                    disabled={isDeploying || !pendingFields || (isInLocalSeo ? !selectedGbp?.id : !JSON.stringify(selectedLocation?.id ))} onClick={async () => {
                      await setIsDeploying(true);
                      const response = await OTTO_V2_API.deployOptimizationsV2(selectedLocation?.id, isInLocalSeo);
                      await getDeployStatus(response.data.id);
                    }}>
                    <FontAwesomeIcon icon={faCodePullRequest} color='#fff' style={{marginRight: 6}}/>{'Deploy all changes'}
                    <Badge style={{background: '#40B261', cursor: 'pointer', border: '1px solid #40B261'}}>
                      {pendingFields}
                    </Badge>
                  </StyledButton>
                </Tooltip>
                <Tooltip overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212'}} title={ (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ))? 'No business selected' :
                  <TooltipContent>
                    <div className='icon-and-text'>
                      <div className='icon'>
                        <FontAwesomeIcon icon={isLockOn ? faShieldCheck : faTriangleExclamation} color={isLockOn ? '#2AC155' : '#F44343'} />
                      </div>
                      <div>
                        <p>
                          {isLockOn ?
                            'Your GBP information is securely locked, safeguarding your business from unauthorized changes. No malicious attempts can alter your details or impact your rankings.' :
                            'Unprotected, your GBP business information can be altered by others, potentially harming your reputation and rankings—without you even knowing.'}
                        </p>
                        {incidents?.length > 0 && <p onClick={viewIncidents} className='clickable-text'>{`View unauthorized attempts ${unviewedIncidents > 0 ? `(${unviewedIncidents} new)` : ''}`}</p>}
                      </div>
                    </div>
                  </TooltipContent>
                }><div className='button-container' style={{background: isLockOn ? '#18923B26' : '#F4434326', cursor: `${ (isInLocalSeo ? selectedGbp?.id : JSON.stringify(selectedLocation?.id ))? 'pointer': 'not-allowed'}`}}>
                    <StyledSwitch loading={lockLoader} disabled={isDeploying || lockLoader || (isInLocalSeo ? !selectedGbp?.id: !JSON.stringify(selectedLocation?.id ))}
                      lockOn={isLockOn} checked={isLockOn} onChange={value => lockOnSwitchHandler(value)} checkedChildren={!lockLoader && <FontAwesomeIcon icon={faLock} color='#18923B' />}
                      unCheckedChildren={!lockLoader && <FontAwesomeIcon icon={faLockOpen} color='#F44343'/>}/>
                    <span>{isLockOn ? 'GBP Information Locked' : 'GBP Information Not Locked'}</span>
                    {unviewedIncidents > 0 && (
                      <Badge lockOn={isLockOn}>{unviewedIncidents}</Badge>
                    )}
                  </div></Tooltip>
              </FreezeWrapper>
            </div>
          </DeployContainer>}
          {!isInGbpProjectOverview && <Header className='header-row'>
            <div className='deploy-cell'>STATUS</div>
            <div className='metric-cell'>METRIC</div>
            <div className='status-cell'>VALUE</div>
            <div className='preview-cell'>PREVIEW</div>
          </Header>}
          {optimizationV2Data ? <StyledContainer isInGbpProjectOverview={isInGbpProjectOverview}>
            <div className='form'>
              {fieldsList.map(field => {
                if (isInGbpProjectOverview && ['reviews', 'q_a']?.includes(field?.fieldName)) {
                  return null;
                }
                return (<>
                  <div className='record-row' style={{alignItems: (field?.label == 'Attributes' || field?.customFieldType == 'popup' ) && 'unset'}}>
                    {!isInGbpProjectOverview && <div className='deploy-status'>
                      {!['reviews', 'q_a'].includes(field?.fieldName) && (checkDeployStatus(field.fieldName) ?
                        <FieldStatus isDefault={true} style={{color: '#2AC155'}}><FontAwesomeIcon icon={faCheckCircle} className='icon' />Deployed</FieldStatus> :
                        <FieldStatus isDisabled={true} style={{color: '#A3A4A4'}}><FontAwesomeIcon icon={faCheckCircle} className='icon'/>Deploy</FieldStatus>)}
                    </div>}
                    <div className='metrics' style={{paddingLeft: !field?.icon && 17}}>{field?.icon && <FontAwesomeIcon
                      style={{marginRight: 6}}
                      icon={field.icon}/>}
                    {field?.label}
                    </div>
                    <div className='status'>
                      {field?.customFieldType == 'string' && <StringField field={field} handleSave={payload => updateData(payload)} suggestion={getFieldSuggestion(field)}/>}
                      {field?.customFieldType == 'popup' && <PopupField field={field} openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} suggestion={getFieldSuggestion(field)}/>}
                      {field?.customFieldType == 'reviews' && <ReviewQAField
                        field={field}
                        unanswered={true}
                        isUnanswered={stats?.completion?.notReplied}
                        isInLocalSeo={isInLocalSeo}
                      />}
                      {field?.customFieldType == 'q_a' && <ReviewQAField
                        field={field}
                        unanswered={false}
                        isUnanswered={questions?.notAnswered}
                        isInLocalSeo={isInLocalSeo}
                      />}
                      {(field?.customFieldType == 'attributes' && !socialMediaAttributes.includes(field?.fieldName)) && <AttributeField field={field} openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} suggestion={getFieldSuggestion(field)}/>}
                      {field?.customFieldType == 'media' && <MediaField field={field} openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} suggestion={getFieldSuggestion(field)}/>}
                      {field?.customFieldType == 'open_hours' && <OpenHoursField field={field} openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} suggestion={getFieldSuggestion(field)}/>}
                    </div>
                  </div>
                  <StyledDivider />
                </>
                );
              },
              )}
            </div>
            <Preview
              optimizationData={optimizationV2Data}
              isInLocalSeo={isInLocalSeo}
              removeTopBar={removeTopBar}
              stats={stats}
            />
          </StyledContainer> : (optimizationV2Data == null || !selectedGbp?.id) ? <NoDataDiv>No Data Available</NoDataDiv> : <NoDataDiv>No Data Available</NoDataDiv>}
          <ModalStyled
            key={key}
            width={selectedField == 'categories' ? 560 : selectedField == 'images' ? 1280 : selectedField == 'incidents' ? 1280 : 800}
            closable={true}
            closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
            mask={true}
            title={selectedField == 'categories' ? 'Business Category' : selectedField == 'images' ? 'Media' : selectedField == 'open_info' ? 'Opening Date' : selectedField == 'incidents' ? <div>Change attempts<br/><a target='_blank'
              rel='noopener noreferrer' href={selectedBusiness?.googlemapsUrl} style={{color: '#2D6CCA', fontWeight: 400, fontSize: 14}}>{selectedLocation?.businessName}<FontAwesomeIcon style={{marginLeft: '6px'}} icon={faArrowUpRightFromSquare} /></a></div> :
              humanize(selectedField)} visible={isModalOpen} footer={false} onClose={() => setIsModalOpen(false)} style={{height: 100}}>
            {isModalOpen && <ModalForms
              updateData={payload => updateData(payload)}
              attributes={attributes}
              selectedAttributes={selectedAttributes?.value}
              services={services}
              selectedField={selectedField}
              categories={categories}
              setIsModalOpen={setIsModalOpen}
              modalSuggestion={modalSuggestion}
              selectedValue={selectedValue}
              setModalLoader={setModalLoader}
              modalLoader={modalLoader}
              incidents={incidents}
              primaryCategory={primaryCategory}
              setCategories={setCategories}
              setServices={setServices}
              selectedLocation={selectedLocation?.id}
              selectedLocationCountryCode={selectedLocationCountryCode}
              isInLocalSeo={isInLocalSeo}
              subGroup={'profile_optimizations'}
            />}
          </ModalStyled>
        </div>
      )}
      {openPhoneNumberModal?.visible && <AddPhoneNumberModal
        modalConfig={openPhoneNumberModal}
        setModalConfig={setOpenPhoneNumberModal}
        selectedValue={selectedValue}
        updateData={updateData}
        setModalLoader={setModalLoader}
        isInLocalSeo={isInLocalSeo}
        categories={categories}
        setCategories={setCategories}
        selectedLocation={selectedLocation?.id}
        modalLoader={modalLoader}
      />}
    </>
  );
});

const StyledConfirmationModal = styled(Modal)`
  background-color: #e8e8e8 !important;
  width: 50% !important; 
  max-width: 600px !important; 
  min-width: 400px;
border-radius: 10px !important;
  padding: 0;
   
  .rc-dialog-content {
    height: 100%;
    .rc-dialog-body {
      
      padding: 25px !important;
      border-radius: 10px !important;
      height: calc(100% - 50px); 
      overflow-y: auto; 
      overflow-x: auto;
      
    }
         
    .rc-dialog-close {
    overflow:none;
      font-size: 21px;
      right: -34px;
      top: 0px;
      font-weight: 700;
      color: #fff !important;
      opacity: 0.8 !important;
      position: absolute;
      z-index: 1; 

      &:hover {
        opacity: 1 !important;
      }
    }
  }
  @media (max-width: 768px) {
    width: 90% !important;
    max-width: 500px !important;

    .rc-dialog-content .rc-dialog-body {
      padding: 15px !important;
    }
  }
  @media (max-width: 480px) {
    .rc-dialog-close {
      right: -20px;
    }
  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap; 
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 15px;
  padding: 6px 10px;

  @media (max-width: 768px) {
    justify-content: center; 
    gap: 12px;
  }
`;

const ButtonStyled = styled(Button)`
  height: auto;
  width: 100px;
  margin: 0px;
  padding: 5px;
  display: flex;
  background-color: #2d6cca;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
  border-radius: 6px;

  &:hover {
    background-color: #2d6cca !important;
    color: #fff !important;
  }

  @media (max-width: 768px) {
    width: 100%; 
    font-size: 12px;
  }
`;
