import {backlinksApi} from '@/api/gsc';
import {backlinksResultsModel} from '@/store/competitor-researcher-v2/overview';
import {flow, types, Instance, cast} from 'mobx-state-tree';


const BacklinkProfileModel = types.model({
  backlink: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  domainRating: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  refdomain: types.maybeNull(types.string),
  dofollowCount: types.maybeNull(types.number),
  backlinkCount: types.maybeNull(types.number),
  minDateRef: types.maybeNull(types.string),
  maxDateRef: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  languageCode: types.maybeNull(types.string),
  anchorText: types.maybeNull(types.string),
  annotationText: types.maybeNull(types.string),
  isImage: types.maybeNull(types.number),
  linkrel: types.maybeNull(types.array(types.string)),
  linkrelRaw: types.maybeNull(types.string),
  tags: types.maybeNull(types.array(types.string)),
  targetPage: types.maybeNull(types.string),
});

export type BacklinkProfileType = Instance<typeof BacklinkProfileModel>;

const GscBacklinksMainModel = types.model({
  status: types.maybeNull(types.string),
  lastIndexedAt: types.maybeNull(types.string),
  backlinkProfile: types.array(BacklinkProfileModel),
  pageSize: types.maybeNull(types.number),
  currentPage: types.maybeNull(types.number),
  totalCount: types.maybeNull(types.number),
  searchQuery: types.maybeNull(types.string),
  sortKey: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  backlink: types.maybeNull(types.string),
});


const GscBacklinksDrawerModel = types.model({
  backlink: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  domainRating: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  lastIndexedAt: types.maybeNull(types.string),
  backlinkProfile: types.array(BacklinkProfileModel),
  traffic: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  refdomain: types.maybeNull(types.string),
  dofollowCount: types.maybeNull(types.number),
  backlinkCount: types.maybeNull(types.number),
  minDateRef: types.maybeNull(types.string),
  maxDateRef: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  languageCode: types.maybeNull(types.string),
  anchorText: types.maybeNull(types.string),
  annotationText: types.maybeNull(types.string),
  isImage: types.maybeNull(types.number),
  linkrel: types.maybeNull(types.array(types.string)),
  linkrelRaw: types.maybeNull(types.string),
  tags: types.maybeNull(types.array(types.string)),
  targetPage: types.maybeNull(types.string),
});

export type BacklinkDrawerProfileType = Instance<typeof GscBacklinksDrawerModel>;

const MainGscBacklinksStore = types.model({
  gscBacklinks: types.maybeNull(GscBacklinksMainModel),
  pageSize: types.maybeNull(types.number),
  currentPage: types.maybeNull(types.number),
  totalCount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  loading: types.boolean,
  pageData: types.maybeNull(types.array(BacklinkProfileModel)),
  searchQuery: types.maybeNull(types.string),
  sortKey: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  showExportModal: types.maybeNull(types.boolean),
});

const GscMainBlDrawerStore = types.model({
  gscBacklinks: types.maybeNull(GscBacklinksDrawerModel),
  pageSize: types.maybeNull(types.number),
  currentPage: types.maybeNull(types.number),
  totalCount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  pageData: types.maybeNull(types.array(GscBacklinksDrawerModel)),
  searchQuery: types.maybeNull(types.string),
  sortKey: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  loading: types.boolean,
});
const GscBacklinksDrawerStore = types.model({
  gscBacklinks: types.maybeNull(GscBacklinksDrawerModel),
  pageSize: types.maybeNull(types.number),
  currentPage: types.maybeNull(types.number),
  totalCount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  pageData: types.maybeNull(types.array(GscBacklinksDrawerModel)),
  searchQuery: types.maybeNull(types.string),
  sortKey: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  loading: types.boolean,
});

const GscAnchorTextDrawerBlProfileStore = types.model({
  maxDateRef: types.maybeNull(types.string),
  minDateRef: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  domainRating: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  languageCode: types.maybeNull(types.string),
  tags: types.maybeNull(types.array(types.string)),
  anchorText: types.maybeNull(types.string),
  annotationText: types.maybeNull(types.string),
  linkrelRaw: types.maybeNull(types.string),
  linkrel: types.maybeNull(types.array(types.string)),
  isImage: types.maybeNull(types.union(types.number, types.string)),
  refdomain: types.maybeNull(types.string),
  backlink: types.maybeNull(types.string),
  targetPage: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const GscAnchorTextDrawerStore = types.model({
  status: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  totalCount: types.maybeNull(types.number),
  lastIndexetAt: types.maybeNull(types.string),
  pageData: types.maybeNull(types.array(GscAnchorTextDrawerBlProfileStore)),
  gscBacklinks: types.maybeNull(GscBacklinksDrawerModel),
  currentPage: types.maybeNull(types.number),
  searchQuery: types.maybeNull(types.string),
  sortKey: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  loading: types.boolean,
});

const GscTopPagesBacklinksDrawerStore = types.model({
  gscBacklinks: types.maybeNull(GscBacklinksDrawerModel),
  pageSize: types.maybeNull(types.number),
  currentPage: types.maybeNull(types.number),
  totalCount: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  pageData: types.maybeNull(types.array(GscBacklinksDrawerModel)),
  searchQuery: types.maybeNull(types.string),
  sortKey: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  loading: types.boolean,
});
const backlinksDetailsDataModel = types.model({
  totalCount: types.maybeNull(types.number),
  results: types.maybeNull(types.array(backlinksResultsModel)),
});

export const GscBacklinksStore = types.model({
  mainPage: MainGscBacklinksStore,
  mainBlDrawer: GscMainBlDrawerStore,
  drawer: GscBacklinksDrawerStore,
  anchorTextDrawer: GscAnchorTextDrawerStore,
  topPages: GscTopPagesBacklinksDrawerStore,
  hasAddedContributor: types.boolean,
  hasAccess: types.boolean,
  loadingBacklinksData: types.boolean,
  urlsOwnedByProfile: types.maybeNull(types.array(types.string)),
  isDrawerOpen: types.maybeNull(types.boolean),
  isMainBlDrawerOpen: types.maybeNull(types.boolean),
  drawerRefdomain: types.maybeNull(types.string),
  isAnchorTextDrawerOpen: types.maybeNull(types.boolean),
  drawerAnchorText: types.maybeNull(types.string),
  mainBlDrawerTargetUrl: types.maybeNull(types.string),
  drawerBacklink: types.maybeNull(types.string),
  topPagesDrawerTargetPage: types.maybeNull(types.string),
  groupedBacklinks: types.boolean,
  groupedBacklinsBy: types.maybeNull(types.string),
  groupedTopPagesBacklinks: types.boolean,
  mainPageInit: types.maybeNull(types.boolean),
  mainBlDrawerInit: types.maybeNull(types.boolean),
  refdomainDrawerInit: types.maybeNull(types.boolean),
  anchorTextDrawerInit: types.maybeNull(types.boolean),
  topPagesDrawerInit: types.maybeNull(types.boolean),
  showRefdomainsExportModal: types.maybeNull(types.boolean),
  showLinkedPagesExportModal: types.maybeNull(types.boolean),
  backlinksDetailsData: types.maybeNull(backlinksDetailsDataModel),
}).views(self => ({
  get getGscBacklinks() {
    return self.mainPage.gscBacklinks;
  },
  get getBacklinksDetailsData() {
    return self.backlinksDetailsData;
  },
})).actions(self => {
  const sorterFormatter = (sortKey: string, sortDirection?: string) => {
    const sorterMap = {
      type: 'type',
      title: 'title',
      domainRating: 'domain_rating',
      traffic: 'traffic',
      targetPage: 'target_page',
      refdomain: 'refdomain',
      backlinkCount: 'backlink_count',
      dofollowCount: 'dofollow_count',
      minDateRef: 'min_date_ref',

    };

    if (!sorterMap[sortKey]) return null;
    if (!sortDirection) return sorterMap[sortKey];
    if (sortDirection && sortDirection === 'descend') return `-${sorterMap[sortKey]}`;
    return sorterMap[sortKey];
  };

  /**
  * Used to fetch data for main GSC Backlinks page table
  * @param property main site property
  * @param type used for table type we are showing
  * @param grouped if true switches to different set of tables
  * @param sortKey name of the sorting column, should not be sent for default sorting
  * @param sortDirection can be 'asc' or 'desc' or not sent for default
  */

  interface GscBacklinksProps {
    property: string;
    type: 'new' | 'lost' | 'default';
  }
  const fetchGscBacklinks = flow(function* ({property, type}: GscBacklinksProps) {
    self.hasAddedContributor = true;
    self.mainPage.loading = true;
    try {
      const data = yield backlinksApi.getGscBacklinks(
        {
          property,
          type,
          pageNumber: self.mainPage.currentPage,
          pageSize: self.mainPage.pageSize,
          ...(self.groupedBacklinsBy && {groupedBy: self.groupedBacklinsBy}),
          ...((self.mainPage.sortKey && self.mainPage.sortDirection) && {sortBy: sorterFormatter(self.mainPage.sortKey, self.mainPage.sortDirection)}),
          ...(self.mainPage.searchQuery && {search: self.mainPage.searchQuery}),
        });

      if (data.isCancel) return;

      self.mainPage.gscBacklinks = cast(data);
      self.mainPage.pageSize = data.pageSize;
      self.mainPage.totalCount = data.totalCount;

      if (data.backlinkProfile) self.mainPage.pageData = cast(data.backlinkProfile);

      if (data.status === 'PENDING') {
        setTimeout(() => {
          fetchGscBacklinks({property: property, type: type});
        }, 3000);
      } else {
        self.mainPage.loading = false;
        if (self.mainPageInit) self.mainPageInit = false;
      }
    } catch (e) {
      self.mainPage.loading = false;
      return Promise.reject(e);
    }
  });


  /**
  * Used to fetch data for main GSC Backlinks grouped by table - sidedrawer
  * @param property main site property
  * @param targetPage targetPage for which we are fetching Backlinks
  * @param type type of table for which we are fetching Backlinks
  */
    interface GscTopPagesBacklinksProps {
      property: string;
      targetPage: string;
      type: 'new' | 'lost' | 'default';
    }
    const fetchGscBacklinksForPage = flow(function* ({property, targetPage, type}: GscTopPagesBacklinksProps) {
      self.hasAddedContributor = true;
      self.topPages.loading = true;
      try {
        const data = yield backlinksApi.getGscBacklinksForPage(
          {
            property,
            targetPage,
            type,
            pageNumber: self.topPages.currentPage,
            pageSize: self.topPages.pageSize,
            ...(self.groupedTopPagesBacklinks && {grouped: self.groupedTopPagesBacklinks}),
            ...((self.topPages.sortKey && self.topPages.sortDirection) && {sortBy: sorterFormatter(self.topPages.sortKey, self.topPages.sortDirection)}),
            ...(self.topPages.searchQuery && {search: self.topPages.searchQuery}),
          });

        if (data.isCancel) return;


        self.topPages.gscBacklinks = cast(data);
        self.topPages.pageSize = data.pageSize;
        self.topPages.totalCount = data.totalCount;

        if (data.backlinkProfile) self.topPages.pageData = cast(data.backlinkProfile);

        if (data.status === 'PENDING') {
          setTimeout(() => {
            fetchGscBacklinksForPage({property, targetPage, type});
          }, 3000);
        } else {
          self.topPages.loading = false;
          if (self.topPagesDrawerInit) self.topPagesDrawerInit = false;
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.topPages.loading = false;
      }
    });


  /**
  * Used to fetch data for main GSC Backlinks grouped by table - sidedrawer
  * @param property main site property
  * @param refdomain refdomain for which we are fetching Backlinks
  */
  interface GscRefdomainBacklinksProps {
    property: string;
    refdomain: string;
  }
  const fetchGscBacklinksForLinkedPages = flow(function* ({property, refdomain}: GscRefdomainBacklinksProps) {
    self.drawer.loading = true;
    // self.drawer.totalCount = 0;
    try {
      const data = yield backlinksApi.getGscBacklinksLinkingPages({
        property,
        refdomain,
        pageNumber: self.drawer.currentPage,
        pageSize: self.drawer.pageSize,
        ...((self.drawer.sortKey && self.drawer.sortDirection) && {sortBy: sorterFormatter(self.drawer.sortKey, self.drawer.sortDirection)}),
        ...(self.drawer.searchQuery && {search: self.drawer.searchQuery}),
      });
      if (data.isCancel) return;

      self.drawer.gscBacklinks = cast(data);
      self.drawer.pageSize = data.pageSize;
      self.drawer.totalCount = data.totalCount;

      if (data.backlinkProfile) self.drawer.pageData = cast(data.backlinkProfile);

      if (data.status === 'PENDING') {
        setTimeout(() => {
          fetchGscBacklinksForLinkedPages({property: property, refdomain: refdomain});
        }, 3000);
      } else {
        self.drawer.loading = false;
        if (self.refdomainDrawerInit) self.refdomainDrawerInit = false;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.drawer.loading = false;
    }
  });
  /**
  * Used to fetch data for main GSC Backlinks grouped by table - sidedrawer
  * @param property main site property
  * @param anchorText anchor Text for which we are fetching Backlinks
  */
  interface GscAnchorTextBacklinksProps {
    property: string;
    anchorText: string;
  }
  const fetchGscBacklinksForAnchorText = flow(function* ({property, anchorText}: GscAnchorTextBacklinksProps) {
    self.anchorTextDrawer.loading = true;
    // self.anchorTextDrawer.totalCount = 0;
    try {
      const data = yield backlinksApi.getGscAnchorTextAnalysis({
        property,
        anchorText,
        pageNumber: self.anchorTextDrawer.currentPage,
        pageSize: self.anchorTextDrawer.pageSize,
        ...((self.anchorTextDrawer.sortKey && self.anchorTextDrawer.sortDirection) && {sortBy: sorterFormatter(self.anchorTextDrawer.sortKey, self.anchorTextDrawer.sortDirection)}),
        ...(self.anchorTextDrawer.searchQuery && {search: self.anchorTextDrawer.searchQuery}),
      });
      if (data.isCancel) return;

      self.anchorTextDrawer.gscBacklinks = cast(data);
      self.anchorTextDrawer.pageSize = data.pageSize;
      self.anchorTextDrawer.totalCount = data.totalCount;

      if (data.backlinkProfile) self.anchorTextDrawer.pageData = cast(data.backlinkProfile);

      if (data.status === 'PENDING') {
        setTimeout(() => {
          fetchGscBacklinksForAnchorText({property, anchorText});
        }, 3000);
      } else {
        self.anchorTextDrawer.loading = false;
        if (self.anchorTextDrawerInit) self.anchorTextDrawerInit = false;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.anchorTextDrawer.loading = false;
    }
  });
  const loadBacklinksDetailsForTable = flow(function* (targetProperty) {
    self.loadingBacklinksData = true;
    try {
      const data = yield backlinksApi.loadBacklinksDetailsData(targetProperty);
      if (data) {
        self.backlinksDetailsData = cast(data);
      }
      if (data.isCancel) return;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingBacklinksData = false;
    }
  });

  /**
  * Used to fetch data for main GSC Backlinks - sidedrawer
  * @param property main site property
  * @param targetUrl target url for which we are fetching Backlinks
  * @param type type of table for which we are fetching Backlinks
  */
     interface GscTargetUrlBacklinksProps {
      property: string;
      targetUrl: string;
      type: 'new' | 'lost' | 'default';
    }
     const fetchGscBacklinksForMainBlDrawer = flow(function* ({property, targetUrl, type}: GscTargetUrlBacklinksProps) {
       self.hasAddedContributor = true;
       self.mainBlDrawer.loading = true;
       try {
         const data = yield backlinksApi.getGscBacklinksForPageMainDrawer(
           {
             property,
             targetPage: targetUrl,
             type,
             pageNumber: self.mainBlDrawer.currentPage,
             pageSize: self.mainBlDrawer.pageSize,
             ...((self.mainBlDrawer.sortKey && self.mainBlDrawer.sortDirection) && {sortBy: sorterFormatter(self.mainBlDrawer.sortKey, self.mainBlDrawer.sortDirection)}),
             ...(self.mainBlDrawer.searchQuery && {search: self.mainBlDrawer.searchQuery}),
           });

         if (data.isCancel) return;

         self.mainBlDrawer.gscBacklinks = cast(data);
         self.mainBlDrawer.pageSize = data.pageSize;
         self.mainBlDrawer.totalCount = data.totalCount;

         if (data.backlinkProfile) self.mainBlDrawer.pageData = cast(data.backlinkProfile);

         if (data.status === 'PENDING') {
           setTimeout(() => {
             fetchGscBacklinksForMainBlDrawer({property, targetUrl, type});
           }, 3000);
         }
       } catch (e) {
         return Promise.reject(e);
       } finally {
         self.mainBlDrawer.loading = false;
         if (self.mainBlDrawerInit) self.mainBlDrawerInit = false;
       }
     });

     const setConnectionStatusData = (data: {hasAccess: boolean; urlsOwnedByProfil: string[]}) => {
       self.hasAccess = data.hasAccess;
       self.urlsOwnedByProfile = cast(data.urlsOwnedByProfil);
     };

     /*
  * Checking user connection status for conditional data rendering
  */
     const checkConnectionStatus = flow(function* (property: string, type: 'new' | 'lost' | 'default') {
       self.mainPage.loading = true;

       try {
         const statusCheck = yield backlinksApi.checkBacklinksConnectionStatus(property);
         setConnectionStatusData(statusCheck);
         if (statusCheck.hasAccess) {
           self.hasAddedContributor = true;
           yield backlinksApi.processGscBacklinks(property);
           fetchGscBacklinks({property, type});
         } else {
           self.hasAddedContributor = false;
         }
       } catch (e) {
         return Promise.reject(e);
       } finally {
         self.mainPage.loading = false;
       }
     });


     // Setting current page number for main GSC Backlinks page table
     const setCurrentPage = (pageNum: number) => self.mainPage.currentPage = pageNum;
     const setPageSize = (pageSize: number) => self.mainPage.pageSize = pageSize;
     const setSearchQuery = (query: string) => self.mainPage.searchQuery = query;
     const setSortKey = (key: string) => self.mainPage.sortKey = key;
     const setSortDirection = (direction: string) => self.mainPage.sortDirection = direction;
     const setShowMainExportModal = (value: boolean) => self.mainPage.showExportModal = value;


     // Setting Page size for main GSC Backlinks page table pagination
     const setDrawerPageSize = (pageSize: number) => self.drawer.pageSize = pageSize;
     const setDrawerSearchQuery = (query: string) => self.drawer.searchQuery = query;
     const setDrawerSortKey = (key: string) => self.drawer.sortKey = key;
     const setDrawerSortDirection = (direction: string) => self.drawer.sortDirection = direction;
     const clearDrawerData = () => {
       if (self.drawer.pageData) self.drawer.pageData.length = 0;
     };
     const setCurrentDrawerPage = (pageNum: number) => self.drawer.currentPage = pageNum;


     // Sets target page url when opening GSC Top Pages -> target page drawer
     const setTopPagesDrawerTargetPage = (targetPage: string) => {
       const formatTargetPage = targetPage?.endsWith('/') ? targetPage : targetPage + '/';
       self.topPagesDrawerTargetPage = formatTargetPage;
     };
     const setTopPagesDrawerPage = (pageNum: number) => self.topPages.currentPage = pageNum;
     const setTopPagesPageSize = (pageSize: number) => self.topPages.pageSize = pageSize;
     const setTopPagesSearchQuery = (query: string) => self.topPages.searchQuery = query;
     const setTopPagesSortKey = (key: string) => self.topPages.sortKey = key;
     const setTopPagesSortDirection = (direction: string) => self.topPages.sortDirection = direction;

     // Handles main GSC Backlinks page -> Linking Pages drawer
     const handleToggleDrawer = (value: boolean) => self.isDrawerOpen = value;
     const setDrawerRefdomain = (refdomain: string) => self.drawerRefdomain = refdomain;
     const setDrawerBacklink = (backlink: string) => self.drawerBacklink = backlink;
     const setShowRefdomainsExportModal = (value: boolean) => self.showRefdomainsExportModal = value;

     // Handles main GSC Backlinks page -> Anchor Text drawer
     const handleToggleAnchorTextDrawer = (value: boolean) => self.isAnchorTextDrawerOpen = value;
     const setDrawerAnchorText = (text: string) => self.drawerAnchorText = text;
     const setAnchorTextDrawerPageSize = (pageSize: number) => self.anchorTextDrawer.pageSize = pageSize;
     const setAnchorTextDrawerSearchQuery = (query: string) => self.anchorTextDrawer.searchQuery = query;
     const setAnchorTextDrawerSortKey = (key: string) => self.anchorTextDrawer.sortKey = key;
     const setAnchorTextDrawerSortDirection = (direction: string) => self.anchorTextDrawer.sortDirection = direction;
     const clearAnchorTextDrawerData = () => {
       if (self.anchorTextDrawer.pageData) self.anchorTextDrawer.pageData.length = 0;
     };
     const setCurrentAnchorTextDrawerPage = (pageNum: number) => self.anchorTextDrawer.currentPage = pageNum;

     // Handles main GSC Backlinks page -> main bl drawer
     const handleToggleMainBlDrawer = (value: boolean) => self.isMainBlDrawerOpen = value;
     const setMainBlDrawerTargetUrl = (url: string) => self.mainBlDrawerTargetUrl = url;
     const setMainBlDrawerPageSize = (pageSize: number) => self.mainBlDrawer.pageSize = pageSize;
     const setMainBlDrawerSearchQuery = (query: string) => self.mainBlDrawer.searchQuery = query;
     const setMainBlDrawerSortKey = (key: string) => self.mainBlDrawer.sortKey = key;
     const setMainBlDrawerSortDirection = (direction: string) => self.mainBlDrawer.sortDirection = direction;
     const setCurrentMainBlDrawerPage = (pageNum: number) => self.mainBlDrawer.currentPage = pageNum;
     const clearMainBlDrawerData = () => self.mainBlDrawer.pageData.length = 0;
     const setShowLinkedPagesExportModal = (value: boolean) => self.showLinkedPagesExportModal = value;

     // Setting value when switching from standard to grouped backlinks table
     const setGroupedBacklinks = (grouped: boolean) => self.groupedBacklinks = grouped;
     const setGroupedBacklinksBy = (grouped: string) => self.groupedBacklinsBy = grouped;
     const setTopPagesGroupedBacklinks = (grouped: boolean) => self.groupedTopPagesBacklinks = grouped;


     // Setting initial loaders for all GSC Backlinks tables
     const setMainPageInit = (value: boolean) => self.mainPageInit = value;
     const setMainBlDrawerInit = (value: boolean) => self.mainBlDrawerInit = value;
     const setRefDomainDrawerInit = (value: boolean) => self.refdomainDrawerInit = value;
     const setTopPagesDrawerInit = (value: boolean) => self.topPagesDrawerInit = value;

     return {
       fetchGscBacklinks,
       loadBacklinksDetailsForTable,
       fetchGscBacklinksForLinkedPages,
       checkConnectionStatus,
       setCurrentPage,
       handleToggleDrawer,
       setDrawerRefdomain,
       setCurrentDrawerPage,
       setDrawerBacklink,
       setTopPagesDrawerTargetPage,
       setTopPagesDrawerPage,
       fetchGscBacklinksForPage,
       setPageSize,
       setSearchQuery,
       setSortKey,
       setSortDirection,
       setGroupedBacklinks,
       setDrawerPageSize,
       setDrawerSearchQuery,
       setDrawerSortKey,
       setDrawerSortDirection,
       clearDrawerData,
       handleToggleMainBlDrawer,
       setMainBlDrawerTargetUrl,
       setCurrentMainBlDrawerPage,
       setMainBlDrawerSearchQuery,
       setMainBlDrawerSortKey,
       setMainBlDrawerSortDirection,
       clearMainBlDrawerData,
       setMainBlDrawerPageSize,
       fetchGscBacklinksForMainBlDrawer,
       setMainPageInit,
       setMainBlDrawerInit,
       setRefDomainDrawerInit,
       setTopPagesPageSize,
       setTopPagesSearchQuery,
       setTopPagesSortKey,
       setTopPagesSortDirection,
       setTopPagesDrawerInit,
       setTopPagesGroupedBacklinks,
       setShowMainExportModal,
       setShowLinkedPagesExportModal,
       setShowRefdomainsExportModal,
       setGroupedBacklinksBy,
       handleToggleAnchorTextDrawer,
       setDrawerAnchorText,
       fetchGscBacklinksForAnchorText,
       setAnchorTextDrawerPageSize,
       setAnchorTextDrawerSearchQuery,
       setAnchorTextDrawerSortKey,
       setAnchorTextDrawerSortDirection,
       clearAnchorTextDrawerData,
       setCurrentAnchorTextDrawerPage,
     };
});

export const initGscBacklinksStore = () => {
  return GscBacklinksStore.create({
    mainPage: {
      gscBacklinks: {
        status: 'PENDING',
        lastIndexedAt: '',
        backlinkProfile: [],
      },
      loading: false,
      pageSize: 10,
      currentPage: 1,
      totalCount: null,
      status: '',
    },
    mainBlDrawer: {
      gscBacklinks: {
        status: '',
        lastIndexedAt: '',
        backlinkProfile: [],
      },
      loading: false,
      pageSize: 10,
      currentPage: 1,
      totalCount: null,
      status: '',
    },
    drawer: {
      gscBacklinks: {
        status: '',
        lastIndexedAt: '',
        backlinkProfile: [],
      },
      loading: false,
      pageSize: 10,
      currentPage: 1,
      totalCount: null,
      status: '',
    },
    anchorTextDrawer: {
      gscBacklinks: {
        status: '',
        lastIndexedAt: '',
        backlinkProfile: [],
      },
      loading: false,
      pageSize: 10,
      currentPage: 1,
      totalCount: null,
      status: '',
    },
    topPages: {
      gscBacklinks: {
        status: '',
        lastIndexedAt: '',
        backlinkProfile: [],
      },
      loading: false,
      pageSize: 10,
      currentPage: 1,
      totalCount: null,
      status: '',
    },
    hasAddedContributor: true,
    hasAccess: false,
    urlsOwnedByProfile: [],
    isDrawerOpen: false,
    isMainBlDrawerOpen: false,
    groupedBacklinks: false,
    groupedBacklinsBy: '',
    groupedTopPagesBacklinks: false,
    mainPageInit: true,
    mainBlDrawerInit: true,
    refdomainDrawerInit: true,
    topPagesDrawerInit: true,
    loadingBacklinksData: true,
    showRefdomainsExportModal: false,
    showLinkedPagesExportModal: false,
  });
};
