import {topPagesApi} from '@/api/gsc';
import {notification} from '@/utils/notification-v2';
import {types, flow, cast} from 'mobx-state-tree';
import {getSingleUrlParam} from '@/utils/url';

import {getSingleUrlParamHash} from '@/utils/url';
const overviewModel = types.model({
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  topPages: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  totalTrafficValue: types.maybeNull(types.number),
});

const scatteredModel = types.model({
  page: types.maybeNull(types.string),
  totalKeywords: types.maybeNull(types.number),
  totalImpressions: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  rankByTotalKw: types.maybeNull(types.string),
  rankByAvgPosition: types.maybeNull(types.string),
});

export const TopPagesStore = types.model({
  siteUrl: types.maybeNull(types.string),
  overview: overviewModel,
  scattered: types.array(scatteredModel),
  loading: types.boolean,
}).actions(self => {
  const getTopPagesData = flow(function* (property, countryCode, periodStart, periodEnd) {
    const publicHash = getSingleUrlParamHash('public_hash') || getSingleUrlParam('hash');
    self.loading = true;
    try {
      const response = yield topPagesApi.getOrganicSearchReports(property, countryCode, periodStart, periodEnd, publicHash);
      if (response) {
        self.siteUrl = response.siteUrl;
        if (response.overview) {
          self.overview.totalImpressions = response.overview.totalImpressions;
          self.overview.totalTraffic = response.overview.totalTraffic;
          self.overview.topPages = response.overview.topPages;
          self.overview.totalPages = response.overview.totalPages;
          self.overview.totalTrafficValue = response.overview.totalTrafficValue;
        }
        self.scattered = cast(response.scattered);
      }
    } catch (e) {
      notification.error('An error has occurred', 'There was an error fetching top pages data. Please try again later.', true);
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });
  return {
    getTopPagesData,
  };
});

export const initTopPagesStore = () => {
  return TopPagesStore.create({
    siteUrl: '',
    overview: {
      totalImpressions: null,
      totalTraffic: null,
      topPages: null,
      totalPages: null,
      totalTrafficValue: null,
    },
    scattered: [],
    loading: true,
  });
};
