import {types, flow, cast} from 'mobx-state-tree';
import {issueAnalyzerApi} from '@/api/account';

const REPORTS_LIST_DEFAULT = {
  totalPages: 0,
  contentDistribution: {css: 0,
    html: 0,
    image: 0,
    js: 0,
    other: 0},
  crawlability: {
    indexable: 0,
    nonIndexable: 0,
  },
  httpStatus: {
    error: 0,
    redirect: 0,
    success: 0,
  },
  markup: {
    with: 0,
    without: 0,
  },
  performance: {
    '3S': 0,
    '005S': 0,
    '13S': 0,
    '051S': 0,
  },
  sitemap: {
    included: 0,
    notIncluded: 0,
  },
};
const contentDistributionContent =types.model({
  css: types.number,
  html: types.number,
  image: types.number,
  js: types.number,
  other: types.number,
});
const crawlabilityContent =types.model({
  indexable: types.number,
  nonIndexable: types.number,
});
const httpStatusContent =types.model({
  error: types.number,
  redirect: types.number,
  success: types.number,
});
const markupContent =types.model({
  with: types.number,
  without: types.number,
});
const performanceContent =types.model({
  '3S': types.number,
  '005S': types.number,
  '13S': types.number,
  '051S': types.number,
});
const sitemapContent =types.model({
  included: types.number,
  notIncluded: types.number,
});

const reportsListContent = types.model({
  totalPages: types.maybeNull(types.number),
  contentDistribution: types.maybeNull(contentDistributionContent),
  crawlability: types.maybeNull(crawlabilityContent),
  httpStatus: types.maybeNull(httpStatusContent),
  markup: types.maybeNull(markupContent),
  performance: types.maybeNull(performanceContent),
  sitemap: types.maybeNull(sitemapContent),
});


const header = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  filter: types.maybeNull(types.string),
});

const h1Setup = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const h1LenDistribution = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const metaDescSetup = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const titleTagSetup = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const metaDescLengthDistribution = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const titleLengthDistribution = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const ChartSeriesItem = types.model({
  data: types.maybeNull(types.array(types.number)),
  filters: types.maybeNull(types.array(types.string)),
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const Chart = types.model({
  xAxisLabels: types.maybeNull(types.array(types.string)),
  series: types.maybeNull(types.array(ChartSeriesItem)),
  colors: types.maybeNull(types.array(types.string)),
});

const contentData = types.model({
  headers: types.maybeNull(types.array(header)),
  h1Setup: types.maybeNull(types.array(h1Setup)),
  metaDescLengthDistribution: types.maybeNull(types.array(metaDescLengthDistribution)),
  titleLengthDistribution: types.maybeNull(types.array(titleLengthDistribution)),
  titleTagSetup: types.maybeNull(types.array(titleTagSetup)),
  metaDescSetup: types.maybeNull(types.array(metaDescSetup)),
  h1LenDistribution: types.maybeNull(types.array(h1LenDistribution)),
  duplicateContentDistribution: types.maybeNull(Chart),
  pagesByWordCount: types.maybeNull(Chart),
});

const socialTagsHeader = types.model({
  value: types.maybeNull(types.number),
  filter: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
});

const labelAndValue = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const labelValueColor = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const socialTagsDataModel = types.model({
  headers: types.maybeNull(types.array(socialTagsHeader)),
  basicOpenGraphTags: types.maybeNull(types.array(labelValueColor)),
  ogTypeDistribution: types.maybeNull(types.array(labelValueColor)),
  openGraphTagsDistribution: types.maybeNull(Chart),
  basicTwitterCardTags: types.maybeNull(types.array(labelValueColor)),
  twitterCardTypeDistribution: types.maybeNull(types.array(labelValueColor)),
  pagesWithSummaryTwitterCard: types.maybeNull(Chart),
});

const Content = types.model({
  title: types.string,
  count: types.number,
  percentage: types.number,
});


const AffectedPagesDetail = types.model({
  healthScore: types.maybeNull(types.number),
  issueCount: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const IssueDetail = types.model({
  affectedPages: types.optional(types.array(AffectedPagesDetail), []),
  compliantPages: types.optional(types.array(AffectedPagesDetail), []),
  description: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
  isIssue: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  severity: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const LineSeriesItem = types.model({
  data: types.maybeNull(types.array(types.number)),
  filters: types.maybeNull(types.array(types.string)),
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const statusCodeByDepth = types.model({
  xAxisLabels: types.maybeNull(types.array(types.string)),
  series: types.maybeNull(types.array(LineSeriesItem)),
  colors: types.maybeNull(types.array(types.string)),
});

const contentTypes = types.model({
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const hreflangTags = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const issuesSummaryModel = types.model({
  avgPosition: types.maybeNull(types.number),
  compliantCount: types.maybeNull(types.number),
  contentScore: types.maybeNull(types.number),
  indexability: types.maybeNull(types.boolean),
  issueCount: types.maybeNull(types.number),
  maxSeverity: types.maybeNull(types.number),
  speedIndex: types.maybeNull(types.number),
  statusCode: types.maybeNull(types.number),
  technicalScore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  warningCount: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
});

const langTagStatus = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const localizationData = types.model({
  headers: types.maybeNull(types.array(header)),
  hreflangTags: types.maybeNull(types.array(hreflangTags)),
  langTagStatus: types.maybeNull(types.array(langTagStatus)),
  pagesDeclaredAsHreflang: types.maybeNull(Chart),
});

const internalPagesContent = types.model({
  headers: types.maybeNull(types.array(header)),
  statusCodeDistribution: types.maybeNull(types.array(labelValueColor)),
  topSubdomains: types.maybeNull(types.array(labelAndValue)),
  contentTypes: types.maybeNull(types.array(contentTypes)),
  statusCodeByDepth: types.maybeNull(statusCodeByDepth),
  protocolsDistribution: types.maybeNull(types.array(labelValueColor)),
});

export const IssueAnalyzerStore = types.model({
  errorDistributionIssues: types.maybeNull(types.array(Content)),
  localizationData: types.maybeNull(localizationData),
  errorDistributiontypes: types.maybeNull(types.array(Content)),
  issuesListTotal: types.maybeNull(types.array(IssueDetail)),
  contentData: types.maybeNull(contentData),
  socialTagsData: types.maybeNull(socialTagsDataModel),
  reportsList: types.maybeNull(reportsListContent),
  internalPages: types.maybeNull(internalPagesContent),
  selectedDomain: types.maybeNull(types.string),
  isOpenIssuesDrawer: types.maybeNull(types.boolean),
  issuesSummaryDetail: types.maybeNull(types.array(issuesSummaryModel)),
  loading: types.boolean,
  loadingTableData: types.boolean,

}).views(self => ({
  get showInternalPages() {
    return self.internalPages;
  }})).actions(self => {
  const setIsOpenIssuesDrawer = (open: boolean) => {
    self.isOpenIssuesDrawer = open;
  };

  const setDomain = (domain: string) => {
    self.selectedDomain = domain;
  };
  const getDistributionsList = distributionsDetail => {
    const distributionsData = [];
    if (distributionsDetail) {
      for (const [key, value] of Object.entries(distributionsDetail)) {
        distributionsData.push({
          title: key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
            return str.toUpperCase();
          }),
          count: value['count'] || 0,
          percentage: value['percentage'] || 0,
        });
      }
    }
    return distributionsData?.sort((a, b)=> (a.count < b.count ? 1 : -1));
  };

  const loadIssueList = flow(function* (domain: string) {
    self.loading = true;
    self.loadingTableData = true,
    resetIssues();
    try {
      const response = yield issueAnalyzerApi.issueList(domain);
      self.issuesListTotal = cast(response?.results);
      self.errorDistributionIssues = cast(getDistributionsList(response?.summaryStats?.distribution?.issues));
      self.errorDistributiontypes = cast(getDistributionsList(response?.summaryStats?.distribution?.type));
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
      self.loadingTableData = false;
    }
  });

  const loadIssueChartSummary = flow(function* (domain: string) {
    self.loading = true;
    self.loadingTableData = true,
    resetIssues();
    try {
      const response = yield issueAnalyzerApi.issueSummary(domain);
      self.issuesSummaryDetail = response.pagesData;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
      self.loadingTableData = false;
    }
  });

  const loadInternalReport = flow(function* (domain: string, filter='') {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.internalReportList(domain, filter);
      self.internalPages = cast({
        headers: response.headers,
        protocolsDistribution: response.protocolsDistribution,
        statusCodeDistribution: response.statusCodeDistribution,
        topSubdomains: response.topSubdomains,
        statusCodeByDepth: response.statusCodeByDepth,
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
      self.loadingTableData = false;
    }
  });
  const loadReportList = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.reportList(domain);
      self.reportsList.contentDistribution = response?.contentDistribution;
      self.reportsList.crawlability = response?.crawlability;
      self.reportsList.httpStatus = response?.httpStatus;
      self.reportsList.markup = response?.markup;
      self.reportsList.performance = response?.performance;
      self.reportsList.sitemap = response?.sitemap;
      self.reportsList.totalPages = response?.totalPages;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  const loadContentDataList = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.contentDataList(domain);
      self.contentData =cast(response);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  const loadLocalizationDataList = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.localizationReportList(domain);
      self.localizationData =cast(response);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  const loadSocialTagsData = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.getSocialTagsData(domain);
      self.socialTagsData =cast(response);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  const resetIssues = () => {
    self.errorDistributionIssues = cast([]);
    self.errorDistributiontypes = cast([]);
  };

  return {
    setDomain,
    loadIssueList,
    loadReportList,
    loadInternalReport,
    loadContentDataList,
    loadIssueChartSummary,
    loadLocalizationDataList,
    setIsOpenIssuesDrawer,
    loadSocialTagsData,
  };
});

export function initIssueAnalyzerStore() {
  return IssueAnalyzerStore.create({
    loading: true,
    loadingTableData: true,
    issuesListTotal: [],
    errorDistributionIssues: [],
    errorDistributiontypes: [],
    isOpenIssuesDrawer: false,
    selectedDomain: '',
    reportsList: REPORTS_LIST_DEFAULT,
    internalPages: {},
  });
}
