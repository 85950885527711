import {flow, Instance, types, cast} from 'mobx-state-tree';
import {CONTENT_AUDIT_API} from '@/api/content-optimizer';
import {API_POLL_INTERVAL_CONTENT_AUDIT} from '@/constants';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import {getTokenFromCookies} from '@/api/common-utils';
import Router from 'next/router';
import html2canvas from 'html2canvas';
import {toJS} from 'mobx';
import {OVERVIEW_DETAIL_API} from '@/api/keyword-explorer';
import {handleNetworkError} from '@/utils/api';

const citationSerpsModel = types.model({
  metaDesc: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const commonCitationsModel = types.model({
  count: types.maybeNull(types.number),
  metaDesc: types.maybeNull(types.string),
  serps: types.maybeNull(types.array(citationSerpsModel)),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const competitorsDetailDataModel = types.model({
  contentScore: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  readability: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});

const competitorsDataModel = types.model({
  competitors: types.maybeNull(types.array(competitorsDetailDataModel)),
  keyword: types.maybeNull(types.string),
});

const focusTermsCompetitorsModel = types.model({
  contentScore: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  readability: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});

const focusTermsDetailModel = types.model({
  competitors: types.maybeNull(focusTermsCompetitorsModel),
  cpc: types.maybeNull(types.number),
  idealCountMax: types.maybeNull(types.number),
  idealCountMin: types.maybeNull(types.number),
  isBranded: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  normalizedScore: types.maybeNull(types.number),
  requiredCountMax: types.maybeNull(types.number),
  requiredCountMin: types.maybeNull(types.number),
  source: types.maybeNull(types.array(types.string)),
  status: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  usedCount: types.maybeNull(types.number),
  variations: types.maybeNull(types.array(types.string)),
});

const chartAverageModel = types.model({
  targetRangeMax: types.maybeNull(types.number),
  targetRangeMin: types.maybeNull(types.number),
});

const keywordSuggestionsUsageBySitesModel = types.model({
  name: types.maybeNull(types.string),
  values: types.maybeNull(types.array(types.number)),
});

const keywordSuggestionsSitesModel = types.model({
  contentScore: types.maybeNull(types.optional(types.number, 0)),
  hostname: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  readability: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});

const keywordSuggestionsGridDataModel = types.model({
  countryCode: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  keywordSuggestionsUsageBySites: types.maybeNull(types.array(keywordSuggestionsUsageBySitesModel)),
  location: types.maybeNull(types.number),
  locationId: types.maybeNull(types.number),
  sites: types.maybeNull(types.array(keywordSuggestionsSitesModel)),
});

const questionsModel = types.model({
  cpc: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  usedCount: types.maybeNull(types.number),
});

const statusModel = types.model({
  commonCitationsTaskStatus: types.maybeNull(types.string),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  serpTaskStatus: types.maybeNull(types.string),
});

const svTopCountriesModel = types.model({
  countryCode: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
});

const targetKeywordsModel = types.model({
  contentAnalysisProcessedAt: types.maybeNull(types.string),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  globalSv: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  kd: types.maybeNull(types.number),
  kdErrorMessage: types.maybeNull(types.string),
  kdTaskStatus: types.maybeNull(types.string),
  kdUpdatedAt: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  location: types.maybeNull(types.number),
  locationId: types.maybeNull(types.number),
  processingTimeSeconds: types.maybeNull(types.number),
  rankingPotential: types.maybeNull(types.string),
  serpTaskStatus: types.maybeNull(types.string),
  serpUpdatedAt: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  svTopCountries: types.maybeNull(types.array(svTopCountriesModel)),
  totalSearchResults: types.maybeNull(types.number),
});

const suggestedWordCountLimitsModel = types.model({
  lower: types.maybeNull(types.number),
  upper: types.maybeNull(types.number),
});

const focusTermsModel = types.model({
  commonCitations: types.maybeNull(types.array(commonCitationsModel)),
  competitorsData: types.maybeNull(types.array(competitorsDataModel)),
  focusTerms: types.maybeNull(types.array(focusTermsDetailModel)),
  // focusTermsGridData: types.maybeNull(types.array(focusTermsGridDataModel)),
  keywordSuggestionsGridData: types.maybeNull(types.array(keywordSuggestionsGridDataModel)),
  questions: types.maybeNull(types.array(questionsModel)),
  score: types.maybeNull(types.number),
  shouldRepoll: types.maybeNull(types.boolean),
  status: types.maybeNull(types.array(statusModel)),
  suggestedWordCountLimits: types.maybeNull(suggestedWordCountLimitsModel),
  targetKeywords: types.maybeNull(types.array(targetKeywordsModel)),
});

const internallinkSuggestionsResultsModel = types.model({
  countryCode: types.maybeNull(types.string),
  dt: types.maybeNull(types.string),
  isUsed: types.maybeNull(types.boolean),
  keyword: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  snippet: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.union(types.string, types.array(types.string))),
});

const internallinkSuggestionsModel = types.model({
  keyword: types.maybeNull(types.string),
  results: types.maybeNull(types.array(internallinkSuggestionsResultsModel)),
});

const kwsInBodyDataModel = types.model({
  explanation: types.maybeNull(types.string),
  extras: types.maybeNull(chartAverageModel),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

const kwsInBodyModel = types.model({
  data: types.maybeNull(kwsInBodyDataModel),
  keyword: types.maybeNull(types.string),
});

const pageStructureGraphCompetitorsData = types.model({
  hasLowContent: types.maybeNull(types.boolean),
  hostname: types.maybeNull(types.string),
  kwsInBody: types.maybeNull(types.number),
  kwsInBodyPer100: types.maybeNull(types.number),
  kwsInH1: types.maybeNull(types.number),
  kwsInH2ToH6: types.maybeNull(types.number),
  kwsInTitle: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  strongBElements: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const pageStructureGraphYourPageData = types.model({
  hostname: types.maybeNull(types.string),
  kwsInBody: types.maybeNull(types.number),
  kwsInBodyPer100: types.maybeNull(types.number),
  kwsInH1: types.maybeNull(types.number),
  kwsInH2ToH6: types.maybeNull(types.number),
  kwsInTitle: types.maybeNull(types.number),
  strongBElements: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const pageStructureGraphData = types.model({
  competitors: types.maybeNull(types.array(pageStructureGraphCompetitorsData)),
  keyword: types.maybeNull(types.string),
  yourPage: types.maybeNull(pageStructureGraphYourPageData),
});

const pageStructureModel = types.model({
  graphData: types.maybeNull(types.array(pageStructureGraphData)),
  kwsInBody: types.maybeNull(types.array(kwsInBodyModel)),
  kwsInBodyPer100: types.maybeNull(types.array(kwsInBodyModel)),
  kwsInH1: types.maybeNull(types.array(kwsInBodyModel)),
  kwsInH2ToH6: types.maybeNull(types.array(kwsInBodyModel)),
  kwsInTitle: types.maybeNull(types.array(kwsInBodyModel)),
  strongBElements: types.maybeNull(types.array(kwsInBodyModel)),
});

const technicalHeadersValueModel = types.model({
  count: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  values: types.maybeNull(types.array(types.string)),
});

const technicalIframesModel = types.model({
  count: types.maybeNull(types.number),
  iframe: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    src: types.maybeNull(types.string),
    content: types.maybeNull(types.string),
    height: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    visitable: types.maybeNull(types.boolean),
    width: types.maybeNull(types.string),
  }))),
});

const technicalImagesValuesModel = types.model({
  alt: types.maybeNull(types.string),
  bytes: types.maybeNull(types.number),
  loading: types.maybeNull(types.string),
  size: types.maybeNull(types.string),
  src: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  visitable: types.maybeNull(types.boolean),
});

const technicalImagesModel = types.model({
  count: types.maybeNull(types.number),
  img: types.maybeNull(types.array(technicalImagesValuesModel)),
  status: types.maybeNull(types.string),
});

const technicalsJsDetailModel = types.model({
  bytes: types.maybeNull(types.number),
  src: types.maybeNull(types.string),
  visitable: types.maybeNull(types.boolean),
});

const technicalJsModel = types.model({
  count: types.maybeNull(types.number),
  js: types.maybeNull(types.array(technicalsJsDetailModel)),
});

const technicalLinksExternalValuesModel = types.model({
  anchor: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.string),
  isDofollow: types.maybeNull(types.boolean),
  isRedirect: types.maybeNull(types.boolean),
  ordinal: types.maybeNull(types.number),
  redirectsTo: types.maybeNull(types.string),
  statusCode: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const technicalLinksExternalModel = types.model({
  count: types.maybeNull(types.number),
  links: types.maybeNull(types.array(technicalLinksExternalValuesModel)),
});

const technicalMetaSummaryDetailsModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

const technicalSocialDetailValueModel = types.model({
  type: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const technicalSocialModel = types.model({
  count: types.maybeNull(types.number),
  social: types.maybeNull(types.array(technicalSocialDetailValueModel)),
  status: types.maybeNull(types.string),
});

const technicalTechnicalScoreModel = types.model({
  status: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  suggestion: types.maybeNull(types.string),
  technicalScore: types.maybeNull(types.number),
  dispositionIndicator: types.maybeNull(types.string),
});

const videoDetailModel = types.model({
  size: types.maybeNull(types.string),
  src: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
});

const technicalVideosModel = types.model({
  count: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  video: types.maybeNull(types.array(videoDetailModel)),
});

const technicalHeadersDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(types.array(technicalHeadersValueModel)),
});

const technicalIframesDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(technicalIframesModel),
});

const technicalImagesDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(technicalImagesModel),
});

const technicalJsDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(technicalJsModel),
});

const technicalLinksExternalDetailsModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(technicalLinksExternalModel),
});

const technicalVideosDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(technicalVideosModel),
});

const technicalSchemaDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(types.frozen({})),
});

const technicalSocialDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(technicalSocialModel),
});

const metricsDesktopDetailModel = types.model({
  color: types.maybeNull(types.string),
  displayValue: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  numericUnit: types.maybeNull(types.string),
  numericValue: types.maybeNull(types.number),
  score: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
});

const metricsValueDesktopModel = types.model({
  cumulativeLayoutShift: types.maybeNull(metricsDesktopDetailModel),
  firstInputDelay: types.maybeNull(metricsDesktopDetailModel),
  largestContentfulPaint: types.maybeNull(metricsDesktopDetailModel),
  speedIndex: types.maybeNull(metricsDesktopDetailModel),
});

const metricsValuesModel = types.model({
  desktop: types.maybeNull(metricsValueDesktopModel),
  mobile: types.maybeNull(metricsValueDesktopModel),
});

const psiMetricsDetailModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.array(types.model({
    string: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
  }))),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(metricsValuesModel),
  valueDescription: types.maybeNull(types.string),
});

const technicalMetaSummaryModel = types.model({
  canonical: types.maybeNull(technicalMetaSummaryDetailsModel),
  metaDesc: types.maybeNull(technicalMetaSummaryDetailsModel),
  robotsMeta: types.maybeNull(technicalMetaSummaryDetailsModel),
  robotsTxt: types.maybeNull(technicalMetaSummaryDetailsModel),
  title: types.maybeNull(technicalMetaSummaryDetailsModel),
  sitemap: types.maybeNull(technicalMetaSummaryDetailsModel),
  headers: types.maybeNull(technicalHeadersDetailModel),
  iframes: types.maybeNull(technicalIframesDetailModel),
  images: types.maybeNull(technicalImagesDetailModel),
  js: types.maybeNull(technicalJsDetailModel),
  externalLinks: types.maybeNull(technicalLinksExternalDetailsModel),
  internalLinks: types.maybeNull(technicalLinksExternalDetailsModel),
  schema: types.maybeNull(technicalSchemaDetailModel),
  social: types.maybeNull(technicalSocialDetailModel),
  videos: types.maybeNull(technicalVideosDetailModel),
  psiMetrics: types.maybeNull(psiMetricsDetailModel),
});

const technicalsModel = types.model({
  metaSummary: types.maybeNull(technicalMetaSummaryModel),
  technicalScore: types.maybeNull(technicalTechnicalScoreModel),
});

const chartReadabilityAverageModel = types.model({
  targetRange: types.maybeNull(types.string),
});

const contentScoreDataModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  extras: types.maybeNull(chartAverageModel),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  // valueDescription: null
});

const contentCompetitorsModel = types.model({
  contentScore: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
  timeElapsed: types.maybeNull(types.number),
  missingWordsInTitle: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  readability: types.maybeNull(types.string),
  readabilityRange: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  refdomainsCount: types.maybeNull(types.number),
});


const contentPerformanceModel = types.model({
  competitors: types.maybeNull(types.array(contentCompetitorsModel)),
  keyword: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  yourPage: types.maybeNull(contentCompetitorsModel),
  avgRefdomains: types.maybeNull(types.number),
});

const contentWordCountModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  extras: types.maybeNull(chartAverageModel),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  // valueDescription: null
});

const contentReadabilityModel = types.model({
  dispositionIndicator: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string),
  extras: types.maybeNull(chartReadabilityAverageModel),
  field: types.maybeNull(types.string),
  recommendation: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  // valueDescription: null
});

const contentScoreModel = types.model({
  keyword: types.maybeNull(types.string),
  data: types.maybeNull(contentScoreDataModel),
});

const contentReadabilityDataModel = types.model({
  data: types.maybeNull(contentReadabilityModel),
  keyword: types.maybeNull(types.string),
});

const contentWordCountDataModel = types.model({
  data: types.maybeNull(contentWordCountModel),
  keyword: types.maybeNull(types.string),
});

const contentDataModel = types.model({
  contentScore: types.maybeNull(types.array(contentScoreModel)),
  performance: types.maybeNull(types.array(contentPerformanceModel)),
  readability: types.maybeNull(types.array(contentReadabilityDataModel)),
  wordCount: types.maybeNull(types.array(contentWordCountDataModel)),
});

const contentAuditListModel = types.model({
  countryCode: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  keywords: types.maybeNull(types.array(types.maybeNull(types.string))),
  readability: types.maybeNull(types.string),
  technicalScore: types.maybeNull(types.number),
  contentScore: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  shouldRepoll: types.maybeNull(types.boolean),
  importFailed: types.maybeNull(types.boolean),
  statusCode: types.maybeNull(types.number),
  isCompleted: types.maybeNull(types.boolean),
  isTargetKeywordFailed: types.maybeNull(types.boolean),
  contentScoreColor: types.maybeNull(types.string),
  technicalScoreColor: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  taskStatus: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
});

const internalInlinkSuggestionsModel = types.model({
  url: types.maybeNull(types.string),
  isLinked: types.maybeNull(types.boolean),
  foundIn: types.maybeNull(types.array(types.maybeNull(types.string))),
});

const complaintListModel = types.model({
  description: types.maybeNull(types.string),
  isIssue: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  severity: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
});

const complaintDetailModel = types.model({
  compliantList: types.maybeNull(types.array(complaintListModel)),
  healthToGain: types.maybeNull(types.number),
  issueGroup: types.maybeNull(types.string),
  groupIcon: types.maybeNull(types.string),
  issueList: types.maybeNull(types.array(complaintListModel)),
});

const auditIssuesModel = types.model({
  compliant: types.maybeNull(types.array(complaintDetailModel)),
  issues: types.maybeNull(types.array(complaintDetailModel)),
});
const desktopPsi = types.model({
  date: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  change: types.maybeNull(types.number),
});
const pageIssues = types.model({
  date: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  change: types.maybeNull(types.number),
});
const mobilePsi = types.model({
  date: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  change: types.maybeNull(types.number),
});

const historicalPsi = types.model({
  desktopPsi: types.maybeNull(types.array(desktopPsi)),
  mobilePsi: types.maybeNull(types.array(mobilePsi)),
});
const data = types.model({
  color: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  change: types.maybeNull(types.number),
});
const contentScore = types.model({
  data: types.maybeNull(types.array(data)),
  keyword: types.maybeNull(types.string),
});
const historicalScores = types.model({
  contentScore: types.maybeNull(types.array(contentScore)),
  pageIssues: types.maybeNull(types.array(pageIssues)),
  technicalScore: types.maybeNull(types.array(pageIssues)),
});
const matchingSourcePages = types.model({
  competitor: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  pageAuthority: types.maybeNull(types.number),
});
const backlinks = types.model({
  domain: types.maybeNull(types.string),
  domainAuthority: types.maybeNull(types.number),
  matches: types.maybeNull(types.array(types.string)),
  matchingSourcePages: types.maybeNull(types.array(matchingSourcePages)),
  spamCore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
});
const recommendedBacklinks = types.model({
  backlinks: types.maybeNull(types.union(types.array(backlinks), types.frozen({}))),
  keyword: types.maybeNull(types.string),
});

const serpsSchemaModel = types.model({
  keyword: types.maybeNull(types.string),
  schemas: types.maybeNull(types.frozen({})),
});

const ContentAuditModel = types.model({
  content: types.maybeNull(contentDataModel),
  contentScore: types.maybeNull(types.number),
  contentScoreColor: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  metaDescription: types.maybeNull(types.string),
  screenshotImage: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  focusTerms: types.maybeNull(focusTermsModel),
  issues: types.maybeNull(auditIssuesModel),
  id: types.maybeNull(types.number),
  internalOutlinkSuggestions: types.maybeNull(types.array(internallinkSuggestionsModel)),
  internalInlinkSuggestions: types.maybeNull(types.array(internalInlinkSuggestionsModel)),
  historicalPsi: types.maybeNull(historicalPsi),
  historicalScores: types.maybeNull(historicalScores),
  publicShareHash: types.maybeNull(types.string),
  recommendedBacklinks: types.maybeNull(types.array(recommendedBacklinks)),
  pageStructure: types.maybeNull(pageStructureModel),
  readability: types.maybeNull(types.string),
  shouldRepoll: types.maybeNull(types.boolean),
  keywords: types.maybeNull(types.array(types.string)),
  serpsSchema: types.maybeNull(types.array(serpsSchemaModel)),
  pageHealth: types.maybeNull(types.number),
  technicalScore: types.maybeNull(types.number),
  technicalScoreColor: types.maybeNull(types.string),
  technicals: types.maybeNull(technicalsModel),
  url: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  reprocessedAt: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  importFailed: types.maybeNull(types.boolean),
  isCompleted: types.maybeNull(types.boolean),
  isTargetKeywordFailed: types.maybeNull(types.boolean),
  isProcessed: types.maybeNull(types.boolean),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  taskStatus: types.maybeNull(types.string),

});

const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export type ContentAuditType = Instance<typeof ContentAuditModel>;


export const ContentAudit = types.model({
  loading: types.boolean,
  loadingForHeader: types.boolean,
  loadingList: types.boolean,
  repolling: types.maybeNull(types.boolean),
  contentAuditData: types.maybeNull(ContentAuditModel),
  contentAuditList: types.maybeNull(types.array(contentAuditListModel)),
  selectedKeyword: types.maybeNull(types.string),
  exportPage: types.maybeNull(types.boolean),
  noRepolling: types.boolean,
  reloadList: types.boolean,
  newRecordId: types.maybeNull(types.number),
  listApiCallCheck: types.boolean,
  total: types.maybeNull(types.number),
  params: types.maybeNull(paramsModel),
  maxLookupReached: types.maybeNull(types.boolean),
  contentAuditListTotal: types.maybeNull(types.array(contentAuditListModel)),
  resultCount: types.maybeNull(types.number),
  auditId: types.maybeNull(types.number),
  updatingCompletion: types.boolean,
  deletingAudit: types.boolean,
  showOnpageAuditDrawer: types.boolean,
}).views(self => ({
  get getContentAuditData() {
    return toJS(self.contentAuditData);
  },
  get getContentAuditList() {
    return toJS(self.contentAuditList);
  },
  get getTotalContentAuditList() {
    return toJS(self.contentAuditListTotal);
  },
})).actions(self => {
  const updateList = data => {
    self.contentAuditList = data;
  };
  const fetch = flow(function* (noLoading?: boolean, gsc?: boolean, isSearch?: boolean) {
    if (!noLoading) {
      self.loadingList = true;
    }
    try {
      const obj = {
        page_size: self.params.page_size,
        page: self.resultCount,
        ordering: self.params.ordering,
        search: self.params.search,
        type: self.params.type,
      };

      const response = yield CONTENT_AUDIT_API.getTasks(isSearch ? obj : self.params);
      if (response?.results?.length > 0 && gsc) {
        const num = Math.ceil(response?.count/100);
        self.resultCount = self.resultCount + 1;
        if (num >= self.resultCount && num > 1) {
          self.contentAuditListTotal = cast([...self.contentAuditListTotal, ...response.results]);
          return fetch(false, true, true);
        } else {
          self.contentAuditListTotal = cast(response.results);
        }
      }
      if (response?.results?.length > 0 ) {
        updateList(response?.results);
      }
      self.total = response?.count;
      const listArray = response?.results?.map(item=>item?.shouldRepoll);
      if (listArray?.includes(true) && self.listApiCallCheck) {
        yield new Promise(r => setTimeout(r, API_POLL_INTERVAL_CONTENT_AUDIT));
        return fetch();
      } else {
        if (!response?.isCancel) {
          if (response?.results?.length > 0) {
            updateList(response?.results);
          }
        }
      }
    } catch (e) {
      if (typeof window !== 'undefined' && location.pathname?.includes('onpage-audit')) {
        notification?.error('Error Loading On-page audit', 'The On-page audit data did not load properly. To fix the issue:');
      } else {
        notification.error('', 'Failed to load tasks data');
      }
    } finally {
      self.loadingList = false;
    }
  });

  const fetchAuditData = async () => {
    await fetch();
  };

  const updateSelectedKeyword = value => {
    self.selectedKeyword = value;
  };

  interface ContentAuditLocation {
    countryCode?: string;
    location?: string;
    locationId?: number;
  }

  const loadContentAudit = flow(function* (url:string, targetKeywords:string[], selectedCountry: ContentAuditLocation, renderWithBrowser?: boolean, captchaToken?:string) {
    self.loading = true;
    self.loadingForHeader = true;
    try {
      const response = yield CONTENT_AUDIT_API.loadData(url, targetKeywords, selectedCountry, captchaToken, renderWithBrowser);
      if (response) {
        return response;
      }
    } catch (e: any) {
      if (e.response.status === 400) {
        useErrorNotification({
          e,
          msg: e?.response?.data?.url,
        });
      } else {
        useErrorNotification({
          e,
          msg: 'Error Submitting On-page audit',
        });
      }
    }
  });

  const loadContentAuditPublic = flow(function* (url:string, targetKeywords:string[], countryCode:string, captchaToken?:string) {
    self.loading = true;
    self.loadingForHeader = true;
    try {
      const response = yield CONTENT_AUDIT_API.loadDataPublic(url, targetKeywords, countryCode, captchaToken);
      if (response) {
        self.maxLookupReached = false;
        return response;
      }
    } catch (e) {
      if (e.response.status === 400) {
        notification?.error('Error Submitting On-page audit-9999', e.response.data.nonFieldErrors[0]);
        return false;
      }
      if (e.response.status === 406) {
        notification.error('Maximum number of lookups reached.', 'You cannot make more than 2 lookups as unregistered user.');
        self.maxLookupReached = true;
        return false;
      }
      return Promise.reject(e);
    }
  });

  const recrawlContentAudit = flow(function* (id:number) {
    self.loadingForHeader = true;
    try {
      const response = yield CONTENT_AUDIT_API.reLoadData(id);
      if (response?.shouldRepoll && !self.noRepolling) {
        yield new Promise(r => setTimeout(r, API_POLL_INTERVAL_CONTENT_AUDIT));
        return getContentData(id, true);
      } else {
        if (!response?.isCancel) {
          return getContentData(id, true);
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const recrawlListAudit = flow(function* (id:number) {
    self.loading = true;
    try {
      const response = yield CONTENT_AUDIT_API.reLoadData(id);
      if (response?.shouldRepoll && !self.noRepolling) {
        yield new Promise(r => setTimeout(r, API_POLL_INTERVAL_CONTENT_AUDIT));
        return fetch(true);
      } else {
        fetch(true);
        if (!response?.isCancel) {
          self.loading = false;
          self.contentAuditList = response?.results;
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const getContentData = flow(function* (id:number, noLoading?:boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loading = true;
      self.loadingForHeader = true;
    }
    try {
      const token = getTokenFromCookies();
      if (!token && !publicHash) {
        Router.push('/login');
      } else {
        const response = yield CONTENT_AUDIT_API.getData(id, publicHash);
        self.repolling = response?.shouldRepoll;
        let timeOutId = null;
        if (response?.shouldRepoll && !self.noRepolling) {
          timeOutId = yield new Promise(r => setTimeout(r, API_POLL_INTERVAL_CONTENT_AUDIT));
          return getContentData(id, noLoading);
        } else {
          clearTimeout(timeOutId);
          if (!response?.isCancel) {
            self.contentAuditData = response;
            self.loading = false;
            self.loadingForHeader = false;
          }
        }
      }
    } catch (e) {
      handleNetworkError(e);
      if (e?.response?.status == 500) {
        notification?.error('Something went wrong.', '');
      }
      if (e?.response?.status == 400) {
        notification?.error('Something went wrong.', '');
      }
      return Promise.reject(e);
    }
  });
  const resetContentAudit = () =>{
    self.contentAuditData = null;
  };

  const setExportPage = value =>{
    self.exportPage = value;
  };

  const setRepolling = value => {
    self.repolling = value;
  };

  const exportContentAuditToPng = (node, keyword, url) => {
    if (node?.current) {
      html2canvas(node?.current, {allowTaint: true, useCORS: true})
        .then(canvas => {
          canvas.style.display = 'none';
          const image = canvas.toDataURL();
          const a = document.createElement('a');
          a.setAttribute('download', `Content audit-${keyword}-${url}.png`);
          a.setAttribute('href', image);
          a.click();
        });
    }
  };

  const startRepolling = () => {
    self.noRepolling = false;
  };

  const stopRepolling = () => {
    self.noRepolling = true;
  };

  const reloadData = value => {
    self.reloadList = value;
  };

  const setNewRecordId = id => {
    self.newRecordId = id;
  };

  const setListApiCall = value => {
    self.listApiCallCheck = value;
    if (value) {
      self.loadingList = true;
    }
  };

  const setParams = value => {
    self.params.search = value?.search;
    self.params.ordering = value?.ordering;

    if (value?.type) {
      self.params.type = value?.type;
    }
    if (value?.page) {
      self.params.page = value?.page;
    }
    if (value?.pageSize) {
      self.params.page_size = value?.pageSize;
    }
    fetch();
  };


  const deleteAudit = flow(function* (id:number) {
    self.deletingAudit = true;
    try {
      yield CONTENT_AUDIT_API.deleteAuditData(id);
      fetch(true);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.deletingAudit = false;
    }
  });

  const removeInternalLink = flow(function* (payload:any) {
    try {
      yield CONTENT_AUDIT_API.deleteInternalLinkSuggestion(self.contentAuditData?.id, payload);
      getContentData(self.contentAuditData?.id, true);
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const removeInLink = flow(function* (payload:any) {
    try {
      yield CONTENT_AUDIT_API.deleteInLinkSuggestion(self.contentAuditData?.id, payload);
      getContentData(self.contentAuditData?.id, true);
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const updateCompleteStatus = flow(function* (id, value) {
    self.updatingCompletion = true;
    try {
      yield CONTENT_AUDIT_API.updateAuditStatus(id, value);
      getContentData(self.contentAuditData?.id, true);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.updatingCompletion = false;
    }
  });

  const resetContentAuditListTotal = () => {
    self.contentAuditListTotal = cast([]);
    self.resultCount = 0;
  };

  const setAuditId = id => {
    self.auditId = id;
  };

  const setLoading = () => {
    self.loading = false;
    self.loadingForHeader= false;
  };

  const loadCountries = flow(function* (queryParam: string) {
    try {
      const resp = yield OVERVIEW_DETAIL_API.getCountriesList(queryParam);
      if (resp?.length && !resp.isCancel) return resp[0];
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const setShowOnpageAuditDrawer = (visible: boolean) => self.showOnpageAuditDrawer = visible;

  return {
    fetch,
    loadCountries,
    reloadData,
    removeInternalLink,
    removeInLink,
    deleteAudit,
    updateCompleteStatus,
    setParams,
    exportContentAuditToPng,
    recrawlContentAudit,
    recrawlListAudit,
    setExportPage,
    startRepolling,
    stopRepolling,
    resetContentAudit,
    loadContentAudit,
    setNewRecordId,
    setListApiCall,
    getContentData,
    updateSelectedKeyword,
    updateList,
    loadContentAuditPublic,
    setRepolling,
    resetContentAuditListTotal,
    setAuditId,
    setLoading,
    setShowOnpageAuditDrawer,
    fetchAuditData,
  };
});

export function initContentAudit() {
  return ContentAudit.create({
    loading: false,
    loadingForHeader: false,
    loadingList: true,
    listApiCallCheck: false,
    selectedKeyword: '',
    exportPage: false,
    repolling: false,
    noRepolling: false,
    reloadList: false,
    params: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    contentAuditList: [],
    maxLookupReached: false,
    contentAuditListTotal: [],
    resultCount: 0,
    updatingCompletion: false,
    deletingAudit: false,
    showOnpageAuditDrawer: false,
  });
}

