import {types} from 'mobx-state-tree';

export const NotificationsStore = types.model({
  token: types.maybeNull(types.string),
  notifications: types.maybeNull(types.string),
  alert: types.boolean,
}).views(self => ({
  get parsedNotifications() {
    return JSON.parse(self.notifications);
  },
})).actions(self => {
  const setToken = (token: string) => {
    return self.token = token;
  };

  const setNotifications = notifications => {
    return self.notifications = JSON.stringify(notifications);
  };

  const triggerNotSeen = () => {
    self.alert = true;
  };

  const triggerSeen = () => {
    self.alert = false;
  };

  return {
    setToken,
    triggerNotSeen,
    triggerSeen,
    setNotifications,
  };
});

export function initNotificationsStore() {
  return NotificationsStore.create({
    token: null,
    notifications: null,
    alert: false,
  });
}
