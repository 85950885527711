import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface TypographyProps {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'label' | 'strong' | 'span';
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Typography: React.FC<TypographyProps> = props => {
  const {tag} = props;

  return tag ? React.createElement(tag, {...props, className: classnames(styles.typo, props.className)}, props.children) : <></>;
};
