import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import EChartsReact from 'echarts-for-react';
import {formatLargeNumber, numberWithCommas} from '@/utils/number';
import moment from 'moment';
import {LoadingOutlined} from '@ant-design/icons';
import styled from 'styled-components';
// import {NoDataWrapper} from '../../components/StyledComponent';
import {renderToString} from 'react-dom/server';
import ReactEcharts from 'echarts-for-react';
import {Empty, Spin} from 'antd';

interface KeyProps {
  name: string;
  optName: string;
  color: string;
}

interface ConfigProps {
  FKey: KeyProps;
  SKey: KeyProps;
  hideXAxis: boolean;
}

interface Props {
  data?: any;
  loading?: boolean;
  isBacklinks?: boolean;
  legends?: any [];
  conf?: ConfigProps;
}
export const GMBChart:React.FC<Props> = observer(({data, loading, legends, isBacklinks, conf}) => {
  const toolTipFormatterLineChart = data => {
    const element = (<TooltipContent>
      <TooltipTitle>{moment(data[0]?.axisValue).format(isBacklinks ? 'MMM YYYY' : 'DD MMM YYYY')}</TooltipTitle>
      <TooltipItems>
        {data?.map((item, idx) => {
          return <TooltipItem key={idx}>
            <div dangerouslySetInnerHTML={{__html: item?.marker}} />
            {item?.seriesName}:
            <span style={{marginLeft: 5, color: '#121212'}}>{numberWithCommas(item?.data)}</span>
          </TooltipItem>;
        })}
      </TooltipItems>
    </TooltipContent>
    );
    return renderToString(element);
  };

  const legendsData = legends ? legends : ['New Users', 'New Users'];
  const isDarkTheme = false;
  const option = {
    legend: {
      data: [conf.FKey.optName, conf.SKey.optName],
      show: true,
      icon: 'circle',
      left: 10,
      top: 10,
    },
    textStyle: {
      color: isDarkTheme ? '#E8E8E8' : '#4E5156',
    },
    grid: {
      left: 18,
      right: 20,
      bottom: 20,
      top: 65,
      containLabel: true,
    },
    tooltip: {
      formatter: toolTipFormatterLineChart,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'category',
      data: data?.map(z => z?.date),
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [0, 10, 0, 0],
        formatter: function(value) {
          return !conf.hideXAxis ? moment(value)?.format('MMM DD') : '';
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          type: 'dashed',
          color: isDarkTheme ? '#4E5156' : '#E8E8E8',
        },
      },
    },
    yAxis: [
      {
        minInterval: 1,
        min: 0,
        type: 'value',
        position: 'left',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: isDarkTheme ? '#4E5156' : '#E8E8E8',
          },
        },
        axisLabel: {
          formatter: function(value: number) {
            return formatLargeNumber(value || 0);
          },
          color: conf.FKey.color,
        },
      },
      {
        minInterval: 1,
        min: 0,
        type: 'value',
        position: 'right',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: isDarkTheme ? '#4E5156' : '#E8E8E8',
          },
        },
        axisLabel: {
          formatter: function(value: number) {
            return formatLargeNumber(value || 0);
          },
          color: conf.SKey.color,
        },
      },
    ],
    series: [
      {
        name: conf.FKey.optName,
        data: isBacklinks ? data?.map(z => z?.backlinks) : data?.map(z => z[conf.FKey.name]),
        type: 'line',
        symbol: '',
        itemStyle: {
          color: conf.FKey.color,
        },
        yAxisIndex: 0,
      },
      {
        name: conf.SKey.optName,
        data: isBacklinks ? data?.map(z => z?.refdomains) : data?.map(z => z[conf.SKey.name]),
        type: 'line',
        symbol: 'none',
        itemStyle: {
          color: conf.SKey.color,
        },
        lineStyle: {
          type: 'solid',
        },
        yAxisIndex: 1,
      },
    ],
  };

  const loadingOptions = {
    grid: {
      top: 25,
      bottom: 25,
      left: 50,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr'],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: isDarkTheme ? '#4E5156' : '#4E5156',
          showMinLabel: true,
          showMaxLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 3000,
        axisLabel: {
          color: isDarkTheme ? '#4E5156' : '#4E5156',
        },
        splitLine: {
          lineStyle: {
            color: isDarkTheme ? '#4E5156' : '#4E5156',
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        min: 0,
        max: function(value) {
          return value.max;
        },
        axisLabel: {
          color: '#4E5156',
        },
        splitLine: {
          lineStyle: {
            color: '#e8e8e8',
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        min: 10,
        max: function(value) {
          return value.max;
        },
        axisLabel: {
          color: '#4E5156',
        },
        splitLine: {
          lineStyle: {
            color: '#4E5156',
            type: 'dashed',
          },
        },
      },
    ],
  };
  const antIcon=<LoadingOutlined style={{color: '#4e5156', fontSize: '30px'}} spin/>;
  return (
    loading ? <div style={{position: 'relative', top: '10px'}}>
      <div style={{position: 'absolute', left: '45%', top: '50%'}}>{antIcon}</div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {legendsData?.map((item, index) => {
          return <div key={index}>
            <div>
              <div style={{lineHeight: '22px', whiteSpace: 'nowrap', marginLeft: '20px', color: '#4e5156', display: 'flex'}}><CheckBox/>&nbsp;&nbsp;{item}</div>
            </div>
          </div>;
        })}
      </div>
      <EChartsReact
        style={{height: '360px'}}
        option={loadingOptions}
      />
    </div> : !data?.length ? <NoDataWrapper>No Data</NoDataWrapper> :
      <div>
        <EChartsReact
          option={option}
          style={{
            height: '210px',
          }}
        />
      </div>
  );
});

export const CircleGmbChart = ({CardData, loading, total}: any) => {
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [pieChartSeriesData, setPieChartSeriesData] = useState([]);

  useEffect(() => {
    const pieChartSeries = CardData?.map(item => ({
      value: item?.value, name: item?.label, itemStyle: {
        color: item?.color,
      },
      filter: item?.filter,
    }));
    setPieChartSeriesData(pieChartSeries);
    setFilteredChartData(pieChartSeries);
  }, [CardData]);

  const handleChartFilter = name => {
    const isFilterApplied = selectedLabel.includes(name);

    if (isFilterApplied) {
      const newData = pieChartSeriesData.find(item => item.name === name);
      setFilteredChartData([...filteredChartData, newData]);
      setSelectedLabel(selectedLabel.filter(item => item !== name));
    } else {
      setSelectedLabel(selectedLabel => {
        const updatedSelectedLabel = [...selectedLabel, name];
        const filteredChartData = pieChartSeriesData.filter(i => updatedSelectedLabel.every(val => i.name !== val));
        setFilteredChartData(filteredChartData);
        return updatedSelectedLabel;
      });
    }
  };

  const toolTipFormatter = function(series: any) {
    const element = (
      <TooltipText>
        <span>{series?.name}</span> <span>{series?.value} pages</span>
      </TooltipText>
    );
    return renderToString(element);
  };

  const getPercent = val => {
    return Math.round((Number(val)/total)*100);
  };

  return (
    <div style={{display: 'flex', alignItems: 'center', marginTop: '-22px;'}}>
      {loading ? <div style={{marginLeft: '160px', marginTop: '30px'}}><Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin />} /></div>:
        CardData?.length ? <>
          <div>
            {CardData.map((i, index) =>(
              <Legends key={index} onClick={() => handleChartFilter(i?.label)}>
                <TitleSec>
                  <DOT color={i?.color || '-'} isFilterApplied={selectedLabel.includes(i?.label)}></DOT>
                  <Name>{i?.label || '-'}</Name>
                </TitleSec>
                <div>
                  <Value>{numberWithCommas(i.value) || '-'} <span style={{fontWeight: '500', fontSize: '12px', color: 'gray'}}>({getPercent(i.value)}%)</span></Value>
                  <Delta></Delta>
                </div>
              </Legends>
            ))}
          </div>
          <div style={{width: '175px'}}>
            <ReactEcharts
              style={{height: '280px', left: '35px', top: '-24px'}}
              option={{
                tooltip: {
                  backgroundColor: '#ccc',
                  trigger: 'item',
                  appendToBody: 'true',
                  axisPointer: {
                    type: 'shadow',
                  },
                  textStyle: {
                    color: '#E8E8E8',
                  },
                  formatter: toolTipFormatter,
                },
                series: [
                  {
                    name: '',
                    type: 'pie',
                    radius: ['65%', '95%'],
                    center: ['18%', '50%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                      borderRadius: 1,
                      borderColor: '#FFFFFF',
                      borderWidth: 0.2,
                    },
                    label: {
                      show: false,
                    },
                    emphasis: {
                      focus: 'series',
                      label: {
                        show: false,
                        fontSize: '35',
                        fontWeight: 'bold',
                      },
                    },
                    labelLine: {
                      show: false,
                    },
                    data: filteredChartData,
                    left: '30%',
                  },
                ],
              }}
            />
          </div>
        </> :
          <div style={{marginTop: '70px', marginLeft: '140px'}}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>}
    </div>
  );
};

const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 100%;
  background: #e8e8e8;
`;
const TooltipContent = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
const TooltipItem = styled.div`
 display: flex;
 align-items: center;
 font-size: 12px;
`;
const TooltipItems = styled.div`
 display: flex;
 flex-direction: column;
`;
const TooltipTitle = styled.div`
  font-size: 12px;
  color: #121212;
  font-weight: 600;
`;

const TooltipText = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 6px;
  margin: -15px;
  span {
    font-weight: bold;
  }
`;

const Legends = styled.div`
  margin-top: 20px;
  cursor: pointer;
`;

const DOT = styled.div<{color: string; isFilterApplied: boolean}>`
  width: 12px;
  height: 12px;
  border-radius: 5px;
  background-color: ${p => p.isFilterApplied ? '#e7e7e7' : p.color};
`;

const Name = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

const Value = styled.div`
  margin-left: 16px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const TitleSec = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Delta = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(78, 81, 86, 1);
`;

const NoDataWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 300px;
color: #a3a4a4;
`;
